import { useLocale } from '@hooks/useLocale';
import { Box, Text } from '@mantine/core';
import { ClockClockwise, Sparkle, X } from '@phosphor-icons/react';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';

const ChatHeader = ({
    setChatInput,
    stop,
}: {
    setChatInput: (value: string) => void;
    stop: () => void;
}) => {
    const { t } = useLocale();
    const { showAiAnalyst, aiChatMessages } = useAiAnalystContext(
        (context) => context.state,
    );
    const { setShowAiAnalyst, handleCreateThread } = useAiAnalystContext(
        (context) => context.actions,
    );

    if (!showAiAnalyst && aiChatMessages.length === 0) return null;

    if (!showAiAnalyst) {
        return (
            <Box
                className="sticky top-6 flex gap-2 items-center mx-auto rounded-lg px-3 py-2 mb-4 bg-white/10 cursor-pointer"
                onClick={() => {
                    stop();
                    handleCreateThread();
                    setChatInput('');
                }}
            >
                <ClockClockwise color="white" />
                <Text className="text-sm font-semibold text-white">
                    {t('ai.start_over')}
                </Text>
            </Box>
        );
    }
    return (
        <Box className="flex justify-between py-2 items-center mx-3">
            <Box className="flex gap-2 items-center">
                <Sparkle size={16} color={`rgb(var(--color-blu-800))`} />
                <Text className="text-base font-medium text-white">
                    {t('chat.analyst_ai')}
                </Text>
            </Box>
            <Box className="flex gap-3 items-center p-3.5">
                {aiChatMessages.length > 0 && (
                    <Box
                        className="p-3 cursor-pointer hover:bg-white/10 rounded-lg"
                        onClick={() => {
                            stop();
                            handleCreateThread();
                            setChatInput('');
                        }}
                    >
                        <ClockClockwise size={14} color="white" />
                    </Box>
                )}
                {/* <Box className="p-3 cursor-pointer">
                    <ArrowsOut size={14} color="white"/>
                </Box> */}
                <Box
                    className="p-3 cursor-pointer hover:bg-white/10 rounded-lg "
                    onClick={() => setShowAiAnalyst(false)}
                >
                    <X size={14} color="white" weight="bold" />
                </Box>
            </Box>
        </Box>
    );
};

export default ChatHeader;
