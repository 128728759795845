import { type SegmentedTimeFilterOptions } from '@components/common/SegmentedTimeFilter/types';
import useNotify from '@hooks/toaster/useNotify';
import { useGetEventLogs } from '@hooks/useEvents';
import { useLocale } from '@hooks/useLocale';
import { type EventLog } from '@lightdash/common';
import { Box, Button, Flex, Stack, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { Clock, Copy } from '@phosphor-icons/react';
import {
    PROFILE_ACTIVITY_PAGE_SIZE,
    SEARCH_INPUT_DEBOUNCE_TIME,
} from '@utils/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AceEditor from 'react-ace';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { ButtonVariant } from '../../../../../mantineTheme';
import EventsHistoryAccordion from '../EventsHistoryAccordion';

interface OtherEventHistoryProps {
    searchString: string;
    currentSelectedTimeFilter: SegmentedTimeFilterOptions | undefined;
    timeFilter: {
        startTimestamp?: string;
        endTimestamp?: string;
    };
    profileId: string | undefined;
}

const OtherEventHistory: React.FC<OtherEventHistoryProps> = ({
    searchString,
    currentSelectedTimeFilter,
    profileId,
    timeFilter,
}) => {
    const { showToastSuccess } = useNotify();
    const { t } = useLocale();

    const [offset, setOffset] = useState<number>(0);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
    const [debouncedSearchString] = useDebouncedValue(
        searchString,
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [accumulatedEventLogs, setAccumulatedEventLogs] = useState<
        EventLog[] | undefined
    >(undefined);

    const queryParameters = useMemo(() => {
        const query = [
            `limit=${PROFILE_ACTIVITY_PAGE_SIZE}`,
            `offset=${offset}`,
        ];
        if (timeFilter.startTimestamp) {
            query.push(`startDate=${timeFilter.startTimestamp}`);
        }
        if (timeFilter.endTimestamp) {
            query.push(`endDate=${timeFilter.endTimestamp}`);
        }
        if (profileId) {
            query.push(`userUuid=${profileId}`);
        }
        if (debouncedSearchString) {
            query.push(`eventName=${debouncedSearchString}`);
        }

        const queryString = query.join('&');
        return queryString;
    }, [debouncedSearchString, offset, profileId, timeFilter]);

    const {
        data: eventLogs,
        isLoading: isEventLogsLoading,
        isFetching,
    } = useGetEventLogs(queryParameters);

    useEffect(() => {
        if (eventLogs) {
            setAccumulatedEventLogs((prev) => [...(prev ?? []), ...eventLogs]);
        }
    }, [eventLogs]);

    const prepareVisibleActivities = useCallback(() => {
        if (!projectUuid) return [];
        return accumulatedEventLogs?.map((item, index, array) => {
            const currentDate = new Date(item.serverTimestamp);
            const previousDate =
                index > 0 ? new Date(array[index - 1].serverTimestamp) : null;
            const showDateHeader =
                index === 0 ||
                currentDate.toLocaleDateString() !==
                    previousDate?.toLocaleDateString();

            const rawPayload = item.rawPayload;
            const payload = rawPayload ? JSON.parse(rawPayload) : null;

            return {
                id: uuidv4(),
                timestamp: item.serverTimestamp?.toString(),
                name: item.eventName,
                payload,
                showDateHeader,
                currentDate,
                data: item,
                isReadonly: !Boolean(payload),
            };
        });
    }, [projectUuid, accumulatedEventLogs]);

    const visibleActivities = useMemo(
        () => prepareVisibleActivities(),
        [prepareVisibleActivities],
    );

    const handleTimeFilterChangeOrSearchChange = useCallback(() => {
        setOffset(0);
        setAccumulatedEventLogs(undefined);
        setIsLoadingMore(false);
    }, []);

    useEffect(() => {
        handleTimeFilterChangeOrSearchChange();
    }, [
        currentSelectedTimeFilter,
        handleTimeFilterChangeOrSearchChange,
        searchString,
    ]);

    const loadMoreActivities = () => {
        if (eventLogs && eventLogs.length === PROFILE_ACTIVITY_PAGE_SIZE) {
            setOffset((prevOffset) => prevOffset + PROFILE_ACTIVITY_PAGE_SIZE);
            setIsLoadingMore(true);
        }
    };

    return (
        <Box>
            <Stack>
                <Box className="p-3 !h-[65vh] overflow-auto">
                    <EventsHistoryAccordion<EventLog>
                        events={visibleActivities ?? []}
                        onLoadMore={loadMoreActivities}
                        eventName={(item) => {
                            if (!item.data) {
                                return '';
                            }
                            return (
                                <Flex align="center" gap={4}>
                                    <Clock
                                        weight="duotone"
                                        color="rgb(var(--color-gray-500))"
                                    />
                                    <Text className="text-sm font-medium text-gray-500">
                                        {item.data.source}
                                    </Text>
                                </Flex>
                            );
                        }}
                        eventPanel={(item) => (
                            <Box className="w-full border rounded border-shade-6 ">
                                <Box className="flex justify-between px-3.5 border-b border-shade-6 items-center h-[2.8rem]">
                                    <Text className="text-sm font-medium">
                                        {t('common.payload')}
                                    </Text>
                                    <Button
                                        leftIcon={
                                            <Copy
                                                weight="duotone"
                                                color="rgb(var(--color-blu-800))"
                                            />
                                        }
                                        variant={ButtonVariant.SUBTLE_ACCENTED}
                                        onClick={() => {
                                            void navigator.clipboard.writeText(
                                                JSON.stringify(
                                                    item.payload,
                                                    null,
                                                    2,
                                                ),
                                            );
                                            showToastSuccess({
                                                title: t('common.copied'),
                                                autoClose: 1000,
                                            });
                                        }}
                                    >
                                        {t('common.copy_json')}
                                    </Button>
                                </Box>
                                <Box className="px-2 py-2">
                                    <AceEditor
                                        value={JSON.stringify(
                                            item.payload,
                                            null,
                                            2,
                                        )}
                                        readOnly
                                        width="100%"
                                        minLines={1}
                                        maxLines={100}
                                        setOptions={{
                                            tabSize: 2,
                                            lineHeight: 19,
                                        }}
                                        mode="json"
                                        theme="textmate"
                                        name="json-editor"
                                        showGutter={false}
                                        highlightActiveLine={false}
                                    />
                                </Box>
                            </Box>
                        )}
                        canLoadMore={Boolean(
                            eventLogs &&
                                eventLogs.length === PROFILE_ACTIVITY_PAGE_SIZE,
                        )}
                        isInitialLoading={
                            (isEventLogsLoading || isFetching) && !isLoadingMore
                        }
                        isLoadingMore={
                            isLoadingMore && (isEventLogsLoading || isFetching)
                        }
                    />
                </Box>
            </Stack>
        </Box>
    );
};

export default OtherEventHistory;
