import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import { getItemId, isCustomSqlDimension } from '@lightdash/common';
import { Box, Divider } from '@mantine/core';
import { MagnifyingGlass } from '@phosphor-icons/react';
import Fuse from 'fuse.js';
import { useCallback, useMemo, useState, type ReactNode } from 'react';
import { useDebounce } from 'react-use';
import { VariableSizeList } from 'react-window';
import FieldListItem from '../Filters/FieldListItem';
import {
    convertFieldsIntoPropertySelectListType,
    type FieldWithSelectStatus,
} from '../Filters/FieldListItem/utils';
import { type FieldWithSuggestions } from '../Filters/FiltersProvider/types';

enum ReservedStrings {
    HEADER = 'header',
    ITEM = 'item',
    DEFAULT = 'default',
}

const HEADER_HEIGHT = 36;
const ITEM_HEIGHT = 42;

const searchKeys = ['label', 'tableLabel', 'name'];
interface AudiencePreviewPropertiesPanelProps {
    fieldsWithSelectStatus: FieldWithSelectStatus[];
    setPreviewColumns: (columns: string[]) => void;
    onPropertyToggle?: (items: string[]) => void;
}

const AudiencePreviewPropertiesPanel = ({
    fieldsWithSelectStatus,
    setPreviewColumns,
    onPropertyToggle,
}: AudiencePreviewPropertiesPanelProps) => {
    const [search, setSearch] = useState<string>('');
    const [searchDebounce, setSearchDebounce] = useState<string>('');
    const [propertyList, setPropertyList] = useState(
        convertFieldsIntoPropertySelectListType(fieldsWithSelectStatus, true),
    );
    useDebounce(() => setSearchDebounce(search), 300, [search]);
    const { t } = useLocale();

    const filteredItems = useMemo(() => {
        let data = propertyList.flatMap((group) => group.items);
        if (searchDebounce) {
            const fuse = new Fuse(data, {
                keys: searchKeys,
                threshold: 0.3,
            });
            data = fuse.search(searchDebounce).map((result) => result.item);
        }
        return data;
    }, [propertyList, searchDebounce]);

    const groupedFilteredItems = useMemo(() => {
        const grouped: Record<
            string,
            (AddditionalPropertySelectListProps & FieldWithSuggestions)[]
        > = {};
        filteredItems?.forEach((item) => {
            const subGroupKey = item.subGroupKey || ReservedStrings.DEFAULT;
            if (!grouped[subGroupKey]) {
                grouped[subGroupKey] = [];
            }
            grouped[subGroupKey].push(item);
        });
        return grouped;
    }, [filteredItems]);

    const flatListWithHeaders = useMemo(() => {
        const flatList: Array<{
            type: ReservedStrings.HEADER | ReservedStrings.ITEM;
            data:
                | (AddditionalPropertySelectListProps & FieldWithSuggestions)
                | string;
        }> = [];
        Object.entries(groupedFilteredItems)?.forEach(
            ([subGroupKey, groupItems]) => {
                if (
                    subGroupKey &&
                    groupItems[0]?.subGroupLabel &&
                    subGroupKey !== ReservedStrings.DEFAULT
                ) {
                    flatList.push({
                        type: ReservedStrings.HEADER,
                        data: groupItems[0]?.subGroupLabel as string,
                    });
                }
                groupItems.forEach((item) => {
                    flatList.push({ type: ReservedStrings.ITEM, data: item });
                });
            },
        );
        return flatList;
    }, [groupedFilteredItems]);

    const toggleSelection = useCallback(
        (item: AddditionalPropertySelectListProps & FieldWithSuggestions) => {
            const newPropertyList = propertyList.map((group) => ({
                ...group,
                items: group.items.map((groupItem) =>
                    groupItem === item
                        ? { ...groupItem, isChecked: !groupItem.isChecked }
                        : groupItem,
                ),
            }));
            setPropertyList(newPropertyList);
            const items = newPropertyList
                .flatMap((group) => group.items)
                .filter((_item) => _item.isChecked);
            setPreviewColumns(
                items.map((_item) =>
                    isCustomSqlDimension(_item)
                        ? `${_item.table}_${_item.id}`
                        : getItemId(_item),
                ),
            );
            if (onPropertyToggle) {
                onPropertyToggle(
                    items.map((_item) =>
                        isCustomSqlDimension(_item)
                            ? `${_item.table}_${_item.id}`
                            : getItemId(_item),
                    ),
                );
            }
        },
        [propertyList, setPreviewColumns, onPropertyToggle],
    );

    const EachProperty = useCallback(
        ({ index, style }: { index: number; style: React.CSSProperties }) => {
            const listItem = flatListWithHeaders[index];
            if (listItem.type === ReservedStrings.HEADER) {
                const headerHeight =
                    index === 0 ? HEADER_HEIGHT : HEADER_HEIGHT + 26; //Add more space for the headers to accommodate the divider
                return (
                    <Box
                        style={{
                            ...style,
                            height: headerHeight,
                        }} // Set the height for the header
                        className={`px-2 py-3 text-xs font-medium text-gray-600`}
                    >
                        {index !== 0 && (
                            <Divider className="mb-4 border-t-4 border-t-shade-2" />
                        )}
                        {listItem.data as ReactNode}
                    </Box>
                );
            }
            const item = listItem.data;
            return (
                <Box
                    style={style}
                    className={
                        (
                            item as AddditionalPropertySelectListProps &
                                FieldWithSuggestions
                        ).isDisabled
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                    }
                    onClick={() => {
                        if (typeof item !== 'string' && item.isDisabled) return;
                        if (typeof item !== 'string') {
                            toggleSelection(item);
                        }
                    }}
                >
                    <FieldListItem
                        item={item as FieldWithSuggestions}
                        checked={
                            typeof item !== 'string' &&
                            (item.isChecked ?? false)
                        }
                        showCheckbox={true}
                        disabled={
                            typeof item !== 'string' &&
                            (item.isDisabled ?? false)
                        }
                        showHoverIcon={false}
                    />
                </Box>
            );
        },
        [flatListWithHeaders, toggleSelection],
    );

    const getItemSize = useCallback(
        (index: number) =>
            flatListWithHeaders[index].type === ReservedStrings.HEADER
                ? index === 0
                    ? HEADER_HEIGHT
                    : HEADER_HEIGHT + 26
                : ITEM_HEIGHT,
        [flatListWithHeaders],
    );

    return (
        <Box className="border border-gray-200 h-full w-[20rem]">
            <Box className="p-2 border-b border-gray-200">
                <SearchInput
                    icon={
                        <MagnifyingGlass color="rgb(var(--color-gray-500))" />
                    }
                    variant="unstyled"
                    placeholder={t('property_select_type.search_placeholder')}
                    className="w-full"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </Box>
            <Box className="!py-0 pl-2 overflow-scroll">
                <VariableSizeList
                    ref={undefined}
                    height={428}
                    itemCount={flatListWithHeaders.length}
                    itemSize={(index) => getItemSize(index)}
                    width="100%"
                    className="List !overflow-x-hidden"
                >
                    {EachProperty}
                </VariableSizeList>
            </Box>
        </Box>
    );
};

export default AudiencePreviewPropertiesPanel;
