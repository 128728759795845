import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import TestCommunication from '@components/TestCommunication';
import { TestCommunicationSource } from '@components/TestCommunication/types';
import useTestCommunication from '@components/TestCommunication/useTestCommunication';
import { useLocale } from '@hooks/useLocale';
import { useGetTestProfiles } from '@hooks/useTestProfiles';
import {
    CommunicationChannel,
    CommunicationChannelName,
    type Campaign,
    type ContentMappings,
    type ContentMappingSection,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import React, { useEffect, useMemo, useState } from 'react';
import { ButtonVariant } from '../../../../../../mantineTheme';

type JourneyTestProps = {
    opened: boolean;
    onClose: () => void;
    channel: CommunicationChannel;
    journeyVariables: ContentMappings;
    contentMappings: ContentMappings;
    contentDetails: Campaign['contentDetails'];
    communicationDetails: Campaign['communicationDetails'];
    templateDetails: Campaign['templateDetails'];
};

const JourneyTest = ({
    opened,
    onClose,
    channel,
    journeyVariables,
    contentMappings,
    contentDetails,
    communicationDetails,
    templateDetails,
}: JourneyTestProps) => {
    const { t } = useLocale();
    const [selectedTestProfiles, setSelectedTestProfiles] = useState<string[]>(
        [],
    );
    const [testProfilesOptions, setTestProfilesOptions] = useState<
        { label: string; value: string }[]
    >([]);
    const [mappings, setMappings] = useState(contentMappings);

    const { sendTestOldFlow } = useTestCommunication({
        channel,
        communicationDetails,
        templateDetails,
    });

    const handleChange = (
        value: string,
        variable: string,
        section: ContentMappingSection,
    ) => {
        setMappings({
            ...mappings,
            [section]: {
                ...mappings[section],
                [variable]: {
                    ...mappings?.[section]?.[variable],
                    defaultValue: value,
                },
            },
        });
    };

    const { data } = useGetTestProfiles({
        successCallback: (payload) => {
            if (payload && payload.length) {
                setSelectedTestProfiles([
                    ...payload
                        .map(
                            (item: any) =>
                                item[channel ?? CommunicationChannel.EMAIL],
                        )
                        .filter((item: any) => item),
                ]);
                setTestProfilesOptions([
                    ...payload.map((profile: any) => ({
                        label: profile.name,
                        value: profile?.[channel ?? CommunicationChannel.EMAIL],
                    })),
                ]);
            }
        },
    });

    useEffect(() => {
        if (data && data.length && channel) {
            setSelectedTestProfiles([
                ...data
                    .map(
                        (item: any) =>
                            item[channel ?? CommunicationChannel.EMAIL],
                    )
                    .filter((item: any) => item),
            ]);
            setTestProfilesOptions([
                ...data.map((profile: any) => ({
                    label: profile.name,
                    value: profile?.[channel ?? CommunicationChannel.EMAIL],
                })),
            ]);
        }
    }, [channel, data]);

    const isSendTestDisabled = useMemo(() => {
        return !selectedTestProfiles.length;
    }, [selectedTestProfiles.length]);

    const sendTestCommTitle = useMemo(() => {
        if (!channel) return '';
        let channelLabel = CommunicationChannelName[channel];
        if (channel === CommunicationChannel.EMAIL) {
            channelLabel = channelLabel.toLowerCase();
        }
        return t('campaigns_builder.send_test_communication', {
            channel: channelLabel,
        });
    }, [channel, t]);

    const handleSendTest = async () => {
        await sendTestOldFlow({
            mappings,
            contentDetails,
            recipients: selectedTestProfiles,
        });
    };

    return (
        <Modal
            onClose={onClose}
            title="Send a test message"
            opened={opened}
            size={'lg'}
        >
            <Box>
                <Box className="mb-6">
                    <TestCommunication
                        source={TestCommunicationSource.JOURNEYS}
                        journeyVariables={journeyVariables}
                        testProfilesOptions={testProfilesOptions}
                        selectedTestProfiles={selectedTestProfiles}
                        setSelectedTestProfiles={setSelectedTestProfiles}
                        setTestProfilesOptions={setTestProfilesOptions}
                        mappings={mappings}
                        handleChange={handleChange}
                        channel={channel}
                    />
                </Box>
                <ModalFooter
                    primaryText={sendTestCommTitle}
                    secondaryText={t('common.cancel')}
                    primaryButtonClick={handleSendTest}
                    secondaryButtonClick={onClose}
                    showPrimaryButton
                    showSecondaryButton
                    primaryLeftIcon={undefined}
                    primaryRightIcon={
                        <CaretRight
                            color={
                                isSendTestDisabled
                                    ? 'rgb(var(--color-gray-600))'
                                    : 'white'
                            }
                        />
                    }
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={undefined}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    isLoading={false}
                    primaryButtonDisabled={isSendTestDisabled}
                    primaryButtonCustomClass={undefined}
                    justify="right"
                />
            </Box>
        </Modal>
    );
};

export default JourneyTest;
