import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { type FieldValueProperty } from '@components/Audience/Filters/FiltersProvider/types';
import { isTimeWindowFilterInMetricFilter } from '@components/Audience/Filters/utils';
import {
    journeyEventFieldId,
    journeyEventFieldRef,
} from '@components/Audience/utils';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { addFieldIdToMetricFilterRule } from '@components/Explorer/CustomMetricModal/utils';
import {
    FilterOperator,
    getFilterRuleWithDefaultValue,
    ReservedEventColumns,
    WarehouseTypes,
    type AdditionalMetric,
    type FilterableDimension,
    type FilterableField,
    type FilterRule,
    type MetricFilterRule,
} from '@lightdash/common';
import { Flex, Stack } from '@mantine/core';
import useProjectContext from '@providers/Project/useProjectContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { SetTimeWindowButton } from '../SetTimeWIndowButton';
import { TimeWindowFilterRule } from '../TimeWindowFilterRule';
import JourneyFilterRuleFormInAudience from './JourneyFilterRuleFormInAudience';
import {
    createFilterRuleForJourneyEvent,
    timeWindowFieldRefInJourneyEvents,
} from './utils';
interface JourneyEventFilterGroupProps {
    activeField: FilterableField | undefined;
    isEditMode: boolean;
    additionalMetrics: AdditionalMetric[];
    setAdditionalMetrics: (
        metrics: AdditionalMetric[],
        isNested: boolean,
        groupIndex: number,
    ) => void;
    tableFields: FilterableDimension[];
    groupIndex: number;
    dynamicFieldValues:
        | PropertySelectListType<FieldValueProperty>[]
        | undefined;
}
const JourneyEventFilterGroup = ({
    activeField,
    isEditMode,
    additionalMetrics,
    setAdditionalMetrics,
    tableFields,
    groupIndex,
    dynamicFieldValues,
}: JourneyEventFilterGroupProps) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData } = useProjectContext();
    const { activeRelation } = useRelationContext();
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });
    const timeWindowFieldRef = useMemo(
        () =>
            projectUuid &&
            projectData?.warehouseConnection &&
            timeWindowFieldRefInJourneyEvents(
                projectUuid,
                projectData.warehouseConnection.type,
            ),
        [projectUuid, projectData],
    );

    const isMatchingMetric = useCallback(
        (metric: AdditionalMetric): boolean => {
            return (
                metric.name === activeField?.name &&
                metric.table === activeField?.table
            );
        },
        [activeField],
    );
    const timeStampField = useMemo(() => {
        const timeStampId = journeyEventFieldId(
            projectUuid ?? '',
            ReservedEventColumns.SERVER_TIMESTAMP,
            projectData?.warehouseConnection?.type ?? WarehouseTypes.SNOWFLAKE,
        );
        return fieldsWithSuggestions[timeStampId];
    }, [projectUuid, projectData, fieldsWithSuggestions]);
    const changedAdditionalMetrics = useCallback(
        (filters: MetricFilterRule[]) => {
            const newAdditionalMetrics = additionalMetrics.map((metric) =>
                isMatchingMetric(metric) ? { ...metric, filters } : metric,
            );
            setAdditionalMetrics(newAdditionalMetrics, false, groupIndex);
        },
        [additionalMetrics, groupIndex, setAdditionalMetrics, isMatchingMetric],
    );

    const matchedAdditionalMetric = additionalMetrics.find((metric) =>
        isMatchingMetric(metric),
    );
    const journeyFilters = useMemo(
        () =>
            matchedAdditionalMetric?.filters
                ?.slice(1)
                .filter(
                    (filter) =>
                        timeWindowFieldRef &&
                        !isTimeWindowFilterInMetricFilter(
                            filter,
                            timeWindowFieldRef,
                        ),
                )
                .map(addFieldIdToMetricFilterRule),
        [matchedAdditionalMetric, timeWindowFieldRef],
    );
    const timeWindowFilter = useMemo(
        () =>
            matchedAdditionalMetric?.filters
                ?.slice(1)
                .find(
                    (filter) =>
                        timeWindowFieldRef &&
                        isTimeWindowFilterInMetricFilter(
                            filter,
                            timeWindowFieldRef,
                        ),
                ),
        [matchedAdditionalMetric, timeWindowFieldRef],
    );

    const handleTimeWindowFilterDelete = useCallback(() => {
        const filteredMetrics = (matchedAdditionalMetric?.filters ?? []).filter(
            (filter) =>
                timeWindowFieldRef &&
                !isTimeWindowFilterInMetricFilter(filter, timeWindowFieldRef),
        );
        changedAdditionalMetrics(filteredMetrics);
    }, [
        matchedAdditionalMetric?.filters,
        changedAdditionalMetrics,
        timeWindowFieldRef,
    ]);

    const handleTimeWindowFilterChange = useCallback(
        (value: FilterRule) => {
            const updatedFilters = (matchedAdditionalMetric?.filters ?? []).map(
                (filter) =>
                    timeWindowFieldRef &&
                    projectData?.warehouseConnection &&
                    isTimeWindowFilterInMetricFilter(filter, timeWindowFieldRef)
                        ? {
                              ...value,
                              target: {
                                  ...value.target,
                                  fieldRef: journeyEventFieldRef(
                                      projectUuid ?? '',
                                      ReservedEventColumns.SERVER_TIMESTAMP,
                                      projectData.warehouseConnection.type,
                                  ),
                              },
                          }
                        : filter,
            );
            changedAdditionalMetrics(updatedFilters);
        },
        [
            projectUuid,
            projectData?.warehouseConnection,
            matchedAdditionalMetric?.filters,
            changedAdditionalMetrics,
            timeWindowFieldRef,
        ],
    );
    const renderTimeWindowFilter = useMemo(() => {
        if (!timeWindowFilter) return null;
        return (
            <TimeWindowFilterRule
                showWherePrefix={!journeyFilters?.length}
                timeWindowFilter={addFieldIdToMetricFilterRule(
                    timeWindowFilter,
                )}
                fields={tableFields}
                handleTimeWindowFilterChange={handleTimeWindowFilterChange}
                handleTimeWindowFilterDelete={handleTimeWindowFilterDelete}
                isEditMode={isEditMode}
                groupIndex={groupIndex}
                additionalMetrics={additionalMetrics}
                dynamicFieldValues={dynamicFieldValues}
            />
        );
    }, [
        timeWindowFilter,
        tableFields,
        isEditMode,
        handleTimeWindowFilterChange,
        handleTimeWindowFilterDelete,
        journeyFilters?.length,
        additionalMetrics,
        dynamicFieldValues,
        groupIndex,
    ]);
    const renderJourneyFilterForm = useMemo(() => {
        if (!activeField) return null;
        return (
            <JourneyFilterRuleFormInAudience
                journeyFilters={journeyFilters ?? []}
                activeField={activeField}
                isEditMode={isEditMode}
                additionalMetrics={additionalMetrics}
                setAdditionalMetrics={setAdditionalMetrics}
                groupIndex={groupIndex}
            />
        );
    }, [
        journeyFilters,
        activeField,
        isEditMode,
        additionalMetrics,
        setAdditionalMetrics,
        groupIndex,
    ]);
    const handleSetTimeWindow = useCallback(() => {
        if (!projectData || !projectData.warehouseConnection) return;
        const newTimeWindowFilter = getFilterRuleWithDefaultValue(
            timeStampField,
            createFilterRuleForJourneyEvent({
                dimension: ReservedEventColumns.SERVER_TIMESTAMP,
                projectUuid: projectUuid ?? '',
                operator: FilterOperator.EQUALS,
                value: '',
                warehouseType: projectData.warehouseConnection.type,
            }),
            undefined,
        );

        const newFilters = [
            ...(matchedAdditionalMetric?.filters ?? []),
            {
                ...newTimeWindowFilter,
                target: {
                    fieldRef: journeyEventFieldRef(
                        projectUuid ?? '',
                        ReservedEventColumns.SERVER_TIMESTAMP,
                        projectData.warehouseConnection.type,
                    ),
                },
            },
        ];
        changedAdditionalMetrics(newFilters);
    }, [
        projectUuid,
        projectData,
        changedAdditionalMetrics,
        matchedAdditionalMetric?.filters,
        timeStampField,
    ]);
    const renderTimeWindowButton = () => (
        <SetTimeWindowButton onClick={handleSetTimeWindow} />
    );

    if (!activeField) return null;

    return (
        <Stack className="gap-2 pl-4 mb-4 ml-4 border-l-4 border-shade-4">
            {(journeyFilters?.length ?? 0) > 0 && (
                <>
                    <Flex align="center" gap={8}>
                        {renderJourneyFilterForm}
                    </Flex>
                    {!timeWindowFilter && renderTimeWindowButton()}
                </>
            )}

            {timeWindowFilter && renderTimeWindowFilter}

            {(journeyFilters?.length ?? 0) === 0 && (
                <Flex align="center" gap={8}>
                    {renderJourneyFilterForm}
                    {!timeWindowFilter && renderTimeWindowButton()}
                </Flex>
            )}
        </Stack>
    );
};

export default JourneyEventFilterGroup;
