import HiddenButton, { type HideProps } from '@components/Hidden/HiddenButton';
import Manager from '@components/Manager';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceBuilderHashParams,
    AudienceStatus,
    JoinType,
    QueryGenerationStrategy,
    ShowDataType,
    type Audience,
    type Pagination,
} from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import useRelationContext from '@providers/Relation/useRelationContext';
import { type SortingState } from '@tanstack/react-table';
import React, {
    useCallback,
    useMemo,
    useState,
    type Dispatch,
    type SetStateAction,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { getCustomDimensionsFromActiveRelation } from '../Filters/FilterInputs/utils';
import { useFieldsWithSuggestions } from '../Filters/FiltersCard/useFieldsWithSuggestions';
import SchedulersModal from '../Scheduler/SchedulerModal';
import { useAudienceColumns } from './useAudienceTableUtils';
type AudienceTableProps = HideProps & {
    audiences: Audience[] | null | undefined;
    pagination: Pagination;
    onPageChange: (page: number) => void;
    onSearchChange: (newSearchString: string) => void;
    searchValue: string;
    sorting: SortingState;
    setSorting: Dispatch<SetStateAction<SortingState>>;
    isDataFetching: boolean;
};

const AudienceTable: React.FC<AudienceTableProps> = ({
    audiences,
    pagination,
    onPageChange,
    onSearchChange,
    searchValue,
    isHiddenActive,
    onHiddenChange,
    hideHiddenButton,
    sorting,
    setSorting,
    isDataFetching,
}) => {
    const [modalOpen, { open: setModalOpen, close: setModalClose }] =
        useDisclosure(false);
    const { activeRelation, getTableRelation } = useRelationContext();
    const [audienceData, setAudienceData] = useState<Audience>();
    const [isNeedToRefresh, setIsNeedToRefresh] = useState<boolean>(true);
    const handeMenuClick = useCallback(() => {
        setIsNeedToRefresh(true);
        onPageChange(1);
    }, [onPageChange, setIsNeedToRefresh]);
    const filteredRelation = getTableRelation([
        JoinType.one_one,
        JoinType.many_one,
    ]);

    const customDimensions = useMemo(
        () =>
            getCustomDimensionsFromActiveRelation(
                activeRelation,
                filteredRelation?.map((table) => table.name) ?? [],
            ),
        [activeRelation, filteredRelation],
    );
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });
    const audienceColumns = useAudienceColumns({
        setModalOpen,
        setAudienceData,
        onRowChange: handeMenuClick,
        fieldsWithSuggestions,
        customDimensions,
    });
    const navigate = useNavigate();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    return (
        <div>
            {audiences && audienceColumns && (
                <Manager<Audience>
                    name={t('audiences')}
                    tableData={audiences}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: audienceColumns,
                        },
                    ]}
                    searchPlaceholder={t(
                        'audience_manager.table_search_placeholder',
                    )}
                    paginationText={t('audience_manager.pagination_text')}
                    isManualPagination={true}
                    onPageChange={onPageChange}
                    onSearchChange={(value) => {
                        onSearchChange(value);
                        setIsNeedToRefresh(true);
                    }}
                    pagination={pagination}
                    isEditable={false}
                    handleRowClick={(row) => {
                        void navigate(
                            `/projects/${projectUuid}/audiences/${
                                row.original.id
                            }/${
                                row.original.status === AudienceStatus.DRAFT
                                    ? 'edit'
                                    : 'view'
                            }#${
                                row.original.generationStrategy ===
                                QueryGenerationStrategy.AUDIENCE_BUILDER
                                    ? AudienceBuilderHashParams.VISUAL
                                    : AudienceBuilderHashParams.SQL
                            }`,
                        );
                    }}
                    searchValue={searchValue}
                    leftSection={
                        !hideHiddenButton && (
                            <HiddenButton
                                isHiddenActive={isHiddenActive}
                                onHiddenChange={(value) => {
                                    onHiddenChange(value);
                                    setIsNeedToRefresh(true);
                                }}
                                activeHideText={t(
                                    'audience_manager.active_hide_button_text',
                                )}
                            />
                        )
                    }
                    isNeedToRefresh={isNeedToRefresh}
                    onNeedToRefresh={setIsNeedToRefresh}
                    sorting={sorting}
                    setSorting={(updater) => {
                        setSorting(updater);
                        onPageChange(1);
                        setIsNeedToRefresh(true);
                    }}
                    isDataFetching={isDataFetching}
                />
            )}
            <SchedulersModal
                isOpen={modalOpen}
                onClose={setModalClose}
                audienceData={audienceData}
                handleMenuClick={handeMenuClick}
            />
        </div>
    );
};

export default React.memo(AudienceTable);
