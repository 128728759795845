import { useStreamingText } from '@hooks/useStreamingText';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';
import { useEffect, useState } from 'react';
import { Markdown } from './Markdown';
export const StreamingText: React.FC<{
    stream: boolean;
    html: string;
    streamedMessages: number[];
    setStreamedMessages: (value: number[]) => void;
    index: number;
    messageId: string;
}> = ({ stream, html, messageId }) => {
    const { addStreamedMessageId } = useAiAnalystContext(
        (context) => context.actions,
    );
    const { streamedMessageIds } = useAiAnalystContext(
        (context) => context.state,
    );

    const [streamCurrent, setStreamCurrent] = useState(false);

    const streamedText = useStreamingText(html, 20);

    const isAlreadyStreamed = streamedMessageIds.includes(messageId);

    useEffect(() => {
        if (isAlreadyStreamed) return;
        if (stream) {
            addStreamedMessageId(messageId);
            setStreamCurrent(true);
        }
    }, [
        stream,
        streamedText,
        html,
        isAlreadyStreamed,
        addStreamedMessageId,
        messageId,
    ]);

    return <Markdown>{streamCurrent ? streamedText : html}</Markdown>;
};

export default StreamingText;
