import { useLocale } from '@hooks/useLocale';
import { FieldType } from '@lightdash/common';
import { Box, Button, Flex, Text } from '@mantine/core';
import { Check, Function } from '@phosphor-icons/react';

import { ButtonVariant } from '../../../mantineTheme';
interface ManualModeInTraitModalProps {
    aiThinking: boolean;
    handleFieldTypeChange: (fieldType: FieldType) => void;
    fieldType: FieldType | undefined;
}

const ManualModeInTraitModal: React.FC<ManualModeInTraitModalProps> = ({
    aiThinking,
    handleFieldTypeChange,
    fieldType,
}) => {
    const { t } = useLocale();
    return (
        <>
            <Box className=" ">
                <Text className="text-gray-800 font-medium">
                    {t(
                        'custom_metric.create_new_trait_modal.select_field_type_title',
                    )}
                </Text>
            </Box>

            <Flex className=" gap-2">
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={() => handleFieldTypeChange(FieldType.METRIC)}
                    disabled={aiThinking}
                    className={`${
                        fieldType === FieldType.METRIC
                            ? '!bg-gray-200 hover:!bg-gray-200'
                            : 'cursor-pointer'
                    }`}
                    rightIcon={
                        fieldType === FieldType.METRIC ? (
                            <Check weight="bold" />
                        ) : undefined
                    }
                    leftIcon={<Function weight="duotone" />}
                >
                    {t('custom_metric.create_new_trait_modal.metric')}
                </Button>
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={() => handleFieldTypeChange(FieldType.DIMENSION)}
                    disabled={aiThinking}
                    className={`${
                        fieldType === FieldType.DIMENSION
                            ? '!bg-gray-200 hover:!bg-gray-200'
                            : 'cursor-pointer'
                    }`}
                    rightIcon={
                        fieldType === FieldType.DIMENSION ? (
                            <Check weight="bold" />
                        ) : undefined
                    }
                    leftIcon={<Function weight="duotone" />}
                >
                    {t('custom_metric.create_new_trait_modal.dimension')}
                </Button>
            </Flex>
        </>
    );
};

export default ManualModeInTraitModal;
