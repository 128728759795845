import AiChart from '@components/AIWidget/AiChart';
import ErrorMessage from '@components/AnalystChat/ErrorMessage';

import { useAiAnalystFeedback } from '@hooks/useAiAnalyst';
import { useLocale } from '@hooks/useLocale';
import {
    AIMessageContext,
    AiMessageTypes,
    Author,
    type AgentMessage,
    type Chart,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import { Sparkle, ThumbsDown, ThumbsUp } from '@phosphor-icons/react';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';
import { motion } from 'framer-motion';
import { memo, useCallback, useMemo, useState } from 'react';
import StreamingText from './StreamingText';

const PurePreviewMessage = ({
    message,
    index,
    isLoading,
}: {
    chatId: string;
    message: AgentMessage;
    isLoading: boolean;
    index: number;
}) => {
    const { mutateAsync: mutateAsyncAnalystFeedback } = useAiAnalystFeedback();
    const { aiChatMessages, threadId, userFeedback } = useAiAnalystContext(
        (context) => context.state,
    );
    const { setUserFeedback } = useAiAnalystContext(
        (context) => context.actions,
    );
    const [streamedMessages, setStreamedMessages] = useState<number[]>([]);

    const handleFeedbackClick = useCallback(
        async (messageId: string, sentiment: boolean, traceId: string) => {
            setUserFeedback({
                [messageId]: sentiment,
            });
            await mutateAsyncAnalystFeedback({
                threadId,
                messageId,
                sentiment,
                metadata: {},
                traceId,
            });
        },
        [mutateAsyncAnalystFeedback, setUserFeedback, threadId],
    );

    const renderFeedback = useCallback(
        (item: AgentMessage) => {
            if (item.author !== Author.SYSTEM) return null;
            return (
                <Box className="flex items-center gap-2 mt-2">
                    {!(item.messageId in userFeedback) && (
                        <Box className="flex items-center gap-2">
                            <ThumbsUp
                                size={14}
                                weight="duotone"
                                className="text-gray-700 cursor-pointer hover:text-gray-800"
                                onClick={() =>
                                    handleFeedbackClick(
                                        item.messageId,
                                        true,
                                        item.traceId,
                                    )
                                }
                            />
                            <ThumbsDown
                                size={14}
                                weight="duotone"
                                className="text-gray-700 cursor-pointer hover:text-gray-800"
                                onClick={() =>
                                    handleFeedbackClick(
                                        item.messageId,
                                        false,
                                        item.traceId,
                                    )
                                }
                            />
                        </Box>
                    )}
                    {item.messageId in userFeedback &&
                        (userFeedback[item.messageId] ? (
                            <ThumbsUp
                                size={14}
                                weight="duotone"
                                className="text-gray-500"
                            />
                        ) : (
                            <ThumbsDown
                                size={14}
                                weight="duotone"
                                className="text-gray-500"
                            />
                        ))}
                </Box>
            );
        },
        [handleFeedbackClick, userFeedback],
    );

    const renderedMessage = useMemo(() => {
        if (message.content.type === AiMessageTypes.ERROR) {
            return <ErrorMessage message={message.content.value} />;
        }

        if (
            message.context === AIMessageContext.CHART &&
            (message.content.value as Chart).context
        ) {
            return (
                <AiChart
                    metricQuery={(message.content.value as Chart).metricQuery}
                    chartConfig={(message.content.value as Chart).chartConfig}
                />
            );
        }
        return (
            <>
                <StreamingText
                    stream={message.author === Author.SYSTEM}
                    streamedMessages={streamedMessages}
                    setStreamedMessages={setStreamedMessages}
                    index={index}
                    html={message.content.value as string}
                    messageId={message.messageId}
                />
                {!isLoading &&
                    index === aiChatMessages.length - 1 &&
                    renderFeedback(message)}
            </>
        );
    }, [
        index,
        message,
        streamedMessages,
        aiChatMessages,
        isLoading,
        renderFeedback,
    ]);

    return (
        <motion.div
            className="w-full max-w-3xl px-4 mx-auto group/message"
            initial={{ y: 5, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            data-role={message.author}
        >
            <Box
                className={`flex gap-4 w-full group-data-[role=user]/message:ml-auto group-data-[role=user]/message:max-w-2xl group-data-[role=user]/message:w-fit`}
            >
                <Box className="flex flex-col w-full gap-2">
                    <Box className="flex flex-row items-start gap-2">
                        <Box
                            className={`flex flex-col gap-4 w-full ${
                                message.author === 'user'
                                    ? 'bg-blu-800 text-white px-3 py-2 rounded-xl'
                                    : 'text-white'
                            }`}
                        >
                            {renderedMessage}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </motion.div>
    );
};

export const PreviewMessage = memo(
    PurePreviewMessage,
    (prevProps, nextProps) => {
        if (prevProps.isLoading !== nextProps.isLoading) return false;
        if (prevProps.message.content.value !== nextProps.message.content.value)
            return false;
        return true;
    },
);

interface ThinkingMessageProps {
    label: string | undefined;
}

export const ThinkingMessage = ({ label }: ThinkingMessageProps) => {
    const role = Author.SYSTEM;
    const { t } = useLocale();
    return (
        <motion.div
            className="w-full max-w-3xl px-4 mx-auto group/message "
            initial={{ y: 5, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 1 } }}
            data-role={role}
        >
            <Box className="flex items-center gap-4 group-data-[role=user]/message:px-3 w-full group-data-[role=user]/message:w-fit group-data-[role=user]/message:ml-auto group-data-[role=user]/message:max-w-2xl group-data-[role=user]/message:py-2 rounded-xl group-data-[role=user]/message:bg-muted">
                <Box className="flex items-center justify-center rounded-full size-8 ring-1 shrink-0 ring-border">
                    <Sparkle size={14} />
                </Box>

                <Box className="flex flex-col w-full gap-2">
                    <Box className="flex flex-col gap-4 text-gray-600">
                        {label || t('chat.thinking')}
                    </Box>
                </Box>
            </Box>
        </motion.div>
    );
};
