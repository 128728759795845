import {
    type ActionType,
    type CommunicationChannel,
    type JourneyGroup,
    type JourneyNode,
} from '@lightdash/common';

export enum JourneyBuilderMode {
    CREATE = 'create',
    EDIT = 'edit',
    VIEW = 'view',
}

export enum JourneyBlock {}

export interface JourneyGroupConfig {
    title: string;
    description: string | undefined;
    iconColor: string | undefined;
}

export type JourneyGroupConfigType = Record<JourneyGroup, JourneyGroupConfig>;

export enum ControlPanel {
    'TRIGGERS' = 'triggers',
    'TRIGGER_CONFIG' = 'trigger_config',
    'BLOCKS_LIST' = 'blocks_list',
    'BLOCK_CONFIG' = 'block_config',
}

interface ControlPanelBlockState {
    type: ControlPanel.BLOCK_CONFIG;
    nodeId: string;
}

interface ControlPanelTriggerState {
    type: ControlPanel.TRIGGER_CONFIG;
    triggerId: string;
}

export type ControlPanelBlocksListState = {
    type: ControlPanel.BLOCKS_LIST;
    reactFlowNodeId: string; //Info: this is the id of the placeholder node or the id of the react flow node where the block is being added
};

interface ControlPanelNonBlockState {
    type: ControlPanel.TRIGGERS;
}

export type ControlPanelState =
    | ControlPanelNonBlockState
    | ControlPanelBlockState
    | ControlPanelTriggerState
    | ControlPanelBlocksListState;

export interface JourneyBlockConfig {
    getIcon: (color: string) => React.ReactNode;
}

export type JourneyBlockConfigType = Record<ActionType, JourneyBlockConfig>;

export enum JourneyNodeEnum {
    'PLACEHOLDER' = 'PLACEHOLDER',
    'BLOCK' = 'BLOCK',
    'TRIGGER' = 'TRIGGER',
    'GHOSTNODE' = 'GHOSTNODE',
    'EVERY_ONE_ELSE' = 'EVERY_ONE_ELSE',
    'CIRCUIT' = 'CIRCUIT',
}

export enum JourneyEdgeEnum {
    'BLOCK' = 'BLOCK',
    'BRANCHING' = 'BRANCHING',
    'DEFAULT' = 'DEFAULT',
}

interface JourneyNodeBase {
    isExperiment?: boolean;
}

interface JourneyNodeBlockBase extends JourneyNodeBase {
    nodeId: string;
    blockId: string;
}
export interface JourneyNodeTriggerData extends JourneyNodeBlockBase {
    type: JourneyNodeEnum.TRIGGER;
}

export interface JourneyNodePlaceholderData extends JourneyNodeBase {
    type: JourneyNodeEnum.PLACEHOLDER;
    placeHolderType: JourneyNodeEnum.TRIGGER | JourneyNodeEnum.BLOCK;
}
export interface JourneyNodeGhostData extends JourneyNodeBase {
    type: JourneyNodeEnum.GHOSTNODE;
    nodeId: string;
    isVisible?: boolean;
}

export interface JourneyNodeBlockData extends JourneyNodeBlockBase {
    type: JourneyNodeEnum.BLOCK;
}

export interface JourneyNodeEveryOneElseData extends JourneyNodeBase {
    type: JourneyNodeEnum.EVERY_ONE_ELSE;
    nodeId: string;
}

export interface JourneyNodeCircuitData extends JourneyNodeBase {
    type: JourneyNodeEnum.CIRCUIT;
    nodeId: string;
}

export type JourneyNodeData =
    | JourneyNodePlaceholderData
    | JourneyNodeBlockData
    | JourneyNodeTriggerData
    | JourneyNodeGhostData
    | JourneyNodeEveryOneElseData
    | JourneyNodeCircuitData;

export type JourneyNodeUpdatePayload = {
    nodeId: string;
    nodePayload: Partial<Pick<JourneyNode, 'title' | 'description'>>;
};

export type JourneyEventFilterConfig = {
    eventName: string;
    eventSource: string;
    eventChannel: CommunicationChannel | undefined;
};

export enum TriggerType {
    USER_ACTION = 'user_action',
    BUSINESS_EVENT = 'business_event',
    SCHEDULED = 'scheduled',
}
