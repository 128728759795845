import { useLocale } from '@hooks/useLocale';
import { CommunicationChannel, type SupressionConfig } from '@lightdash/common';
import { Button, Group } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import { set } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
    useCreateSuppressionConfig,
    useGetSuppressionConfig,
    useSetSuppressionConfig,
} from '../../../../hooks/useDeliveryControlsApi';
import { ButtonVariant } from '../../../../mantineTheme';
import Card from '../Card';
import SuppressionRule from './SuppressionRule';

const SuppressionRules = () => {
    const { t } = useLocale();

    const [inputMode, setInputMode] = useState<boolean>(false);
    const [suppressionRules, setSuppressionRules] = useState<
        SupressionConfig['config']['mappings']
    >([]);
    const { data } = useGetSuppressionConfig({ enabled: true });
    const {
        mutateAsync: setSuppressionConfig,
        isLoading: isUpdatingSuppressionConfig,
    } = useSetSuppressionConfig();
    const {
        mutateAsync: createSuppressionConfig,
        isLoading: isCreatingSuppressionConfig,
    } = useCreateSuppressionConfig();

    const setInitialState = useCallback(() => {
        if (data?.config.mappings) {
            setSuppressionRules([...data.config.mappings]);
        } else {
            setSuppressionRules([]);
        }
    }, [data]);

    useEffect(() => {
        setInitialState();
    }, [data, setInitialState]);

    const handleCancel = () => {
        setInputMode(false);
        setInitialState();
    };

    const handleEdit = () => {
        setInputMode(true);
    };

    const handleSave = () => {
        if (data?.config.mappings?.length) {
            setSuppressionConfig({
                config: {
                    mappings: suppressionRules,
                },
            })
                .then(() => {
                    setInputMode(false);
                })
                .catch(() => {});
        } else {
            createSuppressionConfig({
                config: {
                    mappings: suppressionRules,
                },
            })
                .then(() => {
                    setInputMode(false);
                })
                .catch(() => {});
        }
    };

    const handleInputChange = (
        value: string | null,
        type: string,
        index: number,
    ) => {
        const newRules = [...suppressionRules];
        const newRule = { ...suppressionRules[index] };
        newRules.splice(index, 1, {
            ...set(newRule, type, value),
        });
        setSuppressionRules([...newRules]);
    };

    const handleDelete = (index: number) => {
        const newRules = [...suppressionRules];
        newRules.splice(index, 1);
        setSuppressionRules([...newRules]);
    };

    const handleAddRule = () => {
        if (!inputMode) {
            setInputMode(true);
        }
        setSuppressionRules([
            ...suppressionRules,
            {
                channel: CommunicationChannel.ANY,
                audienceId: '',
            },
        ]);
    };

    return (
        <Card
            inputMode={inputMode}
            handleCancel={handleCancel}
            handleEdit={handleEdit}
            handleSave={handleSave}
            isUpdating={
                isUpdatingSuppressionConfig || isCreatingSuppressionConfig
            }
            isEditAllowed={!!suppressionRules.length}
            headerLabel={t(
                'workspace_settings.delivery_controls.suppression_rules.title',
            )}
            headerDescription={t(
                'workspace_settings.delivery_controls.suppression_rules.subtitle',
            )}
        >
            <Group align="center" spacing={0} className="mb-3">
                {suppressionRules.map((rule, index) => (
                    <SuppressionRule
                        readOnly={!inputMode}
                        key={`${rule.audienceId}-${rule.channel}-${index}`}
                        index={index}
                        onChange={handleInputChange}
                        value={rule}
                        onDelete={handleDelete}
                        inputMode={inputMode}
                        isLastItem={index === suppressionRules.length - 1}
                    />
                ))}
            </Group>
            {(!suppressionRules.length || inputMode) && (
                <Button
                    onClick={handleAddRule}
                    variant={ButtonVariant.OUTLINED_ACCENTED}
                    leftIcon={
                        <PlusCircle
                            size={14}
                            color="rgb(var(--color-blu-800))"
                        />
                    }
                >
                    {t(
                        'workspace_settings.delivery_controls.frequency_capping_button',
                    )}
                </Button>
            )}
        </Card>
    );
};

export default SuppressionRules;
