import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { getFieldFromCustomDimensionsAndFields } from '@components/Audience/Filters/utils';
import Modal from '@components/common/modal/Modal';
import DataTable from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import { ShowDataType } from '@lightdash/common';
import { Box } from '@mantine/core';
import useRelationContext from '@providers/Relation/useRelationContext';
import useSchemaContext from '@providers/Schema/useSchemaContext';
import { type Cell } from '@tanstack/react-table';
import { CSV_SCHEMA_PREVIEW_LIMIT } from '@utils/constants';
import React, { useEffect, useState } from 'react';

type CsvAudiencePreviewModalProps = {
    open: boolean;
    onClose: () => void;
    fileName: string | undefined;
    count: number | undefined;
    schemaCsvData: unknown[];
};

const SchemaPreviewModal: React.FC<CsvAudiencePreviewModalProps> = ({
    open,
    onClose,
    fileName,
    count,
    schemaCsvData,
}) => {
    const { isEditMode } = useSchemaContext((context) => context.state);
    const { t } = useLocale();
    const [columns, setColumns] = useState<any[]>([]);
    const { activeRelation, customDimensionsFields } = useRelationContext();

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: false,
    });

    useEffect(() => {
        if (schemaCsvData && schemaCsvData.length > 0) {
            const headers = Object.keys(
                schemaCsvData[0] as Record<string, unknown>,
            );
            const data = headers.map((header) => {
                const field = getFieldFromCustomDimensionsAndFields(
                    header,
                    fieldsWithSuggestions,
                    customDimensionsFields,
                );
                return {
                    accessorKey: header,
                    header: !isEditMode ? header : field?.name,
                    cell: (props: Cell<string, string>) => {
                        if (props.getValue())
                            return <Box>{props.getValue()}</Box>;
                        return <Box>{`-`}</Box>;
                    },
                    size: 10,
                };
            });
            setColumns([...data]);
        }
    }, [
        customDimensionsFields,
        fieldsWithSuggestions,
        schemaCsvData,
        isEditMode,
    ]);

    return (
        <Modal
            opened={open}
            onClose={onClose}
            size={`calc(100vw - 200px)`}
            title={t('schema_preview_modal.title', { fileName })}
        >
            {schemaCsvData && (
                <Box>
                    <DataTable<unknown>
                        tableData={schemaCsvData}
                        options={[
                            {
                                format: ShowDataType.LIST,
                                formatData: columns,
                            },
                        ]}
                        isManualPagination={false}
                        paginationText={t('audience_manager.pagination_text')}
                        withBorder={true}
                        customClass={`overflow-scroll`}
                        showSearch={false}
                        isEditable={false}
                        totalResultCount={count}
                        previewLimit={CSV_SCHEMA_PREVIEW_LIMIT}
                    />
                </Box>
            )}
        </Modal>
    );
};

export default SchemaPreviewModal;
