import TextInput from '@components/common/Inputs/TextInput';
import {
    type ContentMappings,
    type ContentMappingSection,
    type Variable,
} from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import { capitalize } from 'lodash';
import React from 'react';

type VariableSectionProps = {
    data: { [key: string]: Variable } | undefined;
    value: { [key: string]: Variable } | undefined;
    header: string;
    onChange: (
        value: string,
        variable: string,
        section: ContentMappingSection,
    ) => void;
    section: ContentMappingSection;
};

type VariableDefaultsProps = {
    variables: ContentMappings;
    mappings: ContentMappings;
    handleChange: (
        value: string,
        variable: string,
        section: ContentMappingSection,
    ) => void;
};

export const VariableSection = ({
    data,
    header,
    onChange,
    section,
    value,
}: VariableSectionProps) => {
    if (!data) return null;

    return (
        <Box>
            <Text className="text-gray-600 mb-2">{capitalize(header)}</Text>
            {Object.entries(data).map(([variable, variableMapping]) => (
                <Box key={variable} className="mb-3">
                    <TextInput
                        label={`{{${variable}}}`}
                        value={value?.[variable].defaultValue}
                        defaultValue={variableMapping.defaultValue}
                        placeholder="Enter a sample value"
                        onChange={(e) =>
                            onChange(e.target.value, variable, section)
                        }
                    />
                </Box>
            ))}
        </Box>
    );
};

const VariableDefaults = ({
    variables,
    mappings,
    handleChange,
}: VariableDefaultsProps) => {
    return (
        <Box>
            <Text className="text-gray-800 font-semibold mb-3">
                Enter sample values to be used for now
            </Text>
            <Box>
                {Object.keys(variables).map((section) => (
                    <VariableSection
                        key={section}
                        header={`${section} variables`}
                        data={variables?.[section as ContentMappingSection]}
                        onChange={handleChange}
                        section={section as ContentMappingSection}
                        value={mappings[section as ContentMappingSection]}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default VariableDefaults;
