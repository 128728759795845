import DataTable from '@components/Table';
import useNotify from '@hooks/toaster/useNotify';
import { useIsTruncated } from '@hooks/useIsTruncated';
import { useLocale } from '@hooks/useLocale';
import { useRefreshDimensions } from '@hooks/useRelation';
import {
    useFetchRelations,
    useRelationTable,
    useUpdateTableDetail,
    useUpsertCatalogFileToRelation,
} from '@hooks/useSchemaBuilder';
import {
    DimensionType,
    FileUploadFormat,
    RelationTableType,
    ShowDataType,
    Timezones,
    type AnyType,
    type FileTableColumn,
    type RelationSchemaColumn,
} from '@lightdash/common';
import { Box, Button, Flex, Text, TextInput, Tooltip } from '@mantine/core';
import {
    ArrowsCounterClockwise,
    CaretLeft,
    CaretRight,
} from '@phosphor-icons/react';
import useProjectContext from '@providers/Project/useProjectContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import useSchemaContext from '@providers/Schema/useSchemaContext';
import { useQueryClient } from '@tanstack/react-query';
import Fuse from 'fuse.js';
import { t as translate } from 'i18next';
import { capitalize, debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../../../mantineTheme';
import { SchemaBuilderStep, SchemaFileType } from '../../types';
import { useSchemaTableColumns } from './useSchemaTableUtils';

export const InputBox = ({
    defaultValue,
    onChange,
    width = 250,
    placeholder = translate('common.click_to_add', { value: 'description' }),
    ...rest
}: {
    defaultValue: string;
    onChange: (value: string) => void;
    width?: number;
    placeholder?: string;
}) => {
    const { ref, isTruncated } = useIsTruncated();
    const [value, setValue] = useState<string>(defaultValue);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    if (!isFocused) {
        return (
            <Tooltip label={value} disabled={!isTruncated}>
                <Box onClick={() => setIsFocused(true)}>
                    <Text
                        ref={ref}
                        className={
                            value
                                ? `max-w-[${width}px] truncate`
                                : 'text-gray-500'
                        }
                    >
                        {value || placeholder}
                    </Text>
                </Box>
            </Tooltip>
        );
    }

    return (
        <TextInput
            onBlur={() => setIsFocused(false)}
            autoFocus
            defaultValue={value}
            value={value}
            onChange={(e) => {
                setValue(e.currentTarget.value);
                onChange(e.currentTarget.value);
            }}
            placeholder={placeholder}
            sx={{
                input: {
                    border: 0,
                    boxShadow: 'none',
                    padding: 0,

                    '&:focus-within': {
                        border: 0,
                        boxShadow: 'none',
                    },
                    maxWidth: width,
                },
            }}
            {...rest}
        />
    );
};

const TableProperties: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { schemaPayload, currentBuilderStep, activeProject } =
        useSchemaContext((context) => context.state);
    const { activeRelationUuid } = useRelationContext();
    const { showToastSuccess } = useNotify();
    const { mutateAsync: updateTableDetail, isLoading: isUpdatingTableDetail } =
        useUpdateTableDetail(activeRelationUuid);
    const queryClient = useQueryClient();
    const { mutate } = useRefreshDimensions();
    const { projectData } = useProjectContext();

    const [searchDebounce, setSearchDebounce] = useState<string>('');
    const { schemaFileType } = useSchemaContext((context) => context.state);
    const { updateSchemaPayload, setCurrentBuilderStep, toggleDrawer } =
        useSchemaContext((context) => context.actions);

    const { data: relations = [] } =
        useFetchRelations(activeProject?.projectUuid) ?? {};
    const { mutateAsync: createRelationTable, isLoading: isCreatingRelation } =
        useRelationTable(
            relations[0]?.uuid,
            !activeProject?.isBaseTableConfigured,
        );

    const {
        mutateAsync: upsertCatalogFileToRelation,
        isLoading: isUpsertingCatalogFile,
    } = useUpsertCatalogFileToRelation();

    const { isEditMode } = useSchemaContext((context) => context.state);

    const lastSyncedAt = useMemo(() => {
        const col = schemaPayload?.columns?.find(
            (column: { lastSyncedAt: Date }) => column.lastSyncedAt,
        );
        if (col) {
            return moment(col.lastSyncedAt)
                .tz(projectData?.timezone ?? Timezones.UTC)
                .format('HH:MM a');
        }
        return '';
    }, [schemaPayload, projectData]);

    const handleUpdate = useCallback(
        async ({ name, ...rest }: { name: string; [key: string]: any }) => {
            if (!isEditMode) {
                updateSchemaPayload({
                    ...schemaPayload,
                    columns: schemaPayload.columns.map(
                        (column: FileTableColumn) =>
                            column.name === name
                                ? { ...column, ...rest }
                                : column,
                    ),
                });
                return;
            }
            await updateTableDetail({
                tableName: schemaPayload.name,
                payload: {
                    type: schemaPayload.type,
                    dimensions: {
                        [name]: { ...rest },
                    },
                },
            });

            await queryClient.invalidateQueries([
                QueryKeys.RELATION_SCHEMA,
                projectUuid,
                activeRelationUuid,
            ]);
        },
        [
            activeRelationUuid,
            isEditMode,
            projectUuid,
            queryClient,
            schemaPayload,
            updateSchemaPayload,
            updateTableDetail,
        ],
    );

    const onSearchChange = (newSearchString: string) => {
        setSearchDebounce(newSearchString);
    };

    const debouncedHandleUpdate = debounce(async (row, key, value) => {
        await handleUpdate({ name: row.name, [key]: value });
    }, 1000);

    const handleChange = useCallback(debouncedHandleUpdate, [
        debouncedHandleUpdate,
    ]);

    const handleColumns = useCallback((columns: FileTableColumn[]) => {
        return columns.map((column: FileTableColumn) => ({
            name: column.name ?? '',
            dataType: column.dataType ?? DimensionType.STRING,
            nullable: column.nullable ?? false,
            label: column.label ?? '',
            description: column.description ?? '',
            cached: column.cached ?? false,
            cachedColumnValues: column.cachedColumnValues ?? [],
            lastSyncedAt: column.lastSyncedAt ?? null,
            masked: column.masked ?? false,
            hidden: column.hidden ?? false,
        }));
    }, []);

    const handleClick = useCallback(async () => {
        if (!isEditMode) {
            const { columns, ...schemaPayloadWithoutColumns } = schemaPayload;
            if (schemaFileType === SchemaFileType.CSV) {
                await upsertCatalogFileToRelation({
                    blobFilePath: schemaPayload.blobFilePath,
                    tableName: schemaPayload.name,
                    columns: handleColumns(columns),
                    fileFormat: FileUploadFormat.CSV,
                    description: schemaPayload.description,
                    label: schemaPayload.label,
                    primaryKey: schemaPayload.primaryKey,
                    userUploadedTable: schemaFileType === SchemaFileType.CSV,
                    tableType: schemaPayload.type,
                });
            }
            if (schemaFileType === SchemaFileType.WAREHOUSE) {
                await createRelationTable({
                    payload: {
                        ...schemaPayloadWithoutColumns,
                        dimensions: columns,
                    },
                });
                showToastSuccess({
                    title: t(
                        'relation_provider.relation_created_successfully',
                        {
                            type: capitalize(schemaPayload.type),
                        },
                    ),
                });
            }
            const dimensions = schemaPayload.columns.reduce(
                (
                    acc: Record<string, AnyType>,
                    column: RelationSchemaColumn,
                ) => {
                    const { name, ...rest } = column;
                    acc[name] = rest;
                    return acc;
                },
                {},
            );

            await updateTableDetail({
                tableName: schemaPayload.name,
                payload: {
                    type: schemaPayload.type,
                    dimensions: dimensions,
                },
            });
            setCurrentBuilderStep(SchemaBuilderStep.PERSONALISE);
            toggleDrawer();

            await queryClient.invalidateQueries([
                QueryKeys.RELATION_SCHEMA,
                projectUuid,
                activeRelationUuid,
            ]);
        }
    }, [
        activeRelationUuid,
        createRelationTable,
        handleColumns,
        isEditMode,
        projectUuid,
        queryClient,
        schemaFileType,
        schemaPayload,
        setCurrentBuilderStep,
        showToastSuccess,
        t,
        toggleDrawer,
        updateTableDetail,
        upsertCatalogFileToRelation,
    ]);

    const onToggle = async (row: AnyType, field: string) => {
        await handleUpdate({ name: row.name, [field]: row[field] });
        if (field === 'masked' && row?.cached === true) {
            await handleUpdate({ name: row.name, ['cached']: false });
        } else if (field === 'cached' && row?.masked === true) {
            await handleUpdate({ name: row.name, ['masked']: false });
        }
    };

    const filterItems = useMemo(() => {
        let fieldsData = schemaPayload.columns;

        if (searchDebounce !== '' && fieldsData) {
            const fuse = new Fuse(fieldsData, {
                keys: ['name'],
                threshold: 0.3,
            });

            fieldsData = fuse.search(searchDebounce).map((res) => res.item);
        }

        return fieldsData;
    }, [schemaPayload.columns, searchDebounce]);

    const columns = useSchemaTableColumns({
        onToggle,
        handleUpdate,
        handleChange,
        schemaFileType,
    });

    useEffect(() => {
        if (
            (!schemaPayload.database ||
                !schemaPayload.schema ||
                !schemaPayload.name) &&
            schemaFileType === SchemaFileType.WAREHOUSE
        ) {
            setCurrentBuilderStep(SchemaBuilderStep.SETUP);
        }
        if (
            schemaFileType === SchemaFileType.CSV &&
            !schemaPayload.blobFilePath
        ) {
            setCurrentBuilderStep(SchemaBuilderStep.SETUP);
        }
    }, [
        schemaPayload,
        currentBuilderStep,
        setCurrentBuilderStep,
        schemaFileType,
    ]);
    if (!columns) return null;
    return (
        <>
            <DataTable<FileTableColumn>
                searchPlaceholder={t('profiles_view.search_placeholder')}
                tableBackgroundColor=""
                customClass="!h-[28rem] overflow-scroll"
                cellHeight="h-[60px]"
                rightSection={
                    <Flex
                        className="ml-[22rem] w-[15rem]"
                        justify={'flex-end'}
                        align={'center'}
                        gap={'sm'}
                    >
                        {lastSyncedAt && (
                            <Text className="text-xs text-gray-600">
                                {t(
                                    'schema_builder.table_properties.last_synced_at',
                                    {
                                        lastSyncedAt,
                                        timezone:
                                            projectData?.timezone ??
                                            Timezones.UTC,
                                    },
                                )}
                            </Text>
                        )}
                        <ArrowsCounterClockwise
                            size={14}
                            weight="regular"
                            className="hover:cursor-pointer"
                            onClick={() =>
                                mutate({
                                    tableName: schemaPayload?.name,
                                    relationId: activeRelationUuid,
                                })
                            }
                        />
                    </Flex>
                }
                tableData={filterItems || []}
                onSearchChange={onSearchChange}
                options={[
                    {
                        format: ShowDataType.LIST,
                        formatData: columns,
                    },
                ]}
                isEditable={false}
                disablePagination
            />
            {!isEditMode && (
                <Box className="fixed bottom-0 left-0 w-full bg-white border-t-2">
                    <Flex className="px-4 my-4 flex justify-between">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={async () => {
                                setCurrentBuilderStep(
                                    SchemaBuilderStep.PERSONALISE,
                                );
                            }}
                            loading={isCreatingRelation}
                            disabled={isCreatingRelation}
                            leftIcon={<CaretLeft weight="duotone" size={14} />}
                            className="py-2"
                        >
                            {t('schema_builder.table_properties.go_back')}
                        </Button>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            onClick={async () => {
                                await handleClick();
                            }}
                            rightIcon={<CaretRight color="white" size={14} />}
                            className="py-2"
                            loading={
                                isCreatingRelation ||
                                isUpsertingCatalogFile ||
                                isUpdatingTableDetail
                            }
                        >
                            {t(
                                schemaPayload.type === RelationTableType.PRIMARY
                                    ? 'schema_builder.table_chooser.looks_good'
                                    : 'common.save',
                            )}
                        </Button>
                    </Flex>
                </Box>
            )}
        </>
    );
};

export default TableProperties;
