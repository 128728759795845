import { useGetAudienceExclusions } from '@hooks/useAnalytics';
import { useLocale } from '@hooks/useLocale';
import {
    COUNT_DISTINCT_OF_FREQUENCY_CAPPING_EXCLUDED_USERS,
    COUNT_DISTINCT_OF_GLOBAL_UNSUBSCRIBED_USERS,
    COUNT_DISTINCT_OF_NULL_CHECK_FAILED_USERS,
    COUNT_DISTINCT_OF_SUBSCRIPTION_GROUP_UNSUBSCRIBED_USERS,
    COUNT_DISTINCT_OF_TOTAL_UNSUBSCRIBED_USERS,
    RelationTableType,
    type ApiAudienceExclusionCountQueryDto,
    type AudienceExclusionCountQueryRequest,
    type Campaign,
} from '@lightdash/common';
import { Box, Skeleton, Table, Text } from '@mantine/core';
import useRelationContext from '@providers/Relation/useRelationContext';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

const AudienceExclusionsTable = ({
    campaignData,
}: {
    campaignData: Campaign;
}) => {
    const { t } = useLocale();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const campaignId = queryParams.get('campaignUuid') ?? '';
    const { activeRelationUuid, activeRelation } = useRelationContext();

    const {
        mutate: getAudienceExclusions,
        data: audienceExclusions,
        isLoading,
    } = useGetAudienceExclusions({
        relationId: activeRelationUuid,
    });

    useEffect(() => {
        const payload: AudienceExclusionCountQueryRequest = {
            campaignId: campaignId,
        };
        getAudienceExclusions(payload);
    }, [campaignId, getAudienceExclusions]);

    const keyPrefix = useMemo(() => {
        let userAlias = '';
        if (activeRelation) {
            const relationBaseTable =
                activeRelation.tables[activeRelation.baseTable];
            if (relationBaseTable.type === RelationTableType.PRIMARY)
                userAlias = `${relationBaseTable.name}_${relationBaseTable.userId}`;
        }
        return `${campaignData?.communicationDetails?.runDetails?.[0]?.exclusionsSnapshotName}_${userAlias}`;
    }, [activeRelation, campaignData?.communicationDetails?.runDetails]);

    const audienceExclusionKeys = useMemo(
        () => ({
            totalUnsubscribe: `${keyPrefix}_${COUNT_DISTINCT_OF_TOTAL_UNSUBSCRIBED_USERS}`,
            globalUnsubscribe: `${keyPrefix}_${COUNT_DISTINCT_OF_GLOBAL_UNSUBSCRIBED_USERS}`,
            subGroupUnsubscribed: `${keyPrefix}_${COUNT_DISTINCT_OF_SUBSCRIPTION_GROUP_UNSUBSCRIBED_USERS}`,
            nullCheckFailed: `${keyPrefix}_${COUNT_DISTINCT_OF_NULL_CHECK_FAILED_USERS}`,
            frequenceCapping: `${keyPrefix}_${COUNT_DISTINCT_OF_FREQUENCY_CAPPING_EXCLUDED_USERS}`,
        }),
        [keyPrefix],
    );

    const elements = useMemo(() => {
        const data =
            audienceExclusions as unknown as ApiAudienceExclusionCountQueryDto['results'];
        if (data) {
            return [
                {
                    totalUnsubscribe:
                        data[audienceExclusionKeys.totalUnsubscribe],
                    globalUnsubscribe:
                        data[audienceExclusionKeys.globalUnsubscribe],
                    subGroupUnsubscribed:
                        data[audienceExclusionKeys.subGroupUnsubscribed],
                    nullCheckFailed:
                        data[audienceExclusionKeys.nullCheckFailed],
                    frequenceCapping:
                        data[audienceExclusionKeys.frequenceCapping],
                },
            ];
        }
        return [];
    }, [audienceExclusionKeys, audienceExclusions]);

    if (isLoading) {
        return (
            <Box className="border-base p-3 mt-3 mx-3">
                <Text className="text-base text-gray-800 font-semibold">
                    {t('campaign_analytics.audience_exclusions.title')}
                </Text>
                <Skeleton height={20} width={300} className="mt-3" />
            </Box>
        );
    }

    if (!elements.length) return null;

    const rows = elements?.map((element, index) => {
        return (
            <tr key={index}>
                <td>{element.totalUnsubscribe}</td>
                <td>{element?.globalUnsubscribe}</td>
                <td>{element?.subGroupUnsubscribed}</td>
                <td>{element?.nullCheckFailed}</td>
                <td>{element?.frequenceCapping}</td>
            </tr>
        );
    });

    return (
        <Box className="border-base p-3 my-3 mx-3">
            <Text className="text-base text-gray-800 font-semibold">
                {t('campaign_analytics.audience_exclusions.title')}
            </Text>
            <Table withBorder withColumnBorders className="mt-4">
                <thead>
                    <tr>
                        <th>
                            {t(
                                'campaign_analytics.audience_exclusions.total_unsubscribe',
                            )}
                        </th>
                        <th>
                            {t(
                                'campaign_analytics.audience_exclusions.global_unsubscribe',
                            )}
                        </th>
                        <th>
                            {t(
                                'campaign_analytics.audience_exclusions.sub_group_unsubscribed',
                            )}
                        </th>
                        <th>
                            {t(
                                'campaign_analytics.audience_exclusions.null_check_failed',
                            )}
                        </th>
                        <th>
                            {t(
                                'campaign_analytics.audience_exclusions.frequency_capping',
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        </Box>
    );
};

export default AudienceExclusionsTable;
