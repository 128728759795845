import BuilderContainer from '@components/common/BuilderContainer';
import GenericOption from '@components/common/Card/GenericOption';
import SkeletonLoader from '@components/common/SkeletonLoader';
import {
    useDuplicateCampaign,
    useTriggerCampaign,
    useUpdateCampaign,
} from '@hooks/useCampaigns';
import {
    useGetIntegratedProviderConfig,
    useGetProviderConfig,
} from '@hooks/useChannels';
import { useLocale } from '@hooks/useLocale';
import {
    useGetSyncedTemplateByTemplateName,
    useGetTemplateById,
} from '@hooks/useTemplate';
import useTimestamp from '@hooks/useTimestamp';
import {
    AudienceType,
    CampaignScheduleType,
    CampaignStatus as CampaignStatusEnum,
    CommunicationChannel,
    // type AudienceType,
    type Campaign,
    type CampaignUpdateRequest,
} from '@lightdash/common';
import { Box, Button, Group, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    CaretRight,
    CheckCircle,
    ClockCountdown,
    Copy,
    NewspaperClipping,
    WarningCircle,
} from '@phosphor-icons/react';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import useProjectContext from '@providers/Project/useProjectContext';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';
import { toCampaignAnalyticsTimeFormat } from '../Analytics/utils';
import IntegrationOption from '../Builder/IntegrationOption';
import Audience from '../Builder/Steps/ReviewCampaign/Audience';
import {
    CampaignBuilderQueryParams,
    CampaignBuilderStep,
} from '../Builder/types';
import CampaignFailureReasonModal from '../CampaignFailureReasonModal';
import { CampaignTab } from '../Table/types';
import { allowCampaignEdits } from '../utils';
import {
    getCampaignAnalyticsDashboardUrl,
    isCampaignExecutionDone,
} from './../campaignUtils';
import Performance from './Performance';
import PreviewSubtitle from './PreviewSubtitle';

interface CampaignPreviewContainerProps {
    campaignData: Campaign;
}

const CampaignPreviewContainer: React.FC<CampaignPreviewContainerProps> = ({
    campaignData,
}) => {
    const { t } = useLocale();
    const navigate = useNavigate();
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();
    const queryClient = useQueryClient();
    const { projectData } = useProjectContext();
    const { mutateAsync: mutateTriggerCampaign } = useTriggerCampaign();
    const { getTimestamp } = useTimestamp();

    const { setCurrentStep } = useCampaignContext((context) => context.actions);

    const { campaignPayload } = useCampaignContext((context) => context.state);
    const { communicationDetails, templateDetails } = campaignPayload;
    const { mutate: duplicateCampaign } = useDuplicateCampaign({
        id: campaignData.id,
        name: campaignData.name,
        channel: campaignPayload.channel ?? CommunicationChannel.EMAIL,
        description: campaignData.description,
    });

    const isCampaignEditable = useMemo(
        () =>
            allowCampaignEdits({
                executionTime: campaignData.schedule?.executionTime,
                status: campaignData.status,
            }),
        [campaignData],
    );

    const {
        data: integrationConfig,
        isInitialLoading: isIntegrationConfigLoading,
    } = useGetIntegratedProviderConfig(
        communicationDetails?.providerId,
        communicationDetails?.id,
    );
    const { data: providerConfig, isInitialLoading: isProviderConfigLoading } =
        useGetProviderConfig(communicationDetails?.providerId);

    const { data: templateData, isInitialLoading: isTemplateDataLoading } =
        useGetTemplateById(
            campaignData.templateDetails?.id ?? '',
            campaignPayload?.channel !== CommunicationChannel.WHATSAPP,
        );

    const {
        data: syncedTemplateMetadata,
        isLoading: isSyncedTemplateMetadataLoading,
    } = useGetSyncedTemplateByTemplateName(
        (templateDetails?.integrationId || communicationDetails?.id) ?? '',
        campaignData.templateDetails?.id ?? '',
        campaignPayload?.channel === CommunicationChannel.WHATSAPP,
    );

    const { mutateAsync: updateCampaign } = useUpdateCampaign(
        campaignData?.id ?? '',
        false,
    );

    const [
        showFailureReasonModal,
        { open: failureReasonModalOpen, close: failureReasonModalClose },
    ] = useDisclosure(false);

    const handleDuplicateCampaign = useCallback(() => {
        if (!campaignData) return;
        duplicateCampaign();
    }, [campaignData, duplicateCampaign]);

    const handleViewReport = useCallback(() => {
        if (!campaignData || !projectData) return;

        const dashboardUrl = getCampaignAnalyticsDashboardUrl(
            projectData,
            campaignData.id,
        );

        void navigate(dashboardUrl);
    }, [campaignData, navigate, projectData]);

    const handleTriggerCampaign = useCallback(async () => {
        if (!campaignData.id) return;
        await mutateTriggerCampaign({ campaignId: campaignData.id ?? '' });
        await queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
        void navigate(
            `/projects/${projectUuid}/campaigns#${CampaignTab.RUNNING_NOW}`,
        );
    }, [
        campaignData.id,
        mutateTriggerCampaign,
        queryClient,
        navigate,
        projectUuid,
    ]);

    const renderBuilderContainerRightSection = useMemo(() => {
        return (
            <Group>
                {campaignData.audienceType === AudienceType.WAREHOUSE && (
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        onClick={handleDuplicateCampaign}
                        leftIcon={<Copy />}
                    >
                        {t('campaign_manager.menu_item_duplicate')}
                    </Button>
                )}
                {campaignData.status === CampaignStatusEnum.READY && (
                    <Button
                        variant={ButtonVariant.PRIMARY}
                        onClick={() => handleTriggerCampaign()}
                        rightIcon={
                            <CaretRight
                                color="white"
                                weight="regular"
                                size={16}
                            />
                        }
                    >
                        Send message now
                    </Button>
                )}

                {campaignData.status === CampaignStatusEnum.COMPLETED && (
                    <Button
                        variant={ButtonVariant.PRIMARY}
                        onClick={handleViewReport}
                        rightIcon={
                            <CaretRight
                                color="white"
                                weight="regular"
                                size={16}
                            />
                        }
                    >
                        View report
                    </Button>
                )}
            </Group>
        );
    }, [
        campaignData.audienceType,
        campaignData.status,
        handleDuplicateCampaign,
        t,
        handleViewReport,
        handleTriggerCampaign,
    ]);

    const runTypeText = useMemo(() => {
        switch (campaignData.schedule?.type) {
            case CampaignScheduleType.MANUAL: {
                let time = campaignData.schedule?.executionTime;

                return campaignData.status === CampaignStatusEnum.COMPLETED ||
                    campaignData.status === CampaignStatusEnum.FAILURE
                    ? time
                        ? t('campaign.view.manual_trigger_done', {
                              time: toCampaignAnalyticsTimeFormat(
                                  getTimestamp(time?.toString() ?? ''),
                              ),
                          })
                        : t('campaign.view.manual_trigger_done_without_time')
                    : t('campaign.view.manual_trigger_title');
            }
            case CampaignScheduleType.DELAYED: {
                let time = campaignData.schedule?.executionTime;
                if (!time) return;

                const timestamp = getTimestamp(time.toString());
                return t('campaign.view.is_scheduled_trigger_title', {
                    time: toCampaignAnalyticsTimeFormat(timestamp),
                    verb: isCampaignExecutionDone(time)
                        ? 'triggered'
                        : 'is set to trigger',
                });
            }

            default:
                return '';
        }
    }, [campaignData, getTimestamp, t]);

    const renderStatusBanner = useMemo(() => {
        if (campaignData.status === CampaignStatusEnum.COMPLETED) {
            return (
                <Box className="flex gap-1.5 px-3 py-2 border rounded-lg bg-blu-800/2 border-blu-800/40 items-center">
                    <CheckCircle color="rgb(var(--color-blu-800))" />
                    <Text className="text-sm font-medium text-blu-800">
                        {t('campaign.view.campaign_execution_successfull', {
                            time: toCampaignAnalyticsTimeFormat(
                                getTimestamp(
                                    campaignData.updatedAt?.toString() ?? '',
                                ),
                            ),
                        })}
                    </Text>
                </Box>
            );
        }

        if (campaignData.status === CampaignStatusEnum.FAILURE) {
            return (
                <Box className="flex gap-1.5 px-3 py-2 border rounded-lg bg-halt-800/5 border-halt-800/40 items-center">
                    <WarningCircle
                        weight="duotone"
                        color={'rgb(var(--color-halt-800))'}
                    />
                    <Text className="text-sm font-medium text-halt-800">
                        {t('campaign.view.campaign_execution_failed')}
                    </Text>
                    <Text
                        className="text-sm font-medium underline text-halt-800 hover:cursor-pointer hover:font-semibold decoration-dashed underline-offset-2"
                        onClick={() => failureReasonModalOpen()}
                    >
                        View reason
                    </Text>
                </Box>
            );
        }

        if (
            campaignData.status === CampaignStatusEnum.DRAFT &&
            campaignData.schedule?.type === CampaignScheduleType.DELAYED
        ) {
            return (
                <Box className="flex gap-1.5 px-3 py-2 border rounded-lg bg-mustard-800/2 border-mustard-800/40 items-center">
                    <ClockCountdown color="rgb(var(--color-mustard-800))" />
                    <Text className="text-sm font-medium text-mustard-800">
                        {t('campaign.view.campaign_execution_scheduled', {
                            time: toCampaignAnalyticsTimeFormat(
                                getTimestamp(
                                    campaignData.schedule?.executionTime?.toString() ??
                                        '',
                                ),
                            ),
                        })}
                    </Text>
                </Box>
            );
        }

        return null;
    }, [
        campaignData.schedule?.executionTime,
        campaignData.schedule?.type,
        campaignData.status,
        campaignData.updatedAt,
        failureReasonModalOpen,
        getTimestamp,
        t,
    ]);

    const handleCampaignNameChange = useCallback(
        async (name: string) => {
            if (!campaignData.id) return;

            const payload: CampaignUpdateRequest = {
                name: name,
                // audienceType: campaignData?.audienceType as AudienceType,
            };

            await updateCampaign(payload);
        },
        [campaignData.id, updateCampaign],
    );

    const handleCampaignDescriptionChange = useCallback(
        async (description: string) => {
            if (!campaignData.id) return;

            const payload: CampaignUpdateRequest = {
                description: description,
                // audienceType: campaignData?.audienceType as AudienceType,
            };

            await updateCampaign(payload);
        },
        [campaignData.id, updateCampaign],
    );

    const handleOptionClick = useCallback(
        (campaignStep: CampaignBuilderStep) => {
            const mode =
                campaignData.status === CampaignStatusEnum.READY ||
                campaignData.status === CampaignStatusEnum.SCHEDULED
                    ? 'edit'
                    : campaignData.status === CampaignStatusEnum.COMPLETED
                    ? 'view'
                    : null;
            if (!mode) return;

            setCurrentStep(campaignStep);
            void navigate(
                `/projects/${projectUuid}/campaigns/${campaignData.id}/${mode}?${CampaignBuilderQueryParams.CHANNEL}=${campaignPayload.channel}`,
            );
        },
        [
            campaignData.id,
            campaignData.status,
            campaignPayload.channel,
            navigate,
            projectUuid,
            setCurrentStep,
        ],
    );

    return (
        <BuilderContainer
            isEditable={isCampaignEditable}
            title={campaignData.name}
            onTitleChange={(value) => handleCampaignNameChange(value)}
            titlePlaceholder={t('audience_builder.name_placeholder')}
            subtitle={
                <PreviewSubtitle
                    campaignData={campaignData}
                    isEditable={isCampaignEditable}
                />
            }
            description={campaignData.description}
            descriptionPlaceholder={t(
                'audience_builder.description_placeholder',
            )}
            onDescriptionChange={(value) =>
                handleCampaignDescriptionChange(value)
            }
            withContentPadding={true}
            rightSection={renderBuilderContainerRightSection}
        >
            <Stack>
                {renderStatusBanner}
                <Box>
                    <Performance
                        channel={campaignData.channel}
                        campaignId={campaignData.id}
                    />
                </Box>
                <Box
                    className="w-full"
                    onClick={() =>
                        isCampaignEditable &&
                        handleOptionClick(CampaignBuilderStep.AUDIENCE)
                    }
                >
                    <Audience
                        handleEdit={() => {}}
                        sendList={campaignPayload.audienceConfig?.config.in}
                        suppressList={
                            campaignPayload.audienceConfig?.config.notIn
                        }
                        channel={
                            campaignPayload.channel ??
                            CommunicationChannel.EMAIL
                        }
                        count={
                            campaignPayload.audienceConfig?.totalCount ||
                            campaignPayload.csvUploadDetails?.audienceCount ||
                            0
                        }
                        filename={campaignPayload.csvUploadDetails?.fileName}
                        showEdit={false}
                        showPreview={
                            campaignPayload.audienceType ===
                            AudienceType.WAREHOUSE
                        }
                    />
                </Box>

                {isIntegrationConfigLoading || isProviderConfigLoading ? (
                    <SkeletonLoader height={70} />
                ) : integrationConfig && providerConfig ? (
                    <>
                        <IntegrationOption
                            title={integrationConfig?.customName}
                            providerName={providerConfig.providerName}
                            providerLogoUrl={providerConfig.logoUrl}
                            integrationId={
                                integrationConfig.integrationId ?? ''
                            }
                            isMutable={false}
                            customClass="!w-full"
                            channel={campaignPayload.channel}
                            onClick={() =>
                                isCampaignEditable &&
                                handleOptionClick(CampaignBuilderStep.CHANNEL)
                            }
                        />
                    </>
                ) : null}

                {isTemplateDataLoading ||
                (campaignPayload.channel === CommunicationChannel.WHATSAPP &&
                    isSyncedTemplateMetadataLoading) ? (
                    <>
                        <SkeletonLoader height={70} />
                    </>
                ) : (
                    (templateData || syncedTemplateMetadata) && (
                        <GenericOption
                            title={
                                <Text className="text-sm font-medium text-gray-800">
                                    {(templateData && templateData.name) ||
                                        (syncedTemplateMetadata &&
                                            syncedTemplateMetadata.name)}
                                </Text>
                            }
                            subtitle={
                                <Group className="gap-1.5 text-xs text-gray-600">
                                    <NewspaperClipping />
                                    <Text>Template</Text>
                                </Group>
                            }
                            rightSection={<CaretRight weight="regular" />}
                            customClass={'!w-full'}
                            onClick={() =>
                                isCampaignEditable &&
                                handleOptionClick(CampaignBuilderStep.CONTENT)
                            }
                        />
                    )
                )}

                {runTypeText && (
                    <GenericOption
                        title={
                            <Group className="gap-1.5">
                                <ClockCountdown />
                                <Text className="text-sm font-medium text-gray-800">
                                    {runTypeText}
                                </Text>
                            </Group>
                        }
                        rightSection={<CaretRight weight="regular" />}
                        customClass={'!w-full'}
                        onClick={() =>
                            isCampaignEditable &&
                            handleOptionClick(CampaignBuilderStep.SCHEDULE)
                        }
                    />
                )}

                <CampaignFailureReasonModal
                    campaignId={campaignData.id}
                    showModal={showFailureReasonModal}
                    closeModal={failureReasonModalClose}
                />
            </Stack>
        </BuilderContainer>
    );
};

export default React.memo(CampaignPreviewContainer);
