import FieldListItem from '@components/Audience/Filters/FieldListItem';
import { convertFieldsIntoPropertySelectListType } from '@components/Audience/Filters/FieldListItem/utils';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import { useLocale } from '@hooks/useLocale';
import { type CompiledDimension } from '@lightdash/common';
import { Button, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretDown } from '@phosphor-icons/react';
import useCustomAttributeContext from '@providers/CustomAttribute/useCustomAttributeContext';
import { ButtonVariant } from '../../mantineTheme';

interface CustomMetricPropertySelectProps {
    fields: FieldWithSuggestions[];
    onSubmit: (field: FieldWithSuggestions[]) => void;
    isLoading: boolean;
    activeField: FieldWithSuggestions | CompiledDimension | undefined;
}
const CustomMetricPropertySelect: React.FC<CustomMetricPropertySelectProps> = ({
    fields,
    onSubmit,
    isLoading,
    activeField,
}) => {
    const { t } = useLocale();
    const [opened, { open, close }] = useDisclosure();
    const { isEditMode } = useCustomAttributeContext(
        (context) => context.reducerState,
    );
    return (
        <PropertySelect<
            FieldWithSuggestions & AddditionalPropertySelectListProps
        >
            items={convertFieldsIntoPropertySelectListType(fields, true)}
            showGroup={false}
            headerRightSection={null}
            onSubmit={(field: FieldWithSuggestions[]) => {
                if (!field[0]) return;
                onSubmit(field);
                close();
            }}
            itemTemplate={({ item }) => {
                return (
                    <FieldListItem
                        item={item}
                        checked={item.isChecked ?? false}
                        showCheckbox={false}
                        disabled={item.isDisabled ?? false}
                        disableHover
                    />
                );
            }}
            opened={opened}
            close={close}
            open={open}
            targetButton={
                <Button
                    variant={ButtonVariant.UNSTYLED}
                    className="px-2 w-fit !h-9"
                    style={{
                        borderRadius: '8px',
                        background: 'white',
                        border: '1px solid',
                        borderColor: 'rgba(0, 0, 0, 0.06)',
                        boxShadow: `0px -1px 4px 0px rgba(0, 0, 0, 0.06) inset`,
                        fontSize: '14px',
                        color: 'rgb(var(--color-gray-600))',
                        width: 'wrap-content !important',
                        cursor: !isEditMode || isLoading ? 'auto' : 'pointer',
                        minWidth: '18rem',
                        minHeight: '2rem',
                    }}
                    form="some_random"
                    disabled={!isEditMode || isLoading}
                >
                    <Flex
                        justify="space-between"
                        align={'center'}
                        className="w-full"
                    >
                        {activeField ? (
                            <FieldListItem
                                item={activeField as FieldWithSuggestions}
                                disabled={!isEditMode || isLoading}
                                showCheckbox={false}
                                checked={false}
                                showHoverIcon={false}
                            />
                        ) : (
                            <Text className="text-sm font-medium text-gray-500">
                                {t(
                                    'custom_metric.dimension_modal_property_description',
                                )}
                            </Text>
                        )}
                        <CaretDown
                            color={'rgb(var(--color-gray-600)'}
                            weight={'regular'}
                        />
                    </Flex>
                </Button>
            }
            showSearch={true}
            searchKeys={['label', 'tableLabel']}
            searchPlaceholder={t('audience_filters.search_filter_label')}
            allowMultipleSelection={false}
            withinPortal={true}
            disabled={!isEditMode || isLoading}
        />
    );
};
export default CustomMetricPropertySelect;
