import { subject } from '@casl/ability';
import { DEFAULT_PROVIDER } from '@components/Channels/ChannelTabs/types';
import ChannelTabs from '@components/ChannelTabs';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import UnsavedChangesModal from '@components/common/modal/UnsavedChangesModal';
import SettingsTitle from '@components/common/Settings/SettingsTitle';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { useLocale } from '@hooks/useLocale';
import {
    useProject,
    useUpdateReachabilityConfigMutation,
} from '@hooks/useProject';
import {
    ProjectSettings,
    type CommunicationChannel,
    type ReachabilityConfig,
    type TableConfig,
} from '@lightdash/common';
import { Box, Button, Stack, Tabs } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Plus, PlusCircle } from '@phosphor-icons/react';
import useApp from '@providers/App/useApp';
import { removeEmptyParams } from '@utils/helpers';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ButtonVariant } from '../../../mantineTheme';
import ReachabilityVariablesTable from './ReachabilityVariablesTable';
import VariablePropertySelect from './VariablePropertySelect';

const ReachabilityVariables = () => {
    const { t } = useLocale();
    const [activePill, setActivePill] =
        useState<CommunicationChannel>(DEFAULT_PROVIDER);
    const [opened, { open, close }] = useDisclosure();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { data: project, isInitialLoading } = useProject(projectUuid);
    const navigate = useNavigate();
    const ability = useAbilityContext();
    const { user } = useApp();
    const canEditReachabilityVariables = ability.can(
        'edit',
        subject(ProjectSettings.reachabilityVariables, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const cannotViewReachabilityVariables = ability.cannot(
        'view',
        subject(ProjectSettings.reachabilityVariables, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const {
        mutate: updateReachabilityConfig,
        isLoading: isUpdatingReachabilityConfig,
    } = useUpdateReachabilityConfigMutation(projectUuid);

    const [reachabilityConfig, setReachabilityConfig] = useState<
        ReachabilityConfig | undefined
    >(project?.attributes?.reachability);

    const handlePillChange = useCallback((pill: CommunicationChannel) => {
        setActivePill(pill);
    }, []);
    const hasFormChanged = _.isEqual(
        removeEmptyParams(project?.attributes?.reachability ?? []),
        removeEmptyParams(reachabilityConfig ?? []),
    );
    const primaryConfig = useMemo(
        () => reachabilityConfig?.[activePill]?.primary,
        [reachabilityConfig, activePill],
    );
    const secondaryConfig = useMemo(
        () => reachabilityConfig?.[activePill]?.secondary || [],
        [reachabilityConfig, activePill],
    );
    const variablesConfig = useMemo(() => {
        return [
            ...(primaryConfig ? [{ ...primaryConfig, isPrimary: true }] : []),
            ...(secondaryConfig || []),
        ];
    }, [primaryConfig, secondaryConfig]);
    const handlePrimaryVariableChange = useCallback(
        (newConfigIndex: number) => {
            const newPrimaryConfig = secondaryConfig[newConfigIndex - 1];
            const newSecondaryConfig = secondaryConfig.map((config, index) =>
                index === newConfigIndex - 1 ? primaryConfig : config,
            );

            setReachabilityConfig((prev) => {
                if (!prev)
                    return {
                        [activePill]: {
                            primary: newPrimaryConfig,
                            secondary:
                                newSecondaryConfig?.filter(
                                    (config): config is TableConfig =>
                                        config !== undefined,
                                ) ?? [],
                        },
                    };
                return {
                    ...prev,
                    [activePill]: {
                        ...prev[activePill],
                        primary: newPrimaryConfig,
                        secondary: newSecondaryConfig,
                    },
                };
            });
        },
        [activePill, primaryConfig, secondaryConfig],
    );
    const handleRemoveVariable = useCallback(
        (newConfigIndex: number) => {
            const newSecondaryConfig = secondaryConfig.filter(
                (_eachConfig, index) => index !== newConfigIndex - 1,
            );
            setReachabilityConfig((prev) => {
                if (!prev)
                    return {
                        [activePill]: {
                            primary: primaryConfig,
                            secondary: newSecondaryConfig,
                        },
                    };
                return {
                    ...prev,
                    [activePill]: {
                        ...prev[activePill],
                        secondary: newSecondaryConfig,
                    },
                };
            });
        },
        [activePill, secondaryConfig, primaryConfig],
    );
    const handleAddSecondaryVariable = useCallback(
        (newConfig: TableConfig) => {
            setReachabilityConfig((prev) => {
                if (!prev)
                    return {
                        [activePill]: {
                            primary: primaryConfig,
                            secondary: [newConfig],
                        },
                    };
                return {
                    ...prev,
                    [activePill]: {
                        ...prev[activePill],
                        secondary: [
                            ...(prev[activePill]?.secondary ?? []),
                            newConfig,
                        ],
                    },
                };
            });
        },
        [activePill, primaryConfig],
    );
    const handleAddPrimaryVariable = useCallback(
        (newConfig: TableConfig) => {
            setReachabilityConfig((prev) => {
                if (!prev)
                    return {
                        [activePill]: {
                            primary: newConfig,
                            secondary: [],
                        },
                    };
                return {
                    ...prev,
                    [activePill]: {
                        primary: newConfig,
                        secondary: [],
                    },
                };
            });
        },
        [activePill],
    );
    const handleSave = useCallback(() => {
        if (reachabilityConfig) {
            //filter out undefined primary as it is not a valid config
            const filteredReachabilityConfig = _.reduce(
                Object.entries(reachabilityConfig),
                (acc, [channel, config]) => {
                    if (config?.primary) {
                        acc[channel as CommunicationChannel] = config;
                    }
                    return acc;
                },
                {} as ReachabilityConfig,
            );
            updateReachabilityConfig(filteredReachabilityConfig);
        }
    }, [reachabilityConfig, updateReachabilityConfig]);

    if (cannotViewReachabilityVariables) {
        void navigate('/');
    }
    if (isInitialLoading) {
        return <SkeletonLoader height={10} />;
    }

    if (!projectUuid) return null;

    return (
        <Stack>
            <SettingsTitle
                title={t('workspace_settings.reachability_variables_title')}
            />
            <Tabs
                keepMounted={false}
                variant="pills"
                radius="lg"
                defaultValue={activePill}
                onTabChange={handlePillChange}
                className="pl-2 bg-gray-100/60"
            >
                <ChannelTabs activeTab={activePill} />
            </Tabs>

            <UnsavedChangesModal
                opened={!hasFormChanged && canEditReachabilityVariables}
                secondaryActionButtonClick={() =>
                    setReachabilityConfig(project?.attributes?.reachability)
                }
                primaryActionButtonClick={handleSave}
                disableButtons={isUpdatingReachabilityConfig}
            />
            <Box className="border border-shade-4 rounded-2xl p-0.5 bg-white">
                {variablesConfig.length === 0 && (
                    <VariablePropertySelect
                        onSubmit={(item) => {
                            close();
                            handleAddPrimaryVariable({
                                tableName: item.table ?? '',
                                dimensionName: item.name ?? '',
                            });
                        }}
                        opened={opened}
                        close={close}
                        open={open}
                        targetButton={
                            <Button
                                variant={ButtonVariant.OUTLINED_ACCENTED}
                                leftIcon={
                                    <Plus color="rgb(var(--color-blu-800))" />
                                }
                                className="m-1 w-fit"
                                disabled={
                                    isUpdatingReachabilityConfig ||
                                    !canEditReachabilityVariables
                                }
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>,
                                ) => {
                                    if (
                                        isUpdatingReachabilityConfig ||
                                        !canEditReachabilityVariables
                                    ) {
                                        e.stopPropagation();
                                    }
                                }}
                            >
                                {t('reachability_variables.add_first_field')}
                            </Button>
                        }
                    />
                )}
                {variablesConfig.length > 0 && (
                    <>
                        <ReachabilityVariablesTable
                            data={variablesConfig}
                            handlePrimaryVariableChange={
                                handlePrimaryVariableChange
                            }
                            handleRemoveVariable={handleRemoveVariable}
                            isLoading={isUpdatingReachabilityConfig}
                        />
                        {canEditReachabilityVariables && (
                            <VariablePropertySelect
                                onSubmit={(item) => {
                                    close();
                                    handleAddSecondaryVariable({
                                        tableName: item.table ?? '',
                                        dimensionName: item.name ?? '',
                                    });
                                }}
                                opened={opened}
                                close={close}
                                open={open}
                                targetButton={
                                    <Button
                                        variant={ButtonVariant.OUTLINED}
                                        leftIcon={<PlusCircle />}
                                        className="m-1 w-fit"
                                        disabled={
                                            isUpdatingReachabilityConfig ||
                                            !canEditReachabilityVariables
                                        }
                                        onClick={(
                                            e: React.MouseEvent<HTMLButtonElement>,
                                        ) => {
                                            if (
                                                isUpdatingReachabilityConfig ||
                                                !canEditReachabilityVariables
                                            ) {
                                                e.stopPropagation();
                                            }
                                        }}
                                    >
                                        {t(
                                            'reachability_variables.add_another_secondary_variable',
                                        )}
                                    </Button>
                                }
                            />
                        )}
                    </>
                )}
            </Box>
        </Stack>
    );
};

export default ReachabilityVariables;
