import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import Manager from '@components/Manager';
import { filterSuperAdmin } from '@components/Workspace/WorkspaceMembers/utils';
import { useLocale } from '@hooks/useLocale';
import { useOrganizationUsers } from '@hooks/useOrganizationUsers';
import { useSortedUsers } from '@hooks/useSortedUsers';
import {
    ShowDataType,
    type OrganizationMemberProfile,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import useApp from '@providers/App/useApp';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import Fuse from 'fuse.js';
import React, { useMemo } from 'react';
import { useOrganizationMembersColumns } from './useOrganizationMembersTableUtils';

interface OrganizationMembersTableProps {}

const OrganizationMembersTable: React.FC<
    OrganizationMembersTableProps
> = ({}) => {
    const { data: organizationUsers, isLoading: isLoadingUsers } =
        useOrganizationUsers();
    const [searchString, setSearchString] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );

    const organizationMembersColumns = useOrganizationMembersColumns();
    const { t } = useLocale();
    const { user } = useApp();
    const { sortedUsers } =
        useSortedUsers<OrganizationMemberProfile>(organizationUsers);
    const filteredUsers = useMemo(() => {
        const users = sortedUsers || organizationUsers || [];
        const hasSearchQuery = Boolean(searchString);
        if (!hasSearchQuery) return users;
        const fuse = new Fuse(users, {
            keys: ['firstName', 'lastName', 'email', 'role'],
            threshold: 0.3,
        });
        return fuse.search(searchString).map((res) => res.item);
    }, [searchString, sortedUsers, organizationUsers]);
    return (
        <Box>
            {isLoadingUsers ? (
                <SuboptimalState />
            ) : (
                organizationUsers &&
                organizationMembersColumns && (
                    <Manager
                        tableData={filterSuperAdmin<OrganizationMemberProfile>(
                            filteredUsers,
                            user?.data?.userUuid || '',
                        )}
                        options={[
                            {
                                format: ShowDataType.LIST,
                                formatData: organizationMembersColumns,
                            },
                        ]}
                        isManualPagination={false}
                        searchPlaceholder={t(
                            'workspace_members.table_search_placeholder',
                        )}
                        paginationText={t('workspace_members.pagination_text')}
                        isEditable={false}
                        searchValue={searchString}
                        onSearchChange={setSearchString}
                    />
                )
            )}
        </Box>
    );
};

export default React.memo(OrganizationMembersTable);
