import AudienceAndAnalyticsCell from '@components/Campaigns/Table/AudienceAndAnalyticsCell';
import CampaignCurrentStatus from '@components/Campaigns/Table/CampaignCurrentStatus';
import Timestamp from '@components/common/Timestamp';
import { AudienceType, type ReducedCampaign } from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import { type ColumnDef } from '@tanstack/react-table';
import { t as translate } from 'i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

const VIEW_RUNS_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-56`;
const VIEW_RUNS_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;

export const useViewRunsColumns = (
    handleFailureReasonClick: (campaignId: string) => void,
) => {
    const [columns, setColumns] = useState<ColumnDef<ReducedCampaign>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: '',
                    header: translate('campaign_manager.th_scheduled_date'),
                    cell: ({ row }) => (
                        <Box className={VIEW_RUNS_TABLE_CELL_CLASSNAME}>
                            <Text className={VIEW_RUNS_TABLE_CELL_NAME}>
                                <Box className="flex flex-col">
                                    {row.original.schedule?.executionTime && (
                                        <Timestamp
                                            timestamp={row.original.schedule.executionTime.toString()}
                                            customClass="!text-gray-800"
                                        />
                                    )}
                                </Box>
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'audienceCount',
                    header: translate('campaign_manager.th_audience_count'),
                    cell: ({ getValue, row }) => (
                        <AudienceAndAnalyticsCell
                            row={row}
                            count={
                                (row.original.audienceType ===
                                AudienceType.WAREHOUSE
                                    ? getValue<number>()
                                    : row.original.csvUploadDetails
                                          ?.audienceCount) || 0
                            }
                        />
                    ),
                },
                {
                    accessorKey: 'status',
                    header: translate('campaign_manager.th_status'),
                    cell: ({ row }) => {
                        const { original } = row;
                        const status = original?.status;
                        return (
                            <CampaignCurrentStatus
                                status={status}
                                onFailureReasonClick={() =>
                                    original.id &&
                                    handleFailureReasonClick(original.id)
                                }
                            />
                        );
                    },
                },
            ]);
        }
    }, [handleFailureReasonClick, projectUuid]);

    return columns;
};
