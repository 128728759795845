export enum EventStatus {
    PROCESSED = 'PROCESSED',
    QUEUED = 'QUEUED',
    REJECTED = 'REJECTED',
    SUCCESS = 'Success',
    DELIVERED = 'DELIVERED',
    SENT = 'Sent',
    UNKNOWN = 'UNKNOWN',
    OPEN = 'OPEN',
    UNDELIVERED = 'UNDELIVERED',
    BOUNCE = 'BOUNCE',
    ERROR = 'error',
    TIMEOUT = 'Timeout',
    FAILED = 'FAILED',
    CLICKED = 'CLICKED',
    NULL_CHECK_FAILED = 'NULL_CHECK_FAILED',
    READ = 'READ',
    CONVERTED = 'CampaignConverted',
    DROPPED = 'Dropped',
    SPAM = 'SPAMREPORT',
}

export const DELIVERY_TIME_MINS_COL = 'srt_cmp_delivery_time_mins';
export const DELIVERY_TIME_COL = 'srt_cmp_delivery_time';
export const COUNT_EVENT_STATUS_METRIC = 'srt_event_name_count_of_event_status';
export const DELIVERED_EVENT_STATUS = 'count_of_delivered_events';
export const OPENED_EVENT_STATUS = 'count_of_open_events';
export const SENT_EVENT_STATUS = 'count_of_sent_events';
export const ALL_EVENT_STATUS = 'count_of_all_events';
export const CLICKED_EVENT_STATUS = 'count_of_clicked_events';
export const CONVERSION_EVENT_STATUS = 'count_of_conversion_events';
export const CUMULATIVE_SENT_EVENTS = 'cumulative_count_of_sent_events';
export const CUMULATIVE_OPENED_EVENTS = 'cumulative_count_of_open_events';
export const CUMULATIVE_DELIVERED_EVENTS =
    'cumulative_count_of_delivered_events';
export const CUMULATIVE_OPENED_BY_DELIVERED =
    'cumulative_count_open_by_delivered';
export const CUMULATIVE_DELIVERED_BY_SENT =
    'cumulative_count_delivered_by_sent';
export const CUMULATIVE_OPENED_BY_SENT = 'cumulative_count_open_by_sent';

export const COUNT_DISTINCT_OF_TOTAL_UNSUBSCRIBED_USERS = `count_distinct_of_total_unsubscribed_users`;
export const COUNT_DISTINCT_OF_GLOBAL_UNSUBSCRIBED_USERS = `count_distinct_of_global_unsubscribed_users`;
export const COUNT_DISTINCT_OF_SUBSCRIPTION_GROUP_UNSUBSCRIBED_USERS = `count_distinct_of_subscription_group_unsubscribed_users`;
export const COUNT_DISTINCT_OF_NULL_CHECK_FAILED_USERS = `count_distinct_of_null_check_failed_users`;
export const COUNT_DISTINCT_OF_FREQUENCY_CAPPING_EXCLUDED_USERS = `count_distinct_of_frequency_capping_excluded_users`;
