import FilterSelectWrapper from '@components/common/Filters/FilterInputs/FilterSelectWrapper';
import { TextWithTooltip } from '@components/common/TextWithTooltip';
import { useLocale } from '@hooks/useLocale';
import { RelationTableType, type CompiledRelation } from '@lightdash/common';
import { Box, Flex, Group, Text } from '@mantine/core';
import { RELATED_ONE_MANY_SUBGROUP_KEY } from '@utils/constants';
import React from 'react';
import FieldListItem, { FieldListBoxWrapper } from '../FieldListItem';
import { renderTableIcon } from '../FieldListItem/utils';
import { type FieldWithSuggestions } from '../FiltersProvider/types';
import { isAudienceField } from '../utils';

interface FilterFieldSelectProps {
    activeField: FieldWithSuggestions | undefined;
    isEditMode: boolean;
    showFieldSource: boolean;
    activeRelation: CompiledRelation | undefined;
    showHoverIcon: boolean;
    showRelatedTableLabelOnly?: boolean; //This is used to show the table label only for related tables instead of the property name
    showSourceTable?: boolean;
    isOneToManyTable?: boolean;
    isRelatedRecord?: boolean;
}
export const FilterFieldSelectItem = ({
    activeField,
    isEditMode,
    showFieldSource,
    activeRelation,
    showHoverIcon,
    showRelatedTableLabelOnly = false,
    showSourceTable = false,
    isOneToManyTable = false,
    isRelatedRecord = false,
}: FilterFieldSelectProps) => {
    const { t } = useLocale();
    if (!activeField) {
        return (
            <Flex className="items-center justify-between !w-full">
                <Text className="text-sm font-normal text-gray-500">
                    {t('filter_rule_form.empty_property_select_label')}
                </Text>
            </Flex>
        );
    }

    if (isAudienceField(activeField) && activeField.table) {
        const tableName =
            'label' in activeField ? activeField.label : activeField.name;
        return (
            <Flex justify="space-between" align={'center'}>
                <FieldListBoxWrapper
                    checked={false}
                    disableHover={false}
                    key={`${activeField?.table}_${activeField?.name}`}
                    showHoverIcon={showHoverIcon}
                >
                    <Group className="flex items-center gap-1 flex-nowrap">
                        <Flex className="flex-row items-center justify-center gap-10 rounded-md ">
                            {renderTableIcon(
                                RelationTableType.AUDIENCE,
                                undefined,
                            )}
                        </Flex>
                        <TextWithTooltip
                            text={tableName ?? ''}
                            className="!m-0 py-2 rounded text-sm text-gray-800 font-medium"
                        />
                    </Group>
                </FieldListBoxWrapper>
            </Flex>
        );
    }

    if (
        isRelatedRecord ||
        (showRelatedTableLabelOnly &&
            activeField.table &&
            activeRelation &&
            activeRelation.tables[activeField.table]?.type ===
                RelationTableType.RELATED &&
            isOneToManyTable &&
            'subGroupKey' in activeField &&
            activeField.subGroupKey === RELATED_ONE_MANY_SUBGROUP_KEY)
    ) {
        const isReservedTable =
            activeRelation?.tables[activeField.table ?? '']?.isReserved;
        if (isReservedTable) {
            return null;
        }
        return (
            <FieldListBoxWrapper
                checked={false}
                disableHover={!isEditMode}
                key={`${activeField?.table}_${activeField?.name}`}
                showHoverIcon={showHoverIcon}
            >
                <Box className="flex items-center gap-1">
                    <Box className="flex items-center justify-center gap-10 rounded-md ">
                        {renderTableIcon(
                            activeField?.tableType,
                            'rgb(var(--color-green))',
                        )}
                    </Box>
                    <Box className={`text-sm text-gray-800 font-medium`}>
                        {'tableLabel' in activeField ? (
                            <TextWithTooltip text={activeField.tableLabel} />
                        ) : (
                            <TextWithTooltip text={activeField.table ?? ''} />
                        )}
                    </Box>
                </Box>
            </FieldListBoxWrapper>
        );
    }

    return (
        <Flex
            justify="space-between"
            align={'center'}
            className="w-full h-full"
        >
            <FieldListItem
                item={activeField}
                disabled={!isEditMode}
                showCheckbox={false}
                checked={false}
                disableHover={!isEditMode}
                showFieldSource={showFieldSource}
                showHoverIcon={showHoverIcon}
                showSourceTable={showSourceTable}
            />
        </Flex>
    );
};

const FilterFieldSelect = ({
    activeField,
    isEditMode,
    showFieldSource,
    activeRelation,
    showHoverIcon,
    showSourceTable,
}: FilterFieldSelectProps) => {
    return (
        <FilterSelectWrapper isEditMode={isEditMode}>
            <FilterFieldSelectItem
                {...{
                    activeField,
                    isEditMode,
                    showFieldSource,
                    activeRelation,
                    showHoverIcon,
                    showSourceTable,
                }}
            />
        </FilterSelectWrapper>
    );
};
export default FilterFieldSelect;
