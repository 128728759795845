import { CommonReservedTags } from '@lightdash/common';

const PRESERVED_INTERNAL_TAGS = [
    CommonReservedTags.HIDDEN,
    CommonReservedTags.INTERNAL,
] as const;

export const handleInternalTags = (newTags: string[], oldTags: string[]) => {
    const preservedTags = PRESERVED_INTERNAL_TAGS.filter(
        (tag) => oldTags.includes(tag) && !newTags.includes(tag),
    );

    return [...newTags, ...preservedTags];
};
