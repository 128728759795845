import Timestamp from '@components/common/Timestamp';
import UserCount from '@components/common/UserCount';
import { useLocale } from '@hooks/useLocale';
import {
    CommonReservedTags,
    JourneyStatus,
    type Journey,
    type JourneyAndExecutionCount,
} from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { Archive, SunHorizon } from '@phosphor-icons/react';
import { type ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getJourneyStatusIcon } from '../Builder/utils';
import JourneyMenuItem from './JourneyMenuItem';

const JOURNEY_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-56`;
const JOURNEY_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;

export function useJourneyColumns(
    openDeactivateModal: () => void,
    setJourneyData: (data: Journey) => void,
    handeMenuClick: () => void,
) {
    const [columns, setColumns] =
        useState<ColumnDef<JourneyAndExecutionCount>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: t('journey_manager.th_journey_name'),
                    cell: ({ row, getValue }) => (
                        <Box className={JOURNEY_TABLE_CELL_CLASSNAME}>
                            <Flex align={'center'} gap={6}>
                                {row.original.tags?.includes(
                                    CommonReservedTags.HIDDEN,
                                ) && <Archive weight="duotone" />}
                                <Text className={JOURNEY_TABLE_CELL_NAME}>
                                    {row.original.status ===
                                        JourneyStatus.DRAFT && (
                                        <span className="text-pink-800">
                                            {t(
                                                'audience_manager.tr_audience_status_draft',
                                            )}{' '}
                                        </span>
                                    )}

                                    {getValue<string>()}
                                </Text>
                            </Flex>
                        </Box>
                    ),
                    size: 300,
                },
                {
                    accessorKey: 'scheduledAt',
                    header: t('campaign_manager.th_status'),
                    enableSorting: false,
                    cell: ({ row }) => {
                        const { original } = row;
                        const status = original.status;
                        let content;
                        switch (status) {
                            case JourneyStatus.DRAFT:
                                content = getJourneyStatusIcon(status);
                                break;
                            case JourneyStatus.SCHEDULED:
                                content = getJourneyStatusIcon(status);
                                break;
                            case JourneyStatus.ACTIVATION_FAILED:
                                content = getJourneyStatusIcon(status);
                                break;
                            case JourneyStatus.ACTIVATING:
                                content = getJourneyStatusIcon(status);
                                break;
                            case JourneyStatus.ACTIVE:
                                const endDate =
                                    original.endDate &&
                                    new Date(original.endDate);
                                const currentDate = new Date();
                                if (endDate && endDate < currentDate) {
                                    content = (
                                        <Box className="flex flex-col justify-start">
                                            <Box className="flex flex-row items-center justify-start gap-1">
                                                <SunHorizon
                                                    color={
                                                        'rgb(var(--color-orange-800))'
                                                    }
                                                    size={14}
                                                    weight="duotone"
                                                />
                                                <Text className="text-sm font-normal text-orange-800">
                                                    {t(
                                                        'journey_manager.tr_status_sunset',
                                                    )}
                                                </Text>
                                            </Box>
                                            <Text className="text-gray-500 text-xs font-normal mt-0.5">
                                                {`${t(
                                                    'journey_manager.tr_status_sunset_last',
                                                )} ${
                                                    row.original
                                                        .currentExecutions
                                                } ${t(
                                                    'journey_manager.tr_status_sunset_users',
                                                )} `}
                                            </Text>
                                        </Box>
                                    );
                                    break;
                                }

                                content = (
                                    <Box className="flex flex-col justify-start">
                                        {getJourneyStatusIcon(status)}
                                        {!!row.original.currentExecutions && (
                                            <Text className="text-gray-500 text-xs font-normal mt-0.5">
                                                {`${
                                                    row.original
                                                        .currentExecutions
                                                } ${t(
                                                    'journey_manager.tr_status_sunset_users',
                                                )}`}
                                            </Text>
                                        )}
                                    </Box>
                                );
                                break;
                            //the default one is for cancelled state
                            default:
                                content = getJourneyStatusIcon(status);
                        }
                        return <Box>{content}</Box>;
                    },
                },
                {
                    accessorKey: 'totalExecutions',
                    enableSorting: false,
                    header: t('journey_manager.th_total_entered'),
                    cell: ({ getValue }) => {
                        return (
                            <Box className="text-sm text-gray-800">
                                {getValue<string>() ? (
                                    <UserCount
                                        withLeftSection={false}
                                        count={getValue<string>()}
                                        withRightSection={false}
                                        withApproximation={false}
                                    />
                                ) : (
                                    '-'
                                )}
                            </Box>
                        );
                    },
                },
                {
                    accessorKey: 'convertedExecutions',
                    enableSorting: false,
                    header: t('journey_manager.th_total_converted'),
                    cell: ({ getValue, row }) => {
                        return (
                            <Box className="text-sm text-gray-800">
                                {getValue<string>() &&
                                row.original.totalExecutions
                                    ? `${(
                                          (Number(getValue<string>()) /
                                              row.original.totalExecutions) *
                                          100
                                      ).toFixed(2)}%`
                                    : '-'}
                            </Box>
                        );
                    },
                },
                {
                    accessorKey: 'createdAt',
                    header: t('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`by ${
                                    row.original.createdBy?.firstName || ''
                                } ${row.original.createdBy?.lastName || ''}`}
                            </Text>
                        </Box>
                    ),
                    size: 200,
                },
                {
                    accessorKey: 'updatedAt',
                    header: t('journey_manager.th_journey_last_modified'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {row.original.updatedBy &&
                                    row.original.updatedBy.firstName &&
                                    `by ${
                                        row.original.updatedBy?.firstName || ''
                                    } ${
                                        row.original.updatedBy?.lastName || ''
                                    }`}
                            </Text>
                        </Box>
                    ),
                    size: 200,
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    enableSorting: false,
                    cell: ({ row }) => (
                        <JourneyMenuItem
                            openDeactivateModal={openDeactivateModal}
                            setJourneyData={setJourneyData}
                            rowData={row}
                            handeMenuClick={handeMenuClick}
                        />
                    ),
                    size: 80,
                },
            ]);
        }
    }, [openDeactivateModal, projectUuid, setJourneyData, t, handeMenuClick]);

    return columns;
}
