import { ShowDataType } from '@lightdash/common';
import { Box } from '@mantine/core';
import { CaretDown, CaretUp, CaretUpDown } from '@phosphor-icons/react';
import { flexRender, type Row, type Table } from '@tanstack/react-table';
import { type Virtualizer } from '@tanstack/react-virtual';
import React from 'react';
import NoDataFound from './NoDataFound';
import TableLoader from './TableLoader';

interface InfinityScrollTableProps<TData> {
    viewType: ShowDataType;
    table: Table<TData>;
    handleRowClick: ((data: Row<TData>) => void) | undefined;
    tableContainerRef: React.RefObject<HTMLDivElement>;
    rowVirtualizer: Virtualizer<any, Element>;
    cellHeight?: string;
    gridSize: number;
    isDataFetching: boolean;
    isNeedToRefresh: boolean;
    isControlledFetching: boolean;
    tableName: string;
    loadMore: React.ReactNode;
}
interface InfinityScrollListProps<TData> {
    table: Table<TData>;
    rowVirtualizer: Virtualizer<any, Element>;
    handleRowClick: ((data: Row<TData>) => void) | undefined;
    cellHeight?: string;
    tableContainerRef: React.RefObject<HTMLDivElement>;
    isDataFetching: boolean;
    isNeedToRefresh: boolean;
    isControlledFetching: boolean;
    tableName: string;
    tableCustomClass?: string;
    loadMore: React.ReactNode;
}

interface InfinityScrollGridProps<TData> {
    table: Table<TData>;
    rowVirtualizer: Virtualizer<any, Element>;
    gridSize: number;
    tableContainerRef: React.RefObject<HTMLDivElement>;
    tableName: string;
    isDataFetching: boolean;
    isNeedToRefresh: boolean;
    loadMore: React.ReactNode;
}

const InfinityScrollList = <TData,>({
    table,
    rowVirtualizer,
    handleRowClick,
    cellHeight,
    tableContainerRef,
    isDataFetching,
    isNeedToRefresh,
    isControlledFetching,
    tableName,
    loadMore,
}: InfinityScrollListProps<TData>) => {
    return (
        <Box
            className="overflow-auto overflow-x-scroll h-[inherit]"
            sx={() => ({
                scrollbarWidth: 'none', // Hide scrollbar in Firefox
                msOverflowStyle: 'none', // Hide scrollbar in IE 10+
                '::-webkit-scrollbar': {
                    display: 'none',
                },
            })}
            ref={tableContainerRef}
        >
            <table className="w-full">
                <thead className="sticky top-0 z-50 bg-white">
                    {table.getHeaderGroups()?.map((headerGroup) => (
                        <tr
                            key={headerGroup.id}
                            className="grid w-full bg-gray-50"
                            style={{
                                gridTemplateColumns: headerGroup.headers
                                    .map((header) => {
                                        if (header.column.getSize() >= 200) {
                                            return `minmax(${header.column.getSize()}px, 1fr)`;
                                        }
                                        if (header.column.getSize() <= 100) {
                                            return `${header.column.getSize()}px`;
                                        }
                                        return `minmax(0, 1fr)`;
                                    })
                                    .join(' '),
                            }}
                        >
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th
                                        key={header.id}
                                        className={`px-3.5 py-2.5 text-xs font-normal text-left text-gray-600 uppercase bg-gray-50 ${
                                            header.column.getCanSort()
                                                ? 'cursor-pointer select-none  hover:bg-gray-100'
                                                : ''
                                        }`}
                                        onClick={
                                            header.column.getCanSort()
                                                ? header.column.getToggleSortingHandler()
                                                : undefined
                                        }
                                    >
                                        <Box className="flex items-center gap-1">
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}

                                            {{
                                                asc: (
                                                    <CaretUp
                                                        color="rgb(var(--color-blu-800))"
                                                        size={14}
                                                    />
                                                ),
                                                desc: (
                                                    <CaretDown
                                                        color="rgb(var(--color-blu-800))"
                                                        size={14}
                                                    />
                                                ),
                                                false: header.column.getCanSort() && (
                                                    <CaretUpDown
                                                        color="rgb(var(--color-gray-500))"
                                                        size={14}
                                                    />
                                                ),
                                            }[
                                                header.column.getIsSorted() as string
                                            ] ?? null}
                                        </Box>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>

                {!(isDataFetching && isNeedToRefresh) && (
                    <tbody
                        className={`relative divide-y bg-white`}
                        style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
                    >
                        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                            const row =
                                table.getRowModel().rows[virtualRow.index];
                            return (
                                <tr
                                    data-index={virtualRow.index}
                                    ref={(node) =>
                                        rowVirtualizer.measureElement(node)
                                    }
                                    key={row.id}
                                    className={`grid absolute w-full  ${
                                        handleRowClick
                                            ? 'cursor-pointer hover:bg-gray-50'
                                            : ''
                                    }`}
                                    style={{
                                        transform: `translateY(${virtualRow.start}px)`,
                                        gridTemplateColumns: row
                                            .getVisibleCells()
                                            .map((cell) => {
                                                if (isControlledFetching) {
                                                    return 'minmax(0, 1fr)';
                                                }
                                                if (
                                                    cell.column.getSize() >= 200
                                                ) {
                                                    return `minmax(${cell.column.getSize()}px, 1fr)`;
                                                }
                                                if (
                                                    cell.column.getSize() <= 100
                                                ) {
                                                    return `${cell.column.getSize()}px`;
                                                }
                                                return `minmax(0, 1fr)`;
                                            })
                                            .join(' '),
                                    }}
                                    onClick={() =>
                                        handleRowClick && handleRowClick(row)
                                    }
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            key={cell.id}
                                            className={`px-3.5 py-2.5 ${
                                                cellHeight ?? 'h-16'
                                            }`}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                )}
            </table>
            {isDataFetching && isNeedToRefresh && (
                <tr className="sticky left-0 bottom-0 w-full flex justify-center items-center bg-white h-8">
                    <td colSpan={table.getAllColumns().length} className="">
                        <TableLoader />
                    </td>
                </tr>
            )}
            {!isDataFetching && table.getRowModel().rows?.length === 0 && (
                <NoDataFound name={tableName} />
            )}
            <tr className="sticky left-0 bottom-0 w-full flex justify-center items-center bg-white h-8">
                <td colSpan={table.getAllColumns().length} className="">
                    {loadMore}
                </td>
            </tr>
            {isDataFetching && !isNeedToRefresh && (
                <tr className="sticky left-0 bottom-0 w-full flex justify-center items-center bg-white h-8">
                    <td colSpan={table.getAllColumns().length} className="">
                        <TableLoader />
                    </td>
                </tr>
            )}
        </Box>
    );
};

const InfinityScrollGrid = <TData,>({
    table,
    rowVirtualizer,
    gridSize,
    tableContainerRef,
    tableName,
    isDataFetching,
}: InfinityScrollGridProps<TData>) => {
    // Calculate how many items we can fit in a row
    const itemsPerRow = gridSize;

    // Get all rows from the table
    const allRows = table.getRowModel().rows;

    // Create grid rows - each containing up to 4 table rows
    const gridData: Row<TData>[][] = [];
    for (let i = 0; i < allRows.length; i += itemsPerRow) {
        gridData.push(allRows.slice(i, i + itemsPerRow));
    }

    // Now use the virtualizer with our grid data
    const virtualRows = rowVirtualizer.getVirtualItems();

    // Get the last visible item to calculate accurate content height
    const lastVirtualItem = virtualRows[virtualRows.length - 1];

    // Calculate total height based on actual measured items when possible
    let contentHeight = 0;
    if (lastVirtualItem && lastVirtualItem.index === gridData.length - 1) {
        // If we've measured the last item, use its end as the total height
        contentHeight = lastVirtualItem.end;
    } else {
        // Estimate based on available measurements
        const avgRowHeight =
            virtualRows.length > 0
                ? virtualRows.reduce((sum, item) => sum + (item.size || 0), 0) /
                  virtualRows.length
                : 120; // Fallback estimate
        contentHeight = gridData.length * avgRowHeight;
    }

    return (
        <Box
            className="overflow-auto h-[inherit]"
            sx={() => ({
                scrollbarWidth: 'none', // Hide scrollbar in Firefox
                msOverflowStyle: 'none', // Hide scrollbar in IE 10+
                '::-webkit-scrollbar': {
                    display: 'none',
                },
            })}
            ref={tableContainerRef}
        >
            <div className="relative" style={{ height: `${contentHeight}px` }}>
                {virtualRows.map((virtualRow) => {
                    // Safety check
                    if (virtualRow.index >= gridData.length) return null;

                    // Get the rows for this grid row
                    const rowsInThisGridRow = gridData[virtualRow.index];

                    return (
                        <div
                            data-index={virtualRow.index}
                            ref={(node) => rowVirtualizer.measureElement(node)}
                            key={`grid-row-${virtualRow.index}`}
                            className="absolute w-full"
                            style={{
                                transform: `translateY(${virtualRow.start}px)`,
                                display: 'grid',
                                gridTemplateColumns:
                                    'repeat(4, minmax(0, 1fr))',
                                gap: '0.5rem',
                                padding: '0.5rem 1rem',
                            }}
                        >
                            {rowsInThisGridRow.map((row) => {
                                // Get the first cell of each row to display
                                const cell = row.getVisibleCells()[0];
                                return (
                                    <Box
                                        key={row.id}
                                        className="p-2 bg-white rounded shadow-sm min-h-[100px]"
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </Box>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            {!isDataFetching && table.getRowModel().rows?.length === 0 && (
                <NoDataFound name={tableName} />
            )}

            {isDataFetching && (
                <div className="sticky left-0 bottom-0 w-full flex justify-center items-center bg-white h-8">
                    <TableLoader />
                </div>
            )}
        </Box>
    );
};

const InfinityScrollTable = <TData,>({
    viewType,
    table,
    rowVirtualizer,
    handleRowClick,
    cellHeight,
    tableContainerRef,
    gridSize,
    isDataFetching,
    isNeedToRefresh,
    isControlledFetching,
    tableName,
    loadMore,
}: InfinityScrollTableProps<TData>) => {
    switch (viewType) {
        case ShowDataType.GRID:
            return (
                <InfinityScrollGrid
                    table={table}
                    rowVirtualizer={rowVirtualizer}
                    gridSize={gridSize}
                    tableContainerRef={tableContainerRef}
                    tableName={tableName ?? ''}
                    isDataFetching={isDataFetching}
                    isNeedToRefresh={isNeedToRefresh}
                    loadMore={loadMore}
                />
            );

        case ShowDataType.LIST:
        default:
            return (
                <InfinityScrollList
                    table={table}
                    rowVirtualizer={rowVirtualizer}
                    handleRowClick={handleRowClick}
                    cellHeight={cellHeight}
                    tableContainerRef={tableContainerRef}
                    isDataFetching={isDataFetching}
                    isNeedToRefresh={isNeedToRefresh}
                    isControlledFetching={isControlledFetching}
                    tableName={tableName ?? ''}
                    loadMore={loadMore}
                />
            );
    }
};

export default InfinityScrollTable;
