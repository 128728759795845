import { type FieldValueProperty } from '@components/Audience/Filters/FiltersProvider/types';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { useFilterFields } from '@hooks/useFilterFields';
import {
    FilterOperator,
    getDedupedEventTableName,
    getItemId,
    getJourneyEventsTableName,
    JoinType,
    RelationTableType,
    type AdditionalMetric,
    type FilterableField,
    type FilterRule,
    type Filters,
} from '@lightdash/common';
import { Group, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import useAudienceContext from '@providers/Audience/useAudienceContext';
import useProjectContext from '@providers/Project/useProjectContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { getJoinedTablesByJoinTypes } from '@utils/relation';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { MenuForDimensionFilterRule } from '../../FilterRuleMenuItem';
import { type FieldWithSuggestions } from '../../FiltersProvider/types';
import { isFilterRuleEventNameMetric } from '../../utils';
import { createFilterRule } from '../../utils/createFilterRule';
import DerivedMetricChildFilters from '../DerivedMetricFilters/DerivedMetricChildFilters';
import { DerivedMetricPropertySelect } from '../DerivedMetricFilters/DerivedMetricPropertySelect';
import {
    addFieldRuleToDerivedMetric,
    getMatchedAdditionalMetric,
} from '../DerivedMetricFilters/utils';
import FilterRuleFormWrapper from '../FilterRuleFormWrapper';
import CampaignEventFilterGroup from './CampaignEventFilters/CampaignEventFilterGroup';
import EventMetricFilterRuleForm from './EventMetricFilterRuleForm';
import JourneyEventFilterGroup from './JourneyEventFilters';
import RelatedRecordFilterRuleForm from './RelatedRecordFilterRuleForm';

interface DerivedMetricFilterRuleFormProps {
    filterRule: FilterRule;
    onChange: (value: FilterRule) => void;
    isEditMode: boolean;
    fields: FilterableField[];
    onFieldChange: (value: FilterableField) => void;
    additionalMetrics: AdditionalMetric[];
    groupIndex: number;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    onDeleteItem: (index: number) => void;
    dynamicFieldValues:
        | PropertySelectListType<FieldValueProperty>[]
        | undefined;
}

const DerivedMetricFilterRuleForm = ({
    filterRule,
    onChange,
    isEditMode,
    fields,
    additionalMetrics,
    groupIndex,
    filters,
    setFilters,
    onDeleteItem,
    dynamicFieldValues,
}: DerivedMetricFilterRuleFormProps) => {
    const [opened, { open, close }] = useDisclosure(false);
    const { activeRelation } = useRelationContext();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData } = useProjectContext();

    const activeField = useMemo(() => {
        return fields.find(
            (field) => getItemId(field) === filterRule.target.fieldId,
        );
    }, [filterRule, fields]);

    const matchedAdditionalMetric = useMemo(
        () => getMatchedAdditionalMetric(additionalMetrics, activeField),
        [additionalMetrics, activeField],
    );

    const { dimensions } = useFilterFields();

    const setAdditionalMetrics = useAudienceContext(
        (context) => context.actions.setAdditionalMetrics,
    );
    const isEventNameMetric = useMemo(() => {
        if (!filterRule || !additionalMetrics || !activeRelation) return false;
        return isFilterRuleEventNameMetric({
            filterRule,
            additionalMetrics,
            activeRelation,
        });
    }, [filterRule, additionalMetrics, activeRelation]);

    const tableFields = useMemo(() => {
        if (!activeField?.table || !activeRelation) return [];
        const joinedTables = getJoinedTablesByJoinTypes(
            activeField.table,
            activeRelation,
            [
                JoinType.one_one,
                JoinType.many_one,
                JoinType.many_many,
                JoinType.one_many,
            ],
        );
        const allowedTables = [activeField.table, ...joinedTables];
        const table = activeRelation.tables[activeField.table];
        let possibleFields = [...dimensions].filter((field) =>
            allowedTables.includes(field.table),
        );
        if (isEventNameMetric && table.type === RelationTableType.EVENT) {
            possibleFields = possibleFields.filter(
                (field) => field.name !== table.eventNameColumn,
            );
        }
        return possibleFields;
    }, [activeField, activeRelation, dimensions, isEventNameMetric]);

    const selectedToggleOperator: FilterOperator = useMemo(() => {
        switch (filterRule.operator) {
            case FilterOperator.EQUALS:
                return FilterOperator.EQUALS;
            case FilterOperator.NOT_EQUALS:
                return FilterOperator.NOT_EQUALS;
            case FilterOperator.GREATER_THAN_OR_EQUAL:
                return FilterOperator.EQUALS;
            case FilterOperator.LESS_THAN:
                return FilterOperator.NOT_EQUALS;
            case FilterOperator.LESS_THAN_OR_EQUAL:
                return FilterOperator.EQUALS;
            case FilterOperator.GREATER_THAN:
                return FilterOperator.NOT_EQUALS;
            default:
                return filterRule.operator;
        }
    }, [filterRule.operator]);

    const selectedConditionalOperator = useMemo(() => {
        switch (filterRule.operator) {
            case FilterOperator.EQUALS:
                return FilterOperator.EQUALS;
            case FilterOperator.NOT_EQUALS:
                return FilterOperator.EQUALS;
            case FilterOperator.GREATER_THAN_OR_EQUAL:
                return FilterOperator.GREATER_THAN_OR_EQUAL;
            case FilterOperator.LESS_THAN:
                return FilterOperator.GREATER_THAN_OR_EQUAL;
            case FilterOperator.LESS_THAN_OR_EQUAL:
                return FilterOperator.LESS_THAN_OR_EQUAL;
            case FilterOperator.GREATER_THAN:
                return FilterOperator.LESS_THAN_OR_EQUAL;
            default:
                return filterRule.operator;
        }
    }, [filterRule.operator]);

    const handleOperatorChange = useCallback(
        (inputValue: FilterOperator) => {
            let newOperator = inputValue;
            if (selectedToggleOperator === FilterOperator.EQUALS) {
                if (inputValue === FilterOperator.EQUALS) {
                    newOperator = FilterOperator.EQUALS;
                } else if (
                    inputValue === FilterOperator.GREATER_THAN_OR_EQUAL
                ) {
                    newOperator = FilterOperator.GREATER_THAN_OR_EQUAL;
                } else if (inputValue === FilterOperator.LESS_THAN_OR_EQUAL) {
                    newOperator = FilterOperator.LESS_THAN_OR_EQUAL;
                }
            } else {
                if (inputValue === FilterOperator.EQUALS) {
                    newOperator = FilterOperator.NOT_EQUALS;
                } else if (
                    inputValue === FilterOperator.GREATER_THAN_OR_EQUAL
                ) {
                    newOperator = FilterOperator.LESS_THAN;
                } else if (inputValue === FilterOperator.LESS_THAN_OR_EQUAL) {
                    newOperator = FilterOperator.GREATER_THAN;
                }
            }

            onChange({
                ...filterRule,
                operator: newOperator,
            });
        },
        [selectedToggleOperator, onChange, filterRule],
    );

    const handleToggleChange = useCallback(
        (inputValue: string) => {
            let newOperator = inputValue as FilterOperator;
            if (selectedConditionalOperator === FilterOperator.EQUALS) {
                if (inputValue === FilterOperator.EQUALS) {
                    newOperator = FilterOperator.EQUALS;
                } else if (inputValue === FilterOperator.NOT_EQUALS) {
                    newOperator = FilterOperator.NOT_EQUALS;
                }
            } else if (
                selectedConditionalOperator ===
                FilterOperator.GREATER_THAN_OR_EQUAL
            ) {
                if (inputValue === FilterOperator.EQUALS) {
                    newOperator = FilterOperator.GREATER_THAN_OR_EQUAL;
                } else if (inputValue === FilterOperator.NOT_EQUALS) {
                    newOperator = FilterOperator.LESS_THAN;
                }
            } else if (
                selectedConditionalOperator ===
                FilterOperator.LESS_THAN_OR_EQUAL
            ) {
                if (inputValue === FilterOperator.EQUALS) {
                    newOperator = FilterOperator.LESS_THAN_OR_EQUAL;
                } else if (inputValue === FilterOperator.NOT_EQUALS) {
                    newOperator = FilterOperator.GREATER_THAN;
                }
            }
            onChange({
                ...filterRule,
                operator: newOperator,
            });
        },
        [selectedConditionalOperator, onChange, filterRule],
    );

    const handleValueChange = useCallback(
        (inputValue: number) => {
            onChange({
                ...filterRule,
                values: [inputValue],
            });
        },
        [filterRule, onChange],
    );

    const handleFieldChange = useCallback(
        (updatedFilterRule: FilterRule) => {
            onChange(updatedFilterRule);
        },
        [onChange],
    );

    const handleMetricGroupDelete = useCallback(() => {
        const newAdditionalMetrics = [...additionalMetrics];

        //Info: Remove the additional metric that is created on the fly for the derived metric filter rule
        const filteredAdditionalMetrics = newAdditionalMetrics.filter(
            (metric) =>
                !(
                    metric.name === activeField?.name &&
                    metric.table === activeField?.table
                ),
        );
        setAdditionalMetrics(filteredAdditionalMetrics, false, groupIndex);

        //Info: Remove the derived metric filter rule from the filters metrics
        onDeleteItem(groupIndex);
    }, [
        activeField?.name,
        activeField?.table,
        additionalMetrics,
        groupIndex,
        onDeleteItem,
        setAdditionalMetrics,
    ]);

    const handleDuplicateMetricFilter = useCallback(() => {
        if (!matchedAdditionalMetric || !projectUuid) return;

        //Info: Find the base dimension that is used to create the additional metric
        const matchedDimension = dimensions.find(
            (field) =>
                field.name === matchedAdditionalMetric.baseDimensionName &&
                field.table === matchedAdditionalMetric.table,
        );
        if (!matchedDimension) return;
        if (!projectData || !projectData.warehouseConnection) return;

        //Todo: This recreates the additional metric, we should find a way to reuse the existing additional metric
        createFilterRule({
            field: matchedDimension,
            additionalMetrics,
            setAdditionalMetrics,
            activeRelation,
            filters,
            setFilters,
            index: groupIndex,
            additionalMetricsFilters: matchedAdditionalMetric.filters,
            projectUuid,
            customDimensions: undefined,
            setCustomDimensions: () => {},
            relatedOneToManyTables: [],
            warehouseType: projectData.warehouseConnection.type,
        });
    }, [
        activeRelation,
        additionalMetrics,
        dimensions,
        filters,
        groupIndex,
        matchedAdditionalMetric,
        setAdditionalMetrics,
        setFilters,
        projectUuid,
        projectData,
    ]);

    const isCampaignEvent = useMemo(
        () =>
            projectUuid &&
            projectData &&
            projectData.warehouseConnection &&
            activeField?.table ===
                getDedupedEventTableName(
                    projectUuid,
                    projectData.warehouseConnection.type,
                ),
        [activeField?.table, projectUuid, projectData],
    );
    const isJourneyEvent = useMemo(
        () =>
            projectUuid &&
            projectData &&
            projectData.warehouseConnection &&
            activeField?.table ===
                getJourneyEventsTableName(
                    projectUuid,
                    projectData.warehouseConnection.type,
                ),
        [activeField?.table, projectUuid, projectData],
    );

    const filterRuleForm = useMemo(() => {
        if (isCampaignEvent || isEventNameMetric || isJourneyEvent) {
            return (
                <EventMetricFilterRuleForm
                    isEditMode={isEditMode}
                    selectedToggleOperator={selectedToggleOperator}
                    handleToggleChange={handleToggleChange}
                    selectedConditionalOperator={selectedConditionalOperator}
                    handleOperatorChange={handleOperatorChange}
                    filterRule={filterRule}
                    handleValueChange={handleValueChange}
                    filters={filters}
                    setFilters={setFilters}
                    groupIndex={groupIndex}
                    additionalMetrics={additionalMetrics}
                    fields={fields}
                    handleFieldChange={handleFieldChange}
                />
            );
        }
        return (
            <RelatedRecordFilterRuleForm
                isEditMode={isEditMode}
                selectedToggleOperator={selectedToggleOperator}
                handleToggleChange={handleToggleChange}
                selectedConditionalOperator={selectedConditionalOperator}
                handleOperatorChange={handleOperatorChange}
                filterRule={filterRule}
                handleValueChange={handleValueChange}
                filters={filters}
                setFilters={setFilters}
                groupIndex={groupIndex}
                additionalMetrics={additionalMetrics}
                fields={fields}
            />
        );
    }, [
        isEventNameMetric,
        additionalMetrics,
        fields,
        filterRule,
        filters,
        setFilters,
        groupIndex,
        isCampaignEvent,
        handleFieldChange,
        handleOperatorChange,
        handleToggleChange,
        handleValueChange,
        isEditMode,
        selectedConditionalOperator,
        selectedToggleOperator,
        isJourneyEvent,
    ]);

    const handleSubmitFieldRule = useCallback(
        (items: FieldWithSuggestions[]) => {
            if (!items[0]) return;
            const newAdditionalMetrics = addFieldRuleToDerivedMetric({
                field: items[0],
                additionalMetrics,
                activeField,
            });
            setAdditionalMetrics(newAdditionalMetrics ?? [], false, groupIndex);
            close();
        },
        [
            activeField,
            additionalMetrics,
            setAdditionalMetrics,
            groupIndex,
            close,
        ],
    );

    const nestedFilters = useMemo(() => {
        if (isCampaignEvent) {
            return (
                <CampaignEventFilterGroup
                    activeField={activeField}
                    isEditMode={isEditMode}
                    additionalMetrics={additionalMetrics}
                    setAdditionalMetrics={setAdditionalMetrics}
                    groupIndex={groupIndex}
                    tableFields={tableFields}
                    dynamicFieldValues={dynamicFieldValues}
                />
            );
        }
        if (isJourneyEvent) {
            return (
                <JourneyEventFilterGroup
                    activeField={activeField}
                    isEditMode={isEditMode}
                    additionalMetrics={additionalMetrics}
                    setAdditionalMetrics={setAdditionalMetrics}
                    tableFields={tableFields}
                    groupIndex={groupIndex}
                    dynamicFieldValues={dynamicFieldValues}
                />
            );
        }
        return matchedAdditionalMetric?.filters?.length ? (
            <DerivedMetricChildFilters
                additionalMetrics={additionalMetrics}
                activeField={activeField}
                setAdditionalMetrics={setAdditionalMetrics}
                tableFields={tableFields}
                groupIndex={groupIndex}
                filterRule={filterRule}
                isEditMode={isEditMode}
                dynamicFieldValues={dynamicFieldValues}
            />
        ) : (
            isEditMode && (
                <DerivedMetricPropertySelect
                    tableFields={tableFields}
                    onSubmit={handleSubmitFieldRule}
                    opened={opened}
                    close={close}
                    open={open}
                />
            )
        );
    }, [
        activeField,
        additionalMetrics,
        groupIndex,
        setAdditionalMetrics,
        tableFields,
        opened,
        close,
        open,
        dynamicFieldValues,
        filterRule,
        isCampaignEvent,
        isEditMode,
        matchedAdditionalMetric,
        handleSubmitFieldRule,
        isJourneyEvent,
    ]);
    if (!activeField || !matchedAdditionalMetric) return null;

    return (
        <FilterRuleFormWrapper>
            <Stack className="gap-2">
                <Group className="gap-2">
                    {filterRuleForm}
                    {isEditMode && (
                        <MenuForDimensionFilterRule
                            onDelete={handleMetricGroupDelete}
                            duplicateFilter={handleDuplicateMetricFilter}
                        />
                    )}
                </Group>

                {nestedFilters}
            </Stack>
        </FilterRuleFormWrapper>
    );
};

export default React.memo(DerivedMetricFilterRuleForm);
