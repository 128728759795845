import { type ContactFields } from '@components/Audience/utils';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import { Button, Flex, List, Popover, Text } from '@mantine/core';
import { MagnifyingGlass, XCircle } from '@phosphor-icons/react';
import React, { type ReactNode } from 'react';
import { ButtonVariant } from '../../mantineTheme';

type UserSearchProps = {
    open: boolean;
    searchValue: string;
    setSearchValue: React.ChangeEventHandler<HTMLInputElement>;
    handleReset: () => void;
    handleListItemClick: (index: number) => void;
    selectedIndex: number;
    fields: {
        label: ContactFields;
        fieldId: string | undefined;
        icon: ReactNode;
    }[];
};
const UserSearch = ({
    open,
    searchValue,
    setSearchValue,
    handleReset,
    fields,
    handleListItemClick,
    selectedIndex,
}: UserSearchProps) => {
    const { t } = useLocale();

    return (
        <Popover opened={open && searchValue.length > 0} width="target">
            <Popover.Target>
                <SearchInput
                    icon={
                        selectedIndex > -1 ? (
                            fields[selectedIndex].icon
                        ) : (
                            <MagnifyingGlass
                                color={'rgb(var(--color-gray-500))'}
                            />
                        )
                    }
                    variant={ButtonVariant.UNSTYLED}
                    placeholder={t('audience.preview.search_user_placeholder')}
                    className="w-[15rem]"
                    value={searchValue}
                    onChange={setSearchValue}
                    rightSection={
                        searchValue && (
                            <Button
                                variant={ButtonVariant.SUBTLE}
                                onClick={handleReset}
                                className="p-0"
                            >
                                <XCircle color="rgb(var(--color-gray-600))" />
                            </Button>
                        )
                    }
                />
            </Popover.Target>
            <Popover.Dropdown className="border border-gray-200 rounded-md p-1">
                <List>
                    {fields.map(({ label }, index) => (
                        <List.Item
                            key={index}
                            className="cursor-pointer hover:bg-gray-200 text-sm font-normal text-gray-800 flex items-center p-[9px]"
                            onClick={() => handleListItemClick(index)}
                            icon={
                                <MagnifyingGlass
                                    color={'rgb(var(--color-gray-500))'}
                                />
                            }
                        >
                            <Flex gap="4px">
                                <Text color="gray">{label}:</Text>
                                <strong>{`\'${searchValue}\'`}</strong>
                            </Flex>
                        </List.Item>
                    ))}
                </List>
            </Popover.Dropdown>
        </Popover>
    );
};

export default UserSearch;
