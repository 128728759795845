import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type ApiSuccessEmpty,
    type TestProfileRequest,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';

const createTestProfiles = ({
    projectUuid,
    body,
}: {
    projectUuid: string;
    body: TestProfileRequest;
}) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/test-profiles`,
        method: 'POST',
        body: JSON.stringify(body),
    });

export const useCreateTestProfiles = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const queryClient = useQueryClient();

    return useMutation<ApiSuccessEmpty, ApiError, { body: TestProfileRequest }>(
        {
            mutationFn: ({ body }: { body: TestProfileRequest }) =>
                createTestProfiles({ projectUuid, body }),
            mutationKey: [QueryKeys.CREATE_TEST_PROFILE],
            onSuccess: async () => {
                showToastSuccess({
                    title: 'Test profiles updated successfully',
                });
                await queryClient.invalidateQueries([
                    QueryKeys.GET_TEST_PROFILE,
                    projectUuid,
                ]);
            },
            onError: (error) => {
                showToastError({
                    title: error.error.message,
                });
            },
            cacheTime: undefined,
        },
    );
};

const getTestProfiles = ({ projectUuid }: { projectUuid: string }) =>
    sortmentApi<any>({
        url: `/projects/${projectUuid}/test-profiles`,
        method: 'GET',
        body: undefined,
    });

export const useGetTestProfiles = ({
    successCallback,
}: {
    successCallback: (payload: any) => void;
}) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useQuery<any>({
        queryKey: [QueryKeys.GET_TEST_PROFILE, projectUuid],
        queryFn: () => getTestProfiles({ projectUuid }),
        enabled: !!projectUuid,
        onSuccess: (data) => {
            successCallback(data);
        },
    });
};
