import { buttonInputStyles } from '@components/common/Inputs/ButtonInput/ButtonInput.styles';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import { ShowDataType } from '@lightdash/common';
import {
    Box,
    Flex,
    Group,
    Input,
    Loader,
    Pagination,
    Text,
} from '@mantine/core';

import {
    flexRender,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
    type ColumnFiltersState,
    type Row,
    type Table,
    type TableOptions,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { t as translate } from 'i18next';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Grid, List } from 'react-feather';
import InfinityScrollTable from './InfinityScrollTable';
import {
    type EditableTableProps,
    type TableProps,
    type TablePropsManualPagination,
} from './type';
import { fuzzyFilter } from './utils';

const PAGINATION_NAVIGATION_CLASS =
    '!text-gray-600 hover:!text-gray-800 !bg-white !border-none disabled:!text-gray-400';

const EditableCell = ({
    getValue,
    row: { index },
    column: { id },
    table,
}: {
    getValue: any;
    row: {
        index: any;
    };
    column: {
        id: any;
    };
    table: any;
}) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);

    const onBlur = () => {
        table.options.meta?.updateData(index, id, value);
    };

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <Input
            value={value as string}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue(e.target.value)
            }
            onBlur={onBlur}
            styles={buttonInputStyles({
                placeholderColor: 'rgb(var(--color-gray-400))',
                textColor: 'rgb(var(--color-gray-800))',
            })}
            className="!w-fit"
        />
    );
};

const NoDataFound: React.FC<{ name?: string }> = ({ name }) => {
    const { t } = useLocale();
    return (
        <Box className="flex items-center justify-center w-full gap-2 px-3 py-2 ">
            <Text className="text-sm text-gray-600">
                {t('table.search_no_data_found', {
                    name: name || 'Data',
                })}
            </Text>
        </Box>
    );
};

interface TableTypeOptionsProps<TData> {
    viewType: ShowDataType;
    tableName?: string;
    table: Table<TData>;
    handleRowClick?: (data: Row<TData>) => void;
    customClass?: string;
    cellHeight?: string;
    gridItemBorder?: boolean;
}

export function TableTypeOptions<TData>({
    viewType,
    tableName,
    table,
    handleRowClick,
    customClass,
    cellHeight,
    gridItemBorder,
}: TableTypeOptionsProps<TData>) {
    switch (viewType) {
        case ShowDataType.LIST:
            return (
                <Box
                    className={`
                 ${customClass ? `${customClass}` : ''} `}
                    sx={() => ({
                        scrollbarWidth: 'none', // Hide scrollbar in Firefox
                        msOverflowStyle: 'none', // Hide scrollbar in IE 10+
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                    })}
                >
                    <table className="w-full">
                        <thead className={'bg-white sticky top-0 z-50'}>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className="w-full bg-gray-50"
                                >
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <th
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                className={`px-3.5 py-2.5 text-xs font-normal text-left text-gray-600 uppercase`}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <>
                                                        <Box className="w-max">
                                                            {flexRender(
                                                                header.column
                                                                    .columnDef
                                                                    .header,
                                                                header.getContext(),
                                                            )}
                                                        </Box>
                                                    </>
                                                )}
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="divide-y">
                            {table.getRowModel().rows.map((row) => {
                                return (
                                    <tr
                                        key={row.id}
                                        onClick={() =>
                                            handleRowClick &&
                                            handleRowClick(row)
                                        }
                                        className={`${
                                            Boolean(handleRowClick)
                                                ? 'cursor-pointer hover:bg-gray-50'
                                                : ''
                                        }`}
                                    >
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <td
                                                    key={cell.id}
                                                    className={`px-3.5 py-2.5 ${
                                                        cellHeight
                                                            ? cellHeight
                                                            : 'h-16'
                                                    }`}
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef
                                                            .cell,
                                                        cell.getContext(),
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {table.getRowModel().rows?.length === 0 && (
                        <NoDataFound name={tableName} />
                    )}
                </Box>
            );
        case ShowDataType.GRID:
            return (
                <Box className="grid grid-cols-4 gap-2 px-4 py-2 ">
                    {table.getRowModel().rows.map((row) => (
                        <Box
                            key={row.id}
                            className={`${
                                gridItemBorder && 'border rounded-lg'
                            }`}
                        >
                            {row.getVisibleCells().map((cell) => {
                                return (
                                    <Box key={cell.id}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    ))}
                </Box>
            );
    }

    return null;
}

export default function DataTable<TData, T = string>({
    name: tableName,
    handleRowClick,
    showSearch = true,
    searchPlaceholder,
    paginationText = translate('data_table.pagination_text_default'),
    withBorder = false,
    customClass,
    totalResultCount,
    cellHeight,
    isVirtualTable = false,
    gridSize = 4,
    gridItemBorder = true,
    pageSize = 10,
    tableBackgroundColor = 'gray',
    searchValue,
    onSearchChange,
    previewLimit,
    disablePagination,
    disablePaginationText,
    disablePageButtons,
    ...props
}: TableProps<TData, T>) {
    const {
        isManualPagination,
        onPageChange,
        pagination,
        isDataFetching,
        leftSection,
        rightSection,
        tableData,
        options,
        tabs,
        onTabChange,
        selectedTab,
    } = props as TablePropsManualPagination<TData, T>;
    const { isEditable, updateData } = props as EditableTableProps<TData, T>;
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);

    const [viewType, setViewType] = useState<ShowDataType>(ShowDataType.LIST);

    const [data, setData] = useState<TData[]>(tableData);

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const getFormatData = useCallback(() => {
        const option = options.find((item) => item.format === viewType);
        if (!!option) {
            return option.formatData;
        }
    }, [viewType, options]);

    // It checks if manual pagination is enabled, and if so, calculates whether to fetch the next page
    // based on the current scroll position relative to the table's dimensions.

    const tableOptions = {
        data: data,
        columns: getFormatData(),
        state: {
            columnFilters: columnFilters,
            globalFilter: searchValue,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: onSearchChange,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        globalFilterFn: fuzzyFilter,
        enableGlobalFilter: true,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
        ...(!disablePagination && {
            getPaginationRowModel: getPaginationRowModel(),
            manualPagination: isManualPagination,
            initialState: {
                pagination: {
                    pageSize,
                    pageIndex: 0,
                },
            },
        }),
        ...(isEditable && {
            defaultColumn: { cell: EditableCell },
            meta: {
                updateData,
            },
        }),
    };

    const table = useReactTable(tableOptions as unknown as TableOptions<TData>);

    useEffect(() => {
        if (isVirtualTable) {
            setData((prevData) => [...(prevData ?? []), ...tableData]);
        } else {
            setData([...tableData]);
        }
    }, [isVirtualTable, tableData, table]);

    // INFO - This is to reset the page index when the tab is changed
    useEffect(() => {
        table.setPageIndex(0);
    }, [selectedTab, table]);
    useEffect(() => {
        if (data && !isManualPagination) {
            // Force update the table data
            table.options.data = data;
            table.getRowModel().rows = table.getCoreRowModel().rows;

            // Reset pagination
            if (!isManualPagination) {
                table.setPageIndex(0);
            } else if (pagination) {
                table.setPagination({
                    pageIndex: pagination.currentPage - 1,
                    pageSize: pagination.perPage,
                });
            }
        }
    }, [data, isManualPagination, pagination, table]);
    const { rows } = table.getRowModel();

    const rowVirtualizer = useVirtualizer({
        count: !isManualPagination ? rows.length : pagination.total ?? 0,
        estimateSize: () => 64, //this is hardcoded beacause if heights are measured dynamicaliy using virtualItem.measureElement then this function is used currently we are not using dynamic height and it is mandatory field
        getScrollElement: () => tableContainerRef.current,
        measureElement:
            typeof window !== 'undefined' &&
            navigator.userAgent.indexOf('Firefox') === -1
                ? (element) => element?.getBoundingClientRect().height
                : undefined,
        overscan: 3,
    });

    useEffect(() => {
        if (isManualPagination) {
            const lastItemIndex =
                rowVirtualizer.getVirtualItems().at(-1)?.index || 0;
            const totalFetched = pagination.currentPage * pagination.perPage;
            if (
                lastItemIndex >= totalFetched - 1 && // Near the last fetched item
                !isDataFetching &&
                totalFetched < (pagination?.total ?? 0)
            ) {
                onPageChange(pagination.currentPage + 1);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        rowVirtualizer.getVirtualItems(),
        pagination,
        onPageChange,
        isDataFetching,
    ]);

    const [totalPages, setTotalPages] = useState<number>(1);

    useEffect(() => {
        if (isManualPagination && pagination && pagination.lastPage) {
            setTotalPages(pagination.lastPage);
        }
    }, [table, isManualPagination, pagination]);
    const totalManualRows = useMemo(() => {
        if (isManualPagination && pagination && pagination.total) {
            return pagination.total;
        }
        return table.getRowCount();
    }, [isManualPagination, pagination, table]);
    if (isManualPagination && isDataFetching)
        return (
            <Flex className="p-3.5" align="center">
                <Loader size={11} />
                <Text className="text-sm font-normal text-blu-800 px-1.5">
                    Loading...
                </Text>
            </Flex>
        );

    return (
        <Box
            className={`${
                tableBackgroundColor === 'gray' ? 'bg-gray-100' : ''
            } ${withBorder ? 'border rounded-lg border-shade-4' : ''}`}
        >
            <Box className="flex justify-between w-full pb-2 h-[2.75rem]">
                <Flex align="center" gap={8}>
                    {showSearch && (
                        <Box className="w-[15rem]">
                            <SearchInput
                                size="xs"
                                placeholder={searchPlaceholder}
                                defaultValue={searchValue}
                                onChange={(e) => {
                                    if (onSearchChange) {
                                        onSearchChange(e.target.value);
                                        table.setPageIndex(0);
                                    }
                                }}
                            />
                        </Box>
                    )}
                    <Group>{leftSection}</Group>
                </Flex>
                <Flex align="center" gap={4}>
                    <Group>{rightSection}</Group>
                    <Box className="flex items-center justify-center">
                        {options.length > 1 &&
                            options.some(
                                (option) => option.format === ShowDataType.GRID,
                            ) && (
                                <Box
                                    onClick={() =>
                                        setViewType(ShowDataType.GRID)
                                    }
                                    className={`cursor-pointer px-3.5 py-2.5 border border-shade-6 ${
                                        viewType === ShowDataType.GRID
                                            ? 'bg-shade-6'
                                            : ''
                                    }`}
                                >
                                    <Grid
                                        className={` h-3.5 w-3.5 ${
                                            viewType === ShowDataType.GRID
                                                ? 'text-gray-800'
                                                : 'text-gray-600'
                                        } `}
                                    />
                                </Box>
                            )}
                        {options.length > 1 &&
                            options.some(
                                (option) => option.format === ShowDataType.LIST,
                            ) && (
                                <Box
                                    onClick={() =>
                                        setViewType(ShowDataType.LIST)
                                    }
                                    className={`cursor-pointer px-3.5 py-2.5 border border-shade-6 ${
                                        viewType === ShowDataType.LIST
                                            ? 'bg-shade-6'
                                            : ''
                                    } `}
                                >
                                    <List
                                        className={`  h-3.5 w-3.5 ${
                                            viewType === ShowDataType.LIST
                                                ? 'text-gray-800 '
                                                : 'text-gray-600'
                                        } `}
                                    />
                                </Box>
                            )}
                    </Box>
                </Flex>
            </Box>
            <Box
                className={` ${
                    !withBorder
                        ? 'border border-gray-100 rounded-2xl p-0.5 mt-4'
                        : ''
                } bg-white`}
            >
                {tabs && tabs.length > 0 && (
                    <Flex className="gap-2 px-1 py-2">
                        {tabs.map((tab, index) => (
                            <Box
                                key={index}
                                className={`text-sm cursor-pointer px-2 py-1 font-semibold rounded-lg text-gray-700 hover:bg-gray-50 hover:text-gray-800 ${
                                    tab.key === selectedTab
                                        ? 'bg-shade-6 text-gray-800 hover:text-gray-800 hover:bg-shade-6'
                                        : ''
                                }`}
                                onClick={() => onTabChange(tab.key)}
                            >
                                {tab.label}
                            </Box>
                        ))}
                    </Flex>
                )}
                <Box
                    className={`bg-white overflow-hidden
                        ${
                            !withBorder
                                ? 'border rounded-xl border-gray-200'
                                : ''
                        } `}
                >
                    {isVirtualTable && tableContainerRef ? (
                        <InfinityScrollTable
                            loadMore={undefined}
                            viewType={viewType}
                            table={table}
                            handleRowClick={handleRowClick}
                            tableContainerRef={
                                tableContainerRef as React.RefObject<HTMLDivElement>
                            }
                            cellHeight={cellHeight}
                            rowVirtualizer={rowVirtualizer}
                            gridSize={gridSize}
                            isDataFetching={isDataFetching ?? false}
                            isNeedToRefresh={false}
                            isControlledFetching={false}
                            tableName={tableName ?? ''}
                        />
                    ) : (
                        <TableTypeOptions
                            viewType={viewType}
                            tableName={tableName}
                            table={table}
                            handleRowClick={handleRowClick}
                            cellHeight={cellHeight}
                            customClass={customClass}
                            gridItemBorder={gridItemBorder}
                        />
                    )}

                    {!isVirtualTable &&
                        table.getRowModel().rows?.length !== 0 &&
                        !disablePagination && (
                            <Box
                                className={`flex items-center ${
                                    disablePaginationText
                                        ? 'justify-end'
                                        : 'justify-between'
                                } gap-2 px-3 py-2 border-t border-shade-4`}
                            >
                                {!disablePaginationText &&
                                    (totalResultCount ? (
                                        <Text className="text-sm text-gray-600">
                                            {`Showing ${
                                                table.getState().pagination
                                                    .pageIndex *
                                                    table.getState().pagination
                                                        .pageSize +
                                                1
                                            } - ${Math.min(
                                                (table.getState().pagination
                                                    .pageIndex +
                                                    1) *
                                                    table.getState().pagination
                                                        .pageSize,
                                                tableData.length,
                                            )} of   ${
                                                previewLimit &&
                                                totalResultCount >= previewLimit
                                                    ? totalResultCount +
                                                      ' · Limiting this preview to ' +
                                                      previewLimit
                                                    : totalResultCount
                                            }`}
                                        </Text>
                                    ) : (
                                        <Text className="text-sm text-gray-600">
                                            {`Showing ${
                                                table.getState().pagination
                                                    .pageIndex *
                                                    table.getState().pagination
                                                        .pageSize +
                                                1
                                            } - ${Math.min(
                                                (table.getState().pagination
                                                    .pageIndex +
                                                    1) *
                                                    table.getState().pagination
                                                        .pageSize,
                                                totalManualRows,
                                            )} of ${totalManualRows} ${paginationText}`}
                                        </Text>
                                    ))}

                                {!disablePagination && (
                                    <Box>
                                        <Pagination.Root
                                            total={
                                                isManualPagination
                                                    ? totalPages
                                                    : table.getPageCount()
                                            }
                                            onChange={(newPage) => {
                                                if (isManualPagination)
                                                    onPageChange(newPage);

                                                table.setPageIndex(newPage - 1);
                                            }}
                                            value={
                                                isManualPagination
                                                    ? pagination.currentPage
                                                    : table.getState()
                                                          .pagination
                                                          .pageIndex + 1
                                            }
                                            getItemProps={() => ({
                                                className: '!w-auto',
                                            })}
                                        >
                                            <Group
                                                spacing={5}
                                                position="center"
                                            >
                                                <Pagination.Previous
                                                    className={`${PAGINATION_NAVIGATION_CLASS}`}
                                                />
                                                {!disablePageButtons && (
                                                    <Pagination.Items />
                                                )}
                                                <Pagination.Next
                                                    className={`${PAGINATION_NAVIGATION_CLASS}`}
                                                />
                                            </Group>
                                        </Pagination.Root>
                                    </Box>
                                )}
                            </Box>
                        )}
                </Box>
            </Box>
        </Box>
    );
}
