import { useOrganization } from '@hooks/organization/useOrganization';
import {
    useUpdateGlobalPreference,
    useUpdateSubscriptionPreferenceForChannel,
    useUserSubscriptionPreferencesForChannel,
} from '@hooks/subscriptionGroups/useSubscriptionPreference';
import { useLocale } from '@hooks/useLocale';
import {
    type CommunicationChannel,
    type SubscriptionGroupWithUserPreference,
    type UserPreferenceUpdateRequest,
} from '@lightdash/common';
import { SubscriptionGroupType } from '@lightdash/common/src/types/subscriptionGroup';
import { type CategoriesWithGroupsAndUserPreference } from '@lightdash/common/src/types/userSubscriptionPreference';
import { Box, Button, Flex, Loader, Text } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import { getChannel } from '../utils';
import Node from './Node';

interface NodeData {
    id: string;
    name: string;
    categoryId: string | null;
    description: string;
    type: string | undefined;
    weight: number | undefined;
    children: NodeData[] | undefined;
    userPreference: boolean;
}

interface CategoryWithGroups {
    category: {
        id: string;
        isGlobal: boolean;
    };
    groups: { id: string; userPreference: boolean; campaignId: string }[];
}

const UserSubscriptionPreferences = () => {
    const [treeData, setTreeData] = useState<NodeData[]>([]);
    const [isGlobalPreference, setIsGlobalPreference] = useState<boolean>(true);
    const location = useLocation();
    const { t } = useLocale();
    const searchParams = new URLSearchParams(location.search);
    const campaign = searchParams.get('campaign') || '';
    const channel = searchParams.get('channel') || '';
    const userId = searchParams.get('userId') || '';
    const projectId = searchParams.get('projectId') || '';
    const queryClient = useQueryClient();

    const { data: org } = useOrganization();

    const {
        data: userSubscriptionPreference,
        isLoading: isSubscriptionPreferenceLoading,
    } = useUserSubscriptionPreferencesForChannel({
        channel: getChannel(channel),
        userId: userId,
        projectId: projectId,
    });

    const {
        mutateAsync: updateSubscriptionPreference,
        isSuccess: isUpdateSubscriptionPreference,
        isLoading: isUpdatingPreference,
    } = useUpdateSubscriptionPreferenceForChannel();

    const {
        isLoading: isAllUnsubscribed,
        mutateAsync: mutateGlobalPreference,
    } = useUpdateGlobalPreference();

    const getCategoryPreferenceValue = useCallback(
        (groups: NodeData[]): boolean => {
            return groups.every((group) => group.userPreference === true);
        },
        [],
    );

    const transformGroup = useCallback(
        (
            group: SubscriptionGroupWithUserPreference,
            globalPreference: boolean,
        ) => ({
            id: group.id,
            categoryId: group.categoryId,
            name: group.name,
            description: group.description,
            type: group.type,
            weight: group.weight,
            userPreference:
                group.type !== SubscriptionGroupType.REQUIRED &&
                !globalPreference
                    ? globalPreference
                    : group.userPreference,
            children: undefined,
        }),
        [],
    );

    const transformData = useCallback(
        (
            data: CategoriesWithGroupsAndUserPreference,
            globalPreference: boolean,
        ) => {
            const newData: NodeData[] = [];
            data.forEach((item) => {
                const { category, groups } = item;

                if (category.isGlobal) {
                    groups.forEach((group) => {
                        newData.push(transformGroup(group, globalPreference));
                    });
                } else {
                    const sortedGroups = groups
                        .map((group) => transformGroup(group, globalPreference))
                        .sort((a, b) => {
                            if (!a.weight || !b.weight) {
                                return 0;
                            }
                            return a.weight - b.weight;
                        });

                    newData.push({
                        id: category.id,
                        categoryId: null,
                        name: category.name,
                        description: category.description,
                        weight: category.weight,
                        userPreference:
                            getCategoryPreferenceValue(sortedGroups),
                        type: undefined,
                        children: sortedGroups,
                    });
                }
            });
            newData.sort((a, b) => {
                if (!a.weight || !b.weight) {
                    return 0;
                }
                return a.weight - b.weight;
            });
            return [...newData];
        },
        [getCategoryPreferenceValue, transformGroup],
    );

    useEffect(() => {
        if (userSubscriptionPreference) {
            setIsGlobalPreference(userSubscriptionPreference.globalPreference);
            setTreeData(
                transformData(
                    userSubscriptionPreference.categoryWisePreference,
                    userSubscriptionPreference.globalPreference,
                ),
            );
        }
    }, [getCategoryPreferenceValue, transformData, userSubscriptionPreference]);

    const toggleUserPreference = useCallback(
        (id: string) => {
            const updatedTreeData = treeData.map((item) => {
                if (item.id === id) {
                    item.userPreference = !item.userPreference;
                    if (item.children) {
                        item.children = item.children.map((child) => {
                            if (child.type !== SubscriptionGroupType.REQUIRED)
                                return {
                                    ...child,
                                    userPreference: item.userPreference,
                                };
                            return {
                                ...child,
                                userPreference: child.userPreference,
                            };
                        });
                    }
                } else if (item.children) {
                    item.children = item.children.map((child) => {
                        if (
                            child.id === id &&
                            item.type !== SubscriptionGroupType.REQUIRED
                        ) {
                            child.userPreference = !child.userPreference;
                        }
                        return child;
                    });
                }
                const allChildrenTrue =
                    item.children &&
                    item.children.every((child) => child.userPreference);
                const anyChildFalse =
                    item.children &&
                    item.children.some((child) => !child.userPreference);

                if (allChildrenTrue) {
                    item.userPreference = true;
                } else if (anyChildFalse) {
                    item.userPreference = false;
                }
                return item;
            });

            setTreeData(updatedTreeData);
        },
        [treeData],
    );

    const processTreeData = useCallback(
        (
            subscriptionChannel: CommunicationChannel,
        ): UserPreferenceUpdateRequest => {
            let globalCategory: CategoryWithGroups = {
                category: {
                    id: '',
                    isGlobal: true,
                },
                groups: [],
            };

            let categories: CategoryWithGroups[] = [];

            treeData.forEach((item) => {
                if (!item.children || item.children.length === 0) {
                    globalCategory.groups.push({
                        id: item.id,
                        userPreference: item.userPreference,
                        campaignId: campaign,
                    });
                } else {
                    let category: CategoryWithGroups = {
                        category: {
                            id: item.id,
                            isGlobal: false,
                        },
                        groups: item.children.map((child: NodeData) => ({
                            id: child.id,
                            userPreference: child.userPreference,
                            campaignId: campaign,
                        })),
                    };
                    categories.push(category);
                }
            });

            return {
                userId,
                channel: subscriptionChannel,
                data: [globalCategory, ...categories],
            };
        },
        [treeData, userId, campaign],
    );

    const savePreference = useCallback(async () => {
        await updateSubscriptionPreference({
            payload: processTreeData(getChannel(channel)),
        });
        await mutateGlobalPreference({
            payload: {
                userId: userId,
                channel: getChannel(channel),
                projectId: projectId,
                actionTaken: true,
                campaignId: campaign,
            },
        });
    }, [
        updateSubscriptionPreference,
        processTreeData,
        channel,
        mutateGlobalPreference,
        userId,
        projectId,
        campaign,
    ]);

    const updateGlobalPreference = useCallback(async () => {
        setIsGlobalPreference(false);
        await mutateGlobalPreference({
            payload: {
                userId: userId,
                channel: getChannel(channel),
                projectId: projectId,
                actionTaken: false,
                campaignId: campaign,
            },
        });
        await queryClient.invalidateQueries([
            QueryKeys.GET_SUBSCRIPTION_PREFERENCE_FOR_CHANNEL,
        ]);
    }, [
        mutateGlobalPreference,
        queryClient,
        channel,
        projectId,
        userId,
        campaign,
    ]);

    if (isSubscriptionPreferenceLoading) {
        return (
            <Box className="w-screen h-screen flex justify-center items-center">
                <Flex className="p-3.5" align="center">
                    <Loader size={11} />
                    <Text className="text-sm font-normal text-blu-800 px-1.5">
                        {t('subscription_body.loading')}
                    </Text>
                </Flex>
            </Box>
        );
    }

    if (userSubscriptionPreference && !isGlobalPreference)
        return (
            <Box className="w-screen h-screen flex flex-col justify-center items-center">
                <Box className="w-[40%]">
                    <Text className="text-gray-800 text-lg font-medium">
                        {t(
                            'subscription_preference.you_ve_been_unsubscribed_from',
                        )}{' '}
                        {org && org.name} {channel}.{' '}
                    </Text>
                    <Text className="mt-3 text-gray-600 text-sm font-medium">
                        {t('subscription_preference.unsubscribe_all')}
                    </Text>
                    <Text className="flex mt-3.5 text-gray-600 font-medium text-xs">
                        {t(
                            'unsubscribe_group.manage_all_your_subscription_preferences',
                        )}{' '}
                        <Text
                            className="ml-1 underline cursor-pointer"
                            onClick={() => setIsGlobalPreference(true)}
                        >
                            {t('unsubscribe_group.here')}
                        </Text>
                    </Text>
                </Box>
            </Box>
        );

    return (
        <Box className="w-screen h-screen flex flex-col justify-center items-center">
            <Box className="w-[40%]">
                <Text className="text-gray-800 text-lg font-medium mb-3">
                    {t('subscription_preference.choose_what')} {channel}{' '}
                    {t('subscription_preference.youd_like_to_receive')}
                </Text>
                <Text className="text-gray-600 text-sm font-medium">
                    {t(
                        'subscription_preference.well_only_send_you_content_that_you_re_interested_in',
                    )}
                </Text>
                <Box className=" h-[28.87rem] overflow-y-scroll mt-3.5">
                    {treeData.map((item) => (
                        <Node
                            key={item.id}
                            nodeData={item}
                            onChecked={(id) => toggleUserPreference(id)}
                        />
                    ))}
                </Box>
                <Flex className="py-2 ">
                    <Button
                        className="bg-black rounded py-1.5 px-3 text-white text-sm font-medium mr-1.5 hover:!bg-black"
                        onClick={() => savePreference()}
                        loading={isUpdatingPreference}
                    >
                        {isUpdateSubscriptionPreference
                            ? t('subscription_preference.preferences_saved')
                            : t('subscription_preference.save_preferences')}
                    </Button>
                    <Button
                        className="bg-gray-500 rounded py-1.5 px-3 text-black text-sm font-medium hover:!bg-gray-500"
                        onClick={() => updateGlobalPreference()}
                        loading={isAllUnsubscribed}
                    >
                        {t('subscription_preference.unsubscribe_from_all')}
                    </Button>
                </Flex>
            </Box>
        </Box>
    );
};

export default UserSubscriptionPreferences;
