import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import {
    type AdditionalMetric,
    type CompiledDimension,
    type CompiledRelationTable,
    type CustomDimension,
    type FieldType,
    type InsertCustomAttribute,
    type MetricFilterRule,
    type MetricQuery,
    type MetricType,
    type TableCalculation,
} from '@lightdash/common';

export enum ActionType {
    SELECT_TABLE,
    SELECT_DIMENSION,
    SET_FILTERS,
    ADD_BASE_TABLE_DETAILS_TO_PAYLOAD,
    ADD_DIMENSION_DETAILS_TO_PAYLOAD,
    ADD_CUSTOM_METRIC_NAME_DETAILS,
    ADD_CUSTOM_METRIC_DESCRIPTION_DETAILS,
    ADD_FILTERS_TO_CUSTOM_METRIC,
    ADD_CUSTOM_METRIC_TYPE_DETAIL,
    SET_CUSTOM_METRIC,
    SET_FIELD_TYPE,
    SET_SQL_IN_CUSTOM_SQL_DIMENSION,
    SET_DEFINITION,
    SET_TAGS,
    RESET_STATE,
}

export interface CustomAttributeReducerState {
    customAttributePayload: InsertCustomAttribute;
    initialCustomAttributePayload: InsertCustomAttribute;
    isEditMode: boolean;
    selectedTable: CompiledRelationTable | undefined;
    selectedDimension: CompiledDimension | FieldWithSuggestions | undefined;
    filters: MetricQuery['filters'] | undefined;
    fieldType: FieldType | undefined;
}

export type CustomAttributeContextType = {
    reducerState: CustomAttributeReducerState;
    actions: {
        selectTable: (selectedTable: CompiledRelationTable | undefined) => void;
        selectDimension: (
            selectedDimension:
                | CompiledDimension
                | FieldWithSuggestions
                | undefined,
        ) => void;
        setFilters: (filters: MetricQuery['filters'] | undefined) => void;
        addBaseTableDetails: (
            selectedTable: CompiledRelationTable | undefined,
        ) => void;
        addSelectedDimensionDetails: (
            selectedDimension:
                | CompiledDimension
                | FieldWithSuggestions
                | undefined,
        ) => void;
        addNameDetailsToPayload: (name: string) => void;
        addDescriptionDetailsToPayload: (description: string) => void;
        addMetricTypeDetailsToPayload: (metric: MetricType) => void;
        addFilttersToCustomMetric: (
            filters: MetricFilterRule[] | undefined,
        ) => void;
        setCustomMetricData: (data: InsertCustomAttribute) => void;
        setFieldType: (fieldType: FieldType) => void;
        setSqlInCustomSqlDimension: (sql: string) => void;
        setDefinition: (
            definition:
                | AdditionalMetric
                | CustomDimension
                | TableCalculation
                | null,
        ) => void;
        setTags: (tags: string[]) => void;
        resetState: (newState: CustomAttributeReducerState) => void;
    };
};
