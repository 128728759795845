import { SortmentLogo } from '@assets/icons/Logo';
import RouterNavLink from '@components/Router/RouterNavLink';
import { useLocale } from '@hooks/useLocale';
import { Box, getDefaultZIndex, Header, Text } from '@mantine/core';
import {
    AsteriskSimple,
    CursorClick,
    House,
    Sparkle,
    TreeStructure,
} from '@phosphor-icons/react';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';
import { NAVBAR_HEIGHT } from '@utils/constants';
import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import MenuItems from './MenuItems';
import WorkspaceSwitcher from './ProjectSwitcher';
import UserMenu from './UserMenu';

const activeClass = '!bg-white/30 rounded-full shadow-card';
const inactiveClass = 'hover:!bg-white/10 hover:rounded-full';
interface NavBarProps {
    showMenuItems?: boolean;
    showWorkspaceSwitcher?: boolean;
    showRelation?: boolean;
    showCustomMetric?: boolean;
    showEventsManager?: boolean;
    showAskAi?: boolean;
}

const NavBar: React.FC<NavBarProps> = ({
    showMenuItems = true,
    showWorkspaceSwitcher = true,
    showRelation = true,
    showCustomMetric = true,
    showEventsManager = true,
    showAskAi = true,
}) => {
    const { t } = useLocale();
    const navigate = useNavigate();
    const location = useLocation();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showAiAnalyst } = useAiAnalystContext((context) => context.state);
    const { setShowAiAnalyst } = useAiAnalystContext(
        (context) => context.actions,
    );

    const navigationSection = useMemo(
        () => (
            <Box className="flex flex-row items-center gap-3.5">
                <Box
                    className={`px-2 py-1 hover:bg-white/10 rounded-lg cursor-pointer ${
                        location.pathname.includes('/home')
                            ? 'bg-white/30 hover:bg-white/30'
                            : ''
                    }`}
                    onClick={async () => {
                        await navigate(`/projects/${projectUuid}/home`);
                        setShowAiAnalyst(false);
                    }}
                >
                    <House color="white" />
                </Box>

                {Boolean(showMenuItems) && <MenuItems />}
            </Box>
        ),
        [
            location.pathname,
            navigate,
            projectUuid,
            setShowAiAnalyst,
            showMenuItems,
        ],
    );

    return (
        <>
            <Box h={NAVBAR_HEIGHT} className="!bg-transparent" />
            <Header
                height={NAVBAR_HEIGHT}
                fixed
                zIndex={getDefaultZIndex('app')}
                className="flex !bg-transparent"
                withBorder={false}
            >
                <Box
                    className={`flex flex-row items-center justify-between shrink-0 w-full px-9 !bg-transparent`}
                >
                    <div className="flex items-center gap-8">
                        <SortmentLogo />
                        {navigationSection}
                    </div>
                    <div className="flex items-center gap-2.5">
                        {!location.pathname.includes('/home') &&
                            !location.pathname.includes('/settings') &&
                            showAskAi &&
                            !showAiAnalyst && (
                                <Box
                                    onClick={() => setShowAiAnalyst(true)}
                                    className="flex items-center justify-center gap-1 px-2 py-1 rounded-lg cursor-pointer bg-white/10"
                                >
                                    <Sparkle color="white" />
                                    <Text className="text-sm text-white">
                                        {t('navbar_ask')}
                                    </Text>
                                    <Text className="text-sm text-white">
                                        {t('navbar_ai')}
                                    </Text>
                                </Box>
                            )}
                        {showEventsManager && (
                            <RouterNavLink
                                exact
                                to={`/projects/${projectUuid}/events`}
                                label=""
                                icon={
                                    <CursorClick
                                        color="white"
                                        weight="duotone"
                                    />
                                }
                                styles={{
                                    root: {
                                        padding: '0.325rem 0.325rem',
                                    },
                                    icon: {
                                        marginRight: 0,
                                    },
                                }}
                                activeclass={activeClass}
                                inactiveclass={inactiveClass}
                            />
                        )}
                        {showRelation && (
                            <RouterNavLink
                                exact
                                to={`/projects/${projectUuid}/relations`}
                                label=""
                                icon={
                                    <TreeStructure
                                        color="white"
                                        weight="duotone"
                                    />
                                }
                                styles={{
                                    root: {
                                        padding: '0.325rem 0.325rem',
                                    },
                                    icon: {
                                        marginRight: 0,
                                    },
                                }}
                                activeclass={activeClass}
                                inactiveclass={inactiveClass}
                            />
                        )}
                        {showCustomMetric && (
                            <RouterNavLink
                                exact
                                to={`/projects/${projectUuid}/traits`}
                                label=""
                                icon={
                                    <AsteriskSimple
                                        color="white"
                                        weight="duotone"
                                    />
                                }
                                styles={{
                                    root: {
                                        padding: '0.325rem 0.325rem',
                                    },
                                    icon: {
                                        marginRight: 0,
                                    },
                                }}
                                activeclass={activeClass}
                                inactiveclass={inactiveClass}
                            />
                        )}
                        {showWorkspaceSwitcher && <WorkspaceSwitcher />}

                        <UserMenu />
                    </div>
                </Box>
            </Header>
        </>
    );
};

export default React.memo(NavBar);
