import LoadingState from '@components/common/LoadingState';
import Modal from '@components/common/modal/Modal';
import { toFieldsWithSuggestions } from '@components/Profiles/utils';
import UserSearch from '@components/UserSearch';
import useContactFieldOptions from '@components/UserSearch/useContactFieldOptions';
import {
    useAudiencePreviewById,
    useAudiencePreviewByPayload,
} from '@hooks/useAudiencePreview';
import { useLocale } from '@hooks/useLocale';
import {
    FilterOperator,
    JoinType,
    QueryGenerationStrategy,
    type ApiSqlQueryResults,
    type AudiencePreviewConfig,
    type AudiencePreviewPayload,
    type DimensionType,
    type UserSearchByContactFieldPayload,
} from '@lightdash/common';
import { Box, Button, Flex, Stack, Text } from '@mantine/core';
import { ArrowsClockwise, WifiSlash } from '@phosphor-icons/react';
import useProjectContext from '@providers/Project/useProjectContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { v4 as uuid4 } from 'uuid';
import { ButtonVariant } from '../../../mantineTheme';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import { modifyFieldsWithSelectionStatus } from '../Filters/FieldListItem/utils';
import { getCustomDimensionsFromActiveRelation } from '../Filters/FilterInputs/utils';
import { useFieldsWithSuggestions } from '../Filters/FiltersCard/useFieldsWithSuggestions';
import { getDimensionsAndMetrics, getUserIdColumnFromRelation } from '../utils';
import AudiencePreviewPropertiesPanel from './AudiencePreviewPropertiesPanel';
import AudiencePreviewTable from './AudiencePreviewTable';
interface AudiencePreviewModalProps {
    isEditMode: boolean;
    data: Record<string, unknown>[];
    fields: Record<string, { type: DimensionType }>;
    opened: boolean;
    close: () => void;
    getAudiencePayload?: AudiencePreviewPayload;
    initialColumns?: AudiencePreviewConfig;
    generationStrategy: string;
    onColumnChange?: (columns: string[]) => void;
    audienceId?: string;
    handlePreview:
        | ((
              fields: string[],
              contactFilters?: UserSearchByContactFieldPayload,
          ) => void)
        | undefined;
    isValidQuery: boolean | undefined;
    showPropertySelect: boolean;
    footerRightSection: React.ReactNode;
    bottomSection: React.ReactNode;
    isApiCallTimeout: boolean | undefined;
    onPropertySelect: undefined | ((items: string[]) => void);
    isLoading?: boolean;
    // irrespective of the generation strategy, the property select will be shown if this is true
    allowPropertySelect?: boolean;
    rightSection?: React.ReactNode;
    customTitle?: string;
}

const AudiencePreviewModal = ({
    isEditMode,
    opened,
    close,
    data,
    fields,
    initialColumns,
    generationStrategy,
    getAudiencePayload,
    onColumnChange,
    audienceId,
    handlePreview,
    isValidQuery,
    footerRightSection,
    showPropertySelect,
    bottomSection,
    isApiCallTimeout,
    onPropertySelect,
    isLoading,
    allowPropertySelect = false,
    rightSection,
    customTitle,
}: AudiencePreviewModalProps) => {
    const MODALHEIGHT = `${Object.keys(data).length > 0 ? '37.5rem' : ''}`;
    const { t } = useLocale();

    const {
        activeRelation,
        customDimensionsFields,
        activeRelationUuid,
        getTableRelation,
    } = useRelationContext();
    const { projectData } = useProjectContext();

    const [previewSuccess, setPreviewSuccess] = useState<boolean>(true);
    const [searchFieldValue, setSearchFieldValue] = useState<string>('');
    const [selectedContactFieldIdx, setSelectedContactFieldIdx] =
        useState<number>(-1);
    const [audiencePreviewData, setAudiencePreviewData] =
        useState<ApiSqlQueryResults>({
            fields: {},
            rows: [],
        });
    const [menuOpened, setMenuOpened] = useState(false);
    const [previewColumns, setPreviewColumns] = useState<string[]>([]);
    const [refreshButtonVisible, setRefreshButtonVisible] = useState(false);
    const [contactValueExists, setContactValueExists] = useState(false);

    const { mutateAsync: mutateAsyncPreview, isLoading: isPreviewDataLoading } =
        useAudiencePreviewByPayload();
    const { mutateAsync: mutateAsyncView, isLoading: isViewLoading } =
        useAudiencePreviewById();

    const { audienceUuid: audienceUuiParam } = useParams<{
        audienceUuid: string;
    }>();

    const { contactFields } = useContactFieldOptions();
    const audienceUuid = audienceUuiParam ?? audienceId;

    const filteredRelation = getTableRelation([
        JoinType.one_one,
        JoinType.many_one,
    ]);
    const userIdColumn = useMemo(() => {
        return getUserIdColumnFromRelation(activeRelation);
    }, [activeRelation]);
    useEffect(() => {
        if (searchFieldValue.length > 0) {
            setMenuOpened(true);
        }
    }, [searchFieldValue]);

    const showPropertySelectPanel = useMemo(() => {
        if (allowPropertySelect) {
            return true;
        }
        return (
            showPropertySelect &&
            generationStrategy &&
            generationStrategy === QueryGenerationStrategy.AUDIENCE_BUILDER
        );
    }, [allowPropertySelect, generationStrategy, showPropertySelect]);

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });
    const filteredFields = toFieldsWithSuggestions(
        filteredRelation,
        fieldsWithSuggestions,
        customDimensionsFields,
    );
    const customDimensions = useMemo(
        () =>
            getCustomDimensionsFromActiveRelation(
                activeRelation,
                filteredRelation?.map((table) => table.name) ?? [],
            ),
        [activeRelation, filteredRelation],
    );

    useEffect(() => {
        setAudiencePreviewData({ fields, rows: data });
    }, [data, fields]);

    const getAudiencePreviewData = useCallback(
        async (previewDataColumns: string[], index?: number) => {
            const { dimensions: dimensionsFieldIds, metrics: metricsFieldIds } =
                getDimensionsAndMetrics(
                    previewDataColumns,
                    fieldsWithSuggestions,
                    customDimensions,
                );
            const contactFilters =
                searchFieldValue.length > 0 &&
                (index ?? selectedContactFieldIdx) > -1
                    ? {
                          contactFieldId:
                              contactFields[index ?? selectedContactFieldIdx]
                                  .fieldId!,
                          contactValue: searchFieldValue,
                      }
                    : undefined;
            // check if search value exists at all
            if (contactFilters) {
                await mutateAsyncPreview(
                    {
                        relationUuid: activeRelationUuid,
                        data: {
                            metricQuery: {
                                and: [
                                    {
                                        filters: {
                                            dimensions: {
                                                id: uuid4(),
                                                and: [
                                                    {
                                                        id: uuid4(),
                                                        target: {
                                                            fieldId:
                                                                contactFilters.contactFieldId,
                                                        },
                                                        operator:
                                                            FilterOperator.INCLUDE,
                                                        values: [
                                                            contactFilters.contactValue,
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                        additionalMetrics: [],
                                        dimensions: [],
                                        exploreName: '',
                                        limit: 1,
                                        metrics: [],
                                        sorts: [],
                                        tableCalculations: [],
                                    },
                                ],
                                id: uuid4(),
                            },
                        },
                        dimensions: [],
                        metrics: [],
                    },
                    {
                        onSuccess: (response) => {
                            if (!response.rows.length) {
                                setPreviewSuccess(true);
                                setAudiencePreviewData({
                                    fields: {},
                                    rows: [],
                                });
                                setRefreshButtonVisible(false);
                                setContactValueExists(false);
                                return;
                            } else {
                                setContactValueExists(true);
                            }
                        },
                    },
                );
            }

            if (handlePreview != undefined && !isValidQuery) {
                await handlePreview(previewDataColumns, contactFilters);
            } else if (isEditMode && getAudiencePayload) {
                await mutateAsyncPreview(
                    {
                        relationUuid: activeRelationUuid,
                        data: {
                            ...getAudiencePayload,
                            contactFilters,
                        },
                        dimensions: dimensionsFieldIds,
                        metrics: metricsFieldIds,
                    },
                    {
                        onSuccess: (response) => {
                            setPreviewSuccess(true);
                            const newColumns = Object.keys(response.fields);
                            if (onColumnChange) onColumnChange(newColumns);
                            setAudiencePreviewData(response);
                        },
                    },
                );
            } else if (audienceUuid) {
                await mutateAsyncView(
                    {
                        dimensions: dimensionsFieldIds,
                        metrics: metricsFieldIds,
                        audienceId: audienceUuid,
                        contactFilters,
                    },
                    {
                        onSuccess: (response) => {
                            setPreviewSuccess(true);
                            const newColumns = Object.keys(response.fields);
                            if (onColumnChange) onColumnChange(newColumns);
                            setAudiencePreviewData(response);
                        },
                    },
                );
            }
        },
        [
            mutateAsyncView,
            mutateAsyncPreview,
            activeRelationUuid,
            audienceUuid,
            isEditMode,
            getAudiencePayload,
            onColumnChange,
            handlePreview,
            isValidQuery,
            fieldsWithSuggestions,
            customDimensions,
            searchFieldValue,
            contactFields,
            selectedContactFieldIdx,
        ],
    );

    const previewRows = useMemo(() => {
        if (isApiCallTimeout) {
            return (
                <Stack>
                    <Flex
                        justify="center"
                        align="center"
                        h={350}
                        className="bg-gray-100 border border-gray-200 rounded-md"
                    >
                        {' '}
                        <Flex gap={4} align="center">
                            <WifiSlash weight="duotone" />
                            <Text className="text-sm font-normal text-gray-800 ">
                                {t('audience_preview_data.api_call_timeout')}
                            </Text>
                        </Flex>
                    </Flex>
                    {bottomSection}
                </Stack>
            );
        }
        if (isLoading || isPreviewDataLoading || isViewLoading) {
            return <LoadingState title="" />;
        }
        if (Object.keys(audiencePreviewData.rows).length <= 0) {
            return (
                <Box
                    className={`flex flex-col items-center justify-center h-full border border-gray-400 h-[27rem] ${
                        refreshButtonVisible ? 'opacity-50' : ''
                    }`}
                >
                    <Text className="text-sm font-normal text-gray-800">
                        {t('audience_preview.no_users_found')}
                    </Text>
                    <Text className="text-sm font-normal text-gray-600">
                        {contactValueExists
                            ? t(
                                  'audience_preview.no_audience_search_value_not_found',
                              )
                            : t('audience_preview.no_audience')}
                    </Text>
                </Box>
            );
        }

        return (
            <Box
                className={`w-full h-full opacity-${
                    refreshButtonVisible ? '50' : '100'
                }`}
            >
                <AudiencePreviewTable
                    isFetching={isPreviewDataLoading || isViewLoading}
                    data={audiencePreviewData.rows}
                    fields={audiencePreviewData.fields}
                    generationStrategy={generationStrategy}
                    customClass=""
                    onRowClick={(row) => {
                        if (refreshButtonVisible) {
                            return;
                        }
                        if (userIdColumn && userIdColumn in row) {
                            const userId = row[userIdColumn] as string;
                            window.open(
                                `/projects/${projectData?.projectUuid}/profiles/${userId}`,
                                '_blank',
                            );
                        }
                    }}
                />
            </Box>
        );
    }, [
        isApiCallTimeout,
        audiencePreviewData,
        generationStrategy,
        isPreviewDataLoading,
        isViewLoading,
        t,
        bottomSection,
        userIdColumn,
        refreshButtonVisible,
        projectData?.projectUuid,
        contactValueExists,
        isLoading,
    ]);

    const previewBody = useMemo(() => {
        const previewContent = (
            <Box
                className={`relative w-full h-full ${
                    refreshButtonVisible ? 'overflow-hidden' : ''
                }`}
            >
                <Box
                    className={
                        refreshButtonVisible ? 'pointer-events-none' : ''
                    }
                >
                    {previewSuccess && previewRows}
                </Box>
                {refreshButtonVisible && (
                    <Box className="absolute inset-0 bg-gray-500 bg-opacity-30 flex items-center justify-center z-10 pointer-events-auto">
                        <Button
                            variant={ButtonVariant.DEFAULT}
                            onClick={async () => {
                                setRefreshButtonVisible(false);
                                await getAudiencePreviewData(previewColumns);
                                if (onPropertySelect) {
                                    onPropertySelect(previewColumns);
                                }
                            }}
                            className="flex h-[36px] items-center rounded-[9px] px-[11px] py-[8px] z-20"
                            disabled={isPreviewDataLoading || isViewLoading}
                        >
                            <Box className="flex items-center gap-2">
                                <ArrowsClockwise size={14} />
                                {t('audience.preview.refresh_data')}
                            </Box>
                        </Button>
                    </Box>
                )}
            </Box>
        );
        if (!rightSection) {
            return (
                <Box className="relative w-[67%] flex-[2] overflow-auto border border-gray-200">
                    {previewContent}
                </Box>
            );
        }
        return (
            <Box className="flex relative w-[67%] flex-[2] overflow-auto border border-gray-200">
                <Box className="w-[40rem] relative overflow-auto border border-gray-200">
                    {previewContent}
                </Box>
                <Box className="w-[25rem] relative overflow-auto border border-gray-200">
                    {rightSection}
                </Box>
            </Box>
        );
    }, [
        previewSuccess,
        previewRows,
        rightSection,
        refreshButtonVisible,
        isPreviewDataLoading,
        isViewLoading,
        getAudiencePreviewData,
        previewColumns,
        onPropertySelect,
        t,
    ]);
    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title={
                    <>
                        <h2>
                            {customTitle
                                ? customTitle
                                : t('audience.preview.title')}
                        </h2>
                        {showPropertySelectPanel && (
                            <Box className="mr-2.5">
                                <UserSearch
                                    fields={contactFields}
                                    selectedIndex={selectedContactFieldIdx}
                                    open={
                                        menuOpened &&
                                        searchFieldValue.length > 0
                                    }
                                    handleListItemClick={(index: number) => {
                                        setSelectedContactFieldIdx(index);
                                        setMenuOpened(false);
                                        setRefreshButtonVisible(true);
                                    }}
                                    handleReset={() => {
                                        setSearchFieldValue('');
                                        setSelectedContactFieldIdx(-1);
                                        setRefreshButtonVisible(true);
                                    }}
                                    setSearchValue={(e) => {
                                        setSearchFieldValue(e.target.value);
                                    }}
                                    searchValue={searchFieldValue}
                                />
                            </Box>
                        )}
                    </>
                }
                centered
                size={showPropertySelectPanel ? '75rem' : '50rem'}
                styles={(_params) => ({
                    header: {
                        borderBottom: 'unset !important',
                        height: '3.75rem',
                    },
                    content: {
                        height: MODALHEIGHT,
                    },
                    title: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    },
                    close: {
                        margin: '0px !important',
                    },
                    body: {
                        padding: '0px',
                    },
                })}
                footerLeftSection={bottomSection}
                footerRightSection={footerRightSection}
                withContentPadding={false}
                closeButtonProps={{
                    variant: 'light',
                    className: 'h-9 w-9',
                }}
                contentCustomClass={`${
                    bottomSection ? 'h-[25rem]' : 'h-[30rem]'
                }`}
            >
                <Box className="flex h-full">
                    {audiencePreviewData.rows.length > 0 &&
                        initialColumns &&
                        showPropertySelectPanel && (
                            <AudiencePreviewPropertiesPanel
                                fieldsWithSelectStatus={modifyFieldsWithSelectionStatus(
                                    {
                                        fields: Object.values(filteredFields),
                                        selectedFieldIds: Object.keys(
                                            audiencePreviewData.fields,
                                        ),
                                        shouldDisableChecked: false,
                                    },
                                )}
                                setPreviewColumns={setPreviewColumns}
                                onPropertyToggle={(items: string[]) => {
                                    setRefreshButtonVisible(true);
                                    if (onPropertySelect) {
                                        onPropertySelect(items);
                                    }
                                }}
                            />
                        )}
                    {previewBody}
                    {/* {refreshButtonVisible && refreshButton} */}
                </Box>
            </Modal>
        </>
    );
};

export default AudiencePreviewModal;
