import { sortmentApi } from '@api/index';
import { SchemaFileType } from '@components/SchemaBuilder/Builder/types';
import {
    type ApiError,
    type ApiSuccessEmpty,
    type CreateRelationTableRequest,
    type DimensionConfigurationResult,
    type RelationSchemaTableResponse,
    type SummaryRelation,
    type TableFileUploadPayload,
    type TablePreviewPayload,
    type WarehouseTableInfo,
    type WarehouseTableInfoBase,
} from '@lightdash/common';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

const getWarehouseTables = async (projectId: string) =>
    sortmentApi<WarehouseTableInfoBase[]>({
        url: `/projects/${projectId}/warehouse-catalog/tables`,
        method: 'GET',
        body: undefined,
    });

export const useWarehouseTables = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    const tables = useQuery<WarehouseTableInfoBase[], ApiError>({
        queryKey: [QueryKeys.WAREHOUSE_TABLES, projectUuid],
        queryFn: () => getWarehouseTables(projectUuid),
        keepPreviousData: true,
    });

    return tables;
};

export const getTableMetadata = async (
    projectId: string | undefined,
    database: string,
    schema: string,
    table: string,
) =>
    sortmentApi<WarehouseTableInfo>({
        url: `/projects/${projectId}/warehouse-catalog/database/${database}/schema/${schema}/tables/${table}`,
        method: 'GET',
        body: undefined,
    });

export const useTableMetadata = ({
    database,
    schema,
    table,
    schemaFileType,
}: WarehouseTableInfoBase & { schemaFileType: SchemaFileType }) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const tables = useQuery<WarehouseTableInfo, ApiError>({
        queryKey: [
            QueryKeys.WAREHOUSE_TABLES,
            projectUuid,
            database,
            schema,
            table,
        ],
        queryFn: () => getTableMetadata(projectUuid, database, schema, table),
        keepPreviousData: true,
        enabled: Boolean(
            database &&
                schema &&
                table &&
                schemaFileType === SchemaFileType.WAREHOUSE,
        ),
    });

    return tables;
};

export const getTableDetail = async (
    projectUuid: string | undefined,
    relationUuid: string,
    tableId: string,
) =>
    sortmentApi<RelationSchemaTableResponse>({
        url: `/projects/${projectUuid}/relations/${relationUuid}/tables/${tableId}`,
        method: 'GET',
        body: undefined,
    });

const getRelations = async (projectId: string | undefined) =>
    sortmentApi<SummaryRelation[]>({
        url: `/projects/${projectId}/relations`,
        method: 'GET',
        body: undefined,
    });

export const useFetchRelations = (projectUuid: string | undefined) => {
    const relations = useQuery<SummaryRelation[], ApiError>({
        queryKey: [QueryKeys.RELATIONS, projectUuid],
        queryFn: () => getRelations(projectUuid),
        keepPreviousData: true,
    });

    return relations;
};

const createRelationTable = async (
    projectId: string,
    relationId: string,
    payload: CreateRelationTableRequest,
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/relations/${relationId}/tables`,
        method: 'POST',
        body: JSON.stringify(payload),
    });

export const useRelationTable = (
    relationId: string,
    shuoldRedirect?: boolean,
) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastError } = useNotify();
    const { t } = useLocale();

    return useMutation<
        ApiSuccessEmpty,
        ApiError,
        { payload: CreateRelationTableRequest }
    >(({ payload }) => createRelationTable(projectUuid, relationId, payload), {
        mutationKey: [QueryKeys.CREATE_RELATION_TABLE, projectUuid, relationId],
        onSuccess: async () => {
            if (shuoldRedirect) {
                window.location.href =
                    '/projects/' + projectUuid + '/relations';
            }
        },
        onError: (error) => {
            showToastError({
                title: t('relation_provider.relation_creation_failed'),
                subtitle: error.error.message,
            });
        },
    });
};

export const getTableEventConfig = async (
    projectId: string,
    relationUuid: string,
    tableId: string,
    dimId: string,
) =>
    sortmentApi<DimensionConfigurationResult>({
        url: `/projects/${projectId}/relations/${relationUuid}/tables/${tableId}/dim/${dimId}/cache-config`,
        method: 'GET',
        body: undefined,
    });

export const useTableEventConfig = ({
    relationUuid,
    tableId,
    dimId,
}: {
    relationUuid: string;
    tableId: string;
    dimId: string;
}) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const tables = useQuery<DimensionConfigurationResult, ApiError>({
        queryKey: [QueryKeys.EVENT_CONFIG, relationUuid, tableId],
        queryFn: () =>
            getTableEventConfig(projectUuid, relationUuid, tableId, dimId),
        keepPreviousData: true,
    });

    return tables;
};

const updateTableDetail = async (
    projectId: string,
    relationId: string,
    tableName: string,
    payload: WarehouseTableInfo,
) => {
    return sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/relations/${relationId}/tables/${tableName}`,
        method: 'PATCH',
        body: JSON.stringify(payload),
    });
};

export const useUpdateTableDetail = (relationId: string, toast = true) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<
        ApiSuccessEmpty,
        ApiError,
        { tableName: string; payload: any }
    >(
        ({ tableName, payload }) =>
            updateTableDetail(projectUuid, relationId, tableName, payload),
        {
            mutationKey: [QueryKeys.UPDATE_TABLE_METADATA, projectUuid],
            onSuccess: async () => {
                if (toast) {
                    showToastSuccess({
                        title: t(
                            'relation_provider.table_metadata_updated_successfully',
                        ),
                    });
                }
            },
            onError: (error) => {
                if (toast) {
                    showToastError({
                        title: t(
                            'relation_provider.table_metadata_update_failed',
                        ),
                        subtitle: error.error.message,
                    });
                }
            },
        },
    );
};

const refreshTables = async (projectId: string) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/refresh`,
        method: 'POST',
        body: undefined,
    });

export const useRefreshTables = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useMutation<ApiSuccessEmpty, ApiError>({
        mutationFn: () => refreshTables(projectUuid),
        mutationKey: [QueryKeys.REFRESH_TABLES, projectUuid],
    });
};

const uploadCatalogFile = async (
    projectId: string,
    file: File,
    relationUuid: string,
) => {
    const formData = new FormData();
    formData.append('file', file);
    return sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/relations/${relationUuid}/tables/file-upload`,
        method: 'POST',
        body: formData,
        headers: null,
    });
};

export const useUploadCatalogFile = () => {
    const { activeRelationUuid: relationUuid } = useRelationContext();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useMutation<ApiSuccessEmpty, ApiError, { file: File }>({
        mutationFn: ({ file }) =>
            uploadCatalogFile(projectUuid, file, relationUuid),
        mutationKey: [QueryKeys.UPLOAD_CATALOG_FILE, projectUuid],
        onSuccess: () => {
            showToastSuccess({
                title: t('catalog.file_uploaded_successfully'),
            });
        },
        onError: () => {
            showToastError({
                title: t('catalog.file_upload_failed'),
            });
        },
    });
};

const upsertCatalogFileToRelation = async (
    projectId: string,
    relationUuid: string,
    payload: TableFileUploadPayload,
) => {
    return sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/relations/${relationUuid}/tables/file/upsert`,
        method: 'PUT',
        body: JSON.stringify(payload),
    });
};

export const useUpsertCatalogFileToRelation = () => {
    const { activeRelationUuid: relationUuid } = useRelationContext();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useMutation<ApiSuccessEmpty, ApiError, TableFileUploadPayload>({
        mutationFn: (payload) =>
            upsertCatalogFileToRelation(projectUuid, relationUuid, payload),
        mutationKey: [QueryKeys.UPSERT_CATALOG_FILE_TO_RELATION, projectUuid],
        onSuccess: () => {
            showToastSuccess({
                title: t('table_upsert.success'),
            });
        },
        onError: () => {
            showToastError({
                title: t('table_upsert.failure'),
            });
        },
    });
};

const previewTable = async (
    projectId: string,
    relationUuid: string,
    payload: TablePreviewPayload,
    tableId: string,
) => {
    return sortmentApi<Record<string, any>[]>({
        url: `/projects/${projectId}/relations/${relationUuid}/tables/${tableId}/preview`,
        method: 'POST',
        body: JSON.stringify(payload),
    });
};

export const usePreviewTable = () => {
    const { activeRelationUuid: relationUuid } = useRelationContext();
    const { showToastError } = useNotify();
    const { t } = useLocale();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useMutation<
        Record<string, any>[],
        ApiError,
        TablePreviewPayload & { tableId: string }
    >({
        mutationFn: ({ tableId, ...payload }) =>
            previewTable(projectUuid, relationUuid, payload, tableId),
        mutationKey: [QueryKeys.SCHEMA_PREVIEW_TABLE, projectUuid],
        onError: () => {
            showToastError({
                title: t('schema_builder.table_preview_error'),
            });
        },
    });
};
