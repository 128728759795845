import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import List from '@components/common/List';
import { TextWithTooltip } from '@components/common/TextWithTooltip';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import {
    CommonReservedTags,
    CommunicationChannelName,
    Sources,
    WarehouseTypes,
    type JourneyEventMapperSchema,
    type JourneyFiltersConfig,
} from '@lightdash/common';
import { Box, Divider, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { CaretLeft, CaretRight, CursorClick } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import useProjectContext from '@providers/Project/useProjectContext';
import Fuse from 'fuse.js';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ControlPanel, TriggerType } from '../../types';
import EventInfoPopover from './EventInfoPopover';
import { createDefaultFilterConfigForChannel } from './utils';
export const InternalEventItem: React.FC<{
    event: JourneyEventMapperSchema;
}> = ({ event }) => (
    <Group className="items-center max-w-full gap-1 overflow-hidden">
        <ChannelIcon channel={event.sourceLabel} showChannelName={false} />
        <CaretRight />
        <Text className="flex-grow text-sm font-medium text-gray-800 truncate flex-nowrap">
            {event.sourceLabel && CommunicationChannelName[event.sourceLabel]}{' '}
            {event.label || event.eventName}
        </Text>
    </Group>
);

export const ExternalEventItem: React.FC<{
    event: JourneyEventMapperSchema;
}> = ({ event }) => {
    return (
        <>
            <EventInfoPopover
                target={
                    <Group className="items-center max-w-full gap-1 overflow-hidden cursor-pointer">
                        <CursorClick color={'rgb(var(--color-mustard-800))'} />
                        <TextWithTooltip
                            className="text-sm font-medium text-gray-500 truncate  max-w-[40%] flex-nowrap"
                            text={event.source}
                        />

                        <CaretRight />
                        <TextWithTooltip
                            className="flex-grow text-sm font-medium text-gray-800 truncate  max-w-[40%] flex-nowrap"
                            text={event.label || event.eventName}
                        />
                    </Group>
                }
                eventLabel={event.label || event.eventName}
                eventSource={event.source}
                eventName={event.eventName}
            />
        </>
    );
};

interface EventsListSelectorProps {
    journeyEvents: JourneyEventMapperSchema[];
    onEventClick: (event: JourneyEventMapperSchema) => void;
    setShowEventsList: (show: boolean) => void;
    scrollAreaCustomCss?: string;
}

export const EventsListSelector: React.FC<EventsListSelectorProps> = ({
    journeyEvents,
    onEventClick,
    setShowEventsList,
    scrollAreaCustomCss,
}) => {
    const { t } = useLocale();

    const [searchQuery, setSearchQuery] = useState<string>('');

    const fuse = useMemo(() => {
        if (!journeyEvents) return null;
        return new Fuse(journeyEvents, {
            keys: ['source', 'label', 'eventName'],
            threshold: 0.3,
        });
    }, [journeyEvents]);

    const filteredEvents = useMemo(() => {
        if (!journeyEvents) return { internal: [], external: [] };
        let result = journeyEvents;

        if (searchQuery && fuse) {
            result = fuse.search(searchQuery).map(({ item }) => item);
        }

        const internal = result.filter(
            (event) =>
                event.isInternal &&
                !event.tags?.includes(CommonReservedTags.HIDDEN),
        );
        const external = result.filter((event) => !event.isInternal);

        internal.sort((a, b) => {
            if (a.source < b.source) return -1;
            if (a.source > b.source) return 1;
            if ((a.sourceLabel || '') < (b.sourceLabel || '')) return -1;
            if ((a.sourceLabel || '') > (b.sourceLabel || '')) return 1;
            if ((a.label || a.eventName) < (b.label || b.eventName)) return -1;
            if ((a.label || a.eventName) > (b.label || b.eventName)) return 1;
            return 0;
        });

        external.sort((a, b) => {
            if (a.source < b.source) return -1;
            if (a.source > b.source) return 1;
            if ((a.label || a.eventName) < (b.label || b.eventName)) return -1;
            if ((a.label || a.eventName) > (b.label || b.eventName)) return 1;
            return 0;
        });

        return { internal, external };
    }, [journeyEvents, searchQuery, fuse]);

    return (
        <Stack className="gap-2.5">
            <Box className="flex items-center gap-2 px-2">
                <Box
                    className="p-2 border rounded-md cursor-pointer border-gray-250"
                    onClick={() => {
                        setShowEventsList(false);
                    }}
                >
                    <CaretLeft />
                </Box>
                <SearchInput
                    placeholder={t(
                        'journey_builder.trigger_block_search_placeholder',
                    )}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </Box>
            <Text className="px-2 text-xs font-medium text-gray-500 uppercase">
                {t('journey_builder.trigger_block_events_section_title')}
            </Text>
            <ScrollArea className={`flex-grow ${scrollAreaCustomCss}`}>
                <List
                    items={filteredEvents.external.filter(
                        (event) =>
                            !event.tags?.includes(CommonReservedTags.HIDDEN),
                    )}
                    itemTemplate={(event) => (
                        <ExternalEventItem event={event} />
                    )}
                    onItemClick={(event) => {
                        onEventClick(event);
                    }}
                    itemSize={40}
                />
                <Divider className="mx-4 my-1 border-t-2 border-t-shade-2" />
                <List
                    items={filteredEvents.internal}
                    itemTemplate={(event) => (
                        <InternalEventItem event={event} />
                    )}
                    onItemClick={(event) => {
                        onEventClick(event);
                    }}
                    itemSize={40}
                />
            </ScrollArea>
        </Stack>
    );
};

interface EventsListProps {
    setShowEventsList: (show: boolean) => void;
    triggerType: TriggerType;
}

const EventsList: React.FC<EventsListProps> = ({
    setShowEventsList,
    triggerType,
}) => {
    const { addTriggerNode, updateTriggerNode, openControlPanel } =
        useJourneyBuilderContext((context) => context.actions);
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData } = useProjectContext();

    const { journeyEvents, journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const handleEventClick = (event: JourneyEventMapperSchema) => {
        const isInternal =
            triggerType === TriggerType.USER_ACTION &&
            projectUuid &&
            event.source === Sources.FYNO;
        const channelFilterConfig = createDefaultFilterConfigForChannel(
            projectUuid ?? '',
            event.sourceLabel || '',
            projectData?.warehouseConnection?.type ?? WarehouseTypes.SNOWFLAKE,
        );
        if (!journeyPayload.triggers?.entry[0]) {
            let eventConfig: {
                eventName: string;
                eventSource: string;
                filterConfig: JourneyFiltersConfig | undefined;
                isBusinessEventTrigger: boolean;
            } = {
                eventName: event.eventName,
                eventSource: event.source,
                isBusinessEventTrigger:
                    triggerType === TriggerType.BUSINESS_EVENT,
                filterConfig: undefined,
            };
            if (isInternal) {
                eventConfig.filterConfig = channelFilterConfig;
            }
            addTriggerNode(eventConfig);
            return;
        }
        updateTriggerNode({
            eventName: event.eventName,
            eventSource: event.source,
            associatedAudienceFilterConfig: undefined,
            isBusinessEventTrigger: triggerType === TriggerType.BUSINESS_EVENT,
            isScheduledAudienceTrigger: false,
            filterConfig: isInternal ? channelFilterConfig : undefined,
        });
        openControlPanel({
            type: ControlPanel.TRIGGER_CONFIG,
            triggerId: journeyPayload.triggers?.entry[0].id,
        });
    };

    const filteredJourneyEvents = useMemo(() => {
        if (!journeyEvents) return null;
        if (triggerType === TriggerType.BUSINESS_EVENT) {
            return journeyEvents.filter(
                (event) => !('srt_user_id' in event.mapperSchema),
            );
        }
        if (triggerType === TriggerType.USER_ACTION) {
            return journeyEvents.filter(
                (event) => 'srt_user_id' in event.mapperSchema,
            );
        }
        return null;
    }, [journeyEvents, triggerType]);

    if (!filteredJourneyEvents) return null;

    return (
        <EventsListSelector
            journeyEvents={filteredJourneyEvents}
            onEventClick={handleEventClick}
            setShowEventsList={setShowEventsList}
        />
    );
};

export default React.memo(EventsList);
