import { useLocale } from '@hooks/useLocale';
import {
    ShowDataType,
    type UserAlertPreferenceResponse,
} from '@lightdash/common';

import Manager from '@components/Manager';
import { useAlertColumns } from './alertTableUtils';

interface AlertTableProps {
    alerts: (UserAlertPreferenceResponse | null)[];
}

const AlertTable = ({ alerts }: AlertTableProps) => {
    const { t } = useLocale();

    const alertColumns = useAlertColumns();

    if (!alertColumns) return null;

    return (
        <Manager<UserAlertPreferenceResponse | null>
            name={t('alerts')}
            tableData={alerts || []}
            options={[
                {
                    format: ShowDataType.LIST,
                    formatData: alertColumns,
                },
            ]}
            searchPlaceholder={t('alert_settings.search_placeholder')}
            paginationText={t('alert_settings.pagination_text')}
            isManualPagination={false}
            isEditable={false}
        />
    );
};

export default AlertTable;
