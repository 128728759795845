import { type AnyType } from './any';
import { type ApiSort } from './api/sort';
import { type ExternalCampaignTriggerRequest } from './campaign';
import { type CommunicationChannel } from './communications';
import { type PeriodType } from './deliveryControl';
import {
    type CommonEventColumns,
    type EventMapperSchema,
    type JourneyEventNames,
    type ReservedEventColumns,
} from './events';
import { type DimensionType } from './field';
import { type NestedMetricQueryGroup } from './metricQuery';
import { type CompiledRelationTable } from './relation/compiledTables';
import { type LightdashUser, type SessionUser } from './user';

export const JOURNEY_DYNAMIC_VARIABLE_PREFIX = '$.';

export type JourneyEntryLogic = {
    cooldown: number; // milliseconds
    killExisting: boolean;
    contextId:
        | CommonEventColumns.CLIENT_EVENT_ID
        | ReservedEventColumns.USER_ID;
    contextConcurrency: number; // -1
    contextTotal: number; // -1
    uiConfig?: Partial<{
        cooldownType: PeriodType;
    }>;
    entryLimit?: number;
};

export type ConversionTrigger = BaseTrigger & {
    deadline: number; // ms
    uiConfig?: Partial<{
        granularity: PeriodType;
    }>;
};

export type JourneyTriggerConfig = {
    entry: Array<EntryTrigger>;
    exit?: Array<BaseTrigger>;
    conversion?: Array<ConversionTrigger>;
    signals?: Array<BaseTrigger>;
};

export type EntryTrigger = BaseTrigger & {
    associatedAudienceFilterConfig?: NestedMetricQueryGroup;
    isBusinessEventTrigger?: boolean;
    isScheduledAudienceTrigger?: boolean;
    cronConfig?: {
        cron: string | undefined;
        jobId: string | undefined;
    };
    audienceDiffEnabled?: boolean;
};

export type BaseTrigger = {
    id: string;
    eventName: string;
    eventSource: string;
    filterConfig?: JourneyFiltersConfig;
    metadata?: Partial<JourneyNode>;
};

export function isBusinessEventTrigger(
    value: EntryTrigger,
): value is EntryTrigger {
    return (
        'isBusinessEventTrigger' in value &&
        value.isBusinessEventTrigger === true
    );
}

export function isScheduledAudienceTrigger(
    value: EntryTrigger,
): value is EntryTrigger {
    return (
        'isScheduledAudienceTrigger' in value &&
        value.isScheduledAudienceTrigger === true
    );
}

export enum JourneyStatus {
    DRAFT = 'DRAFT',
    ACTIVATING = 'ACTIVATING',
    ACTIVATION_FAILED = 'ACTIVATION_FAILED',
    ACTIVE = 'ACTIVE',
    SCHEDULED = 'SCHEDULED',
    CANCELED = 'CANCELED',
}

export enum JourneyVersionStatus {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    CANCELED = 'CANCELED',
}

export enum JourneyExecutionStatus {
    RUNNING = 'RUNNING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    TERMINATED = 'TERMINATED',
}

export type Journey = {
    id: string;
    projectId: string;
    name: string;
    description?: string;
    displayName: string;
    startDate: Date | undefined;
    status: JourneyStatus;
    endDate: Date | undefined;
    jobId: string;
    createdAt: Date;
    createdBy: Pick<LightdashUser, 'userUuid' | 'firstName' | 'lastName'>;
    updatedAt: Date;
    updatedBy: Pick<
        LightdashUser,
        'userUuid' | 'firstName' | 'lastName'
    > | null;
    cancelledAt: Date | undefined;
    cancelledBy: string | undefined;
    isDeleted: boolean;
    tags: string[] | undefined;
    currentVersionId: string | undefined;
};

export type JourneyAndVersionConfig = Journey & JourneyVersionConfig;

export type JourneyVersionConfig = Pick<
    JourneyVersion,
    'versionName' | 'config' | 'triggers' | 'entryLogic'
>;

export type JourneyVersion = {
    id: string;
    journeyId: string;
    versionName: string;
    config: JourneyNodesConfig;
    triggers: JourneyTriggerConfig;
    entryLogic: JourneyEntryLogic;
    status: JourneyVersionStatus;
    createdAt: Date;
    createdBy: string;
};

export type JourneyCreatePayload = Partial<
    Omit<
        Journey,
        | 'id'
        | 'createdAt'
        | 'createdBy'
        | 'updatedAt'
        | 'updatedBy'
        | 'cancelledAt'
        | 'cancelledBy'
        | 'isDeleted'
        | 'status'
        | 'jobId'
    >
> &
    Partial<JourneyVersionConfig>;

export type JourneyAndExecutionCount = JourneyAndVersionConfig & {
    totalExecutions: number | undefined;
    currentExecutions: number | undefined;
    convertedExecutions: number | undefined;
};

export type JourneyAndExecutionCountList = {
    data: JourneyAndExecutionCount[] | null;
    paginate: {
        total?: number;
        lastPage?: number;
        currentPage: number;
        perPage: number;
        from: number;
        to: number;
    };
};

export type JourneyList = {
    data: Journey[] | null;
    paginate: {
        total?: number;
        lastPage?: number;
        currentPage: number;
        perPage: number;
        from: number;
        to: number;
    };
};

export type FindJourneysResponseDto = {
    status: string;
    results: JourneyList;
};

export type GetJourneyResponseDto = {
    status: string;
    results: JourneyAndVersionConfig;
};

export type FindJourneyPayload = {
    user: SessionUser;
    projectId: string;
    paginate: {
        perPage: number;
        currentPage: number;
    };
    status?: string[];
    name?: string;
    isDeleted?: string;
    includesTags?: string;
    excludesTags?: string;
    sortArgs?: ApiSort;
};

// Workflow types

export enum ActionType {
    WAIT = 'WAIT',
    SPLIT = 'SPLIT',
    FILTER = 'FILTER',
    SET = 'SET',
    SEND_MESSAGE = 'SEND_MESSAGE',
    API = 'API',
    WAIT_UNTIL = 'WAIT_UNTIL',
    UPDATE_TRAIT = 'UPDATE_TRAIT',
    EXPERIMENT = 'EXPERIMENT',
}

export const InlineActivities = [
    ActionType.WAIT,
    ActionType.FILTER,
    ActionType.SET,
    ActionType.WAIT_UNTIL,
    ActionType.EXPERIMENT,
    ActionType.SPLIT,
];

export type BaseAction = {
    type: ActionType;
};

export type WaitAction = BaseAction & {
    type: ActionType.WAIT;
    config: {
        timeConfig: DurationTimeConfig | DateTimeConfig;
        responseConfig: ResponseConfig;
    };
    uiConfig?: Partial<{
        timeGranularity: PeriodType;
    }>;
    actionConfig?: WaitActionConfig;
};

export type SplitAction = BaseAction & {
    type: ActionType.SPLIT;
    config: {
        responseConfig: ResponseConfig;
    };
    actionConfig?: SplitActionConfig;
};

export type UpdateTraitConfig = {
    name: string;
    updateConfig:
        | UpdateTraitDateConfig
        | UpdateNumberConfig
        | UpdateTraitBaseConfig;
}[];

export type UpdateTraitAction = BaseAction & {
    type: ActionType.UPDATE_TRAIT;
    config: {
        updateTraitConfig: UpdateTraitConfig;
        responseConfig: ResponseConfig;
    };
};

export type FilterAction = BaseAction & {
    type: ActionType.FILTER;
    config: {
        filterConfig: JourneyFiltersConfig;
        responseConfig: ResponseConfig;
    };
    actionConfig?: FilterActionConfig;
};

export type SetAction = BaseAction & {
    type: ActionType.SET;
    config: {
        payloadMapper: Record<string, AnyType>;
        responseConfig: ResponseConfig;
    };
    actionConfig?: SetActionConfig;
};

export type SendMessageAction = BaseAction & {
    type: ActionType.SEND_MESSAGE;
    config: {
        messageConfig: ExternalCampaignTriggerRequest;
        campaignId?: string;
        responseConfig: ResponseConfig;
        webhookConfig?: WebhookConfig;
    };
    actionConfig?: SendMessageActionConfig;
};

export type ApiAction = BaseAction & {
    type: ActionType.API;
    config: {
        apiConfig: ApiConfig;
        responseConfig: ResponseConfig;
    };
    actionConfig?: ApiActionConfig;
    warehouseFields: string[];
};

export type ExperimentAction = BaseAction & {
    type: ActionType.EXPERIMENT;
    config: {
        experimentConfig: ExperimentConfig;
        responseConfig: ResponseConfig;
    };
    actionConfig?: undefined;
};

export type WaitUntilAction = BaseAction & {
    type: ActionType.WAIT_UNTIL;
    config: {
        timeConfig: DurationTimeConfig | DateTimeConfig;
        eventConfig: EventConfig;
        responseConfig: ResponseConfig;
    };
    uiConfig?: Partial<{
        timeGranularity: PeriodType;
        transientEventFilterTable: string; // Info: This is the table name of the event filter that is added to the filter action but no property is selected
        eventLabel: string;
    }>;
    actionConfig?: WaitUntilActionConfig;
};

export type JourneyAction =
    | WaitAction
    | FilterAction
    | SetAction
    | SendMessageAction
    | ApiAction
    | WaitUntilAction
    | SplitAction
    | UpdateTraitAction
    | ExperimentAction;

export enum ProcessingType {
    SYNC = 'sync',
    ASYNC = 'async',
}

export type ResponseConfig = {
    type?: ProcessingType;
    responseMapper?: { [key: string]: AnyType };
};

export type WebhookConfig = {
    type: ProcessingType;
    eventConfig: EventConfig;
};

export type EventConfig = {
    eventName: string;
    eventSource: string;
    filterConfig?: JourneyFiltersConfig;
    type?: ProcessingType;
};

export type ApiConfig = {
    payloadMapper: string;
    httpConfig: HttpConfig;
};

export type ExperimentConfig = {
    experimentId: string;
    experimentName: string;
    conversionEvent: ConversionTrigger;
    conclusionConfig?: {
        // this is enough to assume auto selection of winning path
        time: number; // if this value is not null then on the day of conclusion winning path will be selected
        granularity: PeriodType;
    };
    variantConfig: ExperimentVariant[];
};

export type ExperimentVariant = {
    variantId: string;
    variantName: string;
    distributionPercentage: number;
    nodeId: string;
    isControl: boolean;
    winningVariant: boolean; // default to false
};

export type JourneyNodeEventsData = {
    projectId: string;
    journeyId: string;
    userId: string | null;
    contextId: string | null;
    dslVersion: string | undefined;
    executionId: string;
    nodeId: string;
    eventName: JourneyEventNames;
};

export type ApiRequestConfig = {
    payloadMapper: AnyType;
    httpConfig: HttpRequestConfig;
    retryConfig?: RetryConfig;
};

export type HttpConfig = {
    url: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    headers: string;
};

export type HttpRequestConfig = {
    url: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    headers: AnyType;
};

export type RetryConfig = {
    retryCount: number;
    retryDelay: number;
};

export type Branch = {
    destination: string;
    isDefault: boolean;
    conditions?: JourneyFiltersConfig;
};

export enum BranchConcurrencyTypes {
    PARALLEL = 'PARALLEL',
    SEQUENTIAL = 'SEQUENTIAL',
}

export enum BranchConditionalTypes {
    IFELSE = 'IFELSE',
    IFIF = 'IFIF',
}

export type ParallelBranches = {
    type: BranchConcurrencyTypes.PARALLEL;
    branches: Branch[];
};

export type SequentialBranches = {
    type: BranchConcurrencyTypes.SEQUENTIAL;
    branches: Branch[];
};

export type BranchConfig = {
    type: BranchConditionalTypes;
    children: ParallelBranches | SequentialBranches;
};

export type JourneyNodeExitConfig = {
    notify: boolean;
    notifyConfig: {
        type: 'EVENT';
        config: {
            eventName: string;
            payloadMapper: Record<string, AnyType>;
        };
    };
};

export type JourneyNode = {
    id: string;
    title: string;
    description: string;
    actions: JourneyAction[];
    branchConfig?: BranchConfig;
    exitConfig?: JourneyNodeExitConfig;
    metadata: // Keep experiment_id in the metadata for experiment scope in the journey UI
    | {
              blockId: string;
          }
        | undefined;
};

export type JourneyNodesConfig = {
    nodes: JourneyNode[];
};

export type ActivityResult = {
    responseData?: Record<string, AnyType>;
    journeyParams?: JourneyData;
    nextNodes?: JourneyNode[];
};

// For FE
export enum JourneyGroup {
    'TRIGGER' = 'trigger',
    'ACTION' = 'action',
    'FLOW_CONTROL' = 'Flow control',
    'DELAY' = 'delay',
}

export interface JourneyBlockConfig {
    id: string;
    title: string;
    description: string | undefined;
    group: JourneyGroup;
    actions: ActionTypeWithConfig<ActionType>[];
}

interface JourneyBlockConfigWithEveryOneElse {
    allowEveryoneElse: boolean;
}

// Define the specific config types for each action type
export type WaitActionConfig = JourneyBlockConfigWithEveryOneElse & {
    type: TimeConfigType;
};

export type WaitUntilActionConfig = JourneyBlockConfigWithEveryOneElse & {};

export type FilterActionConfig = JourneyBlockConfigWithEveryOneElse & {};

export type SendMessageActionConfig = JourneyBlockConfigWithEveryOneElse & {
    channelType: CommunicationChannel;
};

export type ApiActionConfig = JourneyBlockConfigWithEveryOneElse & {};

export type SetActionConfig = JourneyBlockConfigWithEveryOneElse & {};

export type SplitActionConfig = JourneyBlockConfigWithEveryOneElse & {};

export type ExperimentActionConfig = JourneyBlockConfigWithEveryOneElse & {};

export type ActionTypeConfigMap = {
    WAIT: WaitActionConfig;
    SPLIT: SplitActionConfig;
    WAIT_UNTIL: WaitUntilActionConfig;
    FILTER: FilterActionConfig;
    SEND_MESSAGE: SendMessageActionConfig;
    API: ApiActionConfig;
    SET: SetActionConfig;
    UPDATE_TRAIT: UpdateTraitConfig;
    EXPERIMENT: ExperimentActionConfig;
};

export type ActionTypeWithConfig<T> = {
    actionType: T;
    config?:
        | WaitActionConfig
        | WaitUntilActionConfig
        | FilterActionConfig
        | SendMessageActionConfig
        | ApiActionConfig
        | SetActionConfig
        | SplitActionConfig
        | ExperimentActionConfig;
};

export type JourneyBlocksMetadata = Omit<JourneyBlockConfig, 'actions'> & {
    actions: ActionTypeWithConfig<ActionType>[];
    allowedInExperiment: boolean;
};

export type JourneyBlocksList = JourneyBlocksMetadata[];

// Filter Schema

export enum JourneyTableType {
    PRIMARY = 'PRIMARY',
    EVENT = 'EVENT',
    RELATED = 'RELATED',
    AUDIENCE = 'AUDIENCE',
    API = 'API',
}

export type JourneyDimension = {
    type: DimensionType;
    label: string;
    name: string;
    table: string;
};

export type JourneyTable = {
    name: string;
    type: JourneyTableType;
    label: string;
    eventName?: string;
    eventSource?: string;
    nodeId?: string;
    dimensions: Record<string, JourneyDimension>;
};

export type JourneyEventParamsRequest = Pick<
    JourneyVersion,
    'config' | 'entryLogic' | 'triggers'
>;

export type JourneyDataSchema = {
    baseTable: string;
    journeyId: string;
    userId: string | undefined;
    contextId: string;
    tables: Record<string, JourneyTable>;
    relationTables: { [tableName: string]: CompiledRelationTable };
};

export type JourneyData = {
    projectId: string;
    journeyId: string;
    userId: string | null;
    contextId: string | null;
    dslVersion: string | undefined;
    srtCustomer360?: Record<string, AnyType>; // will be populated in workflows/activities whenever needed.
} & Record<string, AnyType>;

export enum JourneyConditionalOperator {
    NULL = 'isNull',
    NOT_NULL = 'notNull',
    EQUALS = 'equals',
    NOT_EQUALS = 'notEquals',
    STARTS_WITH = 'startsWith',
    ENDS_WITH = 'endsWith',
    INCLUDE = 'include',
    NOT_INCLUDE = 'doesNotInclude',
    LESS_THAN = 'lessThan',
    LESS_THAN_OR_EQUAL = 'lessThanOrEqual',
    GREATER_THAN = 'greaterThan',
    GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
    // IN_THE_PAST = 'inThePast',
    // NOT_IN_THE_PAST = 'notInThePast',
    // IN_THE_NEXT = 'inTheNext',
    // IN_THE_CURRENT = 'inTheCurrent',
    // NOT_IN_THE_CURRENT = 'notInTheCurrent',
    IN_BETWEEN = 'inBetween',
}

export type JourneyContextExecutionsResponse = {
    journeysToTerminate: string[];
    startNew: boolean;
};

export type JourneyConditionalRule<
    O = JourneyConditionalOperator,
    V = AnyType,
> = {
    operator: O;
    values?: V[];
};

export type JourneyFieldTarget = {
    fieldId: string;
    fieldRef?: string;
};

export interface JourneyFilterRule<
    O = JourneyConditionalOperator,
    T = JourneyFieldTarget,
    V = AnyType,
> extends JourneyConditionalRule<O, V> {
    target: T;
}

export type JourneyFilterGroupItem = JourneyFilterGroup | JourneyFilterRule;

export type OrJourneyFilterGroup = {
    or: Array<JourneyFilterGroupItem>;
};

export type AndJourneyFilterGroup = {
    and: Array<JourneyFilterGroupItem>;
};

export type JourneyFilterGroup = OrJourneyFilterGroup | AndJourneyFilterGroup;

export type JourneyFiltersConfig = {
    id: string;
    journeyFilters: JourneyFilterGroup | undefined;
    audienceFilters:
        | {
              filterJoinType: 'and' | 'or';
              filterConfig: NestedMetricQueryGroup;
              compiledAudienceId: string | undefined;
          }
        | undefined;
};

export const isOrJourneyFilterGroup = (
    value: JourneyFilterGroupItem,
): value is OrJourneyFilterGroup => 'or' in value;

export const isAndJourneyFilterGroup = (
    value: JourneyFilterGroupItem,
): value is AndJourneyFilterGroup => 'and' in value;

export const isJourneyFilterGroup = (
    value: JourneyFilterGroupItem,
): value is JourneyFilterGroup =>
    isOrJourneyFilterGroup(value) || isAndJourneyFilterGroup(value);

export const isJourneyFilterRule = (
    value: JourneyConditionalRule | JourneyFilterGroupItem,
): value is JourneyFilterRule =>
    'id' in value && 'target' in value && 'operator' in value;

export type JourneyEventMapperSchema = EventMapperSchema & {
    sourceLabel: CommunicationChannel | undefined;
};

export type JourneyPublishConfig = Partial<
    Pick<Journey, 'startDate' | 'endDate' | 'status'> &
        Pick<Journey, 'currentVersionId'>
>;

export type JourneyExecutionCount = {
    [journeyId: string]: {
        running: number;
        entered: number;
        converted: number;
    };
};

// Temporal types --start

export enum TemporalStatus {
    RUNNING = 'Running',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED',
    CONTINUED_AS_NEW = 'CONTINUED_AS_NEW',
    TIMED_OUT = 'TIMED_OUT',
    TERMINATED = 'TERMINATED',
}

export enum TemporalDefaultSearchAttributes {
    WORKFLOW_ID = 'WorkflowId',
    WORKFLOW_TYPE = 'WorkflowType',
    START_TIME = 'StartTime',
    EXECUTION_DURATION = 'ExecutionDuration',
    CLOSE_TIME = 'CloseTime',
    STATUS = 'Status',
    EXECUTION_STATUS = 'ExecutionStatus',
}

export enum TemporalCustomSearchAttributes {
    CONTEXT_ID = 'SRT_CUSTOM_JOURNEY_CONTEXT_ID',
    JOURNEY_ID = 'SRT_CUSTOM_JOURNEY_ID',
    JOURNEY_CONVERTED = 'SRT_CUSTOM_JOURNEY_CONVERTED',
    JOURNEY_DSL_VERSION = 'SRT_CUSTOM_JOURNEY_DSL_VERSION',
    JOURNEY_WORKFLOW_VERSION = 'SRT_CUSTOM_JOURNEY_WORKFLOW_VERSION',
    // DSL_VERSION = "SRT_CUSTOM_DSL_VERSION", Add it soon
}

export enum TemporalWorkflowVersions {
    V1 = 'v1',
}

export enum TemporalFilterTypes {
    EQUALS = '=',
    NOT_EQUALS = '!=',
    GREATER_THAN = '>',
    LESS_THAN = '<',
    GREATER_THAN_OR_EQUALS = '>=',
    LESS_THAN_OR_EQUALS = '<=',
    AND = 'and',
    OR = 'or',
    STARTS_WITH = 'STARTS_WITH',
}

export enum TemporalExecutionStatusCode {
    RUNNING = 1,
    COMPLETED = 2,
    FAILED = 3,
    CANCELED = 4,
    CONTINUED_AS_NEW = 6,
    TIMED_OUT = 7,
    TERMINATED = 5,
}
// temporal types --end

export type UpsertJourneyConversion = {
    [TemporalCustomSearchAttributes.JOURNEY_CONVERTED]: boolean;
};
export enum JourneyTriggerType {
    ENTRY = 'entry',
    EXIT = 'exit',
    CONVERSION = 'conversion',
    SIGNAL = 'signal',
}

export type JourneyTagsUpdateRequest = {
    tags: string[];
};

export enum JourneyParamsNames {
    CACHED_PROPERTY = 'SRT_CACHED_PROPERTY',
    CACHED_AUDIENCE = 'SRT_CACHED_AUDIENCE',
    JOURNEY_EVENT = 'SRT_JOURNEY_EVENT',
    SRT_USER_TRAITS = 'SRT_USER_TRAITS',
}

export enum TimeConfigType {
    DURATION = 'DURATION',
    DATE_TIME = 'DATE_TIME',
}

export enum UpdateTraitType {
    NUMBER = 'NUMBER',
    DATE_TIME = 'DATE_TIME',
    BASE = 'BASE',
}

export interface DurationTimeConfig {
    timeConfigType: TimeConfigType.DURATION;
    duration: number;
}

export interface DateTimeConfig {
    timeConfigType: TimeConfigType.DATE_TIME;
    dateTime: {
        value: number | string;
        time?: string;
    };
    timeDifference: {
        value: number;
        operator: DifferenceOperator;
    };
}

export interface UpdateTraitBaseConfig {
    updateTraitType: UpdateTraitType.BASE;
    config: {
        value: AnyType;
    };
}
export interface UpdateTraitDateConfig {
    updateTraitType: UpdateTraitType.DATE_TIME;
    config: DateTimeConfig;
}

export interface UpdateNumberConfig {
    updateTraitType: UpdateTraitType.NUMBER;
    config: {
        value: number | string;
        diffrence: {
            value: number | string;
            operator: DifferenceOperator;
        };
    };
}

export enum DifferenceOperator {
    ADD = '+',
    SUBTRACT = '-',
}

export const DefaultBranchSelected = 'DEFAULT_BRANCH_SELECTED';

export type JourneyEventPayload = {
    contextId: string | null;
    journeyId: string;
    executionId: string;
    dslVersion: string | null;
    eventName: JourneyEventNames;
    userId: string | null;
    nodeId: string | null;
    timestamp: number;
};
