import {
    AudienceStatus,
    CampaignStatus,
    EntityType,
    JourneyStatus,
} from '@lightdash/common';

interface GetEntityURLType {
    entityType: EntityType;
    entityId: string;
    data: any;
    projectUuid: string;
}

/**
 * Get the URL for an entity
 * @param entityType
 * @param entityId
 * @param data
 * @returns
 */
export const getEntityURL = ({
    entityType,
    entityId,
    data,
    projectUuid,
}: GetEntityURLType) => {
    switch (entityType) {
        case EntityType.Audience:
            if (data.status === AudienceStatus.DRAFT) {
                return `${window.location.origin}/projects/${projectUuid}/audiences/${entityId}/edit`;
            }
            return `${window.location.origin}/projects/${projectUuid}/audiences/${entityId}/view`;
        case EntityType.Journey:
            if (
                data.status === JourneyStatus.DRAFT ||
                data.status === JourneyStatus.ACTIVATING
            ) {
                return `${window.location.origin}/projects/${projectUuid}/journeys/${entityId}/edit`;
            }
            return `${window.location.origin}/projects/${projectUuid}/journeys/${entityId}/view`;
        case EntityType.Campaign:
            if (data.status === CampaignStatus.DRAFT) {
                return `${window.location.origin}/projects/${projectUuid}/campaigns/${entityId}/edit?channel=${data.channel}`;
            }
            return `${window.location.origin}/projects/${projectUuid}/campaigns/${entityId}/preview`;
        default:
            return '';
    }
};
