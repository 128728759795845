import IntegrationOption from '@components/Campaigns/Builder/IntegrationOption';
import StepTitle from '@components/Campaigns/Builder/Steps/StepTitle';
import {
    CampaignBuilderQueryParams,
    CampaignBuilderStep,
} from '@components/Campaigns/Builder/types';
import { CampaignBuilderSteps } from '@components/Campaigns/Builder/utils';
import { createIntegratedProviderObject } from '@components/Campaigns/utils';
import NoResultsFound from '@components/Channels/NoResultsFound';
import SkeletonLoader from '@components/common/SkeletonLoader';
import SearchInput from '@components/SearchInput';
import { useUpdateCampaign } from '@hooks/useCampaigns';
import {
    useGetChannelIntegrations,
    useGetChannels,
    useGetIntegratedProviderConfig,
    useGetProviderConfig,
} from '@hooks/useChannels';
import { useLocale } from '@hooks/useLocale';
import {
    CommunicationChannel,
    CommunicationChannelName,
    type Integration,
} from '@lightdash/common';
import { Box, Button, Stack, Text } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

const CampaignBuilderChannel: React.FC<{}> = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const { t } = useLocale();
    const location = useLocation();
    const { campaignUuid, projectUuid } = useParams<{
        campaignUuid: string;
        projectUuid: string;
    }>();
    const {
        setCampaignChannelPayload,
        setShowFooterButtons,
        setCurrentStep,
        setCampaignContentPayload,
        setCampaignChannel,
    } = useCampaignContext((context) => context.actions);

    const { campaignPayload, isEditMode } = useCampaignContext(
        (context) => context.state,
    );
    const { communicationDetails } = campaignPayload;

    const {
        data: integrationConfig,
        isInitialLoading: isIntegrationConfigLoading,
    } = useGetIntegratedProviderConfig(
        communicationDetails?.providerId,
        communicationDetails?.id,
    );

    const { data: providerConfig, isInitialLoading: isProviderConfigLoading } =
        useGetProviderConfig(communicationDetails?.providerId);

    const { data: integrations, isLoading: isIntegrationsLoading } =
        useGetChannelIntegrations(
            campaignPayload.channel ?? CommunicationChannel.EMAIL,
        );
    const { data: allChannels, isLoading: isAllChannelsLoading } =
        useGetChannels();

    const { mutate: updateCampaign } = useUpdateCampaign(campaignUuid, false);

    const title = CampaignBuilderSteps.find(
        (step) => step.key === CampaignBuilderStep.CHANNEL,
    )?.pageHeader;

    const onProviderChange = (provider: Integration) => {
        setCampaignChannelPayload(provider);
        setCurrentStep(CampaignBuilderStep.AUDIENCE);
        if (campaignUuid) {
            updateCampaign({
                communicationDetails: {
                    eventId: '',
                    id: provider.integrationId,
                    providerId: provider.providerId,
                },
            });
        }
    };

    const initializeCampaignChannel = useCallback(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get(CampaignBuilderQueryParams.CHANNEL)) {
            setCampaignChannel(
                queryParams.get(
                    CampaignBuilderQueryParams.CHANNEL,
                ) as CommunicationChannel,
            );
        }
    }, [location, setCampaignChannel]);

    useEffect(() => {
        initializeCampaignChannel();
    }, [initializeCampaignChannel, location, setCampaignChannel]);

    useEffect(() => {
        if (communicationDetails?.providerId) {
            setShowFooterButtons({
                next: true,
                back: false,
            });
            return;
        }
        setShowFooterButtons({
            next: false,
            back: false,
        });
    }, [communicationDetails, setShowFooterButtons]);

    const formattedIntegrations = useMemo(() => {
        if (integrations && allChannels && campaignPayload.channel) {
            const filteredIntegrations = integrations.filter(
                (integration) =>
                    integration.customName
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    integration.providerName
                        .toLowerCase()
                        .includes(search.toLowerCase()),
            );
            return (
                createIntegratedProviderObject(
                    allChannels?.[
                        campaignPayload.channel as CommunicationChannel
                    ],
                    filteredIntegrations,
                    false,
                ) ?? []
            );
        }
        return [];
    }, [integrations, allChannels, campaignPayload.channel, search]);

    const handleAddChannel = () => {
        void navigate(`/projects/${projectUuid}/settings/channels`);
    };

    return (
        <Box className="p-6">
            <StepTitle title={title || ''} />
            <Box className="mt-6 max-w-[800px] w-[500px] pb-[6rem]">
                {isIntegrationConfigLoading ||
                isProviderConfigLoading ||
                isIntegrationsLoading ||
                isAllChannelsLoading ? (
                    <>
                        <SkeletonLoader width={500} height={100} />
                    </>
                ) : (
                    <>
                        {communicationDetails?.providerId &&
                        integrationConfig &&
                        providerConfig ? (
                            <Box
                                className={`w-full ${
                                    isEditMode ? 'cursor-pointer' : ''
                                } `}
                                onClick={() => {
                                    if (!isEditMode) return;
                                    setCampaignChannelPayload(null);
                                    setCampaignContentPayload(null);
                                    initializeCampaignChannel();
                                }}
                            >
                                {campaignPayload.channel && (
                                    <Text className="text-sm font-medium text-gray-800 mb-1.5">
                                        {t('channels.channel_selected', {
                                            channelName:
                                                CommunicationChannelName[
                                                    campaignPayload.channel
                                                ],
                                        })}
                                    </Text>
                                )}

                                <IntegrationOption
                                    title={integrationConfig?.customName}
                                    providerName={providerConfig.providerName}
                                    providerLogoUrl={providerConfig.logoUrl}
                                    integrationId={
                                        integrationConfig.integrationId ?? ''
                                    }
                                    isMutable={isEditMode}
                                    showRightSection={isEditMode}
                                />
                            </Box>
                        ) : !integrations?.length ? (
                            <Box>
                                <Text className="text-sm font-medium text-gray-600 mb-1.5">
                                    {t(
                                        'channel_settings.no_integrations_found',
                                        { channel: campaignPayload.channel },
                                    )}
                                </Text>
                                <Button
                                    size="sm"
                                    leftIcon={<PlusCircle color="white" />}
                                    onClick={handleAddChannel}
                                >
                                    {t('channel_settings.set_up_header')}
                                </Button>
                            </Box>
                        ) : (
                            <Stack>
                                <SearchInput
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder={t(
                                        'campaigns_builder.channel_search_placeholder',
                                        {
                                            channel: campaignPayload.channel,
                                        },
                                    )}
                                />
                                {formattedIntegrations?.map((integration) => (
                                    <IntegrationOption
                                        onClick={() =>
                                            onProviderChange(integration)
                                        }
                                        key={integration.integrationId}
                                        title={integration.customName}
                                        providerName={integration.providerName}
                                        providerLogoUrl={integration.logoUrl}
                                        integrationId={
                                            integration.integrationId ?? ''
                                        }
                                        isMutable={false}
                                        showRightSection={true}
                                    />
                                ))}
                                {search &&
                                    formattedIntegrations &&
                                    formattedIntegrations.length === 0 && (
                                        <NoResultsFound searchValue={search} />
                                    )}
                            </Stack>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default React.memo(CampaignBuilderChannel);
