import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import { FiltersPropertySelectorFieldItem } from '@components/Journeys/Builder/JourneyFilters/FiltersForm/FiltersPropertySelector';
import LightdashVisualization from '@components/LightdashVisualization';
import VisualizationProvider from '@components/LightdashVisualization/VisualizationProvider';
import { useGetAudienceBreakdown } from '@hooks/useAudience';
import { useLocale } from '@hooks/useLocale';
import {
    CartesianSeriesType,
    ChartType,
    FieldType,
    JoinType,
    type ApiInsightsCountQueryDto,
    type ApiQueryResults,
    type NestedMetricQueryGroup,
} from '@lightdash/common';
import {
    Box,
    Button,
    Flex,
    Group,
    Skeleton,
    Text,
    Tooltip,
} from '@mantine/core';
import { Info, PlusCircle, X } from '@phosphor-icons/react';
import useAudienceContext from '@providers/Audience/useAudienceContext';
import { filterTablesFromRelation } from '@utils/relation';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type InsightsCallbackType } from '.';
import { ButtonVariant } from '../../../mantineTheme';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import FieldListItem from '../Filters/FieldListItem';
import { convertFieldsIntoPropertySelectListType } from '../Filters/FieldListItem/utils';
import { useFieldsWithSuggestions } from '../Filters/FiltersCard/useFieldsWithSuggestions';
import { type FieldWithSuggestions } from '../Filters/FiltersProvider/types';
import { formatNestedMetricQuery } from './utils';

const BREAKDOWN_MAX_COUNT = 5;

const Breakdown = ({
    disableEdits,
    setRefreshCallback,
}: {
    disableEdits: boolean;
    setRefreshCallback: (payload: InsightsCallbackType) => void;
}) => {
    const initialRender = useRef<boolean>(false);
    const { t } = useLocale();
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [isPropertySelectOpen, setIsPropertySelectOpen] =
        useState<boolean>(false);
    const [breakdownDimensions, setBreakdownDimensions] = useState<
        (FieldWithSuggestions & AddditionalPropertySelectListProps)[]
    >([]);
    const { activeRelation, getTableRelation } = useRelationContext();
    const [chartData, setChartData] = useState<
        ApiInsightsCountQueryDto['results'] | undefined
    >(undefined);

    const { state, actions } = useAudienceContext((context) => context);
    const { audiencePayload, initialAudiencePayload } = state;
    const { setInsightsBreakdownPayload } = actions;

    const { mutateAsync: getBreakdownAsync, isLoading: isGetBreakdownLoading } =
        useGetAudienceBreakdown();

    const insightsRelationData = useMemo(() => {
        if (activeRelation) {
            const allowedRelationTables = getTableRelation([
                JoinType.one_one,
                JoinType.many_one,
            ]);
            if (!allowedRelationTables) return;
            const tableIds = allowedRelationTables.map((table) => table.name);
            const filteredRelation = filterTablesFromRelation(
                activeRelation,
                tableIds,
            );
            if (!filteredRelation) return;
            return filteredRelation;
        }
    }, [activeRelation, getTableRelation]);

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: insightsRelationData,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });

    const fieldWithSuggestionInArray = useMemo(() => {
        return Object.keys(fieldsWithSuggestions)
            ?.map((fieldKey: string) => {
                return {
                    ...fieldsWithSuggestions?.[fieldKey],
                    uniqueIdentifier:
                        fieldsWithSuggestions?.[fieldKey].uniqueIdentifier ??
                        fieldKey,
                };
            })
            .filter((field) => {
                const breakdownDimensionsIdentifiers = breakdownDimensions.map(
                    (dimension) => dimension.uniqueIdentifier,
                );
                return (
                    !breakdownDimensionsIdentifiers.includes(
                        field.uniqueIdentifier,
                    ) ||
                    (field.type as unknown as FieldType) !== FieldType.METRIC
                );
            });
    }, [breakdownDimensions, fieldsWithSuggestions]);

    const yField = `${activeRelation?.baseTable}_${
        (activeRelation?.tables[activeRelation?.baseTable] as any)?.userId
    }_count_distinct_of_users`;

    const refreshCallback = useCallback(() => {
        if (breakdownDimensions.length === 0) return;
        setIsRefreshing(true);
        const formattedNestedMetricQuery = formatNestedMetricQuery(
            audiencePayload.nestedMetricQuery,
        );
        getBreakdownAsync({
            relationName: activeRelation?.name ?? '',
            filters: {
                metricQuery:
                    formattedNestedMetricQuery as unknown as NestedMetricQueryGroup,
            },
            breakdownDimensions: breakdownDimensions.map(
                (dimension) => dimension.uniqueIdentifier ?? '',
            ),
        })
            .then((response) => {
                setChartData({
                    ...chartData,
                    ...response,
                });
            })
            .catch(() => {})
            .finally(() => setIsRefreshing(false));
    }, [
        activeRelation?.name,
        audiencePayload.nestedMetricQuery,
        breakdownDimensions,
        chartData,
        getBreakdownAsync,
    ]);

    useEffect(() => {
        if (
            initialAudiencePayload?.insights?.breakdownFilters &&
            breakdownDimensions.length === 0 &&
            fieldWithSuggestionInArray.length > 0 &&
            !initialRender.current
        ) {
            const initialBreakdownDimensions =
                fieldWithSuggestionInArray.filter((field) =>
                    initialAudiencePayload?.insights?.breakdownFilters?.includes(
                        field.uniqueIdentifier,
                    ),
                );
            setBreakdownDimensions(initialBreakdownDimensions);
            initialRender.current = true;
        }
    }, [
        breakdownDimensions.length,
        fieldWithSuggestionInArray,
        initialAudiencePayload?.insights?.breakdownFilters,
    ]);

    useEffect(() => {
        setRefreshCallback({
            breakdown: refreshCallback,
        });
    }, [refreshCallback, setRefreshCallback]);

    const handleDelete = (index: number) => {
        const newBreakdownDimensions = [...breakdownDimensions];
        newBreakdownDimensions.splice(index, 1);
        setBreakdownDimensions([...newBreakdownDimensions]);
        setInsightsBreakdownPayload([
            ...newBreakdownDimensions.map(
                (dimension) => dimension.uniqueIdentifier ?? '',
            ),
        ]);
    };

    const handleAddBreakdown = (
        field: (FieldWithSuggestions & AddditionalPropertySelectListProps)[],
    ) => {
        if (!field[0]) return;
        const newBreakdownDimensions = [...breakdownDimensions, ...field];
        setBreakdownDimensions([...newBreakdownDimensions]);
        setInsightsBreakdownPayload(
            newBreakdownDimensions.map(
                (dimension) => dimension.uniqueIdentifier ?? '',
            ),
        );
        setIsPropertySelectOpen(false);
        const formattedNestedMetricQuery = formatNestedMetricQuery(
            audiencePayload.nestedMetricQuery,
        );
        getBreakdownAsync({
            relationName: activeRelation?.name ?? '',
            filters: {
                metricQuery:
                    formattedNestedMetricQuery as unknown as NestedMetricQueryGroup,
            },
            breakdownDimensions: [field[0].uniqueIdentifier ?? ''],
        })
            .then((response) => {
                setChartData({
                    ...chartData,
                    [field[0].uniqueIdentifier ?? '']:
                        response[field[0].uniqueIdentifier ?? ''],
                });
            })
            .catch(() => {});
    };

    return (
        <Box className="p-3 h-fit">
            <Group align="center" className="mb-4" spacing={4}>
                <Text className="text-xs font-medium text-gray-500 uppercase">
                    {t('audience_builder.insights.breakdown')}
                </Text>
                <Tooltip
                    withinPortal
                    label={t('audience_builder.insights.breakdown_tooltip')}
                >
                    <Info size={13} className="cursor-pointer" />
                </Tooltip>
            </Group>

            {breakdownDimensions.map((dimension, index) => (
                <Box
                    key={dimension.uniqueIdentifier}
                    className="p-2 mb-2 border-base"
                >
                    <Flex justify={'space-between'} align={'center'}>
                        <FieldListItem
                            item={dimension}
                            checked={false}
                            showCheckbox={false}
                            disabled={false}
                            showFieldSource={true}
                            disableHover
                            showHoverIcon={false}
                        />
                        <X
                            weight="regular"
                            className="cursor-pointer"
                            onClick={() => handleDelete(index)}
                        />
                    </Flex>
                    {isRefreshing ||
                    (isGetBreakdownLoading &&
                        index === breakdownDimensions.length - 1) ? (
                        <Skeleton height={20} width={100} />
                    ) : (
                        <VisualizationProvider
                            isLoading={false}
                            initialPivotDimensions={[]}
                            resultsData={
                                chartData?.[
                                    dimension?.uniqueIdentifier ?? ''
                                ] as unknown as ApiQueryResults
                            }
                            chartConfig={{
                                type: ChartType.CARTESIAN,
                                config: {
                                    layout: {
                                        xField: dimension?.uniqueIdentifier,
                                        yField: [yField],
                                    },
                                    eChartsConfig: {
                                        series: [
                                            {
                                                type: CartesianSeriesType.BAR,
                                                name: 'Number of users',
                                                yAxisIndex: 0,
                                                encode: {
                                                    xRef: {
                                                        field:
                                                            dimension?.uniqueIdentifier ??
                                                            '',
                                                    },
                                                    yRef: {
                                                        field: yField,
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                },
                            }}
                            columnOrder={[]}
                            pivotTableMaxColumnLimit={5}
                            colorPalette={[
                                '#0195ff',
                                '#fc8452',
                                '#91cc75',
                                '#fac858',
                                '#ee6666',
                                '#73c0de',
                                '#3ba272',
                                '#9a60b4',
                                '#ea7ccc',
                            ]}
                        >
                            <LightdashVisualization
                                className="sentry-block ph-no-capture !h-[200px]"
                                data-testid="visualization"
                            />
                        </VisualizationProvider>
                    )}
                </Box>
            ))}

            <PropertySelect<
                FieldWithSuggestions & AddditionalPropertySelectListProps,
                any
            >
                items={convertFieldsIntoPropertySelectListType(
                    fieldWithSuggestionInArray,
                    true,
                )}
                showGroup={true}
                headerRightSection={null}
                onSubmit={handleAddBreakdown}
                itemTemplate={({ item: field }) => {
                    return <FiltersPropertySelectorFieldItem item={field} />;
                }}
                disabled={
                    disableEdits ||
                    breakdownDimensions.length >= BREAKDOWN_MAX_COUNT
                }
                opened={isPropertySelectOpen}
                close={() => setIsPropertySelectOpen(false)}
                open={() => setIsPropertySelectOpen(true)}
                withinPortal={true}
                targetButton={
                    breakdownDimensions.length < BREAKDOWN_MAX_COUNT && (
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={() => setIsPropertySelectOpen(true)}
                            leftIcon={
                                <PlusCircle color="rgb(var(--color-gray-700))" />
                            }
                            disabled={disableEdits}
                        >
                            {t('audience_builder.insights.add_breakdown')}
                        </Button>
                    )
                }
                showSearch={true}
                searchKeys={['label', 'name', 'tableLabel']}
                searchPlaceholder={t('audience_filters.search_filter_label')}
                allowMultipleSelection={false}
                showAllItemsGroup={true}
                // width={WIDTH}
                height={400}
            />
        </Box>
    );
};

export default Breakdown;
