import {
    ClockCountdown,
    DoorOpen,
    FlagCheckered,
    Gauge,
} from '@phosphor-icons/react';
import { t as translate } from 'i18next';
import React from 'react';
import EntryLimits from '../EntryLimits';
import ExitTriggers from '../ExitTriggers';
import Goals from '../Goals';
import OverView from '../OverviewPanel';
import Scheduling from '../SchedulingPanel';
import { JourneySettingType } from './types';

export interface SettingListItemType {
    label: string;
    children: React.ReactNode;
    leftSection?: React.ReactNode;
    type: JourneySettingType;
}

const JourneySettingsMenuGroups: SettingListItemType[][] = [
    [
        {
            label: translate('journey_settings.sidebar.overview'),
            type: JourneySettingType.OVERVIEW,
            children: <OverView />,
        },
    ],
    [
        {
            label: translate('journey_settings.sidebar.scheduling'),
            type: JourneySettingType.SCHEDULING,
            children: <Scheduling />,
            leftSection: <ClockCountdown size={14} weight="duotone" />,
        },
        {
            label: translate('journey_settings.sidebar.entry_limits'),
            type: JourneySettingType.ENTRY_LIMITS,
            children: <EntryLimits />,
            leftSection: <Gauge size={14} weight="duotone" />,
        },
        {
            label: translate('journey_settings.sidebar.exit_triggers'),
            type: JourneySettingType.EXIT_TRIGGERS,
            children: <ExitTriggers />,
            leftSection: <DoorOpen size={14} weight="duotone" />,
        },
        {
            label: translate('journey_settings.sidebar.goals'),
            type: JourneySettingType.GOALS,
            children: <Goals />,
            leftSection: <FlagCheckered size={14} weight="duotone" />,
        },
    ],
];
export default JourneySettingsMenuGroups;
