import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import TextArea from '@components/common/Inputs/TextArea';
import TextInput from '@components/common/Inputs/TextInput';
import FieldSelect from '@components/common/Select/FieldSelect';
import { useLocale } from '@hooks/useLocale';
import { useGenerateTableDescription } from '@hooks/useRelation';
import {
    useFetchRelations,
    usePreviewTable,
    useTableMetadata,
    useUpdateTableDetail,
} from '@hooks/useSchemaBuilder';
import {
    RelationTableType,
    type CreateRelationTableRequest,
    type RelationSchemaColumn,
    type WarehouseTableColumn,
} from '@lightdash/common';
import { Box, Button, Checkbox, Flex, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { CaretRight, Clock, CursorClick, Key } from '@phosphor-icons/react';
import { Table } from '@phosphor-icons/react/dist/ssr';
import useRelationContext from '@providers/Relation/useRelationContext';
import useSchemaContext from '@providers/Schema/useSchemaContext';
import { useQueryClient } from '@tanstack/react-query';
import { t as translate } from 'i18next';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../../../mantineTheme';
import { SchemaBuilderStep, SchemaFileType } from '../../types';
import { SchemaBuilderSteps } from '../../utils';
import SchemaPreviewModal from '../PrimaryTableSetup/SchemaPreviewModal';
const ALLOWED_CHARS_REGEX = /[^a-zA-Z0-9\s]/g;
const WHITESPACE_REGEX = /\s+/g;

export type TableConfigurationForm = {
    name: string;
    label: string;
    description: string | undefined;
    userId: string;
    primaryKey: string;
};

const getValidators = () => {
    const labelValidator = {
        label: (value: string | undefined) => {
            return !value || !value.length ? translate('required_field') : null;
        },
    };

    const primaryKeyValidator = {
        primaryKey: (value: string | undefined) => {
            return !value || !value.length ? translate('required_field') : null;
        },
    };

    return { ...labelValidator, primaryKeyValidator };
};

const PrimaryTableConfiguration: React.FC<{}> = () => {
    const { t } = useLocale();
    const [userIdSameAsPrimaryKey, setUserIdSameAsPrimaryKey] =
        useState<boolean>(true);
    const { tables } = useRelationContext();
    const {
        schemaPayload,
        activeProject,
        isEditMode,
        currentBuilderStep,
        isDrawerOpen,
        schemaFileType,
    } = useSchemaContext((context) => context.state);
    const { setCurrentBuilderStep, updateSchemaPayload } = useSchemaContext(
        (context) => context.actions,
    );
    const [showCsvPreview, { open: openCsvPreview, close: closeCsvPreview }] =
        useDisclosure(false);
    const [aiGeneratedTableName, setAiGeneratedTableName] =
        useState<string>('');
    const [aiGeneratedTableDescription, setAiGeneratedTableDescription] =
        useState<string>('');
    const [primaryKey, setPrimaryKey] = useState<FieldWithSuggestions>();
    const [userId, setUserId] = useState<FieldWithSuggestions>();
    const [timestampColumn, setTimestampColumn] =
        useState<FieldWithSuggestions>();
    const [eventNameColumn, setEventNameColumn] =
        useState<FieldWithSuggestions>();
    const queryClient = useQueryClient();

    const { data: relations = [] } =
        useFetchRelations(activeProject?.projectUuid) ?? {};
    const { mutateAsync: updateTableDetail, isLoading: isUpdatingTableDetail } =
        useUpdateTableDetail(relations[0]?.uuid);

    const {
        mutateAsync: mutatePreviewTable,
        data: previewTableData,
        isLoading: isPreviewingTable,
    } = usePreviewTable();

    const {
        mutateAsync: generateTableDescription,
        isLoading: isAiGeneratingTableDescription,
    } = useGenerateTableDescription();

    const sourceTable = useMemo(
        () => tables?.find((table) => table.name === schemaPayload.name),
        [tables, schemaPayload],
    );

    const shouldResetToSetup = useMemo(() => {
        return (
            (schemaFileType === SchemaFileType.WAREHOUSE &&
                (!schemaPayload.database ||
                    !schemaPayload.schema ||
                    !schemaPayload.name)) ||
            (schemaFileType === SchemaFileType.CSV &&
                !schemaPayload.blobFilePath)
        );
    }, [schemaFileType, schemaPayload]);

    useEffect(() => {
        if (shouldResetToSetup) {
            setCurrentBuilderStep(SchemaBuilderStep.SETUP);
        }

        if (schemaPayload.primaryKey) {
            const col = schemaPayload.columns.find(
                (column: RelationSchemaColumn) =>
                    column.name === schemaPayload.primaryKey,
            );
            setPrimaryKey(col);
        }

        if (schemaPayload.userId) {
            const col = schemaPayload.columns.find(
                (column: RelationSchemaColumn) =>
                    column.name === schemaPayload.userId,
            );
            setUserId(col);
            setUserIdSameAsPrimaryKey(false);
        }

        if (schemaPayload.userId === schemaPayload.primaryKey) {
            setUserIdSameAsPrimaryKey(true);
        }

        if (schemaPayload.eventTimestampColumn) {
            const col = schemaPayload.columns.find(
                (column: RelationSchemaColumn) =>
                    column.name === schemaPayload.eventTimestampColumn,
            );
            setTimestampColumn(col);
        }

        if (schemaPayload.eventNameColumn) {
            const col = schemaPayload.columns.find(
                (column: RelationSchemaColumn) =>
                    column.name === schemaPayload.eventNameColumn,
            );
            setEventNameColumn(col);
        }
    }, [
        schemaFileType,
        schemaPayload,
        setCurrentBuilderStep,
        shouldResetToSetup,
    ]);

    const form = useForm({
        initialValues: {
            label: schemaPayload.label ?? '',
            description: schemaPayload.description ?? '',
            userId: schemaPayload.userId ?? '',
            primaryKey: schemaPayload.primaryKey ?? undefined,
        },
        validate: getValidators(),
        initialErrors: {
            label: '',
            primaryKey: '',
            userId: '',
        },
    });

    const { data: tableMetadata, isFetching: isTableMetadataLoading } =
        useTableMetadata({
            database: schemaPayload.database,
            schema: schemaPayload.schema,
            table: schemaPayload.name,
            schemaFileType,
        });

    useEffect(() => {
        if (schemaFileType === SchemaFileType.CSV) {
            generateTableDescription({
                columns: schemaPayload.columns,
                database: schemaPayload.database,
                schema: schemaPayload.schema,
                table: schemaPayload.name,
            })
                .then((response) => {
                    setAiGeneratedTableDescription(response.tableDescription);
                    setAiGeneratedTableName(response.tableLabel);
                    form.setFieldValue(
                        'description',
                        response.tableDescription,
                    );
                    form.setFieldValue('label', response.tableLabel);
                })
                .catch((error) => {
                    console.log(error, 'error');
                });
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateTableDescription, isEditMode, schemaPayload.name]);

    useEffect(() => {
        if (
            tableMetadata &&
            !isEditMode &&
            schemaFileType === SchemaFileType.WAREHOUSE
        ) {
            updateSchemaPayload({
                ...schemaPayload,
                columns: tableMetadata.columns,
            });

            generateTableDescription({
                columns: tableMetadata.columns,
                database: schemaPayload.database,
                schema: schemaPayload.schema,
                table: schemaPayload.name,
            })
                .then((response) => {
                    setAiGeneratedTableDescription(response.tableDescription);
                    setAiGeneratedTableName(response.tableLabel);
                    form.setFieldValue(
                        'description',
                        response.tableDescription,
                    );
                    form.setFieldValue('label', response.tableLabel);
                })
                .catch((error) => {
                    console.log(error, 'error');
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        generateTableDescription,
        schemaPayload.name,
        isEditMode,
        tableMetadata,
    ]);

    const updateDetail = useCallback(
        async (field: string, value: any) => {
            if (!isEditMode) return;

            // Update table
            await updateTableDetail({
                tableName: schemaPayload.name,
                payload: {
                    type: schemaPayload.type,
                    [field]: value,
                },
            });
            await queryClient.invalidateQueries([QueryKeys.RELATION_SCHEMA]);
        },
        [isEditMode, schemaPayload, updateTableDetail, queryClient],
    );

    const getNextStep = useCallback(() => {
        const currentStepIndex = SchemaBuilderSteps.findIndex(
            (step) => step.key === currentBuilderStep,
        );

        if (currentStepIndex === SchemaBuilderSteps.length - 1) return;

        return SchemaBuilderSteps[currentStepIndex + 1]?.key;
    }, [currentBuilderStep]);

    const handleNext = useCallback(() => {
        const nextStep = getNextStep();
        if (!nextStep) return;

        setCurrentBuilderStep(nextStep as SchemaBuilderStep);
    }, [getNextStep, setCurrentBuilderStep]);

    const handleSubmit = useCallback(
        async (
            values: Pick<
                CreateRelationTableRequest,
                'label' | 'description' | 'primaryKey'
            > & { userId: string },
        ) => {
            if (!values.label?.trim()) {
                form.setErrors({
                    ...form.errors,
                    label: t('common.error.empty_text_input'),
                });
                return;
            }
            if (schemaPayload.type === RelationTableType.PRIMARY) {
                if (!values.primaryKey) {
                    form.setErrors({
                        ...form.errors,
                        primaryKey: t('common.error.empty_select_input'),
                    });
                    return;
                }
                if (!userIdSameAsPrimaryKey && !values.userId) {
                    form.setErrors({
                        ...form.errors,
                        userId: t('common.error.empty_select_input'),
                    });
                    return;
                }
            }

            const payload: CreateRelationTableRequest = {
                ...schemaPayload,
                ...values,
            };

            if (
                'userId' in payload &&
                userIdSameAsPrimaryKey &&
                payload.type === RelationTableType.PRIMARY
            ) {
                payload.userId = values.primaryKey ?? '';
            }
            if (payload.label) {
                payload.label = payload.label.trim();
            }

            const updatedSchemaPayload = {
                ...payload,
                ...(schemaFileType === SchemaFileType.CSV
                    ? {
                          name: form.values.label
                              .trim()
                              .replace(ALLOWED_CHARS_REGEX, '')
                              .replace(WHITESPACE_REGEX, '_'),
                      }
                    : {}),
            };
            updateSchemaPayload(updatedSchemaPayload);

            handleNext();
        },
        [
            form,
            handleNext,
            schemaFileType,
            schemaPayload,
            t,
            updateSchemaPayload,
            userIdSameAsPrimaryKey,
        ],
    );

    const handlePrimaryKeyChange = useCallback(
        async (value: FieldWithSuggestions) => {
            if (schemaFileType === SchemaFileType.CSV) {
                const hasInvalidValue = schemaPayload?.csvData.some(
                    (item: any) =>
                        isEmpty(item[value?.name]) ||
                        item[value?.name] === null,
                );

                if (hasInvalidValue) {
                    form.setErrors({
                        ...form.errors,
                        primaryKey: t('schema_builder.primary_key_error'),
                    });
                    return;
                }
                const valueMap = new Map();
                const hasDuplicates = schemaPayload?.csvData.some(
                    (item: any) => {
                        const columnValue = item[value?.name];
                        if (valueMap.has(columnValue)) {
                            return true;
                        }
                        valueMap.set(columnValue, true);
                        return false;
                    },
                );

                if (hasDuplicates) {
                    form.setErrors({
                        ...form.errors,
                        primaryKey: t(
                            'schema_builder.duplicate_primary_key_error',
                        ),
                    });
                    return;
                }
            }

            setPrimaryKey(value);
            form.setFieldValue('primaryKey', value?.name);
            await updateDetail('primaryKey', value?.name);
        },
        [schemaFileType, form, updateDetail, schemaPayload?.csvData, t],
    );

    const fieldWithSuggestionInArray = useMemo(() => {
        if (schemaFileType === SchemaFileType.CSV) {
            return schemaPayload.columns?.map((item: WarehouseTableColumn) => {
                return {
                    ...item,
                    label: item.name,
                    name: item.name,
                    type: item.type,
                } as FieldWithSuggestions;
            });
        }
        return tableMetadata?.columns?.map((item: WarehouseTableColumn) => {
            return {
                ...item,
                label: item.name,
                name: item.name,
                type: item.type,
            } as FieldWithSuggestions;
        });
    }, [schemaFileType, schemaPayload.columns, tableMetadata?.columns]);

    const getPreviousStep = useCallback(() => {
        const currentStepIndex = SchemaBuilderSteps.findIndex(
            (step) => step.key === currentBuilderStep,
        );
        return SchemaBuilderSteps[currentStepIndex - 1]?.key;
    }, [currentBuilderStep]);

    const handlePrev = useCallback(() => {
        if (isEditMode || isUpdatingTableDetail) return;
        if (schemaFileType === SchemaFileType.WAREHOUSE) {
            updateSchemaPayload({
                ...schemaPayload,
                name: '',
                schema: '',
                database: '',
            });
        }
        const previousStep = getPreviousStep();
        if (previousStep) {
            setCurrentBuilderStep(previousStep as SchemaBuilderStep);
        }
    }, [
        isEditMode,
        isUpdatingTableDetail,
        getPreviousStep,
        setCurrentBuilderStep,
        schemaFileType,
        schemaPayload,
        updateSchemaPayload,
    ]);

    const handlePreviewTable = useCallback(async () => {
        try {
            await mutatePreviewTable({
                tableId: schemaPayload.name,
                dimensions: schemaPayload.columns.map(
                    (item: WarehouseTableColumn) =>
                        `${schemaPayload.name}_${item.name}`,
                ),
                limit: 10,
                offset: 0,
            });
            openCsvPreview();
        } catch (error) {}
    }, [
        mutatePreviewTable,
        schemaPayload.columns,
        schemaPayload.name,
        openCsvPreview,
    ]);

    return (
        <Box className="w-8/12 mt-4 pb-14">
            <form id="table_config_form" name="table_config_form">
                <Stack className="gap-6">
                    <Stack
                        className={`gap-1.5 ${
                            !isEditMode ? 'cursor-pointer' : ''
                        }`}
                    >
                        <Text className="text-sm font-medium text-gray-800">
                            {t('schema_builder.table_chooser.selected_table')}
                        </Text>
                        <Flex
                            justify={'space-between'}
                            className="p-2 border-gray-250 rounded-lg border-base"
                        >
                            <Flex gap={4} align={'center'}>
                                <Text className="text-gray-600">
                                    {t(
                                        'schema_builder.table_chooser.selected_table_desc',
                                    )}
                                </Text>
                                <Table color="gray.8" />
                                <Text className="text-gray-800">
                                    {schemaPayload?.name}
                                </Text>
                            </Flex>
                            <Box>
                                {schemaPayload?.userUploadedTable && (
                                    <Button
                                        onClick={handlePreviewTable}
                                        className="px-3 py-2"
                                        loading={isPreviewingTable}
                                        variant={ButtonVariant.DEFAULT}
                                    >
                                        {t('schema.preview_data')}
                                    </Button>
                                )}
                                {!isEditMode && (
                                    <Text
                                        onClick={handlePrev}
                                        className="text-sm font-semibold text-gray-700 border border-gray-250 rounded-md px-3 py-2"
                                    >
                                        {t('common.edit')}
                                    </Text>
                                )}
                            </Box>
                        </Flex>
                    </Stack>

                    {!isEditMode && (
                        <>
                            <Stack className="gap-1.5">
                                <TextInput
                                    data-autofocus
                                    label={
                                        <Text className="text-gray-800">
                                            {t(
                                                'primary_table_config.title_field_label',
                                            )}
                                        </Text>
                                    }
                                    placeholder={t(
                                        'schema_builder.table_chooser.table_name.placeholder',
                                    )}
                                    className="!w-[272px]"
                                    aiGeneratedData={aiGeneratedTableName}
                                    isAiGeneratingData={
                                        isAiGeneratingTableDescription ||
                                        isTableMetadataLoading
                                    }
                                    {...form.getInputProps('label')}
                                    error={form.getInputProps('label').error}
                                />

                                <Text className="text-sm font-medium text-gray-500">
                                    {t(
                                        'schema_builder.table_chooser.table_name.help_text',
                                    )}
                                </Text>
                            </Stack>

                            <Stack className="gap-1.5">
                                <TextArea
                                    label={
                                        <Box className="flex">
                                            <Text className="inline">
                                                {t(
                                                    'schema_builder.table_chooser.description.label',
                                                )}
                                            </Text>
                                            <Text className="text-gray-600 ml-1 text-sm font-medium">
                                                {t('common.optional')}
                                            </Text>
                                        </Box>
                                    }
                                    placeholder={t(
                                        'schema_builder.table_chooser.description.placeholder',
                                    )}
                                    aiGeneratedData={
                                        aiGeneratedTableDescription
                                    }
                                    isAiGeneratingData={
                                        isAiGeneratingTableDescription ||
                                        isTableMetadataLoading
                                    }
                                    {...form.getInputProps('description')}
                                />
                            </Stack>
                        </>
                    )}

                    <Stack className="gap-1.5">
                        <Text className="text-sm font-medium text-gray-800">
                            <Flex align="center">
                                <Key
                                    className="mr-1"
                                    color={
                                        isDrawerOpen
                                            ? 'rgb(var(--color-green)'
                                            : 'rgb(var(--color-blu-800))'
                                    }
                                />
                                {`${t(
                                    'schema_builder.table_chooser.primary_key.label',
                                )} `}
                                {schemaPayload.type !==
                                    RelationTableType.PRIMARY && (
                                    <Text className="text-gray-600 ml-1 text-sm font-medium">
                                        {t('common.optional')}
                                    </Text>
                                )}
                            </Flex>
                        </Text>

                        <FieldSelect
                            size="sm"
                            hideTableLabel
                            item={primaryKey}
                            items={fieldWithSuggestionInArray || []}
                            placeholder={t(
                                'schema_builder.table_chooser.primary_key.label',
                            )}
                            disabled={isTableMetadataLoading}
                            onChange={handlePrimaryKeyChange}
                            error={form.getInputProps('primaryKey').error}
                            isDisabled={isUpdatingTableDetail}
                        />

                        <Text className="text-sm font-medium text-gray-500">
                            {t(
                                'schema_builder.table_chooser.primary_key.help_text',
                            )}
                        </Text>
                    </Stack>

                    {schemaPayload.type === RelationTableType.PRIMARY && (
                        <Stack className="gap-1.5">
                            <Text className="text-sm font-medium text-gray-800">
                                {t(
                                    'schema_builder.table_chooser.unique_uid.label',
                                )}
                            </Text>

                            <Checkbox
                                label={t(
                                    'schema_builder.table_chooser.unique_uid.same',
                                )}
                                checked={userIdSameAsPrimaryKey}
                                onChange={async (event) => {
                                    setUserIdSameAsPrimaryKey(
                                        event.currentTarget.checked,
                                    );
                                    if (event.currentTarget.checked) {
                                        await updateDetail(
                                            'userId',
                                            primaryKey?.name,
                                        );
                                    }
                                }}
                                disabled={isUpdatingTableDetail}
                            />

                            <Text className="text-sm font-medium text-gray-500">
                                {t(
                                    'schema_builder.table_chooser.unique_uid.help_text',
                                )}
                            </Text>
                        </Stack>
                    )}

                    {!userIdSameAsPrimaryKey && (
                        <Stack className="gap-1.5">
                            <FieldSelect
                                item={userId}
                                items={fieldWithSuggestionInArray || []}
                                {...form.getInputProps('userId')}
                                placeholder={t(
                                    'schema_builder.table_chooser.unique_uid.label',
                                )}
                                size={'sm'}
                                hideTableLabel
                                onChange={async (value) => {
                                    setUserId(value);
                                    form.setFieldValue('userId', value?.name);
                                    await updateDetail('userId', value?.name);
                                }}
                                isDisabled={isUpdatingTableDetail}
                            />
                        </Stack>
                    )}

                    {schemaPayload.type === RelationTableType.EVENT && (
                        <>
                            <Stack className="gap-1.5">
                                <Text className="text-sm font-medium text-gray-800">
                                    <Flex align="center">
                                        <Clock
                                            className="mr-1"
                                            color={
                                                'rgb(var(--color-mustard-800))'
                                            }
                                        />
                                        Timestamp
                                    </Flex>
                                </Text>
                                <FieldSelect
                                    size="sm"
                                    placeholder="Timestamp"
                                    hideTableLabel
                                    item={timestampColumn}
                                    items={
                                        (isEditMode
                                            ? sourceTable.columns
                                            : fieldWithSuggestionInArray) || []
                                    }
                                    onChange={async (value) => {
                                        setTimestampColumn(value);
                                        form.setFieldValue(
                                            'eventTimestampColumn',
                                            value?.name,
                                        );
                                        await updateDetail(
                                            'eventTimestampColumn',
                                            value?.name,
                                        );
                                    }}
                                    isDisabled={isUpdatingTableDetail}
                                />
                            </Stack>

                            <Stack className="gap-1.5">
                                <Text className="text-sm font-medium text-gray-800">
                                    <Flex align="center">
                                        <CursorClick
                                            className="mr-1"
                                            color={
                                                'rgb(var(--color-mustard-800))'
                                            }
                                        />
                                        Event name
                                    </Flex>
                                </Text>
                                <FieldSelect
                                    size="sm"
                                    placeholder="Event name"
                                    hideTableLabel
                                    item={eventNameColumn}
                                    items={
                                        (isEditMode
                                            ? sourceTable.columns
                                            : fieldWithSuggestionInArray) || []
                                    }
                                    onChange={async (value) => {
                                        setEventNameColumn(value);
                                        form.setFieldValue(
                                            'eventNameColumn',
                                            value?.name,
                                        );
                                        await updateDetail(
                                            'eventNameColumn',
                                            value?.name,
                                        );
                                    }}
                                    isDisabled={isUpdatingTableDetail}
                                />
                            </Stack>
                        </>
                    )}
                </Stack>
            </form>
            {!isEditMode && (
                <Box className="fixed bottom-0 left-0 w-full bg-white border-t-2">
                    <Flex className="px-4 my-4" justify={'flex-end'}>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            onClick={async () => {
                                await handleSubmit(form.values);
                            }}
                            rightIcon={<CaretRight color="white" size={14} />}
                            className="py-2"
                        >
                            {t(
                                schemaPayload.type === RelationTableType.PRIMARY
                                    ? 'schema_builder.table_chooser.looks_good'
                                    : 'common.continue',
                            )}
                        </Button>
                    </Flex>
                </Box>
            )}
            {previewTableData && !isPreviewingTable && (
                <SchemaPreviewModal
                    open={showCsvPreview}
                    onClose={closeCsvPreview}
                    fileName={schemaPayload?.name}
                    count={previewTableData?.length ?? 0}
                    schemaCsvData={previewTableData ?? []}
                />
            )}
        </Box>
    );
};

export default React.memo(PrimaryTableConfiguration);
