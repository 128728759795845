import BeeFreeSDK from '@beefree.io/sdk';
import TextArea from '@components/common/Inputs/TextArea';
import TextInput from '@components/common/Inputs/TextInput';
import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import UnsavedChangesConfirmModal from '@components/common/modal/UnsavedChangesConfirmModal';
import { useLocale } from '@hooks/useLocale';
import {
    useCreateTemplate,
    useGenerateTemplateNameDescription,
    useInitiateBeefree,
    useUpdateTemplateContent,
} from '@hooks/useTemplate';
import {
    CommunicationChannel,
    previewTextVariable,
    type AnyType,
    type ProviderTemplateDetails,
    type TemplateContentDetails,
} from '@lightdash/common';
import { Button, Flex, Stack, Text } from '@mantine/core';
import { CheckCircle } from '@phosphor-icons/react';
import useTemplateBuilderContext from '@providers/TemplateBuilder/useTemplateBuilderContext';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';
import ConfirmTemplateSaveModal from '../Builder/modals/ConfirmTemplateSaveModal';
import { type HtmlTemplateDetails } from '../HTMLManager/types';
import { appendPreheader } from '../utils';
import useBeeFreeConfig, { starterTemplate } from './useBeeFreeConfig';

type BeeFreeEditorProps = {
    templateDetail: HtmlTemplateDetails;
    onClose: (() => void) | undefined;
    onSave: ((payload: ProviderTemplateDetails) => void) | undefined;
    handleSave: ((payload: TemplateContentDetails) => void) | undefined;
};
const BeeFreeEditor = ({
    templateDetail,
    onClose,
    onSave,
    handleSave: handleSaveProp,
}: BeeFreeEditorProps) => {
    const { t } = useLocale();
    const query = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const [beefree, setBeefree] = useState<BeeFreeSDK | undefined>(undefined);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showSaveAsNewModal, setShowSaveAsNewModal] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [templateDescription, setTemplateDescription] = useState('');
    const [templateJson, setTemplateJson] = useState<Record<string, AnyType>>(
        {},
    );
    const [templateHtml, setTemplateHtml] = useState<string>('');
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    const [openUnsavedChangesModal, setOpenUnsavedChangesModal] =
        useState<boolean>(false);
    const { setOpenCreateTemplateModal } = useTemplateBuilderContext(
        (context) => context.actions,
    );

    const { mutateAsync: mutateAsyncCreateTemplate } = useCreateTemplate();

    const { mutateAsync: mutateAsyncUpdateTemplate } = useUpdateTemplateContent(
        templateDetail.id ?? '',
        1,
    );

    const {
        mutateAsync: mutateAsyncGenerateTemplateNameDescription,
        data: nameDescription,
        isLoading: isLoadingNameDescription,
    } = useGenerateTemplateNameDescription();

    const { data: initiateBeefreeData, isLoading: initiateBeefreeLoading } =
        useInitiateBeefree();

    const handleSave = (jsonFile: unknown, htmlFile: unknown) => {
        setTemplateJson(JSON.parse(jsonFile as string));
        setTemplateHtml(htmlFile as string);
        if (handleSaveProp) {
            handleSaveProp({
                html: htmlFile as string,
                json: JSON.parse(jsonFile as string),
            });
            if (onClose && typeof onClose === 'function') {
                onClose();
            }
            return;
        }
        if (templateDetail.id) {
            setShowSaveAsNewModal(true);
        } else {
            setShowSaveModal(true);
        }
    };

    const handleTemplateUpdate = () => {
        if (!templateDetail.id) return;

        mutateAsyncUpdateTemplate({
            html: appendPreheader(templateHtml, previewTextVariable),
            json: templateJson,
            subject: '',
        })
            .then(() => {
                setOpenCreateTemplateModal(false);
                if (onClose && typeof onClose === 'function') {
                    onClose();
                }
                void query.refetchQueries([QueryKeys.GET_TEMPLATE]);
            })
            .catch(() => {});
    };

    const handleSaveTemplate = () => {
        if (!templateName) {
            return;
        }
        mutateAsyncCreateTemplate({
            name: templateName,
            description: templateDescription,
            channel: CommunicationChannel.EMAIL,
            consideredInLibrary: true,
            tags: [],
            content: {
                html: appendPreheader(templateHtml, previewTextVariable),
                subject: '',
                json: templateJson,
            },
            previewUrl: '',
        })
            .then((response) => {
                setShowSaveModal(false);
                setOpenCreateTemplateModal(false);
                if (onSave && typeof onSave === 'function') {
                    onSave({
                        id: response.id,
                        name: response.name,
                        description: response.description,
                    });
                }
                if (onClose && typeof onClose === 'function') {
                    onClose();
                }
                if (location.pathname.includes('templates')) {
                    void navigate(
                        `/projects/${projectUuid}/templates/email/${response.id}`,
                    );
                    void query.refetchQueries([QueryKeys.GET_TEMPLATE]);
                }
            })
            .catch(() => {});
    };

    const handleChange = () => {
        setUnsavedChanges(true);
    };

    const beeConfig = useBeeFreeConfig({
        handleSave,
        handleChange,
    });

    useEffect(() => {
        if (
            showSaveModal &&
            !templateName &&
            !templateDescription &&
            !nameDescription
        ) {
            void mutateAsyncGenerateTemplateNameDescription({
                channel: CommunicationChannel.EMAIL,
                templateContent: {
                    html: appendPreheader(templateHtml, previewTextVariable),
                    subject: '',
                    json: templateJson,
                },
            }).then((res) => {
                setTemplateName(res.name);
                setTemplateDescription(res.description);
            });
        }
    }, [
        mutateAsyncGenerateTemplateNameDescription,
        nameDescription,
        showSaveModal,
        templateDescription,
        templateHtml,
        templateJson,
        templateName,
    ]);

    useEffect(() => {
        if (!initiateBeefreeLoading) {
            const beeInstance = new BeeFreeSDK(initiateBeefreeData);
            setBeefree(beeInstance);
            beeInstance
                .start(beeConfig, templateDetail?.json ?? starterTemplate)
                .then(() => {})
                .catch(() => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initiateBeefreeData, initiateBeefreeLoading]);

    return (
        <>
            <Flex
                className="px-3 py-3"
                justify={'space-between'}
                align={'center'}
            >
                <Text className="text-gray-800 font-medium text-lg">
                    {templateDetail.id
                        ? t('template_create.email.update_title')
                        : t('template_create.email.create_title')}
                </Text>
                <Flex align={'center'} gap={8}>
                    <Button
                        onClick={() => {
                            if (unsavedChanges) {
                                setOpenUnsavedChangesModal(true);
                            } else if (onClose) {
                                onClose();
                            }
                        }}
                        variant={ButtonVariant.OUTLINED}
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            if (beefree) {
                                beefree.save();
                            }
                        }}
                        leftIcon={<CheckCircle color="white" />}
                    >
                        {templateDetail.id
                            ? t('common.save_changes')
                            : t('common.save')}
                    </Button>
                </Flex>
            </Flex>
            <div
                style={{
                    width: '100vw',
                    height: 'calc(100vh - 56px)',
                }}
                id="beefree-sdk-container"
            ></div>
            <Modal
                opened={showSaveModal}
                title={t('template_create.save')}
                onClose={() => setShowSaveModal(false)}
            >
                <Stack className="mb-3">
                    <TextInput
                        value={templateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                        label={t('template_create.name_label')}
                        placeholder={t(
                            'template_create.modal_name_placeholder',
                        )}
                        isAiGeneratingData={isLoadingNameDescription}
                        aiGeneratedData={nameDescription?.name}
                    />
                    <TextArea
                        label={`${t('common.description')} ${t(
                            'common.optional',
                        )}`}
                        placeholder={t(
                            'template_create.modal_description_placeholder',
                        )}
                        value={templateDescription}
                        onChange={(e) => setTemplateDescription(e.target.value)}
                        isAiGeneratingData={isLoadingNameDescription}
                        aiGeneratedData={nameDescription?.description}
                    />
                </Stack>
                <ModalFooter
                    justify="right"
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={undefined}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    isLoading={false}
                    primaryButtonCustomClass=""
                    primaryButtonDisabled={false}
                    primaryButtonClick={handleSaveTemplate}
                    primaryText={t('common.save')}
                    secondaryButtonClick={() => setShowSaveModal(false)}
                    secondaryText={t('common.cancel')}
                    showPrimaryButton
                    showSecondaryButton
                    primaryLeftIcon={undefined}
                    primaryRightIcon={undefined}
                />
            </Modal>
            <ConfirmTemplateSaveModal
                opened={showSaveAsNewModal}
                handleSave={handleTemplateUpdate}
                handleSaveAsNewTemplate={() => {
                    setShowSaveModal(true);
                    setShowSaveAsNewModal(false);
                }}
                isLoading={false}
                onClose={() => setShowSaveAsNewModal(false)}
            />
            <UnsavedChangesConfirmModal
                opened={openUnsavedChangesModal}
                close={() => setOpenUnsavedChangesModal(false)}
                primaryActionButtonClick={() => onClose && onClose()}
                secondaryActionButtonClick={() =>
                    setOpenUnsavedChangesModal(false)
                }
            />
        </>
    );
};

export default BeeFreeEditor;
