import { useIsTruncated } from '@hooks/useIsTruncated';
import { Box, Text, Tooltip } from '@mantine/core';
import { isValidElement } from 'react';
interface AudiencePreviewCellProps<T> {
    value: T;
}

const AUDIENCE_TABLE_CELL = `text-sm font-medium text-gray-800 max-w-[16rem] truncate`;

const AudiencePreviewCell = <T,>({ value }: AudiencePreviewCellProps<T>) => {
    const { ref: truncatedRef, isTruncated } = useIsTruncated<HTMLDivElement>();

    return (
        <Box>
            {isValidElement(value) ? (
                value
            ) : typeof value === 'object' ? (
                <Tooltip
                    withinPortal
                    variant="xs"
                    label={JSON.stringify(value)}
                    disabled={!isTruncated}
                    styles={{
                        tooltip: {
                            maxWidth: '80rem',
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                            overflowWrap: 'break-word',
                        },
                    }}
                >
                    <Text className={AUDIENCE_TABLE_CELL} ref={truncatedRef}>
                        {JSON.stringify(value)}
                    </Text>
                </Tooltip>
            ) : (
                <Tooltip
                    withinPortal
                    multiline
                    variant="xs"
                    label={
                        typeof value === 'string'
                            ? value
                            : JSON.stringify(value)
                    }
                    disabled={!isTruncated}
                    styles={{
                        tooltip: {
                            maxWidth: '80rem',
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                            overflowWrap: 'break-word',
                        },
                    }}
                >
                    <Text className={AUDIENCE_TABLE_CELL} ref={truncatedRef}>
                        {typeof value === 'string'
                            ? value
                            : JSON.stringify(value)}
                    </Text>
                </Tooltip>
            )}
        </Box>
    );
};

export default AudiencePreviewCell;
