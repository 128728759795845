import Modal from '@components/common/modal/Modal';
import TemplateBuilderManager from '@components/Templates/Builder/TemplateBuilderManager';
import { useGetProviderMetada } from '@hooks/useChannels';
import {
    type ExternalCampaignTriggerRequest,
    type ProviderTemplateDetails,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import React from 'react';

interface JourneyTemplateSelectionModalProps {
    opened: boolean;
    closeTemplateSelection: () => void;
    messageConfig: ExternalCampaignTriggerRequest;
    onTemplateSelect: (value: ProviderTemplateDetails) => void;
}

const JourneyTemplateSelectionModal: React.FC<
    JourneyTemplateSelectionModalProps
> = ({
    opened,
    closeTemplateSelection,
    messageConfig,

    onTemplateSelect,
}) => {
    const { data: providerMetadata } = useGetProviderMetada(
        messageConfig.communicationDetails?.providerId ?? '',
        messageConfig.channel ?? '',
    );

    return (
        <Modal
            opened={opened}
            onClose={closeTemplateSelection}
            title={'Select your content'}
            size="lg"
            closeOnClickOutside={false}
        >
            {providerMetadata && messageConfig?.channel && (
                <Box className="w-full">
                    <TemplateBuilderManager
                        integration={providerMetadata}
                        isSingleSelect={true}
                        handleTemplateSelect={onTemplateSelect}
                        integrationId={
                            messageConfig?.communicationDetails?.id ?? ''
                        }
                        channel={messageConfig?.channel}
                        onClose={undefined}
                        editMode={false}
                        handleUpdateTemplateInCampaignContext={undefined}
                        contentDetails={messageConfig.contentDetails}
                    />
                </Box>
            )}
        </Modal>
    );
};

export default React.memo(JourneyTemplateSelectionModal);
