import { useFieldsWithEvents } from '@components/Audience/Filters/FiltersCard/useFieldsWithEvents';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider/FiltersProvider';
import ErrorState from '@components/common/ErrorState';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import Page from '@components/Page/Page';
import { useGetCustomMetricById } from '@hooks/useCustomMetric';
import { useLocale } from '@hooks/useLocale';
import useSearchParams from '@hooks/useSearchParams';
import { Box } from '@mantine/core';
import CustomAttributeProvider from '@providers/CustomAttribute/CustomAttributeProvider';
import useProjectContext from '@providers/Project/useProjectContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import CustomAttributeContainer from './CustomAttributeContainer';
import { useCustomAttributeInitialData } from './useCustomAttributeInitialData';

const CustomAttributeBuilder = () => {
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const { projectUuid, mode, customAttributeUuid } = useParams<{
        projectUuid: string;
        mode?: string;
        customAttributeUuid: string;
    }>();
    const customAttributeTemplateUuid = useSearchParams('templateId');
    const location = useLocation();

    const { t } = useLocale();
    const {
        data: customAttributePayload,
        isInitialLoading,
        error,
    } = useGetCustomMetricById(
        customAttributeUuid ?? customAttributeTemplateUuid ?? '',
    );

    const customAttribute = useMemo(
        () => location.state?.customAttribute,
        [location.state?.customAttribute],
    );

    const isNewMode = location.pathname.includes('create');
    const isEditMode = isNewMode || mode === 'edit';
    const isDuplicateMode = isNewMode && Boolean(customAttributeTemplateUuid);
    const { projectData } = useProjectContext();
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });
    const initialState = useCustomAttributeInitialData({
        customAttributeData: customAttribute ?? customAttributePayload,
        relationUuid: activeRelationUuid,
        activeRelation,
        fieldsWithSuggestions,
        isEditMode,
        isDuplicateMode,
    });
    const { data: eventsData, eventsTableNames } = useFieldsWithEvents({
        relationData: activeRelation,
        activeRelationUuid,
        projectUuid,
    });

    const pageTitle = useMemo(() => {
        if (!activeRelation) {
            return t('custom_attributes');
        }
        if (isNewMode) {
            return t('custom_attribute.create.title');
        }
        return customAttribute?.name;
    }, [activeRelation, isNewMode, customAttribute, t]);

    if (isInitialLoading) {
        return (
            <Box className="mt-5">
                <SuboptimalState title="Loading..." loading />
            </Box>
        );
    }
    if (error) {
        return <ErrorState error={error.error} />;
    }

    return (
        <FiltersProvider
            projectUuid={projectUuid}
            fieldsMap={fieldsWithSuggestions}
            startOfWeek={
                projectData?.warehouseConnection?.startOfWeek ?? undefined
            }
            eventsMap={eventsData}
            eventTables={eventsTableNames}
        >
            <Page
                title={pageTitle}
                withFullHeight
                withNavbar
                backgroundColor="white"
                withFixedContent
                contentClassName="h-full w-full pt-3"
            >
                <CustomAttributeProvider
                    initialState={initialState}
                    isEditMode={isEditMode}
                >
                    <CustomAttributeContainer initialState={initialState} />
                </CustomAttributeProvider>
            </Page>
        </FiltersProvider>
    );
};

export default CustomAttributeBuilder;
