import FieldListItem from '@components/Audience/Filters/FieldListItem';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import Modal from '@components/common/modal/Modal';
import {
    type AddditionalPropertySelectListProps,
    type PropertySelectListType,
} from '@components/common/Select/PropertySelect/type';
import DataTypeMenu from '@components/Journeys/Builder/ControlPanel/Actions/CallAPI/ResponsePayloadMapper/DataTypeMenu';
import { useLocale } from '@hooks/useLocale';
import {
    DimensionType,
    getItemId,
    JoinType,
    type AnyType,
    type UserTraitFieldConfig,
    type UserTraits,
} from '@lightdash/common';
import {
    Box,
    Button,
    Flex,
    Stack,
    Text,
    Textarea,
    TextInput,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    AsteriskSimple,
    CaretDown,
    Textbox,
    UserCircle,
} from '@phosphor-icons/react';
import useRelationContext from '../../providers/Relation/useRelationContext';

import { convertFieldsIntoPropertySelectListType } from '@components/Audience/Filters/FieldListItem/utils';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import PropertySelect from '@components/common/Select/PropertySelect';
import { toFieldsWithSuggestions } from '@components/Profiles/utils';
import {
    useCreateUpdateTraitMutation,
    useUpdateUpdateTraitMutation,
} from '@hooks/useUpdateTrait';
import useApp from '@providers/App/useApp';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonVariant } from '../../mantineTheme';
import { sanitizeName } from './utils';
// import UpdateTraitPropertySelect from './UpdateTraitPropertySelect';

interface UpdateTraitsModalProps {
    opened: boolean;
    onClose: () => void;
    items: PropertySelectListType<AnyType>[] | undefined;
    updateTrait: UserTraits | undefined;
    isEditMode: boolean;
    updateTraitFieldConfig: UserTraitFieldConfig | undefined;
    onTraitCreated?: (trait: UserTraitFieldConfig, name: string) => void;
}

const UpdateTraitsModal: React.FC<UpdateTraitsModalProps> = ({
    opened,
    onClose,
    items,
    updateTrait,
    isEditMode,
    updateTraitFieldConfig,
    onTraitCreated,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        reset,
        setError,
    } = useForm<UserTraitFieldConfig>({
        defaultValues: {
            label: updateTraitFieldConfig?.label || '',
            type: updateTraitFieldConfig?.type || DimensionType.STRING,
            default: updateTraitFieldConfig?.default || '',
            description: updateTraitFieldConfig?.description || '',
        },
    });
    const { mutate: createUpdateTrait, isLoading: isCreateLoading } =
        useCreateUpdateTraitMutation();
    const { mutate: updateUpdateTrait, isLoading: isUpdateLoading } =
        useUpdateUpdateTraitMutation();
    const { t } = useLocale();
    const { activeRelation, getTableRelation, customDimensionsFields } =
        useRelationContext();
    const { user } = useApp();
    const [openedSelect, { open, close }] = useDisclosure();
    const primaryTableRelationLabel = useMemo(() => {
        const primaryTable = activeRelation?.baseTable;
        if (!primaryTable) return '';
        return activeRelation?.tables[primaryTable]?.label;
    }, [activeRelation]);
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });
    const filteredRelation = getTableRelation([
        JoinType.one_one,
        JoinType.many_one,
    ]);

    const fields = toFieldsWithSuggestions(
        filteredRelation,
        fieldsWithSuggestions,
        customDimensionsFields,
    );
    const fieldsToRender = useMemo(() => {
        return (
            items ||
            convertFieldsIntoPropertySelectListType(
                Object.values(fields),
                false,
            )
        );
    }, [items, fields]);

    useEffect(() => {
        if (opened && updateTraitFieldConfig) {
            reset({
                label: updateTraitFieldConfig.label || '',
                type: updateTraitFieldConfig.type || DimensionType.STRING,
                default: updateTraitFieldConfig.default || '',
                description: updateTraitFieldConfig.description || '',
            });
        }
    }, [opened, updateTraitFieldConfig, reset]);

    const onSubmit = useCallback(
        async (data: UserTraitFieldConfig) => {
            const sanitizedName = sanitizeName(data.label);

            // Check if name already exists
            if (updateTrait?.config.fieldConfig?.[sanitizedName]) {
                // Show error in the form
                setError('label', {
                    type: 'manual',
                    message: t('update_traits.error_trait_name_already_exists'),
                });
                return;
            }

            const newTrait = {
                type: data.type,
                label: data.label,
                default: data.default,
                description: data.description,
                createdAt: new Date(),
                createdBy: {
                    userUuid: user?.data?.userUuid || '',
                    firstName: user?.data?.firstName || '',
                    lastName: user?.data?.lastName || '',
                },
            };

            if (!Boolean(updateTrait)) {
                createUpdateTrait(
                    {
                        config: {
                            fieldConfig: {
                                [sanitizedName]: newTrait,
                            },
                        },
                    },
                    {
                        onSuccess: () => {
                            onTraitCreated?.(newTrait, sanitizedName);
                            reset();
                            onClose();
                        },
                    },
                );
            } else {
                updateUpdateTrait(
                    {
                        config: {
                            fieldConfig: {
                                [sanitizedName]: newTrait,
                                ...updateTrait?.config.fieldConfig,
                            },
                        },
                    },
                    {
                        onSuccess: () => {
                            onTraitCreated?.(newTrait, sanitizedName);
                            reset();
                            onClose();
                        },
                    },
                );
            }
        },
        [
            updateTrait,
            createUpdateTrait,
            updateUpdateTrait,
            onTraitCreated,
            onClose,
            setError,
            t,
            reset,
            user,
        ],
    );
    const isLoading = useMemo(
        () => isCreateLoading || isUpdateLoading,
        [isCreateLoading, isUpdateLoading],
    );

    const handleClose = useCallback(() => {
        reset({
            label: '',
            type: undefined,
            default: '',
        });
        onClose();
    }, [reset, onClose]);

    return (
        <Modal
            opened={opened}
            onClose={handleClose}
            title={
                <Box>
                    <Flex gap={4} align="center">
                        <Textbox size={16} color="rgb(var(--color-halt-800))" />
                        <AsteriskSimple
                            size={14}
                            color="rgb(var(--color-purple))"
                        />
                        <Text>
                            {t(
                                'update_traits_modal.create_calculated_custom_trait',
                            )}
                        </Text>
                    </Flex>
                    <Flex gap={4} align="center">
                        <Text className="font-semibold text-gray-700">
                            {t('common.in')}
                        </Text>
                        <UserCircle
                            size={16}
                            color="rgb(var(--color-blu-800))"
                        />
                        <Text className="text-blu-800">
                            {primaryTableRelationLabel}
                        </Text>
                    </Flex>
                </Box>
            }
            footerRightSection={
                isEditMode && (
                    <>
                        <Button variant="subtle" onClick={onClose}>
                            {t('common.cancel')}
                        </Button>
                        <Button
                            type="submit"
                            loading={isLoading}
                            disabled={isLoading}
                            form="update-trait-form"
                        >
                            {isLoading
                                ? t('common.loading')
                                : t(
                                      'custom_metric.dimension_modal_create_button',
                                  )}
                        </Button>
                    </>
                )
            }
            size="lg"
            closeButtonProps={{
                className: 'absolute top-3 right-3',
            }}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                id="update-trait-form"
                className="w-full"
            >
                <Stack className="space-y-4">
                    <TextInput
                        label="Trait name"
                        {...register('label', {
                            required: 'Trait name is required',
                        })}
                        className="w-[23rem]"
                        disabled={isLoading}
                        readOnly={!isEditMode}
                        error={errors.label?.message}
                    />

                    <Textarea
                        label={
                            <Box>
                                <Text className="inline me-2">
                                    {t(
                                        'custom_metric.dimension_modal_description_label',
                                    )}
                                </Text>
                                <Text className="inline text-gray-500">
                                    {t(
                                        'custom_metric.dimension_modal_description_label_optional',
                                    )}
                                </Text>
                            </Box>
                        }
                        placeholder={t(
                            'custom_metric.dimension_modal_description_description',
                        )}
                        disabled={isLoading}
                        readOnly={!isEditMode}
                        {...register('description')}
                    />
                    <Box>
                        <Text className="mb-1.5">
                            {t('update_trait.data_type_title')}
                        </Text>
                        <Box className="overflow-hidden bg-white border border-gray-200 rounded-lg shadow-inner w-fit focus-within:border-blu-800 focus-within:shadow focus-within:shadow-blu-800/25">
                            <DataTypeMenu
                                onSelect={(value) => {
                                    setValue('type', value as DimensionType);
                                }}
                                value={watch('type')}
                                disabled={isLoading || !isEditMode}
                                className="w-[10rem]"
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box className="mb-1.5">
                            <Text className="inline me-2">
                                {t('update_trait.initiate_title')}
                            </Text>
                            <Text className="inline text-gray-500">
                                {t(
                                    'custom_metric.dimension_modal_description_label_optional',
                                )}
                            </Text>
                        </Box>
                        <PropertySelect<
                            FieldWithSuggestions &
                                AddditionalPropertySelectListProps
                        >
                            items={fieldsToRender}
                            showGroup
                            headerRightSection={null}
                            onSubmit={(field: FieldWithSuggestions[]) => {
                                if (!field[0]) return;
                                const value = `$.${getItemId(
                                    field[0],
                                )}`.replace(/^["'](.+)["']$/, '$1');
                                setValue('default', value);
                                close();
                            }}
                            itemTemplate={({ item }) => {
                                return (
                                    <FieldListItem
                                        item={item}
                                        checked={item.isChecked ?? false}
                                        showCheckbox={false}
                                        disabled={item.isDisabled ?? false}
                                    />
                                );
                            }}
                            opened={openedSelect}
                            close={close}
                            open={open}
                            withinPortal
                            targetButton={
                                <Button
                                    variant={ButtonVariant.UNSTYLED}
                                    className="px-2 w-fit !h-9"
                                    style={{
                                        borderRadius: '8px',
                                        background: 'white',
                                        border: '1px solid',
                                        borderColor: 'rgba(0, 0, 0, 0.06)',
                                        boxShadow: `0px -1px 4px 0px rgba(0, 0, 0, 0.06) inset`,
                                        fontSize: '14px',
                                        color: 'rgb(var(--color-gray-600))',
                                        width: 'wrap-content !important',
                                        // cursor: viewMode || isLoading ? 'auto' : 'pointer',
                                        minWidth: '12rem',
                                        minHeight: '2rem',
                                    }}
                                    form="some_random"

                                    // disabled={viewMode || isLoading}
                                >
                                    <Flex
                                        justify="space-between"
                                        align={'center'}
                                        className="w-full"
                                    >
                                        {watch('default') ? (
                                            <FieldListItem
                                                // item={activeField as FieldWithSuggestions}
                                                item={
                                                    fieldsWithSuggestions[
                                                        watch('default').split(
                                                            '.',
                                                        )[1]
                                                    ]
                                                }
                                                // disabled={viewMode || isLoading}
                                                showCheckbox={false}
                                                checked={false}
                                                showHoverIcon={false}
                                                disabled={false}
                                            />
                                        ) : (
                                            <Text className="text-sm font-medium text-gray-500">
                                                {t(
                                                    'custom_metric.dimension_modal_property_description',
                                                )}
                                            </Text>
                                        )}
                                        <CaretDown
                                            color={'rgb(var(--color-gray-600)'}
                                            weight={'regular'}
                                        />
                                    </Flex>
                                </Button>
                            }
                            showSearch={true}
                            searchKeys={['label', 'tableLabel']}
                            searchPlaceholder={t(
                                'audience_filters.search_filter_label',
                            )}
                            allowMultipleSelection={false}
                            disabled={isLoading || !isEditMode}
                        />
                    </Box>
                </Stack>
            </form>
        </Modal>
    );
};

export default UpdateTraitsModal;
