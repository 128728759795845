import { useLocale } from '@hooks/useLocale';
import { useProjectAccess } from '@hooks/useProjectAccess';
import { type ProjectMemberProfileWithInviteInformation } from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import useApp from '@providers/App/useApp';
import { type ColumnDef } from '@tanstack/react-table';
import { capitalizeFirstCharacter } from '@utils/helpers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import WorksapceMenuItem from './WorksapceMenuItem';

export const useWorkspaceMembersColumns = () => {
    const [columns, setColumns] =
        useState<ColumnDef<ProjectMemberProfileWithInviteInformation>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const { user } = useApp();
    const { data: users } = useProjectAccess(projectUuid as string);
    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: t('workspace_members_table.th_name'),
                    sortingFn: (rowA, rowB) => {
                        const labelA =
                            rowA.original.firstName +
                            ' ' +
                            rowA.original.lastName;
                        const labelB =
                            rowB.original.firstName +
                            ' ' +
                            rowB.original.lastName;
                        if (
                            typeof labelA === 'string' &&
                            typeof labelB === 'string'
                        ) {
                            return labelA.localeCompare(labelB);
                        }
                        return 0;
                    },
                    cell: ({ row }) => (
                        <Box className="flex flex-col gap-0.5 justify-center items-start">
                            <Text className="text-sm font-medium text-gray-800">
                                {row.original.firstName} {row.original.lastName}
                                {user?.data?.userUuid ===
                                    row.original.userUuid && (
                                    <span className="font-normal text-gray-500">
                                        {' '}
                                        {t(
                                            'workspace_members_table.tr_name_logged_in_user_identifier',
                                        )}
                                    </span>
                                )}
                            </Text>

                            {row.original.email && (
                                <div>
                                    <Text className="inline text-xs font-normal text-gray-600">
                                        {row.original.email}
                                    </Text>
                                    {!row.original.isActive && (
                                        <Text
                                            variant="outline"
                                            size="xs"
                                            className={`inline rounded border px-1 mx-1' ${
                                                row.original.isInviteExpired
                                                    ? 'border-red-100 text-red-400'
                                                    : 'border-amber-100 text-amber-400'
                                            }`}
                                        >
                                            {row.original.isInviteExpired
                                                ? t(
                                                      'organization_settings.admin_invite_expired_button',
                                                  )
                                                : t(
                                                      'organization_settings.admin_invite_sent_button',
                                                  )}
                                        </Text>
                                    )}
                                </div>
                            )}
                        </Box>
                    ),
                    size: 200,
                },
                {
                    accessorKey: 'role',
                    header: t('workspace_members_table.th_role'),
                    cell: ({ getValue }) => (
                        <Text className="text-xs font-normal text-gray-800">
                            {capitalizeFirstCharacter(getValue<string>())}
                        </Text>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => {
                        return (
                            <WorksapceMenuItem
                                rowData={row}
                                projectUuid={projectUuid}
                            />
                        );
                    },
                    size: 80,
                    enableSorting: false,
                },
            ]);
        }
    }, [projectUuid, t, user?.data?.userUuid, users]);

    return columns;
};
