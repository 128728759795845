import { sortmentApi } from '@api/index';
import {
    type AnyType,
    type ApiError,
    type ApiResponse,
    type CreateTemplateRequest,
    type EntityNameDescription,
    type ExternalTemplate,
    type GetAllProviderTemplateRequest,
    type GetAllProviderTemplateResponse,
    type ImportTemplateFromIntegrationRequest,
    type SyncedTemplateBasicDetails,
    type SyncExternalTemplate,
    type Template,
    type TemplateContentDetails,
    type TemplateContentDetailsUpdateRequest,
    type TemplateListWithPagination,
    type TemplateModelUpdateTemplateRequest,
    type TemplateNameDescriptionRequest,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

const getTemplates = async (
    projectId: string,
    channel: string,
    perPage: number,
    currentPage: number,
) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/templates?channel=${channel}&perPage=${perPage}&currentPage=${currentPage}`,
        method: 'GET',
        body: undefined,
    });

export const useTemplates = (
    channel: string,
    perPage: number,
    currentPage: number,
) => {
    const { projectUuid = '' } = useParams<{
        projectUuid: string;
    }>();

    return useQuery<ApiResponse, ApiError, TemplateListWithPagination>({
        queryKey: [QueryKeys.GET_ALL_TEMPLATE, channel, perPage, currentPage],
        queryFn: () => getTemplates(projectUuid, channel, perPage, currentPage),
        enabled: !!projectUuid || !channel,
        retry: true,
        keepPreviousData: true,
    });
};

const importTemplateFromProvider = async (
    projectId: string,
    providerId: string | undefined,
    integrationId: string | undefined,
    body: GetAllProviderTemplateRequest,
) =>
    sortmentApi<GetAllProviderTemplateResponse>({
        url: `/projects/${projectId}/templates/provider-template?providerId=${providerId}&integrationId=${integrationId}`,
        method: 'POST',
        body: JSON.stringify(body),
    });

export const useImportTemplateFromProvider = (
    providerId: string | undefined,
    integrationId: string | undefined,
) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<
        GetAllProviderTemplateResponse,
        ApiError,
        GetAllProviderTemplateRequest
    >(
        (payload: GetAllProviderTemplateRequest) =>
            importTemplateFromProvider(
                projectUuid,
                providerId,
                integrationId,
                payload,
            ),
        {
            mutationKey: [
                QueryKeys.IMPORT_TEMPLATES_PROVIDER,
                providerId,
                integrationId,
            ],
            onError: (error) => {
                showToastError({
                    title: t('template_manager.import_failed'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const migrateTemplate = async (
    projectId: string,
    body: ImportTemplateFromIntegrationRequest,
) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/templates/migrate-template`,
        method: 'POST',
        body: JSON.stringify(body),
    });

export const useMigrateTemplate = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();

    return useMutation<
        any,
        ApiError,
        ImportTemplateFromIntegrationRequest,
        Template[]
    >(
        (payload: ImportTemplateFromIntegrationRequest) =>
            migrateTemplate(projectUuid, payload),
        {
            mutationKey: [QueryKeys.MIGRATE_TEMPLATES_PROVIDER],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('template_manager.import_successful'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('template_manager.import_failed'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const getTemplate = async (projectId: string, templateUuid: string) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/templates/${templateUuid}?includeContents=true`,
        method: 'GET',
        body: undefined,
    });

export const useGetTemplateById = (templateUuid: string, enabled: boolean) => {
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();

    return useQuery<ApiResponse, ApiError, Template>({
        queryKey: [QueryKeys.GET_TEMPLATE, templateUuid],
        queryFn: () => getTemplate(projectUuid ?? '', templateUuid),
        enabled: !!projectUuid && !!templateUuid && enabled,
    });
};

const createTemplate = async (
    projectId: string,
    payload: CreateTemplateRequest,
) =>
    sortmentApi<Template>({
        url: `/projects/${projectId}/templates`,
        method: 'POST',
        body: JSON.stringify(payload),
    });

export const useCreateTemplate = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();

    const response = useMutation<Template, ApiError, CreateTemplateRequest>(
        (payload: CreateTemplateRequest) =>
            createTemplate(projectUuid, payload),
        {
            mutationKey: [QueryKeys.CREATE_TEMPLATE],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('template_manager.create_template_success'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('template_manager.create_template_error'),
                    subtitle: error.error.message,
                });
            },
        },
    );
    return response;
};

export const updateTemplateContent = async (
    projectId: string,
    templateId: string,
    payload: TemplateContentDetails,
    version: number | undefined,
) =>
    sortmentApi<Template>({
        url: `/projects/${projectId}/templates/${templateId}/version/${version}/content`,
        method: 'PUT',
        body: JSON.stringify(payload),
    });

export const useUpdateTemplateContent = (
    templateId: string,
    version: number | undefined,
) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const query = useQueryClient();
    const { t } = useLocale();

    const response = useMutation<Template, ApiError, TemplateContentDetails>(
        (payload: TemplateContentDetails) =>
            updateTemplateContent(projectUuid, templateId, payload, version),
        {
            mutationKey: [
                QueryKeys.UPDATE_TEMPLATE_CONTENT,
                templateId,
                projectUuid,
            ],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('template_manager.update_template_success'),
                });
                await query.invalidateQueries([QueryKeys.GET_TEMPLATE]);
            },
            onError: (error) => {
                showToastError({
                    title: t('template_manager.update_template_error'),
                    subtitle: error.error.message,
                });
            },
        },
    );
    return response;
};

const syncedTemplates = async (projectId: string, query: string) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/templates/synced?${query}`,
        method: 'GET',
        body: undefined,
    });

export const useSyncedTemplates = (payload: { query: string }) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useQuery<ApiResponse, ApiError, SyncedTemplateBasicDetails[] | null>(
        {
            queryKey: [
                QueryKeys.GET_SYNCED_TEMPLATES,
                projectUuid,
                payload.query,
            ],
            queryFn: () => syncedTemplates(projectUuid, payload.query),
            enabled: !!projectUuid,
        },
    );
};

const getSyncedTemplateByTemplateName = async (
    projectId: string,
    integrationId: string,
    templateName: string,
) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/templates/synced/integration/${integrationId}/template/${templateName}`,
        method: 'GET',
        body: undefined,
    });

export const useGetSyncedTemplateByTemplateName = (
    integrationId: string,
    templateName: string,
    enabled: boolean,
) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useQuery<ApiResponse, ApiError, ExternalTemplate>({
        queryKey: [
            QueryKeys.GET_SYNCED_TEMPLATE_BY_TEMPLATE_NAME,
            projectUuid,
            integrationId,
            templateName,
        ],
        queryFn: () =>
            getSyncedTemplateByTemplateName(
                projectUuid,
                integrationId,
                templateName,
            ),
        enabled: !!projectUuid && !!integrationId && !!templateName && enabled,
    });
};

const syncWhatsappTemplates = (
    projectUuid: string,
    payload: SyncExternalTemplate,
) =>
    sortmentApi<string>({
        method: 'POST',
        url: `/projects/${projectUuid}/templates/synced`,
        body: JSON.stringify(payload),
    });

export const useSyncWhatsappTemplates = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useMutation<string, ApiError, SyncExternalTemplate>({
        mutationKey: [QueryKeys.SYNC_WHATSAPP_TEMPLATES, projectUuid],
        mutationFn: (payload: SyncExternalTemplate) =>
            syncWhatsappTemplates(projectUuid, payload),
    });
};

const initiateBeefree = async (projectId: string) =>
    sortmentApi<AnyType>({
        url: `/projects/${projectId}/templates/utils/initiate-befree`,
        method: 'GET',
        body: undefined,
    });

export const useInitiateBeefree = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useQuery<any, ApiError>({
        queryKey: [QueryKeys.INITIATE_BEEFREE, projectUuid],
        queryFn: () => initiateBeefree(projectUuid),
        enabled: !!projectUuid,
    });
};

const generateTemplateNameDescription = async (
    projectId: string,
    body: TemplateNameDescriptionRequest,
) =>
    sortmentApi<EntityNameDescription>({
        url: `/projects/${projectId}/ai/templates/name-description`,
        method: 'POST',
        body: JSON.stringify(body),
    });

export const useGenerateTemplateNameDescription = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useMutation<
        EntityNameDescription,
        ApiError,
        TemplateNameDescriptionRequest
    >({
        mutationKey: [
            QueryKeys.GENERATE_TEMPLATE_NAME_DESCRIPTION,
            projectUuid,
        ],
        mutationFn: (body) =>
            generateTemplateNameDescription(projectUuid, body),
    });
};

const duplicateTemplateApi = async (projectId: string, templateId: string) =>
    sortmentApi<Template>({
        url: `/projects/${projectId}/templates/${templateId}/clone`,
        method: 'POST',
        body: undefined,
    });

export const useDuplicateTemplate = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useMutation<Template, ApiError, { templateId: string }>({
        mutationFn: ({ templateId }: { templateId: string }) =>
            duplicateTemplateApi(projectUuid, templateId),
        mutationKey: [QueryKeys.DUPLICATE_TEMPLATE],
    });
};

const updateTemplateDetails = async ({
    projectId,
    templateId,
    body,
}: {
    projectId: string;
    templateId: string;
    body: TemplateModelUpdateTemplateRequest;
}) =>
    sortmentApi<Template>({
        url: `/projects/${projectId}/templates/${templateId}`,
        method: 'PATCH',
        body: JSON.stringify(body),
    });

export const useUpdateTemplateDetails = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    return useMutation<
        Template,
        ApiError,
        { templateId: string; body: TemplateModelUpdateTemplateRequest }
    >({
        mutationFn: ({
            templateId,
            body,
        }: {
            templateId: string;
            body: TemplateModelUpdateTemplateRequest;
        }) => {
            if (!projectUuid) {
                throw new Error(t('common.project_id_required'));
            }
            return updateTemplateDetails({
                projectId: projectUuid,
                templateId,
                body,
            });
        },
        mutationKey: [QueryKeys.UPDATE_TEMPLATE_DETAILS],
    });
};

const updateTemplateContentDetails = ({
    projectUuid,
    templateId,
    version,
    body,
}: {
    projectUuid: string;
    templateId: string;
    version: number;
    body: TemplateContentDetailsUpdateRequest;
}) =>
    sortmentApi({
        method: 'PUT',
        url: `/projects/${projectUuid}/templates/${templateId}/version/${version}/content-details`,
        body: JSON.stringify(body),
    });

export const useUpdateTemplateContentDetails = () => {
    const { projectUuid = '', templateId: templateUuid = '' } = useParams<{
        projectUuid: string;
        templateId: string;
    }>();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({
            templateId,
            version,
            body,
        }: {
            templateId: string;
            version: number;
            body: TemplateContentDetailsUpdateRequest;
        }) =>
            updateTemplateContentDetails({
                projectUuid,
                templateId,
                version,
                body,
            }),
        mutationKey: [QueryKeys.UPDATE_TEMPLATE_CONTENT_DETAILS, projectUuid],
        onSuccess: async () => {
            await queryClient.refetchQueries([
                QueryKeys.GET_TEMPLATE,
                templateUuid,
            ]);
        },
    });
};
