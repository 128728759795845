import Checkbox from '@components/common/Checkbox';
import Timestamp from '@components/common/Timestamp';
import UserCount from '@components/common/UserCount';
import { useGetSuppressionConfig } from '@hooks/useDeliveryControlsApi';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceRunStatus,
    CommunicationChannel,
    type Audience,
} from '@lightdash/common';
import { Box, Flex, Group, Loader as LoaderIcon, Text } from '@mantine/core';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { type ColumnDef } from '@tanstack/react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import SuppressionRuleTag from '../../SuppressionRuleTag';
const AUDIENCE_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start w-full`;
const AUDIENCE_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;
const AUDIENCE_TABLE_CELL_DESCRIPTION = `text-xs font-normal text-gray-600 truncate !max-w-[90%]`;

const useAudienceColumns = () => {
    const [columns, setColumns] = useState<ColumnDef<Audience>[]>([]);

    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload } = state;
    const { audienceConfig } = campaignPayload;
    const { setSendAudiences, setSuppressedAudiences } = actions;

    const { data: suppressionConfig } = useGetSuppressionConfig({
        enabled: true,
    });

    const globalSuppressedAudiences = useMemo(() => {
        if (suppressionConfig) {
            return suppressionConfig.config.mappings
                .filter(
                    (item) =>
                        item.channel === campaignPayload.channel ||
                        item.channel === CommunicationChannel.ANY,
                )
                .map((item) => item.audienceId);
        }
        return [];
    }, [campaignPayload.channel, suppressionConfig]);

    const handleSendSelect = useCallback(
        (value: boolean, id: string) => {
            const selectedAudiences = audienceConfig?.config?.in ?? [];
            if (value) {
                setSendAudiences([...selectedAudiences, id]);
            } else {
                setSendAudiences([
                    ...selectedAudiences.filter((item) => item !== id),
                ]);
            }
        },
        [audienceConfig, setSendAudiences],
    );

    const handleSuppressSelect = useCallback(
        (value: boolean, id: string) => {
            const selectedAudiences = audienceConfig?.config.notIn ?? [];
            if (value) {
                setSuppressedAudiences([...selectedAudiences, id]);
            } else {
                setSuppressedAudiences([
                    ...selectedAudiences.filter((item) => item !== id),
                ]);
            }
        },
        [audienceConfig, setSuppressedAudiences],
    );

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: '',
                    id: 'send',
                    header: () => (
                        <Flex justify={'center'} className="w-full">
                            <Text>{t('common.send')}</Text>
                        </Flex>
                    ),
                    cell: ({ row }) => (
                        <Flex justify={'center'} className="w-full">
                            <Checkbox
                                checked={audienceConfig?.config?.in?.includes(
                                    row.original.id,
                                )}
                                onChange={(e) =>
                                    handleSendSelect(
                                        e.target.checked,
                                        row.original.id,
                                    )
                                }
                                disabled={audienceConfig?.config?.notIn?.includes(
                                    row.original.id,
                                )}
                            />
                        </Flex>
                    ),
                    size: 90,
                },
                {
                    accessorKey: '',
                    id: 'suppress',
                    header: () => (
                        <Flex justify={'center'} className="w-full">
                            <Text>{t('common.suppress')}</Text>
                        </Flex>
                    ),
                    cell: ({ row }) => (
                        <Flex justify={'center'} className="w-full">
                            <Checkbox
                                checked={audienceConfig?.config?.notIn?.includes(
                                    row.original.id,
                                )}
                                onChange={(e) =>
                                    handleSuppressSelect(
                                        e.target.checked,
                                        row.original.id,
                                    )
                                }
                                disabled={audienceConfig?.config?.in?.includes(
                                    row.original.id,
                                )}
                            />
                        </Flex>
                    ),
                    size: 120,
                },
                {
                    accessorKey: 'name',
                    header: t('audience_manager.th_audience_name'),
                    cell: ({ row }) => (
                        <Box className={AUDIENCE_TABLE_CELL_CLASSNAME}>
                            <Flex align={'center'}>
                                <Text className={AUDIENCE_TABLE_CELL_NAME}>
                                    {row.original.name}
                                </Text>
                                {globalSuppressedAudiences.includes(
                                    row.original.id,
                                ) && <SuppressionRuleTag />}
                            </Flex>
                            {row.original.description && (
                                <Text
                                    className={AUDIENCE_TABLE_CELL_DESCRIPTION}
                                >
                                    {row.original.description}
                                </Text>
                            )}
                        </Box>
                    ),
                    size: undefined,
                },
                {
                    accessorKey: 'totalCount',
                    header: t('audience_manager.th_audience_count'),
                    cell: ({ getValue }) => (
                        <Box className="">
                            <UserCount
                                count={getValue<string>()}
                                withRightSection={false}
                            />
                        </Box>
                    ),
                    size: 150,
                },
                {
                    accessorKey: 'lastRunAt',
                    header: t('audience_manager.th_last_run'),
                    cell: ({ row, getValue }) => {
                        const { original } = row;
                        const { lastRunStatus } = original;

                        let content;
                        switch (lastRunStatus) {
                            case AudienceRunStatus.RUNNING:
                                content = (
                                    <Group className="gap-1.5">
                                        <LoaderIcon
                                            color="rgb(var(--color-blu-800))"
                                            size={14}
                                        />
                                        <Text className="text-sm font-normal text-blu-800">
                                            {t(
                                                'campaign_manager.tr_status_progress',
                                            )}
                                        </Text>
                                    </Group>
                                );
                                break;
                            case AudienceRunStatus.NEVER:
                                content = (
                                    <Text className="text-sm font-normal text-gray-600">
                                        {t('timestamp.undefined')}
                                    </Text>
                                );
                                break;
                            case AudienceRunStatus.FAILED:
                                content = (
                                    <Box className="flex flex-row items-center justify-start gap-1">
                                        <Text className="text-sm font-normal text-halt-800">
                                            {t(
                                                'audience_manager.tr_last_run_failed',
                                            )}
                                        </Text>
                                        <Timestamp
                                            timestamp={getValue<string>()}
                                        />
                                    </Box>
                                );
                                break;
                            case AudienceRunStatus.SUCCESS:
                                content = (
                                    <Timestamp timestamp={getValue<string>()} />
                                );
                                break;
                            case AudienceRunStatus.SCHEDULED:
                                content = (
                                    <Timestamp timestamp={getValue<string>()} />
                                );
                                break;
                            default:
                                content = (
                                    <Text className="text-sm font-normal text-gray-600">
                                        {t('timestamp.undefined')}
                                    </Text>
                                );
                        }
                        if (!original.lastRunAt) {
                            content = (
                                <Text className="text-sm font-normal text-gray-600">
                                    {t('timestamp.undefined')}
                                </Text>
                            );
                        }
                        return (
                            <Box className="text-sm text-gray-800">
                                {content}
                            </Box>
                        );
                    },
                    size: 200,
                },
                {
                    accessorKey: 'updatedAt',
                    header: t('common.modified_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />

                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`${
                                    row.original.updatedBy?.firstName ||
                                    row.original.updatedBy?.lastName
                                        ? 'by'
                                        : ''
                                } ${row.original.updatedBy?.firstName || ''} ${
                                    row.original.updatedBy?.lastName || ''
                                }`}
                            </Text>
                        </Box>
                    ),
                    size: 200,
                },
            ]);
        }
    }, [
        audienceConfig,
        handleSendSelect,
        handleSuppressSelect,
        projectUuid,
        t,
        globalSuppressedAudiences,
    ]);

    return columns;
};

export default useAudienceColumns;
