import { Flex, Stack, Text } from '@mantine/core';
import React from 'react';

import UserCount from '@components/common/UserCount';
import { useLocale } from '@hooks/useLocale';
import { ProhibitInset, UsersThree } from '@phosphor-icons/react';

const AudienceItem = ({
    name,
    count,
    isGlobalSuppressed,
}: {
    name: string;
    count: number;
    isGlobalSuppressed: boolean;
}) => {
    const { t } = useLocale();

    return (
        <Flex justify={'space-between'} className="py-3 pr-3">
            <Stack spacing={4}>
                <Flex align={'center'} gap={4}>
                    <UsersThree color="rgb(var(--color-pink-800))" />
                    <Text className="font-medium text-gray-800">{name}</Text>
                </Flex>
                {isGlobalSuppressed && (
                    <Flex
                        align={'center'}
                        gap={4}
                        className="w-fit border-base bg-gray-50 rounded-lg px-2"
                    >
                        <ProhibitInset
                            size={12}
                            color="rgb(var(--color-gray-600))"
                        />

                        <Text className="text-xs text-gray-600">
                            {t('campaign_builder.audience.global_rule_applied')}
                        </Text>
                    </Flex>
                )}
            </Stack>
            <Flex align={'center'} gap={4}>
                <UserCount count={count} withRightSection={false} />
            </Flex>
        </Flex>
    );
};

export default AudienceItem;
