import AudienceExclusionsTable from '@components/Campaigns/Analytics/AudienceExclusionsTable';
import CampaignAnalyticsFilter from '@components/Campaigns/Analytics/CampaignAnalyticsFilter';
import CampaignSummaryTable from '@components/Campaigns/Analytics/CampaignSummaryTable';
import ClickedLinksTable from '@components/Campaigns/Analytics/ClickedLinksTable';
import PageBreadcrumbs from '@components/PageBreadcrumbs';
import { useLocale } from '@hooks/useLocale';
import useSearchParams from '@hooks/useSearchParams';
import {
    assertUnreachable,
    CommunicationChannel,
    DashboardTileTypes,
    type Campaign,
    type Dashboard as IDashboard,
} from '@lightdash/common';
import { Box, Button, Group, Modal, Stack, Text } from '@mantine/core';
import DashboardProvider from '@providers/Dashboard/DashboardProvider';
import useDashboardContext from '@providers/Dashboard/useDashboardContext';
import { TrackSection } from '@providers/Tracking/TrackingProvider';
import { captureException, useProfiler } from '@sentry/react';
import { IconAlertCircle } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    type FC,
    type RefObject,
} from 'react';
import { Responsive, WidthProvider, type Layout } from 'react-grid-layout';
import { useBlocker, useNavigate, useParams } from 'react-router';
import { useIntersection } from 'react-use';
import { QueryKeys } from 'types/UseQuery';
import ChartTile from '../components/Campaigns/Analytics/CampaginAnalyticsDashboardChartTile';
import DashboardHeader from '../components/Campaigns/Analytics/CampaignAnalyticsHeader';
import ErrorState from '../components/common/ErrorState';
import MantineIcon from '../components/common/MantineIcon';
import DashboardDeleteModal from '../components/common/modal/DashboardDeleteModal';
import { DashboardExportModal } from '../components/common/modal/DashboardExportModal';
import SuboptimalState from '../components/common/SuboptimalState/SuboptimalState';
import {
    getReactGridLayoutConfig,
    getResponsiveGridLayoutProps,
} from '../components/DashboardTabs/gridUtils';
import LoomTile from '../components/DashboardTiles/DashboardLoomTile';
import MarkdownTile from '../components/DashboardTiles/DashboardMarkdownTile';
import EmptyStateNoTiles from '../components/DashboardTiles/EmptyStateNoTiles';
import TileBase from '../components/DashboardTiles/TileBase/index';
import Page from '../components/Page/Page';
import {
    appendNewTilesToBottom,
    useUpdateDashboard,
} from '../hooks/dashboard/useDashboard';
import useDashboardStorage from '../hooks/dashboard/useDashboardStorage';
import useNotify from '../hooks/toaster/useNotify';
import useApp from '../providers/App/useApp';
import '../styles/react-grid.css';
import { SectionName } from '../types/Events';

const ResponsiveGridLayout = WidthProvider(Responsive);

const GridTile: FC<
    Pick<
        React.ComponentProps<typeof TileBase>,
        'tile' | 'onEdit' | 'onDelete' | 'isEditMode'
    > & {
        isLazyLoadEnabled: boolean;
        index: number;
        useSrtBaseFunnelChart?: boolean;
    }
> = memo((props) => {
    const { tile, isLazyLoadEnabled, index } = props;
    useProfiler(`Dashboard-${tile.type}`);
    const [isTiledViewed, setIsTiledViewed] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>(null);
    const intersection = useIntersection(ref as RefObject<HTMLDivElement>, {
        root: null,
        threshold: 0.3,
    });
    useEffect(() => {
        if (intersection?.isIntersecting) {
            setIsTiledViewed(true);
        }
    }, [intersection]);

    if (isLazyLoadEnabled && !isTiledViewed) {
        setTimeout(() => {
            setIsTiledViewed(true);
            // Prefetch tile sequentially, even if it's not in view
        }, index * 1000);
        return (
            <Box ref={ref as React.Ref<HTMLDivElement>} h="100%">
                <TileBase isLoading {...props} title={''} />
            </Box>
        );
    }

    switch (tile.type) {
        case DashboardTileTypes.SAVED_CHART:
            return <ChartTile {...props} tile={tile} />;
        case DashboardTileTypes.MARKDOWN:
            return <MarkdownTile {...props} tile={tile} />;
        case DashboardTileTypes.LOOM:
            return <LoomTile {...props} tile={tile} />;
        default: {
            return assertUnreachable(
                tile,
                `Dashboard tile type "${props.tile.type}" not recognised`,
            );
        }
    }
});

const Dashboard: FC = () => {
    const queryClient = useQueryClient();
    const isLazyLoadFeatureFlagEnabled = useFeatureFlagEnabled(
        'lazy-load-dashboard-tiles',
    );
    const isLazyLoadEnabled =
        !!isLazyLoadFeatureFlagEnabled && !(window as any).Cypress; // disable lazy load for e2e test
    const navigate = useNavigate();
    const { projectUuid, dashboardUuid, mode } = useParams<{
        projectUuid: string;
        dashboardUuid: string;
        mode?: string;
    }>();

    const { clearIsEditingDashboardChart } = useDashboardStorage();

    const isDashboardLoading = useDashboardContext((c) => c.isDashboardLoading);

    const dashboard = useDashboardContext((c) => c.dashboard);
    const dashboardError = useDashboardContext((c) => c.dashboardError);

    const haveFiltersChanged = useDashboardContext((c) => c.haveFiltersChanged);
    const setHaveFiltersChanged = useDashboardContext(
        (c) => c.setHaveFiltersChanged,
    );
    const dashboardTiles = useDashboardContext((c) => c.dashboardTiles);
    const baseFunnelChartData = useDashboardContext(
        (c) => c.baseFunnelChartData,
    );
    const setDashboardTiles = useDashboardContext((c) => c.setDashboardTiles);
    const haveTilesChanged = useDashboardContext((c) => c.haveTilesChanged);
    const setHaveTilesChanged = useDashboardContext(
        (c) => c.setHaveTilesChanged,
    );

    const setDashboardTemporaryFilters = useDashboardContext(
        (c) => c.setDashboardTemporaryFilters,
    );

    const { isFullscreen, toggleFullscreen } = useApp();
    const { showToastError } = useNotify();

    const { t } = useLocale();

    const isEditMode = useMemo(() => mode === 'edit', [mode]);
    const { isSuccess, reset } = useUpdateDashboard(dashboardUuid);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const campaignUuid = useSearchParams('campaignUuid');

    const campaignData: Campaign | undefined = queryClient.getQueryData([
        QueryKeys.GET_CAMPAIGN_BY_ID,
        campaignUuid,
    ]);

    const layouts = useMemo(
        () => ({
            lg:
                dashboardTiles?.map<Layout>((tile) =>
                    getReactGridLayoutConfig(tile, isEditMode),
                ) ?? [],
        }),
        [dashboardTiles, isEditMode],
    );

    useEffect(() => {
        if (isDashboardLoading) return;
        if (dashboardTiles) return;

        setDashboardTiles(dashboard?.tiles ?? []);
    }, [isDashboardLoading, dashboard, dashboardTiles, setDashboardTiles]);

    useEffect(() => {
        if (isDashboardLoading) return;
        if (dashboardTiles === undefined) return;

        clearIsEditingDashboardChart();

        const unsavedDashboardTilesRaw = sessionStorage.getItem(
            'unsavedDashboardTiles',
        );
        if (!unsavedDashboardTilesRaw) return;

        sessionStorage.removeItem('unsavedDashboardTiles');

        try {
            const unsavedDashboardTiles = JSON.parse(unsavedDashboardTilesRaw);
            // If there are unsaved tiles, add them to the dashboard
            setDashboardTiles(unsavedDashboardTiles);

            setHaveTilesChanged(!!unsavedDashboardTiles);
        } catch {
            showToastError({
                title: 'Error parsing chart',
                subtitle: 'Unable to save chart in dashboard',
            });
            captureException(
                `Error parsing chart in dashboard. Attempted to parse: ${unsavedDashboardTilesRaw} `,
            );
        }
    }, [
        isDashboardLoading,
        dashboardTiles,
        setHaveTilesChanged,
        setDashboardTiles,
        clearIsEditingDashboardChart,
        showToastError,
    ]);

    const [gridWidth, setGridWidth] = useState(0);

    useEffect(() => {
        if (isSuccess) {
            setHaveTilesChanged(false);
            setHaveFiltersChanged(false);
            setDashboardTemporaryFilters({
                dimensions: [],
                metrics: [],
                tableCalculations: [],
            });
            reset();
            void navigate(
                `/projects/${projectUuid}/dashboards/${dashboardUuid}/view`,
            );
        }
    }, [
        dashboardUuid,
        navigate,
        isSuccess,
        projectUuid,
        reset,
        setDashboardTemporaryFilters,
        setHaveFiltersChanged,
        setHaveTilesChanged,
    ]);

    useEffect(() => {
        const onFullscreenChange = () => {
            if (isFullscreen && !document.fullscreenElement) {
                toggleFullscreen(false);
            } else if (!isFullscreen && document.fullscreenElement) {
                toggleFullscreen(true);
            }
        };

        document.addEventListener('fullscreenchange', onFullscreenChange);

        return () =>
            document.removeEventListener(
                'fullscreenchange',
                onFullscreenChange,
            );
    });

    const handleUpdateTiles = useCallback(
        async (layout: Layout[]) => {
            setDashboardTiles((currentDashboardTiles) =>
                currentDashboardTiles?.map((tile) => {
                    const layoutTile = layout.find(({ i }) => i === tile.uuid);
                    if (
                        layoutTile &&
                        (tile.x !== layoutTile.x ||
                            tile.y !== layoutTile.y ||
                            tile.h !== layoutTile.h ||
                            tile.w !== layoutTile.w)
                    ) {
                        return {
                            ...tile,
                            x: layoutTile.x,
                            y: layoutTile.y,
                            h: layoutTile.h,
                            w: layoutTile.w,
                        };
                    }
                    return tile;
                }),
            );

            setHaveTilesChanged(true);
        },
        [setDashboardTiles, setHaveTilesChanged],
    );

    const handleAddTiles = useCallback(
        async (tiles: IDashboard['tiles'][number][]) => {
            setDashboardTiles((currentDashboardTiles) =>
                appendNewTilesToBottom(currentDashboardTiles, tiles),
            );

            setHaveTilesChanged(true);
        },
        [setDashboardTiles, setHaveTilesChanged],
    );

    const handleDeleteTile = useCallback(
        async (tile: IDashboard['tiles'][number]) => {
            setDashboardTiles((currentDashboardTiles) =>
                currentDashboardTiles?.filter(
                    (filteredTile) => filteredTile.uuid !== tile.uuid,
                ),
            );

            setHaveTilesChanged(true);
        },
        [setDashboardTiles, setHaveTilesChanged],
    );

    const handleEditTiles = useCallback(
        (updatedTile: IDashboard['tiles'][number]) => {
            setDashboardTiles((currentDashboardTiles) =>
                currentDashboardTiles?.map((tile) =>
                    tile.uuid === updatedTile.uuid ? updatedTile : tile,
                ),
            );
            setHaveTilesChanged(true);
        },
        [setDashboardTiles, setHaveTilesChanged],
    );

    const [isExportDashboardModalOpen, setIsExportDashboardModalOpen] =
        useState(false);

    const blocker = useBlocker(({ currentLocation }) => {
        if (isEditMode && (haveTilesChanged || haveFiltersChanged)) {
            return (
                currentLocation.pathname.includes(
                    `/projects/${projectUuid}/dashboards/${dashboardUuid}`,
                ) && !sessionStorage.getItem('unsavedDashboardTiles')
            );
        }
        return false;
    });

    useEffect(() => {
        const checkReload = (event: BeforeUnloadEvent) => {
            if (isEditMode && (haveTilesChanged || haveFiltersChanged)) {
                const message =
                    'You have unsaved changes to your dashboard! Are you sure you want to leave without saving?';
                event.returnValue = message;
                return message;
            }
        };
        window.addEventListener('beforeunload', checkReload);
        return () => window.removeEventListener('beforeunload', checkReload);
    }, [haveTilesChanged, haveFiltersChanged, isEditMode]);

    if (dashboardError) {
        return <ErrorState error={dashboardError.error} />;
    }
    if (dashboard === undefined) {
        return (
            <Box mt="md">
                <SuboptimalState title="Loading..." loading />
            </Box>
        );
    }
    const dashboardChartTiles = dashboardTiles?.filter(
        (tile) => tile.type === DashboardTileTypes.SAVED_CHART,
    );

    // TODO - FIX ME check why the render order is not as per the BE response
    const sortedTiles = dashboardTiles?.sort((a, b) => {
        let orderA = 0,
            orderB = 0;
        if (
            'chartName' in a.properties &&
            typeof a.properties.chartName === 'string'
        ) {
            orderA = +a.properties.chartName.split('.')[0];
        }
        if (
            'chartName' in b.properties &&
            typeof b.properties.chartName === 'string'
        ) {
            orderB = +b.properties.chartName.split('.')[0];
        }
        return orderA > orderB ? 1 : -1;
    });

    const hasDashboardTiles = dashboardTiles && dashboardTiles.length > 0;

    return (
        <>
            <Modal
                opened={blocker.state === 'blocked'}
                onClose={() => {
                    if (blocker.state === 'blocked') {
                        blocker.reset();
                    }
                }}
                title={null}
                withCloseButton={false}
                closeOnClickOutside={false}
            >
                <Stack>
                    <Group noWrap spacing="xs">
                        <MantineIcon
                            icon={IconAlertCircle}
                            color="red"
                            size={50}
                        />
                        <Text fw={500}>
                            You have unsaved changes to your dashboard! Are you
                            sure you want to leave without saving?
                        </Text>
                    </Group>

                    <Group position="right">
                        <Button
                            onClick={() => {
                                if (blocker.state === 'blocked') {
                                    blocker.reset();
                                }
                            }}
                        >
                            Stay
                        </Button>
                        <Button
                            color="red"
                            onClick={() => {
                                if (blocker.state === 'blocked') {
                                    blocker.proceed();
                                }
                            }}
                        >
                            Leave
                        </Button>
                    </Group>
                </Stack>
            </Modal>

            <Page
                withFullHeight
                withPaddedContent
                withNavbar
                backgroundColor="white"
                withFixedContent
                title={dashboard.name}
            >
                <Box component="div" className="mb-3.5">
                    <PageBreadcrumbs
                        items={[
                            {
                                title: t(
                                    'campaign_analytics.breadcrumb_campaign',
                                ),
                                to: `/projects/${projectUuid}/campaigns`,
                            },
                            {
                                title: t(
                                    'campaign_analytics.breadcrumb_view_report',
                                ),
                                active: true,
                            },
                        ]}
                    />
                </Box>

                {campaignUuid && (
                    <Box className="mb-4">
                        <DashboardHeader campaignUuid={campaignUuid} />
                    </Box>
                )}

                <Group className="w-full">
                    {dashboardChartTiles && dashboardChartTiles.length > 0 && (
                        <CampaignAnalyticsFilter />
                    )}
                </Group>

                <br />
                {/* <Group position="apart" align="flex-start" noWrap>
                    {dashboardChartTiles && dashboardChartTiles.length > 0 && (
                        <DashboardFilter isEditMode={isEditMode} />
                    )}
                    {hasDashboardTiles && <DateZoom isEditMode={isEditMode} />}
                </Group> */}

                {baseFunnelChartData && (
                    <CampaignSummaryTable
                        data={baseFunnelChartData}
                        channel={
                            campaignData?.channel ?? CommunicationChannel.EMAIL
                        }
                    />
                )}

                <ClickedLinksTable />

                {campaignData &&
                campaignData?.communicationDetails?.runDetails?.[0]
                    ?.exclusionsSnapshotName &&
                campaignData?.communicationDetails?.runDetails?.[0]
                    .ignoredEntityCount ? (
                    <AudienceExclusionsTable campaignData={campaignData} />
                ) : null}

                <ResponsiveGridLayout
                    {...getResponsiveGridLayoutProps()}
                    className="react-grid-layout-dashboard"
                    onDragStop={handleUpdateTiles}
                    onResizeStop={handleUpdateTiles}
                    onWidthChange={(cw) => setGridWidth(cw)}
                    layouts={layouts}
                >
                    {sortedTiles &&
                        [...sortedTiles].map((tile, idx) => {
                            return (
                                <div key={tile.uuid}>
                                    <TrackSection
                                        name={SectionName.DASHBOARD_TILE}
                                    >
                                        <GridTile
                                            isLazyLoadEnabled={
                                                isLazyLoadEnabled ?? true
                                            }
                                            index={idx}
                                            isEditMode={isEditMode}
                                            tile={tile}
                                            onDelete={handleDeleteTile}
                                            onEdit={handleEditTiles}
                                            useSrtBaseFunnelChart={
                                                campaignData &&
                                                !!campaignData
                                                    .communicationDetails
                                                    ?.runDetails?.[0]
                                                    ?.materializedCampaignCount
                                            }
                                        />
                                    </TrackSection>
                                </div>
                            );
                        })}
                </ResponsiveGridLayout>

                {!hasDashboardTiles && (
                    <EmptyStateNoTiles
                        onAddTiles={handleAddTiles}
                        emptyContainerType={'dashboard'}
                        isEditMode={isEditMode}
                        setAddingTab={() => {}}
                    />
                )}

                {isDeleteModalOpen && (
                    <DashboardDeleteModal
                        opened
                        uuid={dashboard.uuid}
                        onClose={() => setIsDeleteModalOpen(false)}
                        onConfirm={() => {
                            void navigate(
                                `/projects/${projectUuid}/dashboards`,
                            );
                        }}
                    />
                )}
                {isExportDashboardModalOpen && (
                    <DashboardExportModal
                        opened={isExportDashboardModalOpen}
                        onClose={() => setIsExportDashboardModalOpen(false)}
                        dashboard={dashboard}
                        gridWidth={gridWidth}
                    />
                )}
            </Page>
        </>
    );
};

const DashboardPage: FC = () => {
    useProfiler('Dashboard');
    return (
        <DashboardProvider>
            <Dashboard />
        </DashboardProvider>
    );
};

export default DashboardPage;
