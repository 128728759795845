import CampaignBuilderFooter from '@components/Campaigns/Builder/CampaignBuilderFooter';
import CampaignBuilderHeader from '@components/Campaigns/Builder/CampaignBuilderHeader';
import { CampaignBuilderSteps } from '@components/Campaigns/Builder/utils';
import Page from '@components/Page/Page';
import { useLocale } from '@hooks/useLocale';
import { CampaignStatus } from '@lightdash/common';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import React from 'react';
import { CampaignBuilderStep } from './types';

interface CampaignBuilderFormProps {
    isNewMode: boolean;
    isEditMode: boolean;
}

const CampaignBuilderForm: React.FC<CampaignBuilderFormProps> = ({
    isNewMode,
    isEditMode,
}) => {
    const { state } = useCampaignContext((context) => context);
    const { currentStep, showFooterButtons, campaignPayload, status } = state;
    const { t } = useLocale();

    const CurrentStepComponent = CampaignBuilderSteps.find(
        (step) => step.key === currentStep,
    )?.component;

    if (!CurrentStepComponent) {
        return null;
    }

    const showFooter = showFooterButtons.back || showFooterButtons.next;

    return (
        <Page
            withFullHeight={false}
            withPaddedContent={currentStep !== CampaignBuilderStep.CONTENT}
            backgroundColor="white"
            withFixedContent
            withNavbar={false}
            header={
                <CampaignBuilderHeader
                    isNew={isNewMode}
                    isViewOnly={!isNewMode && !isEditMode}
                    status={status ?? CampaignStatus.DRAFT}
                    channel={campaignPayload?.channel}
                />
            }
            footer={showFooter && <CampaignBuilderFooter />}
            withFixedFooter={showFooter}
            title={t('campaign.create.title')}
            contentClassName="h-[calc(100%-50px)] !p-0 !overflow-hidden"
        >
            <CurrentStepComponent />
        </Page>
    );
};

export default CampaignBuilderForm;
