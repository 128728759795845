import { useAudienceCountByPayload } from '@hooks/useAudience';
import { useLocale } from '@hooks/useLocale';
import { type ApiSqlQueryCountResults } from '@lightdash/common';
import {
    Box,
    Button,
    Divider,
    Flex,
    Group,
    Overlay,
    Stack,
    Text,
} from '@mantine/core';
import { ArrowsClockwise } from '@phosphor-icons/react';
import useAudienceContext from '@providers/Audience/useAudienceContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import Breakdown from './Breakdown';
import Overlap from './Overlap';
import Reachability from './Reachability';
import { formatNestedMetricQuery } from './utils';

export type InsightsCallbackType = {
    overlap?: (() => void) | undefined;
    breakdown?: (() => void) | undefined;
    reachability?: (() => void) | undefined;
};

const AudienceInsights = () => {
    const callbackRefs = useRef<InsightsCallbackType>({
        overlap: undefined,
        breakdown: undefined,
        reachability: undefined,
    });
    const { t } = useLocale();
    const [isDataOutdated, setIsDataOutdated] = useState<boolean>(true);
    const { activeRelationUuid } = useRelationContext();
    const { state, actions } = useAudienceContext((context) => context);
    const { audiencePayload, audienceCount, initialAudienceCount } = state;
    const { setAudienceCount } = actions;
    const { mutateAsync: getAudienceCountByPayload } =
        useAudienceCountByPayload();

    useEffect(() => {
        if (audiencePayload.nestedMetricQuery) {
            setIsDataOutdated(true);
        }
    }, [audiencePayload.nestedMetricQuery]);

    const setRefreshCallback = (payload: InsightsCallbackType) => {
        callbackRefs.current = {
            ...callbackRefs.current,
            ...payload,
        };
    };

    const getInitialAudienceCount = useCallback(() => {
        if (!activeRelationUuid) return;
        getAudienceCountByPayload({
            relationUuid: activeRelationUuid,
            payload: {
                metricQuery: formatNestedMetricQuery(
                    audiencePayload.nestedMetricQuery,
                ),
            },
        })
            .then((response: ApiSqlQueryCountResults) => {
                setAudienceCount(response.count);
            })
            .catch(() => {});
    }, [
        activeRelationUuid,
        audiencePayload.nestedMetricQuery,
        getAudienceCountByPayload,
        setAudienceCount,
    ]);

    const handleRefresh = () => {
        getInitialAudienceCount();
        if (callbackRefs.current.breakdown) {
            callbackRefs.current.breakdown();
        }
        if (callbackRefs.current.overlap) {
            callbackRefs.current.overlap();
        }
        if (callbackRefs.current.reachability) {
            callbackRefs.current.reachability();
        }
        setIsDataOutdated(false);
    };

    return (
        <Stack
            spacing={0}
            className={`h-full w-full border-base bg-white rounded-lg ${
                isDataOutdated ? 'overflow-hidden' : 'overflow-auto'
            } relative`}
        >
            <Group position="apart" className="p-3 bg-white border-b">
                <Text className="text-[16px] text-gray-800 font-medium">
                    {t('audience_builder.audience_insights')}
                </Text>
                <Group spacing={4} align="center">
                    {isDataOutdated ? (
                        <Text className="text-sm text-gray-600">
                            Filters updated
                        </Text>
                    ) : null}
                    <Flex
                        className="w-6 h-6 cursor-pointer border-base"
                        justify={'center'}
                        align={'center'}
                    >
                        <ArrowsClockwise
                            size={13}
                            weight="regular"
                            color="rgb(var(--color-gray-600))"
                            onClick={handleRefresh}
                        />
                    </Flex>
                </Group>
            </Group>
            <Box>
                <Box>
                    {isDataOutdated ? (
                        <Overlay className="flex justify-center items-center mt-[48px] bg-[rgba(255,255,255,0.8)] h-full z-10">
                            <Button
                                onClick={handleRefresh}
                                leftIcon={
                                    <ArrowsClockwise
                                        size={12}
                                        weight="regular"
                                    />
                                }
                                variant={ButtonVariant.OUTLINED}
                            >
                                Refresh
                            </Button>
                        </Overlay>
                    ) : (
                        <>
                            <Reachability
                                audienceCount={
                                    audienceCount || initialAudienceCount
                                }
                                setRefreshCallback={setRefreshCallback}
                            />
                            <Divider
                                size="md"
                                className="mx-3 mt-1 border-t-shade-2"
                            />
                            <Breakdown
                                disableEdits={false}
                                setRefreshCallback={setRefreshCallback}
                            />
                            <Divider
                                size="md"
                                className="mx-3 mt-1 border-t-shade-2"
                            />
                            <Overlap
                                audienceCount={
                                    audienceCount || initialAudienceCount
                                }
                                disableEdits={false}
                                setRefreshCallback={setRefreshCallback}
                            />
                        </>
                    )}
                </Box>
            </Box>
        </Stack>
    );
};

export default AudienceInsights;
