import TextInputVariableWidth from '@components/common/Inputs/TextInputVariableWidth';
import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, Flex, Grid, Group, Stack, Text } from '@mantine/core';
import { CaretRight, Info } from '@phosphor-icons/react';
import { useState } from 'react';
import {
    useGetGlobalHoldoutConfig,
    useSetGlobalHoldoutConfig,
} from '../../../../hooks/useDeliveryControlsApi';
import { ButtonVariant } from '../../../../mantineTheme';
import Card from '../Card';

const GlobalHoldoutGroup = () => {
    const { t } = useLocale();
    const [inputMode, setInputMode] = useState<boolean>(false);
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
    const { data, refetch } = useGetGlobalHoldoutConfig();
    const [holdoutValue, setHoldoutValue] = useState<number>(
        data?.holdoutPercentage ?? 0,
    );

    const {
        mutateAsync: setGlobalHoldoutValue,
        isLoading: isUpdatingGlobalHoldout,
    } = useSetGlobalHoldoutConfig();

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHoldoutValue(+e.target.value);
    };

    const resetState = () => {
        setHoldoutValue(0);
    };

    const handleCancel = () => {
        setInputMode(false);
        resetState();
    };

    const handleEdit = () => {
        setInputMode(true);
    };

    const handleSave = () => {
        setConfirmationModal(true);
    };

    const handleConfirm = async () => {
        try {
            await setGlobalHoldoutValue({
                holdoutPercentage: holdoutValue,
            });
            setInputMode(false);
            setConfirmationModal(false);
            await refetch();
        } catch (error) {}
    };

    return (
        <>
            <Card
                inputMode={inputMode}
                handleCancel={handleCancel}
                handleEdit={handleEdit}
                handleSave={handleSave}
                isUpdating={false}
                isEditAllowed={!!data?.holdoutPercentage}
                headerLabel={t(
                    'workspace_settings.delivery_controls.global_holdout_group.title',
                )}
                headerDescription={t(
                    'workspace_settings.delivery_controls.global_holdout_group.subtitle',
                )}
            >
                {inputMode || data?.holdoutPercentage ? (
                    <Box>
                        <Group align="center" className="mb-3">
                            <Text className="text-gray-600">Assign</Text>
                            <TextInputVariableWidth
                                defaultValue={data?.holdoutPercentage}
                                onChange={handleInput}
                                type="number"
                                minWidth={60}
                                rightSection={
                                    <Text className="text-gray-600">%</Text>
                                }
                                readOnly={!inputMode}
                            />
                            <Text className="text-gray-600">
                                of all users to the global holdout group
                            </Text>
                        </Group>
                        <Grid>
                            <Grid.Col span={3}>
                                <Stack spacing={4}>
                                    <Text className="text-base text-gray-800 font-semibold">
                                        {data?.totalUsersCount || 0}
                                    </Text>
                                    <Text className="text-xs text-gray-600 font-medium">
                                        All users
                                    </Text>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col span={3}>
                                <Stack spacing={4}>
                                    <Text className="text-base text-gray-800 font-semibold">
                                        {data?.holdoutCount || 0}
                                    </Text>
                                    <Flex gap={4}>
                                        <Text className="text-xs text-gray-600 font-medium">
                                            {t(
                                                'workspace_settings.delivery_controls.global_holdout_group.title',
                                            )}
                                        </Text>
                                        <Info color="rgb(var(--color-gray-600))" />
                                    </Flex>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col span={3}>
                                <Stack spacing={4}>
                                    <Text className="text-base text-gray-800 font-semibold">
                                        {data?.totalUsersCount || 0}
                                    </Text>
                                    <Flex gap={4}>
                                        <Text className="text-xs text-gray-600 font-medium">
                                            {t(
                                                'workspace_settings.delivery_controls.global_holdout_group.treatment_group',
                                            )}
                                        </Text>
                                        <Info color="rgb(var(--color-gray-600))" />
                                    </Flex>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col span={3}>
                                <Stack spacing={4}>
                                    <Text className="text-base text-gray-800 font-semibold">
                                        {data?.holdoutCount || 0}
                                    </Text>
                                    <Flex gap={4}>
                                        <Text className="text-xs text-gray-600 font-medium">
                                            {t(
                                                'workspace_settings.delivery_controls.global_holdout_group.treatment_group_sample',
                                            )}
                                        </Text>
                                        <Info color="rgb(var(--color-gray-600))" />
                                    </Flex>
                                </Stack>
                            </Grid.Col>
                        </Grid>
                    </Box>
                ) : (
                    <Button
                        onClick={() => {
                            setInputMode(true);
                            setHoldoutValue(5);
                        }}
                        variant={ButtonVariant.OUTLINED_ACCENTED}
                    >
                        {t('common.set_up')}
                    </Button>
                )}
            </Card>
            <Modal
                size={'lg'}
                opened={confirmationModal}
                title={t(
                    'workspace_settings.delivery_controls.global_holdout_group.modal_title',
                )}
                onClose={() => setConfirmationModal(false)}
            >
                <Box className="mb-4">
                    <Text className="font-medium text-gray-800">
                        {t(
                            'workspace_settings.delivery_controls.global_holdout_group.modal_description',
                        )}
                    </Text>
                </Box>
                <ModalFooter
                    primaryText={t('common.confirm')}
                    secondaryText={t('common.cancel')}
                    primaryButtonClick={handleConfirm}
                    secondaryButtonClick={() => setConfirmationModal(false)}
                    showPrimaryButton
                    showSecondaryButton
                    primaryLeftIcon={undefined}
                    primaryRightIcon={<CaretRight color="white" />}
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={undefined}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    isLoading={isUpdatingGlobalHoldout}
                    primaryButtonDisabled={false}
                    primaryButtonCustomClass={''}
                    justify={'right'}
                />
            </Modal>
        </>
    );
};

export default GlobalHoldoutGroup;
