import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { useLocale } from '@hooks/useLocale';
import {
    FilterGroupOperator,
    type AdditionalMetric,
    type FilterableField,
    type FilterRule,
} from '@lightdash/common';
import { Box, Button, Flex, Text } from '@mantine/core';
import { ButtonVariant } from '../../../../../mantineTheme';
import { type FieldValueProperty } from '../../FiltersProvider/types';
import FilterRuleForm from '../FilterRuleForm';
interface TimeWindowFilterRuleProps {
    showWherePrefix: boolean;
    timeWindowFilter: FilterRule;
    fields: FilterableField[];
    handleTimeWindowFilterChange: (filterRule: FilterRule) => void;
    handleTimeWindowFilterDelete: () => void;
    isEditMode: boolean;
    groupIndex: number;
    additionalMetrics: AdditionalMetric[] | undefined;
    dynamicFieldValues:
        | PropertySelectListType<FieldValueProperty>[]
        | undefined;
}

export const TimeWindowFilterRule: React.FC<TimeWindowFilterRuleProps> = ({
    showWherePrefix,
    timeWindowFilter,
    handleTimeWindowFilterChange,
    handleTimeWindowFilterDelete,
    isEditMode,
    fields,
    groupIndex,
    additionalMetrics,
    dynamicFieldValues,
}) => {
    const { t } = useLocale();
    return (
        <Flex gap={6} align="center">
            {showWherePrefix ? (
                <Text className="text-sm text-gray-600">
                    {t('filter_group_form.group_prefix_where')}
                </Text>
            ) : (
                <Button
                    variant={ButtonVariant.SUBTLE}
                    disabled={true}
                    className="p-2 text-sm font-normal text-gray-600 border-dashed hover:font-medium border-base border-gray-250"
                >
                    {FilterGroupOperator.and}
                </Button>
            )}
            <Box>
                <FilterRuleForm
                    filterRule={timeWindowFilter}
                    fields={fields}
                    isEditMode={isEditMode}
                    onChange={handleTimeWindowFilterChange}
                    onDelete={handleTimeWindowFilterDelete}
                    filters={{}}
                    showFieldSource={false}
                    setFilters={() => {}}
                    groupIndex={groupIndex}
                    additionalMetrics={additionalMetrics}
                    disableFieldSelector={true}
                    dynamicFieldValues={dynamicFieldValues}
                />
            </Box>
        </Flex>
    );
};
