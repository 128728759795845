/**
 * This function takes a raw count value as input and returns it in a formatted manner.
 * For example -
 *
 * Input - 10000
 * Output - 10k
 *
 * Input - 1000000
 * Output - 1M
 *
 * @param {number} - original count
 * @returns {string} - Returns a formatted count string
 */
export const getFormattedCount = (count: number) => {
    if (count > 999999) {
        const millionValueCeil = Math.ceil(count / 100000) / 10;
        return `~${
            millionValueCeil % 1 === 0
                ? millionValueCeil.toFixed(0)
                : millionValueCeil.toFixed(1)
        }M`;
    }
    if (count > 999) {
        const kValueCeil = Math.ceil(count / 100) / 10;
        return `~${
            kValueCeil % 1 === 0 ? kValueCeil.toFixed(0) : kValueCeil.toFixed(1)
        }k`;
    }
    return count.toLocaleString();
};
