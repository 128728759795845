import { subject } from '@casl/ability';
import AuthorizationError from '@components/AuthorizationError';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import Page from '@components/Page/Page';
import ProjectManager from '@components/ProjectConnection/ProjectManager';
import { useLocale } from '@hooks/useLocale';
import useApp from '@providers/App/useApp';
import ProjectProvider from '@providers/Project/ProjectProvider';
import React from 'react';
import { useParams } from 'react-router';

const CreateProject: React.FC<{}> = () => {
    const { t } = useLocale();
    const { user } = useApp();
    const ability = useAbilityContext();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const cannotCreateProject = ability.cannot(
        'create',
        subject('Project', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    if (cannotCreateProject) {
        return <AuthorizationError showFooter={false} />;
    }
    return (
        <ProjectProvider
            projectData={undefined}
            communicationChannels={undefined}
        >
            <Page
                title={t('createProject.page_title')}
                withFullHeight
                withPaddedContent
                withNavbar
                withFixedContent
            >
                <h1 className="text-lg font-medium">
                    {t('createProject.main_title')}
                </h1>
                <main className="mt-8">
                    <ProjectManager />
                </main>
            </Page>
        </ProjectProvider>
    );
};

export default CreateProject;
