import {
    ContentMappingSection,
    type ApiSqlQueryResults,
    type ContentMappings,
} from '@lightdash/common';
import { useMemo } from 'react';

const usePreviewDimensions = ({
    contentMappings,
}: {
    contentMappings: ContentMappings | undefined;
}) => {
    const previewDimensions = useMemo(() => {
        const res = new Set<string>();

        if (contentMappings) {
            if (contentMappings?.[ContentMappingSection.SUBJECT]) {
                Object.values(
                    contentMappings[ContentMappingSection.SUBJECT],
                ).forEach((mapping) => {
                    res.add(mapping.value);
                });
            }
            if (contentMappings?.[ContentMappingSection.BODY]) {
                Object.values(
                    contentMappings[ContentMappingSection.BODY],
                ).forEach((mapping) => {
                    res.add(mapping.value);
                });
            }
        }
        return [...res];
    }, [contentMappings]);

    const variableMappingsMap = useMemo(() => {
        let res = {
            [ContentMappingSection.SUBJECT]: {},
            [ContentMappingSection.BODY]: {},
        };

        if (contentMappings) {
            if (contentMappings?.[ContentMappingSection.SUBJECT]) {
                Object.entries(
                    contentMappings[ContentMappingSection.SUBJECT],
                ).forEach(([key, mapping]) => {
                    res = {
                        ...res,
                        [ContentMappingSection.SUBJECT]: {
                            ...res[ContentMappingSection.SUBJECT],
                            [key]: mapping.value,
                        },
                    };
                });
            }
            if (contentMappings?.[ContentMappingSection.BODY]) {
                Object.entries(
                    contentMappings[ContentMappingSection.BODY],
                ).forEach(([key, mapping]) => {
                    res = {
                        ...res,
                        [ContentMappingSection.BODY]: {
                            ...res[ContentMappingSection.BODY],
                            [key]: mapping.value,
                        },
                    };
                });
            }
        }

        return res;
    }, [contentMappings]);

    const getPreviewUserFormattedData = (payload: ApiSqlQueryResults) => {
        if (payload.rows.length && payload.rows[0]) {
            let res = {
                [ContentMappingSection.SUBJECT]: {},
                [ContentMappingSection.BODY]: {},
            };

            if (variableMappingsMap[ContentMappingSection.SUBJECT]) {
                Object.entries(
                    variableMappingsMap[ContentMappingSection.SUBJECT],
                ).map(([key, value]) => {
                    res = {
                        ...res,
                        [ContentMappingSection.SUBJECT]: {
                            ...res[ContentMappingSection.SUBJECT],
                            [key]: payload.rows[0][value as string],
                        },
                    };
                });
            }
            if (variableMappingsMap[ContentMappingSection.BODY]) {
                Object.entries(
                    variableMappingsMap[ContentMappingSection.BODY],
                ).map(([key, value]) => {
                    res = {
                        ...res,
                        [ContentMappingSection.BODY]: {
                            ...res[ContentMappingSection.BODY],
                            [key]: payload.rows[0][value as string],
                        },
                    };
                });
            }
            return res;
        }
        return {};
    };

    return {
        previewDimensions,
        variableMappingsMap,
        getPreviewUserFormattedData,
    };
};

export default usePreviewDimensions;
