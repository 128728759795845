import { PropertySelectGroupEnum } from '@components/Audience/Filters/FieldListItem/utils';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, Divider, Flex, Group, Loader, Menu } from '@mantine/core';
import { MagnifyingGlass } from '@phosphor-icons/react';
import Fuse from 'fuse.js';
import _ from 'lodash';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    type ReactNode,
} from 'react';
import { useDebounce } from 'react-use';
import { VariableSizeList } from 'react-window';
import { ButtonVariant } from '../../../../mantineTheme';
import {
    type AddditionalPropertySelectListProps,
    type BasePropertySelectPropsWithMultipleSelection,
    type PropertySelectListType,
    type PropertySelectProps,
} from './type';

/**
 * Enum for special string values used in the component.
 */
enum ReservedStrings {
    HEADER = 'header',
    ITEM = 'item',
    DEFAULT = 'default',
}

const HEADER_HEIGHT = 36;
const ITEM_HEIGHT = 42;

/**
 * PropertySelect component for selecting properties with optional grouping and search functionality.
 *
 * @template T - Type of the property items.
 * @template K - Type of the group key.
 * @param {PropertySelectProps<T, K>} props - The properties for the component.
 * @returns {JSX.Element} The rendered PropertySelect component.
 */
const PropertySelect = <
    T extends AddditionalPropertySelectListProps,
    K extends string = string,
>({
    items,
    showGroup,
    headerRightSection,
    onSubmit,
    itemTemplate,
    targetButton,
    close,
    opened,
    open,
    withinPortal = false,
    showAllItemsGroup = true,
    width = 450,
    height = 200,
    disabled = false,
    groupPanelWidth,
    ...props
}: PropertySelectProps<T, K>) => {
    const { isLoading } = props as BasePropertySelectPropsWithMultipleSelection<
        T,
        K
    >;
    const { t } = useLocale();
    const [search, setSearch] = useState<string>('');
    const [searchDebounce, setSearchDebounce] = useState<string>('');
    const [selectedGroup, setSelectedGroup] = useState<K | undefined>(
        undefined,
    );

    useEffect(() => {
        setSelectedGroup(
            showAllItemsGroup ? undefined : items?.[0]?.groupKey ?? undefined,
        );
    }, [items, showAllItemsGroup]);

    const [propertyItems, setPropertyItems] =
        useState<PropertySelectListType<T, K>[]>(items);
    useDebounce(() => setSearchDebounce(search), 300, [search]);
    useEffect(() => {
        if (!opened) {
            setSearch('');
            setSearchDebounce('');
        }
    }, [opened]);
    useEffect(() => {
        if (
            !props.allowMultipleSelection ||
            (props.allowMultipleSelection && !isLoading)
        ) {
            setPropertyItems(items);
        }
    }, [items, props.allowMultipleSelection, isLoading]);
    const eachGroupSelectedItemsCount = useMemo(() => {
        return propertyItems?.reduce((counts, group) => {
            counts[group.groupKey] = group.items.reduce(
                (count, item) => count + (item.isChecked ? 1 : 0),
                0,
            );
            return counts;
        }, {} as Record<string, number>);
    }, [propertyItems]);

    const filteredItems = useMemo(() => {
        let data = propertyItems
            ?.filter(
                (group) => !selectedGroup || group.groupKey === selectedGroup,
            )
            .flatMap((group) => group.items);
        if (props.showSearch && searchDebounce) {
            const fuse = new Fuse(data, {
                keys: props.searchKeys,
                threshold: 0.3,
            });
            data = fuse.search(searchDebounce).map((result) => result.item);
            // If no group is selected, filter out the suggestions group as suggestions are duplicates of the other groups
            if (!selectedGroup) {
                data = data.filter(
                    (item) =>
                        item.subGroupKey !==
                        PropertySelectGroupEnum.SUGGESTIONS,
                );
            }
        }

        return data;
    }, [propertyItems, searchDebounce, props, selectedGroup]);

    /**
     * Groups the filtered items by their subGroupKey.
     *
     * @returns {Record<string, T[]>} The grouped items.
     */
    const groupedFilteredItems = useMemo(() => {
        const grouped: Record<string, T[]> = {};
        filteredItems?.forEach((item) => {
            const subGroupKey = item.subGroupKey || ReservedStrings.DEFAULT;
            if (!grouped[subGroupKey]) {
                grouped[subGroupKey] = [];
            }
            grouped[subGroupKey].push(item);
        });
        return grouped;
    }, [filteredItems]);

    /**
     * Flattens the grouped items into a single list with headers.
     *
     * @returns {Array<{ type: ReservedStrings.HEADER | ReservedStrings.ITEM; data: T | string }>} The flattened list with headers.
     */
    const flatListWithHeaders = useMemo(() => {
        const flatList: Array<{
            type: ReservedStrings.HEADER | ReservedStrings.ITEM;
            data: T | string;
        }> = [];
        Object.entries(groupedFilteredItems)?.forEach(
            ([subGroupKey, groupItems]) => {
                if (
                    subGroupKey &&
                    groupItems[0]?.subGroupLabel &&
                    subGroupKey !== ReservedStrings.DEFAULT
                ) {
                    flatList.push({
                        type: ReservedStrings.HEADER,
                        data: groupItems[0]?.subGroupLabel as string,
                    });
                }
                groupItems.forEach((item) => {
                    flatList.push({ type: ReservedStrings.ITEM, data: item });
                });
            },
        );
        return flatList;
    }, [groupedFilteredItems]);

    /**
     * Toggles the selection state of an item.
     *
     * @param {T & AddditionalPropertySelectListProps} item - The item to toggle selection for.
     */
    const toggleSelection = useCallback(
        (item: T & AddditionalPropertySelectListProps) => {
            setPropertyItems((prevItems) =>
                prevItems.map((group) => ({
                    ...group,
                    items: group.items.map((groupItem) =>
                        groupItem === item
                            ? { ...groupItem, isChecked: !groupItem.isChecked }
                            : groupItem,
                    ),
                })),
            );
        },
        [],
    );

    const isSelectButtonDisabled = useMemo(() => {
        return _.isEqual(items, propertyItems);
    }, [items, propertyItems]);

    const totalSelectedProperties = useMemo(() => {
        return Object.values(eachGroupSelectedItemsCount ?? {}).reduce(
            (sum, count) => sum + count,
            0,
        );
    }, [eachGroupSelectedItemsCount]);

    /**
     * Renders each property item or header.
     *
     * @param {Object} param0 - The parameters for rendering.
     * @param {number} param0.index - The index of the item.
     * @param {React.CSSProperties} param0.style - The style for the item.
     * @returns {JSX.Element} The rendered item or header.
     */
    const EachProperty = useCallback(
        ({ index, style }: { index: number; style: React.CSSProperties }) => {
            const listItem = flatListWithHeaders[index];
            if (listItem.type === ReservedStrings.HEADER) {
                const headerHeight =
                    index === 0 ? HEADER_HEIGHT : HEADER_HEIGHT + 26; //Add more space for the headers to accommodate the divider
                return (
                    <Box
                        style={{
                            ...style,
                            height: headerHeight,
                        }} // Set the height for the header
                        className={`px-2 py-3 text-xs font-medium text-gray-600`}
                    >
                        {index !== 0 && (
                            <Divider className="mb-4 border-t-4 border-t-shade-2" />
                        )}
                        {listItem.data as ReactNode}
                    </Box>
                );
            }
            const item = listItem.data as T;
            return (
                <Box
                    style={style}
                    className={
                        item.isDisabled
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                    }
                    onClick={() => {
                        if (item.isDisabled) return;
                        if (props.allowMultipleSelection) {
                            toggleSelection(item);
                        } else {
                            onSubmit([item]);
                        }
                    }}
                >
                    {itemTemplate({ item })}
                </Box>
            );
        },
        [
            flatListWithHeaders,
            itemTemplate,
            onSubmit,
            props.allowMultipleSelection,
            toggleSelection,
        ],
    );

    const shouldShowGroupPanel = useMemo(() => {
        return showGroup && items?.length > 1;
    }, [showGroup, items]);

    const listRef = useRef<VariableSizeList>(null);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0, true);
        }
    }, [selectedGroup]);

    return (
        <Menu
            opened={opened}
            onOpen={open}
            onClose={close}
            position="bottom-start"
            width={width}
            withinPortal={withinPortal}
            disabled={disabled}
        >
            <Menu.Target>
                <Box
                    className={`${
                        disabled ? 'pointer-events-none' : 'cursor-pointer'
                    } w-fit`}
                >
                    {targetButton}
                </Box>
            </Menu.Target>
            <Menu.Dropdown>
                <Box>
                    <Flex
                        justify="space-between items-center"
                        className="border-b border-shade-6"
                    >
                        <Box className="m-2.5 grow">
                            {props.showSearch && (
                                <SearchInput
                                    icon={
                                        <MagnifyingGlass
                                            color={'rgb(var(--color-gray-500))'}
                                        />
                                    }
                                    variant="unstyled"
                                    placeholder={
                                        props.searchPlaceholder ??
                                        t(
                                            'property_select_type.search_placeholder',
                                        )
                                    }
                                    className="w-full "
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            )}
                        </Box>
                        {headerRightSection}
                    </Flex>
                    <Group
                        className={`flex items-start gap-0 ${
                            shouldShowGroupPanel ? 'pl-2' : ''
                        } ${
                            props.allowMultipleSelection && props.isLoading
                                ? 'opacity-50 pointer-events-none'
                                : ''
                        }`}
                    >
                        {shouldShowGroupPanel && (
                            <Box
                                className={`pt-3  ${
                                    groupPanelWidth ? `` : 'basis-1/3'
                                }`}
                                style={{
                                    width: groupPanelWidth
                                        ? groupPanelWidth
                                        : 'unset',
                                }}
                            >
                                <ul className="flex flex-col gap-2">
                                    {showAllItemsGroup && (
                                        <li
                                            key="ALL"
                                            className={`rounded-lg cursor-pointer flex flex-col items-start p-1 text-sm me-1 ${
                                                !selectedGroup
                                                    ? 'bg-gray-200'
                                                    : 'bg-white hover:bg-gray-50'
                                            }`}
                                            onClick={() =>
                                                setSelectedGroup(undefined)
                                            }
                                        >
                                            <Box className="flex justify-between w-full">
                                                <Box
                                                    className={`text-sm font-semibold ps-1 ${
                                                        !selectedGroup
                                                            ? 'text-gray-800'
                                                            : 'text-gray-700'
                                                    }`}
                                                >
                                                    {t(
                                                        'property_select_type.all_table_label',
                                                    )}
                                                </Box>
                                                {props.allowMultipleSelection &&
                                                    totalSelectedProperties >
                                                        0 && (
                                                        <Box className="flex text-gray-700 pe-1 align-center">
                                                            {
                                                                totalSelectedProperties
                                                            }
                                                        </Box>
                                                    )}
                                            </Box>
                                        </li>
                                    )}
                                    {items.map((group) => (
                                        <li
                                            key={group.groupKey}
                                            className={`rounded-lg cursor-pointer flex flex-col items-start p-1 me-1 ${
                                                selectedGroup === group.groupKey
                                                    ? 'bg-gray-200'
                                                    : 'bg-white hover:bg-gray-50'
                                            }`}
                                            onClick={() => {
                                                setSelectedGroup(
                                                    group.groupKey,
                                                );
                                                if (listRef.current) {
                                                    listRef.current.scrollToItem(
                                                        0,
                                                    );
                                                }
                                            }}
                                        >
                                            <Box className="flex justify-between w-full">
                                                <Box className="flex flex-row items-center gap-1.5">
                                                    {group.groupIcon}
                                                    <Box
                                                        className={`text-sm font-semibold ${
                                                            selectedGroup ===
                                                            group.groupKey
                                                                ? 'text-gray-800'
                                                                : 'text-gray-700'
                                                        }`}
                                                    >
                                                        {group.groupLabel}
                                                    </Box>
                                                </Box>
                                                {props.allowMultipleSelection && (
                                                    <Box className="flex p-1 text-gray-700 align-center">
                                                        {eachGroupSelectedItemsCount[
                                                            group.groupKey
                                                        ] > 0 &&
                                                            eachGroupSelectedItemsCount[
                                                                group.groupKey
                                                            ]}
                                                    </Box>
                                                )}
                                            </Box>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        )}
                        <Box
                            className={`flex-1 overflow-scroll pt-1 ${
                                shouldShowGroupPanel
                                    ? 'border-l border-shade-6'
                                    : ''
                            }`}
                        >
                            <Box className="!py-0 pl-2">
                                <VariableSizeList
                                    ref={listRef}
                                    height={height}
                                    itemCount={flatListWithHeaders.length}
                                    itemSize={(index) =>
                                        flatListWithHeaders[index].type ===
                                        ReservedStrings.HEADER
                                            ? index === 0
                                                ? HEADER_HEIGHT
                                                : HEADER_HEIGHT + 26
                                            : ITEM_HEIGHT
                                    }
                                    width="100%"
                                    className="List !overflow-x-hidden"
                                >
                                    {EachProperty}
                                </VariableSizeList>
                            </Box>
                        </Box>
                    </Group>
                    {props.allowMultipleSelection && (
                        <Box className="flex justify-end p-2 border-t border-shade-6">
                            <Button
                                variant={ButtonVariant.FILLED}
                                leftIcon={
                                    props.isLoading ? (
                                        <Loader color="white" size={14} />
                                    ) : null
                                }
                                onClick={() => {
                                    if (totalSelectedProperties > 0) {
                                        onSubmit(
                                            propertyItems
                                                .flatMap((group) => group.items)
                                                .filter(
                                                    (item) => item.isChecked,
                                                ),
                                        );
                                    }
                                }}
                                disabled={
                                    props.isLoading || isSelectButtonDisabled
                                }
                            >
                                {props.isLoading
                                    ? t('audience_preview.select_saving_state')
                                    : t('audience_preview.select')}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Menu.Dropdown>
        </Menu>
    );
};

export default PropertySelect;
