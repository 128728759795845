import { type AnyType } from '@lightdash/common';

export enum SchemaBuilderStep {
    SETUP = 'setup',
    PERSONALISE = 'personalise',
    PROPERTIES = 'properties',
}

export enum SchemaConfigStep {
    CONFIGURE = 'configure',
    RELATIONSHIPS = 'relationships',
    EVENTS = 'events',
    PROPERTIES = 'properties',
}

export enum SchemaFileType {
    CSV = 'CSV',
    WAREHOUSE = 'WAREHOUSE',
}

export interface SchemaStepMap {
    key: SchemaBuilderStep | SchemaConfigStep;
    label: string;
    component: React.ComponentType<AnyType>;
}

export interface StepMap {
    key: SchemaBuilderStep;
    label: string;
    isVisited: boolean;
}
