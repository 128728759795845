import Page from '@components/Page/Page';
import TemplateDetails from '@components/Templates/TemplateDetails';
import React from 'react';

const TemplateBuilder = () => {
    return (
        <Page
            title={'Templates'}
            withFullHeight
            // withPaddedContent
            withNavbar
            backgroundColor="gray"
            withFixedContent
        >
            <TemplateDetails />
        </Page>
    );
};

export default TemplateBuilder;
