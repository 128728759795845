import TextInput from '@components/common/Inputs/TextInput';
import UnsavedChangesModal from '@components/common/modal/UnsavedChangesModal';
import { useLocale } from '@hooks/useLocale';
import { Box, Radio, Text } from '@mantine/core';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React, { useEffect, useState } from 'react';
import SettingsTitle from '../SettingsTitle';

enum EntryLimitType {
    NO_LIMIT = 'no_limit',
    SPECIFIC = 'specific',
}

const EntryLimits = () => {
    const { t } = useLocale();

    const [limitType, setLimitType] = useState<EntryLimitType>(
        EntryLimitType.NO_LIMIT,
    );
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const { state, actions } = useJourneyBuilderContext((context) => context);
    const { journeyPayload, initialJourneyPayload } = state;
    const { mutateAsyncJourney, setEntryLogic } = actions;

    useEffect(() => {
        if (journeyPayload?.entryLogic?.entryLimit) {
            setLimitType(EntryLimitType.SPECIFIC);
        }
    }, [journeyPayload?.entryLogic?.entryLimit]);

    const handleRadio = (value: EntryLimitType) => {
        setLimitType(value);
        if (value === EntryLimitType.NO_LIMIT) {
            setEntryLogic({
                entryLimit: undefined,
            });
        }
        setUnsavedChanges(true);
    };

    return (
        <Box>
            <SettingsTitle
                icon={undefined}
                title={t('journey_settings.entry_limits.title')}
                subtitle={t('journey_settings.entry_limits.subtitle')}
            />
            <Box className="mt-6 ml-4">
                <Text className="mb-3 text-gray-800 font-semibold">
                    {t('journey_settings.entry_limits.limit_label')}
                </Text>
                <Radio.Group value={limitType} onChange={handleRadio}>
                    <Radio
                        value={EntryLimitType.NO_LIMIT}
                        className="mb-2"
                        label={t('journey_settings.entry_limits.no_limits')}
                    />
                    <Radio
                        value={EntryLimitType.SPECIFIC}
                        label={t('journey_settings.entry_limits.specific')}
                    />
                </Radio.Group>
                {limitType === EntryLimitType.SPECIFIC && (
                    <TextInput
                        value={journeyPayload?.entryLogic?.entryLimit}
                        onChange={(e) => {
                            if (
                                Number(e.target.value) !==
                                journeyPayload?.entryLogic?.entryLimit
                            ) {
                                setUnsavedChanges(true);
                            }
                            setEntryLogic({
                                entryLimit: Number(e.target.value),
                            });
                        }}
                        type="number"
                        className="w-[250px] ml-6"
                    />
                )}
            </Box>
            <UnsavedChangesModal
                opened={unsavedChanges}
                secondaryActionButtonClick={() => {
                    setEntryLogic({
                        entryLimit:
                            initialJourneyPayload.entryLogic?.entryLimit,
                    });
                    setUnsavedChanges(false);
                }}
                disableButtons={false}
                primaryActionButtonClick={async () => {
                    setUnsavedChanges(false);
                    await mutateAsyncJourney();
                }}
            />
        </Box>
    );
};

export default EntryLimits;
