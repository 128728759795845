import Page from '@components/Page/Page';
import ProfileActivityAndEngagement from '@components/Profiles/ProfileActivityAndEngagement';
import ProfileAttributes from '@components/Profiles/ProfileAttributes';
import { useLocale } from '@hooks/useLocale';
import { Box, Flex } from '@mantine/core';
import React from 'react';
import UserUpdateTraits from '../components/Profiles/UserUpdateTraits';
const ProfilesBuilder = () => {
    const { t } = useLocale();
    return (
        <Page
            title={t('profiles')}
            withFullHeight
            withPaddedContent
            withNavbar
            backgroundColor="gray"
            withFixedContent
        >
            <Flex gap={16}>
                <Box className="w-[21.25rem]">
                    <ProfileAttributes />
                    <UserUpdateTraits />
                </Box>

                <Box className="flex-1 min-w-[40rem]">
                    <ProfileActivityAndEngagement />
                </Box>
            </Flex>
        </Page>
    );
};

export default React.memo(ProfilesBuilder);
