import { sortmentApi } from '@api/index';
import { type ApiError, type Tag } from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

const getTags = async ({ projectUuid }: { projectUuid: string }) =>
    sortmentApi<Tag[]>({
        url: `/projects/${projectUuid}/srt-tags`,
        method: 'GET',
        body: undefined,
    });

export const useTags = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useQuery({
        queryKey: [QueryKeys.TAGS, projectUuid],
        queryFn: () => getTags({ projectUuid }),
    });
};

const createTag = async ({
    projectUuid,
    tag,
}: {
    projectUuid: string;
    tag: Pick<Tag, 'name'>;
}) =>
    sortmentApi<{ tagUuid: string }>({
        url: `/projects/${projectUuid}/srt-tags`,
        method: 'POST',
        body: JSON.stringify(tag),
    });

export const useCreateTag = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const queryClient = useQueryClient();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation({
        mutationFn: (tag: Pick<Tag, 'name'>) => createTag({ projectUuid, tag }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [QueryKeys.TAGS, projectUuid],
            });
            showToastSuccess({
                title: t('tag_created'),
            });
        },
        onError: (error: ApiError) => {
            showToastError({
                title: t('tag_creation_failed'),
                subtitle: error.error.message,
            });
        },
    });
};
