import {
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from '@components/Audience/Filters/FieldListItem/utils';
import TemplateContentPreview from '@components/Campaigns/Builder/Steps/CampaignBuilderContent/SetupContent/TemplateContentPreview';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { getJourneyEventPropertySelectList } from '@components/Journeys/Builder/JourneyFilters/useJourneyProperties';
import { useLocale } from '@hooks/useLocale';
import {
    useGetSyncedTemplateByTemplateName,
    useGetTemplateById,
} from '@hooks/useTemplate';
import {
    CommunicationChannel,
    ContentMappingSection,
    TemplateType,
    type ContentMappings,
    type EmailTemplateContentDetails,
    type ExternalCampaignTriggerRequest,
} from '@lightdash/common';
import { Box, Button, Group, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { SetupContentSource } from '@components/Campaigns/Builder/types';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React, { useEffect, useMemo, useState } from 'react';
import { ButtonVariant } from '../../../../../../../mantineTheme';
import { useJourneyNodeParams } from '../../../useJourneyNodeParams';
import ActionTitle from '../../ActionTitle';
import ContentSetupStrip from '../ContentSetupStrip';
import JourneyTest from '../JourneyTest';
import JourneyContentSetupManagerModal from './SetupManager';

interface CampaignActionContentProps {
    setJourneyContentPayload: (
        messageConfig: ExternalCampaignTriggerRequest,
    ) => void;
    nodeId: string;
    messageConfig: ExternalCampaignTriggerRequest;
}

const CampaignActionContent: React.FC<CampaignActionContentProps> = ({
    setJourneyContentPayload,
    nodeId,
    messageConfig,
}) => {
    const [isTestModalOpen, setIsTestModalOpen] = useState<boolean>(false);
    const { t } = useLocale();
    const {
        isLoading: isJourneyNodeParamsLoading,
        warehouseFields,
        journeyDataSchema,
    } = useJourneyNodeParams(nodeId);

    const { nodes, edges, journeyPayload, isEditable } =
        useJourneyBuilderContext((context) => context.state);

    const {
        data: templateMetadata,
        isInitialLoading: isTemplateMetadataLoading,
    } = useGetTemplateById(
        messageConfig?.templateDetails?.id ?? '',
        messageConfig.channel !== CommunicationChannel.WHATSAPP,
    );

    useEffect(() => {
        if (templateMetadata && !isTemplateMetadataLoading) {
            if (
                messageConfig.channel === CommunicationChannel.EMAIL &&
                !Object.keys(messageConfig.contentDetails ?? {}).length
            ) {
                let payload = {
                    campaignTrackingParameters:
                        templateMetadata.contents[0]?.trackingParameters !==
                        null
                            ? templateMetadata.contents[0]?.trackingParameters
                            : undefined,
                    contentDetails: {
                        subject: templateMetadata.contents[0]?.subject ?? '',
                        html: (
                            templateMetadata.contents[0]
                                ?.content as EmailTemplateContentDetails
                        ).html,
                        json: (
                            templateMetadata.contents[0]
                                ?.content as EmailTemplateContentDetails
                        ).json,
                        variables: templateMetadata.contents[0].variables,
                        parsedVariables:
                            templateMetadata.contents[0].parsedVariables ?? [],
                    },
                    previewText:
                        templateMetadata.contents[0]?.preheader !== null
                            ? templateMetadata.contents[0]?.preheader
                            : undefined,
                    templateDetails: {
                        id: templateMetadata.id,
                        type: TemplateType.INTERNAL,
                    },
                    contentMappings:
                        templateMetadata.contents[0]?.contentMappings,
                };
                // INFO - Extra checks to avoid re-renders
                if (
                    payload.campaignTrackingParameters ||
                    payload.contentMappings ||
                    payload.previewText ||
                    payload.contentDetails
                ) {
                    setJourneyContentPayload({
                        ...messageConfig,
                        ...payload,
                    });
                }
            }
            if (
                messageConfig.channel === CommunicationChannel.SMS &&
                !Object.keys(messageConfig.contentDetails ?? {}).length
            ) {
                let payload = {
                    campaignTrackingParameters:
                        templateMetadata.contents[0]?.trackingParameters !==
                        null
                            ? templateMetadata.contents[0]?.trackingParameters
                            : undefined,
                    contentDetails: {
                        ...templateMetadata.contents[0]?.content,
                        variables: templateMetadata.contents[0].variables,
                    },
                    templateDetails: {
                        id: templateMetadata.id,
                        type: TemplateType.INTERNAL,
                    },
                    contentMappings:
                        templateMetadata.contents[0]?.contentMappings,
                };
                if (
                    payload.campaignTrackingParameters ||
                    payload.contentMappings ||
                    payload.contentDetails
                ) {
                    setJourneyContentPayload({
                        ...messageConfig,
                        ...payload,
                    });
                }
            }
        }
    }, [
        isTemplateMetadataLoading,
        messageConfig,
        setJourneyContentPayload,
        templateMetadata,
    ]);

    const {
        data: syncedTemplateMetadata,
        isLoading: isSyncedTemplateMetadataLoading,
    } = useGetSyncedTemplateByTemplateName(
        (messageConfig.templateDetails?.integrationId ||
            messageConfig.communicationDetails?.id) ??
            '',
        messageConfig?.templateDetails?.id ?? '',
        messageConfig.channel === CommunicationChannel.WHATSAPP,
    );

    const getTemplateMetadata = useMemo(() => {
        if (messageConfig.channel === CommunicationChannel.WHATSAPP) {
            return {
                ...syncedTemplateMetadata,
                contents: syncedTemplateMetadata?.languages[0]?.content ?? [],
                channel: CommunicationChannel.WHATSAPP,
            };
        }
        if (messageConfig.contentDetails) {
            return messageConfig.contentDetails;
        }
        if (templateMetadata) {
            return templateMetadata;
        }
        return null;
    }, [
        messageConfig.channel,
        messageConfig.contentDetails,
        syncedTemplateMetadata,
        templateMetadata,
    ]);

    const [
        opened,
        { open: openContentSetupManager, close: closeContentSetupManager },
    ] = useDisclosure(false);

    const hasChannel = useMemo(() => {
        return (
            messageConfig.communicationDetails &&
            messageConfig.communicationDetails.id
        );
    }, [messageConfig.communicationDetails]);

    const journeyTables = useMemo(() => {
        return Object.keys(journeyDataSchema?.tables ?? {});
    }, [journeyDataSchema?.tables]);

    const variablesMappedWithJourneyParams = useMemo(() => {
        const result: ContentMappings = {
            [ContentMappingSection.SUBJECT]: undefined,
            [ContentMappingSection.BODY]: undefined,
            [ContentMappingSection.BUTTONS]: undefined,
            [ContentMappingSection.HEADER]: undefined,
        };
        const subjectVariables =
            messageConfig?.contentMappings?.[ContentMappingSection.SUBJECT];
        const bodyVariables =
            messageConfig?.contentMappings?.[ContentMappingSection.BODY];
        const headerVariables =
            messageConfig?.contentMappings?.[ContentMappingSection.HEADER];
        const buttonVariables =
            messageConfig?.contentMappings?.[ContentMappingSection.BUTTONS];
        if (bodyVariables) {
            let variables = {};
            Object.entries(bodyVariables).forEach(
                ([variable, variableMapping]) => {
                    if (
                        journeyTables.some((item) =>
                            variableMapping.value.includes(item),
                        )
                    ) {
                        variables = {
                            ...variables,
                            [variable]: variableMapping,
                        };
                    }
                },
            );
            result[ContentMappingSection.BODY] = variables;
        }
        if (subjectVariables) {
            let variables = {};
            Object.entries(subjectVariables).forEach(
                ([variable, variableMapping]) => {
                    if (
                        journeyTables.some((item) =>
                            variableMapping.value.includes(item),
                        )
                    ) {
                        variables = {
                            ...variables,
                            [variable]: variableMapping,
                        };
                    }
                },
            );
            result[ContentMappingSection.SUBJECT] = variables;
        }
        if (headerVariables) {
            let variables = {};
            Object.entries(headerVariables).forEach(
                ([variable, variableMapping]) => {
                    if (
                        journeyTables.some((item) =>
                            variableMapping.value.includes(item),
                        )
                    ) {
                        variables = {
                            ...variables,
                            [variable]: variableMapping,
                        };
                    }
                },
            );
            result[ContentMappingSection.HEADER] = variables;
        }
        if (buttonVariables) {
            let variables = {};
            Object.entries(buttonVariables).forEach(
                ([variable, variableMapping]) => {
                    if (
                        journeyTables.some((item) =>
                            variableMapping.value.includes(item),
                        )
                    ) {
                        variables = {
                            ...variables,
                            [variable]: variableMapping,
                        };
                    }
                },
            );
            result[ContentMappingSection.BUTTONS] = variables;
        }
        return result;
    }, [journeyTables, messageConfig?.contentMappings]);

    const renderContentElement = useMemo(() => {
        // Case where a communication channel is selected but no template is selected
        if (!messageConfig?.templateDetails?.id) {
            return (
                <Button
                    variant={ButtonVariant.DEFAULT}
                    className="w-fit"
                    onClick={openContentSetupManager}
                >
                    {/* {t('journey_builder.channel_element_select_channel_btn')} */}
                    Setup
                </Button>
            );
        }

        // Case where a communication channel and a template is selected but the template metadata for preview is still loading
        if (
            isTemplateMetadataLoading ||
            isJourneyNodeParamsLoading ||
            (messageConfig?.channel === CommunicationChannel.WHATSAPP &&
                isSyncedTemplateMetadataLoading)
        )
            return (
                <Box className="p-3">
                    <SkeletonLoader height={70} />
                </Box>
            );

        // Case where a communication channel and a template is selected and the template metadata is loaded but empty
        if (!getTemplateMetadata) return null;

        // Case where a communication channel and a template is selected and the template metadata is loaded

        // INFO - @manoj-floian @Divyansh-soni-17 Validations implemented here are not reliable !! Need to re look this
        return (
            <Box className=" w-full">
                <ContentSetupStrip
                    onSendTest={setIsTestModalOpen}
                    openContentSetupManager={openContentSetupManager}
                    isSetupComplete={
                        messageConfig.channel === CommunicationChannel.WHATSAPP
                            ? !!messageConfig.communicationDetails?.id &&
                              !!messageConfig.sendTo?.[messageConfig.channel]
                            : !!messageConfig.contentDetails
                    }
                />
                <TemplateContentPreview
                    templateMetadata={getTemplateMetadata as any}
                    grapesContainerId="html-preview-journeys-action"
                    showDeviceAndThemeSwitcher={false}
                    showModifyButton={false}
                    onTemplateSelect={undefined}
                    isSetupComplete={
                        messageConfig.channel === CommunicationChannel.WHATSAPP
                            ? !!messageConfig.communicationDetails?.id &&
                              !!messageConfig.sendTo?.[messageConfig.channel]
                            : !!messageConfig.contentDetails
                    }
                    openContentSetupManager={openContentSetupManager}
                    showHeader={false}
                    source={SetupContentSource.JOURNEY}
                    handleUpdateTemplateInCampaignContext={undefined}
                />
                {isTestModalOpen && (
                    <JourneyTest
                        contentMappings={messageConfig.contentMappings ?? {}}
                        journeyVariables={variablesMappedWithJourneyParams}
                        channel={
                            messageConfig.channel ?? CommunicationChannel.EMAIL
                        }
                        opened={isTestModalOpen}
                        onClose={() => setIsTestModalOpen(false)}
                        contentDetails={messageConfig.contentDetails}
                        communicationDetails={
                            messageConfig.communicationDetails
                        }
                        templateDetails={messageConfig.templateDetails}
                    />
                )}
            </Box>
        );
    }, [
        messageConfig,
        isTemplateMetadataLoading,
        isJourneyNodeParamsLoading,
        isSyncedTemplateMetadataLoading,
        getTemplateMetadata,
        openContentSetupManager,
        isTestModalOpen,
        setIsTestModalOpen,
        variablesMappedWithJourneyParams,
    ]);

    const journeyEventsPropertySelectList = useMemo(() => {
        if (!journeyDataSchema) return [];
        return getJourneyEventPropertySelectList({
            journeyDataSchema,
            nodes,
            edges,
            nodeId,
            journeyNodes: journeyPayload.config?.nodes ?? [],
        });
    }, [journeyDataSchema, nodes, edges, journeyPayload, nodeId]);

    return (
        <>
            <Stack>
                <Group className="justify-between">
                    <ActionTitle
                        title={t('journey_builder.content_element_title')}
                    />
                </Group>

                {!hasChannel && isEditable ? (
                    <Button
                        variant={ButtonVariant.DEFAULT}
                        className="w-fit"
                        onClick={openContentSetupManager}
                        disabled
                    >
                        {t(
                            'journey_builder.channel_element_select_integration_btn_disabled',
                        )}
                    </Button>
                ) : (
                    <>
                        {renderContentElement}
                        <JourneyContentSetupManagerModal
                            opened={opened}
                            closeContentSetupManager={closeContentSetupManager}
                            messageConfig={messageConfig}
                            setJourneyContentPayload={setJourneyContentPayload}
                            fields={{
                                ...warehouseFields,
                            }}
                            additionalPropertySelectList={
                                journeyEventsPropertySelectList as PropertySelectListType<
                                    FieldWithSelectStatus,
                                    PropertySelectGroupType
                                >[]
                            }
                            variablesMappedWithJourneyParams={
                                variablesMappedWithJourneyParams
                            }
                        />
                    </>
                )}
            </Stack>
        </>
    );
};

export default React.memo(CampaignActionContent);
