import { useGetCampaignById } from '@hooks/useCampaigns';
import {
    CLICKED_EVENT_STATUS,
    CONVERSION_EVENT_STATUS,
    DELIVERED_EVENT_STATUS,
    EventStatus,
    getDedupedEventTableName,
    getSortmentWarehouseName,
    OPENED_EVENT_STATUS,
    ReservedEventColumns,
    SENT_EVENT_STATUS,
    WarehouseTypes,
    type ApiQueryResults,
} from '@lightdash/common';
import useProjectContext from '@providers/Project/useProjectContext';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';

const useBaseFunnelData = ({ data }: { data: ApiQueryResults | undefined }) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData } = useProjectContext();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const campaignId = queryParams.get('campaignUuid') ?? '';
    const tablePrefix = useMemo(() => {
        if (projectUuid && projectData && projectData.warehouseConnection) {
            return getDedupedEventTableName(
                projectUuid,
                projectData.warehouseConnection.type,
            );
        }
        return '';
    }, [projectUuid, projectData]);

    const { data: campaignData } = useGetCampaignById(campaignId);

    const eventMap = useMemo(
        () =>
            data &&
            projectData &&
            projectData.warehouseConnection &&
            data.rows.reduce((acc, item) => {
                const eventName = item[
                    `${tablePrefix}_${getSortmentWarehouseName(
                        ReservedEventColumns.EVENT_NAME,
                        projectData.warehouseConnection?.type ??
                            WarehouseTypes.SNOWFLAKE,
                    )}`
                ]?.value?.raw as string;
                if (eventName) {
                    acc[eventName] = item;
                }
                return acc;
            }, {} as Record<string, any>),
        [data, tablePrefix, projectData],
    );

    if (!data || !eventMap) return null;

    const getCount = (eventKey: string, columnKey: string) =>
        eventMap[eventKey]?.[`${tablePrefix}_${columnKey}`]?.value?.raw ?? 0;

    const deliveredCount = getCount(
        EventStatus.DELIVERED,
        DELIVERED_EVENT_STATUS,
    );
    const bouncedCount = getCount(EventStatus.BOUNCE, DELIVERED_EVENT_STATUS);
    const undeliveredCount = getCount(
        EventStatus.UNDELIVERED,
        DELIVERED_EVENT_STATUS,
    );
    const openEventsCount = getCount(EventStatus.OPEN, OPENED_EVENT_STATUS);
    const readEventsCount = getCount(EventStatus.READ, OPENED_EVENT_STATUS);
    const clickedEventsCount = getCount(
        EventStatus.CLICKED,
        CLICKED_EVENT_STATUS,
    );
    const convertedEventsCount = getCount(
        EventStatus.CONVERTED,
        CONVERSION_EVENT_STATUS,
    );
    const failedEventsCount = getCount(EventStatus.FAILED, SENT_EVENT_STATUS);
    const errorEventsCount = getCount(EventStatus.ERROR, SENT_EVENT_STATUS);
    const droppedEventsCount = getCount(EventStatus.DROPPED, SENT_EVENT_STATUS);

    const sentFailures =
        failedEventsCount + errorEventsCount + droppedEventsCount;

    const sentCount =
        (campaignData?.communicationDetails?.runDetails?.[0]
            ?.materializedCampaignCount ?? 0) - sentFailures;

    const audienceCount =
        campaignData?.communicationDetails?.runDetails?.[0]
            ?.materializedCampaignCount;
    const audienceExclusionsCount =
        campaignData?.communicationDetails?.runDetails?.[0].ignoredEntityCount;

    return {
        deliveredCount,
        bouncedCount,
        undeliveredCount,
        openEventsCount,
        readEventsCount,
        clickedEventsCount,
        convertedEventsCount,
        sentCount,
        audienceCount,
        audienceExclusionsCount,
        campaignName: campaignData?.name,
        sentFailures,
    };
};

export default useBaseFunnelData;
