// Info: To enrich the tags, just add the enum here and
export enum SentryTagKeys {
    userName = 'user_name',
    userEmail = 'user_email',
    organizationName = 'organization_name',
    package = 'package',
    organizationUuid = 'organization_uuid',
    userUuid = 'user_uuid',
}

export enum SentryPackageType {
    backend = 'backend',
    frontend = 'frontend',
}
