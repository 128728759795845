import { useTestCampaign, useTestCampaignOldFlow } from '@hooks/useCampaigns';
import {
    useGetSyncedTemplateByTemplateName,
    useGetTemplateById,
} from '@hooks/useTemplate';
import {
    CommunicationChannel,
    DefaultWhatsappHeaderVariablesMap,
    WAHeaderType,
    WhatsappHeaderVariable,
    type Campaign,
    type ContentMappings,
    type ContentMappingSection,
    type TestCampaignRequest,
    type TestCampaignRequestv1,
} from '@lightdash/common';
import { isEmpty } from 'lodash';

type UseTestCommunicationProps = {
    communicationDetails: Campaign['communicationDetails'];
    templateDetails: Campaign['templateDetails'];
    channel: CommunicationChannel;
    previewText?: string | undefined;
};

const useTestCommunication = ({
    communicationDetails,
    templateDetails,
    channel,
    previewText,
}: UseTestCommunicationProps) => {
    const {
        mutateAsync: triggerTestMutation,
        // isLoading: isTestLoadingNewFlow,
    } = useTestCampaign();

    const {
        mutateAsync: triggerTestMutationOldFlow,
        // isLoading: isTestLoadingOldFlow,
    } = useTestCampaignOldFlow(
        communicationDetails?.providerId ?? '',
        communicationDetails?.id ?? '',
    );

    const { data: syncedTemplateMetadata } = useGetSyncedTemplateByTemplateName(
        (templateDetails?.integrationId || communicationDetails?.id) ?? '',
        templateDetails?.id ?? '',
        channel === CommunicationChannel.WHATSAPP,
    );

    const { data: templateMetadata } = useGetTemplateById(
        templateDetails?.id ?? '',
        channel !== CommunicationChannel.WHATSAPP,
    );

    const handleSendTestNewFlow = async ({
        recipients,
        campaignUuid,
    }: {
        recipients: any;
        campaignUuid: string;
    }) => {
        const payload: TestCampaignRequest = {
            to: recipients.map((id: string) => ({
                [channel ?? CommunicationChannel.EMAIL]: id,
            })),
        };
        await triggerTestMutation({ payload, campaignId: campaignUuid });
    };

    const handleSendTestOldFlow = async ({
        mappings,
        contentDetails,
        recipients,
    }: {
        mappings: ContentMappings;
        contentDetails: Campaign['contentDetails'];
        recipients: any;
    }) => {
        const mappingsPayload = { ...mappings };

        if (!mappingsPayload) return;
        Object.keys(mappingsPayload).forEach((groupKey) => {
            const mapping =
                mappingsPayload[groupKey as ContentMappingSection] ?? {};
            Object.keys(mapping).forEach((key) => {
                if (
                    key === WhatsappHeaderVariable &&
                    channel === CommunicationChannel.WHATSAPP &&
                    !mapping[key].defaultValue
                ) {
                    mapping[key].defaultValue =
                        DefaultWhatsappHeaderVariablesMap[
                            syncedTemplateMetadata?.languages?.[0]?.content
                                ?.header?.type ?? WAHeaderType.IMAGE
                        ];
                }
            });
        });
        let templateContent = undefined;
        let templateTestDetails = undefined;
        if (channel === CommunicationChannel.EMAIL) {
            templateContent =
                contentDetails && !isEmpty(contentDetails)
                    ? { ...contentDetails }
                    : undefined;
        } else if (channel === CommunicationChannel.SMS) {
            templateContent = templateMetadata?.contents[0].content ?? {
                body: '',
                dltTemplateId: '',
                unicode: false,
                flash: false,
            };
        }
        if (channel === CommunicationChannel.WHATSAPP) {
            templateTestDetails = {
                id: syncedTemplateMetadata?.name ?? '',
                integrationId: syncedTemplateMetadata?.integrationId ?? '',
            };
        }
        const payload: TestCampaignRequestv1 = {
            to: recipients.map((id: string) => ({
                [channel ?? CommunicationChannel.EMAIL]: id,
            })),
            channel: channel,
            mappings: mappingsPayload,
            templateContent,
            templateDetails: templateTestDetails || {
                name: templateMetadata?.name || '',
            },
            previewText,
            // previewText: campaignPayload?.previewText,
        };
        await triggerTestMutationOldFlow({ payload });
    };

    return {
        sendTestOldFlow: handleSendTestOldFlow,
        sendTest: handleSendTestNewFlow,
    };
};

export default useTestCommunication;
