import { z } from 'zod';
import { type AnyType } from './any';
import { type CommunicationChannel } from './communications';
import { type ConditionalOperator } from './conditionalRule';
import { type GenerateCustomAttribute } from './customAttributes';
import { type UpdateCustomMaterialisation } from './customMaterialisations';
import {
    type CustomDimension,
    type CustomFormatType,
    type Format,
    type MetricType,
    type NumberSeparator,
    type Schedule,
    type TableCalculation,
} from './field';
import { type JourneyCreatePayload } from './journeys';
import {
    type AdditionalMetric,
    type MetricQuery,
    type NestedMetricQuery,
    type NestedMetricQueryGroup,
} from './metricQuery';
import { type JoinType, type RelationTableType } from './relation/enums';
import type {
    CachedColumnValues,
    CompiledRelation,
} from './relation/relations';
import { type ChartConfig } from './savedCharts';
import { type TemplateContentDetails } from './templates';
import { type TimeFrames } from './timeFrames';

export const USER_THREAD_MAPPING_TABLE_NAME = 'user_thread_mapping';
export const KNOWLEDGE_BASE_TABLE_NAME = 'knowledge_base';
export const GLOBAL_KNOWLEDGE_BASE_TABLE_NAME = 'global_knowledge_base';
export const USER_MESSAGE_FEEDBACK_TABLE_NAME = 'user_message_feedback';
export const LANGSMITH_USER_MESSAGE_FEEDBACK_KEY = 'user_message_feedback';

// The use case context in which AI is receiving and generating messages
export enum AIMessageContext {
    SQL_AUDIENCE = 'sql_audience',
    VISUAL_AUDIENCE = 'visual_audience',
    HOME_PAGE = 'home_page',
    EVENT_SETUP = 'events',
    OTHERS = 'others',
    SCHEMA_SEARCH = 'schema_search',
    CHART = 'chart',
    CUSTOM_ATTRIBUTE = 'custom_attribute',
    CUSTOM_ATTRIBUTE_CREATION = 'custom_attribute_creation',
    RELATIONS = 'relations',
    INTERRUPT = 'interrupt',
}

export type AgentMessage = {
    threadId: string;
    messageId: string;
    author: Author;
    context: AIMessageContext;
    content: TextContent | ImageContent | AgentJsonContent | AgentErrorContent;
    additional_kwargs?: AnyType;
    traceId: string;
};

export type RelationDetails = {
    relationId: string;
    relation: CompiledRelation;
};

export enum AiMessageTypes {
    TEXT = 'text',
    IMAGE = 'image',
    JSON = 'json',
    ERROR = 'error',
}

export type AgentJsonContent = {
    type: AiMessageTypes.JSON;
    value: SQLAudience | VisualAudience | Chart | CustomAttributeAgent; // Add Analyst output type here
};

export type AgentErrorContent = {
    type: AiMessageTypes.ERROR;
    value: string;
};

export type TextContent = {
    type: AiMessageTypes.TEXT;
    value: string;
    thinkingText?: string;
};

type ImageContent = {
    type: AiMessageTypes.IMAGE;
    value: string;
    alt?: string;
};

export type VisualAudience = {
    context: AIMessageContext.VISUAL_AUDIENCE;
    nestedMetricQuery: NestedMetricQuery;
};

export type SQLAudience = {
    context: AIMessageContext.SQL_AUDIENCE;
    sql: string;
    explaination: string;
};

export type Chart = {
    context: AIMessageContext.CHART;
    metricQuery: MetricQuery;
    chartConfig: ChartConfig;
};

export type CustomAttributeAgent = {
    context:
        | AIMessageContext.CUSTOM_ATTRIBUTE
        | AIMessageContext.CUSTOM_ATTRIBUTE_CREATION;
    customAttribute: GenerateCustomAttribute;
};

export type NaturalLanguageSQLResult = {
    question: string;
    response: string | null;
    summary?: string;
};

export enum Author {
    USER = 'user',
    SYSTEM = 'system',
}

export type AgentContext = {
    messageContext: AIMessageContext;
    schemaLinks?: SchemaLinks;
    projectUuid?: string;
    organisationUuid?: string;
    userUuid?: string;
    metricQuery?: MetricQuery;
    customAttribute?: AdditionalMetric | CustomDimension | TableCalculation;
    cookie?: string;
};

export interface Column {
    name: string;
    type: string;
    description?: string;
    label?: string;
}

export type TableDescription = {
    tableName: string;
    tableDescription: string;
    tableLabel: string;
};

export type EventColumnMapping = {
    key: string;
    columnName: string;
    columnDataType: string;
    label: string;
    nullable: boolean;
};

export type ColumnDetails = {
    name: string;
    description: string;
    label: string;
    masked?: boolean;
    cached?: boolean;
};

export type RelationsServiceRequest = {
    type: string;
    relationID: string;
    tableID: string;
};

export type TableDetails = {
    type: RelationTableType;
    relationID: string;
    tableID: string;
};

export type EventReservedKeysRequest = {
    user_id: string;
    event_id: string;
    timestamp: string;
};

export type EventExistingColumnsRequest = {
    existingColumns: Record<string, string>;
};

export type JourneyNodeDescriptionRequest = {
    journeyDataSchema: JourneyCreatePayload;
    nodeId: string;
};

export type JourneyNodeDescription = {
    nodeId: string;
    nodeDescription: string;
};

export type SchemaLinkElement = {
    id: string;
    description: string;
    type?: string;
    primaryKey?: string;
};

export type SchemaLinkJoin = {
    join: string;
    joinType: JoinType;
};

export type AdditionaMetricDetails = {
    name: string;
    explanation: string;
    additionalMetric: z.infer<typeof additionalMetricType>[];
};

export type SchemaLinks = {
    columns: Array<SchemaLinkElement>;
    values: Record<string, Array<string>>;
    foreignKeys: Array<SchemaLinkJoin>;
    audiences: Array<SchemaLinkElement>;
    customMetrics: Array<SchemaLinkElement>;
    explanation: string;
};

export type EntityNameDescription = {
    name: string;
    description: string;
};

export type VisualAudienceDescriptionRequest = {
    context: AIMessageContext.VISUAL_AUDIENCE;
    nestedMetricQuery: NestedMetricQueryGroup;
};

export type SqlAudienceDescriptionRequest = {
    context: AIMessageContext.SQL_AUDIENCE;
    sqlQuery: string;
};

export type CampaignNameDescriptionRequest = {
    audienceIdArray: string[];
    templateId: string;
    channel: CommunicationChannel;
    schedule: Schedule;
};

export type TemplateNameDescriptionRequest = {
    templateContent: TemplateContentDetails;
    channel: CommunicationChannel;
};

export type AudienceDescriptionRequest =
    | VisualAudienceDescriptionRequest
    | SqlAudienceDescriptionRequest;

export type RelationDetailswithCachedValues = {
    relationId: string;
    relation: CompiledRelation;
    allTableCachedValues: Record<string, Record<string, CachedColumnValues[]>>;
};

export type SuggestedPrompt = {
    prompt: string;
    example: string;
};

export type ProfileSuggestions = {
    CustomMaterialisation: UpdateCustomMaterialisation;
    columnsArray: string[];
};

export type UserThreadMapping = {
    threadId: string;
    userUuid: string;
    projectUuid: string;
    createdAt: Date;
};

export type UserMessageFeedback = {
    threadId: string;
    messageId: string;
    sentiment: boolean;
    metadata: Record<string, string>;
    userUuid: string;
    projectUuid: string;
    createdAt: Date;
    traceId: string;
};

export type DbUserMessageFeedback = {
    message_id: string;
    thread_id: string;
    user_uuid: string;
    project_uuid: string;
    sentiment: boolean;
    metadata: Record<string, string>;
    created_at: Date;
    trace_id: string;
};

export type GetUserFeedbackResponseDto = {
    status: string;
    results: UserMessageFeedback;
};

// If there is only one document for a given type use this enum
// These documents would be retrived and update directly with specific content type
export enum KBSingleDocumentContentTypes {
    FACT = 'fact',
    INFO = 'info',
    NAMING_CONVENTION = 'naming_convention',
    SUMMARY = 'summary',
}

// These documents will generally be retrived with a content query
// They will not be updated, new documents would be appended
export enum KBMultipleDocumentContentTypes {
    ENTITY_SUMMARY = 'entity_summary',
    USER_INSIGHTS = 'user_insights',
    PROJECT_INSIGHTS = 'project_insights',
}

export enum KnowledgeBaseEntities {
    USER = 'user',
    ORGANIZATION = 'organization',
    PROJECT = 'project',
    RELATIONS = 'relations',
    EVENTS = 'events',
    AUDIENCE = 'audience',
    TEMPLATE = 'template',
    CHANNEL = 'channel',
    CAMPAIGN = 'campaign',
    JOURNEY = 'journey',
    ANALYTICS = 'analytics',
    STRATEGY = 'strategy',
    THREAD = 'thread',
}

export type KnowledgeBaseDocument = {
    id: string;
    organizationUuid: string;
    projectUuid: string;
    content: string;
    embedding?: number[];
    contentType: KBSingleDocumentContentTypes | KBMultipleDocumentContentTypes;
    entity?: KnowledgeBaseEntities;
    entityId?: string;
    timestamp: Date;
};

export type GlobalKnowledgeBaseDocument = {
    id: string;
    document_id: string;
    content: string;
    embedding: number[];
    tags: string[];
    chunk_index: number;
    timestamp: Date;
    summary: string;
};

export type ConversationSummary = {
    summary: string;
    countMessagesSummarized: number;
};

export const DEFAULT_AUDIENCE_NAME_FORMAT_GUIDELINES = `
    Format: {KeyBehavior} {UserType} {SegmentCharacteristic} {LifecycleStage}
    - Parameters are optional, so omit any that aren’t relevant.
    - Use underscores between terms.
    - Keep names concise (under 50 characters), abbreviate if needed.
    - Max 50 characters

    Examples:
    - Engaged Premium Onboarding
    - Lapsed Basic Winback
    - Active New Users High Value Retention
    - Engaged Enterprise Onboardings
    - Active Budget
`;

export const DEFAULT_TEMPLATE_NAME_FORMAT_GUIDELINES = `
  Format: {Channel} {Purpose} {KeyTheme} {Action}
  
  - Parameters are optional, so omit any that aren’t relevant to your campaign.
  - Use underscores between terms.
  - Keep names concise (under 50 characters), abbreviate if needed.
  - Max 50 characters.

  Examples:
  - Email Retargeting Conversion Promo
  - Push Awareness ProductLaunch
  - SMS Retention BrandLoyalty Reminder
  - InApp Engagement Upsell
`;

export const DEFAULT_CAMPAIGN_NAME_FORMAT_GUIDELINES = `
  Format: {Channel} {Purpose} {KeyTheme} {Objective}

  - Parameters are optional, so omit any that aren’t relevant.
  - Use underscores between terms.
  - Keep names concise (under 50 characters), abbreviate if needed.
  - Max 50 characters.

  Examples:
  - Email Retargeting Holiday Discount
  - Push Awareness BrandLaunch GoLive
  - SMS Retention FlashSale Conversion
  - Social Engagement TrendCampaign Upsell
`;

export const additionalMetricType = z.object({
    uuid: z.string(),
    baseDimensionName: z.string(),
    table: z.string(),
    sql: z.string(),
    type: z.custom<MetricType>(),
    format: z.custom<Format>().optional(),
    formatOptions: z
        .object({
            type: z.custom<CustomFormatType>(),
            separator: z.custom<NumberSeparator>(),
        })
        .optional(),
    filters: z
        .array(
            z.object({
                id: z.string(),
                operator: z.custom<ConditionalOperator>(),
                values: z.array(z.string()),
                target: z.object({
                    fieldRef: z.string(),
                }),
                settings: z
                    .object({
                        completed: z.boolean(),
                        unitOfTime: z.custom<TimeFrames>(),
                    })
                    .optional(),
            }),
        )
        .optional(),
    label: z.string(),
    name: z.string(),
    description: z.string(),
});

export type Fact = {
    fact: string;
    confidence: number;
    category: KnowledgeBaseEntities;
};
