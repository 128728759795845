import { type FieldValueProperty } from '@components/Audience/Filters/FiltersProvider/types';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import {
    assertUnreachable,
    CustomDimensionType,
    DimensionType,
    FilterOperator,
    FilterType,
    formatBoolean,
    formatDate,
    getFilterTypeFromItem,
    getItemId,
    getLocalTimeDisplay,
    isCustomSqlDimension,
    isDashboardFilterRule,
    isDimension,
    isField,
    isFilterableItem,
    isFilterRule,
    isMomentInput,
    type CompiledRelation,
    type ConditionalRule,
    type ConditionalRuleLabels,
    type CustomSqlDimension,
    type Field,
    type FilterableDimension,
    type FilterableItem,
    type TableCalculation,
} from '@lightdash/common';
import { type PopoverProps } from '@mantine/core';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { type MomentInput } from 'moment';

const filterOperatorLabel: Record<FilterOperator, string> = {
    [FilterOperator.NULL]: 'does not exist',
    [FilterOperator.NOT_NULL]: 'exists',
    [FilterOperator.EQUALS]: 'is',
    [FilterOperator.NOT_EQUALS]: 'is not',
    [FilterOperator.STARTS_WITH]: 'starts with',
    [FilterOperator.ENDS_WITH]: 'ends with',
    [FilterOperator.NOT_INCLUDE]: 'does not include',
    [FilterOperator.INCLUDE]: 'includes',
    [FilterOperator.LESS_THAN]: 'is less than',
    [FilterOperator.LESS_THAN_OR_EQUAL]: 'is less than or equal',
    [FilterOperator.GREATER_THAN]: 'is greater than',
    [FilterOperator.GREATER_THAN_OR_EQUAL]: 'is greater than or equal',
    [FilterOperator.IN_THE_PAST]: 'in the last',
    [FilterOperator.NOT_IN_THE_PAST]: 'not in the last',
    [FilterOperator.IN_THE_NEXT]: 'in the next',
    [FilterOperator.IN_THE_CURRENT]: 'in the current',
    [FilterOperator.IN_BETWEEN]: 'is between',
    [FilterOperator.NOT_IN_THE_CURRENT]: 'not in the current',
    [FilterOperator.EQUALS_CASE_INSENSITIVE]: 'is',
    [FilterOperator.NOT_EQUALS_CASE_INSENSITIVE]: 'is not',
};

const eventFilterOperatorLabel: Record<FilterOperator, string> = {
    ...filterOperatorLabel,
    [FilterOperator.EQUALS]: 'performed',
    [FilterOperator.NOT_EQUALS]: 'did not perform',
};

const derivedCustomMetricFilterOperatorLabel: Record<FilterOperator, string> = {
    ...filterOperatorLabel,
    [FilterOperator.EQUALS]: 'have',
    [FilterOperator.NOT_EQUALS]: "don't have",
};

const audienceFilterOperatorLabel: Record<FilterOperator, string> = {
    ...filterOperatorLabel,
    [FilterOperator.EQUALS]: 'belong to',
    [FilterOperator.NOT_EQUALS]: 'do not belong to',
};
/**
 * this function is used to get the filter operator label
 * @param operator it is the operator that is passed to the function
 * @returns it returns the filter operator label
 */
export const filterOperatorLabelForMetricEvent: Record<FilterOperator, string> =
    {
        ...filterOperatorLabel,
        [FilterOperator.EQUALS]: 'exactly',
        [FilterOperator.LESS_THAN_OR_EQUAL]: 'at most',
        [FilterOperator.GREATER_THAN_OR_EQUAL]: 'at least',
        [FilterOperator.IN_BETWEEN]: 'between',
    };

const getFilterOptions = <T extends FilterOperator>(
    operators: Array<T>,
): Array<{ value: T; label: string }> =>
    operators.map((operator) => ({
        value: operator,
        label: filterOperatorLabel[operator],
    }));

export const getEventFilterOptions = <T extends FilterOperator>(
    operators: Array<T>,
): Array<{ value: T; label: string }> =>
    operators.map((operator) => ({
        value: operator,
        label: eventFilterOperatorLabel[operator],
    }));

export const getDerivedCustomMetricFilterOptions = <T extends FilterOperator>(
    operators: Array<T>,
): Array<{ value: T; label: string }> =>
    operators.map((operator) => ({
        value: operator,
        label: derivedCustomMetricFilterOperatorLabel[operator],
    }));

export const getAudienceFilterOptions = <T extends FilterOperator>(
    operators: Array<T>,
): Array<{ value: T; label: string }> =>
    operators.map((operator) => ({
        value: operator,
        label: audienceFilterOperatorLabel[operator],
    }));

export const getMetricEventFilterOptions = <T extends FilterOperator>(
    operators: Array<T>,
): Array<{ value: T; label: string }> =>
    operators.map((operator) => ({
        value: operator,
        label: filterOperatorLabelForMetricEvent[operator],
    }));

const timeFilterOptions: Array<{
    value: FilterOperator;
    label: string;
}> = [
    ...getFilterOptions([
        FilterOperator.NULL,
        FilterOperator.NOT_NULL,
        FilterOperator.EQUALS,
        FilterOperator.NOT_EQUALS,
        FilterOperator.IN_THE_PAST,
        FilterOperator.NOT_IN_THE_PAST,
        FilterOperator.IN_THE_NEXT,
        FilterOperator.IN_THE_CURRENT,
    ]),
    { value: FilterOperator.LESS_THAN, label: 'is before' },
    { value: FilterOperator.LESS_THAN_OR_EQUAL, label: 'is on or before' },
    { value: FilterOperator.GREATER_THAN, label: 'is after' },
    { value: FilterOperator.GREATER_THAN_OR_EQUAL, label: 'is on or after' },
    { value: FilterOperator.IN_BETWEEN, label: 'is between' },
];

export type FilterInputsProps<T extends ConditionalRule> = {
    filterType: FilterType;
    field: FilterableItem;
    rule: T;
    onChange: (value: T) => void;
    disabled?: boolean;
    popoverProps?: Omit<PopoverProps, 'children'>;
    dynamicFieldValues:
        | PropertySelectListType<FieldValueProperty>[]
        | undefined;
};

export const getFilterOperatorOptions = (
    filterType: FilterType,
): Array<{ value: FilterOperator; label: string }> => {
    switch (filterType) {
        case FilterType.STRING:
            return getFilterOptions([
                FilterOperator.NULL,
                FilterOperator.NOT_NULL,
                FilterOperator.EQUALS,
                FilterOperator.NOT_EQUALS,
                FilterOperator.STARTS_WITH,
                FilterOperator.ENDS_WITH,
                FilterOperator.INCLUDE,
                FilterOperator.NOT_INCLUDE,
                FilterOperator.EQUALS_CASE_INSENSITIVE,
                FilterOperator.NOT_EQUALS_CASE_INSENSITIVE,
            ]);
        case FilterType.EVENT:
            return getEventFilterOptions([
                FilterOperator.EQUALS,
                FilterOperator.NOT_EQUALS,
            ]);
        case FilterType.NUMBER:
            return getFilterOptions([
                FilterOperator.NULL,
                FilterOperator.NOT_NULL,
                FilterOperator.EQUALS,
                FilterOperator.NOT_EQUALS,
                FilterOperator.LESS_THAN,
                FilterOperator.GREATER_THAN,
            ]);
        case FilterType.DATE:
            return timeFilterOptions;
        case FilterType.BOOLEAN:
            return getFilterOptions([
                FilterOperator.NULL,
                FilterOperator.NOT_NULL,
                FilterOperator.EQUALS,
            ]);
        case FilterType.AUDIENCE:
            return getAudienceFilterOptions([
                FilterOperator.EQUALS,
                FilterOperator.NOT_EQUALS,
            ]);
        default:
            return assertUnreachable(
                filterType,
                `Unexpected filter type: ${filterType}`,
            );
    }
};

const getValueAsString = (
    filterType: FilterType,
    rule: ConditionalRule,
    field: Field | TableCalculation | CustomSqlDimension,
) => {
    const { operator, values } = rule;
    const firstValue = values?.[0];
    const secondValue = values?.[1];

    switch (filterType) {
        case FilterType.STRING:
        case FilterType.NUMBER:
            return values?.join(', ');
        case FilterType.BOOLEAN:
            return values?.map(formatBoolean).join(', ');
        case FilterType.DATE:
            switch (operator) {
                case FilterOperator.IN_THE_PAST:
                case FilterOperator.NOT_IN_THE_PAST:
                case FilterOperator.IN_THE_NEXT:
                    if (!isFilterRule(rule)) throw new Error('Invalid rule');

                    return `${firstValue} ${
                        rule.settings?.completed ? 'completed ' : ''
                    }${rule.settings?.unitOfTime}`;
                case FilterOperator.IN_BETWEEN:
                    return `${getLocalTimeDisplay(
                        firstValue as MomentInput,
                        false,
                    )} and ${getLocalTimeDisplay(secondValue as MomentInput)}`;
                case FilterOperator.IN_THE_CURRENT:
                    if (!isFilterRule(rule)) throw new Error('Invalid rule');

                    return rule.settings?.unitOfTime.slice(0, -1);
                case FilterOperator.NULL:
                case FilterOperator.NOT_NULL:
                case FilterOperator.EQUALS:
                case FilterOperator.NOT_EQUALS:
                case FilterOperator.STARTS_WITH:
                case FilterOperator.ENDS_WITH:
                case FilterOperator.INCLUDE:
                case FilterOperator.NOT_INCLUDE:
                case FilterOperator.LESS_THAN:
                case FilterOperator.LESS_THAN_OR_EQUAL:
                case FilterOperator.GREATER_THAN:
                case FilterOperator.GREATER_THAN_OR_EQUAL:
                    return values
                        ?.map((value) => {
                            const type = isCustomSqlDimension(field)
                                ? field.dimensionType
                                : field.type;
                            if (
                                isDimension(field) &&
                                isMomentInput(value) &&
                                type === DimensionType.TIMESTAMP
                            ) {
                                return getLocalTimeDisplay(value);
                            } else if (
                                isDimension(field) &&
                                isMomentInput(value) &&
                                type === DimensionType.DATE
                            ) {
                                return formatDate(value, field.timeInterval);
                            } else {
                                return value;
                            }
                        })
                        .join(', ');
                default:
                    return assertUnreachable(
                        operator,
                        `Unexpected operator: ${operator}`,
                    );
            }
        case FilterType.EVENT:
            return null;
        default:
            return assertUnreachable(
                filterType,
                `Unexpected filter type: ${filterType}`,
            );
    }
};

export const getConditionalRuleLabel = (
    rule: ConditionalRule,
    item: FilterableItem,
): ConditionalRuleLabels => {
    const filterType = isFilterableItem(item)
        ? getFilterTypeFromItem(item)
        : FilterType.STRING;
    const operatorOptions = getFilterOperatorOptions(filterType);
    const operationLabel =
        operatorOptions.find((o) => o.value === rule.operator)?.label ||
        filterOperatorLabel[rule.operator];

    return {
        field: isField(item) ? item.label : item.name,
        operator: operationLabel,
        value: getValueAsString(filterType, rule, item),
    };
};

export const getFilterRuleTables = (
    filterRule: ConditionalRule,
    field: FilterableDimension,
    filterableFields: FilterableDimension[],
): string[] => {
    if (
        isDashboardFilterRule(filterRule) &&
        filterRule.tileTargets &&
        !isEmpty(filterRule.tileTargets)
    ) {
        return Object.values(filterRule.tileTargets).reduce<string[]>(
            (tables, tileTarget) => {
                const targetField = filterableFields.find(
                    (f) =>
                        tileTarget !== false &&
                        f.table === tileTarget.tableName &&
                        getItemId(f) === tileTarget.fieldId,
                );
                return targetField
                    ? uniq([...tables, targetField.tableLabel])
                    : tables;
            },
            [],
        );
    } else {
        return [field.tableLabel];
    }
};

export const getCaseChangeOperator = (
    operator: FilterOperator,
    checked: boolean,
): FilterOperator => {
    switch (operator) {
        case FilterOperator.EQUALS:
            return checked
                ? FilterOperator.EQUALS
                : FilterOperator.EQUALS_CASE_INSENSITIVE;
        case FilterOperator.NOT_EQUALS:
            return checked
                ? FilterOperator.NOT_EQUALS
                : FilterOperator.NOT_EQUALS_CASE_INSENSITIVE;
        case FilterOperator.EQUALS_CASE_INSENSITIVE:
            return checked
                ? FilterOperator.EQUALS
                : FilterOperator.EQUALS_CASE_INSENSITIVE;
        case FilterOperator.NOT_EQUALS_CASE_INSENSITIVE:
            return checked
                ? FilterOperator.NOT_EQUALS
                : FilterOperator.NOT_EQUALS_CASE_INSENSITIVE;
        default:
            return FilterOperator.EQUALS;
    }
};

/**
 * Retrieves custom SQL dimensions from an active relation based on specified table names.
 * @param activeRelation The active relation containing tables and dimensions.
 * @param tablesNames The names of tables to filter dimensions from.
 * @returns An array of custom SQL dimensions.
 */
export const getCustomDimensionsFromActiveRelation = (
    activeRelation: CompiledRelation | undefined,
    tablesNames: string[],
): CustomSqlDimension[] => {
    if (!activeRelation) return [];

    return Object.values(activeRelation.tables)
        .filter((table) => tablesNames.includes(table.name))
        .flatMap((table) =>
            Object.values(table.dimensions)
                .filter((dimension) => dimension.isCustomDimension)
                .map((dimension) => ({
                    type: CustomDimensionType.SQL,
                    sql: dimension.sql,
                    dimensionType: dimension.type,
                    id: dimension.name,
                    name: dimension.label,
                    table: dimension.table,
                })),
        );
};
