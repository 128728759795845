import { useLocale } from '@hooks/useLocale';
import {
    isCustomSqlDimension,
    isTableCalculation,
    QueryGenerationStrategy,
    type DimensionType,
} from '@lightdash/common';
import { type ColumnDef } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import { useFieldsWithSuggestions } from '../Filters/FiltersCard/useFieldsWithSuggestions';
import { getFieldFromCustomDimensionsAndFields } from '../Filters/utils';
import AudiencePreviewCell from './AudiencePreviewCell';

export function useAudiencePreviewColumns(
    fields: Record<string, { type: DimensionType }>,
    generationStrategy: string,
) {
    const [columns, setColumns] =
        useState<ColumnDef<Record<string, unknown>>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const { activeRelation, customDimensionsFields } = useRelationContext();

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });

    useEffect(() => {
        if (projectUuid && fields) {
            if (!fieldsWithSuggestions || isEmpty(fieldsWithSuggestions))
                return;

            setColumns(
                Object.keys(fields).map((item) => {
                    const field = getFieldFromCustomDimensionsAndFields(
                        item,
                        fieldsWithSuggestions,
                        customDimensionsFields,
                    );
                    const isAudienceBuilder =
                        generationStrategy ===
                        QueryGenerationStrategy.AUDIENCE_BUILDER;

                    const fieldName = field?.name ?? item;
                    const header =
                        field && isAudienceBuilder
                            ? isTableCalculation(field) ||
                              isCustomSqlDimension(field)
                                ? fieldName
                                : field?.label ?? fieldName
                            : item;
                    return {
                        accessorKey: item,
                        header,
                        cell: ({ getValue }) => {
                            const value = getValue();

                            return (
                                <AudiencePreviewCell<typeof value>
                                    value={value}
                                />
                            );
                        },
                        enableSorting: false,
                        size: 200,
                    };
                }),
            );
        }
    }, [
        projectUuid,
        t,
        fields,
        fieldsWithSuggestions,
        generationStrategy,
        customDimensionsFields,
    ]);

    return { columns, setColumns };
}
