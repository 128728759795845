import { Box } from '@mantine/core';
import { AudienceDescriptionMarkdown } from './AudienceDescriptionMarkdown';
interface SqlDescriptionProps {
    sqlExplaination: string;
}

const SqlDescription = ({ sqlExplaination }: SqlDescriptionProps) => {
    return (
        <Box className="mt-5 w-full flex justify-center">
            <Box className="w-[48rem]">
                <AudienceDescriptionMarkdown>
                    {sqlExplaination}
                </AudienceDescriptionMarkdown>
            </Box>
        </Box>
    );
};

export default SqlDescription;
