import ErrorState from '@components/common/ErrorState';
import PageSpinner from '@components/PageSpinner';
import { useActiveProjectUuid } from '@hooks/useActiveProject';
import { useProject } from '@hooks/useProject';
import { useProjects } from '@hooks/useProjects';
import { type FC } from 'react';
import { Navigate } from 'react-router';

const Projects: FC = () => {
    const { isInitialLoading, data, error } = useProjects();
    const { isLoading: isActiveProjectLoading, activeProjectUuid } =
        useActiveProjectUuid();
    const { isInitialLoading: isProjectDataLoading } =
        useProject(activeProjectUuid);

    if (!isInitialLoading && data && data.length === 0) {
        return <Navigate to="/no-access" />;
    }

    if (
        isInitialLoading ||
        isActiveProjectLoading ||
        !activeProjectUuid ||
        isProjectDataLoading
    ) {
        return <PageSpinner />;
    }

    if (error && error.error) {
        return <ErrorState error={error.error} />;
    }

    return <Navigate to={`/projects/${activeProjectUuid}/home`} />;
};

export default Projects;
