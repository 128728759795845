import { getCustomDimensionsFromActiveRelation } from '@components/Audience/Filters/FilterInputs/utils';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import {
    ContactFields,
    getUserIdColumnFromRelation,
} from '@components/Audience/utils';
import { JoinType } from '@lightdash/common';
import {
    ChatCircleText,
    Envelope,
    UserCircle,
    WhatsappLogo,
} from '@phosphor-icons/react';
import useProjectContext from '@providers/Project/useProjectContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useMemo } from 'react';

const useContactFieldOptions = () => {
    const { activeRelation, getTableRelation, activeRelationUuid } =
        useRelationContext();
    const { projectData } = useProjectContext();

    const userIdColumn = useMemo(() => {
        return getUserIdColumnFromRelation(activeRelation);
    }, [activeRelation]);

    const userIdColumnType = useMemo(() => {
        if (activeRelation) {
            const baseTableData =
                activeRelation.tables?.[activeRelation?.baseTable];
            if ('userId' in baseTableData) {
                return baseTableData.dimensions[baseTableData.userId].type;
            }
        }
    }, [activeRelation]);

    const contactFields = useMemo(() => {
        const projectContactFields =
            projectData?.attributes?.reachability ?? {};
        const result = [];
        result.push({
            label: ContactFields.USER_ID,
            fieldId: userIdColumn,
            icon: <UserCircle color="rgb(var(--color-blu-800))" />,
            type: userIdColumnType,
        });
        if (projectContactFields.email) {
            result.push({
                label: ContactFields.EMAIL,
                fieldId: `${projectContactFields.email.primary.tableName}_${projectContactFields.email.primary.dimensionName}`,
                icon: <Envelope color="rgb(var(--color-blu-800))" />,
            });
        }
        if (projectContactFields.whatsapp) {
            result.push({
                label: ContactFields.WHATSAPP,
                fieldId: `${projectContactFields.whatsapp.primary.tableName}_${projectContactFields.whatsapp.primary.dimensionName}`,
                icon: <WhatsappLogo color="rgb(var(--color-blu-800))" />,
            });
        }
        if (projectContactFields.sms) {
            result.push({
                label: ContactFields.SMS,
                fieldId: `${projectContactFields.sms.primary.tableName}_${projectContactFields.sms.primary.dimensionName}`,
                icon: <ChatCircleText color="rgb(var(--color-blu-800))" />,
            });
        }
        return result;
    }, [projectData?.attributes?.reachability, userIdColumn, userIdColumnType]);

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });

    const filteredRelation = getTableRelation([
        JoinType.one_one,
        JoinType.many_one,
    ]);

    const customDimensions = useMemo(
        () =>
            getCustomDimensionsFromActiveRelation(
                activeRelation,
                filteredRelation?.map((table) => table.name) ?? [],
            ),
        [activeRelation, filteredRelation],
    );

    return {
        contactFields,
        fieldsWithSuggestions,
        customDimensions,
        activeRelationUuid,
    };
};

export default useContactFieldOptions;
