import { subject } from '@casl/ability';
import { Can } from '@components/common/Authorization';
import ErrorState from '@components/common/ErrorState';
import PageSpinner from '@components/PageSpinner';
import { useCommunicationChannels } from '@hooks/useCommunicationChannels';
import { useProject } from '@hooks/useProject';
import { useProjects } from '@hooks/useProjects';
import { type OrganizationProject } from '@lightdash/common';
import useApp from '@providers/App/useApp';
import ProjectProvider from '@providers/Project/ProjectProvider';
import RelationLoadingState from '@providers/Relation/RelationLoadingState';
import React, { type ComponentProps, type FC, type ReactNode } from 'react';
import { Navigate, useLocation, useParams, type Route } from 'react-router';
import { RelationProvider } from '../../providers/Relation/RelationProvider';

interface AuthorizedProjectRouteProps {
    children: ReactNode;
    projects: OrganizationProject[] | undefined;
}

const AuthorizedProjectRoute: FC<AuthorizedProjectRouteProps> = ({
    children,
    projects,
}) => {
    const { projectUuid } = useParams<{ projectUuid?: string }>();
    const { organization } = useApp();
    const location = useLocation();
    const { isLoading, data: projectData } = useProject(projectUuid);
    const {
        isInitialLoading: communicationChannelsLoading,
        data: communicationChannels,
    } = useCommunicationChannels(
        projectData && !projectData.isBaseTableConfigured ? false : true,
    );

    if (isLoading || communicationChannelsLoading) {
        return <PageSpinner />;
    }
    if (!projectData) {
        // Try to find a suitable project for redirection in order of priority:
        // 1. Organization's default project
        // 2. First non-demo project
        // 3. First demo project
        const redirectProjectUuid =
            organization?.defaultProjectUuid ??
            projects?.find((project) => !project.isDemo)?.projectUuid ??
            projects?.find((project) => project.isDemo)?.projectUuid;

        // If we have a valid project UUID, redirect to its home page
        if (redirectProjectUuid) {
            return <Navigate to={`/projects/${redirectProjectUuid}/home`} />;
        }

        // If no suitable project is found, redirect to no-access page
        return <Navigate to="/no-access" />;
    }

    const rootProjectPath = `/projects/${projectUuid}`;
    const isRootProjectPath = location.pathname === rootProjectPath;
    if (isRootProjectPath) {
        return <Navigate to={`/projects/${projectUuid}/home`} />;
    }

    return (
        <ProjectProvider
            projectData={projectData}
            communicationChannels={communicationChannels}
        >
            <RelationProvider>{children}</RelationProvider>
        </ProjectProvider>
    );
};

const ProjectRoute: FC<
    React.PropsWithChildren<ComponentProps<typeof Route>>
> = ({ children }) => {
    const { user } = useApp();
    const { data: projects, isInitialLoading, isError, error } = useProjects();
    const { projectUuid } = useParams();

    if (isInitialLoading) {
        return <PageSpinner />;
    }

    if (isError && error) {
        return <ErrorState error={error.error} />;
    }

    if (!projects || projects.length <= 0) {
        return <Navigate to="/no-access" />;
    }

    return (
        <Can
            I="view"
            this={subject('Project', {
                organizationUuid: user.data?.organizationUuid,
                projectUuid: projectUuid,
            })}
            passThrough
        >
            {(isAllowed) => {
                return isAllowed ? (
                    <AuthorizedProjectRoute projects={projects}>
                        {
                            <>
                                {children}
                                <RelationLoadingState />
                            </>
                        }
                    </AuthorizedProjectRoute>
                ) : (
                    <Navigate to="/no-project-access" />
                );
            }}
        </Can>
    );
};

export default ProjectRoute;
