import { activeTabClass, tabClass } from '@components/ChannelTabs/utils';
import { SegmentedTimeFilterOptions } from '@components/common/SegmentedTimeFilter/types';
import { getTimestampForOption } from '@components/common/SegmentedTimeFilter/utils';
import TimeFilterMenu from '@components/common/TimeFilterMenu';
import { useLocale } from '@hooks/useLocale';
import { Box, Flex, Input, Stack, Tabs, Text } from '@mantine/core';
import { Info, MagnifyingGlass } from '@phosphor-icons/react';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { OUTER_CONTAINER_CLASS } from '../utils';
import InternalEventHistory from './InternalEventHistory';
import OtherEventHistory from './OtherEventHistory';
import { ProfilesActivityTabs } from './utils';

export const ProfileActivityInfo = () => {
    const { t } = useLocale();
    return (
        <Box className="px-2 pb-2 font-semibold border-b border-shade-6">
            <Flex
                gap={4}
                align="center"
                className="p-2 text-sm text-gray-800 border rounded-lg border-shade-6"
            >
                <Info weight="duotone" color="rgb(var(--color-gray-800))" />
                <Text className="">
                    {t('profiles_event_history.info_text')}
                </Text>
            </Flex>
        </Box>
    );
};

const ProfileActivity = () => {
    const { t } = useLocale();
    const [activeTab, setActiveTab] = useState<ProfilesActivityTabs>(
        ProfilesActivityTabs.CAMPAIGNS_AND_JOURNEYS,
    );
    const { profileId } = useParams<{ profileId: string }>();
    const [searchString, setSearchString] = useState('');
    const last7Days = getTimestampForOption(
        SegmentedTimeFilterOptions.LAST_7_DAYS,
    );
    const [timeFilter, setTimeFilter] = useState<{
        startTimestamp?: string;
        endTimestamp?: string;
    }>({
        startTimestamp: last7Days?.[0]
            ? new Date(last7Days?.[0]).toISOString()
            : undefined,
        endTimestamp: last7Days?.[1]
            ? new Date(last7Days?.[1]).toISOString()
            : undefined,
    });
    const [currentSelectedTimeFilter, setCurrentSelectedTimeFilter] = useState<
        SegmentedTimeFilterOptions | undefined
    >(SegmentedTimeFilterOptions.LAST_7_DAYS);
    const getTabClass = useCallback(
        (isActive: boolean) =>
            isActive
                ? `${activeTabClass} rounded-lg`
                : `${tabClass} rounded-lg`,
        [],
    );
    return (
        <Box className={`${OUTER_CONTAINER_CLASS} p-0`}>
            <Tabs
                variant="pills"
                value={activeTab}
                onTabChange={(value) =>
                    setActiveTab(value as ProfilesActivityTabs)
                }
            >
                <Stack spacing={8}>
                    <Flex align="center" justify="space-between">
                        <Tabs.List className="p-2 pb-0">
                            <Tabs.Tab
                                value={
                                    ProfilesActivityTabs.CAMPAIGNS_AND_JOURNEYS
                                }
                                className={getTabClass(
                                    activeTab ===
                                        ProfilesActivityTabs.CAMPAIGNS_AND_JOURNEYS,
                                )}
                            >
                                {t('profiles_builder.campaigns_and_journeys')}
                            </Tabs.Tab>
                            <Tabs.Tab
                                value={ProfilesActivityTabs.OTHER_EVENTS}
                                className={getTabClass(
                                    activeTab ===
                                        ProfilesActivityTabs.OTHER_EVENTS,
                                )}
                            >
                                {t('profiles_builder.other_sources')}
                            </Tabs.Tab>
                        </Tabs.List>
                        <Flex className="p-2" gap={8}>
                            <Input
                                placeholder={t(
                                    'profiles_event_history.search_placeholder',
                                )}
                                icon={
                                    <MagnifyingGlass
                                        weight="duotone"
                                        color="rgb(var(--color-gray-500))"
                                    />
                                }
                                size="xs"
                                value={searchString}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) => setSearchString(event.target.value)}
                            />

                            <TimeFilterMenu
                                onTimeFilterChange={(
                                    newTimeFilter,
                                    newFilter,
                                ) => {
                                    setTimeFilter({
                                        startTimestamp:
                                            newTimeFilter.startTimestamp
                                                ? new Date(
                                                      newTimeFilter.startTimestamp,
                                                  ).toISOString()
                                                : undefined,
                                        endTimestamp: newTimeFilter.endTimestamp
                                            ? new Date(
                                                  newTimeFilter.endTimestamp,
                                              ).toISOString()
                                            : undefined,
                                    });
                                    setCurrentSelectedTimeFilter(newFilter);
                                }}
                                filter={currentSelectedTimeFilter}
                            />
                        </Flex>
                    </Flex>
                    <ProfileActivityInfo />
                </Stack>
                <Tabs.Panel
                    value={ProfilesActivityTabs.CAMPAIGNS_AND_JOURNEYS}
                    pt="xs"
                >
                    <InternalEventHistory
                        searchString={searchString}
                        currentSelectedTimeFilter={currentSelectedTimeFilter}
                        timeFilter={timeFilter}
                    />
                </Tabs.Panel>
                <Tabs.Panel value={ProfilesActivityTabs.OTHER_EVENTS} pt="xs">
                    <OtherEventHistory
                        searchString={searchString}
                        currentSelectedTimeFilter={currentSelectedTimeFilter}
                        timeFilter={timeFilter}
                        profileId={profileId}
                    />
                </Tabs.Panel>
            </Tabs>
        </Box>
    );
};

export default ProfileActivity;
