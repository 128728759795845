import { Box, Card as MantineCard } from '@mantine/core';
import React, { useMemo } from 'react';
import CardHeader from '../CardHeader';

type CardProps = {
    inputMode: boolean;
    handleEdit: () => void;
    handleSave: () => void;
    handleCancel: () => void;
    isUpdating: boolean;
    isEditAllowed: boolean;
    children: React.ReactNode;
    headerLabel: string;
    headerDescription: string;
};

const Card = ({
    inputMode,
    handleEdit,
    handleSave,
    handleCancel,
    isUpdating,
    isEditAllowed,
    children,
    headerLabel,
    headerDescription,
}: CardProps) => {
    const outerBorderColor = useMemo(
        () => (inputMode ? 'border-blu-800/40' : 'border-shade-4'),
        [inputMode],
    );

    const innerBorderColor = useMemo(
        () => (inputMode ? 'border-blu-800' : 'border-gray-50'),
        [inputMode],
    );

    return (
        <Box className={`border ${outerBorderColor} rounded-2xl p-0.5 mt-4`}>
            <MantineCard
                className={`border ${innerBorderColor} rounded-1xl`}
                padding={3}
            >
                <CardHeader
                    title={headerLabel}
                    subtitle={headerDescription}
                    isActive={inputMode}
                    handlePrimaryButtonClick={handleSave}
                    handleSecondaryButtonClick={handleCancel}
                    isUpdating={isUpdating}
                    isEditAllowed={isEditAllowed}
                    handleEditClick={handleEdit}
                    primaryButtonLabel={undefined}
                    secondaryButtonLabel={undefined}
                />
                <MantineCard.Section py="sm" px="sm">
                    {children}
                </MantineCard.Section>
            </MantineCard>
        </Box>
    );
};

export default Card;
