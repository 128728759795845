import { Box, Button, Flex, Skeleton, Stack, Text } from '@mantine/core';
import {
    CheckCircle,
    PaperPlaneTilt,
    Pencil,
    ProhibitInset,
    Table,
    UsersThree,
} from '@phosphor-icons/react';
import React, { useMemo, useState } from 'react';

import AudiencePreviewModal from '@components/Audience/AudiencePreview/AudiencePreviewData';
import { useGetAudiencePreview } from '@hooks/useCampaigns';
import { useGetSuppressionConfig } from '@hooks/useDeliveryControlsApi';
import { useAudiences } from '@hooks/useGetAudience';
import { useLocale } from '@hooks/useLocale';
import {
    CommunicationChannel,
    QueryGenerationStrategy,
} from '@lightdash/common';
import { getFormattedCount } from '@utils/index';
import { ButtonVariant } from '../../../../../mantineTheme';
import SuppressionRuleTag from '../SuppressionRuleTag';

type AudienceProps = {
    handleEdit: () => void;
    sendList: string[] | undefined;
    suppressList: string[] | undefined;
    channel: CommunicationChannel;
    count: number | undefined;
    filename?: string;
    showEdit?: boolean;
    showPreview?: boolean;
};

const Audience = ({
    handleEdit,
    sendList,
    suppressList,
    channel,
    count,
    filename,
    showEdit = true,
    showPreview = true,
}: AudienceProps) => {
    const { t } = useLocale();
    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const { data: sendListAudiences, isLoading: isSendListLoading } =
        useAudiences({
            query: `idIn=${sendList?.join(',')}`,
            perPage: sendList?.length ?? 99,
            currentPage: 1,
            polling: false,
            enabled: !!sendList?.length,
        });

    const { data: suppressListAudiences, isLoading: isSuppressListLoading } =
        useAudiences({
            query: `idIn=${suppressList?.join(',')}`,
            perPage: suppressList?.length ?? 99,
            currentPage: 1,
            polling: false,
            enabled: !!suppressList?.length,
        });

    const { data: suppressionConfig } = useGetSuppressionConfig({
        enabled: true,
    });
    const {
        mutateAsync: getAudiencePreview,
        isLoading: isLoadingAudiencePreview,
        data: previewAudienceData,
    } = useGetAudiencePreview();

    const previewInitialColumns = useMemo(() => {
        if (!previewAudienceData) return [];
        return Object.keys(previewAudienceData.fields);
    }, [previewAudienceData]);

    const globalSuppressedAudiences = useMemo(() => {
        if (suppressionConfig) {
            return suppressionConfig.config.mappings
                .filter(
                    (item) =>
                        item.channel === channel ||
                        item.channel === CommunicationChannel.ANY,
                )
                .map((item) => item.audienceId);
        }
        return [];
    }, [channel, suppressionConfig]);

    const renderSendList = useMemo(() => {
        if (isSendListLoading) {
            return <Skeleton width={120} height={20} />;
        }
        if (sendListAudiences && sendListAudiences.data?.length) {
            return (
                <Stack spacing={8}>
                    {sendListAudiences.data.map((item) => (
                        <Flex align={'center'} gap={4} key={item.id}>
                            <UsersThree color="rgb(var(--color-pink-800))" />
                            <Text className="text-gray-800 font-medium">
                                {item.name}
                            </Text>
                            {globalSuppressedAudiences.includes(item.id) && (
                                <SuppressionRuleTag />
                            )}
                        </Flex>
                    ))}
                </Stack>
            );
        }
        if (filename) {
            return (
                <Text className="text-gray-800 font-medium">{filename}</Text>
            );
        }
        return <Text className="text-gray-800 font-medium">-</Text>;
    }, [
        isSendListLoading,
        sendListAudiences,
        globalSuppressedAudiences,
        filename,
    ]);

    const renderSuppressList = useMemo(() => {
        if (isSuppressListLoading) {
            return <Skeleton width={120} height={20} />;
        }
        if (suppressListAudiences && suppressListAudiences.data?.length) {
            return (
                <Stack spacing={8}>
                    {suppressListAudiences.data.map((item) => (
                        <Flex align={'center'} gap={4} key={item.id}>
                            <UsersThree color="rgb(var(--color-pink-800))" />
                            <Text className="text-gray-800 font-medium">
                                {item.name}
                            </Text>
                            {globalSuppressedAudiences.includes(item.id) && (
                                <SuppressionRuleTag />
                            )}
                        </Flex>
                    ))}
                </Stack>
            );
        }
        return <Text className="text-gray-800 font-medium">-</Text>;
    }, [
        isSuppressListLoading,
        suppressListAudiences,
        globalSuppressedAudiences,
    ]);

    const renderCount = useMemo(() => {
        if (count) {
            return `${getFormattedCount(count)} user${count > 1 ? 's' : ''}`;
        }
        return '-';
    }, [count]);

    const handlePreview = async () => {
        try {
            await getAudiencePreview({
                channel,
                campaignAudienceConfig: {
                    config: {
                        in: sendList ?? [],
                        notIn: suppressList ?? [],
                    },
                },
                previewConfig: {
                    dimensions: [],
                    limit: 50,
                    metrics: [],
                },
            });
            setOpenPreviewModal(true);
        } catch (error) {}
    };

    return (
        <Box className="border-base rounded-lg p-3 pb-[18px]">
            <Flex justify={'space-between'} className="mb-5">
                <Flex gap={6} align={'center'}>
                    <CheckCircle color="rgb(var(--color-blu-800))" />
                    <Text className="text-base text-gray-700 font-semibold">
                        {t('audience')}
                    </Text>
                </Flex>
                <Flex gap={8}>
                    {showPreview && (
                        <Button
                            loading={isLoadingAudiencePreview}
                            onClick={handlePreview}
                            leftIcon={
                                <Table color="rgb(var(--color-gray-600))" />
                            }
                            variant={ButtonVariant.OUTLINED}
                        >
                            {t('audience.preview.title')}
                        </Button>
                    )}
                    {showEdit && (
                        <Button
                            leftIcon={
                                <Pencil color="rgb(var(--color-gray-600))" />
                            }
                            variant={ButtonVariant.OUTLINED}
                            onClick={handleEdit}
                        >
                            {t('common.edit')}
                        </Button>
                    )}
                </Flex>
            </Flex>
            <Stack spacing={'lg'} className="w-full">
                <Flex className="w-full">
                    <Flex align={'center'} gap={4} className="w-[30%]">
                        <Text className="text-gray-600 font-semibold uppercase">
                            {t('campaign_builder.audience.estimated_reach')}
                        </Text>
                    </Flex>
                    <Text className="text-gray-800 font-medium">
                        {renderCount}
                    </Text>
                </Flex>
                <Flex className="w-full" align={'flex-start'}>
                    <Flex align={'center'} gap={4} className="w-[30%]">
                        <PaperPlaneTilt color="rgb(var(--color-gray-600))" />
                        <Text className="text-gray-600 font-semibold uppercase">
                            {t('campaign_builder.audience.send_list')}
                        </Text>
                    </Flex>
                    <Stack spacing={8}>{renderSendList}</Stack>
                </Flex>
                <Flex className="w-full" align={'flex-start'}>
                    <Flex align={'center'} gap={4} className="w-[30%]">
                        <ProhibitInset color="rgb(var(--color-gray-600))" />
                        <Text className="text-gray-600 font-semibold uppercase">
                            {t('campaign_builder.audience.suppress_list')}
                        </Text>
                    </Flex>
                    <Stack spacing={8}>{renderSuppressList}</Stack>
                </Flex>
            </Stack>
            {openPreviewModal && previewAudienceData && (
                <AudiencePreviewModal
                    handlePreview={undefined}
                    isValidQuery={undefined}
                    footerRightSection={
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={() => setOpenPreviewModal(false)}
                        >
                            {t('password_modal.secondary_btn_text')}
                        </Button>
                    }
                    showPropertySelect={true}
                    bottomSection={null}
                    isEditMode={true}
                    opened={openPreviewModal}
                    close={() => setOpenPreviewModal(false)}
                    data={previewAudienceData.rows}
                    fields={previewAudienceData.fields}
                    generationStrategy={
                        QueryGenerationStrategy.AUDIENCE_BUILDER
                    }
                    initialColumns={{
                        previewFields: previewInitialColumns,
                    }}
                    // audienceId={selectedCampaignAudience.id}
                    isApiCallTimeout={undefined}
                    onPropertySelect={undefined}
                />
            )}
        </Box>
    );
};

export default Audience;
