import { Box, Divider, Text } from '@mantine/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import EventFilterGroup from '@components/Campaigns/EventFilterGroup';
import Checkbox from '@components/common/Checkbox';
import InputErrorText from '@components/common/InputErrorText';
import TimeInputWithOptions from '@components/common/Time/TimeInputWithOptions';
import { useLocale } from '@hooks/useLocale';
import { PeriodType } from '@lightdash/common';
import useCampaignContext from '@providers/Campaign/useCampaignContext';

type ConversionEventProps = {
    enableConversionTracking: boolean;
    setEnableConversionTracking: (payload: boolean) => void;
};
const ConversionEvent = ({
    enableConversionTracking,
    setEnableConversionTracking,
}: ConversionEventProps) => {
    const { t } = useLocale();
    const [errorsValidation, setErrorsValidation] = useState<{
        eventError: string;
    }>({
        eventError: '',
    });
    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload } = state;
    const { updateConversionConfig } = actions;

    useEffect(() => {
        if (campaignPayload.conversionConfig) {
            setEnableConversionTracking(true);
        }
    }, [campaignPayload.conversionConfig, setEnableConversionTracking]);

    const onDurationChange = useCallback(
        (duration: number | undefined, granularityValue: PeriodType) => {
            updateConversionConfig({
                events: [
                    {
                        ...campaignPayload.conversionConfig?.events[0],
                        id:
                            campaignPayload.conversionConfig?.events[0].id ??
                            uuidv4(),
                        eventName:
                            campaignPayload.conversionConfig?.events[0]
                                .eventName ?? '',
                        eventSource:
                            campaignPayload.conversionConfig?.events[0]
                                .eventSource ?? '',
                        deadline: duration,
                        uiConfig: {
                            timeGranularity: granularityValue,
                        },
                    },
                ],
            });
        },
        [updateConversionConfig, campaignPayload.conversionConfig?.events],
    );

    const errorMessage = useMemo(() => {
        if (campaignPayload.conversionConfig?.events[0].deadline === null) {
            return t('common.error.empty_text_input');
        }
        if (campaignPayload.conversionConfig?.events[0].deadline === 0) {
            return t('duration_delay.enter_a_valid_value');
        }
        return '';
    }, [campaignPayload.conversionConfig?.events, t]);

    return (
        <Box>
            <Text className="text-lg font-medium text-gray-800">
                {t('campaign_builder.conversion_tracking')}
            </Text>
            <Checkbox
                className="mt-2"
                label="Enable conversion tracking"
                checked={enableConversionTracking}
                onChange={(event) => {
                    setEnableConversionTracking(event.currentTarget.checked);
                    if (!event.currentTarget.checked) {
                        updateConversionConfig(undefined);
                    }
                }}
            />
            {enableConversionTracking && (
                <Box className="mt-4">
                    <EventFilterGroup
                        eventConfig={{
                            eventName:
                                campaignPayload.conversionConfig?.events[0]
                                    .eventName || '',
                            eventSource:
                                campaignPayload.conversionConfig?.events[0]
                                    .eventSource || '',
                            eventChannel: undefined,
                        }}
                        setEvent={(eventName, eventSource) => {
                            updateConversionConfig({
                                events: [
                                    {
                                        id:
                                            campaignPayload.conversionConfig
                                                ?.events[0].id ?? uuidv4(),
                                        eventName,
                                        eventSource,
                                        deadline:
                                            campaignPayload.conversionConfig
                                                ?.events[0].deadline,
                                    },
                                ],
                            });
                            setErrorsValidation({
                                ...errorsValidation,
                                eventError: '',
                            });
                        }}
                        filters={
                            campaignPayload.conversionConfig?.events[0]
                                .filterConfig
                        }
                        setFilters={(filters) => {
                            updateConversionConfig({
                                events: [
                                    {
                                        ...campaignPayload.conversionConfig
                                            ?.events[0],
                                        id:
                                            campaignPayload.conversionConfig
                                                ?.events[0].id ?? uuidv4(),
                                        eventName:
                                            campaignPayload.conversionConfig
                                                ?.events[0].eventName ?? '',
                                        eventSource:
                                            campaignPayload.conversionConfig
                                                ?.events[0].eventSource ?? '',
                                        filterConfig: filters,
                                    },
                                ],
                            });
                        }}
                    />
                    {errorsValidation.eventError && (
                        <InputErrorText
                            className="mb-2 text-sm font-medium"
                            value={errorsValidation.eventError}
                        />
                    )}
                    <Text className="mt-4 mb-2 text-sm font-medium text-gray-800">
                        {t('campaign_builder.tracking_window')}
                    </Text>
                    <TimeInputWithOptions
                        selectedDuration={
                            campaignPayload.conversionConfig?.events[0].deadline
                        }
                        onDurationChange={onDurationChange ?? (() => {})}
                        selectedGranularity={
                            campaignPayload.conversionConfig?.events[0].uiConfig
                                ?.timeGranularity ?? PeriodType.MINUTE
                        }
                        granularityOptions={[
                            PeriodType.MINUTE,
                            PeriodType.HOUR,
                            PeriodType.DAY,
                            PeriodType.WEEK,
                            PeriodType.MONTH,
                        ]}
                        // focusOnMount={true}
                        onBlur={(value) => {
                            if (Number.isNaN(value) && onDurationChange) {
                                onDurationChange(
                                    null as unknown as number,
                                    PeriodType.DAY,
                                );
                            }
                        }}
                        error={errorMessage}
                        disabled={false}
                    />

                    <Divider className="mt-4 border-gray-250 w-[50%]" />
                </Box>
            )}
        </Box>
    );
};

export default ConversionEvent;
