import { Box, Flex } from '@mantine/core';
import { type ManagerProps } from '../type';

type ManagerTabsProps<TData, T> = Pick<
    ManagerProps<TData, T>,
    'tabs' | 'selectedTab' | 'onTabChange'
>;

const ManagerTabs = <TData, T>({
    tabs,
    selectedTab,
    onTabChange,
}: ManagerTabsProps<TData, T>) => {
    if (!tabs || tabs.length === 0) return null;
    return (
        <Flex className="gap-2 px-1 py-2">
            {tabs.map((tab, index) => (
                <Box
                    key={index}
                    className={`text-sm cursor-pointer px-2 py-1 font-semibold rounded-lg text-gray-700 hover:bg-gray-50 hover:text-gray-800 ${
                        tab.key === selectedTab
                            ? 'bg-shade-6 text-gray-800 hover:text-gray-800 hover:bg-shade-6'
                            : ''
                    }`}
                    onClick={() => onTabChange?.(tab.key)}
                >
                    {tab.label}
                </Box>
            ))}
        </Flex>
    );
};

export default ManagerTabs;
