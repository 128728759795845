import InputErrorText from '@components/common/InputErrorText';
import { useLocale } from '@hooks/useLocale';
import {
    Box,
    Flex,
    Overlay,
    Stack,
    Text,
    Textarea as MantineTextarea,
    type TextareaProps as MantineTextareaProps,
} from '@mantine/core';
import { Sparkle } from '@phosphor-icons/react';
import React, { useMemo } from 'react';

interface TextAreaProps extends MantineTextareaProps {
    error?: React.ReactNode;
    description?: React.ReactNode;
    errorClass?: string;
    aiGeneratedData?: string;
    isAiGeneratingData?: boolean;
    aiGenerationCustomClass?: string;
    isOptional?: boolean;
}

const getTextAreaIcon = (
    icon: MantineTextareaProps['icon'],
    isAiGeneratingData: boolean | undefined,
    aiGeneratedData: string | undefined,
    value: MantineTextareaProps['value'],
) => {
    if ((aiGeneratedData && value === aiGeneratedData) || isAiGeneratingData) {
        return <Sparkle color="rgb(var(--color-purple))" />;
    }
    if (icon) {
        return icon;
    }
    return null;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {
            error,
            description,
            errorClass,
            aiGeneratedData,
            isAiGeneratingData,
            onChange,
            value: propValue,
            aiGenerationCustomClass,
            isOptional = false,
            label,
            ...rest
        },
        ref,
    ) => {
        const { t } = useLocale();
        const optionalLabel = useMemo(() => {
            if (isOptional) {
                return (
                    <Flex className="gap-1">
                        <Text>{label}</Text>
                        <Text className="text-sm font-medium text-gray-600">
                            ({t('common.optional')})
                        </Text>
                    </Flex>
                );
            }
            return label;
        }, [isOptional, label, t]);

        return (
            <Stack className="gap-1.5">
                <Box className="relative">
                    <MantineTextarea
                        icon={getTextAreaIcon(
                            rest.icon,
                            isAiGeneratingData,
                            aiGeneratedData,
                            propValue,
                        )}
                        styles={() =>
                            aiGeneratedData && aiGeneratedData === propValue
                                ? {
                                      input: {
                                          border: '1px solid rgb(var(--color-purple))',
                                          ':focus-within': {
                                              border: '1px solid rgb(var(--color-purple))',
                                          },
                                      },
                                  }
                                : {}
                        }
                        value={propValue}
                        label={optionalLabel}
                        onChange={onChange}
                        ref={ref}
                        description={
                            <Text className="text-sm font-medium text-gray-500">
                                {description}
                            </Text>
                        }
                        {...rest}
                    />
                    {isAiGeneratingData && (
                        <Overlay
                            className={`h-5 top-8 ml-8 mr-3 bg-white ${aiGenerationCustomClass}`}
                        >
                            <Box className="w-full h-full bg-purple/20 animate-pulse" />
                        </Overlay>
                    )}
                </Box>
                {error && (
                    <InputErrorText className={errorClass} value={error} />
                )}
            </Stack>
        );
    },
);

export default TextArea;
