import { WarehouseTypes } from '../types/projects';

export const SUB_QUERY_PREFIX = 'srt_sub_query:';

export const getSortmentWarehouseName = (
    name: string,
    whType: WarehouseTypes,
) => {
    switch (whType) {
        case WarehouseTypes.SNOWFLAKE:
            return name.toUpperCase();
        case WarehouseTypes.REDSHIFT:
        case WarehouseTypes.BIGQUERY:
            return name.toLowerCase();
        default:
            return name.toLowerCase();
    }
};
