import {
    createJourneyFilterGroupFromFilterableField,
    getBranchesInSplitBlock,
    getLabelForChildOfSplitBlock,
    isExperimentBlock,
    isSplitBlock,
} from '@components/Journeys/Builder/ControlPanel/Actions/Split/utils';
import {
    ControlPanel,
    JourneyEdgeEnum,
    JourneyNodeEnum,
    type JourneyNodeData,
} from '@components/Journeys/Builder/types';
import {
    getActionSeedData,
    getAllChildNodeIds,
    getEdgeId,
} from '@components/Journeys/Builder/utils';
import {
    ActionType as JourneyActionType,
    BranchConcurrencyTypes,
    BranchConditionalTypes,
    generateShortUUID,
    type Branch,
    type ExperimentAction,
    type JourneyCreatePayload,
    type JourneyNode,
} from '@lightdash/common';
import { type JourneyReducerState } from '@providers/Journey/types';
import { t as translate } from 'i18next';
import { type Edge, type Node } from 'reactflow';

interface AddGhostNodeParams {
    nodeId: string;
    branch: Branch | undefined;
    state: JourneyReducerState;
    edgeName: string | undefined;
    destinationNodeId: string | undefined;
    branchIndex: number | undefined;
    ghostNodeId: string | undefined;
    isVisible?: boolean;
}

export function getGhostNodeAndEdge({
    nodeId,
    branch,
    state,
    edgeName,
    destinationNodeId,
    branchIndex,
    ghostNodeId,
    isVisible,
}: AddGhostNodeParams): JourneyReducerState {
    const sourceNode = state.nodes.find((node) => node.id === nodeId);
    if (!sourceNode) return state;

    const parentJourneyNodeData = state.journeyPayload.config?.nodes.find(
        (node) => node.id === nodeId,
    );
    if (!parentJourneyNodeData) return state;

    const everyOneElseNodeId = state.edges.find(
        (edge) =>
            edge.source === nodeId && edge.type === JourneyEdgeEnum.DEFAULT,
    )?.target;

    const isEveryOneElseNodeInJourneyConfig =
        state.journeyPayload.config!.nodes.find(
            (node) => node.id === everyOneElseNodeId,
        );

    const everyOneElseEdge = state.edges.find(
        (edge) =>
            edge.source === nodeId && edge.type === JourneyEdgeEnum.DEFAULT,
    );

    const newGhostNodeId =
        ghostNodeId ?? (branch ? branch.destination : generateShortUUID());

    const existingGhostNode = state.nodes.some(
        (node) => node.id === newGhostNodeId,
    );
    if (existingGhostNode) return state;
    const isSourceExperimentBlock = isExperimentBlock(parentJourneyNodeData);

    const isDefaultBranch = branch?.isDefault ?? false;
    const ghostNode: Node<JourneyNodeData> = {
        id: newGhostNodeId,
        position: {
            x: sourceNode.position.x + (isDefaultBranch ? 200 : 100),
            y: sourceNode.position.y,
        },
        type: JourneyNodeEnum.GHOSTNODE,
        data: {
            type: JourneyNodeEnum.GHOSTNODE,
            nodeId: newGhostNodeId,
            isExperiment: isSourceExperimentBlock,
            isVisible: isVisible,
        },
    };

    const newEdge: Edge = {
        id: getEdgeId(nodeId, newGhostNodeId),
        source: nodeId,
        target: newGhostNodeId,
        type: JourneyEdgeEnum.BRANCHING,
        label: getLabelForChildOfSplitBlock(
            state.nodes,
            nodeId,
            newGhostNodeId,
            edgeName,
            branchIndex,
            state.edges,
        ),
        style: isSourceExperimentBlock
            ? {
                  stroke: 'rgb(var(--color-pink-800)/0.40)',
              }
            : {},
    };

    const branchesLength = getBranchesInSplitBlock({
        edges: state.edges,
        nodes: state.nodes,
        parentNodeId: nodeId,
        includeEveryOneElse: false,
    });
    const updatedEveryOneElseEdge = {
        ...everyOneElseEdge,
        label: isEveryOneElseNodeInJourneyConfig
            ? `${branchesLength.length + 1} ${
                  edgeName ??
                  translate('journey_builder.split_every_one_else_label')
              }`
            : `${branchesLength.length + 2} ${
                  edgeName ??
                  translate('journey_builder.split_every_one_else_label')
              }`,
    };

    const nonDefaultBranches =
        parentJourneyNodeData.branchConfig?.children.branches.filter(
            (eachBranch) => !eachBranch.isDefault,
        ) ?? [];

    const defaultBranches =
        parentJourneyNodeData.branchConfig?.children.branches.filter(
            (eachBranch) => eachBranch.isDefault,
        ) ?? [];

    const updatedBranchConfig = {
        ...parentJourneyNodeData.branchConfig,
        children: {
            ...parentJourneyNodeData.branchConfig?.children,
            branches: isSourceExperimentBlock
                ? parentJourneyNodeData.branchConfig?.children.branches.length
                    ? parentJourneyNodeData.branchConfig?.children.branches[0]
                    : []
                : [
                      ...nonDefaultBranches.filter(
                          (eachBranch) =>
                              JSON.stringify(eachBranch.conditions) !==
                              JSON.stringify(branch?.conditions),
                      ),
                      //   {
                      //       destination: ghostNodeId,
                      //       conditions: branch
                      //           ? branch.conditions
                      //           : createJourneyFilterGroupFromFilterableField(
                      //                 state.splitActiveFields[nodeId]?.field,
                      //                 state.splitActiveFields[nodeId]
                      //                     ?.isJourneyField,
                      //             ),
                      //       isDefault: isDefaultBranch,
                      //   },
                      ...defaultBranches,
                  ],
        },
    };

    const updatedNodes = state.journeyPayload.config!.nodes.map((node) =>
        node.id === nodeId
            ? { ...node, branchConfig: updatedBranchConfig }
            : node,
    );

    const updatedNodesInReactFlow = [...state.nodes, ghostNode].filter(
        (node) => node.id !== everyOneElseNodeId,
    );

    const everyOneElseNode = [...state.nodes, ghostNode].find(
        (node) => node.id === everyOneElseNodeId,
    );

    const updatedEdges = everyOneElseEdge
        ? [
              ...state.edges.filter(
                  (edge) =>
                      edge.id !== newEdge.id && edge.id !== everyOneElseEdge.id,
              ),
              newEdge,
              updatedEveryOneElseEdge,
          ]
        : [...state.edges.filter((edge) => edge.id !== newEdge.id), newEdge];

    if (destinationNodeId) {
        updatedEdges.push({
            id: getEdgeId(newGhostNodeId, destinationNodeId),
            source: newGhostNodeId,
            target: destinationNodeId,
            type: JourneyEdgeEnum.BRANCHING,
            style: isSourceExperimentBlock
                ? {
                      stroke: 'rgb(var(--color-pink-800)/0.40)',
                  }
                : {},
        });
    }

    return {
        ...state,
        edges: updatedEdges as Edge[],
        nodes: [
            ...updatedNodesInReactFlow,
            ...(everyOneElseNode ? [everyOneElseNode] : []),
        ],
        journeyPayload: {
            ...state.journeyPayload,
            config: {
                ...state.journeyPayload.config,
                nodes: updatedNodes as JourneyNode[],
            },
        },
    };
}

interface AddCircuitParams {
    state: JourneyReducerState;
    targetNodeId: string;
    actionPayload: JourneyNode;
}
const addCircuit = ({
    state,
    targetNodeId,
    actionPayload,
}: AddCircuitParams): JourneyReducerState => {
    if (!actionPayload) return state;
    const targetNode = state.nodes.find((node) => node.id === targetNodeId);
    if (!targetNode) return state;

    const totalExperimentVariants = (
        actionPayload.actions.find(
            (action) => action.type === JourneyActionType.EXPERIMENT,
        ) as ExperimentAction
    )?.config?.experimentConfig?.variantConfig.map(
        (variant) => variant.variantId,
    );

    if (!totalExperimentVariants) return state;

    const destinationGhostNodeId = generateShortUUID();
    const destinationGhostNode: Node<JourneyNodeData> = {
        id: destinationGhostNodeId,
        position: {
            x: targetNode.position.x,
            y: targetNode.position.y,
        },
        type: JourneyNodeEnum.GHOSTNODE,
        data: {
            type: JourneyNodeEnum.GHOSTNODE,
            nodeId: destinationGhostNodeId,
        },
        selected: false,
    };

    const {
        ghostNodes,
        ghostEdges,
    }: { ghostNodes: Node<JourneyNodeData>[]; ghostEdges: Edge[] } =
        totalExperimentVariants
            .map((parallelBranchId, index) => {
                const ghostNode: Node<JourneyNodeData> = {
                    id: parallelBranchId,
                    position: {
                        x: targetNode.position.x + 100 * (index + 1),
                        y: targetNode.position.y,
                    },
                    type: JourneyNodeEnum.GHOSTNODE,
                    data: {
                        type: JourneyNodeEnum.GHOSTNODE,
                        nodeId: parallelBranchId,
                        isExperiment: true,
                    },
                    selected: false,
                };

                const ghostEdge: Edge[] = [
                    {
                        id: getEdgeId(targetNode.id, parallelBranchId),
                        source: targetNode.id,
                        target: parallelBranchId,
                        type: JourneyEdgeEnum.BRANCHING,
                        label: `${index + 1} ${translate('common.path', {
                            index: index + 1,
                        })}`,
                        style: {
                            stroke: 'rgb(var(--color-pink-800)/0.40)',
                        },
                    },
                    {
                        id: getEdgeId(parallelBranchId, destinationGhostNodeId),
                        source: parallelBranchId,
                        target: destinationGhostNodeId,
                        type: JourneyEdgeEnum.BRANCHING,
                        style: {
                            stroke: 'rgb(var(--color-pink-800)/0.40)',
                        },
                    },
                ];

                return { ghostNode, ghostEdge };
            })
            .reduce(
                (acc, { ghostNode, ghostEdge }) => {
                    acc.ghostNodes.push(ghostNode);
                    acc.ghostEdges.push(...ghostEdge);
                    return acc;
                },
                { ghostNodes: [], ghostEdges: [] } as {
                    ghostNodes: Node<JourneyNodeData>[];
                    ghostEdges: Edge[];
                },
            );

    return {
        ...state,
        nodes: [...state.nodes, ...ghostNodes, destinationGhostNode],
        edges: [...state.edges, ...ghostEdges],
    };
};

interface AddNodeParams {
    state: JourneyReducerState;
    blockId: string;
    reactFlowNodeId: string;
}

export function addNodeUtil({
    state,
    blockId,
    reactFlowNodeId,
}: AddNodeParams) {
    if (!blockId || !reactFlowNodeId) return state;
    const targetNode = state.nodes.find((node) => node.id === reactFlowNodeId);
    if (!targetNode) return state;

    const { nodes, edges } = state;
    const targetNodeIndex = nodes.findIndex(
        (node) => node.id === reactFlowNodeId,
    );

    let filteredNodes = nodes
        .filter((node) => node.id !== reactFlowNodeId)
        .map((node) => ({ ...node, selected: false }));

    const blockData = state.blocksList.find((block) => block.id === blockId);
    if (!blockData) return state;

    const newNodeId = generateShortUUID();
    const blockActions = blockData?.actions ?? [];

    const newNode: Node<JourneyNodeData> = {
        id: newNodeId,
        position: targetNode.position,
        type: JourneyNodeEnum.BLOCK,
        data: {
            type: JourneyNodeEnum.BLOCK,
            nodeId: newNodeId,
            blockId: blockData?.id,
            isExperiment: Boolean(targetNode.data.isExperiment),
        },
        selected: true,
    };

    const updatedReactNodes = [
        ...filteredNodes.slice(0, targetNodeIndex),
        newNode,
        ...filteredNodes.slice(targetNodeIndex),
    ];

    const parentEdge = edges.find((edge) => edge.target === reactFlowNodeId);
    const parentNodeId = parentEdge ? parentEdge.source : null;
    const parentNode = state.nodes.find((node) => node.id === parentNodeId);
    const parentJourneyNodeData = state.journeyPayload.config?.nodes.find(
        (node) => node.id === parentNodeId,
    );
    let updatedEdges = edges.map((edge) => {
        if (edge.source === reactFlowNodeId) {
            return {
                ...edge,
                source: newNodeId,
                id: getEdgeId(newNodeId, edge.target),
            };
        }
        if (edge.target === reactFlowNodeId) {
            return {
                ...edge,
                target: newNodeId,
                id: getEdgeId(edge.source, newNodeId),
            };
        }
        return edge;
    });

    const childNodeId = edges.find(
        (edge) => edge.source === reactFlowNodeId,
    )?.target;
    const childNode = state.nodes.find((node) => node.id === childNodeId);

    const actionPayload: JourneyNode = {
        id: newNodeId,
        title: blockData?.title ?? '',
        description: blockData?.description ?? '',
        actions: getActionSeedData(blockActions),
        metadata: {
            blockId: blockData?.id,
        },
        branchConfig: {
            type: BranchConditionalTypes.IFIF,
            children: {
                type:
                    blockActions.some(
                        (eachAction) =>
                            eachAction.actionType === JourneyActionType.SPLIT,
                    ) ||
                    (blockActions.length >= 1 &&
                        blockActions[0].config?.allowEveryoneElse)
                        ? BranchConcurrencyTypes.PARALLEL
                        : BranchConcurrencyTypes.SEQUENTIAL,
                branches:
                    childNodeId &&
                    childNode?.data.type === JourneyNodeEnum.BLOCK
                        ? [{ destination: childNodeId, isDefault: false }]
                        : [],
            },
        },
    };

    const filteredNodesConfig = (
        state.journeyPayload.config?.nodes ?? []
    ).filter((node) => node.id !== reactFlowNodeId);

    const updatedConfigNodes =
        parentNode?.data.type === JourneyNodeEnum.TRIGGER
            ? [actionPayload, ...filteredNodesConfig]
            : [...filteredNodesConfig, actionPayload];

    let updatedNodes = updatedConfigNodes.map((node) => {
        if (node.id === parentNodeId) {
            const newBranch: Branch = {
                destination: newNodeId,
                isDefault:
                    parentEdge?.type === JourneyEdgeEnum.DEFAULT ? true : false,
            };

            let updatedBranchConfig = parentJourneyNodeData?.branchConfig;
            let updatedNodeConfig = parentJourneyNodeData;
            if (isSplitBlock(parentJourneyNodeData)) {
                const branches =
                    parentJourneyNodeData?.branchConfig?.children.branches;

                if (
                    targetNode.data.type === JourneyNodeEnum.PLACEHOLDER &&
                    parentEdge?.type === JourneyEdgeEnum.DEFAULT
                ) {
                    updatedBranchConfig = {
                        type: BranchConditionalTypes.IFIF,
                        children: {
                            type: BranchConcurrencyTypes.PARALLEL,
                            branches: [...(branches ?? []), newBranch],
                        },
                    };
                } else {
                    const targetedNode = branches?.find(
                        (eachBranch) =>
                            eachBranch.destination === reactFlowNodeId,
                    );
                    if (targetedNode) {
                        updatedBranchConfig = {
                            type: BranchConditionalTypes.IFIF,
                            children: {
                                type: BranchConcurrencyTypes.PARALLEL,
                                branches: [
                                    ...(branches?.map((eachBranch) => ({
                                        ...eachBranch,
                                        destination:
                                            eachBranch.destination ===
                                            reactFlowNodeId
                                                ? newNodeId
                                                : eachBranch.destination,
                                    })) ?? []),
                                ],
                            },
                        };
                    } else {
                        updatedBranchConfig = {
                            type: BranchConditionalTypes.IFIF,
                            children: {
                                type: BranchConcurrencyTypes.PARALLEL,
                                branches: [
                                    ...(branches ?? []),
                                    {
                                        ...newBranch,
                                        destination: newNodeId,
                                        conditions:
                                            createJourneyFilterGroupFromFilterableField(
                                                state.splitActiveFields[
                                                    parentNodeId
                                                ]?.field,
                                                state.splitActiveFields[
                                                    parentNodeId
                                                ]?.isJourneyField,
                                            ),
                                    },
                                ],
                            },
                        };
                    }
                }
            } else if (
                updatedNodeConfig &&
                isExperimentBlock(updatedNodeConfig)
            ) {
                updatedNodeConfig = {
                    ...updatedNodeConfig,
                    actions: updatedNodeConfig.actions.map((action) => {
                        if (action.type === JourneyActionType.EXPERIMENT) {
                            return {
                                ...action,
                                config: {
                                    ...action.config,
                                    experimentConfig: {
                                        ...action.config.experimentConfig,
                                        variantConfig:
                                            action.config.experimentConfig.variantConfig.map(
                                                (variant) => {
                                                    if (
                                                        variant.nodeId ===
                                                            targetNode.id ||
                                                        variant.variantId ===
                                                            targetNode.id
                                                    ) {
                                                        return {
                                                            ...variant,
                                                            nodeId: newNodeId,
                                                        };
                                                    }
                                                    return variant;
                                                },
                                            ),
                                    },
                                },
                            };
                        }
                        return action;
                    }),
                };
            } else {
                if (
                    updatedBranchConfig?.children.type ===
                    BranchConcurrencyTypes.PARALLEL
                ) {
                    updatedBranchConfig = {
                        ...updatedBranchConfig,
                        children: {
                            ...updatedBranchConfig?.children,
                            branches: [
                                ...(updatedBranchConfig?.children?.branches.filter(
                                    (eachBranch) =>
                                        eachBranch.destination !==
                                        reactFlowNodeId,
                                ) ?? []),
                                newBranch,
                            ],
                        },
                    };
                } else {
                    updatedBranchConfig = {
                        type: BranchConditionalTypes.IFIF,
                        children: {
                            type: BranchConcurrencyTypes.SEQUENTIAL,
                            branches: [newBranch],
                        },
                    };
                }
            }
            return {
                ...node,
                ...updatedNodeConfig,
                branchConfig: updatedBranchConfig,
            };
        }
        return node;
    });

    if (childNodeId && blockData.title === translate('common.split')) {
        const allChildNodeIds = getAllChildNodeIds(reactFlowNodeId, edges);
        filteredNodes = filteredNodes.filter(
            (node) => !allChildNodeIds.includes(node.id),
        );
        updatedEdges = updatedEdges.filter(
            (edge) =>
                !allChildNodeIds.includes(edge.source) &&
                !allChildNodeIds.includes(edge.target),
        );
        updatedNodes = updatedNodes.filter(
            (node) => !allChildNodeIds.includes(node.id),
        );
    }

    const newState: JourneyReducerState = {
        ...state,
        nodes: updatedReactNodes,
        edges: updatedEdges,
        controlPanel: {
            isOpen: true,
            type: ControlPanel.BLOCK_CONFIG,
            nodeId: newNodeId,
        },
        journeyPayload: {
            ...state.journeyPayload,
            config: {
                ...state.journeyPayload.config,
                nodes: updatedNodes,
            },
        },
    };

    if (isExperimentBlock(actionPayload)) {
        return addCircuit({
            state: newState,
            targetNodeId: newNodeId,
            actionPayload,
        });
    }

    return newState;
}

export const isNodePartOfExperimentCircuit = (
    nodeId: string,
    journeyPayload: JourneyCreatePayload,
    nodes: Node<JourneyNodeData>[],
    edges: Edge[],
) => {
    // Loop through each edge to find edges where the nodeId is the target
    for (const edge of edges) {
        if (edge.target === nodeId) {
            // Check if the source node exists in the journeyState.journeyPayload.config.nodes
            const isSourceExperimentBlock = journeyPayload.config?.nodes.some(
                (node) => node.id === edge.source && isExperimentBlock(node),
            );

            // If the source node exists, return true
            if (isSourceExperimentBlock) {
                return true;
            }
        }
    }

    // If no matching source node is found, return false
    return false;
};

export const isExperimentNode = (
    nodeId: string,
    nodes: Node<JourneyNodeData>[],
) => {
    const node = nodes.find((n) => n.id === nodeId);
    return Boolean(node?.data.isExperiment);
};
