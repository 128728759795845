import { CampaignThrottleType } from '@lightdash/common';
import { Box, Group, Radio, Text } from '@mantine/core';
import React from 'react';

import Checkbox from '@components/common/Checkbox';
import TextInputVariableWidth from '@components/common/Inputs/TextInputVariableWidth';
import { useLocale } from '@hooks/useLocale';
import useCampaignContext from '@providers/Campaign/useCampaignContext';

const ThrottleConfig = () => {
    const { t } = useLocale();
    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload } = state;
    const { setThrottleConfig } = actions;

    const handleRadioInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === CampaignThrottleType.BATCH) {
            setThrottleConfig({
                type: CampaignThrottleType.BATCH,
                batch: 100,
            });
            return;
        }
        if (e.target.value === CampaignThrottleType.TIME_INTERVAL) {
            setThrottleConfig({
                type: CampaignThrottleType.TIME_INTERVAL,
                interval: 60,
            });
        }
    };

    const handleThrottleInput = (value: number) => {
        if (
            campaignPayload.throttleConfig?.type === CampaignThrottleType.BATCH
        ) {
            setThrottleConfig({
                batch: value,
                type: CampaignThrottleType.BATCH,
            });
            return;
        }
        if (
            campaignPayload.throttleConfig?.type ===
            CampaignThrottleType.TIME_INTERVAL
        ) {
            setThrottleConfig({
                interval: value,
                type: CampaignThrottleType.TIME_INTERVAL,
            });
        }
    };

    return (
        <Box className="w-[36rem] mt-6">
            <Text className="mb-6 text-lg text-gray-800">
                {t('campaign_builder.throttle_config.header')}
            </Text>
            <Checkbox
                checked={Boolean(campaignPayload.throttleConfig)}
                onChange={(e) => {
                    if (e.target.checked) {
                        setThrottleConfig({
                            type: CampaignThrottleType.TIME_INTERVAL,
                            interval: 60,
                        });
                    } else {
                        setThrottleConfig(undefined);
                    }
                }}
                label={t('campaign_builder.throttle_config.checkbox_label')}
            />
            {campaignPayload.throttleConfig && (
                <Box className="p-2 mt-2 border-base rounded-xl">
                    <Text className="mb-2 font-medium text-gray-800">
                        {t('campaign_builder.throttle_config.custom_setting')}
                    </Text>
                    <Box className="px-3 py-2 mb-2 border-base">
                        <Radio
                            checked={
                                campaignPayload.throttleConfig.type ===
                                CampaignThrottleType.TIME_INTERVAL
                            }
                            value={CampaignThrottleType.TIME_INTERVAL}
                            onChange={handleRadioInput}
                            label={t(
                                'campaign_builder.throttle_config.time_interval_label',
                            )}
                        />
                        {campaignPayload.throttleConfig.type ===
                            CampaignThrottleType.TIME_INTERVAL && (
                            <Group className="mt-2">
                                <Text className="text-gray-600">
                                    Send messages for
                                </Text>
                                <TextInputVariableWidth
                                    onChange={(e) =>
                                        handleThrottleInput(
                                            Number(e.target.value),
                                        )
                                    }
                                    value={
                                        campaignPayload.throttleConfig.type ===
                                        CampaignThrottleType.TIME_INTERVAL
                                            ? campaignPayload.throttleConfig
                                                  .interval
                                            : 0
                                    }
                                />
                                <Text className="text-gray-600">minutes</Text>
                            </Group>
                        )}
                    </Box>
                    <Box className="px-3 py-2 border-base">
                        <Radio
                            checked={
                                campaignPayload.throttleConfig.type ===
                                CampaignThrottleType.BATCH
                            }
                            value={CampaignThrottleType.BATCH}
                            onChange={handleRadioInput}
                            label={t(
                                'campaign_builder.throttle_config.batch_label',
                            )}
                        />
                        {campaignPayload.throttleConfig.type ===
                            CampaignThrottleType.BATCH && (
                            <Group className="mt-2">
                                <Text className="text-gray-600">
                                    {t('common.send')}
                                </Text>
                                <TextInputVariableWidth
                                    onChange={(e) =>
                                        handleThrottleInput(
                                            Number(e.target.value),
                                        )
                                    }
                                    value={
                                        campaignPayload.throttleConfig.type ===
                                        CampaignThrottleType.BATCH
                                            ? campaignPayload.throttleConfig
                                                  .batch
                                            : 0
                                    }
                                />
                                <Text className="text-gray-600">
                                    messages every minute
                                </Text>
                            </Group>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ThrottleConfig;
