import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import { TextWithTooltip } from '@components/common/TextWithTooltip';
import Timestamp from '@components/common/Timestamp';
import { type EventLogsStats } from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import useApp from '@providers/App/useApp';
import { type ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocale } from '../../../hooks/useLocale';
import { EventManagerType } from '../types';
import EventsMenuItem from './EventsMenuItem';

export function useEventColumns(managerType: EventManagerType) {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [columns, setColumns] = useState<ColumnDef<EventLogsStats>[]>([]);
    const { t } = useLocale();
    const { user } = useApp();
    const ability = useAbilityContext();
    const canEditProject = ability.can(
        'update',
        subject('Project', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    useEffect(() => {
        if (projectUuid) {
            const baseColumns: ColumnDef<EventLogsStats>[] = [
                {
                    accessorKey: 'eventName',
                    header: t('event_manager.th_event_name'),
                    sortingFn: (rowA, rowB) => {
                        const labelA = rowA.original.eventName;
                        const labelB = rowB.original.eventName;
                        if (
                            typeof labelA === 'string' &&
                            typeof labelB === 'string'
                        ) {
                            return labelA.localeCompare(labelB);
                        }
                        return 0;
                    },
                    cell: ({ row, getValue }) => (
                        <Box
                            className={`flex flex-col gap-0.5 justify-center items-start`}
                        >
                            <Box className="w-full">
                                <TextWithTooltip
                                    text={row.original.label || ''}
                                    className="!m-0 text-sm font-medium text-gray-800 max-w-[12rem] truncate"
                                />
                            </Box>
                            {getValue<string>() && (
                                <Text
                                    className={`text-xs font-normal text-gray-600 max-w-[12rem] truncate`}
                                >
                                    <TextWithTooltip
                                        text={getValue<string>()}
                                        className="!m-0 text-sm text-gray-800 font-medium w-full"
                                    />
                                </Text>
                            )}
                        </Box>
                    ),
                    size: 200,
                },
                {
                    accessorKey: 'source',
                    header: t('event_manager.th_event_source'),
                    cell: ({ getValue }) => (
                        <Text className="text-sm text-gray-800">
                            {getValue<string>()}
                        </Text>
                    ),
                    size: 200,
                },
                {
                    accessorKey: '30DayCount',
                    header: t('events_manager.th_30_day_count'),
                    cell: ({ row }) => row.original.count.last30,
                    sortingFn: (rowA, rowB) => {
                        return (
                            rowA.original.count.last30 -
                            rowB.original.count.last30
                        );
                    },
                    size: 200,
                },
                {
                    accessorKey: '7DayCount',
                    header: t('events_manager.th_7_day_count'),
                    cell: ({ row }) => row.original.count.last7,
                    sortingFn: (rowA, rowB) => {
                        return (
                            rowA.original.count.last7 -
                            rowB.original.count.last7
                        );
                    },
                    size: 200,
                },
                {
                    accessorKey: 'createdAt',
                    header: t('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {row.original.createdBy &&
                                    `by ${row.original.createdBy || ''}`}
                            </Text>
                        </Box>
                    ),
                    size: 200,
                },
                {
                    accessorKey: 'updatedAt',
                    header: t('common.modified_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            {row.original.updatedAt ? (
                                <Timestamp timestamp={getValue<string>()} />
                            ) : (
                                '-'
                            )}

                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {row.original.updatedBy &&
                                    `by ${row.original.updatedBy || ''}`}
                            </Text>
                        </Box>
                    ),
                    size: 200,
                },
            ];

            if (managerType === EventManagerType.VIEW && canEditProject) {
                baseColumns.push({
                    accessorKey: 'menu',
                    header: '',
                    enableSorting: false,
                    cell: ({ row }) => (
                        <EventsMenuItem rowData={row.original} />
                    ),
                    size: 80,
                });
            }

            setColumns(baseColumns);
        }
    }, [projectUuid, t, managerType, canEditProject]);

    return columns;
}
