import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import ImportTemplateModal from '@components/Templates/Builder/modals/ImportTemplateModal';
import { useLocale } from '@hooks/useLocale';
import { useGetTemplateById } from '@hooks/useTemplate';
import {
    CommunicationChannel,
    type Integration,
    type ProviderTemplateDetails,
    type TemplateContentDetails,
} from '@lightdash/common';
import { Box, Flex, Stack, Text } from '@mantine/core';
import {
    ArrowSquareIn,
    Book,
    CaretRight,
    PaintBrush,
} from '@phosphor-icons/react';
import TemplateBuilderProvider from '@providers/TemplateBuilder/TemplateBuilderProvider';
import { EmailBuilderType } from '@providers/TemplateBuilder/types';
import useTemplateBuilderContext from '@providers/TemplateBuilder/useTemplateBuilderContext';
import React, { useMemo } from 'react';
import CreateTemplateModal from './modals/CreateTemplateModal';
import TemplateLibraryModal from './modals/TemplateLibraryModal';
import { useTemplateBuilderInitialData } from './useTemplateBuilderInitialData';

interface TemplateSelectionTileProps {
    title: string;
    description: string;
    onClick: () => void;
    icon: React.ReactNode;
}
const TemplateSelectionTile: React.FC<TemplateSelectionTileProps> = React.memo(
    ({ title, description, onClick, icon }) => {
        return (
            <Box
                className={
                    'shadow-card border-base rounded-lg p-3.5 cursor-pointer hover:bg-shade-2'
                }
                onClick={onClick}
            >
                <Flex justify={'space-between'} align={'center'}>
                    <Flex justify={'center'} align={'center'}>
                        {icon}
                        <Text className="mx-1.5 text-sm font-medium	">
                            {title}
                        </Text>
                    </Flex>
                    <CaretRight weight="regular" />
                </Flex>
                <Text className="text-sm font-normal text-gray-500">
                    {description}
                </Text>
            </Box>
        );
    },
);

type TemplateOptionsProps = {
    integration: Integration;
    showCreateEditTemplate: Boolean;
    defaultTemplateName: string | undefined;
    isTemplateLibrary: boolean;
    channel: string;
};

const TemplateOptions: React.FC<TemplateOptionsProps> = ({
    integration,
    showCreateEditTemplate,
    defaultTemplateName,
    isTemplateLibrary,
    channel,
}) => {
    const { t } = useLocale();

    const {
        setOpenLibraryModal,
        setOpenImportModal,
        setOpenCreateTemplateModal,
        setEmailBuilderType,
    } = useTemplateBuilderContext((context) => context.actions);

    const options = useMemo(() => {
        const result = [];
        if (integration.channelName !== CommunicationChannel.WHATSAPP) {
            result.push({
                title: t('campaigns.import_template.import_from_integration', {
                    integration: 'integration',
                }),
                description: t(
                    'campaigns.import_template.clone_from_provider',
                    {
                        channel: integration.channelName,
                    },
                ),
                onClick: () => setOpenImportModal(true),
                icon: <ArrowSquareIn />,
            });
        }
        if (
            showCreateEditTemplate &&
            ((integration.channelName &&
                integration.channelName !== CommunicationChannel.WHATSAPP) ||
                (channel && channel !== CommunicationChannel.WHATSAPP))
        ) {
            const getTitle = () => {
                if (defaultTemplateName) {
                    return t('campaigns.import_template.modify_template', {
                        templateName: defaultTemplateName,
                    });
                }
                if (channel === CommunicationChannel.EMAIL) {
                    return t(
                        'template_create.email.drag_and_drop_builder.title',
                    );
                }
                if (channel === CommunicationChannel.SMS) {
                    return t('template_manager.change_template', {
                        channel,
                    });
                }
                return t('campaigns.import_template.design_new_template');
            };
            if (channel === CommunicationChannel.EMAIL) {
                result.unshift({
                    title: getTitle(),
                    description: t(
                        'template_create.email.drag_and_drop_builder.description',
                    ),
                    onClick: () => {
                        setOpenCreateTemplateModal(true);
                        setEmailBuilderType(EmailBuilderType.BEEFREE);
                    },
                    icon: <PaintBrush />,
                });
            }
            if (channel === CommunicationChannel.SMS) {
                result.unshift({
                    title: getTitle(),
                    description: t('templates_importer.write_a_new_message'),
                    onClick: () => {
                        setOpenCreateTemplateModal(true);
                    },
                    icon: <PaintBrush />,
                });
            }
        }
        if (!isTemplateLibrary) {
            result.push({
                title: t('campaigns.import_template.select_from_library'),
                description: t(
                    'campaigns.import_template.choose_from_saved_templates',
                ),
                onClick: () => setOpenLibraryModal(true),
                icon: <Book />,
            });
        }
        return result;
    }, [
        isTemplateLibrary,
        t,
        integration.channelName,
        showCreateEditTemplate,
        defaultTemplateName,
        setOpenLibraryModal,
        setOpenImportModal,
        setOpenCreateTemplateModal,
        channel,
        setEmailBuilderType,
    ]);

    return (
        <Stack>
            {options.map((option, index) => (
                <TemplateSelectionTile
                    key={index}
                    title={option.title}
                    description={option.description}
                    onClick={option.onClick}
                    icon={option.icon}
                />
            ))}
        </Stack>
    );
};

interface TemplateImporterType {
    integration: Integration;
    isSingleSelect: boolean;
    handleTemplateSelect: (obj: ProviderTemplateDetails) => void;
    integrationId: string;
    channel: CommunicationChannel;
    showCreateEditTemplate?: Boolean;
    templateId?: string;
    isTemplateLibrary?: boolean;
    onClose: (() => void) | undefined;
    editMode: boolean;
    contentDetails: TemplateContentDetails | undefined;
    handleUpdateTemplateInCampaignContext:
        | ((content: TemplateContentDetails) => void)
        | undefined;
}
const TemplateBuilderManager = ({
    integration,
    handleTemplateSelect,
    integrationId,
    channel,
    showCreateEditTemplate = true,
    templateId,
    isTemplateLibrary = false,
    onClose,
    editMode,
    contentDetails,
    handleUpdateTemplateInCampaignContext,
}: TemplateImporterType) => {
    const { data: templateMetadata, isInitialLoading } = useGetTemplateById(
        templateId ?? '',
        channel !== CommunicationChannel.WHATSAPP,
    );

    const initialState = useTemplateBuilderInitialData({
        templatePayload: templateMetadata,
        channel,
        templateId,
    });

    if (isInitialLoading) {
        return <SuboptimalState loading />;
    }

    return (
        <TemplateBuilderProvider
            initialState={initialState}
            channel={channel}
            templateId={templateMetadata?.id ?? ''}
            handleTemplateSelectCallback={handleTemplateSelect}
        >
            <>
                <TemplateOptions
                    integration={integration}
                    showCreateEditTemplate={showCreateEditTemplate}
                    defaultTemplateName={templateMetadata?.name}
                    isTemplateLibrary={isTemplateLibrary}
                    channel={channel}
                />

                {/* Modal - Import from Library */}
                <TemplateLibraryModal
                    channel={channel}
                    integrationId={integrationId}
                />

                {/* Modal - Import from Provider */}
                <ImportTemplateModal />

                {/* Modal - Design New Template */}
                <CreateTemplateModal
                    integration={integration}
                    integrationId={integrationId}
                    onClose={onClose}
                    editMode={editMode}
                    contentDetails={contentDetails}
                    handleUpdateTemplateInCampaignContext={
                        handleUpdateTemplateInCampaignContext
                    }
                />
            </>
        </TemplateBuilderProvider>
    );
};

export default TemplateBuilderManager;
