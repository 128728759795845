import {
    type AgentJsonContent,
    type AgentMessage,
    type AIMessageContext,
} from '@lightdash/common';

export interface AiAnalystState {
    showAiAnalyst: boolean;
    threadId: string;
    aiContext: AIMessageContext;
    aiChatMessages: AgentMessage[];
    streamedMessageIds: string[];
    userFeedback: Record<string, boolean>;
}

export interface AiAnalystReducerState {
    showAiAnalyst: boolean;
    threadId: string;
    aiChatMessages: AgentMessage[];
    aiContext: AIMessageContext;
    streamedMessageIds: string[];
    userFeedback: Record<string, boolean>;
}

export interface AiAnalystContext {
    state: AiAnalystState;
    actions: {
        setShowAiAnalyst: (showAiAnalyst: boolean) => void;
        registerCallback: (callback: (data: AgentJsonContent) => void) => void;
        unregisterCallback: () => void;
        onSuccess: (json: AgentJsonContent) => void;
        setAiContext: (context: AIMessageContext) => void;
        setAIChatMessages: (messages: AgentMessage[]) => void;
        handleCreateThread: () => void;
        addStreamedMessageId: (messageId: string) => void;
        setUserFeedback: (userFeedback: Record<string, boolean>) => void;
    };
}

export enum ActionType {
    SET_SHOW_AI_ANALYST,
    SET_CHAT_MESSAGES,
    SET_AI_CHAT_MESSAGES,
    SET_THREAD_ID,
    SET_AI_CONTEXT,
    ADD_STREAMED_MESSAGE_ID,
    SET_USER_FEEDBACK,
}
