import { useLocale } from '@hooks/useLocale';
import { Flex, Text } from '@mantine/core';
import { ProhibitInset } from '@phosphor-icons/react';
import React from 'react';

const SuppressionRuleTag = () => {
    const { t } = useLocale();

    return (
        <Flex
            align={'center'}
            gap={4}
            className="w-fit border-base bg-gray-50 rounded-lg px-2"
        >
            <ProhibitInset size={12} color="rgb(var(--color-gray-600))" />
            <Text className="text-xs text-gray-600">
                {t('campaign_builder.audience.global_rule_applied')}
            </Text>
        </Flex>
    );
};

export default SuppressionRuleTag;
