import { ShowDataType } from '@lightdash/common';
import { Box } from '@mantine/core';
import { useState } from 'react';
import ManagerTable from './Table';
import ManagerTabs from './Tabs';
import TopBanner from './TopBanner';
import {
    type EditableManagerProps,
    type ManagerProps,
    type ManagerPropsManualPagination,
} from './type';

export default function Manager<TData, T = string>({
    showSearch = true,
    gridSize = 4,
    tableBackgroundColor = 'bg-gray-100',
    withBorder = false,
    ...props
}: ManagerProps<TData, T>) {
    const {
        pagination,
        onPageChange,
        isDataFetching,
        isNeedToRefresh,
        isControlledFetching,
        options,
        isManualPagination,
        onNeedToRefresh,
    } = props as ManagerPropsManualPagination<TData, T>;

    const { isEditable, updateData } = props as EditableManagerProps<TData, T>;
    const [viewType, setViewType] = useState<ShowDataType>(ShowDataType.LIST);

    const hasInternalBorder = !withBorder;
    return (
        <Box
            className={`${tableBackgroundColor} ${
                withBorder ? 'border rounded-lg border-shade-4' : ''
            }`}
        >
            <TopBanner
                showSearch={showSearch}
                searchPlaceholder={props.searchPlaceholder}
                searchValue={props.searchValue}
                onSearchChange={(value) => {
                    if (props.onSearchChange) {
                        props.onSearchChange(value);
                    }
                }}
                viewType={viewType}
                setViewType={setViewType}
                options={props.options}
                leftSection={props.leftSection}
                rightSection={props.rightSection}
            />
            <Box
                className={` ${
                    hasInternalBorder
                        ? 'border border-gray-100 rounded-2xl p-0.5 mt-4'
                        : ''
                } bg-white`}
            >
                <ManagerTabs
                    tabs={props.tabs}
                    selectedTab={props.selectedTab}
                    onTabChange={props.onTabChange}
                />
                <Box
                    className={`bg-white overflow-hidden
                    ${
                        hasInternalBorder
                            ? 'border rounded-xl border-gray-200'
                            : ''
                    }
                    !h-[calc(100vh-16rem)]`}
                >
                    <ManagerTable
                        pagination={pagination}
                        onPageChange={onPageChange}
                        isDataFetching={isDataFetching}
                        isNeedToRefresh={isNeedToRefresh}
                        isControlledFetching={isControlledFetching}
                        searchValue={props.searchValue}
                        previewLimit={props.previewLimit}
                        viewType={viewType}
                        isEditable={isEditable}
                        updateData={updateData}
                        name={props.name}
                        customClass={props.customClass}
                        gridSize={gridSize}
                        cellHeight={props.cellHeight}
                        defaultSorting={props.defaultSorting}
                        options={options}
                        handleRowClick={props.handleRowClick}
                        isManualPagination={isManualPagination}
                        tableData={props.tableData}
                        sorting={props.sorting}
                        setSorting={props.setSorting}
                        onNeedToRefresh={onNeedToRefresh}
                        onSuccessInControlledFetching={
                            props.onSuccessInControlledFetching
                        }
                    />
                </Box>
            </Box>
        </Box>
    );
}
