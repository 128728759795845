import { Box, Flex, Stack } from '@mantine/core';
import React, { useMemo } from 'react';

import { useGetTemplateById } from '@hooks/useTemplate';
import { CommunicationChannel, ContentMappingSection } from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import { useParams } from 'react-router';
import TemplateOptionsModal from '../TemplateOptionsModal';
import Details from './Details';
import Header from './Header';
import LinkTracking from './LinkTracking';
import Personalization from './Personalization';
import Preview from './Preview';

const TemplateDetails = () => {
    const { channel, templateId } = useParams<{
        channel: CommunicationChannel;
        templateId: string;
    }>();
    const [opened, { close, open }] = useDisclosure();
    const { data: templateMetadata } = useGetTemplateById(
        templateId ?? '',
        channel !== CommunicationChannel.WHATSAPP,
    );

    const showPersonalization = useMemo(() => {
        return !!templateMetadata?.contents[0]?.variables[
            ContentMappingSection.BODY
        ]?.length;
    }, [templateMetadata?.contents]);

    const showLinkTracking = useMemo(() => {
        if (channel === CommunicationChannel.EMAIL) {
            return !!templateMetadata?.contents[0]?.parsedVariables?.length;
        }
        return false;
    }, [channel, templateMetadata?.contents]);

    return (
        <Box className="h-full bg-gray-50">
            <Header
                name={templateMetadata?.name ?? ''}
                description={templateMetadata?.description}
                tags={templateMetadata?.tags ?? []}
                channel={channel ?? CommunicationChannel.EMAIL}
                templateMetadata={templateMetadata}
                createdAt={(templateMetadata?.createdAt ?? '') as string}
            />
            <Flex className="w-full h-full">
                <Box className="border-r-[1px] mr-1 w-[50%] h-full py-4 px-6">
                    <Stack
                        spacing={18}
                        className="h-[calc(100vh-200px)] overflow-auto"
                    >
                        {channel === CommunicationChannel.EMAIL && (
                            <Details
                                subject={templateMetadata?.contents[0]?.subject}
                                preheader={
                                    templateMetadata?.contents[0]?.preheader
                                }
                            />
                        )}

                        {showPersonalization && (
                            <Personalization
                                variables={
                                    templateMetadata?.contents[0]?.variables
                                }
                                contentMappings={
                                    templateMetadata?.contents[0]
                                        ?.contentMappings
                                }
                                channel={channel}
                            />
                        )}
                        {showLinkTracking && (
                            <LinkTracking
                                links={
                                    templateMetadata?.contents[0]
                                        ?.parsedVariables
                                }
                                contentMappings={
                                    templateMetadata?.contents[0]
                                        ?.contentMappings
                                }
                                trackingParameters={
                                    templateMetadata?.contents[0]
                                        ?.trackingParameters
                                }
                            />
                        )}
                    </Stack>
                </Box>
                <Box className="border-l-[1px] w-[50%]">
                    <Preview
                        templateMetadata={templateMetadata}
                        handleModifyTemplate={() => open()}
                        grapesId="templateDetails"
                        previewUserData={undefined}
                    />
                </Box>
            </Flex>
            {templateMetadata && (
                <TemplateOptionsModal
                    opened={opened}
                    onClose={close}
                    providerMetadata={{
                        customName: '',
                        channelId: '',
                        channelName: '',
                        description: '',
                        displayName: '',
                        groupName: '',
                        integrated: '',
                        logoUrl: '',
                        providerId: '',
                        providerName: '',
                        integrationId: '',
                        isTemplateImportAllowed: false,
                        config: [],
                    }}
                    integrationId={''}
                    handleTemplateSelect={() => {}}
                    channel={templateMetadata.channel}
                    showCreateEditTemplate={true}
                    templateId={templateMetadata?.id ?? undefined}
                    isTemplateLibrary={false}
                    editMode={true}
                    contentDetails={templateMetadata.contents[0].content}
                    handleUpdateTemplateInCampaignContext={undefined}
                />
            )}
        </Box>
    );
};

export default TemplateDetails;
