import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import ToggleSwitch from '@components/common/ToggleSwitch';
import { useFilteringTables } from '@hooks/useGetRelatedTables';
import { useLocale } from '@hooks/useLocale';
import useSorte from '@hooks/useSorte';
import {
    AIMessageContext,
    AiMessageTypes,
    Author,
    FieldType,
    type AgentMessage,
    type CompiledRelationTable,
} from '@lightdash/common';
import { Box, Flex, Stack, Text } from '@mantine/core';
import { BracketsAngle, CaretRight, Sparkle, X } from '@phosphor-icons/react';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { ButtonVariant } from '../../../mantineTheme';
import { CustomMetricMode } from '../types';
import { getFilteredTables } from '../utils';
import AIModeInTraitModal from './AIModeInTraitModal';
import ManualModeInTraitModal from './ManualModeInTraitModal';
import TablesListMenu from './TableListMenu';

interface CalculatedTraitBaseModalProps {
    onClose: () => void;
    opened: boolean;
}

const CalculatedTraitBaseModal: React.FC<CalculatedTraitBaseModalProps> = ({
    onClose,
    opened,
}) => {
    const { activeRelation } = useRelationContext();
    const [advancedMode, setAdvancedMode] = useState<boolean>(false);
    const [selectedTable, setSelectedTable] = useState<
        CompiledRelationTable | undefined
    >(activeRelation?.tables[activeRelation.baseTable]);
    const [fieldType, setFieldType] = useState<FieldType | undefined>(
        FieldType.METRIC,
    );
    const { t } = useLocale();
    const navigate = useNavigate();
    const { threadId } = useAiAnalystContext((context) => context.state);
    const { setAIChatMessages, setShowAiAnalyst } = useAiAnalystContext(
        (context) => context.actions,
    );
    const { handleSendMessage, isLoading: aiThinking } = useSorte();
    const { projectUuid } = useParams<{ projectUuid: string }>();

    const [instructions, setInstructions] = useState<string>('');
    const [exampleInstructions, setExampleInstructions] = useState<string>('');
    const { availableTables, nonAvailableTables } =
        useFilteringTables(activeRelation);

    const handleGenerateCustomAttribute = useCallback(async () => {
        if (advancedMode) {
            void navigate(
                `/projects/${projectUuid}/traits/create#${fieldType}`,
                {
                    state: {
                        customAttribute: {
                            type: fieldType,
                            srcTable: selectedTable?.name,
                        },
                    },
                },
            );
            return;
        }

        try {
            const message: AgentMessage = {
                threadId,
                author: Author.USER,
                messageId: uuidv4(),
                context: AIMessageContext.CUSTOM_ATTRIBUTE,
                content: {
                    type: AiMessageTypes.TEXT,
                    value:
                        instructions.trim() +
                        t('custom_metric.edit_trait_modal.examples_text') +
                        exampleInstructions.trim(),
                },
                traceId: '',
            };
            setAIChatMessages([message]);
            setShowAiAnalyst(true);
            await handleSendMessage(
                instructions.trim() +
                    t('custom_metric.edit_trait_modal.examples_text') +
                    exampleInstructions.trim(),
            );
        } catch (error) {
            console.error(error);
        } finally {
            onClose();
        }
    }, [
        advancedMode,
        navigate,
        projectUuid,
        fieldType,
        selectedTable?.name,
        threadId,
        instructions,
        t,
        exampleInstructions,
        setAIChatMessages,
        setShowAiAnalyst,
        handleSendMessage,
        onClose,
    ]);

    const { filteredAvailableTables, filteredNonAvailableTables } =
        useMemo(() => {
            return getFilteredTables(
                availableTables,
                nonAvailableTables,
                fieldType,
            );
        }, [availableTables, nonAvailableTables, fieldType]);
    const handleToggleSwitch = useCallback((option: string) => {
        if (option === CustomMetricMode.ADVANCED) {
            setAdvancedMode(true);
        } else {
            setAdvancedMode(false);
        }
    }, []);

    const handleFieldTypeChange = useCallback(
        (fieldTypeSelected: FieldType) => {
            setFieldType(fieldTypeSelected);
            setSelectedTable(activeRelation?.tables[activeRelation.baseTable]);
        },
        [activeRelation],
    );

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            size="xl"
            footerRightSection={
                <ModalFooter
                    showSecondaryButton={true}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    secondaryButtonClick={onClose}
                    secondaryText={t('common.cancel')}
                    showPrimaryButton={true}
                    primaryButtonClick={handleGenerateCustomAttribute}
                    primaryText={
                        aiThinking
                            ? t(
                                  'custom_metric.create_new_trait_modal.generating',
                              )
                            : t('custom_metric.create_new_trait_modal.start')
                    }
                    primaryLeftIcon={undefined}
                    isLoading={aiThinking}
                    primaryRightIcon={
                        !aiThinking && (
                            <CaretRight weight="duotone" color="white" />
                        )
                    }
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={ButtonVariant.PRIMARY}
                    primaryButtonDisabled={
                        aiThinking ||
                        (advancedMode && fieldType === undefined) ||
                        (!advancedMode && instructions.trim().length === 0)
                    }
                    primaryButtonCustomClass={undefined}
                />
            }
            withCloseButton={false}
            isAiThinking={aiThinking}
            aiThinkingText={t(
                'custom_metric.create_new_trait_modal.creating_trait_loader',
            )}
            withContentPadding={false}
        >
            <Stack spacing={12}>
                <Flex
                    className="sticky top-0 z-10 px-4 py-3 text-gray-800 bg-white border-b border-gray-250"
                    align="center"
                    justify="space-between"
                >
                    <Text className="text-lg font-medium text-gray-800">
                        {t('custom_metric.create_new_trait_modal.title')}
                    </Text>
                    <Flex align="center" gap={12}>
                        <ToggleSwitch
                            options={[
                                {
                                    icon: (
                                        <Sparkle
                                            weight="duotone"
                                            color="rgb(var(--color-blu-800))"
                                        />
                                    ),
                                    value: CustomMetricMode.BASIC,
                                    label: t(
                                        'custom_metric.create_new_trait_modal.base_mode_label',
                                    ),
                                },
                                {
                                    icon: (
                                        <BracketsAngle
                                            weight="duotone"
                                            size={14}
                                        />
                                    ),
                                    value: CustomMetricMode.ADVANCED,
                                    label: t(
                                        'custom_metric.create_new_trait_modal.advanced_mode_label',
                                    ),
                                },
                            ]}
                            selectedOption={
                                advancedMode
                                    ? CustomMetricMode.ADVANCED
                                    : CustomMetricMode.BASIC
                            }
                            onSelectedOptionChange={handleToggleSwitch}
                            enabled={!aiThinking}
                        />
                        <X
                            weight="duotone"
                            onClick={onClose}
                            className="m-3 cursor-pointer"
                        />
                    </Flex>
                </Flex>
                <Stack spacing={12} className="px-4">
                    {advancedMode ? (
                        <ManualModeInTraitModal
                            handleFieldTypeChange={handleFieldTypeChange}
                            aiThinking={aiThinking}
                            fieldType={fieldType}
                        />
                    ) : (
                        <AIModeInTraitModal
                            aiThinking={aiThinking}
                            setInstructions={setInstructions}
                            instructions={instructions}
                            exampleInstructions={exampleInstructions}
                            setExampleInstructions={setExampleInstructions}
                        />
                    )}
                    {advancedMode && (
                        <Box>
                            <Text className="font-medium text-gray-800">
                                {t(
                                    'custom_metric.create_new_trait_modal.destination_table',
                                )}
                            </Text>
                            <TablesListMenu
                                selectedTable={selectedTable}
                                tables={filteredAvailableTables}
                                nonAvailableTables={filteredNonAvailableTables}
                                handleTableSelect={setSelectedTable}
                                disabled={aiThinking}
                            />
                        </Box>
                    )}
                </Stack>
            </Stack>
        </Modal>
    );
};

export default CalculatedTraitBaseModal;
