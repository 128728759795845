import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import { getDurationValue } from '@components/common/Time/utils';
import {
    JourneyEventType,
    type ActionEventField,
} from '@components/Journeys/Builder/JourneyFilters/types';
import { useGetJourneyEvents } from '@hooks/useJourney';
import { useLocale } from '@hooks/useLocale';
import {
    CampaignThrottleType,
    PeriodType,
    type Campaign,
} from '@lightdash/common';
import { Box, Button, Flex, Skeleton, Stack, Text } from '@mantine/core';
import {
    CheckCircle,
    Clock,
    CursorClick,
    FlagCheckered,
    Gauge,
    Pencil,
} from '@phosphor-icons/react';
import React, { useCallback, useMemo } from 'react';
import { ButtonVariant } from '../../../../../mantineTheme';

type ConversionProps = {
    handleEdit: () => void;
    conversionConfig: Campaign['conversionConfig'];
    throttleConfig: Campaign['throttleConfig'];
};

const Conversion = ({
    handleEdit,
    throttleConfig,
    conversionConfig,
}: ConversionProps) => {
    const { t } = useLocale();

    const eventConfig = useMemo(
        () => ({
            eventName: conversionConfig?.events[0].eventName || '',
            eventSource: conversionConfig?.events[0].eventSource || '',
        }),
        [conversionConfig?.events],
    );

    const { data: eventsList, isLoading: eventsLoading } =
        useGetJourneyEvents();

    const getEventType = useCallback(() => {
        if (!eventConfig) {
            return null;
        }

        const { eventName, eventSource } = eventConfig;

        const mappedEvent = eventsList?.find(
            (event) =>
                event.eventName === eventName && event.source === eventSource,
        );

        if (mappedEvent) {
            return mappedEvent.isInternal
                ? JourneyEventType.INTERNAL_EVENT
                : JourneyEventType.WHITELISTED_EVENT;
        }
    }, [eventConfig, eventsList]);

    const activeEventField: ActionEventField | undefined = useMemo(() => {
        const eventType = getEventType();

        if (eventType === JourneyEventType.INTERNAL_EVENT) {
            const internalEvent = eventsList?.find(
                (event) => event.eventName === eventConfig.eventName,
            );
            if (internalEvent) {
                return {
                    eventName: internalEvent.eventName,
                    eventLabel: internalEvent.label ?? internalEvent.eventName,
                    eventSourceIcon: (
                        <ChannelIcon
                            channel={internalEvent.sourceLabel}
                            showChannelName={false}
                        />
                    ),
                    eventSourceLabel: internalEvent.sourceLabel ?? '',
                    eventSource: internalEvent.source,
                    eventType: JourneyEventType.INTERNAL_EVENT,
                };
            }
        }

        if (eventType === JourneyEventType.WHITELISTED_EVENT) {
            const whitelistedEvent = eventsList?.find(
                (event) => event.eventName === eventConfig.eventName,
            );
            if (whitelistedEvent) {
                return {
                    eventName: whitelistedEvent.eventName,
                    eventLabel:
                        whitelistedEvent.label ?? whitelistedEvent.eventName,
                    eventSourceIcon: (
                        <CursorClick color={'rgb(var(--color-mustard-800))'} />
                    ),
                    eventSourceLabel:
                        whitelistedEvent.sourceLabel ?? whitelistedEvent.source,
                    eventSource: whitelistedEvent.source,
                    eventType: JourneyEventType.WHITELISTED_EVENT,
                };
            }
        }
    }, [eventConfig.eventName, eventsList, getEventType]);

    return (
        <Box className="border-base rounded-lg p-3 pb-[18px]">
            <Flex justify={'space-between'} className="mb-5">
                <Flex gap={6} align={'center'}>
                    <CheckCircle color="rgb(var(--color-blu-800))" />
                    <Text className="text-base text-gray-700 font-semibold">
                        {t('campaign_builder.conversion_and_throttling')}{' '}
                        <span className="text-gray-600">
                            {t('common.optional')}
                        </span>
                    </Text>
                </Flex>
                <Button
                    leftIcon={<Pencil color="rgb(var(--color-gray-600))" />}
                    variant={ButtonVariant.OUTLINED}
                    onClick={handleEdit}
                >
                    {t('common.edit')}
                </Button>
            </Flex>
            <Stack spacing={'lg'} className="w-full">
                <Flex className="w-full" align={'flex-start'}>
                    <Flex align={'center'} gap={4} className="w-[30%]">
                        <FlagCheckered color="rgb(var(--color-gray-600))" />
                        <Text className="text-gray-600 font-semibold uppercase">
                            {t('journey_goals.conversion_event')}
                        </Text>
                    </Flex>
                    {eventsLoading ? (
                        <Skeleton width={120} height={20} />
                    ) : conversionConfig ? (
                        <Stack spacing={4}>
                            <Flex gap={4} align={'center'}>
                                <CursorClick color="rgb(var(--color-gray-600))" />
                                <Text className="text-gray-800">
                                    {activeEventField?.eventSourceLabel}
                                </Text>
                                <Clock color="rgb(var(--color-gray-500))" />
                                <Text className="text-gray-500">
                                    {activeEventField?.eventLabel}
                                </Text>
                            </Flex>
                            <Text className="text-xs font-medium text-gray-600">
                                Within{' '}
                                {getDurationValue(
                                    conversionConfig.events[0]?.deadline ?? 0,
                                    conversionConfig.events[0].uiConfig
                                        ?.timeGranularity ?? PeriodType.HOUR,
                                )}{' '}
                                {conversionConfig.events[0].uiConfig?.timeGranularity?.toLowerCase()}
                            </Text>
                        </Stack>
                    ) : (
                        <Text className="text-gray-800 font-medium">-</Text>
                    )}
                </Flex>

                <Flex className="w-full">
                    <Flex align={'center'} gap={4} className="w-[30%]">
                        <Gauge color="rgb(var(--color-gray-600))" />
                        <Text className="text-gray-600 font-semibold uppercase">
                            {t('throttle')}
                        </Text>
                    </Flex>
                    {throttleConfig ? (
                        <Box>
                            <Text className="text-gray-800">
                                {throttleConfig.type ===
                                CampaignThrottleType.BATCH
                                    ? `Send ${throttleConfig.batch} messages every minute`
                                    : `Send messages for ${throttleConfig.interval} minutes`}
                            </Text>
                        </Box>
                    ) : (
                        <Text className="text-gray-800 font-medium">-</Text>
                    )}
                </Flex>
            </Stack>
        </Box>
    );
};

export default Conversion;
