import UserCount from '@components/common/UserCount';
import { useGetCampaignAnalyticsOverview } from '@hooks/useCampaigns';
import { useGetSuppressionConfig } from '@hooks/useDeliveryControlsApi';
import { useAudiences } from '@hooks/useGetAudience';
import { useLocale } from '@hooks/useLocale';
import {
    CampaignAnalyticsOverviewStatuses,
    CampaignStatus,
    CampaignType,
    CommunicationChannel,
    type ReducedCampaign,
} from '@lightdash/common';
import {
    Box,
    Flex,
    Group,
    HoverCard,
    Skeleton,
    Stack,
    Text,
} from '@mantine/core';
import { PaperPlaneTilt, UsersThree } from '@phosphor-icons/react';
import { type Row } from '@tanstack/react-table';
import { getFormattedCount } from '@utils/index';
import { useMemo, useState } from 'react';
import SuppressionRuleTag from '../Builder/Steps/SuppressionRuleTag';
import AudienceCount from './AudienceCount';

const AudienceAndAnalyticsCell = ({
    row,
    count,
}: {
    row: Row<ReducedCampaign>;
    count: number;
}) => {
    const { t } = useLocale();
    const [fetchAudienceData, setFetchAudienceData] = useState(false);

    const isAnalyticsOverviewEnabled = useMemo(
        () =>
            row.original.status === CampaignStatus.COMPLETED ||
            (row.original.status === CampaignStatus.SCHEDULED &&
                row.original.type === CampaignType.RECURRING),
        [row.original.status, row.original.type],
    );
    const { data: analyticsOverview, isLoading: isAnalyticsOverviewLoading } =
        useGetCampaignAnalyticsOverview(
            row.original.id ?? '',
            isAnalyticsOverviewEnabled,
        );

    const { data: sendListAudiences, isLoading: isSendListLoading } =
        useAudiences({
            query: `idIn=${row.original.audienceConfig?.config.in?.join(',')}`,
            perPage: row.original.audienceConfig?.config.in?.length ?? 99,
            currentPage: 1,
            polling: false,
            enabled:
                fetchAudienceData &&
                !!row.original.audienceConfig?.config.in?.length,
        });

    const { data: suppressListAudiences, isLoading: isSuppressListLoading } =
        useAudiences({
            query: `idIn=${row.original.audienceConfig?.config.notIn?.join(
                ',',
            )}`,
            perPage: row.original.audienceConfig?.config.notIn?.length ?? 99,
            currentPage: 1,
            polling: false,
            enabled:
                fetchAudienceData &&
                !!row.original.audienceConfig?.config.notIn?.length,
        });

    const { data: suppressionConfig } = useGetSuppressionConfig({
        enabled: fetchAudienceData,
    });

    const globalSuppressedAudiences = useMemo(() => {
        if (suppressionConfig) {
            return suppressionConfig.config.mappings
                .filter(
                    (item) =>
                        item.channel === row.original.channel ||
                        item.channel === CommunicationChannel.ANY,
                )
                .map((item) => item.audienceId);
        }
        return [];
    }, [row.original.channel, suppressionConfig]);

    const renderSendList = useMemo(() => {
        if (isSendListLoading) {
            return <Skeleton width={120} height={20} />;
        }
        if (sendListAudiences && sendListAudiences.data?.length) {
            return (
                <Stack spacing={8}>
                    {sendListAudiences.data.map((item) => (
                        <Flex align={'center'} gap={4} key={item.id}>
                            <UsersThree color="rgb(var(--color-pink-800))" />
                            <Text className="text-gray-800 font-medium">
                                {item.name}
                            </Text>
                            {globalSuppressedAudiences.includes(item.id) && (
                                <SuppressionRuleTag />
                            )}
                        </Flex>
                    ))}
                </Stack>
            );
        }
    }, [isSendListLoading, sendListAudiences, globalSuppressedAudiences]);

    const renderSuppressList = useMemo(() => {
        if (isSuppressListLoading) {
            return <Skeleton width={120} height={20} />;
        }
        if (suppressListAudiences && suppressListAudiences.data?.length) {
            return (
                <Stack spacing={8}>
                    {suppressListAudiences.data.map((item) => (
                        <Flex align={'center'} gap={4} key={item.id}>
                            <UsersThree color="rgb(var(--color-pink-800))" />
                            <Text className="text-gray-800 font-medium">
                                {item.name}
                            </Text>
                            {globalSuppressedAudiences.includes(item.id) && (
                                <SuppressionRuleTag />
                            )}
                        </Flex>
                    ))}
                </Stack>
            );
        }
        return <Text className="text-gray-800">-</Text>;
    }, [
        isSuppressListLoading,
        suppressListAudiences,
        globalSuppressedAudiences,
    ]);

    const renderContent = useMemo(() => {
        if (row.original.audienceConfig?.config.in.length) {
            return (
                <Flex align={'center'} gap={4}>
                    <UsersThree color="rgb(var(--color-pink-800))" />
                    <HoverCard
                        withinPortal
                        onOpen={() => setFetchAudienceData(true)}
                        onClose={() => setFetchAudienceData(false)}
                    >
                        <HoverCard.Target>
                            <Text>
                                Send to{' '}
                                {row.original.audienceConfig?.config.in.length}{' '}
                                list
                                {row.original.audienceConfig?.config.in.length >
                                1
                                    ? 's'
                                    : ''}
                            </Text>
                        </HoverCard.Target>
                        <HoverCard.Dropdown className="rounded-xl shadow-[0px 0px 8px 0px rgba(0, 0, 0, 0.10)] p-3">
                            {row.original.audienceConfig.totalCount ? (
                                <Box className="mb-4">
                                    <Text className="text-xs font-semibold text-gray-600 mb-2 uppercase">
                                        {t(
                                            'campaign_builder.audience.estimated_reach',
                                        )}
                                    </Text>
                                    <Text className="font-medium text-gray-800">
                                        {getFormattedCount(
                                            row.original.audienceConfig
                                                .totalCount,
                                        )}{' '}
                                        users
                                    </Text>
                                </Box>
                            ) : null}
                            <Box className="mb-4">
                                <Text className="text-xs font-semibold text-gray-600 mb-2 uppercase">
                                    {t('campaign_builder.audience.send_list')}
                                </Text>
                                {renderSendList}
                            </Box>
                            <Box className="mb-4">
                                <Text className="text-xs font-semibold text-gray-600 mb-2 uppercase">
                                    {t(
                                        'campaign_builder.audience.suppress_list',
                                    )}
                                </Text>
                                {renderSuppressList}
                            </Box>
                        </HoverCard.Dropdown>
                    </HoverCard>
                </Flex>
            );
        }
        if (row.original.audienceName || row.original.csvUploadDetails) {
            return (
                <>
                    <AudienceCount data={row.original} />
                    <UserCount
                        count={count}
                        withRightSection={false}
                        leftSection={false}
                    />
                </>
            );
        }
        return <Text>-</Text>;
    }, [count, renderSendList, renderSuppressList, row.original, t]);

    if (isAnalyticsOverviewLoading && isAnalyticsOverviewEnabled) {
        return (
            <Box>
                <Skeleton height={20} width={100} className="mb-2" />
                <Skeleton height={10} width={50} />
            </Box>
        );
    }

    if (analyticsOverview && Object.keys(analyticsOverview).length) {
        const totalRequests =
            analyticsOverview[
                CampaignAnalyticsOverviewStatuses.TOTAL_REQUESTS
            ] ?? 0;
        const delivered =
            analyticsOverview[CampaignAnalyticsOverviewStatuses.DELIVERED] ?? 0;
        const deliveryPercentage = totalRequests
            ? parseFloat(((delivered / totalRequests) * 100).toFixed(1))
            : 0;
        return (
            <Box>
                <Group spacing={4} align="center">
                    <PaperPlaneTilt color="rgb(var(--color-blu-800))" />
                    <Text className="text-gray-800 font-medium">
                        Sent to {totalRequests || count} user
                        {(totalRequests || count) > 1 ? 's' : ''}
                    </Text>
                </Group>
                <Text className="text-xs text-gray-600">
                    {deliveryPercentage}% delivered
                </Text>
            </Box>
        );
    }

    return <Box>{renderContent}</Box>;
};

export default AudienceAndAnalyticsCell;
