import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import { useLocale } from '@hooks/useLocale';
import { type OrganizationMemberProfile } from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import useApp from '@providers/App/useApp';
import { type ColumnDef } from '@tanstack/react-table';
import { capitalizeFirstCharacter } from '@utils/helpers';
import { useEffect, useState } from 'react';
import { OrganisationMenuItem } from './OrganisationMenuItem';

export const useOrganizationMembersColumns = () => {
    const { user } = useApp();
    const [columns, setColumns] =
        useState<ColumnDef<OrganizationMemberProfile>[]>();
    const { t } = useLocale();
    const ability = useAbilityContext();
    const canModifyMembers = ability.can(
        'manage',
        subject('Organization', {
            organizationUuid: user.data?.organizationUuid,
        }),
    );
    useEffect(() => {
        setColumns([
            {
                accessorKey: 'name',
                header: t('organization_members_table.th_name'),
                sortingFn: (rowA, rowB) => {
                    const labelA =
                        rowA.original.firstName + ' ' + rowA.original.lastName;
                    const labelB =
                        rowB.original.firstName + ' ' + rowB.original.lastName;
                    if (
                        typeof labelA === 'string' &&
                        typeof labelB === 'string'
                    ) {
                        return labelA.localeCompare(labelB);
                    }
                    return 0;
                },
                cell: ({ row }) => (
                    <Box className="flex flex-col gap-0.5 justify-center items-start">
                        <Text className="text-sm font-medium text-gray-800 cursor-pointer">
                            {row.original.firstName} {row.original.lastName}
                            {row.original.userUuid === user.data?.userUuid && (
                                <span className="font-normal text-gray-500">
                                    {' '}
                                    {t(
                                        'workspace_members_table.tr_name_logged_in_user_identifier',
                                    )}
                                </span>
                            )}
                        </Text>

                        {row.original.email && (
                            <div>
                                <Text className="inline text-xs font-normal text-gray-600">
                                    {row.original.email}{' '}
                                </Text>
                                {!row.original.isActive && (
                                    <Text
                                        variant="outline"
                                        size="xs"
                                        className={`inline rounded border px-1 mx-1' ${
                                            row.original.isInviteExpired
                                                ? 'border-red-100 text-red-400'
                                                : 'border-amber-100 text-amber-400'
                                        }`}
                                    >
                                        {row.original.isInviteExpired
                                            ? t(
                                                  'organization_settings.admin_invite_expired_button',
                                              )
                                            : t(
                                                  'organization_settings.admin_invite_sent_button',
                                              )}
                                    </Text>
                                )}
                            </div>
                        )}
                    </Box>
                ),
                size: 600,
            },
            {
                accessorKey: 'role',
                header: t('organization_members_table.th_role'),
                cell: ({ getValue }) => (
                    <Text className="text-xs font-normal text-gray-800">
                        {capitalizeFirstCharacter(getValue<string>())}
                    </Text>
                ),
            },
            {
                accessorKey: 'menu',
                header: '',
                enableSorting: false,
                cell: ({ row }) => {
                    return canModifyMembers ? (
                        <OrganisationMenuItem rowData={row} />
                    ) : null;
                },
                size: 80,
            },
        ]);
    }, [t, user, canModifyMembers]);

    return columns;
};
