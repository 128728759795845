import { Flex } from '@mantine/core';
import { type ToggleOptionProps } from './types';

const ToggleOption: React.FC<ToggleOptionProps> = ({
    option,
    isSelected,
    onSelectedOptionChange,
    enabled,
}) => (
    <Flex
        className={`text-gray-800 p-2 ${
            isSelected ? 'bg-gray-200' : ''
        } cursor-pointer ${
            enabled ? 'cursor-pointer' : 'cursor-not-allowed'
        } transition-colors duration-300 ease-in-out`}
        align="center"
        gap={4}
        onClick={() => enabled && onSelectedOptionChange(option.value)}
    >
        {option.icon}
        {option.label}
    </Flex>
);

export default ToggleOption;
