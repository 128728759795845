import {
    DimensionType,
    FieldType,
    JourneyEventNames,
    RelationTableType,
} from '@lightdash/common';
import { JOURNEY_EVENT_TABLE_NAME } from '@utils/constants';
import { t as translate } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { getJourneyEventLabel } from '../FilterRules/DerivedMetric/JourneyEventFilters/utils';
const convertToDimension = (eventName: JourneyEventNames) => ({
    type: DimensionType.STRING,
    tableType: RelationTableType.EVENT,
    fieldType: FieldType.DIMENSION as const,
    name: eventName,
    tableLabel: translate('common.journeys'),
    table: JOURNEY_EVENT_TABLE_NAME,
    label: getJourneyEventLabel(eventName),
    suggestions: [],
    uuid: uuidv4(),
    sql: '',
    hidden: false,
    fieldReference: eventName,
});
export const useFieldsWithJourneyEvents = () => {
    const journeyEvents = [
        JourneyEventNames.JOURNEY_ENTERED,
        JourneyEventNames.JOURNEY_EXITED,
        JourneyEventNames.JOURNEY_CONVERTED,
    ];
    return journeyEvents.map(convertToDimension);
};
