import { CommunicationChannel } from '@lightdash/common';
import { t as translate } from 'i18next';

export type DropdownOptions<T> = {
    label: string;
    value: T;
}[];

export const MESSAGE_TYPE_OPTIONS: DropdownOptions<CommunicationChannel> = [
    {
        label: translate(
            'workspace_settings.delivery_controls.frequency_capping.message_type.any',
        ),
        value: CommunicationChannel.ANY,
    },
    {
        label: translate(
            'workspace_settings.delivery_controls.frequency_capping.message_type.email',
        ),
        value: CommunicationChannel.EMAIL,
    },
    {
        label: translate(
            'workspace_settings.delivery_controls.frequency_capping.message_type.sms',
        ),
        value: CommunicationChannel.SMS,
    },
    {
        label: translate(
            'workspace_settings.delivery_controls.frequency_capping.message_type.whatsapp',
        ),
        value: CommunicationChannel.WHATSAPP,
    },
];
