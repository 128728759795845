import {
    type CreateEventMapperSchema,
    type MapperSchema,
} from '@lightdash/common';
import {
    type EventBuilderStep,
    type PropertiesType,
} from '../../components/EventsManager/types';

export enum ActionType {
    SET_CURRENT_STEP,
    SET_CURRENT_STEP_CALLBACK,
    SET_PREVIOUS_STEP_CALLBACK,
    SET_SHOW_FOOTER_BUTTONS,
    SET_JSON_PAYLOAD,
    SET_JSON_PAYLOAD_KEYS,
    SET_TOGGLE_IS_CLONE_EVENT,
    SET_SOURCE,
    SET_MAPPER_SCHEMA,
    SET_EVENT_NAME,
    SET_EVENT_LABEL,
    SET_JSON_STRING,
}

export interface CurrentStepCallbackProps {
    callback: (() => void) | null;
    skipExecutionAfterCallback?: boolean;
}

export interface EventReducerState {
    eventPayload: CreateEventMapperSchema;
    isCloneEvent: boolean;
    jsonPayloadKeys: PropertiesType[];
    currentStep: EventBuilderStep;
    currentStepCallback: (() => boolean) | null;
    previousStepCallback: (() => boolean) | null;
    showFooterButtons: {
        next: boolean;
        disableNext: boolean;
        back: boolean;
    };
    initialEventPayload: CreateEventMapperSchema;
    jsonString: string | undefined;
}
export interface EventState extends EventReducerState {
    isValidStep: (step: EventBuilderStep) => boolean;
    isViewMode: boolean;
    isEditMode: boolean;
    isNewMode: boolean;
    isDuplicateMode: boolean;
    uuid: string;
    source: string;
}

export interface EventContext {
    state: EventState;
    actions: {
        setCurrentStep: (value: EventBuilderStep) => void;
        setCurrentStepCallback: ({
            callback,
            skipExecutionAfterCallback,
        }: CurrentStepCallbackProps) => void;
        setPreviousStepCallback: ({
            callback,
            skipExecutionAfterCallback,
        }: CurrentStepCallbackProps) => void;
        setShowFooterButtons: (value: {
            next: boolean;
            disableNext: boolean;
            back: boolean;
        }) => void;
        setJsonPayload: (jsonData: Record<string, unknown>) => void;
        setJsonPayloadKeys: (jsonData: PropertiesType[]) => void;
        toggleIsCloneEvent: (isCloned: boolean) => void;
        setSource: (source: string) => void;
        setMapperSchema: (mapperSchema: Record<string, MapperSchema>) => void;
        setEventName: (eventName: string) => void;
        setEventLabel: (eventLabel: string) => void;
        mutateEvent: () => Promise<void>;
        setJsonString: (jsonString: string) => void;
    };
}
