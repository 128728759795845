import LoadingState from '@components/common/LoadingState';
import { useLocale } from '@hooks/useLocale';
import {
    Accordion,
    Box,
    Button,
    Flex,
    Loader as LoaderIcon,
    Text,
} from '@mantine/core';
import { CalendarBlank, CaretDown } from '@phosphor-icons/react';
import { useState } from 'react';
import { ButtonVariant } from '../../../../../mantineTheme';
import { formatDate, getFormattedTime } from '../../utils';
import { type EventsAccordionProps } from './types';

export default function EventsHistoryAccordion<TData>({
    events,
    onLoadMore,
    isInitialLoading,
    eventName,
    eventPanel,
    canLoadMore,
    isLoadingMore,
}: EventsAccordionProps<TData>) {
    const { t } = useLocale();
    const [activeAccordion, setActiveAccordion] = useState<string | null>(null);
    const toggleAccordion = (id: string) => {
        setActiveAccordion((prev) => (prev === id ? null : id));
    };
    if (isInitialLoading) {
        return <LoadingState title={''} />;
    }
    if (events.length === 0) {
        return (
            <Flex
                justify="center"
                align="center"
                h="100%"
                direction="column"
                gap={24}
            >
                <Text className="text-gray-500">
                    {t('profiles_event_history.no_events')}
                </Text>
            </Flex>
        );
    }
    return (
        <>
            <Accordion
                variant="filled"
                styles={{
                    item: {
                        backgroundColor: 'transparent !important',
                        '&[data-active]': {
                            border: '1px solid rgb(var(--color-gray-200)) !important',
                        },
                    },
                }}
                chevron={null}
            >
                {events?.map((item, index) => {
                    return (
                        <Box key={item.id}>
                            {item.showDateHeader && (
                                <Flex
                                    align="center"
                                    gap={4}
                                    className={`mb-2 text-sm font-medium text-gray-800 ${
                                        index !== 0 ? 'border-t pt-2' : ''
                                    } border-shade-6`}
                                >
                                    <CalendarBlank
                                        weight="duotone"
                                        color="rgb(var(--color-gray-800))"
                                    />
                                    <Text>{formatDate(item.currentDate)}</Text>
                                </Flex>
                            )}
                            <Accordion.Item value={item.id.toString()}>
                                <Accordion.Control
                                    className={` ${
                                        item.isReadonly
                                            ? 'cursor-default'
                                            : 'hover:bg-shade-4'
                                    }`}
                                    onClick={() =>
                                        !item.isReadonly &&
                                        toggleAccordion(item.id.toString())
                                    }
                                >
                                    <Flex gap={12} align="center">
                                        <Text className="text-sm text-gray-500">
                                            {getFormattedTime(item.timestamp)}
                                        </Text>
                                        <Text className="text-sm font-medium text-gray-800">
                                            {item.name}
                                        </Text>
                                        {eventName(item)}
                                        {!item.isReadonly && (
                                            <CaretDown
                                                weight="duotone"
                                                className={`transition-transform duration-300 ${
                                                    activeAccordion ===
                                                    item.id.toString()
                                                        ? 'rotate-180'
                                                        : ''
                                                }`}
                                            />
                                        )}
                                    </Flex>
                                </Accordion.Control>
                                {activeAccordion === item.id.toString() && (
                                    <Accordion.Panel className="ps-[4.875rem]">
                                        {eventPanel(item)}
                                    </Accordion.Panel>
                                )}
                            </Accordion.Item>
                        </Box>
                    );
                })}
            </Accordion>
            {canLoadMore && (
                <Button
                    onClick={onLoadMore}
                    variant={ButtonVariant.SUBTLE_ACCENTED}
                    className="mt-4"
                    leftIcon={
                        isLoadingMore ? (
                            <LoaderIcon
                                color="rgb(var(--color-blu-800))"
                                size={14}
                            />
                        ) : null
                    }
                    disabled={isLoadingMore}
                >
                    {isLoadingMore
                        ? t('common.loading')
                        : t('common.load_more')}
                </Button>
            )}
        </>
    );
}
