import {
    SetupContentSource,
    type ContentStepComponentProps,
} from '@components/Campaigns/Builder/types';
import { getPreviousContentStep } from '@components/Campaigns/Builder/utils';
import TestCommunication from '@components/TestCommunication';
import { TestCommunicationSource } from '@components/TestCommunication/types';
import usePreviewDimensions from '@components/TestCommunication/usePreviewDimensions';
import { useTestCampaign, useTestCampaignOldFlow } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { useGetSyncedTemplateByTemplateName } from '@hooks/useTemplate';
import { useGetTestProfiles } from '@hooks/useTestProfiles';
import {
    AudienceType,
    CommunicationChannel,
    CommunicationChannelName,
    DefaultWhatsappHeaderVariablesMap,
    WAHeaderType,
    WhatsappHeaderVariable,
    type AnyType,
    type ApiSqlQueryResults,
    type ContentMappingSection,
    type EmailTemplateContentDetails,
    type ExternalTemplate,
    type SmsTemplateContentDetails,
    type TemplateContentDetails,
    type TestCampaignRequest,
    type TestCampaignRequestv1,
} from '@lightdash/common';
import { Box, Button, Flex } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ButtonVariant } from '../../../../../../mantineTheme';

const CampaignTestCommunication: React.FC<ContentStepComponentProps> = ({
    setActiveContentStep,
    activeContentStep,
    source,
    variablesMappedWithJourneyParams,
    templateMetadata,
}) => {
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const { t } = useLocale();

    const { setPreviousStepCallback, setPreviewUserData } = useCampaignContext(
        (context) => context.actions,
    );
    const { campaignPayload } = useCampaignContext((context) => context.state);
    const { communicationDetails, templateDetails } = campaignPayload;
    const { previewDimensions, getPreviewUserFormattedData } =
        usePreviewDimensions({
            contentMappings: campaignPayload.contentMappings,
        });
    const {
        mutateAsync: triggerTestMutation,
        isLoading: isTestLoadingNewFlow,
    } = useTestCampaign();
    const {
        mutateAsync: triggerTestMutationOldFlow,
        isLoading: isTestLoadingOldFlow,
    } = useTestCampaignOldFlow(
        communicationDetails?.providerId ?? '',
        communicationDetails?.id ?? '',
    );

    const { data: syncedTemplateMetadata } = useGetSyncedTemplateByTemplateName(
        (templateDetails?.integrationId || communicationDetails?.id) ?? '',
        templateDetails?.id ?? '',
        campaignPayload?.channel === CommunicationChannel.WHATSAPP,
    );

    const [selectedTestProfiles, setSelectedTestProfiles] = useState<string[]>(
        [],
    );
    const [testProfilesOptions, setTestProfilesOptions] = useState<
        { label: string; value: string }[]
    >([]);

    const [mappings, setMappings] = useState(
        campaignPayload.contentMappings ?? {},
    );

    const handleChange = (
        value: string,
        variable: string,
        section: ContentMappingSection,
    ) => {
        setMappings({
            ...mappings,
            [section]: {
                ...mappings[section],
                [variable]: {
                    ...mappings?.[section]?.[variable],
                    defaultValue: value,
                },
            },
        });
    };

    const { data } = useGetTestProfiles({
        successCallback: (payload) => {
            if (payload && payload.length) {
                setSelectedTestProfiles([
                    ...payload
                        .map(
                            (item: any) =>
                                item[
                                    campaignPayload.channel ??
                                        CommunicationChannel.EMAIL
                                ],
                        )
                        .filter((item: any) => item),
                ]);
                setTestProfilesOptions([
                    ...payload.map(
                        (profile: { name: string; [key: string]: string }) => ({
                            label: profile.name,
                            value: profile?.[
                                campaignPayload.channel ??
                                    CommunicationChannel.EMAIL
                            ],
                        }),
                    ),
                ]);
            }
        },
    });

    useEffect(() => {
        if (data && data.length && campaignPayload?.channel) {
            setSelectedTestProfiles([
                ...data
                    .map(
                        (item: any) =>
                            item[
                                campaignPayload.channel ??
                                    CommunicationChannel.EMAIL
                            ],
                    )
                    .filter((item: any) => item),
            ]);
            setTestProfilesOptions([
                ...data
                    .filter(
                        (profile: AnyType) =>
                            profile?.[
                                campaignPayload.channel ??
                                    CommunicationChannel.EMAIL
                            ],
                    )
                    .map((profile: any) => ({
                        label: profile.name,
                        value: profile?.[
                            campaignPayload.channel ??
                                CommunicationChannel.EMAIL
                        ],
                    })),
            ]);
        }
    }, [campaignPayload.channel, data]);

    useEffect(() => {
        const prevStep = getPreviousContentStep(
            activeContentStep,
            campaignPayload?.channel,
            campaignPayload.channel === CommunicationChannel.WHATSAPP
                ? syncedTemplateMetadata
                : templateMetadata,
        );
        const handlePrevStep = () => {
            if (prevStep) {
                setActiveContentStep(prevStep, undefined);
            }
            return;
        };

        setPreviousStepCallback({
            callback: handlePrevStep,
            skipExecutionAfterCallback: true,
        });
    }, [
        activeContentStep,
        setActiveContentStep,
        setPreviousStepCallback,
        communicationDetails,
        templateMetadata,
        campaignPayload.channel,
        syncedTemplateMetadata,
    ]);

    const triggerTestCommunication = useCallback(async () => {
        if (!campaignPayload.channel) return;

        if (campaignUuid) {
            const payload: TestCampaignRequest = {
                to: selectedTestProfiles.map((id) => ({
                    [campaignPayload?.channel ?? CommunicationChannel.EMAIL]:
                        id,
                })),
            };
            await triggerTestMutation({ payload, campaignId: campaignUuid });
        } else {
            // TODO - Remove this once we honor the new test flow in journeys
            const mappingsPayload = { ...mappings };

            if (!mappingsPayload) return;
            Object.keys(mappingsPayload).forEach((groupKey) => {
                const mapping =
                    mappingsPayload[groupKey as ContentMappingSection] ?? {};
                Object.keys(mapping).forEach((key) => {
                    if (
                        key === WhatsappHeaderVariable &&
                        campaignPayload.channel ===
                            CommunicationChannel.WHATSAPP &&
                        !mapping[key].defaultValue
                    ) {
                        mapping[key].defaultValue =
                            DefaultWhatsappHeaderVariablesMap[
                                syncedTemplateMetadata?.languages?.[0]?.content
                                    ?.header?.type ?? WAHeaderType.IMAGE
                            ];
                    }
                });
            });
            let templateContent: TemplateContentDetails | undefined = undefined;
            let templateTestDetails = undefined;
            if (campaignPayload.channel === CommunicationChannel.EMAIL) {
                templateContent =
                    campaignPayload.contentDetails &&
                    !isEmpty(campaignPayload.contentDetails)
                        ? { ...campaignPayload.contentDetails }
                        : templateMetadata
                        ? {
                              ...(templateMetadata as EmailTemplateContentDetails),
                          }
                        : {
                              subject: '',
                              html: '',
                          };
            } else if (campaignPayload.channel === CommunicationChannel.SMS) {
                templateContent =
                    (templateMetadata as SmsTemplateContentDetails) ?? {
                        body: '',
                        dltTemplateId: '',
                        unicode: false,
                        flash: false,
                    };
            }
            if (campaignPayload.channel === CommunicationChannel.WHATSAPP) {
                templateTestDetails = {
                    id: syncedTemplateMetadata?.name ?? '',
                    integrationId: syncedTemplateMetadata?.integrationId ?? '',
                };
            }
            const payload: TestCampaignRequestv1 = {
                to: selectedTestProfiles.map((id) => ({
                    [campaignPayload?.channel ?? CommunicationChannel.EMAIL]:
                        id,
                })),
                channel: campaignPayload.channel,
                mappings: mappingsPayload,
                templateContent,
                templateDetails: templateTestDetails || {
                    name: (templateMetadata as ExternalTemplate)?.name || '',
                },
                previewText: campaignPayload?.previewText,
            };
            await triggerTestMutationOldFlow({ payload });
        }
    }, [
        campaignPayload.channel,
        campaignPayload?.previewText,
        campaignPayload.contentDetails,
        campaignUuid,
        selectedTestProfiles,
        triggerTestMutation,
        mappings,
        templateMetadata,
        triggerTestMutationOldFlow,
        syncedTemplateMetadata?.languages,
        syncedTemplateMetadata?.name,
        syncedTemplateMetadata?.integrationId,
    ]);

    const sendTestCommTitle = useMemo(() => {
        if (!campaignPayload.channel) return '';
        let channelLabel = CommunicationChannelName[campaignPayload.channel];
        if (campaignPayload.channel === CommunicationChannel.EMAIL) {
            channelLabel = channelLabel.toLowerCase();
        }
        return t('campaigns_builder.send_test_communication', {
            channel: channelLabel,
        });
    }, [campaignPayload.channel, t]);

    const isSendTestDisabled = useMemo(() => {
        return !selectedTestProfiles.length;
    }, [selectedTestProfiles.length]);

    const previewUserCallback = (payload: ApiSqlQueryResults) => {
        const res = getPreviewUserFormattedData(payload);
        setPreviewUserData(res);
    };

    return (
        <Box>
            <TestCommunication
                source={
                    source === SetupContentSource.CAMPAIGN
                        ? TestCommunicationSource.CAMPAIGNS
                        : TestCommunicationSource.JOURNEYS
                }
                journeyVariables={variablesMappedWithJourneyParams ?? {}}
                mappings={mappings}
                testProfilesOptions={testProfilesOptions}
                setSelectedTestProfiles={setSelectedTestProfiles}
                setTestProfilesOptions={setTestProfilesOptions}
                selectedTestProfiles={selectedTestProfiles}
                handleChange={handleChange}
                channel={campaignPayload.channel ?? CommunicationChannel.EMAIL}
                isPreviewDisabled={
                    source === SetupContentSource.CAMPAIGN &&
                    campaignPayload.audienceType === AudienceType.CSV
                }
                previewDimensions={previewDimensions}
                previewUserCallback={previewUserCallback}
            />
            <Flex justify={'flex-end'} className="mt-6">
                <Button
                    loading={isTestLoadingNewFlow || isTestLoadingOldFlow}
                    onClick={triggerTestCommunication}
                    rightIcon={
                        <CaretRight
                            color={
                                isSendTestDisabled
                                    ? 'rgb(var(--color-gray-600))'
                                    : 'rgb(var(--color-blu-800))'
                            }
                        />
                    }
                    variant={ButtonVariant.OUTLINED_ACCENTED}
                    disabled={isSendTestDisabled}
                >
                    {sendTestCommTitle}
                </Button>
            </Flex>
        </Box>
    );
};

export default React.memo(CampaignTestCommunication);
