import { useGetAudienceEstimatedCount } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { AudienceType, type CampaignAudienceConfig } from '@lightdash/common';
import { Button, Flex, Stack, Text } from '@mantine/core';
import { ArrowsClockwise } from '@phosphor-icons/react';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { getFormattedCount } from '@utils/index';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonVariant } from '../../../../../mantineTheme';

const CountSection = () => {
    const { t } = useLocale();
    const initialData = useRef<CampaignAudienceConfig | undefined>(undefined);
    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload } = state;
    const { setAudienceCount } = actions;
    const [isCountOutdated, setIsCountOutdated] = useState(false);

    const { mutateAsync: estimateCount, isLoading: isLoadingAudienceCount } =
        useGetAudienceEstimatedCount();

    useEffect(() => {
        if (!initialData.current) {
            initialData.current = campaignPayload.audienceConfig;
        }
    }, [campaignPayload.audienceConfig]);

    useEffect(() => {
        if (initialData.current && campaignPayload.audienceConfig) {
            if (
                !isEqual(
                    initialData.current.config,
                    campaignPayload.audienceConfig.config,
                )
            ) {
                setIsCountOutdated(true);
            } else {
                setIsCountOutdated(false);
            }
        }
    }, [campaignPayload.audienceConfig]);

    const handleCalculateEstimate = useCallback(async () => {
        if (campaignPayload.channel && campaignPayload.audienceConfig) {
            const response = await estimateCount({
                channel: campaignPayload.channel,
                campaignAudienceConfig: campaignPayload.audienceConfig,
            });
            setIsCountOutdated(false);
            setAudienceCount(response as unknown as number);
        }
    }, [
        campaignPayload.audienceConfig,
        campaignPayload.channel,
        estimateCount,
        setAudienceCount,
    ]);

    if (
        (campaignPayload.audienceConfig?.totalCount !== null &&
            campaignPayload.audienceConfig?.totalCount !== undefined) ||
        campaignPayload.csvUploadDetails?.audienceCount
    ) {
        return (
            <Stack spacing={0}>
                <Flex gap={8} align={'center'} justify={'center'}>
                    <Text className="text-[28px] font-medium text-gray-800 text-center">
                        {campaignPayload.audienceType === AudienceType.WAREHOUSE
                            ? getFormattedCount(
                                  campaignPayload.audienceConfig?.totalCount ??
                                      0,
                              )
                            : campaignPayload.csvUploadDetails?.audienceCount}
                    </Text>
                    {isCountOutdated && (
                        <Button
                            onClick={handleCalculateEstimate}
                            loading={isLoadingAudienceCount}
                            leftIcon={
                                <ArrowsClockwise
                                    color="rgb(var(--color-gray-600))"
                                    weight="regular"
                                />
                            }
                            variant={ButtonVariant.OUTLINED}
                        >
                            {t('common.refresh')}
                        </Button>
                    )}
                </Flex>
                <Text className="text-lg text-gray-500">
                    {t('campaign_builder.audience.estimated_reach')}
                </Text>
            </Stack>
        );
    }
    return (
        <Button
            onClick={handleCalculateEstimate}
            loading={isLoadingAudienceCount}
            leftIcon={
                <ArrowsClockwise
                    color="rgb(var(--color-gray-600))"
                    weight="regular"
                />
            }
            variant={ButtonVariant.OUTLINED}
        >
            {t('campaign_builder.audience.calculate_estimate')}
        </Button>
    );
};

export default CountSection;
