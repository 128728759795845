export enum RelationTableType {
    PRIMARY = 'PRIMARY',
    RELATED = 'RELATED',
    AUDIENCE = 'AUDIENCE',
    EVENT = 'EVENT',
    MISCELLANEOUS = 'MISCELLANEOUS',
    CATALOG = 'CATALOG',
}

export enum EventType {
    SINGLE = 'SINGLE',
    CLICKSTREAM = 'CLICKSTREAM',
}

export enum JoinType {
    one_one = 'one_one',
    one_many = 'one_many',
    many_one = 'many_one',
    many_many = 'many_many',
}

export enum ShowDataType {
    LIST = 'list',
    GRID = 'grid',
    INFINITY_SCROLL = 'infinity_scrol',
}
