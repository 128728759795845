import { useLocale } from '@hooks/useLocale';
import { Box, Text } from '@mantine/core';
import { useMemo, useState } from 'react';
import CustomMetricTable from './Table/CustomMetricTable';
import { CustomMetricTab, type CustomMetricManagerContentProps } from './types';

const labelBox = (tabLabel: string, tabCount: number) => (
    <Box className="flex gap-1">
        <Text>{tabLabel}</Text>
        <Text className="text-sm text-gray-600">{tabCount}</Text>
    </Box>
);

const getTabConfig = (
    t: (key: string) => string,
    calculatedCount: number,
    dynamicCount: number,
) => [
    {
        key: CustomMetricTab.CALCULATED.toString(),
        label: labelBox(t('custom_metric.tabs.calculated'), calculatedCount),
        value: CustomMetricTab.CALCULATED.toString(),
    },
    {
        key: CustomMetricTab.DYNAMIC.toString(),
        label: labelBox(t('custom_metric.tabs.dynamic'), dynamicCount),
        value: CustomMetricTab.DYNAMIC.toString(),
    },
];

const CustomMetricManagerContent: React.FC<CustomMetricManagerContentProps> = ({
    updateTraits,
    customMetrics,
    isHiddenActive,
    onHiddenChange,
    hiddenCustomMetrics,
}) => {
    const { t } = useLocale();
    const [selectedTab, setSelectedTab] = useState<CustomMetricTab>(
        CustomMetricTab.CALCULATED,
    );
    const metrics = useMemo(() => {
        return {
            activePill: selectedTab,
            calculatedMetrics: isHiddenActive
                ? hiddenCustomMetrics ?? []
                : customMetrics ?? [],
            dynamicMetrics: updateTraits?.config?.fieldConfig
                ? Object.entries(updateTraits.config.fieldConfig).map(
                      ([key, value]) => ({
                          ...value,
                          name: key,
                      }),
                  )
                : [],
        };
    }, [
        customMetrics,
        hiddenCustomMetrics,
        isHiddenActive,
        selectedTab,
        updateTraits,
    ]);

    return (
        <>
            <CustomMetricTable
                customMetrics={metrics.calculatedMetrics ?? []}
                isHiddenActive={isHiddenActive}
                onHiddenChange={onHiddenChange}
                hideHiddenButton={(hiddenCustomMetrics?.length ?? 0) === 0}
                tabs={getTabConfig(
                    t,
                    metrics.calculatedMetrics.length,
                    Object.keys(updateTraits?.config.fieldConfig ?? {}).length,
                )}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                updateTraits={updateTraits}
            />
        </>
    );
};
export default CustomMetricManagerContent;
