import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import Manager from '@components/Manager';
import { useLocale } from '@hooks/useLocale';
import { ShowDataType, type Pagination } from '@lightdash/common';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import { useProfileColumns } from './useProfileTableUtils';
interface ProfileTableProps {
    profiles: Record<string, unknown>[];
    rightSection?: React.ReactNode;
    pagination: Pagination;
    onPageChange: (page: number) => void;
    onSearchChange: (newSearchString: string) => void;
    fields: string[] | undefined;
    id: string | undefined;
    searchValue: string;
    isDataFetching: boolean;
    disablePagination?: boolean;
    onSuccessInControlledFetching: boolean;
    isNeedToRefresh: boolean;
    onNeedToRefresh: (isNeedToRefresh: boolean) => void;
}

const ProfileTable: React.FC<ProfileTableProps> = ({
    rightSection,
    profiles,
    pagination,
    onPageChange,
    fields,
    id,
    onSearchChange,
    searchValue,
    isDataFetching,
    disablePagination,
    onSuccessInControlledFetching,
    isNeedToRefresh,
    onNeedToRefresh,
}) => {
    const { t } = useLocale();
    const { columns } = useProfileColumns(fields ?? []);
    const { activeRelation } = useRelationContext();
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });

    const navigate = useNavigate();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    return (
        <div>
            {profiles && id && (
                <Manager
                    isDataFetching={isDataFetching}
                    name={t('profiles')}
                    tableData={profiles}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: columns,
                        },
                    ]}
                    searchPlaceholder={t(
                        'profiles_manager.table_search_placeholder',
                        {
                            primary_key: fieldsWithSuggestions[id]?.label ?? '',
                        },
                    )}
                    paginationText={t('profiles_manager.pagination_text')}
                    rightSection={rightSection}
                    isManualPagination={true}
                    pagination={pagination}
                    onPageChange={onPageChange}
                    onSearchChange={onSearchChange}
                    customClass={`overflow-x-scroll`}
                    isEditable={false}
                    handleRowClick={(row) => {
                        void navigate(
                            `/projects/${projectUuid}/profiles/${row.original[id]}`,
                        );
                    }}
                    searchValue={searchValue}
                    disablePagination={disablePagination}
                    isControlledFetching={true}
                    onSuccessInControlledFetching={
                        onSuccessInControlledFetching
                    }
                    isNeedToRefresh={isNeedToRefresh}
                    onNeedToRefresh={onNeedToRefresh}
                />
            )}
        </div>
    );
};

export default React.memo(ProfileTable);
