import { useIsTruncated } from '@hooks/useIsTruncated';
import { useLocale } from '@hooks/useLocale';
import { Group, Text, Tooltip } from '@mantine/core';
import { CaretRight, FlowArrow } from '@phosphor-icons/react';

interface JourneyListItemTemplateProps {
    journeyName: string;
    className?: string;
}

const JourneyListItemTemplate: React.FC<JourneyListItemTemplateProps> = ({
    journeyName,
    className,
}) => {
    const { t } = useLocale();
    const { ref: truncatedRef, isTruncated } = useIsTruncated<HTMLDivElement>();
    return (
        <Group className="items-center max-w-full gap-1 overflow-hidden">
            <FlowArrow color={'rgb(var(--color-orange-800))'} />
            <Text className="text-sm font-medium text-gray-500  max-w-[7.5rem] truncate">
                {t('common.journeys')}
            </Text>
            <CaretRight color={'rgb(var(--color-gray-800))'} />

            <Tooltip
                withinPortal
                variant="xs"
                label={journeyName}
                disabled={!isTruncated}
            >
                <Text
                    ref={truncatedRef}
                    className={`!m-0 rounded text-sm text-gray-800 font-medium max-w-[7.5rem] truncate ${className}`}
                >
                    {journeyName}
                </Text>
            </Tooltip>
        </Group>
    );
};
export default JourneyListItemTemplate;
