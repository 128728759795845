import { campaignEventFieldId } from '@components/Audience/utils';
import {
    FilterGroupOperator,
    FilterOperator,
    isAndFilterGroup,
    isOrFilterGroup,
    ReservedCampaignEventColumns,
    type AndFilterGroup,
    type FilterGroupItem,
    type MetricQuery,
    type NestedMetricQuery,
    type NestedMetricQueryGroup,
    type OrFilterGroup,
    type WarehouseTypes,
} from '@lightdash/common';
import { v4 as uuidv4 } from 'uuid';

/**
 *getting the filters from the NestedMetricQueryGroup
 * @param filterConfig - The filter config
 * @returns The filters
 */
export const getFiltersFromNestedMetricQueryGroup = (
    filterConfig: NestedMetricQueryGroup | undefined,
) => {
    if (!filterConfig) return undefined;
    if ('and' in filterConfig) {
        return filterConfig.and?.[0] as MetricQuery;
    }
    return filterConfig.or?.[0] as MetricQuery;
};

/**
 * Creates a default filter config for a channel
 * @param projectUuid - The project uuid
 * @param sourceLabel - The source label
 * @returns The default filter config
 */
export const createDefaultFilterConfigForChannel = (
    projectUuid: string,
    sourceLabel: string,
    warehouseType: WarehouseTypes,
) => ({
    id: uuidv4(),
    audienceFilters: {
        filterJoinType: FilterGroupOperator.and,
        filterConfig: {
            id: uuidv4(),
            and: [
                {
                    exploreName: '',
                    dimensions: [],
                    metrics: [],
                    filters: {
                        dimensions: {
                            id: uuidv4(),
                            and: [
                                {
                                    id: uuidv4(),
                                    target: {
                                        fieldId: campaignEventFieldId(
                                            projectUuid,
                                            ReservedCampaignEventColumns.CHANNEL,
                                            warehouseType,
                                        ),
                                    },
                                    operator: FilterOperator.EQUALS,
                                    values: [sourceLabel],
                                },
                            ],
                        },
                    },
                    sorts: [],
                    limit: 500,
                    tableCalculations: [],
                    additionalMetrics: [],
                },
            ],
        },
        compiledAudienceId: undefined,
    },
    journeyFilters: undefined,
});
/**
 * Filters out the channel filter from the filter config
 * @param filterConfig - The filter config
 * @returns The filter config without the channel filter
 */
export const filterOutChannelFilter = (
    filterConfig: NestedMetricQueryGroup | undefined,
    projectUuid: string | undefined,
    warehouseType: WarehouseTypes,
) => {
    if (!filterConfig || !projectUuid) return undefined;

    const dimensions =
        getFiltersFromNestedMetricQueryGroup(filterConfig)?.filters?.dimensions;
    if (!dimensions) return filterConfig;

    let newFilters: FilterGroupItem[] = [];
    if ('and' in dimensions) {
        newFilters = dimensions.and.filter(
            (filter: any) =>
                filter.target?.fieldId !==
                campaignEventFieldId(
                    projectUuid,
                    ReservedCampaignEventColumns.CHANNEL,
                    warehouseType,
                ),
        );
    } else if (isOrFilterGroup(dimensions)) {
        newFilters = dimensions.or.filter(
            (filter) =>
                'target' in filter &&
                filter.target?.fieldId !==
                    campaignEventFieldId(
                        projectUuid,
                        ReservedCampaignEventColumns.CHANNEL,
                        warehouseType,
                    ),
        );
    }

    const isAnd = isAndFilterGroup(dimensions);
    return {
        ...filterConfig,
        and: [
            {
                ...getFiltersFromNestedMetricQueryGroup(filterConfig),
                filters: {
                    ...getFiltersFromNestedMetricQueryGroup(filterConfig)
                        ?.filters,
                    dimensions: {
                        id: dimensions.id,
                        ...(isAnd ? { and: newFilters } : { or: newFilters }),
                    },
                },
            },
        ],
    };
};

export const getChannelFilter = (
    filterConfig: NestedMetricQueryGroup | undefined,
    projectUuid: string | undefined,
    warehouseType: WarehouseTypes,
) => {
    if (!filterConfig || !projectUuid) return undefined;

    const config =
        getFiltersFromNestedMetricQueryGroup(filterConfig)?.filters?.dimensions;
    if (!config) return undefined;
    if ('and' in config) {
        return config.and.find(
            (filter: any) =>
                filter.target?.fieldId ===
                campaignEventFieldId(
                    projectUuid,
                    ReservedCampaignEventColumns.CHANNEL,
                    warehouseType,
                ),
        );
    }
    return config.or.find(
        (filter: any) =>
            filter.target?.fieldId ===
            campaignEventFieldId(
                projectUuid,
                ReservedCampaignEventColumns.CHANNEL,
                warehouseType,
            ),
    );
};

export const addCampaignChannelToMetricQuery = (
    metricQuery: NestedMetricQueryGroup | undefined,
    channelFilter: NestedMetricQuery | undefined,
) => {
    if (!metricQuery || !channelFilter) return metricQuery;
    const dimensions =
        getFiltersFromNestedMetricQueryGroup(metricQuery)?.filters?.dimensions;
    if (dimensions && isAndFilterGroup(dimensions)) {
        return {
            ...metricQuery,
            and: [
                {
                    ...getFiltersFromNestedMetricQueryGroup(metricQuery),
                    filters: {
                        ...getFiltersFromNestedMetricQueryGroup(metricQuery)
                            ?.filters,
                        dimensions: {
                            ...getFiltersFromNestedMetricQueryGroup(metricQuery)
                                ?.filters?.dimensions,
                            and: [
                                channelFilter,
                                ...(
                                    getFiltersFromNestedMetricQueryGroup(
                                        metricQuery,
                                    )?.filters?.dimensions as AndFilterGroup
                                )?.and,
                            ],
                        },
                    },
                },
            ],
        };
    }
    if (dimensions && isOrFilterGroup(dimensions)) {
        return {
            ...metricQuery,
            and: [
                {
                    ...getFiltersFromNestedMetricQueryGroup(metricQuery),
                    filters: {
                        ...getFiltersFromNestedMetricQueryGroup(metricQuery)
                            ?.filters,
                        dimensions: {
                            ...getFiltersFromNestedMetricQueryGroup(metricQuery)
                                ?.filters?.dimensions,
                            or: [
                                channelFilter,
                                ...(
                                    getFiltersFromNestedMetricQueryGroup(
                                        metricQuery,
                                    )?.filters?.dimensions as OrFilterGroup
                                )?.or,
                            ],
                        },
                    },
                },
            ],
        };
    }
};
