import { useLocale } from '@hooks/useLocale';
import { Accordion, Text } from '@mantine/core';
import { WarningCircle } from '@phosphor-icons/react';
import React from 'react';

interface ErrorMessageProps {
    message: string;
}

const ErrorMessage = ({ message }: ErrorMessageProps) => {
    const { t } = useLocale();
    return (
        <Accordion
            styles={() => ({
                item: {
                    backgroundColor: 'rgb(var(--color-halt-400))',
                    border: `1px solid rgb(var(--color-halt-800))`,
                    borderRadius: '10px',
                },
                control: {
                    borderRadius: '10px',
                    '&:hover': {
                        backgroundColor: 'unset !important',
                    },
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                },
                label: {
                    padding: '0px',
                },
                icon: {
                    marginRight: '4px',
                },
                chevron: {
                    color: 'rgb(var(--color-gray-700))',
                },
            })}
            transitionDuration={300}
        >
            <Accordion.Item value="error">
                <Accordion.Control icon={<WarningCircle />}>
                    <Text className="text-sm font-medium text-gray-800">
                        {t('ai_analyst.error_message')}
                    </Text>
                </Accordion.Control>
                <Accordion.Panel>
                    <Text className="text-xs text-gray-700">{message}</Text>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
};

export default React.memo(ErrorMessage);
