import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import { sanitizeCustomMetricName } from '@components/CustomAttribute/utils';
import { getCustomMetricName } from '@components/Explorer/CustomMetricModal/utils';
import {
    FieldType,
    snakeCaseName,
    type AdditionalMetric,
    type CompiledDimension,
    type CompiledRelationTable,
    type CustomDimension,
    type InsertCustomAttribute,
    type MetricFilterRule,
    type MetricQuery,
    type MetricType,
    type TableCalculation,
} from '@lightdash/common';
import React, { useCallback, useMemo, useReducer, type FC } from 'react';
import CustomAttributeContext from './context';
import { ActionType, type CustomAttributeReducerState } from './types';

type DefinitionType =
    | AdditionalMetric
    | TableCalculation
    | CustomDimension
    | null;
type Action =
    | {
          type: ActionType.SELECT_TABLE;
          payload: CompiledRelationTable | undefined;
      }
    | {
          type: ActionType.SELECT_DIMENSION;
          payload: CompiledDimension | FieldWithSuggestions | undefined;
      }
    | {
          type: ActionType.SET_FILTERS;
          payload: MetricQuery['filters'] | undefined;
      }
    | {
          type: ActionType.ADD_BASE_TABLE_DETAILS_TO_PAYLOAD;
          payload: CompiledRelationTable | undefined;
      }
    | {
          type: ActionType.ADD_DIMENSION_DETAILS_TO_PAYLOAD;
          payload: CompiledDimension | FieldWithSuggestions | undefined;
      }
    | {
          type: ActionType.ADD_CUSTOM_METRIC_NAME_DETAILS;
          payload: string;
      }
    | {
          type: ActionType.ADD_CUSTOM_METRIC_DESCRIPTION_DETAILS;
          payload: string;
      }
    | {
          type: ActionType.ADD_CUSTOM_METRIC_TYPE_DETAIL;
          payload: MetricType;
      }
    | {
          type: ActionType.ADD_FILTERS_TO_CUSTOM_METRIC;
          payload: MetricFilterRule[] | undefined;
      }
    | {
          type: ActionType.SET_CUSTOM_METRIC;
          payload: InsertCustomAttribute;
      }
    | {
          type: ActionType.SET_FIELD_TYPE;
          payload: FieldType;
      }
    | {
          type: ActionType.SET_SQL_IN_CUSTOM_SQL_DIMENSION;
          payload: string;
      }
    | {
          type: ActionType.SET_DEFINITION;
          payload: AdditionalMetric | CustomDimension | TableCalculation | null;
      }
    | {
          type: ActionType.SET_TAGS;
          payload: string[];
      }
    | {
          type: ActionType.RESET_STATE;
          payload: CustomAttributeReducerState;
      };

function reducer(
    state: CustomAttributeReducerState,
    action: Action,
): CustomAttributeReducerState {
    switch (action.type) {
        case ActionType.SELECT_TABLE:
            return {
                ...state,
                selectedTable: action.payload,
            };
        case ActionType.SELECT_DIMENSION:
            return {
                ...state,
                selectedDimension: action.payload,
            };
        case ActionType.SET_FILTERS: {
            return {
                ...state,
                filters: action.payload,
            };
        }
        case ActionType.ADD_BASE_TABLE_DETAILS_TO_PAYLOAD: {
            const tableName = action.payload?.name ?? '';
            const baseState = {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    srcTable: tableName,
                },
                initialCustomAttributePayload: {
                    ...state.initialCustomAttributePayload,
                    srcTable: tableName,
                },
            };

            if (state.fieldType === FieldType.METRIC) {
                const definitionUpdate = {
                    tableLabel: action.payload?.label,
                    table: tableName,
                };

                return {
                    ...baseState,
                    customAttributePayload: {
                        ...baseState.customAttributePayload,
                        definition: {
                            ...state.customAttributePayload?.definition,
                            ...definitionUpdate,
                        } as DefinitionType,
                    },
                    initialCustomAttributePayload: {
                        ...baseState.initialCustomAttributePayload,
                        definition: {
                            ...state.initialCustomAttributePayload?.definition,
                            ...definitionUpdate,
                        } as DefinitionType,
                    },
                };
            }

            const definitionUpdate = {
                table: tableName,
            };

            return {
                ...baseState,
                customAttributePayload: {
                    ...baseState.customAttributePayload,
                    definition: {
                        ...state.customAttributePayload?.definition,
                        ...definitionUpdate,
                    } as DefinitionType,
                },
                initialCustomAttributePayload: {
                    ...baseState.initialCustomAttributePayload,
                    definition: {
                        ...state.initialCustomAttributePayload?.definition,
                        ...definitionUpdate,
                    } as DefinitionType,
                },
            };
        }
        case ActionType.ADD_DIMENSION_DETAILS_TO_PAYLOAD: {
            return {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    name:
                        getCustomMetricName(
                            action.payload?.table ?? '',
                            sanitizeCustomMetricName(
                                action.payload?.name ?? '',
                            ),
                            action.payload?.name ?? '',
                        ) + Date.now(),
                    definition: {
                        ...state.customAttributePayload?.definition,
                        sql: action.payload?.sql ?? '',
                        baseDimensionName: action.payload?.name,
                        table: action.payload?.table ?? '',
                        fieldType:
                            action.payload?.fieldType ?? ('' as FieldType),
                        tablesReferences: (action.payload as CompiledDimension)
                            ?.tablesReferences,
                        name:
                            getCustomMetricName(
                                action.payload?.table ?? '',
                                sanitizeCustomMetricName(
                                    state.customAttributePayload.name ?? '',
                                ),
                                action.payload?.name ?? '',
                            ) + Date.now(),
                    } as DefinitionType,
                },
            };
        }
        case ActionType.ADD_CUSTOM_METRIC_NAME_DETAILS: {
            const sanitizedName = sanitizeCustomMetricName(action.payload);
            const baseTable =
                state.customAttributePayload.definition?.table ?? '';
            const baseDimensionName =
                (state.customAttributePayload.definition as AdditionalMetric)
                    ?.baseDimensionName ?? '';

            const customMetricName =
                getCustomMetricName(
                    baseTable,
                    sanitizedName,
                    baseDimensionName,
                ) + Date.now();

            const basePayload = {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    name: customMetricName,
                    definition: {
                        ...state.customAttributePayload.definition,
                        name: customMetricName,
                        label: action.payload,
                    } as DefinitionType,
                },
            };

            if (state.fieldType === FieldType.METRIC) {
                return basePayload;
            }

            const timestampedName = `${snakeCaseName(
                sanitizedName,
            )}${Date.now()}`;

            return {
                ...basePayload,
                customAttributePayload: {
                    ...basePayload.customAttributePayload,
                    name: timestampedName,
                    definition: {
                        ...basePayload.customAttributePayload.definition,
                        name: action.payload,
                        id: timestampedName,
                    } as DefinitionType,
                },
            };
        }
        case ActionType.ADD_CUSTOM_METRIC_DESCRIPTION_DETAILS: {
            return {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    description: action.payload,
                    definition: {
                        ...state.customAttributePayload.definition,
                        description: action.payload,
                    } as DefinitionType,
                },
            };
        }
        case ActionType.ADD_CUSTOM_METRIC_TYPE_DETAIL: {
            return {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    definition: {
                        ...state.customAttributePayload.definition,
                        type: action.payload,
                    } as DefinitionType,
                },
            };
        }
        case ActionType.ADD_FILTERS_TO_CUSTOM_METRIC: {
            return {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    definition: {
                        ...state.customAttributePayload.definition,
                        filters: action.payload,
                    } as DefinitionType,
                },
            };
        }
        case ActionType.SET_CUSTOM_METRIC: {
            return {
                ...state,
                customAttributePayload: action.payload,
            };
        }
        case ActionType.SET_FIELD_TYPE: {
            return {
                ...state,
                fieldType: action.payload,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    type: action.payload,
                },
            };
        }
        case ActionType.SET_SQL_IN_CUSTOM_SQL_DIMENSION: {
            return {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    definition: {
                        ...state.customAttributePayload.definition,
                        sql: action.payload,
                    } as DefinitionType,
                },
            };
        }
        case ActionType.SET_DEFINITION: {
            return {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    definition: action.payload,
                },
            };
        }
        case ActionType.SET_TAGS: {
            return {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    tags: action.payload,
                },
            };
        }
        case ActionType.RESET_STATE: {
            return action.payload;
        }
        default:
            return state;
    }
}

const CustomAttributeProvider: FC<
    React.PropsWithChildren<{
        initialState: CustomAttributeReducerState;
        isEditMode?: boolean;
    }>
> = ({ initialState, isEditMode = false, children }) => {
    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const selectTable = useCallback(
        (selectedTable: CompiledRelationTable | undefined) => {
            dispatch({
                type: ActionType.SELECT_TABLE,
                payload: selectedTable,
            });
        },
        [],
    );
    const selectDimension = useCallback(
        (
            selectedDimension:
                | CompiledDimension
                | FieldWithSuggestions
                | undefined,
        ) => {
            dispatch({
                type: ActionType.SELECT_DIMENSION,
                payload: selectedDimension,
            });
        },
        [],
    );
    const setFilters = useCallback(
        (filters: MetricQuery['filters'] | undefined) => {
            dispatch({
                type: ActionType.SET_FILTERS,
                payload: filters,
            });
        },
        [],
    );

    const addBaseTableDetails = useCallback(
        (selectedTable: CompiledRelationTable | undefined) => {
            dispatch({
                type: ActionType.ADD_BASE_TABLE_DETAILS_TO_PAYLOAD,
                payload: selectedTable,
            });
        },
        [],
    );
    const addSelectedDimensionDetails = useCallback(
        (
            selectedDimension:
                | CompiledDimension
                | FieldWithSuggestions
                | undefined,
        ) => {
            dispatch({
                type: ActionType.ADD_DIMENSION_DETAILS_TO_PAYLOAD,
                payload: selectedDimension,
            });
        },
        [],
    );
    const addNameDetailsToPayload = useCallback((name: string) => {
        dispatch({
            type: ActionType.ADD_CUSTOM_METRIC_NAME_DETAILS,
            payload: name,
        });
    }, []);
    const addDescriptionDetailsToPayload = useCallback(
        (description: string) => {
            dispatch({
                type: ActionType.ADD_CUSTOM_METRIC_DESCRIPTION_DETAILS,
                payload: description,
            });
        },
        [],
    );
    const addMetricTypeDetailsToPayload = useCallback((metric: MetricType) => {
        dispatch({
            type: ActionType.ADD_CUSTOM_METRIC_TYPE_DETAIL,
            payload: metric,
        });
    }, []);
    const addFilttersToCustomMetric = useCallback(
        (filters: MetricFilterRule[] | undefined) => {
            dispatch({
                type: ActionType.ADD_FILTERS_TO_CUSTOM_METRIC,
                payload: filters,
            });
        },
        [],
    );
    const setCustomMetricData = useCallback((data: InsertCustomAttribute) => {
        dispatch({
            type: ActionType.SET_CUSTOM_METRIC,
            payload: data,
        });
    }, []);
    const setFieldType = useCallback((fieldType: FieldType) => {
        dispatch({
            type: ActionType.SET_FIELD_TYPE,
            payload: fieldType,
        });
    }, []);
    const setSqlInCustomSqlDimension = useCallback((sql: string) => {
        dispatch({
            type: ActionType.SET_SQL_IN_CUSTOM_SQL_DIMENSION,
            payload: sql,
        });
    }, []);
    const setDefinition = useCallback(
        (
            definition:
                | AdditionalMetric
                | CustomDimension
                | TableCalculation
                | null,
        ) => {
            dispatch({
                type: ActionType.SET_DEFINITION,
                payload: definition,
            });
        },
        [],
    );
    const setTags = useCallback((tags: string[]) => {
        dispatch({
            type: ActionType.SET_TAGS,
            payload: tags,
        });
    }, []);
    const resetState = useCallback((newState: CustomAttributeReducerState) => {
        dispatch({
            type: ActionType.RESET_STATE,
            payload: newState,
        });
    }, []);

    const state = useMemo(
        () => ({
            ...reducerState,
            isEditMode,
        }),
        [isEditMode, reducerState],
    );

    const actions = useMemo(
        () => ({
            selectTable,
            selectDimension,
            setFilters,
            addBaseTableDetails,
            addSelectedDimensionDetails,
            addNameDetailsToPayload,
            addFilttersToCustomMetric,
            addDescriptionDetailsToPayload,
            addMetricTypeDetailsToPayload,
            setCustomMetricData,
            setFieldType,
            setSqlInCustomSqlDimension,
            setDefinition,
            setTags,
            resetState,
        }),
        [
            selectTable,
            selectDimension,
            setFilters,
            addBaseTableDetails,
            addSelectedDimensionDetails,
            addNameDetailsToPayload,
            addFilttersToCustomMetric,
            addDescriptionDetailsToPayload,
            addMetricTypeDetailsToPayload,
            setCustomMetricData,
            setFieldType,
            setSqlInCustomSqlDimension,
            setDefinition,
            setTags,
            resetState,
        ],
    );

    const value = useMemo(
        () => ({ reducerState: state, actions }),
        [state, actions],
    );

    return (
        <CustomAttributeContext.Provider value={value}>
            {children}
        </CustomAttributeContext.Provider>
    );
};

export default CustomAttributeProvider;
