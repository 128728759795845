import {
    concatPropertiesInEvents,
    convertKeysToSelectData,
    EvenTableRequiredColumns,
} from '@components/EventsManager/utils';
import { useCreateEvent, useUpdateEvent } from '@hooks/useEvents';
import { type MapperSchema } from '@lightdash/common';
import { useCallback, useMemo, useReducer } from 'react';
import {
    EventBuilderStep,
    type PropertiesType,
} from '../../components/EventsManager/types';
import EventsProviderContext from './context';
import {
    ActionType,
    type CurrentStepCallbackProps,
    type EventContext,
    type EventReducerState,
    type EventState,
} from './types';

type Action =
    | { type: ActionType.SET_CURRENT_STEP; payload: EventBuilderStep }
    | {
          type: ActionType.SET_CURRENT_STEP_CALLBACK;
          payload: CurrentStepCallbackProps;
      }
    | {
          type: ActionType.SET_PREVIOUS_STEP_CALLBACK;
          payload: CurrentStepCallbackProps;
      }
    | {
          type: ActionType.SET_TOGGLE_IS_CLONE_EVENT;
          payload: boolean;
      }
    | {
          type: ActionType.SET_SHOW_FOOTER_BUTTONS;
          payload: { next: boolean; disableNext: boolean; back: boolean };
      }
    | { type: ActionType.SET_JSON_PAYLOAD; payload: Record<string, unknown> }
    | {
          type: ActionType.SET_JSON_PAYLOAD_KEYS;
          payload: PropertiesType[];
      }
    | { type: ActionType.SET_SOURCE; payload: string }
    | {
          type: ActionType.SET_MAPPER_SCHEMA;
          payload: Record<string, MapperSchema>;
      }
    | {
          type: ActionType.SET_EVENT_NAME;
          payload: string;
      }
    | {
          type: ActionType.SET_EVENT_LABEL;
          payload: string;
      }
    | {
          type: ActionType.SET_JSON_STRING;
          payload: string;
      };

function reducer(state: EventReducerState, action: Action): EventReducerState {
    switch (action.type) {
        case ActionType.SET_CURRENT_STEP:
            return {
                ...state,
                currentStep: action.payload,
            };
        case ActionType.SET_CURRENT_STEP_CALLBACK:
            return {
                ...state,
                currentStepCallback: () => {
                    if (action.payload.callback) {
                        action.payload.callback();
                    }
                    return action.payload.skipExecutionAfterCallback || false;
                },
            };
        case ActionType.SET_PREVIOUS_STEP_CALLBACK:
            return {
                ...state,
                previousStepCallback: () => {
                    if (action.payload.callback) {
                        action.payload.callback();
                    }
                    return action.payload.skipExecutionAfterCallback || false;
                },
            };
        case ActionType.SET_SHOW_FOOTER_BUTTONS:
            return {
                ...state,
                showFooterButtons: action.payload,
            };
        case ActionType.SET_JSON_PAYLOAD:
            return {
                ...state,
                eventPayload: {
                    ...state.eventPayload,
                    sampleEventPayload: action.payload,
                },
            };
        case ActionType.SET_JSON_PAYLOAD_KEYS:
            return {
                ...state,
                jsonPayloadKeys: action.payload,
            };
        case ActionType.SET_SOURCE: {
            return {
                ...state,
                eventPayload: { ...state.eventPayload, source: action.payload },
            };
        }
        case ActionType.SET_MAPPER_SCHEMA: {
            return {
                ...state,
                eventPayload: {
                    ...state.eventPayload,
                    mapperSchema: action.payload,
                },
            };
        }
        case ActionType.SET_EVENT_NAME: {
            return {
                ...state,
                eventPayload: {
                    ...state.eventPayload,
                    eventName: action.payload,
                },
            };
        }
        case ActionType.SET_EVENT_LABEL: {
            return {
                ...state,
                eventPayload: { ...state.eventPayload, label: action.payload },
            };
        }
        case ActionType.SET_JSON_STRING: {
            return {
                ...state,
                jsonString: action.payload,
            };
        }
        default:
            return state;
    }
}

const EventProvider: React.FC<
    React.PropsWithChildren<
        Pick<
            EventState,
            | 'isEditMode'
            | 'isViewMode'
            | 'isNewMode'
            | 'uuid'
            | 'source'
            | 'isDuplicateMode'
        > & { initialState: EventReducerState }
    >
> = ({
    children,
    initialState,
    isNewMode,
    isEditMode,
    isViewMode,
    uuid,
    source,
    isDuplicateMode,
}) => {
    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const { mutate } = useCreateEvent();
    const { mutate: updateMutate } = useUpdateEvent({
        eventUuid: uuid,
        source,
    });
    const setPreviousStepCallback = useCallback(
        ({
            callback,
            skipExecutionAfterCallback,
        }: CurrentStepCallbackProps) => {
            dispatch({
                type: ActionType.SET_PREVIOUS_STEP_CALLBACK,
                payload: { callback, skipExecutionAfterCallback },
            });
        },
        [],
    );

    const setCurrentStepCallback = useCallback(
        ({
            callback,
            skipExecutionAfterCallback,
        }: CurrentStepCallbackProps) => {
            dispatch({
                type: ActionType.SET_CURRENT_STEP_CALLBACK,
                payload: { callback, skipExecutionAfterCallback },
            });
        },
        [],
    );

    const setCurrentStep = useCallback(
        (value: EventBuilderStep) => {
            if (reducerState.currentStep !== value) {
                setCurrentStepCallback({
                    callback: null,
                    skipExecutionAfterCallback: false,
                });
                setPreviousStepCallback({
                    callback: null,
                    skipExecutionAfterCallback: false,
                });
                dispatch({ type: ActionType.SET_CURRENT_STEP, payload: value });
            }
        },
        [
            reducerState.currentStep,
            dispatch,
            setCurrentStepCallback,
            setPreviousStepCallback,
        ],
    );

    const setShowFooterButtons = useCallback(
        (value: { next: boolean; disableNext: boolean; back: boolean }) => {
            dispatch({
                type: ActionType.SET_SHOW_FOOTER_BUTTONS,
                payload: value,
            });
        },
        [],
    );
    const setJsonPayload = useCallback((jsonData: Record<string, unknown>) => {
        dispatch({ type: ActionType.SET_JSON_PAYLOAD, payload: jsonData });
    }, []);
    const setJsonPayloadKeys = useCallback((jsonData: PropertiesType[]) => {
        dispatch({
            type: ActionType.SET_JSON_PAYLOAD_KEYS,
            payload: jsonData,
        });
    }, []);

    const toggleIsCloneEvent = useCallback((isCloned: boolean) => {
        dispatch({
            type: ActionType.SET_TOGGLE_IS_CLONE_EVENT,
            payload: isCloned,
        });
    }, []);
    const setSource = useCallback((sourceString: string) => {
        dispatch({ type: ActionType.SET_SOURCE, payload: sourceString });
    }, []);
    const setMapperSchema = useCallback(
        (mapperSchema: Record<string, MapperSchema>) => {
            dispatch({
                type: ActionType.SET_MAPPER_SCHEMA,
                payload: mapperSchema,
            });
        },
        [],
    );
    const setEventName = useCallback((eventName: string) => {
        dispatch({ type: ActionType.SET_EVENT_NAME, payload: eventName });
    }, []);
    const setEventLabel = useCallback((eventLabel: string) => {
        dispatch({ type: ActionType.SET_EVENT_LABEL, payload: eventLabel });
    }, []);
    const setJsonString = useCallback((jsonString: string) => {
        dispatch({ type: ActionType.SET_JSON_STRING, payload: jsonString });
    }, []);
    const mutateEvent = useCallback(async () => {
        if (isEditMode) {
            await updateMutate({
                eventName: reducerState.eventPayload.eventName,
                mapperSchema: reducerState.eventPayload.mapperSchema,
                sampleEventPayload:
                    reducerState.eventPayload.sampleEventPayload,
                label: reducerState.eventPayload.label,
                persistToWh: reducerState.eventPayload.persistToWh,
                isInternal: reducerState.eventPayload.isInternal,
                tags: reducerState.eventPayload.tags,
            });
        } else {
            await mutate(reducerState.eventPayload);
        }
    }, [mutate, reducerState.eventPayload, isEditMode, updateMutate]);

    const isValidStep = useCallback(
        (step: EventBuilderStep) => {
            let isPayloadValid =
                reducerState.eventPayload.source.length > 0 &&
                Object.keys(reducerState.eventPayload.sampleEventPayload)
                    .length > 0;
            if (isEditMode) {
                try {
                    const parsedJson = JSON.parse(
                        reducerState.jsonString ?? '',
                    );
                    const properties: PropertiesType[] =
                        convertKeysToSelectData(parsedJson);
                    const concatedProperties: PropertiesType[] =
                        concatPropertiesInEvents(
                            reducerState.jsonPayloadKeys,
                            properties,
                        );
                    if (
                        JSON.stringify(concatedProperties) !==
                        JSON.stringify(reducerState.jsonPayloadKeys)
                    ) {
                        setJsonPayloadKeys(concatedProperties);
                    }
                } catch (error) {
                    isPayloadValid = false;
                }
            }

            const isKeysValid =
                reducerState.jsonPayloadKeys.filter(
                    (keyObj: PropertiesType) => keyObj.reservedKey,
                ).length > 0;

            const isPropertiesValid = (() => {
                const columnNames = reducerState.jsonPayloadKeys
                    .filter((keyObj: PropertiesType) => keyObj.reservedKey)
                    .map((keyObj: PropertiesType) => keyObj.columnName);

                return (
                    columnNames.includes(EvenTableRequiredColumns[1].value) &&
                    columnNames.includes(EvenTableRequiredColumns[2].value)
                );
            })();
            const emptyLabelForSelectedProperty =
                reducerState.jsonPayloadKeys.find(
                    (keyObj: PropertiesType) =>
                        keyObj.selectedProperty && keyObj.label === '',
                );

            switch (step) {
                case EventBuilderStep.PAYLOAD:
                    return isPayloadValid;
                case EventBuilderStep.KEYS:
                    return isPayloadValid && isKeysValid;
                case EventBuilderStep.PROPERTIES:
                    return isPayloadValid && isKeysValid && isPropertiesValid;
                case EventBuilderStep.REVIEW:
                    return (
                        isPayloadValid &&
                        isKeysValid &&
                        isPropertiesValid &&
                        !!reducerState.eventPayload.eventName &&
                        !emptyLabelForSelectedProperty
                    );
                default:
                    return false;
            }
        },
        [reducerState, isEditMode, setJsonPayloadKeys],
    );
    const state = useMemo(
        () => ({
            ...reducerState,
            isValidStep,
            isEditMode,
            isNewMode,
            isViewMode,
            uuid,
            source,
            isDuplicateMode,
        }),
        [
            reducerState,
            isValidStep,
            isEditMode,
            isNewMode,
            isViewMode,
            uuid,
            source,
            isDuplicateMode,
        ],
    );

    const actions = useMemo(
        () => ({
            setCurrentStep,
            setPreviousStepCallback,
            setCurrentStepCallback,
            setShowFooterButtons,
            setJsonPayloadKeys,
            setJsonPayload,
            toggleIsCloneEvent,
            setSource,
            setMapperSchema,
            setEventName,
            setEventLabel,
            mutateEvent,
            setJsonString,
        }),
        [
            setCurrentStep,
            setPreviousStepCallback,
            setCurrentStepCallback,
            setShowFooterButtons,
            setJsonPayloadKeys,
            setJsonPayload,
            toggleIsCloneEvent,
            setSource,
            setMapperSchema,
            setEventName,
            setEventLabel,
            mutateEvent,
            setJsonString,
        ],
    );

    const value: EventContext = useMemo(
        () => ({ state, actions }),
        [state, actions],
    );

    return (
        <EventsProviderContext.Provider value={value}>
            {children}
        </EventsProviderContext.Provider>
    );
};

export default EventProvider;
