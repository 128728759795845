import {
    DEDUPED_EVENTS_TABLE_PREFIX,
    DEDUPED_JOURNEY_EVENTS_TABLE_PREFIX,
    DEDUPED_SUBSCRIPTION_EVENTS_TABLE_PREFIX,
    DELIVERY_CONTROL_EVENTS_TABLE_PREFIX,
    EVENT_LOG_TABLE_PREFIX,
    EVENT_TABLE_PREFIX,
} from '../types/events';
import { type WarehouseTypes } from '../types/projects';
import { getSortmentWarehouseName } from './sortmentUtils';

export const getEventTableName = (
    projectUuid: string,
    whType: WarehouseTypes,
): string =>
    getSortmentWarehouseName(
        `${EVENT_TABLE_PREFIX}_${projectUuid.split('-')[0]}`,
        whType,
    );

export const getDedupedEventTableName = (
    projectUuid: string,
    whType: WarehouseTypes,
): string =>
    getSortmentWarehouseName(
        `${DEDUPED_EVENTS_TABLE_PREFIX}${projectUuid.split('-')[0]}`,
        whType,
    );

export const getEventLogsTableName = (
    projectUuid: string,
    whType: WarehouseTypes,
): string =>
    getSortmentWarehouseName(
        `${EVENT_LOG_TABLE_PREFIX}${projectUuid.split('-')[0]}`,
        whType,
    );

export const getDedupedSubscriptionEventTableName = (
    projectUuid: string,
    whType: WarehouseTypes,
): string =>
    getSortmentWarehouseName(
        `${DEDUPED_SUBSCRIPTION_EVENTS_TABLE_PREFIX}${
            projectUuid.split('-')[0]
        }`,
        whType,
    );

export const getDeliveryControlEventsTableName = (
    projectUuid: string,
    whType: WarehouseTypes,
): string =>
    getSortmentWarehouseName(
        `${DELIVERY_CONTROL_EVENTS_TABLE_PREFIX}${projectUuid.split('-')[0]}`,
        whType,
    );

export const getJourneyEventsTableName = (
    projectUuid: string,
    whType: WarehouseTypes,
): string =>
    getSortmentWarehouseName(
        `${DEDUPED_JOURNEY_EVENTS_TABLE_PREFIX}${projectUuid.split('-')[0]}`,
        whType,
    );
