import { isCampaignEvent, isJourneyEvent } from '@components/Audience/utils';
import TableIcon from '@components/common/IconPack/TableIcon';
import TraitIcon from '@components/common/IconPack/TraitIcon';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import {
    getGroupIcon as getGroupIconForJourneyEvent,
    getGroupLabel,
} from '@components/Journeys/Builder/JourneyFilters/useJourneyProperties';
import {
    CommonReservedTags,
    FieldType,
    getItemId,
    isCustomDimension,
    isCustomSqlDimension,
    JourneyParamsNames,
    JourneyTableType,
    RelationTableType as RelationTableTypeEnum,
    type CommunicationChannel,
    type RelationTableType,
} from '@lightdash/common';
import { JOURNEY_EVENT_TABLE_NAME } from '@utils/constants';
import { t as translate } from 'i18next';
import { GlobalStyles } from '../../../../mantineTheme';
import { filterPropertyTypeMapper } from '../FilterSelect/useFilterSelectUtils';
import { type FieldWithSuggestions } from '../FiltersProvider/types';
import { CampaignEventChannelLabel } from '../utils';
export enum PropertySelectGroupEnum {
    SUGGESTIONS = 'suggestions',
    JOURNEY_EVENT = 'journey_event',
    USER_TRAITS = 'user_traits',
}

export type PropertySelectGroupType =
    | FieldType.METRIC
    | RelationTableTypeEnum
    | PropertySelectGroupEnum.SUGGESTIONS
    | PropertySelectGroupEnum.JOURNEY_EVENT
    | PropertySelectGroupEnum.USER_TRAITS;

type PropertySelectAccumulator = {
    [key: string]: PropertySelectListType<
        FieldWithSelectStatus,
        PropertySelectGroupType
    >;
};
export interface BaseFieldListItemProps {
    item: FieldWithSuggestions;
    isDisabled?: boolean;
    isChecked?: boolean;
}
export type FieldListItemProps = BaseFieldListItemProps & {
    showCheckbox: boolean;
    checked: boolean;
    disabled: boolean;
    showFieldSource?: boolean;
    disableHover?: boolean;
    showHoverIcon?: boolean;
    className?: string;
    tableLabelCustomClasses?: string;
    showSourceTable?: boolean;
};
const getKeyForProperties = (item: FieldWithSelectStatus) => {
    if (item?.fieldType === FieldType.METRIC || isCustomDimension(item)) {
        return FieldType.METRIC;
    }
    if (item?.table?.includes(JourneyParamsNames.JOURNEY_EVENT)) {
        return PropertySelectGroupEnum.JOURNEY_EVENT;
    }
    if (item?.table?.includes(JourneyParamsNames.SRT_USER_TRAITS)) {
        return PropertySelectGroupEnum.USER_TRAITS;
    }
    return item?.tableType ?? RelationTableTypeEnum.RELATED;
};
const getSubGroupLabelForProperties = (item: FieldWithSelectStatus) => {
    const { shortLabel, label } =
        filterPropertyTypeMapper.find(
            (eachItem) => (eachItem.propertyType as string) === item?.tableType,
        ) || {};
    if (item?.table?.includes(JourneyParamsNames.JOURNEY_EVENT)) {
        return getGroupLabel(JourneyTableType.EVENT);
    }
    if (item?.fieldType === FieldType.METRIC || isCustomDimension(item)) {
        return (
            filterPropertyTypeMapper.find(
                (eachItem) => eachItem.propertyType === FieldType.METRIC,
            )?.shortLabel ?? ''
        );
    }
    if (item?.table?.includes(JourneyParamsNames.SRT_USER_TRAITS)) {
        return getGroupLabel(JourneyTableType.PRIMARY);
    }
    return shortLabel ?? label ?? '';
};
const getGroupIcon = (item: FieldWithSelectStatus) => {
    if (item?.fieldType === FieldType.METRIC || isCustomDimension(item)) {
        return <TraitIcon color="rgb(var(--color-gray-800))" />;
    }
    if (item?.table?.includes(JourneyParamsNames.JOURNEY_EVENT)) {
        return getGroupIconForJourneyEvent(JourneyTableType.EVENT);
    }
    if (item?.table?.includes(JourneyParamsNames.SRT_USER_TRAITS)) {
        return getGroupIconForJourneyEvent(JourneyTableType.PRIMARY);
    }
    return (
        <TableIcon
            type={item?.tableType ?? RelationTableTypeEnum.RELATED}
            strokeWidth={2.5}
            color="rgb(var(--color-gray-800))"
        />
    );
};
export const convertFieldsIntoPropertySelectListType = (
    items: FieldWithSelectStatus[],
    showSubGroupLabel: boolean,
): PropertySelectListType<FieldWithSelectStatus, PropertySelectGroupType>[] => {
    const groupItems = (item: FieldWithSelectStatus) => {
        let subGroupLabel = '';
        let subGroupKey = '';
        if (showSubGroupLabel) {
            if (item.tableType === RelationTableTypeEnum.PRIMARY) {
                if (item?.table?.includes(JourneyParamsNames.SRT_USER_TRAITS)) {
                    subGroupKey = '';
                    subGroupLabel = '';
                } else {
                    subGroupLabel = 'User attributes';
                    subGroupKey = RelationTableTypeEnum.PRIMARY;
                }
            } else if (item.tableType === RelationTableTypeEnum.EVENT) {
                if (isCampaignEvent(item)) {
                    subGroupLabel =
                        CampaignEventChannelLabel[
                            (item.uniqueIdentifier ??
                                '') as CommunicationChannel
                        ];
                    subGroupKey = item.uniqueIdentifier ?? '';
                } else if (isJourneyEvent(item)) {
                    subGroupKey = JOURNEY_EVENT_TABLE_NAME;
                    subGroupLabel = translate(
                        'field_filter_select.jounrey_event_label',
                    );
                } else if (
                    item?.table?.includes(JourneyParamsNames.JOURNEY_EVENT)
                ) {
                    subGroupKey = '';
                    subGroupLabel = '';
                } else {
                    subGroupKey = RelationTableTypeEnum.EVENT;
                    subGroupLabel = 'Has performed events...';
                }
            } else if (
                item.tableType === RelationTableTypeEnum.RELATED &&
                item.fieldType !== FieldType.METRIC
            ) {
                subGroupLabel = 'Related properties';
                subGroupKey = RelationTableTypeEnum.RELATED;
            } else if (item.tableType === RelationTableTypeEnum.AUDIENCE) {
                subGroupLabel = 'Belongs to Audience...';
                subGroupKey = RelationTableTypeEnum.AUDIENCE;
            }
        }

        return {
            ...item,
            subGroupLabel,
            subGroupKey,
        };
    };

    const dataFields = items.reduce<PropertySelectAccumulator>((acc, pass) => {
        if (!pass) return acc;
        //Info: If the field is hidden, we don't want to show it in the property select but able to see if it is already selected
        if ('tags' in pass && pass?.tags?.includes(CommonReservedTags.HIDDEN)) {
            return acc;
        }

        //Info: Custom dimensions are treated as metrics
        const key = getKeyForProperties(pass);
        if (key) {
            if (acc[key] === undefined) {
                acc[key] = {
                    groupKey: key,
                    groupLabel: getSubGroupLabelForProperties(pass),
                    groupIcon: getGroupIcon(pass),
                    items: [groupItems(pass)],
                };
            } else {
                acc = {
                    ...acc,
                    [key]: {
                        ...acc[key],
                        items: [...acc[key].items, groupItems(pass)],
                    },
                };
            }
        }

        return acc;
    }, {} as PropertySelectAccumulator);
    return Object.values(dataFields);
};
export const getTextColor = (type: RelationTableType | undefined) => {
    switch (type) {
        case RelationTableTypeEnum.PRIMARY:
            return GlobalStyles?.tableStyles?.[RelationTableTypeEnum.PRIMARY]
                ?.textColor;
        case RelationTableTypeEnum.EVENT:
            return GlobalStyles?.tableStyles?.[RelationTableTypeEnum.EVENT]
                ?.textColor;
        case RelationTableTypeEnum.AUDIENCE:
            return GlobalStyles?.tableStyles?.[RelationTableTypeEnum.AUDIENCE]
                ?.textColor;
        default:
            return GlobalStyles?.tableStyles?.[RelationTableTypeEnum.RELATED]
                ?.textColor;
    }
};
export const renderTableIcon = (
    type: RelationTableTypeEnum | undefined,
    iconColor: string | undefined,
) => {
    let iconType;
    switch (type) {
        case RelationTableTypeEnum.PRIMARY:
            iconType = RelationTableTypeEnum.PRIMARY;
            break;
        case RelationTableTypeEnum.EVENT:
            iconType = RelationTableTypeEnum.EVENT;
            break;
        case RelationTableTypeEnum.AUDIENCE:
            iconType = RelationTableTypeEnum.AUDIENCE;
            break;
        default:
            iconType = RelationTableTypeEnum.RELATED;
            break;
    }
    return (
        <TableIcon
            type={iconType}
            strokeWidth={2.5}
            color={iconColor}
            size={14}
        />
    );
};

/**
 * Generates the class name string for the menu target based on the opened state.
 *
 * @param {boolean} opened - The state indicating whether the menu is opened or not.
 * @returns {string} The generated class name string.
 */
export const getButtonHighlightClassName = (opened: boolean): string => {
    return `mx-2  ${
        !opened
            ? 'hover:!bg-shade-2 hover:!text-gray-800 hover:border-shade-2'
            : ''
    }  ${opened ? '!text-blu-800 !bg-blu-100 !border-blu-100' : ''}`;
};

export type FieldWithSelectStatus = FieldWithSuggestions & {
    isChecked?: boolean;
    isDisabled?: boolean;
};

export interface ModifyFieldProps {
    fields: FieldWithSuggestions[];
    selectedFieldIds: string[];
    shouldDisableChecked: boolean;
    disabledFields?: string[];
}

/**
 * Modifies the given fields by adding `isChecked` and `isDisabled` properties
 * based on whether a field is selected and if checked fields should be disabled.
 *
 * @param fields - Array of fields to be modified.
 * @param selectedFieldIds - Array of field IDs that should be marked as checked.
 * @param shouldDisableChecked - If true, disables fields that are checked.
 * @returns An array of fields with added `isChecked` and `isDisabled` properties.
 */
export const modifyFieldsWithSelectionStatus = ({
    fields,
    selectedFieldIds,
    shouldDisableChecked,
    disabledFields,
}: ModifyFieldProps): FieldWithSelectStatus[] => {
    return fields.map((field) => {
        let isChecked = false;
        if (isCustomSqlDimension(field)) {
            isChecked = selectedFieldIds.includes(`${field.table}_${field.id}`);
        } else {
            isChecked = selectedFieldIds.includes(getItemId(field));
        }
        const isDisabled =
            (shouldDisableChecked && isChecked) ||
            disabledFields?.includes(getItemId(field));
        return { ...field, isChecked, isDisabled };
    });
};

/**
 * this function is to tell that it is a custom atribute
 * and it should be shown as a custom metric
 */
export const isCustomAttribute = (field: FieldWithSuggestions) => {
    return field?.fieldType === FieldType.METRIC || isCustomSqlDimension(field);
};
