import { Flex, Group } from '@mantine/core';
import React from 'react';
import ToggleOption from './ToggleOption';
import { type ToggleSwitchProps } from './types';
const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
    enabled,
    options,
    selectedOption,
    onSelectedOptionChange,
}) => {
    return (
        <Group className="border border-gray-250 rounded-lg overflow-hidden">
            <Flex className={`${enabled ? 'cursor-pointer' : ''}`}>
                {options.map((option) => (
                    <ToggleOption
                        key={option.value}
                        option={option}
                        isSelected={selectedOption === option.value}
                        onSelectedOptionChange={onSelectedOptionChange}
                        enabled={enabled}
                    />
                ))}
            </Flex>
        </Group>
    );
};

export default ToggleSwitch;
