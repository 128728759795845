import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import Manager from '@components/Manager';
import { useLocale } from '@hooks/useLocale';
import { useSortedUsers } from '@hooks/useSortedUsers';
import {
    ShowDataType,
    type ProjectMemberProfileWithInviteInformation,
} from '@lightdash/common';
import { useDebouncedState } from '@mantine/hooks';
import useApp from '@providers/App/useApp';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import Fuse from 'fuse.js';
import React, { useMemo } from 'react';
import { useProjectAccessWithLinkInfo } from '../../../hooks/useProjectAccess';
import { useWorkspaceMembersColumns } from './useWorkspaceMembersTableUtils';
import { filterSuperAdmin } from './utils';
interface WorkspaceMembersTableProps {
    projectUuid: string;
}

const WorkspaceMembersTable: React.FC<WorkspaceMembersTableProps> = ({
    projectUuid,
}) => {
    const { data: projectAccess, isLoading: isProjectAccessLoading } =
        useProjectAccessWithLinkInfo(projectUuid);
    const { user } = useApp();
    const [searchString, setSearchString] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const workspaceMembersColumns = useWorkspaceMembersColumns();
    const { t } = useLocale();
    const { sortedUsers } =
        useSortedUsers<ProjectMemberProfileWithInviteInformation>(
            projectAccess,
        );
    const filteredUsers = useMemo(() => {
        if (!sortedUsers) return [];
        const users = sortedUsers || projectAccess;
        const hasSearchQuery = Boolean(searchString);
        if (!hasSearchQuery) return users;
        const fuse = new Fuse(users, {
            keys: ['firstName', 'lastName', 'email', 'role'],
            threshold: 0.3,
        });
        return fuse.search(searchString).map((res) => res.item);
    }, [searchString, sortedUsers, projectAccess]);
    return (
        <div>
            {isProjectAccessLoading ? (
                <SuboptimalState />
            ) : (
                projectAccess &&
                workspaceMembersColumns && (
                    <Manager
                        tableData={filterSuperAdmin<ProjectMemberProfileWithInviteInformation>(
                            filteredUsers,
                            user?.data?.userUuid || '',
                        )}
                        options={[
                            {
                                format: ShowDataType.LIST,
                                formatData: workspaceMembersColumns,
                            },
                        ]}
                        isManualPagination={false}
                        searchPlaceholder={t(
                            'workspace_members.table_search_placeholder',
                        )}
                        paginationText={t('workspace_members.pagination_text')}
                        isEditable={false}
                        searchValue={searchString}
                        onSearchChange={setSearchString}
                    />
                )
            )}
        </div>
    );
};

export default React.memo(WorkspaceMembersTable);
