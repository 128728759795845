import { sortmentApi } from '@api/index';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import {
    type ApiError,
    type ApiResponse,
    type ApiSuccessEmpty,
    type DeliveryControl,
    type DeliveryControlCreateRequest,
    type DeliveryControlUpdateRequest,
    type GlobalHoldoutConfig,
    type SupressionConfig,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';

const getDeliveryControl = ({ projectUuid }: { projectUuid: string }) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectUuid}/delivery-control`,
        method: 'GET',
        body: undefined,
    });

export const useGetDeliveryControl = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useQuery<ApiResponse, ApiError, DeliveryControl>({
        queryFn: () => getDeliveryControl({ projectUuid }),
        queryKey: [QueryKeys.GET_DELIVERY_CONTROLS, projectUuid],
        retry: false, // INFO - API gives 404 initially when no delivery controls are added in that case the initial load time increases because the API keeps on retrying
    });
};

const createDeliveryControl = ({
    projectUuid,
    payload,
}: {
    projectUuid: string;
    payload: DeliveryControlCreateRequest;
}) =>
    sortmentApi<DeliveryControl>({
        method: 'POST',
        url: `/projects/${projectUuid}/delivery-control`,
        body: JSON.stringify(payload),
    });

export const useCreateDeliveryControl = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();

    return useMutation<DeliveryControl, ApiError, DeliveryControlCreateRequest>(
        {
            mutationFn: (payload) =>
                createDeliveryControl({ projectUuid, payload }),
            mutationKey: [QueryKeys.CREATE_DELIVERY_CONTROLS, projectUuid],
            onSuccess: () => {
                showToastSuccess({
                    title: t(
                        'workspace_settings.deliver_controls.update_success',
                    ),
                    autoClose: 2000,
                });
            },
            onError: (error) => {
                showToastError({
                    title: error.error.message ?? 'Something went wrong',
                });
            },
        },
    );
};

const updateDeliveryControl = ({
    projectUuid,
    payload,
}: {
    projectUuid: string;
    payload: DeliveryControlUpdateRequest;
}) =>
    sortmentApi<DeliveryControl>({
        method: 'PUT',
        url: `/projects/${projectUuid}/delivery-control`,
        body: JSON.stringify(payload),
    });

export const useUpdateDeliveryControl = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    const query = useQueryClient();

    return useMutation<DeliveryControl, ApiError, DeliveryControlUpdateRequest>(
        {
            mutationFn: (payload) =>
                updateDeliveryControl({ projectUuid, payload }),
            mutationKey: [QueryKeys.UPDATE_DELIVERY_CONTROLS, projectUuid],
            onSuccess: async () => {
                showToastSuccess({
                    title: t(
                        'workspace_settings.deliver_controls.update_success',
                    ),
                    autoClose: 2000,
                });
                await query.refetchQueries([
                    QueryKeys.GET_DELIVERY_CONTROLS,
                    projectUuid,
                ]);
            },
            onError: (error) => {
                showToastError({
                    title: error.error.message ?? 'Something went wrong',
                });
            },
        },
    );
};

const getGlobalHoldoutConfig = ({ projectId }: { projectId: string }) =>
    sortmentApi<ApiSuccessEmpty & GlobalHoldoutConfig>({
        url: `/projects/${projectId}/configs/global-holdout-config`,
        method: 'GET',
        body: undefined,
    });

export const useGetGlobalHoldoutConfig = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useQuery<GlobalHoldoutConfig>({
        queryFn: () => getGlobalHoldoutConfig({ projectId: projectUuid }),
        queryKey: [QueryKeys.GET_GLOBAL_HOLDOUT_GROUP, projectUuid],
    });
};

type GlobalHoldoutConfigRequest = {
    holdoutPercentage: number;
};

const setGlobalHoldoutConfig = ({
    projectId,
    body,
}: {
    projectId: string;
    body: GlobalHoldoutConfigRequest;
}) =>
    sortmentApi({
        url: `/projects/${projectId}/configs/global-holdout-config`,
        method: 'PUT',
        body: JSON.stringify(body),
    });

export const useSetGlobalHoldoutConfig = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useMutation({
        mutationFn: (body: GlobalHoldoutConfigRequest) =>
            setGlobalHoldoutConfig({ projectId: projectUuid, body }),
        mutationKey: [QueryKeys.SET_GLOBAL_HOLDOUT_GROUP, projectUuid],
    });
};

const getSuppressionConfig = ({ projectId }: { projectId: string }) =>
    sortmentApi<ApiSuccessEmpty & SupressionConfig>({
        url: `/projects/${projectId}/configs/supression-config`,
        method: 'GET',
        body: undefined,
    });

export const useGetSuppressionConfig = ({
    enabled = true,
}: {
    enabled?: boolean;
}) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useQuery<SupressionConfig>({
        queryFn: () => getSuppressionConfig({ projectId: projectUuid }),
        queryKey: [QueryKeys.GET_SUPPRESSION_CONFIG, projectUuid],
        enabled,
    });
};

const createSuppressionConfig = ({
    projectId,
    body,
}: {
    projectId: string;
    body: Partial<SupressionConfig>;
}) =>
    sortmentApi<ApiSuccessEmpty & SupressionConfig>({
        url: `/projects/${projectId}/configs/supression-config`,
        method: 'POST',
        body: JSON.stringify(body),
    });

export const useCreateSuppressionConfig = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useMutation({
        mutationFn: (body: Partial<SupressionConfig>) =>
            createSuppressionConfig({ projectId: projectUuid, body }),
        mutationKey: [QueryKeys.POST_SUPPRESSION_CONFIG, projectUuid],
    });
};

const setSuppressionConfig = ({
    projectId,
    body,
}: {
    projectId: string;
    body: Partial<SupressionConfig>;
}) =>
    sortmentApi<ApiSuccessEmpty & SupressionConfig>({
        url: `/projects/${projectId}/configs/supression-config`,
        method: 'PUT',
        body: JSON.stringify(body),
    });

export const useSetSuppressionConfig = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useMutation({
        mutationFn: (body: Partial<SupressionConfig>) =>
            setSuppressionConfig({ projectId: projectUuid, body }),
        mutationKey: [QueryKeys.SET_SUPPRESSION_CONFIG, projectUuid],
    });
};
