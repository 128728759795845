import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type ApiSqlQueryResults,
    type AudiencePreviewPayload,
    type UserSearchByContactFieldPayload,
} from '@lightdash/common';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useMutation } from '@tanstack/react-query';
import {
    API_CALL_THRESHOLD_IN_MS,
    AUDIENCE_PREVIEW_BY_ID_LIMIT,
    AUDIENCE_PREVIEW_LIMIT,
    TIMEOUT_STATUS_CODE,
} from '@utils/constants';
import Timeout from 'await-timeout';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';
import useQueryError from './useQueryError';

interface AudiencePreviewByIdParams {
    relationId: string;
    audienceId: string;
    perPage?: number;
    currentPage?: number;
    data: AudiencePreviewPayload;
}

const getPreviewDataByAudienceId = async ({
    projectId,
    relationId,
    audienceId,
    perPage,
    currentPage,
    data,
}: AudiencePreviewByIdParams & { projectId: string }) => {
    const promise = sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}/preview?perPage=${perPage}&currentPage=${currentPage}`,
        method: 'POST',
        body: JSON.stringify(data),
    });
    return Timeout.wrap(promise, API_CALL_THRESHOLD_IN_MS, TIMEOUT_STATUS_CODE);
};

export const useAudiencePreviewById = () => {
    const { projectUuid: projectId = '' } = useParams<{
        projectUuid: string;
    }>();
    const { activeRelationUuid: relationId } = useRelationContext();
    const { showToastWarning } = useNotify();
    const { t } = useLocale();

    const setErrorResponse = useQueryError();

    return useMutation<
        ApiSqlQueryResults,
        ApiError,
        {
            dimensions: string[];
            metrics: string[];
            audienceId: string;
            contactFilters?: UserSearchByContactFieldPayload;
        }
    >(
        ({ dimensions, metrics, audienceId, contactFilters }) =>
            getPreviewDataByAudienceId({
                projectId,
                relationId,
                audienceId,
                perPage: AUDIENCE_PREVIEW_BY_ID_LIMIT,
                currentPage: 0,
                data: {
                    limit: AUDIENCE_PREVIEW_BY_ID_LIMIT,
                    dimensions,
                    metrics,
                    contactFilters,
                },
            }),
        {
            mutationKey: [QueryKeys.AUDIENCE_PREVIEW_ID, projectId, relationId],
            onError: (result) => {
                if (result.toString().includes(TIMEOUT_STATUS_CODE)) {
                    showToastWarning({
                        title: t('audience_preview.slow_query_warning'),
                        subtitle: t('audience_preview.slow_query_message'),
                    });
                }

                setErrorResponse(result);
            },
        },
    );
};

const getPreviewDataByPayload = async (
    projectId: string,
    relationId: string,
    data: AudiencePreviewPayload,
) => {
    const promise = sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/preview`,
        method: 'POST',
        body: JSON.stringify(data),
    });
    return Timeout.wrap(promise, API_CALL_THRESHOLD_IN_MS, TIMEOUT_STATUS_CODE);
};

export const useAudiencePreviewByPayload = () => {
    const { showToastError, showToastWarning } = useNotify();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    return useMutation<
        ApiSqlQueryResults,
        ApiError,
        {
            data: AudiencePreviewPayload;
            relationUuid: string;
            dimensions: string[];
            metrics: string[];
            limit?: number;
        }
    >(
        ({ data, dimensions, metrics, relationUuid, limit }) =>
            getPreviewDataByPayload(projectUuid, relationUuid, {
                limit: limit ?? AUDIENCE_PREVIEW_LIMIT,
                ...data,
                dimensions,
                metrics,
            }),
        {
            mutationKey: [QueryKeys.AUDIENCE_PREVIEW_PAYLOAD],
            onError: (error) => {
                if (error.toString().includes(TIMEOUT_STATUS_CODE)) {
                    showToastWarning({
                        title: t('audience_preview.slow_query_warning'),
                        subtitle: t('audience_preview.slow_query_message'),
                    });
                } else {
                    showToastError({
                        title: t('use_audience_preview.error_audience_preview'),
                        subtitle: error.error?.message,
                    });
                }
            },
        },
    );
};
