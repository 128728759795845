import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    type Row,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useEffect, useRef } from 'react';

const InfiniteScrollTable = ({
    pagination,
    data,
    isFetching,
    onPageChange,
    columns,
    height,
}: any) => {
    const tableContainerRef = useRef(null);

    const rowVirtualizer = useVirtualizer({
        count: pagination.total ?? 0,
        estimateSize: () => 64, //this is hardcoded beacause if heights are measured dynamicaliy using virtualItem.measureElement then this function is used currently we are not using dynamic height and it is mandatory field
        getScrollElement: () => tableContainerRef.current,
        measureElement:
            typeof window !== 'undefined' &&
            navigator.userAgent.indexOf('Firefox') === -1
                ? (element) => element?.getBoundingClientRect().height
                : undefined,
        overscan: 3,
    });

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        manualSorting: true,
        debugTable: true,
    });

    const { rows } = table.getRowModel();

    useEffect(() => {
        const lastItemIndex =
            rowVirtualizer.getVirtualItems().at(-1)?.index || 0;
        const totalFetched = pagination.currentPage * pagination.perPage;
        if (
            lastItemIndex >= totalFetched - 1 && // Near the last fetched item
            !isFetching &&
            totalFetched < (pagination?.total ?? 0)
        ) {
            onPageChange(pagination.currentPage + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        rowVirtualizer.getVirtualItems(),
        pagination,
        onPageChange,
        isFetching,
    ]);

    return (
        <div
            className="border-base rounded-none w-full"
            ref={tableContainerRef}
            style={{
                overflow: 'auto', //our scrollable table container
                position: 'relative', //needed for sticky header
                height: height ?? '600px', //should be a fixed height
                scrollbarWidth: 'none', // Hide scrollbar in Firefox
                msOverflowStyle: 'none', // Hide scrollbar in IE 10+
                // '::-webkit-scrollbar': {
                //     display: 'none',
                // },
            }}
        >
            {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
            <table style={{ display: 'grid' }} className="w-full">
                <thead
                    style={{
                        display: 'grid',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                    }}
                    className="bg-gray-50 text-gray-600 uppercase py-[9px] font-normal"
                >
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr
                            key={headerGroup.id}
                            style={{ display: 'flex', width: '100%' }}
                        >
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th
                                        key={header.id}
                                        style={{
                                            display: 'flex',
                                            width: header.column.columnDef.size
                                                ? header.column.columnDef.size
                                                : 'auto', // Fixed width for specific columns
                                            flex: header.column.columnDef.size
                                                ? 'none'
                                                : 1, // Allow flexibility for non-fixed columns
                                            minWidth: 100, // Prevent columns from getting too small
                                            maxWidth:
                                                header.column.columnDef.size ||
                                                'none', // Restrict width for fixed columns
                                        }}
                                    >
                                        <div
                                            // {...{
                                            //   className: header.column.getCanSort()
                                            //     ? 'cursor-pointer select-none'
                                            //     : '',
                                            //   onClick: header.column.getToggleSortingHandler(),
                                            // }}
                                            className="w-full text-left font-normal"
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody
                    style={{
                        display: 'grid',
                        height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                        position: 'relative', //needed for absolute positioning of rows
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const row = rows[virtualRow.index] as Row<any>;

                        if (!row) return null;

                        return (
                            <tr
                                data-index={virtualRow.index} //needed for dynamic row height measurement
                                ref={(node) =>
                                    rowVirtualizer.measureElement(node)
                                } //measure dynamic row height
                                key={row.id}
                                style={{
                                    display: 'flex',
                                    position: 'absolute',
                                    transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                                    width: '100%',
                                    alignItems: 'center',
                                }}
                                className={`${
                                    virtualRow.index !== pagination.total - 1 &&
                                    'border-b-[1px]'
                                } py-2`}
                            >
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td
                                            key={cell.id}
                                            style={{
                                                display: 'flex',
                                                width: cell.column.columnDef
                                                    .size
                                                    ? cell.column.columnDef.size
                                                    : 'auto',
                                                flex: cell.column.columnDef.size
                                                    ? 'none'
                                                    : 1, // Grow only if no fixed width
                                                minWidth: 100,
                                                maxWidth:
                                                    cell.column.columnDef
                                                        .size || 'none',
                                            }}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default InfiniteScrollTable;
