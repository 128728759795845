import {
    CommunicationChannel,
    ContentMappingSection,
    type EmailTemplateContentDetails,
    type ExternalTemplate,
    type SmsTemplateContentDetails,
    type TemplateContentDetails,
} from '@lightdash/common';
import { t as translate } from 'i18next';
import CampaignBuilderAudience from './Steps/CampaignBuilderAudience';
import CampaignBuilderChannel from './Steps/CampaignBuilderChannel';
import CampaignBuilderContent from './Steps/CampaignBuilderContent';
import LinkTracking from './Steps/CampaignBuilderContent/LinkTracking';
import PersonaliseContent from './Steps/CampaignBuilderContent/SetupContent/PersonaliseContent';
import SetupContentDetails from './Steps/CampaignBuilderContent/SetupContent/SetupContentDetails';
import TestCommunication from './Steps/CampaignBuilderContent/TestCommunication';
import UtmTracking from './Steps/CampaignBuilderContent/UtmTracking';
import ConversionAndThrottling from './Steps/ConversionAndThrottling';
import ReviewCampaign from './Steps/ReviewCampaign';
import {
    CampaignBuilderStep,
    CampaignContentStep,
    type CampaignBuilderStepMap,
    type CampaignContentStepMap,
} from './types';

export const CampaignBuilderSteps: CampaignBuilderStepMap[] = [
    {
        key: CampaignBuilderStep.CHANNEL,
        label: translate('campaign_builder.steps.channel.title'),
        component: CampaignBuilderChannel,
        pageHeader: translate('campaign_builder.steps.channel.description'),
    },
    {
        key: CampaignBuilderStep.AUDIENCE,
        label: translate('campaign_builder.steps.audience.title'),
        component: CampaignBuilderAudience,
        pageHeader: translate('campaign_builder.steps.audience.description'),
    },
    {
        key: CampaignBuilderStep.CONTENT,
        label: translate('campaign_builder.steps.content.title'),
        component: CampaignBuilderContent,
        pageHeader: translate('campaign_builder.steps.content.description'),
    },
    {
        key: CampaignBuilderStep.CONVERSION_AND_THROTTLING,
        label: translate('campaign_builder.conversion_and_throttling'),
        component: ConversionAndThrottling,
        pageHeader: translate('campaign_builder.conversion_and_throttling'),
    },
    {
        key: CampaignBuilderStep.SCHEDULE,
        label: translate('campaign_builder.review'),
        component: ReviewCampaign,
        pageHeader: translate('campaign_builder.review'),
    },
];

export const CampaignContentSteps: CampaignContentStepMap[] = [
    {
        key: CampaignContentStep.SETUP,
        label: translate('campaign_builder.content_steps.setup'),
        component: SetupContentDetails,
    },
    {
        key: CampaignContentStep.PERSONALISE,
        label: translate('campaign_builder.content_steps.personalise'),
        component: PersonaliseContent,
    },
    {
        key: CampaignContentStep.TRACK,
        label: translate('campaign_builder.content_steps.track'),
        component: LinkTracking,
    },
    {
        key: CampaignContentStep.UTM_PARAMETERS,
        label: translate('campaign_builder.content_steps.utm_parameters'),
        component: UtmTracking,
    },
    {
        key: CampaignContentStep.TEST,
        label: translate('campaign_builder.content_steps.test'),
        component: TestCommunication,
    },
];

const filterContentSteps = (
    value: CampaignContentStep[],
    steps: CampaignContentStepMap[],
) => steps.filter((step) => !value.includes(step.key));

export const getChannelBasedContentSteps = ({
    channel,
    templateMetadata,
}: {
    channel: CommunicationChannel | undefined;
    templateMetadata?: TemplateContentDetails | ExternalTemplate;
}) => {
    let steps = CampaignContentSteps;

    if (channel === CommunicationChannel.EMAIL) {
        if (
            (templateMetadata as EmailTemplateContentDetails).parsedVariables
                ?.length
        )
            return steps;
        return filterContentSteps(
            [CampaignContentStep.TRACK, CampaignContentStep.UTM_PARAMETERS],
            steps,
        );
    }
    if (channel === CommunicationChannel.SMS) {
        if (
            (templateMetadata as SmsTemplateContentDetails)?.variables?.[
                ContentMappingSection.BODY
            ]?.length
        ) {
            return steps.slice(1);
        }
        return filterContentSteps(
            [
                CampaignContentStep.SETUP,
                CampaignContentStep.TRACK,
                CampaignContentStep.UTM_PARAMETERS,
            ],
            steps,
        );
    }
    if (channel === CommunicationChannel.WHATSAPP) {
        const variables = (templateMetadata as ExternalTemplate)?.variables;
        if (
            variables?.[ContentMappingSection.BODY]?.length ||
            variables?.[ContentMappingSection.BUTTONS]?.length ||
            variables?.[ContentMappingSection.HEADER]?.length
        ) {
            return steps.slice(1);
        }
        return filterContentSteps(
            [
                CampaignContentStep.SETUP,
                CampaignContentStep.TRACK,
                CampaignContentStep.UTM_PARAMETERS,
            ],
            steps,
        );
    }

    return steps.slice(1);
};

export const getNextContentStep = (
    activeContentStep: CampaignContentStep,
    channel: CommunicationChannel | null | undefined,
    templateMetadata?: TemplateContentDetails | ExternalTemplate,
) => {
    if (!channel) return;
    const contentSteps = getChannelBasedContentSteps({
        channel,
        templateMetadata,
    });

    const index = contentSteps.findIndex(
        (step) => step.key === activeContentStep,
    );

    if (index < contentSteps.length - 1) {
        return contentSteps[index + 1]?.key;
    }
    return;
};

export const getPreviousContentStep = (
    activeContentStep: CampaignContentStep,
    channel: CommunicationChannel | null | undefined,
    templateMetadata?: TemplateContentDetails | ExternalTemplate,
) => {
    if (!channel) return;
    const contentSteps = getChannelBasedContentSteps({
        channel,
        templateMetadata,
    });
    const index = contentSteps.findIndex(
        (step) => step.key === activeContentStep,
    );

    if (index > 0) {
        return contentSteps[index - 1]?.key;
    }
    return;
};
/**
 * this function takes object where it have tableName and dimensionName
 * and return the fieldId
 */
export const getFieldIdFromReachability = (object: {
    tableName: string;
    dimensionName: string;
}) => {
    return object.tableName + '_' + object.dimensionName;
};
