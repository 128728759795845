import Timestamp from '@components/common/Timestamp';

import { useLocale } from '@hooks/useLocale';
import { EntityType, type AuditLogEntryInDb } from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import {
    ArrowSquareOut,
    FlowArrow,
    PaperPlaneTilt,
    TreeStructure,
    UsersThree,
} from '@phosphor-icons/react';

import { type ColumnDef } from '@tanstack/react-table';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getEntityURL } from '../utils';

const AUDIT_LOGS_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-40`;
const AUDIT_LOGS_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-40`;

export function useAuditLogsColumns() {
    const [columns, setColumns] = useState<ColumnDef<AuditLogEntryInDb>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    const entityUrl = useCallback(
        (entityType: EntityType, entityId: string, data: any) =>
            projectUuid
                ? getEntityURL({ entityType, entityId, data, projectUuid })
                : '',
        [projectUuid],
    );

    const EntityTypeIcon = useCallback((entityType: EntityType) => {
        switch (entityType) {
            case EntityType.Campaign:
                return (
                    <PaperPlaneTilt
                        weight="duotone"
                        size={14}
                        color="rgb(var(--color-lime))"
                    />
                );
            case EntityType.Audience:
                return (
                    <UsersThree
                        weight="duotone"
                        size={14}
                        color="rgb(var(--color-pink-800))"
                    />
                );
            case EntityType.Journey:
                return (
                    <FlowArrow
                        weight="duotone"
                        size={14}
                        color="rgb(var(--color-orange-800))"
                    />
                );
            case EntityType.Schema:
                return (
                    <TreeStructure
                        weight="duotone"
                        size={14}
                        color="rgb(var(--color-blu-800))"
                    />
                );
            default:
                return (
                    <PaperPlaneTilt
                        weight="duotone"
                        size={14}
                        color="rgb(var(--color-lime))"
                    />
                );
        }
    }, []);

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'timestamp',
                    header: t('Date'),
                    cell: ({ getValue }) => (
                        <Box className={AUDIT_LOGS_TABLE_CELL_CLASSNAME}>
                            <Flex align={'center'} gap={6}>
                                <Text className={AUDIT_LOGS_TABLE_CELL_NAME}>
                                    <Timestamp timestamp={getValue<string>()} />
                                </Text>
                            </Flex>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'userName',
                    header: t('USER'),
                    cell: ({ getValue }) => {
                        return (
                            <Box className="text-sm font-medium text-gray-800">
                                {getValue<string>() || '-'}
                            </Box>
                        );
                    },
                },
                {
                    accessorKey: 'action',
                    header: t('ACTION'),
                    cell: ({ getValue }) => {
                        return (
                            <Box className="text-sm font-medium text-gray-800 !w-fit">
                                {getValue<string>()}
                            </Box>
                        );
                    },
                },
                {
                    accessorKey: 'entityType',
                    header: t('RESOURCE TYPE'),
                    cell: ({ getValue }) => {
                        return (
                            <Box className="flex items-center font-medium gap-1 text-sm text-gray-800">
                                {EntityTypeIcon(getValue<EntityType>())}
                                {getValue<EntityType>()}
                            </Box>
                        );
                    },
                },
                {
                    accessorKey: 'entityName',
                    header: t('RESOURCE NAME'),
                    cell: ({ getValue, row }) => {
                        const isEntityUrl =
                            row.original.entityType === EntityType.Campaign ||
                            row.original.entityType === EntityType.Journey ||
                            row.original.entityType === EntityType.Audience;
                        return (
                            <Box className="text-sm text-gray-800">
                                <Text
                                    onClick={async () => {
                                        if (isEntityUrl) {
                                            window.open(
                                                entityUrl(
                                                    row.original.entityType,
                                                    row.original.entityId,
                                                    row.original.metadata,
                                                ),
                                                '_blank',
                                            );
                                        }
                                    }}
                                    className={`gap-1 flex items-center text-sm font-semibold text-blu-800 truncate max-w-44 ${
                                        isEntityUrl ? 'cursor-pointer' : ''
                                    }`}
                                >
                                    {getValue<string>()
                                        .charAt(0)
                                        .toUpperCase() +
                                        getValue<string>().slice(1)}
                                    {isEntityUrl && (
                                        <ArrowSquareOut
                                            weight="duotone"
                                            size={14}
                                            color="rgb(var(--color-blu-800))"
                                        />
                                    )}
                                </Text>
                            </Box>
                        );
                    },
                },
            ]);
        }
    }, [EntityTypeIcon, projectUuid, t, entityUrl]);

    return columns;
}
