import { useClickedCount } from '@hooks/useAnalytics';
import {
    type ApiCampaignClickedCountQueryDto,
    type GenericAnalyticsCountRequest,
} from '@lightdash/common';
import { Box, Skeleton, Table, Text } from '@mantine/core';
import useRelationContext from '@providers/Relation/useRelationContext';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

const ClickedLinksTable = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const campaignId = queryParams.get('campaignUuid') ?? '';
    const { activeRelationUuid } = useRelationContext();

    const {
        mutate: getClickedCount,
        data: clickedCount,
        isLoading,
    } = useClickedCount({
        relationId: activeRelationUuid,
    });

    useEffect(() => {
        const payload: GenericAnalyticsCountRequest = {
            campaignIds: [campaignId],
        };
        getClickedCount(payload);
    }, [campaignId, getClickedCount]);

    const elements = useMemo(() => {
        const count =
            clickedCount as unknown as ApiCampaignClickedCountQueryDto['results'];
        if (count?.[campaignId]) {
            const campaignCount = count[campaignId];
            return Object.keys(campaignCount).map((link) => ({
                link,
                totalClicks: campaignCount[link]?.totalClicks ?? 0,
                uniqueClicks: campaignCount[link]?.uniqueClicks ?? 0,
            }));
        }
    }, [campaignId, clickedCount]);

    const overallTotalClicks = useMemo(() => {
        if (elements) {
            return elements.reduce((accum, curr) => {
                return accum + curr.totalClicks;
            }, 0);
        }
        return 0;
    }, [elements]);

    const overallUniqueClicks = useMemo(() => {
        if (elements) {
            return elements.reduce((accum, curr) => {
                return accum + curr.uniqueClicks;
            }, 0);
        }
        return 0;
    }, [elements]);

    if (isLoading) {
        return (
            <Box className="border-base p-3 mt-3 mx-3">
                <Text className="text-base text-gray-800 font-semibold">
                    Click tracking
                </Text>
                <Skeleton height={20} width={300} className="mt-3" />
            </Box>
        );
    }

    if (!elements) return null;

    const rows = elements?.map((element) => {
        const totalClickPercentage = overallTotalClicks
            ? ((element.totalClicks / overallTotalClicks) * 100).toFixed(2)
            : 0;
        const uniqueClickPercentage = overallUniqueClicks
            ? ((element.uniqueClicks / overallUniqueClicks) * 100).toFixed(2)
            : 0;
        return (
            <tr key={element.link}>
                <td>{element.link}</td>
                <td>
                    {element?.totalClicks}
                    {` (${totalClickPercentage}%)`}
                </td>
                <td>
                    {element?.uniqueClicks}
                    {` (${uniqueClickPercentage}%)`}
                </td>
            </tr>
        );
    });

    return (
        <Box className="border-base p-3 my-3 mx-3">
            <Text className="text-base text-gray-800 font-semibold">
                Click tracking
            </Text>
            <Table withBorder withColumnBorders className="mt-4">
                <thead>
                    <tr>
                        <th>Link</th>
                        <th>Total clicks</th>
                        <th>Unique clicks</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        </Box>
    );
};

export default ClickedLinksTable;
