import { Group } from '@mantine/core';
import { MoonStars, Sun } from '@phosphor-icons/react';
import { useState, type FC } from 'react';
import { Theme } from './types';
interface IThemeSwitcherProps {
    onSwitch: (mode: Theme) => void;
}

const ThemeSwitcher: FC<IThemeSwitcherProps> = ({
    onSwitch,
}: IThemeSwitcherProps) => {
    const [mode, setMode] = useState(Theme.LIGHT);
    const toggleMode = (newMode: Theme) => {
        if (mode == newMode) return;

        setMode(newMode);
        onSwitch(newMode);
    };

    return (
        <Group spacing={0} className="items-center h-9 cursor-pointer">
            <Group
                className={`rounded-s-lg h-full border px-3 py-1 items-center ${
                    mode === Theme.LIGHT
                        ? 'bg-shade-6 dark:bg-gray-700 border-shade-0 dark:border-gray-700'
                        : 'bg-white dark:bg-gray-850 border-shade-6 dark:border-gray-700'
                }`}
                onClick={() => toggleMode(Theme.LIGHT)}
            >
                <Sun
                    color={
                        mode === Theme.DARK
                            ? 'rgb(var(--color-gray-600))'
                            : 'rgb(var(--color-gray-800))'
                    }
                />
            </Group>
            <Group
                className={`rounded-e-lg h-full border px-3 py-1 items-center ${
                    mode === Theme.DARK
                        ? 'bg-shade-6 dark:bg-gray-700 border-shade-0 dark:border-gray-700'
                        : 'bg-white dark:bg-gray-850 border-shade-6 dark:border-gray-700'
                }`}
                onClick={() => toggleMode(Theme.DARK)}
            >
                <MoonStars
                    color={
                        mode === Theme.DARK
                            ? 'white'
                            : 'rgb(var(--color-gray-600))'
                    }
                />
            </Group>
        </Group>
    );
};

export default ThemeSwitcher;
