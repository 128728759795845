import Modal from '@components/common/modal/Modal';
import { SegmentedTimeFilterOptions } from '@components/common/SegmentedTimeFilter/types';
import { getTimestampForOption } from '@components/common/SegmentedTimeFilter/utils';
import TimeFilterMenu from '@components/common/TimeFilterMenu';
import { ProfileActivityInfo } from '@components/Profiles/ProfileActivityAndEngagement/ProfileActivity';
import OtherEventHistory from '@components/Profiles/ProfileActivityAndEngagement/ProfileActivity/OtherEventHistory';
import { useLocale } from '@hooks/useLocale';
import { Box, Flex, Stack, Text } from '@mantine/core';
import { CursorClick, X } from '@phosphor-icons/react';
import { useState } from 'react';

interface EventHistoryModalProps {
    isEventHistoryModalOpen: boolean;
    closeEventHistoryModal: () => void;
    eventName: string;
}
const EventHistoryModal: React.FC<EventHistoryModalProps> = ({
    isEventHistoryModalOpen,
    closeEventHistoryModal,
    eventName,
}) => {
    const { t } = useLocale();
    const last7Days = getTimestampForOption(
        SegmentedTimeFilterOptions.LAST_7_DAYS,
    );
    const [_timeFilter, setTimeFilter] = useState<{
        startTimestamp?: string;
        endTimestamp?: string;
    }>({
        startTimestamp: last7Days?.[0]
            ? new Date(last7Days?.[0]).toISOString()
            : undefined,
        endTimestamp: last7Days?.[1]
            ? new Date(last7Days?.[1]).toISOString()
            : undefined,
    });
    const [currentSelectedTimeFilter, setCurrentSelectedTimeFilter] = useState<
        SegmentedTimeFilterOptions | undefined
    >(SegmentedTimeFilterOptions.LAST_7_DAYS);
    return (
        <Box
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                e.stopPropagation()
            }
        >
            <Modal
                opened={isEventHistoryModalOpen}
                onClose={closeEventHistoryModal}
                withCloseButton={false}
                size={1000}
                withContentPadding={false}
            >
                <Stack spacing={12}>
                    <Flex
                        className="text-gray-800 py-3   sticky top-0 bg-white z-10 px-4"
                        align="center"
                        justify="space-between"
                    >
                        <Flex align="center" gap={6}>
                            <Text className="text-gray-600 font-medium text-lg">
                                {t('event_manager.event_history_modal.title')}
                            </Text>
                            <Flex align="center" gap={4}></Flex>
                            <CursorClick color={'rgb(var(--color-gray-600))'} />
                            <Text className="text-gray-600 font-medium text-lg">
                                {eventName}
                            </Text>
                        </Flex>
                        <Flex align="center" gap={12}>
                            <TimeFilterMenu
                                onTimeFilterChange={(
                                    newTimeFilter,
                                    newFilter,
                                ) => {
                                    setTimeFilter({
                                        startTimestamp:
                                            newTimeFilter.startTimestamp
                                                ? new Date(
                                                      newTimeFilter.startTimestamp,
                                                  ).toISOString()
                                                : undefined,
                                        endTimestamp: newTimeFilter.endTimestamp
                                            ? new Date(
                                                  newTimeFilter.endTimestamp,
                                              ).toISOString()
                                            : undefined,
                                    });
                                    setCurrentSelectedTimeFilter(newFilter);
                                }}
                                filter={currentSelectedTimeFilter}
                            />
                            <X
                                weight="duotone"
                                onClick={closeEventHistoryModal}
                                className="cursor-pointer m-3"
                            />
                        </Flex>
                    </Flex>
                    <ProfileActivityInfo />
                    <OtherEventHistory
                        searchString={eventName}
                        currentSelectedTimeFilter={currentSelectedTimeFilter}
                        timeFilter={_timeFilter}
                        profileId={undefined}
                    />
                </Stack>
            </Modal>
        </Box>
    );
};

export default EventHistoryModal;
