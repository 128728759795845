import { useGetSuppressionConfig } from '@hooks/useDeliveryControlsApi';
import { useAudiences } from '@hooks/useGetAudience';
import { useLocale } from '@hooks/useLocale';
import { CommunicationChannel } from '@lightdash/common';
import { Box, Flex, Skeleton, Text } from '@mantine/core';
import { FileCsv } from '@phosphor-icons/react';
import React, { useMemo, type ReactElement } from 'react';
import AudienceItem from './AudienceItem';

type SendListProps = {
    audienceIds: string[] | undefined;
    filename: string | undefined;
    channel: CommunicationChannel;
    label: string;
    labelIcon: ReactElement<any, any>;
};

const SelectedAudienceList = ({
    audienceIds,
    filename,
    channel,
    label,
    labelIcon,
}: SendListProps) => {
    const { t } = useLocale();
    const { data: audiences, isLoading } = useAudiences({
        query: `idIn=${audienceIds?.join(',')}`,
        perPage: audienceIds?.length ?? 99,
        currentPage: 1,
        polling: false,
        enabled: !!audienceIds?.length,
    });
    const { data: suppressionConfig } = useGetSuppressionConfig({
        enabled: true,
    });

    const globalSuppressedAudiences = useMemo(() => {
        if (suppressionConfig) {
            return suppressionConfig.config.mappings
                .filter(
                    (item) =>
                        item.channel === channel ||
                        item.channel === CommunicationChannel.ANY,
                )
                .map((item) => item.audienceId);
        }
        return [];
    }, [channel, suppressionConfig]);

    const renderContent = useMemo(() => {
        if (isLoading && audienceIds?.length) {
            return <Skeleton width={120} height={20} />;
        }
        if (audiences?.data && audiences.data.length) {
            return audiences.data.map((item) => (
                <AudienceItem
                    key={item.id}
                    name={item.name}
                    count={item.totalCount ?? 0}
                    isGlobalSuppressed={globalSuppressedAudiences.includes(
                        item.id,
                    )}
                />
            ));
        }
        if (filename) {
            return (
                <Flex gap={8} align={'center'}>
                    <FileCsv color="rgb(var(--color-gray-800))" />
                    <Text className="font-medium text-gray-800">
                        {filename}
                    </Text>
                </Flex>
            );
        }
        return (
            <Text className="text-gray-500">
                {t('campaign_builder.audience.no_audience_selected')}
            </Text>
        );
    }, [
        isLoading,
        audienceIds?.length,
        audiences?.data,
        filename,
        t,
        globalSuppressedAudiences,
    ]);

    return (
        <Box>
            <Flex align={'center'} gap={4} className="mb-3">
                {labelIcon}
                {/* <PaperPlaneTilt color="rgb(var(--color-gray-700))" /> */}
                <Text className="font-semibold text-gray-700 uppercase">
                    {label}
                </Text>
            </Flex>
            {renderContent}
        </Box>
    );
};

export default SelectedAudienceList;
