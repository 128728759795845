import { sortmentApi } from '@api/index';
import { SortmentLogo } from '@assets/icons/Core';
import PasswordInput from '@components/common/Inputs/PasswordInput';
import { ThirdPartySignInButton } from '@components/common/ThirdPartySignInButton';
import Page from '@components/Page/Page';
import PageSpinner from '@components/PageSpinner';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import {
    getEmailSchema,
    LightdashMode,
    OpenIdIdentityIssuerType,
    SEED_ORG_1_ADMIN_EMAIL,
    SEED_ORG_1_ADMIN_PASSWORD,
    type ApiError,
    type LightdashUser,
} from '@lightdash/common';
import {
    Anchor,
    Box,
    Button,
    Divider,
    Stack,
    Text,
    TextInput,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import useApp from '@providers/App/useApp';
import useTracking from '@providers/Tracking/useTracking';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';
import { z } from 'zod';

type LoginParams = { email: string; password: string; orgUuid?: string };

const loginQuery = async (data: LoginParams) =>
    sortmentApi<LightdashUser>({
        url: `/login`,
        method: 'POST',
        body: JSON.stringify(data),
    });
interface LoginProps {
    isSuperAdminLogin: boolean;
}

const Login: React.FC<LoginProps> = ({ isSuperAdminLogin }) => {
    const { health } = useApp();
    const location = useLocation();
    const { t } = useLocale();
    const { showToastError } = useNotify();
    const redirectUrl = location.state?.from
        ? `${location.state.from.pathname}${location.state.from.search}`
        : '/';

    const form = useForm<LoginParams>({
        initialValues: {
            email: '',
            password: '',
        },
        validate: zodResolver(
            z.object({
                email: getEmailSchema(),
            }),
        ),
    });

    const { identify } = useTracking();

    const { isLoading, mutate, isSuccess, isIdle } = useMutation<
        LightdashUser,
        ApiError,
        LoginParams
    >(loginQuery, {
        mutationKey: ['login'],
        onSuccess: (data) => {
            identify({ id: data.userUuid });
            window.location.href = redirectUrl;
        },
        onError: (error) => {
            form.reset();
            showToastError({
                title: `Failed to login`,
                subtitle: error.error.message,
            });
        },
    });

    // Skip login for demo app
    const isDemo = health.data?.mode === LightdashMode.DEMO;

    useEffect(() => {
        if (isDemo && isIdle) {
            mutate({
                email: SEED_ORG_1_ADMIN_EMAIL.email,
                password: SEED_ORG_1_ADMIN_PASSWORD.password,
            });
        }
    }, [isDemo, mutate, isIdle]);

    if (health.isInitialLoading || isDemo) {
        return <PageSpinner />;
    }

    if (
        health.status === 'success' &&
        health.data?.isAuthenticated &&
        health.data?.requiresOrgRegistration
    ) {
        return <Navigate to="/register" />;
    }

    if (health.status === 'success' && health.data?.isAuthenticated) {
        return <Navigate to={redirectUrl} />;
    }
    const allowPasswordAuthentication =
        !health.data?.auth.disablePasswordAuthentication;

    const ssoAvailable =
        health.data?.auth.google.enabled ||
        health.data?.auth.okta.enabled ||
        health.data?.auth.oneLogin.enabled ||
        health.data?.auth.azuread.enabled ||
        health.data?.auth.oidc.enabled;

    const ssoLogins = ssoAvailable && (
        <Stack>
            {Object.values(OpenIdIdentityIssuerType).map((providerName) => (
                <ThirdPartySignInButton
                    key={providerName}
                    providerName={providerName}
                    redirect={redirectUrl}
                    className="py-3 rounded-lg"
                />
            ))}
        </Stack>
    );

    const passwordLogin = allowPasswordAuthentication && (
        <form
            name="login"
            onSubmit={form.onSubmit((values) => mutate(values))}
            className="w-full"
        >
            <Stack className="w-full gap-6">
                <Stack className="w-full gap-3.5">
                    <TextInput
                        label={t('common.email_label')}
                        name="email"
                        placeholder={t('common.email_placeholder')}
                        required
                        {...form.getInputProps('email')}
                        disabled={isLoading || isSuccess}
                        withAsterisk={false}
                    />
                    <Stack className="w-full gap-1">
                        <PasswordInput
                            label={t('common.password_label')}
                            name="password"
                            placeholder={t('common.password_placeholder')}
                            required
                            {...form.getInputProps('password')}
                            disabled={isLoading || isSuccess}
                            withAsterisk={false}
                        />
                        {isSuperAdminLogin && (
                            <TextInput
                                label={t('login.super_admin_label')}
                                name="orgUuid"
                                placeholder={t('login.super_admin_placeholder')}
                                required
                                {...form.getInputProps('orgUuid')}
                                disabled={isLoading || isSuccess}
                                withAsterisk={false}
                                className="mt-2"
                            />
                        )}
                        <Anchor
                            href="/recover-password"
                            className="text-sm font-medium text-blu"
                        >
                            {t('login.forgot_password')}
                        </Anchor>
                    </Stack>
                </Stack>

                <Button
                    type="submit"
                    loading={isLoading || isSuccess}
                    data-cy="signin-button"
                    className="py-3 rounded-lg"
                >
                    {t('common.continue_button')}
                </Button>
            </Stack>
        </form>
    );

    const logins = (
        <>
            {passwordLogin}
            {ssoLogins && passwordLogin && (
                <Divider
                    label={
                        <>
                            <Text className="text-sm font-semibold text-gray-500 uppercase">
                                {t('common.or')}
                            </Text>
                        </>
                    }
                    labelPosition="center"
                    size="xs"
                    className="w-full"
                    styles={() => ({
                        label: {
                            '&::before': {
                                background:
                                    'linear-gradient(to right, transparent, rgb(var(--color-gray-200)))',
                                border: 'none',
                                height: '1px',
                            },
                            '&::after': {
                                background:
                                    'linear-gradient(to left, transparent, rgb(var(--color-gray-200)))',
                                border: 'none',
                                height: '1px',
                            },
                            marginHorizontal: '8px',
                        },
                    })}
                />
            )}
            <Box className="w-full">{ssoLogins}</Box>
        </>
    );

    return (
        <Page
            title="Login"
            withCenteredContent
            withFullHeight
            backgroundColor="gray"
        >
            <section className="w-[300px] px-3 pt-6 pb-3 bg-white rounded-3xl flex flex-col items-center border-base gap-3 shadow-card">
                <div className="flex flex-col items-center w-full gap-2">
                    <SortmentLogo />
                    <p className="text-sm font-medium text-gray-600">
                        {t('login.login_header')}
                    </p>
                </div>
                {logins}
            </section>
        </Page>
    );
};

export default Login;
