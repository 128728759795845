import {
    type CustomAttribute,
    type MetricType,
    type UserTraitFieldConfig,
    type UserTraits,
} from '@lightdash/common';

export enum CustomMetricMode {
    BASIC = 'Basic',
    ADVANCED = 'Advanced',
}

export enum CustomMetricTab {
    CALCULATED = 'CALCULATED',
    DYNAMIC = 'DYNAMIC',
}
export type CustomMetric = {
    activePill: CustomMetricTab;
    calculatedMetrics: CustomAttribute[];
    dynamicMetrics: UserTraitFieldConfig[];
};

export type CustomAttributeForm = {
    name: string;
    description: string;
    metric: MetricType;
};

export interface CustomMetricManagerContentProps {
    openBaseTable: boolean;
    setOpenBaseTable: React.Dispatch<React.SetStateAction<boolean>>;
    updateTraits: UserTraits | undefined;
    openUpdateTraitsModal: () => void;
    customMetrics: CustomAttribute[] | undefined;
    isHiddenActive: boolean;
    onHiddenChange: (value: boolean) => void;
    hiddenCustomMetrics: CustomAttribute[] | undefined;
}
