import {
    convertAdditionalMetric,
    DimensionType,
    getItemId,
    getResultValueArray,
    getVisibleFields,
    getVisibleTables,
    isCustomSqlDimension,
    isFilterableField,
    isTableCalculation,
    WarehouseTypes,
    type AdditionalMetric,
    type ApiQueryResults,
    type CompiledRelation,
    type CustomDimension,
    type Metric,
    type TableCalculation,
} from '@lightdash/common';
import useProjectContext from '@providers/Project/useProjectContext';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { type FieldsWithSuggestions } from '../FiltersProvider/types';
interface FieldsWithSuggestionsHookParams {
    relationData: CompiledRelation | undefined;
    queryResults: ApiQueryResults | undefined;
    customDimensions: CustomDimension[] | undefined;
    additionalMetrics: AdditionalMetric[] | undefined;
    tableCalculations: TableCalculation[] | undefined;
    hideOrphanedTables: boolean;
}

export const useFieldsWithSuggestions = ({
    relationData,
    queryResults,
    customDimensions,
    additionalMetrics,
    tableCalculations,
    hideOrphanedTables = true,
}: FieldsWithSuggestionsHookParams) => {
    const [fieldsWithSuggestions, setFieldsWithSuggestions] =
        useState<FieldsWithSuggestions>({});
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData } = useProjectContext();

    useEffect(() => {
        if (relationData) {
            const filteredRelationData = hideOrphanedTables
                ? getVisibleTables(
                      relationData,
                      projectUuid ?? '',
                      projectData?.warehouseConnection?.type ??
                          WarehouseTypes.SNOWFLAKE,
                  )
                : relationData;

            setFieldsWithSuggestions((prev) => {
                const visibleFields = getVisibleFields(filteredRelationData);
                const filteredCustomDimensionsInVisibleFields =
                    visibleFields.filter((field) => {
                        if ('isCustomDimension' in field) {
                            return !field.isCustomDimension;
                        }
                        return true;
                    });
                const customMetrics = (additionalMetrics || []).reduce<
                    Metric[]
                >((acc, additionalMetric) => {
                    const table =
                        filteredRelationData.tables[additionalMetric.table];
                    if (table) {
                        const metric = convertAdditionalMetric({
                            additionalMetric,
                            table,
                        });
                        return [...acc, metric];
                    }
                    return acc;
                }, []);
                return [
                    ...filteredCustomDimensionsInVisibleFields,
                    ...(customDimensions || []),
                    ...customMetrics,
                    ...(tableCalculations || []),
                ].reduce((sum, field) => {
                    if (isFilterableField(field)) {
                        let suggestions: string[] = [];
                        const type = isCustomSqlDimension(field)
                            ? field.dimensionType
                            : field.type;
                        if (type === DimensionType.STRING) {
                            const currentSuggestions =
                                prev[getItemId(field)]?.suggestions || [];
                            const newSuggestions: string[] =
                                (queryResults &&
                                    queryResults.rows &&
                                    getResultValueArray(
                                        queryResults.rows,
                                        true,
                                    ).results.reduce<string[]>((acc, row) => {
                                        const value = row[getItemId(field)];
                                        if (typeof value === 'string') {
                                            return [...acc, value];
                                        }
                                        return acc;
                                    }, [])) ||
                                [];
                            suggestions = Array.from(
                                new Set([
                                    ...currentSuggestions,
                                    ...newSuggestions,
                                ]),
                            ).sort((a, b) => a.localeCompare(b));
                        }
                        if (isTableCalculation(field)) {
                            return {
                                ...sum,
                                [getItemId(field)]: {
                                    ...field,
                                    suggestions,
                                },
                            };
                        }

                        return {
                            ...sum,
                            [getItemId(field)]: {
                                ...field,
                                suggestions,
                                tableType:
                                    filteredRelationData.tables[field.table]
                                        ?.type,
                            },
                        };
                    }

                    return sum;
                }, {});
            });
        }
    }, [
        relationData,
        queryResults,
        additionalMetrics,
        tableCalculations,
        customDimensions,
        hideOrphanedTables,
        projectUuid,
        projectData?.warehouseConnection?.type,
    ]);

    return fieldsWithSuggestions;
};
