import { createContext } from 'react';
import { type RelationContext } from './types';

const RelationProviderContext = createContext<RelationContext>({
    activeRelationUuid: '',
    activeRelation: undefined,
    relations: [],
    tables: [],
    getTableRelation: () => undefined,
    isInitialLoading: false,
    customDimensionsFields: {},
});

export default RelationProviderContext;
