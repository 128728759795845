import {
    type ControlPanelState,
    type JourneyNodeData,
    type JourneyNodeUpdatePayload,
    type TriggerType,
} from '@components/Journeys/Builder/types';
import {
    type BaseTrigger,
    type Branch,
    type BranchConfig,
    type ConversionTrigger,
    type EntryTrigger,
    type FilterableField,
    type Journey,
    type JourneyAction,
    type JourneyAndVersionConfig,
    type JourneyBlocksList,
    type JourneyCreatePayload,
    type JourneyEntryLogic,
    type JourneyEventMapperSchema,
    type JourneyPublishConfig,
    type JourneyStatus,
    type JourneyTriggerConfig,
    type JourneyVersion,
} from '@lightdash/common';
import { type JourneyAnalytics } from '@pages/JourneyBuilder';
import { type Edge, type Node } from 'reactflow';

export interface ActiveSplitField {
    isJourneyField: boolean;
    field: FilterableField | undefined;
}
export interface JourneyBuilderContext {
    state: JourneyBuilderState;
    actions: {
        addNode: (blockId: string, reactFlowNodeId: string) => void;
        addPlaceholderNode: (nodeId?: string) => void;
        openControlPanel: ({ ...args }: ControlPanelState) => void;
        closeControlPanel: () => void;
        addTriggerNode: (payload: Partial<EntryTrigger>) => void;
        updateNodeActionConfig: (
            nodeId: string,
            updatedAction: JourneyAction,
        ) => void;
        removePlaceholderNodes: () => void;
        addPlaceholderNodeBetween: (edgeId: string) => void;
        updateJourneyPayload: (
            payload: Partial<JourneyAndVersionConfig>,
        ) => void;
        setExitTriggers: (payload: JourneyTriggerConfig['exit']) => void;
        updateExitNode: (payload: {
            payload: Partial<BaseTrigger>;
            id: string;
        }) => void;
        setNodes: (nodes: Node<JourneyNodeData>[]) => void;
        setNodesUnselected: (nodes: Node<JourneyNodeData>[]) => void;
        setGoals: (goals: Partial<ConversionTrigger>) => void;
        setEntryLogic: (payload: Partial<JourneyEntryLogic>) => void;
        mutateAsyncJourney: () => Promise<Journey | undefined>; //Info: This is a wrapper for the createJourney and UpdateJourney hooks
        mutateActivateJourney: (payload: JourneyPublishConfig) => Promise<void>;
        updateTriggerNode: (payload: Partial<EntryTrigger>) => void;
        updateNodeConfig: (payload: JourneyNodeUpdatePayload) => void;
        deleteNode: (nodeId: string, deleteAllChildBranches?: boolean) => void;
        canSave: () => boolean;
        canLaunch: () => boolean;
        addEdgeWithGhostNode: (
            nodeId: string,
            branch: Branch | undefined,
            edgeName: string | undefined,
            destinationNodeId: string | undefined,
            branchIndex: number | undefined,
            ghostNodeId: string | undefined,
            isVisible?: boolean,
        ) => void;
        updateBranchConfig: (
            nodeId: string,
            updatedBranchConfig: BranchConfig,
        ) => void;
        updateSplitActiveFields: (
            nodeId: string,
            activeField: FilterableField | undefined,
            isJourneyField: boolean,
        ) => void;
        deleteAllChildBranches: (nodeId: string) => void;
        updateBranchingEdgeLabel: (edgeId: string, label: string) => void;
        createEveryOneElsePath: (nodeId: string, blockId: string) => void;
        addGhostNodeWithBranchingEdge: (
            nodeId: string,
            blockId: string,
        ) => void;
        fetchJourneyAnalytics: () => Promise<void>;
        getTriggerType: () => TriggerType | undefined;
        createDraftVersion: () => Promise<void>;
        removeFilterFromConfig: (nodeId: string | undefined) => void;
        callGenerateNodeDescription: (nodeId: string) => void;
    };
}

interface JourneyBuilderBaseState {
    nodes: Node<JourneyNodeData>[];
    edges: Edge[];
    journeyPayload: JourneyCreatePayload;
    blocksList: JourneyBlocksList;
    journeyStatus: JourneyStatus;
    splitActiveFields: Record<string, ActiveSplitField>;
}

interface JourneyBuilderOpenState extends JourneyBuilderBaseState {
    controlPanel: ControlPanelState & { isOpen: true };
}

interface JourneyBuilderClosedState extends JourneyBuilderBaseState {
    controlPanel: { isOpen: false };
}

export type JourneyReducerState =
    | JourneyBuilderOpenState
    | JourneyBuilderClosedState;

export type JourneyBuilderState = JourneyReducerState & {
    isLoading: boolean;
    initialJourneyPayload: JourneyCreatePayload;
    uuid: string | undefined;
    journeyEvents: JourneyEventMapperSchema[] | undefined;
    isEditable: boolean;
    journeyAnalytics: JourneyAnalytics[] | undefined;
    isLoadingJourneyAnalytics: boolean;
    journeyVersions: JourneyVersion[] | undefined;
    selectedJourneyVersionId: string | undefined;
};

export enum ActionType {
    ADD_NODE,
    ADD_PLACEHOLDER_NODE,
    OPEN_CONTROL_PANEL,
    CLOSE_CONTROL_PANEL,
    ADD_TRIGGER_NODE,
    ADD_SCHEDULE_TRIGGER_NODE,
    UPDATE_NODE_ACTION_CONFIG,
    REMOVE_PLACEHOLDER_NODES,
    ADD_PLACEHOLDER_NODE_BETWEEN,
    UPDATE_JOURNEY_PAYLOAD,
    SET_EXIT_TRIGGERS,
    UPDATE_EXIT_NODE,
    SET_NODES,
    SET_NODES_UNSELECTED,
    SET_GOALS,
    SET_ENTRY_LOGIC,
    UPDATE_TRIGGER_NODE,
    UPDATE_NODE_CONFIG,
    DELETE_NODE,
    ADD_EDGE_WITH_GHOST_NODE,
    UPDATE_BRANCH_CONFIG,
    UPDATE_SPLIT_ACTIVE_FIELDS,
    DELETE_ALL_CHILD_BRANCHES,
    UPDATE_BRANCHING_EDGE_LABEL,
    UPDATE_NODE_DESCRIPTION,
    CREATE_EVERY_ONE_ELSE_PATH,
    ADD_GHOST_NODE_WITH_BRANCHING_EDGE,
    RESET_REDUCER_STATE,
    REMOVE_FILTER_FROM_CONFIG,
}
