import { useLocale } from '@hooks/useLocale';
import { Button } from '@mantine/core';
import { ClockCountdown } from '@phosphor-icons/react';
import { ButtonVariant } from '../../../../../mantineTheme';
interface SetTimeWindowButtonProps {
    onClick: () => void;
}
export const SetTimeWindowButton = ({ onClick }: SetTimeWindowButtonProps) => {
    const { t } = useLocale();

    return (
        <Button
            variant={ButtonVariant.SUBTLE}
            onClick={onClick}
            size="md"
            className="w-fit"
        >
            <ClockCountdown
                weight="duotone"
                color={'rgb(var(--color-gray-800))'}
                className="mr-1"
            />
            {t('audience_filter.event_filter_time_window')}
        </Button>
    );
};
