// RightSectionMenu.tsx

import LinkButton from '@components/common/LinkButton';
import { useLocale } from '@hooks/useLocale';
import { Button, Group, Menu, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CopySimple, PlusCircle } from '@phosphor-icons/react';
import { useParams } from 'react-router';
import { ButtonVariant } from '../../mantineTheme';

interface RightSectionMenuProps {
    onCloneClick: () => void;
}

const EventManagerRightSection: React.FC<RightSectionMenuProps> = ({
    onCloneClick,
}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [menuOpened, { open: menuOpen, close: menuClose }] = useDisclosure();

    return (
        <Menu opened={menuOpened} onClose={menuClose}>
            <Menu.Target>
                <Button
                    variant={ButtonVariant.PRIMARY}
                    leftIcon={<PlusCircle color="white" />}
                    onClick={menuOpen}
                    size="md"
                >
                    <Text className="text-sm font-semibold">
                        {t('events.create_title')}
                    </Text>
                </Button>
            </Menu.Target>

            <Menu.Dropdown>
                <Group className="flex flex-col">
                    <LinkButton
                        href={`/projects/${projectUuid}/events/create`}
                        variant={ButtonVariant.DEFAULT}
                        className="min-w-full border-none"
                        leftIcon={<PlusCircle size={13} />}
                        styles={{
                            label: {
                                justifyContent: 'left',
                            },
                        }}
                    >
                        {t('events_manager.create_new_event')}
                    </LinkButton>
                    <Button
                        variant={ButtonVariant.DEFAULT}
                        leftIcon={<CopySimple weight="duotone" />}
                        className="border-none"
                        onClick={() => {
                            onCloneClick();
                            menuClose();
                        }}
                    >
                        {t('events_manager.clone_existing_event')}
                    </Button>
                </Group>
            </Menu.Dropdown>
        </Menu>
    );
};

export default EventManagerRightSection;
