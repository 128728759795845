import { sortmentApi } from '@api/index';
import { type ApiError, type AudienceList } from '@lightdash/common';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { LIST_REFETCH_TIME } from '@utils/constants';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
const getAudiences = async (
    perPage: number,
    currentPage: number,
    projectId: string,
    relationId: string,
    query: string,
) =>
    sortmentApi<AudienceList>({
        url: `/projects/${projectId}/relations/${relationId}/audiences?perPage=${perPage}&currentPage=${currentPage}&${query}`,
        method: 'GET',
        body: undefined,
    });

interface UseAudiencesProps {
    perPage: number;
    currentPage: number;
    query?: string;
    name?: string;
    polling: boolean;
    enabled?: boolean;
}

export const useAudiences = ({
    perPage,
    currentPage,
    query,
    polling = true,
    enabled = true,
}: UseAudiencesProps) => {
    const params = useParams<{ projectUuid?: string }>();
    const { activeRelationUuid: relationId } = useRelationContext();
    const audiences = useQuery<AudienceList, ApiError>({
        queryKey: [QueryKeys.GET_AUDIENCE, perPage, currentPage, query],
        queryFn: () =>
            getAudiences(
                perPage,
                currentPage,
                params?.projectUuid || '',
                relationId,
                query || '',
            ),
        enabled: !!relationId && enabled,
        keepPreviousData: true,
        refetchInterval: polling ? LIST_REFETCH_TIME : false,
    });
    return audiences;
};

interface UseInfiniteAudiencesProps {
    perPage: number;
    query?: string;
    polling?: boolean;
    // currentPage: number;
}

export const useInfiniteAudiences = ({
    perPage,
    query,
    polling = true,
}: UseInfiniteAudiencesProps) => {
    const params = useParams<{ projectUuid?: string }>();
    const { activeRelationUuid: relationId } = useRelationContext();

    return useInfiniteQuery<AudienceList, ApiError>({
        queryKey: [QueryKeys.GET_AUDIENCE, perPage, query],
        queryFn: ({ pageParam = 1 }) =>
            getAudiences(
                perPage,
                pageParam,
                params?.projectUuid || '',
                relationId,
                query || '',
            ),
        getNextPageParam: (lastPage, allPages) => {
            // Assuming the API returns total pages or a way to determine if more pages exist
            const totalPages = Math.ceil(
                (lastPage.paginate.total ?? 0) / perPage,
            );
            const nextPage =
                allPages[lastPage ? lastPage.paginate.currentPage - 1 : 0]
                    .paginate.currentPage + 1;
            return nextPage <= totalPages ? nextPage : undefined;
        },
        enabled: !!relationId,
        keepPreviousData: true,
        refetchInterval: polling ? LIST_REFETCH_TIME : false,
        select: (data) => ({
            ...data,
            // Flatten items from all pages
            allItems: data.pages.flatMap((page) => page.data),
            // Combine pagination info from the last page
            totalItems: data.pages[data.pages.length - 1].paginate.total,
            totalPages: Math.ceil(
                (data.pages[data.pages.length - 1].paginate.total ?? 0) /
                    perPage,
            ),
        }),
    });
};
