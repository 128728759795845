import { useLocale } from '@hooks/useLocale';
import { type IterableListInfo } from '@lightdash/common';
import {
    Box,
    Checkbox,
    Flex,
    Radio,
    Select,
    Text,
    Textarea,
    TextInput,
} from '@mantine/core';
import React from 'react';
import { type FieldWithSuggestions } from '../Filters/FiltersProvider/types';

enum CreateMethod {
    EXISTING = 'existing',
    NEW = 'new',
}
interface IterableExportSectionProps {
    previewFields: Array<{
        fieldWithSuggestion: FieldWithSuggestions;
        fieldId: string;
    }>;
    emailField: string | undefined;
    setEmailField: (value: string) => void;
    listId: number | undefined;
    setListId: (value: number | undefined) => void;
    name: string | undefined;
    setName: (value: string | undefined) => void;
    description: string | undefined;
    setDescription: (value: string | undefined) => void;
    updateExistingUsersOnly: boolean;
    setUpdateExistingUsersOnly: (value: boolean) => void;
    iterableLists: IterableListInfo[];
    isLoading: boolean;
    useExistingList: boolean;
    setExistingList: (value: boolean) => void;
}

export const IterableExportSection: React.FC<IterableExportSectionProps> = ({
    previewFields,
    emailField,
    setEmailField,
    listId,
    setListId,
    name,
    setName,
    description,
    setDescription,
    updateExistingUsersOnly,
    setUpdateExistingUsersOnly,
    iterableLists,
    isLoading,
    useExistingList,
    setExistingList,
}) => {
    const { t } = useLocale();

    const availableFields = previewFields.map(
        ({ fieldWithSuggestion, fieldId }) => ({
            value: fieldId,
            label: fieldWithSuggestion.name ?? fieldId,
        }),
    );

    return (
        <Box>
            <Box className="mb-2 p-3 border-b border-gray-400">
                <Text className="text-base">
                    {t('audience_export_iterable.export_settings_title')}
                </Text>
            </Box>
            <Box className="p-4 border-b border-dashed border-gray-200">
                <Text className="text-sm mb-2 font-medium">
                    {t('audience_export_iterable.export_settings_select_list')}
                </Text>
                <Radio.Group
                    value={
                        useExistingList
                            ? CreateMethod.EXISTING
                            : CreateMethod.NEW
                    }
                    onChange={(value) =>
                        setExistingList(value === CreateMethod.EXISTING)
                    }
                >
                    <Flex direction="column" gap="xs">
                        <Radio
                            value={CreateMethod.EXISTING}
                            label={t(
                                'audience_export_iterable.export_settings_select_list_existing_list',
                            )}
                        />
                        {useExistingList && (
                            <Box ml="xs">
                                <Select
                                    placeholder={t(
                                        'audience_export_iterable.export_settings_select_list',
                                    )}
                                    data={iterableLists.map((list) => ({
                                        value: list.id.toString(),
                                        label: list.name,
                                    }))}
                                    value={listId?.toString()}
                                    onChange={(value) =>
                                        setListId(
                                            value ? parseInt(value) : undefined,
                                        )
                                    }
                                    disabled={isLoading}
                                />
                            </Box>
                        )}
                        <Radio
                            value={CreateMethod.NEW}
                            label={t(
                                'audience_export_iterable.export_settings_select_list_create_list',
                            )}
                        />
                        {!useExistingList && (
                            <Box mb="xs">
                                <Flex direction="column" gap="xs">
                                    <Box className="mt-2">
                                        <Text className="mb-1 font-medium">
                                            {t(
                                                'audience_export_iterable.name_label',
                                            )}
                                        </Text>
                                        <TextInput
                                            placeholder={t(
                                                'audience_export_iterable.name_placeholder',
                                            )}
                                            value={name ?? ''}
                                            onChange={(event) =>
                                                setName(
                                                    event.currentTarget.value,
                                                )
                                            }
                                        />
                                    </Box>
                                    <Box>
                                        <Text className="mb-1 font-medium">
                                            {t(
                                                'audience_export_iterable.description_label',
                                            )}
                                        </Text>
                                        <Textarea
                                            placeholder="audience_export_iterable.description_placeholder"
                                            value={description ?? ''}
                                            onChange={(event) =>
                                                setDescription(
                                                    event.currentTarget.value,
                                                )
                                            }
                                            minRows={2}
                                            maxRows={3}
                                        />
                                    </Box>
                                </Flex>
                            </Box>
                        )}
                    </Flex>
                </Radio.Group>
            </Box>

            <Box className="p-4 border-b border-dashed border-gray-200">
                <Text className="text-sm mb-2 font-medium">
                    {t(
                        'audience_export_iterable.export_settings_update_method',
                    )}
                </Text>
                <Checkbox
                    label={t(
                        'audience_export_iterable.update_existing_users_only',
                    )}
                    checked={updateExistingUsersOnly}
                    onChange={(event) =>
                        setUpdateExistingUsersOnly(event.currentTarget.checked)
                    }
                />
            </Box>

            <Box className="p-4">
                <Text className="text-sm mb-2 font-medium">
                    {t(
                        'audience_export_iterable.email_field_selection_description',
                    )}
                </Text>
                <Select
                    placeholder={t(
                        'audience_export_iterable.export_settings_select_email',
                    )}
                    data={availableFields}
                    value={emailField}
                    onChange={(value) => setEmailField(value ?? '')}
                />
            </Box>
        </Box>
    );
};
