import useNotify from '@hooks/toaster/useNotify';
import { useAudiencePreviewByPayload } from '@hooks/useAudiencePreview';
import { useCreateCustomMetricMutation } from '@hooks/useCustomMetric';
import { useFilteringTables } from '@hooks/useGetRelatedTables';
import { useLocale } from '@hooks/useLocale';
import {
    FieldType,
    type AdditionalMetric,
    type CompiledRelationTable,
    type CustomSqlDimension,
    type DimensionType,
    type MetricType,
} from '@lightdash/common';
import {
    Box,
    Button,
    Divider,
    Flex,
    Loader,
    Text,
    TextInput,
} from '@mantine/core';

import AudiencePreviewModal from '@components/Audience/AudiencePreview/AudiencePreviewData';
import TextArea from '@components/common/Inputs/TextArea';
import TagsMultiSelect from '@components/common/Tags/TagsMultiSelect';
import { handleInternalTags } from '@components/common/Tags/utils';
import { QueryGenerationStrategy } from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import { AsteriskSimple, CaretRight } from '@phosphor-icons/react';
import { type CustomAttributeReducerState } from '@providers/CustomAttribute/types';
import useCustomAttributeContext from '@providers/CustomAttribute/useCustomAttributeContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { ButtonVariant } from '../../mantineTheme';
import TablesListMenu from './CalculatedTraitBaseModal/TableListMenu';
import CustomDimensionLogic from './CustomAttributeModal/CustomDimensionLogic';
import CustomMetricLogic from './CustomAttributeModal/CustomMetricLogic';
import { type CustomAttributeForm } from './types';
import {
    generateDimensionPreviewPayload,
    generateMetricPreviewPayload,
    getCustomAttributeName,
    getFilteredTables,
    getInitialCustomAttributeDefinition,
} from './utils';
interface CustomAttributeContainerProps {
    initialState: CustomAttributeReducerState;
}

const CustomAttributeContainer = ({
    initialState,
}: CustomAttributeContainerProps) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [previewOpened, { open: openPreview, close: previewClose }] =
        useDisclosure(false);
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const { availableTables, nonAvailableTables } =
        useFilteringTables(activeRelation);
    const navigate = useNavigate();
    const {
        addNameDetailsToPayload,
        addDescriptionDetailsToPayload,
        setTags,
        selectTable,
        addBaseTableDetails,
        resetState,
        setFilters,
        selectDimension,
        addSelectedDimensionDetails,
        addFilttersToCustomMetric,
        setDefinition,
    } = useCustomAttributeContext((context) => context.actions);

    const {
        selectedTable,
        selectedDimension,
        customAttributePayload,
        isEditMode,
        fieldType,
    } = useCustomAttributeContext((context) => context.reducerState);
    const customAttributeName = useMemo(() => {
        return getCustomAttributeName(customAttributePayload);
    }, [customAttributePayload]);

    const { mutateAsync, isLoading } = useCreateCustomMetricMutation();
    const { showToastError } = useNotify();

    const form = useForm<CustomAttributeForm>({
        defaultValues: {
            name: customAttributeName ?? '',
            description: customAttributePayload.description ?? '',
            metric: '' as MetricType,
        },
    });
    useEffect(() => {
        if (initialState) {
            resetState(initialState);
        }
    }, [initialState, resetState]);
    const { mutateAsync: mutateAsyncPreview, data: previewData } =
        useAudiencePreviewByPayload();
    const handleSubmit = useCallback(async () => {
        if (customAttributeName.trim().length === 0) {
            showToastError({
                title: t('custom_metric_create_name_enter_toast'),
            });
        } else {
            await mutateAsync(customAttributePayload, {
                onSuccess: async () => {
                    await navigate(`/projects/${projectUuid}/traits`);
                },
            });
        }
    }, [
        customAttributeName,
        mutateAsync,
        showToastError,
        t,
        customAttributePayload,
        projectUuid,
        navigate,
    ]);

    const handleTableSelect = useCallback(
        (table: CompiledRelationTable) => {
            // If the selected table is changed, reset the filters
            if (selectedTable?.name !== table.name) {
                setFilters(undefined);
                addFilttersToCustomMetric([]);
                selectDimension(undefined);
                addSelectedDimensionDetails(undefined);
                setDefinition(
                    getInitialCustomAttributeDefinition({
                        fieldType: customAttributePayload.type,
                        tableName: undefined,
                    }) as AdditionalMetric | CustomSqlDimension,
                );
            }
            selectTable(table);
            addBaseTableDetails(table);
        },
        [
            selectTable,
            addBaseTableDetails,
            setFilters,
            selectDimension,
            addSelectedDimensionDetails,
            addFilttersToCustomMetric,
            selectedTable,
            setDefinition,
            customAttributePayload,
        ],
    );
    const disableFinishButton = useMemo(() => {
        if (!customAttributeName || customAttributeName.trim().length === 0) {
            return true;
        }
        if (fieldType === FieldType.METRIC) {
            return (
                isLoading ||
                selectedDimension === undefined ||
                (customAttributePayload.definition?.type?.length ?? 0) < 1
            );
        }
        return (
            isLoading ||
            !customAttributePayload.definition ||
            (
                customAttributePayload.definition as CustomSqlDimension
            )?.sql?.trim().length <= 0
        );
    }, [
        isLoading,
        selectedDimension,
        customAttributePayload,
        fieldType,
        customAttributeName,
    ]);

    const handlePreview = useCallback(async () => {
        openPreview();
        const payload =
            fieldType === FieldType.METRIC
                ? generateMetricPreviewPayload(customAttributePayload)
                : generateDimensionPreviewPayload(customAttributePayload);
        if (payload) {
            await mutateAsyncPreview({
                relationUuid: activeRelationUuid,
                data: payload,
                dimensions: [],
                metrics: [],
            });
        }
    }, [
        activeRelationUuid,
        mutateAsyncPreview,
        customAttributePayload,
        fieldType,
        openPreview,
    ]);
    const { filteredAvailableTables, filteredNonAvailableTables } =
        useMemo(() => {
            return getFilteredTables(
                availableTables,
                nonAvailableTables,
                fieldType,
            );
        }, [availableTables, nonAvailableTables, fieldType]);
    const handleTagsChange = useCallback(
        (newTags: string[]) => {
            const updatedTags = handleInternalTags(
                newTags,
                customAttributePayload.tags ?? [],
            );
            setTags(updatedTags);
        },
        [customAttributePayload.tags, setTags],
    );
    return (
        <>
            <form
                name="create_custom_metric_form"
                id="create_custom_metric_form"
                onSubmit={form.handleSubmit(handleSubmit)}
                onKeyDown={(event) => {
                    if (event.key === 'Escape') {
                        event.stopPropagation();
                    }
                }}
                className="bg-white"
            >
                <Flex
                    className="text-gray-800 py-3 border-b border-gray-250 px-9 sticky top-0 z-10 bg-white"
                    align="center"
                    justify="space-between"
                >
                    <Text className="text-gray-800 font-medium text-lg">
                        {!isEditMode
                            ? t('custom_metric.edit_trait_modal.title')
                            : t('custom_metric.create_new_trait_modal.title')}
                    </Text>
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        onClick={() => {
                            void navigate(`/projects/${projectUuid}/traits`);
                        }}
                    >
                        {t('common.exit')}
                    </Button>
                </Flex>

                <Flex
                    direction="column"
                    gap="lg"
                    className="text-gray-800 !h-full py-6 px-9"
                >
                    <Flex align="center" gap="xs">
                        <AsteriskSimple
                            weight="duotone"
                            size={14}
                            color="rgb(var(--color-purple))"
                        />
                        <Text className="text-gray-700 text-sm">
                            {!isEditMode
                                ? t(
                                      'custom_metric.view_trait_modal.creating_trait_in',
                                  )
                                : t(
                                      'custom_metric.create_new_trait_modal.creating_trait_in',
                                  )}
                        </Text>
                        <Box className="w-fit">
                            <TablesListMenu
                                selectedTable={selectedTable}
                                tables={filteredAvailableTables}
                                nonAvailableTables={filteredNonAvailableTables}
                                handleTableSelect={handleTableSelect}
                                disabled={isLoading || !isEditMode}
                            />
                        </Box>
                        <Box>...</Box>
                    </Flex>
                    <Text className="text-gray-800 font-medium text-lg">
                        {t(
                            'custom_metric.create_new_trait_modal.trait_details',
                        )}
                    </Text>
                    <Box>
                        <TextInput
                            data-autofocus
                            label={
                                <Text className="text-gray-800">
                                    {t(
                                        'custom_metric.dimension_modal_name_label',
                                    )}
                                </Text>
                            }
                            className="!w-[37rem]"
                            {...form.register('name')}
                            value={customAttributeName}
                            onChange={(event) => {
                                const value = event.currentTarget.value;
                                form.setValue('name', value);
                                addNameDetailsToPayload(value);
                            }}
                            readOnly={!isEditMode}
                            disabled={isLoading}
                        />
                    </Box>
                    <TextArea
                        label={
                            <Box>
                                <Text className="inline me-2">
                                    {t(
                                        'custom_metric.dimension_modal_description_label',
                                    )}
                                </Text>
                                <Text className="inline text-gray-500">
                                    {t(
                                        'custom_metric.dimension_modal_description_label_optional',
                                    )}
                                </Text>
                            </Box>
                        }
                        placeholder={t(
                            'custom_metric.dimension_modal_description_description',
                        )}
                        {...form.register('description')}
                        value={customAttributePayload.description ?? ''}
                        onChange={(event) => {
                            form.setValue(
                                'description',
                                event.currentTarget.value,
                            );
                            addDescriptionDetailsToPayload(
                                event.currentTarget.value,
                            );
                        }}
                        disabled={isLoading}
                        readOnly={!isEditMode}
                        className="!w-[37rem]"
                    />
                    <TagsMultiSelect
                        tags={customAttributePayload.tags || []}
                        onChange={handleTagsChange}
                        isDisabled={isLoading || !isEditMode}
                        className="!w-[37rem]"
                    />
                    <Divider color="rgb(var(--color-gray-250))" />
                    <Text className="text-gray-800 font-medium text-lg">
                        {t('custom_metric.create_new_trait_modal.trait_logic')}
                    </Text>
                    {fieldType === FieldType.DIMENSION ? (
                        <CustomDimensionLogic isLoading={isLoading} />
                    ) : (
                        <CustomMetricLogic isLoading={isLoading} form={form} />
                    )}
                </Flex>
                {isEditMode && (
                    <Flex
                        className=" border-t border-gray-250  sticky bottom-0 bg-white z-10 px-9 py-3"
                        align="center"
                        justify="flex-end"
                    >
                        <Button
                            variant={ButtonVariant.OUTLINED_ACCENTED}
                            onClick={handlePreview}
                            className="hidden"
                        >
                            {t('custom_metric.dimension_modal_preview_button')}
                        </Button>
                        <Button
                            form="create_custom_metric_form"
                            variant={ButtonVariant.PRIMARY}
                            size="md"
                            onClick={(
                                e: React.MouseEvent<HTMLButtonElement>,
                            ) => {
                                e.stopPropagation();
                            }}
                            type="submit"
                            disabled={disableFinishButton}
                            rightIcon={
                                isLoading ? (
                                    <Loader color="white" size={14} />
                                ) : (
                                    <CaretRight
                                        color={
                                            selectedDimension
                                                ? 'white'
                                                : 'rgb(var(--color-gray-500))'
                                        }
                                    />
                                )
                            }
                        >
                            {isLoading
                                ? t('common.saving')
                                : t('common.finish')}
                        </Button>
                    </Flex>
                )}
            </form>
            {previewOpened && (
                <AudiencePreviewModal
                    getAudiencePayload={{}}
                    opened={previewOpened}
                    close={previewClose}
                    data={previewData?.rows ?? []}
                    fields={
                        (previewData?.fields as Record<
                            string,
                            { type: DimensionType }
                        >) ?? {}
                    }
                    initialColumns={{
                        previewFields: [],
                    }}
                    generationStrategy={QueryGenerationStrategy.MANUAL}
                    isEditMode={false}
                    onColumnChange={() => {}}
                    handlePreview={undefined}
                    isValidQuery={true}
                    showPropertySelect={true}
                    footerRightSection={
                        (previewData?.rows.length ?? 0) > 0 && (
                            <Button
                                variant={ButtonVariant.OUTLINED}
                                onClick={() => previewClose()}
                            >
                                {t('password_modal.secondary_btn_text')}
                            </Button>
                        )
                    }
                    bottomSection={null}
                    isApiCallTimeout={false}
                    onPropertySelect={undefined}
                />
            )}
        </>
    );
};

export default CustomAttributeContainer;
