import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import { getCustomMetricName } from '@components/Explorer/CustomMetricModal/utils';
import {
    ConditionalOperator,
    CustomDimensionType,
    DimensionType,
    FieldType,
    isCustomSqlDimension,
    snakeCaseName,
    type AdditionalMetric,
    type CompiledRelation,
    type CompiledRelationTable,
    type CustomAttribute,
    type CustomDimension,
    type CustomSqlDimension,
    type InsertCustomAttribute,
} from '@lightdash/common';
import { v4 as uuid4 } from 'uuid';

export interface CreateCustomMetricProps {
    openBaseTable: boolean | undefined;
    tableName: CompiledRelationTable | undefined;
    isDuplicate: boolean | undefined;
    isViewMode: boolean | undefined;
    customMetricData: CustomAttribute | undefined;
    onModalClose: () => void;
    customMetricId: string | undefined;
    disableTableChange: boolean | undefined;
    fieldType: FieldType | undefined;
}

export interface CustomAttributeInitialDataProps {
    customAttributeData: CustomAttribute | undefined;
    relationUuid: string;
    activeRelation: CompiledRelation | undefined;
    fieldsWithSuggestions: FieldsWithSuggestions;
    isEditMode: boolean;
    isDuplicateMode: boolean;
}

export const getInitialCustomAttributeDefinition = ({
    fieldType,
    tableName,
    name = '',
    label = '',
}: {
    fieldType: FieldType;
    tableName: string | undefined;
    name?: string;
    label?: string;
}) => {
    if (fieldType === FieldType.METRIC) {
        return {
            sql: '',
            name: name ?? '',
            table: tableName ?? '',
            filters: [],
            tableLabel: '',
            description: '',
            tablesReferences: tableName ? [tableName] : [],
            type: undefined,
            baseDimensionName: '',
            fieldType: 'dimension',
            label: label ?? '',
        };
    }
    return {
        sql: '',
        name: '',
        type: CustomDimensionType.SQL,
        id: '',
        dimensionType: DimensionType.STRING,
        table: tableName ?? '',
        label: '',
    } as CustomSqlDimension;
};
/**
 * Get the name of the custom attribute
 * @param customAttributePayload
 * @returns name of the custom attribute
 */
export const getCustomAttributeName = (
    customAttributePayload: InsertCustomAttribute,
) => {
    if (!customAttributePayload.definition) {
        return customAttributePayload.name;
    }
    if (isCustomSqlDimension(customAttributePayload?.definition)) {
        return customAttributePayload.definition.name;
    }
    return (customAttributePayload.definition as AdditionalMetric)?.label ?? '';
};
/**
 * this function sanitizes the custom metric name
 * @param name
 * @returns sanitized name
 */
export const sanitizeCustomMetricName = (name: string) => {
    // remove all non-alphanumeric characters and replace spaces with underscores
    return name.replace(/[^a-zA-Z0-9_]/g, '_').replace(/\s+/g, '_');
};
interface GetDefinitionAndNameProps {
    customAttributeData: CustomAttribute | undefined;
    isDuplicateMode: boolean;
    fieldType: FieldType;
    activeRelation: CompiledRelation | undefined;
    fieldsWithSuggestions: FieldsWithSuggestions;
}
/**
 * Get the field id of the base dimension of the additional metric
 * @param additionalMetric
 * @returns field id of the base dimension of the additional metric
 */
export const getBaseDimensionFieldIdFromAdditionalMetric = (
    additionalMetric: AdditionalMetric,
) => {
    return `${additionalMetric.table}_${additionalMetric.baseDimensionName}`;
};
/**
 * Get the definition and name of the custom attribute
 * @param customAttributeData
 * @param isDuplicateMode
 * @param fieldType
 * @param activeRelation
 * @returns definition and name of the custom attribute
 */
export const getDefinitionAndName = ({
    customAttributeData,
    isDuplicateMode,
    fieldType,
    activeRelation,
    fieldsWithSuggestions,
}: GetDefinitionAndNameProps) => {
    if (!customAttributeData?.definition) {
        return {
            definition: getInitialCustomAttributeDefinition({
                fieldType,
                tableName: activeRelation?.baseTable,
            }),
            name: '',
        };
    }
    if (isDuplicateMode) {
        const label = `Copy of ${getCustomAttributeName(customAttributeData)}`;
        if (isCustomSqlDimension(customAttributeData.definition)) {
            const timestampedName = `${snakeCaseName(
                sanitizeCustomMetricName(label),
            )}${Date.now()}`;
            return {
                definition: {
                    ...customAttributeData.definition,
                    name: label,
                    id: timestampedName,
                },
                name: timestampedName,
            };
        }
        const baseDimensionFieldId =
            getBaseDimensionFieldIdFromAdditionalMetric(
                customAttributeData.definition as AdditionalMetric,
            );
        const dimension = fieldsWithSuggestions[baseDimensionFieldId];
        if (dimension) {
            const customMetricName = getCustomMetricName(
                dimension.table ?? '',
                sanitizeCustomMetricName(label ?? ''),
                dimension.name ?? '',
            );
            return {
                definition: {
                    ...customAttributeData.definition,
                    label,
                    name: customMetricName,
                },
                name: customMetricName,
            };
        }
    }
    return {
        definition: customAttributeData.definition,
        name: customAttributeData.name,
    };
};

/**
 * Generate metric preview payload
 * @param customAttributePayload
 * @returns preview payload for metric
 */
export const generateMetricPreviewPayload = (
    customAttributePayload: InsertCustomAttribute,
) => ({
    metricQuery: {
        and: [
            {
                filters: {
                    metrics: {
                        id: uuid4(),
                        and: [
                            {
                                id: uuid4(),
                                target: {
                                    fieldId: `${customAttributePayload.definition?.table}_${customAttributePayload.definition?.name}`,
                                },
                                operator: ConditionalOperator.NOT_NULL,
                                values: [],
                            },
                        ],
                    },
                },
                additionalMetrics: [],
                dimensions: [],
                exploreName: '',
                limit: 500,
                metrics: [
                    `${customAttributePayload.definition?.table}_${customAttributePayload.definition?.name}`,
                ],
                sorts: [],
                tableCalculations: [],
            },
        ],
        id: uuid4(),
    },
    dimensions: [],
    metrics: [
        `${customAttributePayload.definition?.table}_${customAttributePayload.definition?.name}`,
    ],
});

/**
 * Generate dimension preview payload
 * @param customAttributePayload
 * @returns preview payload for dimension
 */
export const generateDimensionPreviewPayload = (
    customAttributePayload: InsertCustomAttribute,
) => ({
    metricQuery: {
        and: [
            {
                filters: {
                    metrics: {
                        id: uuid4(),
                        and: [
                            {
                                id: uuid4(),
                                target: {
                                    fieldId: (
                                        customAttributePayload.definition as CustomDimension
                                    )?.id,
                                },
                                operator: ConditionalOperator.NOT_NULL,
                                values: [],
                            },
                        ],
                    },
                },
                additionalMetrics: [],
                dimensions: [
                    (customAttributePayload.definition as CustomDimension)?.id,
                ],
                exploreName: '',
                limit: 500,
                metrics: [],
                sorts: [],
                tableCalculations: [],
                customDimensions: [],
            },
        ],
        id: uuid4(),
    },
    dimensions: [],
    metrics: [],
});

/**
 * this function filters the tables based on the field type
 * @param availableTables
 * @param nonAvailableTables
 * @param fieldType
 * @returns filtered tables
 */
export const getFilteredTables = (
    availableTables: CompiledRelationTable[],
    nonAvailableTables: CompiledRelationTable[],
    fieldType?: FieldType,
) => {
    const nonOrphanNonInternalAvailableTables = availableTables.filter(
        (table) => table.isConfigured && !table.isReserved,
    );
    const nonOrphanNonInternalNonAvailableTables = nonAvailableTables.filter(
        (table) => table.isConfigured && !table.isReserved,
    );

    if (fieldType === FieldType.DIMENSION) {
        return {
            filteredAvailableTables: [
                ...nonOrphanNonInternalAvailableTables,
                ...nonOrphanNonInternalNonAvailableTables,
            ],
            filteredNonAvailableTables: [],
        };
    }

    return {
        filteredAvailableTables: nonOrphanNonInternalAvailableTables,
        filteredNonAvailableTables: nonAvailableTables,
    };
};

/**
 * this function sanitizes the custom metric description
 * @param description
 * @returns sanitized description
 */
export const getInsertCustomAttribute = (
    customAttribute: CustomAttribute,
): InsertCustomAttribute => {
    return {
        name: customAttribute.name,
        description: customAttribute.description,
        relationUuid: customAttribute.relationUuid,
        srcTable: customAttribute.srcTable,
        type: customAttribute.type,
        definition: customAttribute.definition,
        groupByColumn: customAttribute.groupByColumn,
        tags: customAttribute.tags,
    };
};
