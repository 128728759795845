import { Box, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
/**
 * Props for the TextWithTooltip component.
 * @property {string} text - The text to display with a tooltip.
 */
interface TextWithTooltipProps extends React.HTMLAttributes<HTMLDivElement> {
    text: string;
}

/**
 * TextWithTooltip component displays text with a tooltip if the text overflows.
 * @param {TextWithTooltipProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
export const TextWithTooltip: React.FC<TextWithTooltipProps> = ({
    text,
    ...props
}) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const id = `tooltip-${Math.random().toString(36).substring(2, 11)}`;

    useEffect(() => {
        const checkTruncation = () => {
            const element = document.getElementById(id);
            if (element) {
                setIsTruncated(element.scrollWidth > element.clientWidth);
            }
        };

        // Check on mount and whenever text changes
        checkTruncation();

        // Check on window resize
        window.addEventListener('resize', checkTruncation);

        // Use MutationObserver to detect DOM changes that might affect truncation
        const observer = new MutationObserver(checkTruncation);
        const element = document.getElementById(id);
        if (element) {
            observer.observe(element.parentElement || document.body, {
                childList: true,
                subtree: true,
                attributes: true,
                attributeFilter: ['style', 'class'],
            });
        }

        return () => {
            window.removeEventListener('resize', checkTruncation);
            observer.disconnect();
        };
    }, [id, text]);

    return (
        <Tooltip
            label={text}
            disabled={!isTruncated}
            color="dark"
            position="bottom"
            styles={{
                tooltip: {
                    backgroundColor: 'black',
                    color: 'white',
                    zIndex: 1000,
                },
            }}
            withinPortal
        >
            <Box
                id={id}
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
                {...props}
            >
                {text}
            </Box>
        </Tooltip>
    );
};
