import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import TitleText from '@components/common/Text/TitleText';
import Timestamp from '@components/common/Timestamp';
import TemplateTitleModal from '@components/Templates/TemplateTitleModal';
import { useLocale } from '@hooks/useLocale';
import { useUpdateTemplateDetails } from '@hooks/useTemplate';
import { CommunicationChannel, type Template } from '@lightdash/common';
import { Box, Button, Flex, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretLeft } from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../../mantineTheme';
import SendTest from '../SendTest';
import ViewAsUser from '../ViewAsUser';

type HeaderProps = {
    name: string;
    description: string | undefined;
    tags: string[];
    channel: CommunicationChannel;
    templateMetadata: Template | undefined;
    createdAt: string;
};
const Header = ({
    name,
    description,
    tags,
    channel,
    templateMetadata,
    createdAt,
}: HeaderProps) => {
    const queryClient = useQueryClient();
    const { t } = useLocale();
    const navigate = useNavigate();
    const { templateId = '', projectUuid = '' } = useParams<{
        templateId: string;
        projectUuid: string;
    }>();
    const [titleModalOpened, { open: titleModalOpen, close: titleModalClose }] =
        useDisclosure(false);
    const [
        viewAsUserModalOpened,
        { open: viewAsUserModalOpen, close: viewAsUserModalClose },
    ] = useDisclosure(false);
    const { mutateAsync: updateTemplateDetails } = useUpdateTemplateDetails();

    const isSendTestDisabled = useMemo(() => {
        if (
            !templateMetadata?.contents[0].subject &&
            channel === CommunicationChannel.EMAIL
        )
            return true;

        return false;
    }, [channel, templateMetadata?.contents]);

    const handleTitleChange = async (
        newName: string,
        newDescription: string,
        newTags: string[],
    ) => {
        try {
            await updateTemplateDetails({
                templateId,
                body: {
                    name: newName,
                    description: newDescription,
                    tags: newTags,
                },
            });
            await queryClient.refetchQueries([
                QueryKeys.GET_TEMPLATE,
                templateId,
            ]);
        } catch (error) {}
    };

    return (
        <Box className="p-3 bg-white border-b-[1px]">
            <Flex justify={'space-between'}>
                <Stack>
                    <Button
                        variant={ButtonVariant.SUBTLE}
                        className="w-fit"
                        leftIcon={
                            <CaretLeft color="rgb(var(--color-gray-600))" />
                        }
                        onClick={() =>
                            navigate(
                                `/projects/${projectUuid}/templates#${channel}`,
                            )
                        }
                    >
                        {t('template_details.all_templates')}
                    </Button>
                    <TitleText onClick={titleModalOpen}>{name}</TitleText>
                    <Flex gap={8}>
                        <ChannelIcon
                            channel={channel ?? CommunicationChannel.EMAIL}
                            labelClassName="text-gray-700"
                            color="rgb(var(--color-gray-700))"
                        />
                        <Text className="relative bottom-1">.</Text>
                        <Text className="text-gray-700">
                            {t('template_details.template_id')}: {templateId}
                        </Text>
                        <Text className="relative bottom-1">.</Text>
                        <Timestamp
                            customClass="text-gray-700"
                            timestamp={createdAt}
                        />
                    </Flex>
                </Stack>
                <Flex className="h-fit self-center" gap={8}>
                    {channel === CommunicationChannel.EMAIL && (
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={viewAsUserModalOpen}
                        >
                            {t('template_preview.preview_user_select.label')}
                        </Button>
                    )}
                    <SendTest
                        channel={channel}
                        templateId={templateId}
                        mappings={
                            templateMetadata?.contents[0]?.contentMappings
                        }
                        contentDetails={templateMetadata?.contents[0]?.content}
                        disabled={isSendTestDisabled}
                        subject={templateMetadata?.contents[0]?.subject}
                        previewText={templateMetadata?.contents[0]?.preheader}
                    />
                </Flex>
            </Flex>
            <TemplateTitleModal
                isEditMode={true}
                tags={tags}
                name={name}
                description={description ?? ''}
                handleTitleChange={handleTitleChange}
                opened={titleModalOpened}
                open={titleModalOpen}
                close={titleModalClose}
            />
            <ViewAsUser
                open={viewAsUserModalOpened}
                onClose={viewAsUserModalClose}
                templateMetadata={templateMetadata}
                isSendTestDisabled={isSendTestDisabled}
            />
        </Box>
    );
};

export default Header;
