import { buttonInputStyles } from '@components/common/Inputs/ButtonInput/ButtonInput.styles';
import InfinityScrollTable from '@components/Table/InfinityScrollTable';
import { fuzzyFilter } from '@components/Table/utils';
import VirtualTable from '@components/Table/VirtualTable';
import { useLocale } from '@hooks/useLocale';
import { ShowDataType } from '@lightdash/common';
import { Box, Button, Input } from '@mantine/core';
import {
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
    type ColumnFiltersState,
    type OnChangeFn,
    type SortingState,
    type Table,
    type TableOptions,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import {
    type EditableManagerProps,
    type ManagerProps,
    type ManagerPropsManualPagination,
} from '../type';

interface TableMeta {
    updateData: (rowIndex: number, columnId: string, value: string) => void;
}

const EditableCell = <TData,>({
    getValue,
    row: { index },
    column: { id },
    table,
}: {
    getValue: () => string;
    row: {
        index: number;
    };
    column: {
        id: string;
    };
    table: Table<TData> & {
        options: {
            meta?: TableMeta;
        };
    };
}) => {
    const initialValue = getValue();
    const [value, setValue] = useState<string>(initialValue);

    const onBlur = () => {
        if (table.options.meta && 'updateData' in table.options.meta) {
            table.options.meta.updateData(index, id, value);
        }
    };

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <Input
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue(e.target.value)
            }
            onBlur={onBlur}
            styles={buttonInputStyles({
                placeholderColor: 'rgb(var(--color-gray-400))',
                textColor: 'rgb(var(--color-gray-800))',
            })}
            className="!w-fit"
        />
    );
};

type ManagerTableProps<TData, T> = ManagerProps<TData, T> & {
    viewType: ShowDataType;
};

const ManagerTable = <TData, T>({
    handleRowClick,
    viewType,
    name: tableName,
    customClass,
    gridSize,
    cellHeight,
    defaultSorting,
    onSearchChange,
    searchValue,
    disablePagination,
    pageSize = 10,
    sorting,
    setSorting,
    onSuccessInControlledFetching,
    ...props
}: ManagerTableProps<TData, T>) => {
    const {
        pagination,
        onPageChange,
        isDataFetching,
        isNeedToRefresh,
        isControlledFetching,
        options,
        isManualPagination,
        onNeedToRefresh,
        tableData,
    } = props as ManagerPropsManualPagination<TData, T>;
    const { t } = useLocale();
    const { isEditable, updateData } = props as EditableManagerProps<TData, T>;
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);

    const [data, setData] = useState<TData[]>(tableData);
    const [isAtEndOfRows, setIsAtEndOfRows] = useState(false);

    const tableContainerRef = useRef<HTMLDivElement>(null);
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (isControlledFetching) return;
            if (
                containerRefElement &&
                isManualPagination &&
                pagination &&
                pagination.total &&
                !isDataFetching
            ) {
                const { scrollHeight, scrollTop, clientHeight } =
                    containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight <
                        (viewType === ShowDataType.LIST ? 500 : 2000) &&
                    pagination.currentPage <
                        (pagination.lastPage ??
                            pagination.total / pagination.perPage + 1)
                ) {
                    onPageChange(pagination.currentPage + 1);
                }
            }
        },
        [
            isManualPagination,
            onPageChange,
            pagination,
            isControlledFetching,
            viewType,
            isDataFetching,
        ],
    );

    const getFormatData = useCallback(() => {
        const option = options.find((item) => item.format === viewType);
        if (!!option) {
            return option.formatData;
        }
    }, [viewType, options]);

    // It checks if manual pagination is enabled, and if so, calculates whether to fetch the next page
    // based on the current scroll position relative to the table's dimensions.
    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached, props]);
    const tableOptions = useMemo(
        () => ({
            data: data,
            columns: getFormatData(),
            state: {
                columnFilters: columnFilters,
                globalFilter: isManualPagination ? undefined : searchValue,
                sorting: sorting,
            },
            onColumnFiltersChange: setColumnFilters,
            onGlobalFilterChange: isManualPagination
                ? undefined
                : onSearchChange,
            filterFns: {
                fuzzy: fuzzyFilter,
            },
            globalFilterFn: fuzzyFilter,
            enableGlobalFilter: !isManualPagination,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
            getFacetedMinMaxValues: getFacetedMinMaxValues(),
            debugTable: true,
            debugHeaders: true,
            debugColumns: false,
            ...(!disablePagination && {
                getPaginationRowModel: getPaginationRowModel(),
                manualPagination: isManualPagination,
                initialState: {
                    pagination: {
                        pageSize,
                    },
                },
            }),
            ...(isEditable && {
                defaultColumn: { cell: EditableCell },
                meta: {
                    updateData,
                },
            }),
            manualSorting: isManualPagination,
            manualFiltering: isManualPagination,
        }),
        [
            data,
            getFormatData,
            columnFilters,
            searchValue,
            sorting,
            onSearchChange,
            disablePagination,
            isManualPagination,
            pageSize,
            isEditable,
            updateData,
        ],
    );
    const table = useReactTable(tableOptions as unknown as TableOptions<TData>);

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => 33, //this is hardcoded beacause if heights are measured dynamicaliy using virtualItem.measureElement then this function is used currently we are not using dynamic height and it is mandatory field
        getScrollElement: () => tableContainerRef.current,
        measureElement:
            typeof window !== 'undefined' &&
            navigator.userAgent.indexOf('Firefox') === -1
                ? (element) => element?.getBoundingClientRect().height
                : undefined,
        overscan: 5,
    });

    useEffect(() => {
        if (isDataFetching) return;
        if (isNeedToRefresh) {
            rowVirtualizer.scrollToIndex?.(0);
            setData([...tableData]);
            table.setOptions((prev) => ({
                ...prev,
                data: tableData,
                state: {
                    ...prev.state,
                    pagination: {
                        pageIndex: 0,
                        pageSize: prev.state.pagination?.pageSize ?? 10,
                    },
                },
            }));

            onNeedToRefresh?.(false);
        } else {
            setData((prevData: TData[]) => {
                return [...(prevData ?? []), ...tableData];
            });
            table.setOptions((prev) => ({
                ...prev,
                data: [...(prev.data ?? []), ...tableData],
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData, isDataFetching]);

    useEffect(() => {
        if (onSuccessInControlledFetching && data.length > 0) {
            rowVirtualizer.scrollToIndex?.(table.getRowModel().rows.length);
        }
    }, [onSuccessInControlledFetching, rowVirtualizer, table, data, tableData]);

    //scroll to top of table when sorting changes
    const handleSortingChange: OnChangeFn<SortingState> = (updater) => {
        if (setSorting) {
            setSorting(updater);
            onPageChange(1);
        }
        if (!!table.getRowModel().rows.length) {
            rowVirtualizer.scrollToIndex?.(0);
        }
    };

    table.setOptions((prev) => ({
        ...prev,
        onSortingChange: handleSortingChange,
    }));

    const handleScroll = useCallback(() => {
        if (tableContainerRef.current) {
            const { scrollHeight, scrollTop, clientHeight } =
                tableContainerRef.current;
            const atBottom = scrollHeight - scrollTop === clientHeight;
            setIsAtEndOfRows(atBottom);
        }
    }, [setIsAtEndOfRows]);

    useEffect(() => {
        const container = tableContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll, setIsAtEndOfRows, tableContainerRef]);
    const loadMore = useMemo(() => {
        return (
            tableData.length > 0 &&
            !isDataFetching &&
            !isNeedToRefresh &&
            isControlledFetching &&
            isAtEndOfRows &&
            pagination.currentPage && (
                <Box className="flex items-center justify-center my-2">
                    <Button
                        variant={ButtonVariant.OUTLINED_ACCENTED}
                        size="xs"
                        onClick={() => {
                            onPageChange(pagination.currentPage + 1);
                        }}
                    >
                        {t('common.load_more')}
                    </Button>
                </Box>
            )
        );
    }, [
        isAtEndOfRows,
        isControlledFetching,
        isDataFetching,
        isNeedToRefresh,
        onPageChange,
        pagination,
        t,
        tableData,
    ]);

    if (tableContainerRef && isManualPagination) {
        return (
            <InfinityScrollTable
                viewType={viewType}
                table={table}
                handleRowClick={handleRowClick}
                tableContainerRef={
                    tableContainerRef as React.RefObject<HTMLDivElement>
                }
                cellHeight={cellHeight}
                rowVirtualizer={rowVirtualizer}
                gridSize={gridSize ?? 0}
                isDataFetching={isDataFetching ?? false}
                isNeedToRefresh={isNeedToRefresh ?? false}
                isControlledFetching={isControlledFetching ?? false}
                tableName={tableName ?? ''}
                loadMore={loadMore}
            />
        );
    }

    return (
        <VirtualTable
            data={tableData}
            columns={getFormatData() ?? []}
            onRowClick={handleRowClick}
            customClass={`${customClass} max-h-[40rem] overflow-auto`}
            columnSize={gridSize}
            isLoading={isDataFetching}
            isNeedToRefresh={isNeedToRefresh ?? false}
            isDataFetching={isDataFetching ?? false}
            defaultSorting={defaultSorting}
            tableName={tableName ?? ''}
        />
    );
};

export default ManagerTable;
