import { type JourneyNodeEnum } from '@components/Journeys/Builder/types';
import { Box, Stack } from '@mantine/core';
import React, { useMemo } from 'react';
import { Handle, Position } from 'reactflow';
import AddNodeButton from '../AddNodeButton';
// import AddNodeButton from '../AddNodeButton';

type NodeContainerProps = {
    nodeType: JourneyNodeEnum;
    isFocused: boolean; //This flag is used to determine if the node is focused via keyboard
    isSelected: boolean; //This flag is used to determine if the node is selected via mouse click
    nodeBanner: React.ReactNode | undefined;
    allowAddNode: boolean;
    onAddNodeClick?: () => void;
    isExperimentNode?: boolean;
};

const NodeContainer: React.FC<React.PropsWithChildren<NodeContainerProps>> = ({
    children,
    isFocused,
    isSelected,
    onAddNodeClick,
    nodeBanner,
    allowAddNode,
    isExperimentNode,
}) => {
    const getBorder = useMemo(() => {
        if (isFocused) {
            return `border-blu-800 outline-blu-800/20 outline-dashed outline-offset-[-4px] ${
                isExperimentNode ? '!border-pink-800 !outline-pink-800/20' : ''
            }`;
        }
        if (isSelected) {
            return `border-blu-800 !shadow-[0px_0px_0px_2px_rgba(1,149,225,0.40)] ${
                isExperimentNode
                    ? '!border-pink-800 !shadow-[0px_0px_0px_2px_rgba(205,101,182,0.40)] '
                    : ''
            }`;
        }
        return 'border-gray-200 hover:border-gray-400';
    }, [isExperimentNode, isFocused, isSelected]);

    return (
        <Stack className="relative items-center gap-0">
            {nodeBanner && (
                <Box className=" absolute top-[-0.25rem] left-0 transform -translate-y-full flex border border-mustard-800/40 rounded-xl">
                    {nodeBanner}
                </Box>
            )}
            <Stack
                className={`w-[16.25rem] min-h-[4.25rem] border bg-white rounded-xl cursor-pointer flex items-center justify-center shadow-[0px_0px_3px_0px_rgba(0,0,0,0.04)] gap-0 ${getBorder}`}
            >
                <Box className="w-full">{children}</Box>
                <Handle
                    type="source"
                    position={Position.Bottom}
                    isConnectable={false}
                    className="w-0 h-0 !bg-transparent !border-none"
                />
                <Handle
                    type="target"
                    position={Position.Top}
                    isConnectable={false}
                    className="w-0 h-0 !bg-transparent !border-none"
                />
            </Stack>

            {allowAddNode && onAddNodeClick && (
                <Stack className="items-center gap-0.5">
                    <Box className="w-px h-4 mt-1 bg-gray-400"></Box>
                    <AddNodeButton onAddNodeClick={onAddNodeClick} />
                </Stack>
            )}
        </Stack>
    );
};

export default React.memo(NodeContainer);
