import { useLocale } from '@hooks/useLocale';
import { type Audience } from '@lightdash/common';
import { Box, Group, Loader, Text } from '@mantine/core';
import {
    ArrowsClockwise,
    ClockCountdown,
    MagnifyingGlass,
    UsersThree,
} from '@phosphor-icons/react';
import { useMemo } from 'react';
import { type ListSelectProps } from 'types/ListSelect';
import ListSelect from '../Select/ListSelect';
import Timestamp from '../Timestamp';

type SelectAudienceProps = {
    audiences: Audience[];
    handleFieldSelect: ListSelectProps['onOptionSelect'];
    isFetching: boolean;
    onRefresh: () => void;
};

interface ItemComponentProp {
    name: string;
    lastRun: string | undefined;
    icon: React.ReactNode | undefined;
}

export const ItemComponent = ({ name, lastRun, icon }: ItemComponentProp) => {
    return (
        <Group className="items-center gap-1.5">
            {icon ?? <UsersThree color={'rgb(var(--color-pink-800)'} />}
            <Text className="text-sm font-medium text-gray-800">{name}</Text>
            {lastRun && (
                <Group className="flex items-center gap-1">
                    <ClockCountdown />
                    <Text className="text-sm text-gray-500">
                        <Timestamp timestamp={lastRun} />
                    </Text>
                </Group>
            )}
        </Group>
    );
};

const createdListItemObject = (obj: Audience) => {
    const lastRun = obj?.lastRunAt && obj?.lastRunAt.toString();
    return {
        uuid: obj?.id,
        key: obj?.name,
        value: obj?.name,
        renderComponent: (
            <Box className="px-1">
                <ItemComponent
                    icon={<UsersThree color={'rgb(var(--color-pink-800)'} />}
                    name={obj?.name}
                    lastRun={lastRun}
                />
            </Box>
        ),
    };
};

const SelectAudience = ({
    audiences,
    handleFieldSelect,
    isFetching,
    onRefresh,
}: SelectAudienceProps) => {
    const { t } = useLocale();

    const options = useMemo(() => {
        return audiences?.map((obj: Audience) => {
            return createdListItemObject(obj);
        });
    }, [audiences]);

    return (
        <Box className="h-96">
            <ListSelect
                leftSection={<MagnifyingGlass />}
                placeholder={t('campaigns_builder_audience.search_placeholder')}
                options={options || []}
                onOptionSelect={handleFieldSelect}
                rightSection={
                    isFetching ? (
                        <>
                            <Loader
                                size={13}
                                color={'rgb(var(--color-gray-500))'}
                            />
                        </>
                    ) : (
                        <>
                            <ArrowsClockwise
                                weight="regular"
                                onClick={onRefresh}
                                className={`${
                                    isFetching
                                        ? 'pointer-events-none'
                                        : 'cursor-pointer'
                                } `}
                            />
                        </>
                    )
                }
                withDivider
            />
        </Box>
    );
};

export default SelectAudience;
