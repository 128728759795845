import HiddenButton, { type HideProps } from '@components/Hidden/HiddenButton';
import Manager from '@components/Manager';
import { useLocale } from '@hooks/useLocale';
import {
    JourneyStatus,
    ShowDataType,
    type Journey,
    type JourneyAndExecutionCount,
    type Pagination,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { type Row, type SortingState } from '@tanstack/react-table';
import React, {
    useCallback,
    useState,
    type Dispatch,
    type SetStateAction,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import DeactivateJourneyModal from '../Settings/DeactivateJourneyModal';
import { useJourneyColumns } from './useJourneyTableUtils';

type JourneyTableProps = HideProps & {
    journeys: JourneyAndExecutionCount[] | null | undefined;
    pagination: Pagination;
    onPageChange: (page: number) => void;
    onSearchChange: (newSearchString: string) => void;
    isDataFetching: boolean;
    sorting: SortingState;
    setSorting: Dispatch<SetStateAction<SortingState>>;
};

const JourneyTable: React.FC<JourneyTableProps> = ({
    journeys,
    pagination,
    onPageChange,
    onSearchChange,
    isDataFetching,
    sorting,
    setSorting,
    isHiddenActive,
    onHiddenChange,
    hideHiddenButton,
}) => {
    const [
        showDeactivateModal,
        { open: openDeactivateModal, close: closeDeactivateModal },
    ] = useDisclosure(false);
    const [journeyData, setJourneyData] = useState<Journey>();
    const [isNeedToRefresh, setIsNeedToRefresh] = useState<boolean>(true);
    const { t } = useLocale();
    const navigate = useNavigate();

    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();

    const handleRowClick = useCallback(
        (row: JourneyAndExecutionCount) => {
            if (
                row.status === JourneyStatus.ACTIVE ||
                row.status === JourneyStatus.CANCELED
            ) {
                void navigate(
                    `/projects/${projectUuid}/journeys/${row.id}/view`,
                );
                return;
            }
            void navigate(`/projects/${projectUuid}/journeys/${row.id}/edit`);
        },
        [navigate, projectUuid],
    );

    const handeMenuClick = useCallback(() => {
        setIsNeedToRefresh(true);
        onPageChange(1);
    }, [onPageChange, setIsNeedToRefresh]);

    const journeyColumns = useJourneyColumns(
        openDeactivateModal,
        setJourneyData,
        handeMenuClick,
    );

    return (
        <Box>
            {journeys && journeyColumns && (
                <Manager<JourneyAndExecutionCount>
                    tableData={journeys}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: journeyColumns,
                        },
                    ]}
                    searchPlaceholder={t('journeys_table.search_journey')}
                    paginationText={t('journeys_table.journeys')}
                    isManualPagination={true}
                    isDataFetching={isDataFetching}
                    onPageChange={onPageChange}
                    onSearchChange={(value) => {
                        onSearchChange(value);
                        setIsNeedToRefresh(true);
                    }}
                    pagination={pagination}
                    isEditable={false}
                    handleRowClick={(data: Row<JourneyAndExecutionCount>) => {
                        const journey = data.original;
                        handleRowClick(journey);
                    }}
                    leftSection={
                        !hideHiddenButton && (
                            <HiddenButton
                                isHiddenActive={isHiddenActive}
                                onHiddenChange={(value) => {
                                    onHiddenChange(value);
                                    setIsNeedToRefresh(true);
                                }}
                                activeHideText={t(
                                    'journeys_manager.active_hide_button_text',
                                )}
                            />
                        )
                    }
                    isNeedToRefresh={isNeedToRefresh}
                    onNeedToRefresh={setIsNeedToRefresh}
                    sorting={sorting}
                    setSorting={(updater) => {
                        setSorting(updater);
                        onPageChange(1);
                        setIsNeedToRefresh(true);
                    }}
                />
            )}
            {journeyData && (
                <DeactivateJourneyModal
                    uuid={journeyData.id}
                    endDate={journeyData.endDate}
                    journeyStatus={journeyData.status}
                    showDeactivateModal={showDeactivateModal}
                    onClose={() => closeDeactivateModal()}
                    handeMenuClick={handeMenuClick}
                />
            )}
        </Box>
    );
};

export default React.memo(JourneyTable);
