import { type AnyType } from '@lightdash/common';
import _ from 'lodash';
import { flow, isArray, isEmpty, isObject, map, mapValues } from 'lodash/fp';
/**
 * Capitalizes the first character of the given string if it's not empty.
 * @param {string} str                    The string to capitalize the first character of.
 * @returns {string}                      The string with the first character capitalized if the original string is not empty;
 *                                        otherwise, returns the original string unchanged.
 */
export const capitalizeFirstCharacter = (str: string): string =>
    str && str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : str;

type AnyObject = Record<string, AnyType>;

export const removeEmptyParams = (obj: AnyObject): AnyObject => {
    return _.transform(obj, (result, value, key) => {
        if (
            key !== 'type' &&
            !_.isNull(value) &&
            !_.isUndefined(value) &&
            !_.isEmpty(value)
        ) {
            result[key] = _.isObject(value) ? removeEmptyParams(value) : value;
        }
    });
};

export const convertUndefinedToEmptyString = (obj: any): any => {
    if (obj === undefined) return '';
    if (!isObject(obj)) return obj;
    if (isArray(obj)) return map(convertUndefinedToEmptyString)(obj);
    return flow(mapValues(convertUndefinedToEmptyString), (result) =>
        isEmpty(result) ? '' : result,
    )(obj);
};

/**
 * Accepts a time string in HH:MM format and return the UTC equivalent of the same based on the timezone offset provided
 * @param { string } time Time string to be converted to UTC
 * @param { string } offset Timezone offset
 * @returns { string } A string representing the calculated time in UTC in 'HH:mm' format.
 */
export const getTimeInUtc = (time: string | undefined, offset: string) => {
    if (!time) return '';
    const [offsetHours, offsetMins] = offset.split(':').map(Number);
    const [timeHours, timeMins] = time.split(':').map(Number);

    let formattedMins = timeMins - offsetMins;
    let formattedHours = timeHours - offsetHours;
    if (formattedMins < 0) {
        formattedMins = Math.abs(formattedMins);
        formattedHours = formattedHours - 1;
    }
    if (formattedHours < 0) {
        formattedHours = formattedHours + 24;
    }
    return `${formattedHours.toString().padStart(2, '0')}:${formattedMins
        .toString()
        .padStart(2, '0')}`;
};

/**
 * Converts a given time string from UTC to a specified timezone offset.
 * @param { string } time UTC time string
 * @param { string } offset Timezone offset
 * @returns { string } A string representing the calculated time in the specified timezone offset in 'HH:MM' format.
 */
export const getTimeFromUtc = (time: string | undefined, offset: string) => {
    if (!time) return '';
    const [offsetHours, offsetMins] = offset.split(':').map(Number);
    const [timeHours, timeMins] = time.split(':').map(Number);

    let formattedMins = offsetMins + timeMins;
    let formattedHours = timeHours + offsetHours;
    if (formattedMins > 59) {
        formattedMins = formattedMins - 60;
        formattedHours = formattedHours + 1;
    }
    if (formattedHours > 23) {
        formattedHours = formattedHours - 24;
    }
    return `${formattedHours.toString().padStart(2, '0')}:${formattedMins
        .toString()
        .padStart(2, '0')}`;
};
