import { createIntegratedProviderObject } from '@components/Campaigns/utils';
import Select from '@components/common/Select';
import { useGetChannelIntegrations, useGetChannels } from '@hooks/useChannels';
import { CommunicationChannel, type Campaign } from '@lightdash/common';
import { Box } from '@mantine/core';
import React, { useEffect, useMemo } from 'react';

type SendingInformationProps = {
    channel: CommunicationChannel | undefined;
    communicationDetails: Campaign['communicationDetails'];
    setCommunicationDetails: (value: Campaign['communicationDetails']) => void;
};
const SendingInformation = ({
    channel,
    communicationDetails,
    setCommunicationDetails,
}: SendingInformationProps) => {
    const { data: integrations } = useGetChannelIntegrations(
        channel ?? CommunicationChannel.EMAIL,
    );
    const { data: allChannels } = useGetChannels();

    const formattedIntegrations = useMemo(() => {
        if (integrations && allChannels && channel) {
            const filteredIntegrations = integrations.filter(
                (integration) =>
                    integration.customName.toLowerCase() ||
                    integration.providerName.toLowerCase(),
            );
            return (
                createIntegratedProviderObject(
                    allChannels?.[channel as CommunicationChannel],
                    filteredIntegrations,
                    false,
                ) ?? []
            );
        }
        return [];
    }, [integrations, allChannels, channel]);

    useEffect(() => {
        if (formattedIntegrations.length) {
            setCommunicationDetails({
                id: formattedIntegrations[0].integrationId,
                providerId: formattedIntegrations[0].providerId,
                eventId: '',
            });
        }
    }, [formattedIntegrations, setCommunicationDetails]);

    return (
        <Box>
            <Select
                label="Integration"
                data={formattedIntegrations?.map((item) => ({
                    label: item.customName || item.providerName,
                    value: item.integrationId,
                }))}
                searchable
                value={communicationDetails?.id}
                onChange={(value) =>
                    setCommunicationDetails({
                        id: value || '',
                        providerId:
                            formattedIntegrations.find(
                                (item) => item.integrationId === value,
                            )?.providerId ?? '',
                        eventId: '',
                    })
                }
            />
        </Box>
    );
};

export default SendingInformation;
