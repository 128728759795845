import { useGetCampaignAnalyticsOverview } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { CommunicationChannel } from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { Record } from '@phosphor-icons/react';
import EChartsReact from 'echarts-for-react';
import React from 'react';

type PerformanceProps = {
    channel: CommunicationChannel;
    campaignId: string;
};

const primaryBarColor = '#0195ff';

const Performance = ({ channel, campaignId }: PerformanceProps) => {
    const { t } = useLocale();
    const { data } = useGetCampaignAnalyticsOverview(campaignId, true);

    if (!data || !Object.keys(data).length) return null;

    return (
        <Box className="border-base rounded-lg p-3 pb-[18px]">
            <Flex gap={6} align={'center'}>
                <Record color="rgb(var(--color-blu-800))" />
                <Text className="text-base text-gray-700 font-semibold">
                    {t('campaign.view.performance')}
                </Text>
            </Flex>
            <EChartsReact
                style={{
                    height: 300,
                }}
                option={{
                    // tooltip: tooltipAxisConfig,
                    xAxis: {
                        data: [
                            'Audience',
                            'Sent',
                            'Delivered',
                            channel === CommunicationChannel.EMAIL
                                ? 'Opened'
                                : 'Read',
                        ],
                    },
                    yAxis: {
                        name: t(
                            'campaign_analytics.base_funnel_chart.yAxis.label',
                        ), // Label for the Y-axis
                        nameLocation: 'middle', // Position the label in the middle of the axis
                        nameTextStyle: {
                            fontSize: 14,
                            fontWeight: 'bold',
                            fontFamily: 'Hanken grotesk',
                        },
                        nameGap: 30, // Gap between the label and the axis
                        type: 'value', // The Y-axis represents numerical values
                        position: 'left',
                        show: true,
                        axisLine: {
                            show: true,
                        },
                    },
                    series: [
                        {
                            // COL 1
                            // AUDIENCE
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.audience',
                            ),
                            data: [data?.TotalRequests, 0, 0, 0],
                            type: 'bar',
                            stack: 'x',
                            color: primaryBarColor,
                        },
                        {
                            // COL 2
                            // SENT
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.sent',
                            ),
                            data: [0, data?.Processed, 0, 0],
                            type: 'bar',
                            stack: 'x',
                            color: primaryBarColor,
                        },
                        {
                            // COL 3
                            // DELIVERED
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.delivered',
                            ),
                            data: [0, 0, data?.Delivered, 0],
                            type: 'bar',
                            stack: 'x',
                            color: primaryBarColor,
                        },
                        {
                            // COL 4
                            // OPEN
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.open',
                            ),
                            data: [0, 0, 0, data?.Open],
                            type: 'bar',
                            stack: 'x',
                            color: primaryBarColor,
                        },
                    ],
                }}
            />
        </Box>
    );
};

export default Performance;
