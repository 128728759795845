import { useInfiniteAudiences } from '@hooks/useGetAudience';
import {
    AudienceInternalTags,
    AudienceRunStatus,
    AudienceStatus,
    CommonReservedTags,
    type AudienceList,
} from '@lightdash/common';
import { type InfiniteData } from '@tanstack/react-query';

const useAudienceData = ({
    currentPage,
    searchValue,
}: {
    currentPage: number;
    searchValue: string;
}) => {
    const {
        data: data2,
        isLoading,
        isFetching,
        fetchNextPage,
    } = useInfiniteAudiences({
        query: `status=${AudienceStatus.ACTIVE}&lastRunStatus=${AudienceRunStatus.SUCCESS}&excludesTags=${CommonReservedTags.HIDDEN},${AudienceInternalTags.INTERNAL}&name=${searchValue}`,
        perPage: 20,
        polling: false,
    });

    return {
        audienceData: (
            data2 as InfiniteData<AudienceList> & {
                allItems: AudienceList['data'];
            }
        )?.allItems,
        pagination:
            data2?.pages[currentPage - 1]?.paginate ??
            data2?.pages[currentPage - 2]?.paginate,
        isLoading,
        isFetching,
        fetchNextPage,
    };
};

export default useAudienceData;
