import {
    ContentMappingSection,
    type Campaign,
    type CommunicationChannel,
    type ContentMappings,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import React, { useMemo } from 'react';
import PreviewUser from './PreviewUser';
import SectionHeader from './SectionHeader';
import SendingInformation from './SendingInformation';
import TestRecipientsInput from './TestRecipientsInput';
import {
    CampaignTestCommunicationSections,
    JourneysTestCommunicationSections,
    TemplateTestCommunicationSections,
    TestCommunicationSectionKeys,
    TestCommunicationSource,
} from './types';
import VariableDefaults from './VariableDefaults';

type TestCommunicationProps = {
    source: TestCommunicationSource;
    journeyVariables: ContentMappings;
    mappings: ContentMappings;
    handleChange: (
        value: string,
        variable: string,
        section: ContentMappingSection,
    ) => void;
    testProfilesOptions: {
        label: string;
        value: string;
    }[];
    selectedTestProfiles: string[];
    setSelectedTestProfiles: (value: string[]) => void;
    setTestProfilesOptions: (
        value: {
            label: string;
            value: string;
        }[],
    ) => void;
    channel: CommunicationChannel;
    isPreviewDisabled?: boolean;
    communicationDetails?: Campaign['communicationDetails'];
    setCommunicationDetails?: (
        payload: Campaign['communicationDetails'],
    ) => void;
    previewDimensions?: string[];
    previewUserCallback?: any;
};

const TestCommunication = ({
    source,
    journeyVariables,
    mappings,
    handleChange,
    selectedTestProfiles,
    setSelectedTestProfiles,
    setTestProfilesOptions,
    testProfilesOptions,
    channel,
    isPreviewDisabled,
    setCommunicationDetails,
    communicationDetails,
    previewDimensions,
    previewUserCallback,
}: TestCommunicationProps) => {
    const testSections = useMemo(() => {
        if (source === TestCommunicationSource.JOURNEYS) {
            const filteredSections: any[] = [];
            JourneysTestCommunicationSections.forEach((testSection) => {
                if (testSection.key === TestCommunicationSectionKeys.DEFAULTS) {
                    if (
                        Object.keys(
                            journeyVariables[ContentMappingSection.BODY] ?? {},
                        ).length
                    ) {
                        filteredSections.push(testSection);
                    }
                } else {
                    filteredSections.push(testSection);
                }
            });
            return filteredSections;
        }
        if (source === TestCommunicationSource.CAMPAIGNS) {
            if (isPreviewDisabled) {
                return CampaignTestCommunicationSections.slice(1);
            }
            return CampaignTestCommunicationSections;
        }
        if (source === TestCommunicationSource.TEMPLATES) {
            return TemplateTestCommunicationSections;
        }
        return [];
    }, [journeyVariables, source, isPreviewDisabled]);

    return (
        <Box>
            {testSections.map((testSection, index) => {
                if (testSection.key === TestCommunicationSectionKeys.PREVIEW) {
                    return (
                        <Box
                            key={testSection.key}
                            className="mb-6 pb-6 border-b-[1px]"
                        >
                            <SectionHeader
                                index={index + 1}
                                label={testSection.label}
                            />
                            <PreviewUser
                                disabled={isPreviewDisabled ?? false}
                                previewDimensions={previewDimensions ?? []}
                                previewUserCallback={previewUserCallback}
                            />
                        </Box>
                    );
                }
                if (testSection.key === TestCommunicationSectionKeys.DEFAULTS) {
                    return (
                        <Box
                            key={testSection.key}
                            className="mb-6 pb-6 border-b-[1px]"
                        >
                            <SectionHeader
                                index={index + 1}
                                label={testSection.label}
                            />
                            <VariableDefaults
                                variables={journeyVariables}
                                mappings={mappings}
                                handleChange={handleChange}
                            />
                        </Box>
                    );
                }
                if (
                    testSection.key ===
                    TestCommunicationSectionKeys.CHANNEL_INTEGRATION
                ) {
                    return (
                        <Box
                            key={testSection.key}
                            className="mb-6 pb-6 border-b-[1px]"
                        >
                            <SectionHeader
                                index={index + 1}
                                label={testSection.label}
                            />
                            {setCommunicationDetails && (
                                <SendingInformation
                                    channel={channel}
                                    setCommunicationDetails={
                                        setCommunicationDetails
                                    }
                                    communicationDetails={communicationDetails}
                                />
                            )}
                        </Box>
                    );
                }
                return (
                    <Box
                        key={testSection.key}
                        className="mb-6 pb-6 border-b-[1px]"
                    >
                        <SectionHeader
                            index={index + 1}
                            label={testSection.label.replace(
                                '{{channel}}',
                                channel,
                            )}
                        />
                        <TestRecipientsInput
                            data={testProfilesOptions}
                            value={selectedTestProfiles}
                            onChange={(value) => {
                                setSelectedTestProfiles(value);
                            }}
                            onCreate={(value: {
                                label: string;
                                value: string;
                            }) => {
                                setTestProfilesOptions([
                                    ...testProfilesOptions,
                                    value,
                                ]);
                                setSelectedTestProfiles([
                                    ...selectedTestProfiles,
                                    value.value,
                                ]);
                            }}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};

export default TestCommunication;
