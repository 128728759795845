import { useLocale } from '@hooks/useLocale';
import { Box, Button, Flex, Text } from '@mantine/core';
import { CheckCircle, CircleDashed } from '@phosphor-icons/react';
import React, { useMemo, type ReactNode } from 'react';
import { ButtonVariant } from '../../../../mantineTheme';

type ContainerProps = {
    children: ReactNode;
    label: string;
    isEditing: boolean;
    handleEdit: () => void;
    handleCancel: () => void;
    handleSave: () => void;
    isSaving?: boolean;
    isComplete?: boolean;
};
const Container = ({
    children,
    label,
    isEditing,
    handleEdit,
    handleCancel,
    handleSave,
    isSaving,
    isComplete = true,
}: ContainerProps) => {
    const { t } = useLocale();

    const outerBorderColor = useMemo(
        () => (isEditing ? 'border-blu-800/20' : 'border-shade-4'),
        [isEditing],
    );

    const innerBorderColor = useMemo(
        () => (isEditing ? 'border-blu-800' : 'border-gray-250'),
        [isEditing],
    );

    const buttonSection = useMemo(() => {
        if (isEditing) {
            return (
                <Flex gap={8}>
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        onClick={handleCancel}
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button onClick={handleSave} loading={isSaving}>
                        {t('common.save')}
                    </Button>
                </Flex>
            );
        }
        return (
            <Button variant={ButtonVariant.OUTLINED} onClick={handleEdit}>
                {t('common.edit')}
            </Button>
        );
    }, [handleCancel, handleEdit, handleSave, isEditing, isSaving, t]);

    return (
        <Box
            className={`${
                isEditing && 'border-2'
            } ${outerBorderColor} rounded-[14px]`}
        >
            <Box
                className={`border-base ${innerBorderColor} rounded-xl p-3 pb-[18px] bg-white`}
            >
                <Flex justify={'space-between'} className="mb-6">
                    <Flex align={'center'} gap={6}>
                        {isComplete ? (
                            <CheckCircle color="rgb(var(--color-blu-800))" />
                        ) : (
                            <CircleDashed color="rgb(var(--color-gray-600))" />
                        )}
                        <Text className="text-base text-gray-700 font-semibold">
                            {label}
                        </Text>
                    </Flex>
                    {buttonSection}
                </Flex>
                {children}
            </Box>
        </Box>
    );
};

export default Container;
