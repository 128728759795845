import {
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from '@components/Audience/Filters/FieldListItem/utils';
import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import {
    CampaignContentStep,
    type SetupContentSource,
    type TemplateSetupCompleteArgs,
} from '@components/Campaigns/Builder/types';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import Stepper from '@components/common/Stepper';
import {
    type Campaign,
    type ContentMappings,
    type ExternalTemplate,
    type TemplateContentDetails,
} from '@lightdash/common';
import { Box, Divider, Stack } from '@mantine/core';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { isEqual } from 'lodash';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { getChannelBasedContentSteps } from './../../../utils';

interface SetupContentProps {
    templateMetadata: TemplateContentDetails | ExternalTemplate | undefined;
    onTemplateSetupComplete:
        | ((args: TemplateSetupCompleteArgs) => void)
        | undefined;
    fields: FieldsWithSuggestions | undefined;
    additionalPropertySelectList:
        | PropertySelectListType<
              FieldWithSelectStatus,
              PropertySelectGroupType
          >[]
        | undefined;
    source: SetupContentSource;
    variablesMappedWithJourneyParams?: ContentMappings;
}

const SetupContent: React.FC<SetupContentProps> = ({
    templateMetadata,
    onTemplateSetupComplete,
    fields,
    additionalPropertySelectList,
    source,
    variablesMappedWithJourneyParams,
}) => {
    const oldTemplateMetadata = useRef<
        TemplateContentDetails | ExternalTemplate | null
    >(null);
    const { state, actions } = useCampaignContext((context) => context);
    const { setPreviousStepCallback, setCurrentStepCallback } = actions;
    const { isValidStep, campaignPayload } = state;

    const campaignContentSteps = useMemo(() => {
        return getChannelBasedContentSteps({
            channel: campaignPayload.channel,
            templateMetadata,
        });
    }, [campaignPayload.channel, templateMetadata]);

    const [activeContentStep, setActiveContentStep] =
        useState<CampaignContentStep>(campaignContentSteps[0].key);

    const setActiveContentStepFn = useCallback(
        (
            step: CampaignContentStep,
            currentCampaignPayload: Partial<Campaign> | undefined,
        ) => {
            const currentStepIndex = campaignContentSteps.findIndex(
                (indexStep) => indexStep.key === step,
            );
            const isLastStep =
                currentStepIndex === campaignContentSteps.length - 1;
            setCurrentStepCallback({
                callback:
                    isLastStep && onTemplateSetupComplete
                        ? () =>
                              onTemplateSetupComplete(
                                  currentCampaignPayload
                                      ? {
                                            ...campaignPayload,
                                            ...currentCampaignPayload,
                                        }
                                      : campaignPayload,
                              )
                        : () => {},
                skipExecutionAfterCallback: false,
            });
            setPreviousStepCallback({
                callback: () => {},
                skipExecutionAfterCallback: false,
            });
            setActiveContentStep(step);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [campaignPayload],
    );

    const currentStepIndex = useMemo(
        () =>
            campaignContentSteps.findIndex(
                (step) => step.key === activeContentStep,
            ),
        [activeContentStep, campaignContentSteps],
    );

    // INFO - This is to handle the scenario where user modifies the template at any step of the content
    // and adds any new links or variables
    useEffect(() => {
        if (oldTemplateMetadata.current === null && templateMetadata) {
            oldTemplateMetadata.current = templateMetadata;
        } else if (templateMetadata && oldTemplateMetadata.current) {
            if (!isEqual(oldTemplateMetadata.current, templateMetadata)) {
                setActiveContentStep(CampaignContentStep.SETUP);
                oldTemplateMetadata.current = templateMetadata;
            }
        }
    }, [templateMetadata]);

    useEffect(() => {
        if (currentStepIndex === -1) {
            setActiveContentStep(CampaignContentStep.SETUP);
        }
    }, [currentStepIndex]);

    const CurrentComponent =
        campaignContentSteps[currentStepIndex === -1 ? 0 : currentStepIndex]
            .component;

    const handleStepClick = useCallback(
        (index: number) => {
            const isValid = isValidStep(campaignContentSteps[index].key);
            if (isValid) {
                setActiveContentStep(campaignContentSteps[index].key);
            }
        },
        [campaignContentSteps, isValidStep],
    );

    if (!templateMetadata) return null;

    return (
        <Stack className="border-r-[1px] mr-1 h-[calc(100vh-120px)] pl-8">
            <Stepper
                active={currentStepIndex}
                onStepClick={handleStepClick}
                steps={campaignContentSteps.map((step) => ({
                    key: step.key,
                    label: step.label,
                    isVisited: isValidStep(step.key),
                }))}
                className="mt-6"
            />

            <Divider className="border-shade-6" />

            <Box className="pr-4">
                <CurrentComponent
                    setActiveContentStep={setActiveContentStepFn}
                    activeContentStep={activeContentStep}
                    templateMetadata={templateMetadata}
                    fields={fields}
                    additionalPropertySelectList={additionalPropertySelectList}
                    source={source}
                    variablesMappedWithJourneyParams={
                        variablesMappedWithJourneyParams
                    }
                />
            </Box>
        </Stack>
    );
};

export default React.memo(SetupContent);
