import { type JourneyBuilderState } from '@providers/Journey/types';

export const getExperimentDestinationNodeId = (state: JourneyBuilderState) => {
    const edges = state.edges;
    const targetCount: Record<string, number> = {};

    // Iterate over each edge to count occurrences of each target node
    for (const edge of edges) {
        const targetId = edge.target;
        if (targetCount[targetId] === undefined) {
            targetCount[targetId] = 0;
        }
        targetCount[targetId]++;
    }

    //FIXME: This is a temporary solution to get the destination node id.
    //We need to find a better way to get the destination node id.
    for (const [targetId, count] of Object.entries(targetCount)) {
        if (count > 1) {
            return targetId;
        }
    }
};

/**
 * Removes all nodes that are descendants of the variantId until the destinationNodeId.
 *
 * @param state - The current state of the journey builder.
 * @param sourceNodeId - The ID of the source node.
 * @param destinationNodeId - The ID of the destination node.
 */
export const removeNodesBetweenVariantAndDestination = (
    state: JourneyBuilderState,
    sourceNodeId: string,
    destinationNodeId: string,
): JourneyBuilderState => {
    // Validate input types
    if (
        typeof sourceNodeId !== 'string' ||
        typeof destinationNodeId !== 'string'
    ) {
        throw new Error('sourceNodeId and destinationNodeId must be strings.');
    }

    const { nodes, edges } = state;

    // Find the variantId which is the first node from the sourceNodeId
    const variantEdge = edges.find((edge) => edge.source === sourceNodeId);
    if (!variantEdge) {
        throw new Error(`No variant found from source node: ${sourceNodeId}`);
    }
    const variantId = variantEdge.target;

    // Initialize a set to keep track of nodes to be removed
    const nodesToRemove = new Set<string>();

    // Helper function to recursively find all descendants of a node
    const findDescendants = (nodeId: string): void => {
        for (const edge of edges) {
            if (edge.source === nodeId) {
                const targetId = edge.target;
                if (targetId !== destinationNodeId) {
                    nodesToRemove.add(targetId);
                    findDescendants(targetId);
                }
            }
        }
    };

    // Start finding descendants from the variantId
    findDescendants(variantId);

    // Remove nodes from the state
    state.nodes = nodes.filter((node) => !nodesToRemove.has(node.id));
    state.edges = edges.filter((edge) => !nodesToRemove.has(edge.source));
    console.log('state', state);
    return state;
};

export const removeVariantPath = (
    state: JourneyBuilderState,
    variantId: string,
) => {
    const experimentDestinationNodeId =
        getExperimentDestinationNodeId(state) ?? '';
    return removeNodesBetweenVariantAndDestination(
        state,
        variantId,
        experimentDestinationNodeId,
    );
};
