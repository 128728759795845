import TableIcon from '@components/common/IconPack/TableIcon';
import PropertySelect from '@components/common/Select/PropertySelect';
import {
    type AddditionalPropertySelectListProps,
    type PropertySelectListType,
} from '@components/common/Select/PropertySelect/type';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { useFilterFields } from '@hooks/useFilterFields';
import { useLocale } from '@hooks/useLocale';
import {
    FieldType,
    JoinType,
    RelationTableType,
    type AdditionalMetric,
    type CustomSqlDimension,
    type Filters,
} from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import useProjectContext from '@providers/Project/useProjectContext';
import { getTablePrimaryField } from '@utils/relation';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import {
    convertFieldsIntoPropertySelectListType,
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from './FieldListItem/utils';
import { FilterFieldSelectItem } from './FilterInputs/FilterFieldSelect';
import { filterPropertyTypeMapper } from './FilterSelect/useFilterSelectUtils';
import { type FieldWithSuggestions } from './FiltersProvider/types';
import useFiltersContext from './FiltersProvider/useFiltersContext';
import { createFilterRule } from './utils/createFilterRule';
interface FilterFormPropertySelectProps {
    targetButton?: React.ReactNode;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    index: number;
    additionalMetrics: AdditionalMetric[] | undefined;
    setAdditionalMetrics:
        | ((
              value: AdditionalMetric[],
              shouldFetchResults: boolean,
              index: number,
          ) => void)
        | undefined;
    customDimensions: CustomSqlDimension[] | undefined;
    setCustomDimensions: (
        customDimensions: CustomSqlDimension[],
        shouldFetchResults: boolean,
        index: number,
    ) => void;
}

const FilterFormPropertySelect: React.FC<FilterFormPropertySelectProps> = ({
    targetButton,
    filters,
    setFilters,
    index,
    additionalMetrics,
    setAdditionalMetrics,
    customDimensions,
    setCustomDimensions,
}) => {
    const {
        dimensions,
        metrics,
        events,
        audienceMap,
        customDimensions: customDimensionsFromMetrics,
    } = useFilterFields();
    const { isLoading, relatedOneToManyTables } = useFiltersContext();
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();
    const [opened, { open, close }] = useDisclosure(false);
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData } = useProjectContext();

    const relatedShortLabel = useMemo(
        () =>
            filterPropertyTypeMapper.find(
                (item) => item.propertyType === RelationTableType.RELATED,
            )?.shortLabel ?? '',
        [],
    );

    const hasRelatedGroup = useCallback(
        (
            updatedFields: PropertySelectListType<
                FieldWithSelectStatus,
                PropertySelectGroupType
            >[],
        ) => {
            return updatedFields.some(
                (group) => group.groupKey === RelationTableType.RELATED,
            );
        },
        [],
    );
    //FIXME: Change the type of propertySelectItems
    const propertySelectItems: any[] = useMemo(() => {
        if (!activeRelation) return [];
        // Removing all the related tables from the filterable fields that are one to many
        // since we need to create subgroups for related tables that contain related properties and tables with records
        const dimensionsExculdingRelatedTablesAndEvents = dimensions.filter(
            (dimension) => {
                const table = activeRelation.tables[dimension.table];
                if (table.type === RelationTableType.EVENT) return false;
                if (table.type !== RelationTableType.RELATED) return true;
                return !relatedOneToManyTables?.includes(table.name);
            },
        );

        const filterableFieldsWithoutRelatedTables: PropertySelectListType<
            FieldWithSelectStatus,
            PropertySelectGroupType
        >[] = convertFieldsIntoPropertySelectListType(
            [
                ...dimensionsExculdingRelatedTablesAndEvents,
                ...events,
                ...metrics,
                ...(customDimensionsFromMetrics || []),
                ...audienceMap,
            ],
            true,
        );

        const oneToManyTables = relatedOneToManyTables?.map(
            (tableName) => activeRelation.tables[tableName],
        );

        if (!oneToManyTables?.length)
            return filterableFieldsWithoutRelatedTables;

        //Info: Getting all the primary fields of the one to many related tables
        const primaryFieldsOfOneToManyTables = oneToManyTables
            .filter((table) => !table.isReserved)
            .map((table) => {
                const primaryField = getTablePrimaryField(
                    table.name,
                    activeRelation,
                );
                return primaryField;
            });

        const relatedTableRecordsGroup = primaryFieldsOfOneToManyTables.map(
            (primaryField) => {
                return {
                    subGroupLabel: 'Has related records in...',
                    subGroupKey: RelationTableType.RELATED + JoinType.one_many,
                    ...primaryField,
                };
            },
        );

        // Extract the short label for RELATED type only once

        // Map through the fields and update or keep as is
        const updatedFields = filterableFieldsWithoutRelatedTables.map(
            (group) => {
                if (group.groupKey === RelationTableType.RELATED) {
                    return {
                        ...group,
                        items: [...relatedTableRecordsGroup, ...group.items],
                    };
                }
                return group;
            },
        );
        // Check if there is already a group for RELATED
        const hasRelatedGroupInUpdatedFields = hasRelatedGroup(
            updatedFields as PropertySelectListType<
                FieldWithSelectStatus,
                PropertySelectGroupType
            >[],
        );

        // If there are oneToManyTables and no existing RELATED group, add a new group at the first index
        if (oneToManyTables?.length && !hasRelatedGroupInUpdatedFields) {
            updatedFields.splice(0, 0, {
                groupKey: RelationTableType.RELATED,
                groupLabel: relatedShortLabel,
                groupIcon: (
                    <TableIcon
                        type={RelationTableType.RELATED}
                        color="rgb(var(--color-gray-800))"
                    />
                ),
                items: relatedTableRecordsGroup,
            });
        }
        return updatedFields;
    }, [
        activeRelation,
        dimensions,
        events,
        metrics,
        audienceMap,
        customDimensionsFromMetrics,
        relatedOneToManyTables,
        relatedShortLabel,
        hasRelatedGroup,
    ]);

    return (
        <>
            <PropertySelect<
                FieldWithSuggestions & AddditionalPropertySelectListProps
            >
                items={propertySelectItems}
                showGroup={true}
                headerRightSection={undefined}
                onSubmit={(items: FieldWithSuggestions[]) => {
                    close();
                    if (!items[0] || !projectUuid) return;
                    if (!projectData || !projectData.warehouseConnection)
                        return;
                    createFilterRule({
                        field: items[0],
                        additionalMetrics,
                        setAdditionalMetrics,
                        activeRelation,
                        filters,
                        setFilters,
                        index,
                        additionalMetricsFilters: undefined,
                        projectUuid: projectUuid,
                        customDimensions,
                        setCustomDimensions,
                        relatedOneToManyTables,
                        warehouseType: projectData.warehouseConnection.type,
                    });
                }}
                itemTemplate={({ item }) => {
                    return (
                        <FilterFieldSelectItem
                            activeField={item}
                            isEditMode={true}
                            showFieldSource={
                                item.fieldType !== FieldType.METRIC
                            }
                            activeRelation={activeRelation}
                            showHoverIcon={true}
                            showRelatedTableLabelOnly={true}
                            isOneToManyTable={
                                relatedOneToManyTables?.includes(
                                    item.table ?? '',
                                ) ?? false
                            }
                        />
                    );
                }}
                opened={opened}
                close={close}
                open={open}
                targetButton={
                    isLoading ? (
                        <SkeletonLoader height={20} width={80} />
                    ) : (
                        targetButton
                    )
                }
                showSearch={true}
                searchKeys={['label', 'tableLabel', 'table', 'name']}
                searchPlaceholder={t('audience_filters.search_filter_label')}
                allowMultipleSelection={false}
                height={350}
                width={450}
                groupPanelWidth={120}
                withinPortal={true}
            />
        </>
    );
};
export default FilterFormPropertySelect;
