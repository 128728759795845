import FilterSelectWrapper from '@components/common/Filters/FilterInputs/FilterSelectWrapper';
import FieldIcon from '@components/common/IconPack/FieldIcon';
import { TextWithTooltip } from '@components/common/TextWithTooltip';
import { useLocale } from '@hooks/useLocale';
import { DimensionType, type CommunicationChannel } from '@lightdash/common';
import { Box, Group, Text } from '@mantine/core';
import { CaretDown, CaretRight } from '@phosphor-icons/react';
import React from 'react';
import EventInfoPopover from '../ControlPanel/TriggerPanel/EventInfoPopover';
import { InternalEventItem } from '../ControlPanel/TriggerPanel/EventsList';
import { JourneyEventType, type ActionEventField } from './types';

interface JourneyActiveFieldProps {
    activeEventField: ActionEventField | undefined;
    isEditMode: boolean;
}

const JourneyActiveEventField: React.FC<JourneyActiveFieldProps> = ({
    activeEventField,
    isEditMode,
}) => {
    const { t } = useLocale();

    if (!activeEventField)
        return (
            <FilterSelectWrapper isEditMode={isEditMode}>
                <Text className="font-normal">
                    {t('filter_select.empty_even_select_label')}
                </Text>
                <CaretDown
                    color="rgb(var(--color-gray-600))"
                    weight="regular"
                    size={16}
                    className="ml-2"
                />
            </FilterSelectWrapper>
        );

    if (activeEventField.eventType === JourneyEventType.INTERNAL_EVENT) {
        return (
            <FilterSelectWrapper isEditMode={isEditMode}>
                <InternalEventItem
                    event={{
                        sourceLabel:
                            activeEventField.eventSourceLabel as CommunicationChannel,
                        label: activeEventField.eventLabel,
                        eventName: activeEventField.eventName,
                        uuid: '',
                        projectUuid: '',
                        source: '',
                        mapperSchema: {},
                        sampleEventPayload: {},
                        createdBy: '',
                        createdAt: null,
                        updatedBy: '',
                        updatedAt: null,
                        persistToWh: false,
                        isInternal: false,
                        tags: [],
                    }}
                />
            </FilterSelectWrapper>
        );
    }
    return (
        <EventInfoPopover
            target={
                <FilterSelectWrapper isEditMode={isEditMode}>
                    <Group className="gap-2">
                        <Box className="px-2 flex-shrink-0">
                            <div className="flex items-center">
                                <div className="flex items-center justify-center gap-2">
                                    {activeEventField.eventSourceIcon}
                                </div>
                                <TextWithTooltip
                                    className="text-sm font-medium text-gray-500 truncate max-w-[120px]"
                                    text={activeEventField.eventSourceLabel}
                                />
                            </div>
                        </Box>
                        <CaretRight className="flex-shrink-0" />

                        <FieldIcon
                            type={DimensionType.EVENT}
                            strokeWidth={2.5}
                            className="flex-shrink-0"
                        />
                        <Box className="flex-1 min-w-0">
                            {activeEventField.eventLabel && (
                                <TextWithTooltip
                                    className="text-sm font-medium text-gray-500 truncate max-w-[120px]"
                                    text={activeEventField.eventLabel}
                                />
                            )}
                        </Box>
                    </Group>
                </FilterSelectWrapper>
            }
            eventLabel={activeEventField.eventLabel}
            eventSource={activeEventField.eventSourceLabel}
            eventName={activeEventField.eventName}
        />
    );
};

export default JourneyActiveEventField;
