import {
    Box,
    Group,
    Modal as MantineModal,
    type ModalProps as MantineModalProps,
} from '@mantine/core';
import { Sparkle } from '@phosphor-icons/react';
import React, { useMemo } from 'react';

export interface ModalProps extends MantineModalProps {
    children: React.ReactNode;
    title?: React.ReactNode;
    footerRightSection?: React.ReactNode;
    footerLeftSection?: React.ReactNode;
    withContentPadding?: boolean;
    withFooterBorder?: boolean;
    contentCustomClass?: string;
    isAiThinking?: boolean;
    aiThinkingText?: string;
}

const Modal: React.FC<ModalProps> = ({
    children,
    title,
    footerRightSection,
    footerLeftSection,
    withContentPadding = true,
    withFooterBorder,
    contentCustomClass,
    isAiThinking,
    aiThinkingText,
    ...props
}) => {
    const withFooter = footerLeftSection || footerRightSection;
    const footerBorder = withFooterBorder ? ' border-t border-shade-6 ' : '';

    const modalClassNames = useMemo(() => {
        if (isAiThinking) {
            return {
                root: 'animated-modal-root fade-out pointer-events-none',
                content: 'animated-modal-content pointer-events-auto',
            };
        }
        return { root: '', content: '' };
    }, [isAiThinking]);

    return (
        <MantineModal
            centered
            {...props}
            title={title}
            classNames={modalClassNames}
        >
            <Box
                className={`animated-modal-inner ${
                    isAiThinking ? 'pointer-events-none opacity-40' : ''
                }`}
            >
                <Box
                    className={`${withContentPadding ? 'px-4 py-3 ' : ' '} ${
                        contentCustomClass ? contentCustomClass : ' '
                    }`}
                >
                    {children}
                </Box>

                {withFooter && (
                    <Group
                        className={
                            'sticky bottom-0 flex items-center justify-between p-4 bg-white ' +
                            footerBorder +
                            (isAiThinking ? ' pointer-events-none' : '')
                        }
                        style={{ height: '3.75rem' }}
                    >
                        <Box>{footerLeftSection}</Box>
                        <Box>{footerRightSection}</Box>
                    </Group>
                )}
            </Box>
            {isAiThinking && aiThinkingText && (
                <Box className="absolute bottom-3.5 left-0 flex items-center justify-start p-4 text-blu-800 font-semibold animate-fade-in">
                    <Sparkle
                        color="rgb(var(--color-blu-800))"
                        weight="duotone"
                    />
                    {aiThinkingText}
                </Box>
            )}
        </MantineModal>
    );
};

export default React.memo(Modal);
