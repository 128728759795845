import InputErrorText from '@components/common/InputErrorText';
import Select from '@components/common/Select';
import ListSelect from '@components/common/Select/ListSelect';
import { useLocale } from '@hooks/useLocale';
import {
    useRefreshTables,
    useUploadCatalogFile,
    useWarehouseTables,
} from '@hooks/useSchemaBuilder';
import { DimensionType, type WarehouseTableInfoBase } from '@lightdash/common';
import {
    ActionIcon,
    Box,
    Button,
    Flex,
    Group,
    Loader,
    Stack,
    Text,
} from '@mantine/core';
import { Dropzone, MIME_TYPES, type FileWithPath } from '@mantine/dropzone';
import { useDisclosure } from '@mantine/hooks';
import {
    ArrowsClockwise,
    CaretRight,
    CheckCircle,
    Database,
    Eye,
    FileArrowUp,
    FileCsv,
    MagnifyingGlass,
    SpinnerGap,
    Table,
    Trash,
} from '@phosphor-icons/react';
import useRelationContext from '@providers/Relation/useRelationContext';
import useSchemaContext from '@providers/Schema/useSchemaContext';
import { useQueryClient } from '@tanstack/react-query';
import { CSV_AUDIENCE_PREVIEW_LIMIT, MAX_FILE_SIZE } from '@utils/constants';
import Papa from 'papaparse';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { type ListSelectOptionsProp } from 'types/ListSelect';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../../../mantineTheme';
import { SchemaFileType, type SchemaBuilderStep } from '../../types';
import { fileTypeData, SchemaBuilderSteps } from '../../utils';
import DataSource from './DataSource';
import SchemaPreviewModal from './SchemaPreviewModal';
const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
const isoTimestampRegex =
    /^\d{4}-\d{2}-\d{2}[T ]\d{2}:\d{2}(:\d{2})?(\.\d+)?(Z|[+-]\d{2}:?\d{2})?$/;

interface ItemComponentProps extends React.ComponentPropsWithoutRef<'div'> {
    item: WarehouseTableInfoBase;
    label: string;
    disabled?: boolean;
}

export const ItemComponent = ({ label, disabled }: ItemComponentProps) => {
    return (
        <Group className="items-center gap-1.5">
            <Table
                color={
                    disabled
                        ? 'rgb(var(--color-gray-400)'
                        : 'rgb(var(--color-pink-800)'
                }
            />
            <Text
                className={`text-sm font-medium ${
                    disabled ? 'text-gray-400' : 'text-gray-800'
                }`}
            >
                {label}
            </Text>
        </Group>
    );
};

const PrimaryTableSetup: React.FC<{}> = ({}) => {
    const queryClient = useQueryClient();
    const { t } = useLocale();
    const {
        isEditMode,
        activeProject,
        currentBuilderStep,
        isDrawerOpen,
        schemaPayload,
        schemaFileType,
    } = useSchemaContext((context) => context.state);
    const navigate = useNavigate();
    const location = useLocation();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { activeRelation } = useRelationContext();
    const [selectedTableOption, setSelectedTableOption] =
        useState<ListSelectOptionsProp>();
    const openRef = useRef<() => void>(null);
    const { setCurrentBuilderStep, setSchemaFileType, updateSchemaPayload } =
        useSchemaContext((context) => context.actions);

    const { data: tables, isLoading, isFetching } = useWarehouseTables();
    const { mutateAsync: refreshTables, isLoading: isRefreshLoading } =
        useRefreshTables();
    const [error] = useState<string>('');
    const [showCsvPreview, { open: openCsvPreview, close: closeCsvPreview }] =
        useDisclosure(false);

    const { mutateAsync: uploadCatalogFile, isLoading: isUploadLoading } =
        useUploadCatalogFile();

    const handleRefresh = () => {
        refreshTables()
            .then(() => {
                void queryClient.invalidateQueries([
                    QueryKeys.WAREHOUSE_TABLES,
                ]);
            })
            .catch(() => {});
    };

    const getNextStep = useCallback(() => {
        const currentStepIndex = SchemaBuilderSteps.findIndex(
            (step) => step.key === currentBuilderStep,
        );

        if (currentStepIndex === SchemaBuilderSteps.length - 1) return;

        return SchemaBuilderSteps[currentStepIndex + 1]?.key;
    }, [currentBuilderStep]);

    const handleNext = useCallback(() => {
        const nextStep = getNextStep();
        if (!nextStep) return;

        setCurrentBuilderStep(nextStep as SchemaBuilderStep);
    }, [getNextStep, setCurrentBuilderStep]);

    const createdListItemObject = useCallback(
        (obj: WarehouseTableInfoBase): ListSelectOptionsProp => {
            return {
                uuid: `${obj?.database}|${obj?.schema}|${obj?.table}`,
                key: obj?.schema,
                value: obj?.table,
                disabled: !!activeRelation?.tables[obj?.table],
                renderComponent: (
                    <Box className="px-1">
                        <ItemComponent
                            label={obj?.table}
                            item={obj}
                            disabled={!!activeRelation?.tables[obj?.table]}
                        />
                    </Box>
                ),
            };
        },
        [activeRelation],
    );

    const options: ListSelectOptionsProp[] = useMemo(() => {
        return (
            tables?.map((obj: WarehouseTableInfoBase) => {
                return createdListItemObject(obj);
            }) ?? []
        );
    }, [tables, createdListItemObject]);

    const handleFieldSelect = (selectedOption: ListSelectOptionsProp) => {
        setSelectedTableOption(selectedOption);
        const { uuid, key, value } = selectedOption;
        const database =
            uuid
                ?.toString()
                ?.substring(0, uuid?.toString()?.indexOf(`|${key}|${value}`)) ??
            '';
        updateSchemaPayload({
            ...schemaPayload,
            name: value,
            schema: key,
            database: database,
        });
    };
    const isCreateFlow = useMemo(() => {
        const createPathPattern = new RegExp(
            `/projects/${projectUuid}/relations/create`,
        );
        return createPathPattern.test(location.pathname);
    }, [projectUuid, location.pathname]);
    const handleSkip = useCallback(() => {
        void navigate(`/projects/${projectUuid}/relations`);
    }, [navigate, projectUuid]);

    const getFileTypeIcon = useCallback((fileType: SchemaFileType) => {
        if (fileType === SchemaFileType.WAREHOUSE) return <Database />;
        if (fileType === SchemaFileType.CSV) return <FileCsv />;
    }, []);

    const showNext = useMemo(() => {
        if (schemaFileType === SchemaFileType.CSV) {
            return (
                schemaPayload?.blobFilePath &&
                schemaPayload?.blobFilePath.length > 0
            );
        }
        return (
            schemaPayload?.database &&
            schemaPayload?.schema &&
            schemaPayload?.name &&
            schemaPayload.database !== '' &&
            schemaPayload.schema !== '' &&
            schemaPayload.name !== ''
        );
    }, [
        schemaFileType,
        schemaPayload?.blobFilePath,
        schemaPayload.database,
        schemaPayload.name,
        schemaPayload.schema,
    ]);

    const inferDataType = useCallback((value: any): DimensionType => {
        // Handle null/undefined/empty values
        if (value === null || value === undefined || value === '') {
            return DimensionType.STRING;
        }

        // Convert to string for consistent handling
        const strValue = String(value).trim().toLowerCase();

        // Check for boolean values
        if (strValue === 'true' || strValue === 'false') {
            return DimensionType.BOOLEAN;
        }

        // Check for numbers
        if (!isNaN(Number(strValue)) && strValue !== '') {
            return DimensionType.NUMBER;
        }

        // Check for dates and timestamps
        // ISO date format check

        if (isoTimestampRegex.test(strValue)) {
            return DimensionType.TIMESTAMP;
        }

        if (isoDateRegex.test(strValue)) {
            return DimensionType.DATE;
        }
        return DimensionType.STRING;
    }, []);

    const handleFileUpload = useCallback(
        (files: FileWithPath[]) => {
            if (files) {
                Papa.parse(files[0] as any, {
                    preview: CSV_AUDIENCE_PREVIEW_LIMIT,
                    header: true,
                    complete(results) {
                        if (results.meta.fields) {
                            const firstRow = results.data[0] as Record<
                                string,
                                string
                            >;
                            void uploadCatalogFile({ file: files[0] }).then(
                                (res) => {
                                    updateSchemaPayload({
                                        blobFilePath: res,
                                        columns: results.meta.fields?.map(
                                            (field: string) => ({
                                                name: field,
                                                dataType: inferDataType(
                                                    firstRow[field],
                                                ),
                                                nullable: false,
                                            }),
                                        ),
                                        csvFile: files[0],
                                        csvData: results.data,
                                        csvDataCount: results.data.length,
                                        name: files[0].name,
                                    });
                                },
                            );
                        }
                    },
                });
            }
        },
        [updateSchemaPayload, uploadCatalogFile, inferDataType],
    );

    const renderCsvUploadStatus = useMemo(() => {
        if (isUploadLoading) {
            return (
                <Text className="text-xs text-gray-600">
                    {t('audience_upload.processing')}
                </Text>
            );
        }
        return (
            <>
                <CheckCircle
                    weight="duotone"
                    color="rgba(var(--color-green))"
                    size={14}
                />
                <Text className="text-xs text-gray-600">
                    {t('audience_upload.ready_to_process', {
                        count: schemaPayload?.csvDataCount,
                    })}
                </Text>
            </>
        );
    }, [isUploadLoading, schemaPayload?.csvDataCount, t]);

    const renderModalContent = () => {
        if (schemaPayload?.csvFile) {
            return (
                <Stack spacing={0}>
                    <Group position="apart" className="p-2 border rounded-lg">
                        <Group>
                            <Flex
                                align={'center'}
                                justify={'center'}
                                className="border rounded-lg h-[2.375rem] w-[2.375rem]"
                            >
                                {isUploadLoading ? (
                                    <SpinnerGap
                                        size={14}
                                        weight="duotone"
                                        color="rgba(var(--color-blu-800))"
                                    />
                                ) : (
                                    <FileCsv
                                        size={14}
                                        weight="duotone"
                                        color="rgba(var(--color-blu-800))"
                                    />
                                )}
                            </Flex>
                            <Stack spacing={4}>
                                <Text className="font-semibold text-gray-700">
                                    {schemaPayload.csvFile?.name ??
                                        t('common.csv_file')}
                                </Text>
                                <Group spacing={6}>
                                    {renderCsvUploadStatus}
                                </Group>
                            </Stack>
                        </Group>
                        <Group spacing={'xs'}>
                            <ActionIcon
                                onClick={() => {
                                    updateSchemaPayload({
                                        blobFilePath: '',
                                        csvFile: null,
                                    });
                                }}
                            >
                                <Trash size={14} weight="duotone" />
                            </ActionIcon>
                        </Group>
                    </Group>
                    <Text className="mt-2 text-gray-600">
                        {t('audience_upload.description')}
                    </Text>
                </Stack>
            );
        }
        return (
            <Box className="h-full">
                <Dropzone
                    accept={[MIME_TYPES.csv]}
                    openRef={openRef}
                    onDrop={handleFileUpload}
                    activateOnClick={false}
                    styles={{
                        root: {
                            background: 'rgba(var(--color-gray-50))',
                            borderColor: 'rgba(var(--color-gray-200))',
                        },
                        inner: {
                            pointerEvents: 'all',
                        },
                    }}
                    maxSize={MAX_FILE_SIZE}
                    className="h-full flex items-center justify-center"
                >
                    <Stack
                        w={'100%'}
                        justify={'center'}
                        align="center"
                        spacing={'sm'}
                    >
                        <Button
                            leftIcon={<FileArrowUp color="white" size={14} />}
                            onClick={() => openRef.current?.()}
                            style={{ pointerEvents: 'all' }}
                            loading={isUploadLoading}
                        >
                            {t('audience_upload.button_label')}
                        </Button>
                        <Text className="text-xs text-gray-500">
                            {t('audience_upload.max_file_size')}
                        </Text>
                    </Stack>
                </Dropzone>
                {error && (
                    <Box className="mt-2">
                        <InputErrorText value={error} />
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <Box className="w-full">
            {!isDrawerOpen && (
                <>
                    <Text className="mt-4 mb-1 text-sm font-medium text-gray-800">
                        {t('schema_builder.table_chooser.connected_to')}
                    </Text>

                    <DataSource dataSource={activeProject} />
                </>
            )}

            {!isCreateFlow && (
                <Box className="border-b border-gray-200 w-full">
                    <Select
                        className="my-3"
                        value={schemaFileType}
                        onChange={(value: SchemaFileType) => {
                            if (value === SchemaFileType.CSV) {
                                updateSchemaPayload({
                                    blobFilePath: '',
                                    csvFile: null,
                                });
                            }
                            setSchemaFileType(value);
                        }}
                        data={fileTypeData}
                        icon={getFileTypeIcon(schemaFileType)}
                    />
                </Box>
            )}

            <Text className="mt-4 mb-1 text-sm font-medium text-gray-800">
                {t(
                    isDrawerOpen
                        ? 'schema_builder.add_table.title'
                        : 'schema_builder.table_chooser.where_are_users',
                )}
            </Text>

            <Box className="h-96">
                {!isCreateFlow && schemaFileType === SchemaFileType.CSV ? (
                    renderModalContent()
                ) : (
                    <ListSelect
                        leftSection={<MagnifyingGlass />}
                        placeholder={t(
                            'schema_builder.table_chooser.search.placeholder',
                        )}
                        options={options}
                        onOptionSelect={handleFieldSelect}
                        selectedOption={selectedTableOption}
                        withDivider
                        loading={isLoading}
                        rightSection={
                            isLoading || isRefreshLoading ? (
                                <>
                                    <Loader
                                        size={13}
                                        color={'rgb(var(--color-gray-500))'}
                                    />
                                </>
                            ) : (
                                <>
                                    <ArrowsClockwise
                                        weight="regular"
                                        onClick={handleRefresh}
                                        className={`${
                                            isFetching
                                                ? 'pointer-events-none'
                                                : 'cursor-pointer'
                                        } `}
                                    />
                                </>
                            )
                        }
                    />
                )}
            </Box>
            {isCreateFlow && (
                <Box className="fixed bottom-0 left-0 w-full bg-white border-t-2">
                    <Flex className="px-4 my-4" justify={'flex-end'}>
                        <Button
                            variant={ButtonVariant.DEFAULT}
                            onClick={handleSkip}
                        >
                            {t('common.skip_for_now')}
                        </Button>
                    </Flex>
                </Box>
            )}

            {!isEditMode && (
                <Box className="fixed bottom-0 left-0 w-full bg-white border-t-2">
                    <Flex className="px-4 my-4 gap-2" justify={'flex-end'}>
                        {schemaFileType === SchemaFileType.CSV &&
                            schemaPayload.blobFilePath && (
                                <Button
                                    variant={ButtonVariant.OUTLINED}
                                    onClick={openCsvPreview}
                                    disabled={!showNext}
                                    leftIcon={
                                        <Eye weight="duotone" size={14} />
                                    }
                                    className="py-2"
                                >
                                    {t('common.preview')}
                                </Button>
                            )}
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            onClick={async () => {
                                await handleNext();
                            }}
                            disabled={!showNext}
                            className="py-2"
                            rightIcon={<CaretRight color="white" size={14} />}
                        >
                            {t('common.continue')}
                        </Button>
                    </Flex>
                </Box>
            )}
            <SchemaPreviewModal
                open={showCsvPreview}
                onClose={closeCsvPreview}
                fileName={schemaPayload?.csvFile?.name}
                count={schemaPayload?.csvDataCount}
                schemaCsvData={schemaPayload?.csvData}
            />
        </Box>
    );
};

export default PrimaryTableSetup;
