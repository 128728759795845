import {
    useGetIntegratedProviderConfig,
    useGetProviderConfig,
} from '@hooks/useChannels';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, Flex, Image, Skeleton, Stack, Text } from '@mantine/core';
import { CheckCircle, Pencil } from '@phosphor-icons/react';
import React from 'react';
import { ButtonVariant } from '../../../../../mantineTheme';

type IntegrationProps = {
    providerId: string | undefined;
    integrationId: string | undefined;
    handleEdit: () => void;
};

const Integration = ({
    providerId,
    integrationId,
    handleEdit,
}: IntegrationProps) => {
    const { t } = useLocale();

    const { data: integrationConfig, isLoading: isIntegrationConfigLoading } =
        useGetIntegratedProviderConfig(providerId, integrationId);
    const { data: providerConfig, isLoading: isProviderConfigLoading } =
        useGetProviderConfig(providerId);

    return (
        <Box className="border-base rounded-lg p-3 pb-[18px]">
            <Flex justify={'space-between'} className="mb-5">
                <Flex gap={6} align={'center'}>
                    <CheckCircle color="rgb(var(--color-blu-800))" />
                    <Text className="text-base text-gray-700 font-semibold">
                        {t('campaign_builder.steps.channel.title')}
                    </Text>
                </Flex>
                <Button
                    leftIcon={<Pencil color="rgb(var(--color-gray-600))" />}
                    variant={ButtonVariant.OUTLINED}
                    disabled={isIntegrationConfigLoading}
                    onClick={handleEdit}
                >
                    {t('common.edit')}
                </Button>
            </Flex>
            <Stack spacing={4}>
                {isIntegrationConfigLoading ? (
                    <Skeleton width={200} height={10} />
                ) : (
                    <Text className="text-gray-800 font-medium">
                        {integrationConfig?.customName}
                    </Text>
                )}
                {isProviderConfigLoading ? (
                    <Skeleton width={100} height={10} />
                ) : (
                    <Flex align={'center'} gap={4}>
                        <Image
                            src={providerConfig?.logoUrl}
                            alt={providerConfig?.providerName}
                            width={16}
                            height={16}
                        />
                        <Text className="text-xs text-gray-600 font-medium">
                            {providerConfig?.providerName}
                        </Text>
                    </Flex>
                )}
            </Stack>
        </Box>
    );
};

export default Integration;
