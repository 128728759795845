import InputErrorText from '@components/common/InputErrorText';
import {
    JourneyNodeEnum,
    type JourneyNodeBlockData,
} from '@components/Journeys/Builder/types';
import { useLocale } from '@hooks/useLocale';
import { ActionType, JourneyTriggerType } from '@lightdash/common';
import { Box, Divider, Flex, Text, Tooltip } from '@mantine/core';
import { ArrowBendRightDown, Coffee } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useNodes, type NodeProps } from 'reactflow';
import { isExperimentBlock } from '../../ControlPanel/Actions/Split/utils';
import EdgeAnalyticsLabel from '../Edges/EdgeAnalyticsLabel';
import NodeContainer from './NodeContainer';
import { useNodeData } from './useNodeData';

/**
 *
 * @param {NodeProps<JourneyNodeBlockData>} props - The properties for the node.
 * @returns {JSX.Element | null} The rendered BlockNode component.
 */
const BlockNode: React.FC<NodeProps<JourneyNodeBlockData>> = ({ data }) => {
    const { nodeId } = data;
    const {
        journeyNodeData,
        isLeafNode,
        icon,
        hasError,
        nodeBanner,
        nodeAnalytics,
    } = useNodeData(nodeId);

    const {
        addPlaceholderNode,
        addPlaceholderNodeBetween,
        removePlaceholderNodes,
        callGenerateNodeDescription,
    } = useJourneyBuilderContext((context) => context.actions);

    const { edges, nodes } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const { isEditable } = useJourneyBuilderContext((context) => context.state);

    const { t } = useLocale();

    const reactFlowNodes = useNodes();

    const isNodeSelected =
        reactFlowNodes.find((n) => n.id === nodeId)?.selected ?? false;

    const addNodePlaceholder = useCallback(() => {
        //FIXME: This method only executes for trigger add node, hence added this for entry block only.
        callGenerateNodeDescription(JourneyTriggerType.ENTRY);

        removePlaceholderNodes();
        if (isLeafNode) {
            return addPlaceholderNode(nodeId);
        } else {
            const edge = edges.find((e) => e.source === nodeId);
            const targetNode = nodes.find((n) => n.id === edge?.target);
            const sourceNode = nodes.find((n) => n.id === nodeId);

            if (
                targetNode?.data.type !== JourneyNodeEnum.PLACEHOLDER &&
                sourceNode?.data.type !== JourneyNodeEnum.PLACEHOLDER
            ) {
                return addPlaceholderNodeBetween(edge?.id ?? '');
            }
        }
        return undefined;
    }, [
        removePlaceholderNodes,
        isLeafNode,
        callGenerateNodeDescription,
        addPlaceholderNode,
        nodeId,
        edges,
        nodes,
        addPlaceholderNodeBetween,
    ]);

    const isSplitNode = useMemo(
        () => journeyNodeData?.actions[0]?.type === ActionType.SPLIT,
        [journeyNodeData],
    );

    const isExperimentNode = useMemo(
        () => journeyNodeData && isExperimentBlock(journeyNodeData),
        [journeyNodeData],
    );

    const isTriggerNode = useMemo(() => {
        const node = nodes.find((n) => n.id === nodeId);
        const hasNoChildren = !edges.some((e) => e.source === nodeId);
        return node?.data.type === JourneyNodeEnum.TRIGGER && hasNoChildren;
    }, [nodes, edges, nodeId]);

    const allowAddNode = useMemo(() => {
        return isTriggerNode && isEditable && !isSplitNode && !isExperimentNode;
    }, [isTriggerNode, isEditable, isSplitNode, isExperimentNode]);

    if (!journeyNodeData || isEmpty(journeyNodeData)) return null;

    return (
        <>
            <NodeContainer
                nodeType={JourneyNodeEnum.BLOCK}
                isFocused={false}
                isSelected={isNodeSelected}
                onAddNodeClick={allowAddNode ? addNodePlaceholder : undefined}
                nodeBanner={nodeBanner}
                allowAddNode={allowAddNode}
            >
                <Box>
                    <Flex
                        gap={4}
                        align="center"
                        justify="space-between"
                        className="text-xs font-medium text-gray-800 "
                    >
                        <Box className="flex items-center p-3">
                            {icon}
                            <Tooltip label={journeyNodeData.title}>
                                <Text
                                    className={`text-sm pl-2 w-fit truncate ${
                                        hasError || nodeAnalytics
                                            ? '!w-[5.6rem]'
                                            : ''
                                    }`}
                                >
                                    {journeyNodeData.title}
                                </Text>
                            </Tooltip>
                        </Box>
                        {!isEditable && nodeAnalytics && (
                            <Box className="flex">
                                <ArrowBendRightDown size={14} />
                                <Text className="pl-1 pr-2 text-xs font-medium text-gray-600">
                                    {nodeAnalytics.entryUserCount}
                                </Text>
                            </Box>
                        )}
                        {hasError && (
                            <InputErrorText
                                className="px-2 py-1 my-2 mr-2 border border-halt-800/40 rounded-xl"
                                size="xs"
                                value={t('block_node.setup_pending')}
                            />
                        )}
                    </Flex>
                    {/* <Text className="text-sm font-medium text-gray-800">
                        {nodeValue}
                    </Text> */}
                </Box>
                {!isEditable &&
                    nodeAnalytics &&
                    nodeAnalytics.waitingUserCount > 0 && (
                        <Box className="flex items-center justify-between w-full gap-1 p-2 mt-2 border rounded-md bg-gray-50 border-shade-4">
                            <Box className="flex gap-2">
                                <Coffee size={14} />
                                <Text className="text-xs font-normal text-gray-500">
                                    {t('block_node.waiting')}
                                </Text>
                            </Box>

                            <Text className="text-xs font-normal text-gray-500">
                                {nodeAnalytics.waitingUserCount}
                            </Text>
                        </Box>
                    )}

                <Box
                    className={`w-full transition-all duration-700 ease-in-out overflow-hidden max-h-96`}
                >
                    <Divider className=" border-t-gray-200" />

                    {journeyNodeData.description ? (
                        <Text className="text-xs px-3 py-1.5 font-normal text-gray-500 w-full truncate">
                            {journeyNodeData.description}
                        </Text>
                    ) : (
                        <Text className="text-xs px-3 py-1.5 font-normal text-gray-500">
                            {t('block_node.no_description')}
                        </Text>
                    )}
                </Box>
            </NodeContainer>
            {isLeafNode && nodeAnalytics && (
                <EdgeAnalyticsLabel
                    nodeAnalytics={nodeAnalytics}
                    customClass="mt-2"
                    isLeafNode={isLeafNode}
                />
            )}
        </>
    );
};

export default React.memo(BlockNode);
