import { useLocale } from '@hooks/useLocale';
import { useContextSelector } from 'use-context-selector';
import CustomAttributeContext from './context';
import { type CustomAttributeContextType } from './types';

function useCustomAttributeContext<Selected>(
    selector: (value: CustomAttributeContextType) => Selected,
) {
    const { t } = useLocale();
    return useContextSelector(CustomAttributeContext, (context) => {
        if (context === undefined) {
            throw new Error(t('error.campaign_context_not_found'));
        }
        return selector(context);
    });
}

export default useCustomAttributeContext;
