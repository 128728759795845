import { ContentMappingSection, WAHeaderType } from '@lightdash/common';
import { t as translate } from 'i18next';

export const appendPreheader = (templateHtml: string, preheader: string) => {
    const PLACEHOLDER = '___AMP___'; // Unique placeholder for '&'
    const parser = new DOMParser();

    // INFO - We need this replacement of & before parsing because DOOMParser converts & to &amp; while parsing
    // this was causing issues with the validations added for unsub block.

    // Replace '&' with a placeholder before parsing
    const sanitizedHtml = templateHtml.replace(/&/g, PLACEHOLDER);
    const doc = parser.parseFromString(sanitizedHtml, 'text/html');

    // SOL ref - https://sendgrid.com/en-us/blog/perfecting-your-email-preview-text
    const previewElement = document.createElement('span');

    previewElement.classList.add('preheader');
    previewElement.setAttribute('id', 'preheader-text');
    previewElement.setAttribute(
        'style',
        'color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;',
    );
    previewElement.innerHTML = preheader;
    doc.querySelector('body')?.insertAdjacentElement(
        'afterbegin',
        previewElement,
    );

    let htmlString = doc.documentElement.outerHTML;

    // Restore the original '&' after serialization
    htmlString = htmlString.replace(new RegExp(PLACEHOLDER, 'g'), '&');

    console.log(3333, htmlString);

    return htmlString;
};

export const parseHtmlVariables = ({
    html,
    variables,
}: {
    html: string;
    variables: Record<string, unknown> | undefined;
}) => {
    let parsedHtml = html;

    if (
        !variables ||
        !variables[ContentMappingSection.BODY] ||
        !Object.keys(variables).length
    )
        return html;

    Object.entries(variables[ContentMappingSection.BODY]).forEach(
        ([key, value]) => {
            parsedHtml = parsedHtml.replaceAll(`{{${key}}}`, value as string);
        },
    );

    return parsedHtml;
};

export const getFileSizeErrorMsg = (type: WAHeaderType) => {
    switch (type) {
        case WAHeaderType.FILE:
            return translate('campaign_builder.setup.wa_file_error_msg', {
                size: 100,
            });
        case WAHeaderType.IMAGE:
            return translate('campaign_builder.setup.wa_file_error_msg', {
                size: 5,
            });
        case WAHeaderType.VIDEO:
            return translate('campaign_builder.setup.wa_file_error_msg', {
                size: 16,
            });
        default:
            return '';
    }
};
