import MultiSelect from '@components/common/Select/MultiSelect';
import { Flex, Text, type MultiSelectValueProps } from '@mantine/core';
import { X } from '@phosphor-icons/react';
import React from 'react';

type TestRecipientsInputProps = {
    data: any;
    value: string[];
    onChange: (value: string[]) => void;
    onCreate: (payload: { label: string; value: string }) => void;
};

const ValueComponent = (props: MultiSelectValueProps & { value: string }) => {
    return (
        <Flex
            align={'center'}
            gap={4}
            className="px-2 py-1 m-[2px] border-base rounded-[5px] bg-gray-100"
        >
            <Text className="text-gray-800">
                {props.label}
                {props.label !== props.value && (
                    <span className="text-gray-600">{` (${props.value})`}</span>
                )}
            </Text>
            <X
                size={12}
                weight="regular"
                color="rgb(var(--color-gray-700))"
                className="cursor-pointer"
                onClick={props.onRemove}
            />
        </Flex>
    );
};
const TestRecipientsInput = ({
    data,
    value,
    onChange,
    onCreate,
}: TestRecipientsInputProps) => {
    return (
        <MultiSelect
            data={data}
            creatable
            searchable
            valueComponent={ValueComponent}
            value={value}
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
                const item = { value: query, label: query };
                onCreate(item);
                return item;
            }}
            onChange={onChange}
            label="Test recipients"
            sx={() => ({
                '& .mantine-MultiSelect-values': {
                    maxHeight: 100,
                    overflowY: 'scroll',
                },
            })}
        />
    );
};

export default TestRecipientsInput;
