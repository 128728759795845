import { useLocale } from '@hooks/useLocale';
import { DimensionType, type FileTableColumn } from '@lightdash/common';
import { Box, Button, Menu, Switch } from '@mantine/core';
import { CaretDown } from '@phosphor-icons/react';
import useSchemaContext from '@providers/Schema/useSchemaContext';
import { type ColumnDef } from '@tanstack/react-table';
import { capitalize, type DebouncedFunc } from 'lodash';
import { useEffect, useState } from 'react';
import { InputBox } from '.';
import { ButtonVariant } from '../../../../../mantineTheme';
import { SchemaFileType } from '../../types';

interface SchemaTableProps {
    onToggle: (data: any, field: string) => Promise<void>;
    handleChange: DebouncedFunc<
        (row: any, key: any, value: any) => Promise<void>
    >;
    handleUpdate: (data: { name: string; dataType: string }) => Promise<void>;
    schemaFileType: string;
}

export function useSchemaTableColumns({
    onToggle,
    handleChange,
    handleUpdate,
    schemaFileType,
}: SchemaTableProps) {
    const { schemaPayload } = useSchemaContext((context) => context.state);
    const [columns, setColumns] = useState<ColumnDef<FileTableColumn>[]>();
    const { t } = useLocale();

    useEffect(() => {
        setColumns([
            {
                accessorKey: '',
                header: '-',
                cell: ({ row }) => (
                    <Box className="">
                        <Switch
                            size="xs"
                            defaultChecked={!row?.original?.hidden}
                            onChange={async (event) => {
                                row.original.hidden =
                                    !event.currentTarget.checked;
                                await onToggle(
                                    {
                                        ...row.original,
                                        hidden: !event.currentTarget.checked,
                                    },
                                    'hidden',
                                );
                            }}
                            styles={(_params) => ({
                                track: {
                                    width: '1.5rem !important',
                                },
                            })}
                        />
                    </Box>
                ),
            },
            {
                accessorKey: 'name',
                header: t('schema_builder.table_properties.columns'),
                cell: ({ row }) => (
                    <Box className="">{row?.original?.name}</Box>
                ),
            },
            ...(schemaFileType === SchemaFileType.CSV
                ? [
                      {
                          accessorKey: 'dataType',
                          header: t('schema_builder.table_properties.type'),
                          cell: ({
                              row,
                          }: {
                              row: {
                                  original: {
                                      name: string;
                                      dataType: string;
                                  };
                              };
                          }) => (
                              <Menu>
                                  <Menu.Target>
                                      <Button
                                          variant={ButtonVariant.TRANSPARENT}
                                          styles={(_params) => ({
                                              root: {
                                                  ':hover': {
                                                      backgroundColor:
                                                          'rgb(var(--color-white)) !important',
                                                  },
                                              },
                                          })}
                                          rightIcon={<CaretDown />}
                                          className="!pl-0 w-32 flex justify-start"
                                      >
                                          {capitalize(row?.original?.dataType)}
                                      </Button>
                                  </Menu.Target>
                                  <Menu.Dropdown>
                                      {Object.values(DimensionType).map(
                                          (type) => (
                                              <Menu.Item
                                                  key={type}
                                                  onClick={async () => {
                                                      await handleUpdate({
                                                          name: row.original
                                                              .name,
                                                          dataType: type,
                                                      });
                                                  }}
                                              >
                                                  {capitalize(type)}
                                              </Menu.Item>
                                          ),
                                      )}
                                  </Menu.Dropdown>
                              </Menu>
                          ),
                      },
                  ]
                : []),
            {
                accessorKey: 'label',
                header: t('schema_builder.table_properties.label'),
                cell: ({ row }) => (
                    <Box className="w-40" key={row.id}>
                        <InputBox
                            defaultValue={row?.original?.label || ''}
                            onChange={(value: string) => {
                                void handleChange(row.original, 'label', value);

                                row.original.label = value;
                            }}
                            width={100}
                            placeholder={t('common.click_to_add', {
                                value: 'label',
                            })}
                        />
                    </Box>
                ),
            },
            {
                accessorKey: '',
                header: t('schema_builder.table_properties.description'),
                cell: ({ row }) => (
                    <Box className="w-40" key={row.id}>
                        <InputBox
                            defaultValue={row?.original?.description || ''}
                            onChange={(value: string) => {
                                void handleChange(
                                    row.original,
                                    'description',
                                    value,
                                );

                                row.original.description = value;
                            }}
                            placeholder={t('common.click_to_add', {
                                value: 'description',
                            })}
                        />
                    </Box>
                ),
            },
            {
                accessorKey: '',
                header: t('schema_builder.table_properties.masked'),
                cell: ({ row }) => (
                    <Box className="">
                        <Switch
                            size="xs"
                            defaultChecked={row?.original?.masked}
                            disabled={
                                row?.original?.hidden || row?.original?.cached
                            }
                            onChange={async (event) => {
                                row.original.masked =
                                    event.currentTarget.checked;
                                await onToggle(
                                    {
                                        ...row.original,
                                        masked: event.currentTarget.checked,
                                    },
                                    'masked',
                                );
                            }}
                            styles={(_params) => ({
                                track: {
                                    width: '1.5rem !important',
                                },
                            })}
                        />{' '}
                    </Box>
                ),
            },
            {
                accessorKey: '',
                header: t('schema_builder.table_properties.cached'),
                cell: ({ row }) => (
                    <Box className="">
                        <Switch
                            size="xs"
                            defaultChecked={row?.original?.cached}
                            disabled={
                                row?.original?.hidden || row?.original?.masked
                            }
                            onChange={async (event) => {
                                row.original.cached =
                                    event.currentTarget.checked;
                                await onToggle(
                                    {
                                        ...row.original,
                                        cached: event.currentTarget.checked,
                                    },
                                    'cached',
                                );
                            }}
                            styles={(_params) => ({
                                track: {
                                    width: '1.5rem !important',
                                },
                            })}
                        />{' '}
                    </Box>
                ),
            },
            ...(schemaFileType === SchemaFileType.CSV
                ? [
                      {
                          accessorKey: 'nullable',
                          header: t('schema_builder.table_properties.nullable'),
                          cell: ({
                              row,
                          }: {
                              row: { original: { nullable: boolean } };
                          }) => (
                              <Box>
                                  <Switch
                                      size="xs"
                                      defaultChecked={row?.original?.nullable}
                                      onChange={async (event) => {
                                          row.original.nullable =
                                              event.currentTarget.checked;
                                          await onToggle(
                                              {
                                                  ...row.original,
                                                  nullable:
                                                      event.currentTarget
                                                          .checked,
                                              },
                                              'nullable',
                                          );
                                      }}
                                      styles={(_params) => ({
                                          track: {
                                              width: '1.5rem !important',
                                          },
                                      })}
                                  />{' '}
                              </Box>
                          ),
                      },
                  ]
                : []),
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schemaPayload]);

    return columns;
}
