import { Flex, Text } from '@mantine/core';
import React from 'react';

type SectionHeaderProps = {
    index: number;
    label: string;
};
const SectionHeader = ({ index, label }: SectionHeaderProps) => {
    return (
        <Flex gap={6} className="mb-6">
            <Flex
                className="bg-gray-100 rounded-[50%] p-2 h-5 w-5"
                justify={'center'}
                align={'center'}
            >
                <Text className="text-gray-600">{index}</Text>
            </Flex>
            <Text className="font-medium text-gray-600">{label}</Text>
        </Flex>
    );
};

export default SectionHeader;
