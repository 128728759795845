import {
    FilterOperator,
    getEventTableName,
    getSortmentWarehouseName,
    ReservedEventColumns,
    type WarehouseTypes,
} from '@lightdash/common';
import { format } from 'date-fns';
import { t as translate } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

export enum ProfilesActivityAndEngagementTabs {
    EVENT_HISTORY = 'event_history',
    ENGAGEMENT = 'engagement',
}

/**
 * @param timestamp - ISO 8601 timestamp
 * @returns formatted time string
 */
export const getFormattedTime = (timestamp: string) => {
    return new Date(timestamp).toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    });
};

/**
 * @param dateString - Date string in 'DD/MM/YYYY' format
 * @returns formatted date string
 */
export const formatDate = (date: Date) => {
    if (isNaN(date.getTime())) {
        return 'Invalid Date';
    }

    const today = new Date();
    const isToday = date.toDateString() === today.toDateString();

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const isYesterday = date.toDateString() === yesterday.toDateString();

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const isTomorrow = date.toDateString() === tomorrow.toDateString();

    const formattedDate = format(date, 'd MMM yyyy');

    if (isToday) {
        return `${translate('common.today')}, ${formattedDate}`;
    } else if (isYesterday) {
        return `${translate('common.yesterday')}, ${formattedDate}`;
    } else if (isTomorrow) {
        return `${translate('common.tomorrow')}, ${formattedDate}`;
    } else {
        return formattedDate;
    }
};

/**
 * @param projectUuid
 * @returns filters for user history
 */
export const getUserHistoryFilter = (
    projectUuid: string | undefined,
    userId: string | undefined,
    warehouseType: WarehouseTypes,
    searchQuery: string = '',
    startTimestamp?: string,
    endTimestamp?: string,
) => {
    if (!projectUuid || !userId) return null;

    const filters = [
        {
            id: uuidv4(),
            target: {
                fieldId: `${getEventTableName(
                    projectUuid,
                    warehouseType,
                )}_${getSortmentWarehouseName(
                    ReservedEventColumns.USER_ID,
                    warehouseType,
                )}`,
            },
            operator: FilterOperator.EQUALS,
            values: [userId],
        },
    ];

    if (searchQuery) {
        filters.push({
            id: uuidv4(),
            target: {
                fieldId: `${getEventTableName(
                    projectUuid,
                    warehouseType,
                )}_${getSortmentWarehouseName(
                    ReservedEventColumns.EVENT_NAME,
                    warehouseType,
                )}`,
            },
            operator: FilterOperator.INCLUDE,
            values: [searchQuery],
        });
    }

    if (startTimestamp) {
        filters.push({
            id: uuidv4(),
            target: {
                fieldId: `${getEventTableName(
                    projectUuid,
                    warehouseType,
                )}_${getSortmentWarehouseName(
                    ReservedEventColumns.SERVER_TIMESTAMP,
                    warehouseType,
                )}`,
            },
            operator: FilterOperator.GREATER_THAN_OR_EQUAL,
            values: [startTimestamp],
        });
    }

    if (endTimestamp) {
        filters.push({
            id: uuidv4(),
            target: {
                fieldId: `${getEventTableName(
                    projectUuid,
                    warehouseType,
                )}_${getSortmentWarehouseName(
                    ReservedEventColumns.SERVER_TIMESTAMP,
                    warehouseType,
                )}`,
            },
            operator: FilterOperator.LESS_THAN_OR_EQUAL,
            values: [endTimestamp],
        });
    }

    return {
        dimensions: {
            id: uuidv4(),
            and: filters,
        },
    };
};

export const OUTER_CONTAINER_CLASS =
    'border border-shade-4 rounded-2xl p-0.5 bg-white';
export const INNER_CONTAINER_CLASS =
    'w-full p-0 bg-white border rounded-xl border-gray-200 ';
