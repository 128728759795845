import {
    FieldType,
    type AdditionalMetric,
    type CustomDimension,
    type FilterGroup,
    type FilterGroupItem,
} from '@lightdash/common';

import { addFieldIdToMetricFilterRule } from '@components/Explorer/CustomMetricModal/utils';
import { type CustomAttributeReducerState } from '@providers/CustomAttribute/types';
import { useMemo } from 'react';
import {
    getDefinitionAndName,
    type CustomAttributeInitialDataProps,
} from './utils';

export const useCustomAttributeInitialData = ({
    customAttributeData,
    relationUuid,
    activeRelation,
    fieldsWithSuggestions,
    isEditMode,
    isDuplicateMode,
}: CustomAttributeInitialDataProps): CustomAttributeReducerState => {
    const metricSelectedTableName = (
        customAttributeData?.definition as AdditionalMetric
    )?.table;
    const metricSelectedDimension = (
        customAttributeData?.definition as AdditionalMetric
    )?.baseDimensionName;
    const fieldType = customAttributeData?.type || FieldType.METRIC;
    const { definition, name } = getDefinitionAndName({
        customAttributeData,
        isDuplicateMode,
        fieldType,
        activeRelation,
        fieldsWithSuggestions,
    });

    return useMemo(() => {
        const srcTable =
            customAttributeData?.srcTable || activeRelation?.baseTable || '';

        return {
            customAttributePayload: {
                name: name,
                description: customAttributeData?.description || '',
                relationUuid: relationUuid,
                srcTable: srcTable,
                definition: definition as AdditionalMetric | CustomDimension,
                type: fieldType,
                isActive: customAttributeData?.isActive || true,
                groupByColumn: customAttributeData?.groupByColumn || null,
                tags: customAttributeData?.tags || undefined,
            },
            initialCustomAttributePayload: {
                name: name,
                description: customAttributeData?.description || '',
                relationUuid: relationUuid,
                srcTable: srcTable,
                definition: definition as AdditionalMetric | CustomDimension,
                type: fieldType,
                isActive: customAttributeData?.isActive || true,
                groupByColumn: customAttributeData?.groupByColumn || null,
                tags: customAttributeData?.tags || undefined,
            },
            selectedTable: customAttributeData?.srcTable
                ? activeRelation?.tables[customAttributeData.srcTable]
                : activeRelation?.tables[activeRelation.baseTable],
            selectedDimension:
                fieldsWithSuggestions[
                    `${metricSelectedTableName}_${metricSelectedDimension?.replaceAll(
                        '.',
                        '__',
                    )}`
                ],
            filters: (customAttributeData?.definition as AdditionalMetric)
                ?.filters
                ? {
                      dimensions: {
                          and: (
                              customAttributeData?.definition as AdditionalMetric
                          )?.filters?.map(
                              addFieldIdToMetricFilterRule,
                          ) as FilterGroupItem[],
                      } as FilterGroup,
                  }
                : undefined,
            fieldType,
            isEditMode: isEditMode,
        };
    }, [
        customAttributeData,
        relationUuid,
        activeRelation,
        fieldsWithSuggestions,
        metricSelectedDimension,
        metricSelectedTableName,
        isEditMode,
        fieldType,
        definition,
        name,
    ]);
};
