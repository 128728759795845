import { type SegmentedTimeFilterOptions } from '@components/common/SegmentedTimeFilter/types';
import TimeFilterMenu from '@components/common/TimeFilterMenu';
import Manager from '@components/Manager';
import { useLocale } from '@hooks/useLocale';
import { useProjectAccessWithLinkInfo } from '@hooks/useProjectAccess';
import { useSortedUsers } from '@hooks/useSortedUsers';
import {
    ShowDataType,
    type AuditLogEntryInDb,
    type EntityType,
    type Pagination,
    type ProjectMemberProfileWithInviteInformation,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import { type SortingState } from '@tanstack/react-table';
import React, {
    useCallback,
    useState,
    type Dispatch,
    type SetStateAction,
} from 'react';
import { useParams } from 'react-router';
import AuditLogsResourceType from '../AuditLogsResourceType';
import AuditLogsUser from '../AuditLogsUser';
import { useAuditLogsColumns } from './useAuditLogsTableUtils';

type AuditLogsTableProps = {
    auditLogs: AuditLogEntryInDb[] | null | undefined;
    pagination: Pagination;
    onPageChange: (page: number) => void;
    onSearchChange: (newSearchString: string) => void;
    searchValue: string;
    onUserChange: (newUser: string) => void;
    selectedUser: string;
    onResourceTypeChange: (newResourceType: EntityType | null) => void;
    resourceType: EntityType | null;
    onTimeFilterChange: (newTimeFilter: {
        startTimestamp?: number;
        endTimestamp?: number;
    }) => void;
    isDataFetching: boolean;
    selectedFilter: SegmentedTimeFilterOptions | undefined;
    onFilterChange: (newFilter: SegmentedTimeFilterOptions | undefined) => void;
    sorting: SortingState;
    setSorting: Dispatch<SetStateAction<SortingState>>;
};

const AuditLogsTable: React.FC<AuditLogsTableProps> = ({
    auditLogs,
    pagination,
    onPageChange,
    onSearchChange,
    searchValue,
    onUserChange,
    selectedUser,
    onResourceTypeChange,
    resourceType,
    onTimeFilterChange,
    isDataFetching,
    selectedFilter,
    onFilterChange,
    sorting,
    setSorting,
}) => {
    const auditLogsColumns = useAuditLogsColumns();
    const { t } = useLocale();
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();

    const [isNeedToRefresh, setIsNeedToRefresh] = useState<boolean>(true);

    const { data: projectAccess } = useProjectAccessWithLinkInfo(projectUuid);
    const { sortedUsers } =
        useSortedUsers<ProjectMemberProfileWithInviteInformation>(
            projectAccess,
        );

    const handleMenuClick = useCallback(() => {
        setIsNeedToRefresh(true);
        onPageChange(1);
    }, [onPageChange, setIsNeedToRefresh]);

    const handleTimeFilterChange = useCallback(
        (
            newTimeFilter: { startTimestamp?: number; endTimestamp?: number },
            newFilter?: SegmentedTimeFilterOptions,
        ) => {
            onTimeFilterChange(newTimeFilter);
            onFilterChange(newFilter);
            handleMenuClick();
        },
        [onTimeFilterChange, onFilterChange, handleMenuClick],
    );

    return (
        <Box>
            {auditLogs && auditLogsColumns && (
                <Manager<AuditLogEntryInDb>
                    tableData={auditLogs}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: auditLogsColumns,
                        },
                    ]}
                    searchPlaceholder={t('audit_logs.search_placeholder')}
                    paginationText={t('audit_logs.audit_logs')}
                    isManualPagination={true}
                    isDataFetching={isDataFetching}
                    onPageChange={onPageChange}
                    onSearchChange={(value) => {
                        onSearchChange(value);
                        setIsNeedToRefresh(true);
                    }}
                    searchValue={searchValue}
                    pagination={pagination}
                    isEditable={false}
                    leftSection={
                        <Box className="flex gap-1">
                            <AuditLogsUser
                                users={sortedUsers ?? []}
                                selectedUser={selectedUser}
                                onUserChange={(value) => {
                                    onUserChange(value);
                                    handleMenuClick();
                                }}
                            />
                            <AuditLogsResourceType
                                onResourceTypeChange={(value) => {
                                    onResourceTypeChange(value);
                                    handleMenuClick();
                                }}
                                resourceType={resourceType}
                            />
                        </Box>
                    }
                    rightSection={
                        <TimeFilterMenu
                            onTimeFilterChange={handleTimeFilterChange}
                            filter={selectedFilter}
                        />
                    }
                    isNeedToRefresh={isNeedToRefresh}
                    onNeedToRefresh={setIsNeedToRefresh}
                    sorting={sorting}
                    setSorting={(updater) => {
                        setSorting(updater);
                        onPageChange(1);
                        setIsNeedToRefresh(true);
                    }}
                />
            )}
        </Box>
    );
};

export default React.memo(AuditLogsTable);
