import CampaignFailureReasonModal from '@components/Campaigns/CampaignFailureReasonModal';
import Manager from '@components/Manager';
import {
    ShowDataType,
    type CampaignList,
    type ReducedCampaign,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useCallback, useState } from 'react';
import { useViewRunsColumns } from './useViewRunsTableUtils';

interface ViewRunsTableProps {
    viewRuns: Pick<CampaignList, 'data' | 'paginate'> | null | undefined;
    onPageChange: (page: number) => void;
    isDataFetching: boolean;
}

const ViewRunsTable: React.FC<ViewRunsTableProps> = ({
    viewRuns,
    onPageChange,
    isDataFetching,
}) => {
    const [
        showFailureReasonModal,
        { open: failureReasonModalOpen, close: failureReasonModalClose },
    ] = useDisclosure(false);
    const [selectedCampaignId, setSelectedCampaignId] = useState<string>('');

    const handleFailureReasonClick = useCallback(
        (campaignId: string) => {
            setSelectedCampaignId(campaignId);
            failureReasonModalOpen();
        },
        [failureReasonModalOpen],
    );
    const [isNeedToRefresh, setIsNeedToRefresh] = useState(true);
    const viewRunsColumns = useViewRunsColumns(handleFailureReasonClick);

    return (
        <>
            <Box>
                {viewRuns && viewRunsColumns && (
                    <Manager<ReducedCampaign>
                        tableData={viewRuns.data ?? []}
                        options={[
                            {
                                format: ShowDataType.LIST,
                                formatData: viewRunsColumns,
                            },
                        ]}
                        searchPlaceholder={undefined}
                        paginationText={undefined}
                        isManualPagination={true}
                        isDataFetching={isDataFetching}
                        onPageChange={onPageChange}
                        pagination={
                            viewRuns.paginate ?? {
                                total: 0,
                                lastPage: 0,
                                currentPage: 0,
                                perPage: 0,
                                from: 0,
                                to: 0,
                            }
                        }
                        isEditable={false}
                        handleRowClick={undefined}
                        showSearch={false}
                        tableBackgroundColor="bg-white"
                        isNeedToRefresh={isNeedToRefresh}
                        onNeedToRefresh={setIsNeedToRefresh}
                    />
                )}
            </Box>
            <CampaignFailureReasonModal
                campaignId={selectedCampaignId}
                showModal={showFailureReasonModal}
                closeModal={failureReasonModalClose}
            />
        </>
    );
};

export default React.memo(ViewRunsTable);
