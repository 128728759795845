import { type AudiencePreviewPayload } from './audiences';
import { type CampaignStatus } from './CampaignStatus';
import { type CommunicationChannel } from './communications';
import { type PeriodType } from './deliveryControl';
import {
    type CampaignType,
    type FieldType,
    type MaterializationFieldType,
    type Schedule,
} from './field';
import { type CampaignThrottleConfig } from './integrations';
import { type JourneyFilterGroup } from './journeys';
import {
    CLICKED_EVENT_STATUS,
    CONVERSION_EVENT_STATUS,
    DELIVERED_EVENT_STATUS,
    EventStatus,
    OPENED_EVENT_STATUS,
} from './srtAnalytics';
import { type TemplateContentDetails } from './templates';
import { type LightdashUser } from './user';

export enum AudienceType {
    WAREHOUSE = 'WAREHOUSE',
    CSV = 'CSV',
}

export enum TemplateType {
    EXTERNAL = 'EXTERNAL',
    INTERNAL = 'INTERNAL',
}

export type Variable = {
    value: string;
    defaultValue: string;
    allowNull?: boolean;
    type: MaterializationFieldType;
    enableTracking?: boolean;
    calculatedValue?: string; // Added for dynamic alias involving wh fields
};
export type ContentMapping = Record<string, Variable>;

export enum ContentMappingSection {
    HEADER = 'header',
    SUBJECT = 'subject',
    BODY = 'body',
    BUTTONS = 'buttons',
    LINK_TRACKING = 'link_tracking',
    JOURNEYS_METADATA = 'journeys_metadata',
}

export type WAMappings = {
    header?: { [key: string]: Variable };
    body?: { [key: string]: Variable };
    buttons?: { [key: string]: Variable };
};
export type EmailMappings = {
    subject?: { [key: string]: Variable };
    body?: { [key: string]: Variable };
    link_tracking?: { [key: string]: Variable };
};
export type SMSMappings = {
    body?: { [key: string]: Variable };
};
export type JourneysMappings = {
    journeys_metadata?: { [key: string]: Variable };
};

export type ContentMappings = WAMappings &
    EmailMappings &
    SMSMappings &
    JourneysMappings;

export type CampaignConversionConfig = {
    events: {
        id: string;
        eventName: string;
        eventSource: string;
        deadline?: number;
        filterConfig?: JourneyFilterGroup;
        uiConfig?: Partial<{
            timeGranularity: PeriodType;
        }>;
    }[];
};

export type CommunicationDetails = {
    id: string;
    providerId: string;
    eventId: string | undefined;
    runDetails?: CampaignRunDetails[];
};

export type CampaignRunDetails = {
    runAt: number;
    uploadIds: string[];
    audienceCount: number;
    materializedCampaignCount: number;
    ignoredEntityCount: number;
    campaignAudienceIdInternal?: string;
    exclusionsSnapshotName: string;
    deliveryControlRuleLimits: DeliveryControlRuleLimit[];
};

export type DeliveryControlRuleLimit = {
    ruleId: string;
    limit: number;
};

export type TemplateDetails = {
    id: string;
    version?: number | null;
    type: TemplateType;
    integrationId?: string;
};

export type AudienceDetailsWarehouse = {
    audienceId: string;
    audienceType: 'WAREHOUSE';
};

export type CSVUploadDetailsType = {
    uploadId: string;
    fileName: string;
    audienceCount: number;
    columns: Array<string>;
    blobPath: string;
};

export type AudienceDetailsCSV = {
    csvUploadDetails: CSVUploadDetailsType;
    audienceType: 'CSV';
};
export type TrackingParameters = {
    [key: string]: string;
};
export type SendTo = {
    [key: string]: string;
    type: FieldType;
};

export type SenderDetails = {
    name?: string;
    fromEmail?: string;
    replyToEmail?: string;
};

export enum CampaignResponseIncludes {
    CHILDREN_COUNT = 'childrenCount',
    STATUS_COUNT = 'statusCount',
}

export type CampaignFilters = {
    name?: string;
    id?: string;
    status?: string;
    conversionConfigExists?: boolean;
    type?: string;
    audienceType?: AudienceType;
    includesTags?: string;
    excludesTags?: string;
    parentCampaignId?: string;
    isParent?: boolean;
};

export type Campaign = {
    id: string;
    organizationId: string;
    projectId: string;
    name: string;
    description?: string;
    channel: CommunicationChannel;
    status?: CampaignStatus;
    isDeleted?: boolean;
    communicationDetails?: CommunicationDetails;
    audienceId?: string | null;
    audienceType?: 'CSV' | 'WAREHOUSE' | null;
    csvUploadDetails?: CSVUploadDetailsType | null;
    templateDetails?: TemplateDetails;
    sendTo?: SendTo;
    subscriptionGroupId?: string;
    contentMappings?: ContentMappings;
    contentDetails?: TemplateContentDetails;
    schedule?: Schedule;
    campaignTrackingParameters?: TrackingParameters;
    createdBy?: string;
    updatedBy?: string;
    createdAt?: Date | undefined;
    updatedAt?: Date | undefined;
    tags?: string[] | undefined;
    type?: CampaignType;
    parentId?: string;
    senderDetails?: SenderDetails;
    throttleConfig?: CampaignThrottleConfig;
    conversionConfig?: CampaignConversionConfig;
    previewText?: string;
    audienceConfig?: CampaignAudienceConfig;
};

export type CampaignAudienceConfig = {
    totalCount?: number;
    config: {
        in: string[];
        notIn: string[];
    };
};

export type CampaignAudiencePreviewPayload = {
    channel: CommunicationChannel;
    campaignAudienceConfig: CampaignAudienceConfig;
    previewConfig: Pick<
        AudiencePreviewPayload,
        'limit' | 'offset' | 'dimensions' | 'metrics'
    >;
};

export type WarehouseAudienceMetadata = {
    audienceId: string | null;
    audienceName: string | null;
    audienceType: 'WAREHOUSE';
};

export type CSVAudienceMetadata = {
    uploadId: string;
    fileName: string;
    audienceType: 'CSV';
};

export type ReducedCampaign = Partial<
    Omit<Campaign, 'createdBy' | 'updatedBy'>
> & {
    audienceCount?: number;
    audienceName?: string;
    createdBy: Pick<LightdashUser, 'firstName' | 'lastName' | 'userUuid'>;
    updatedBy: Pick<LightdashUser, 'firstName' | 'lastName' | 'userUuid'>;
    uploadId?: string;
};

export type CampaignList = {
    data: ReducedCampaign[] | null;
    paginate?: {
        total?: number;
        lastPage?: number;
        currentPage: number;
        perPage: number;
        from: number;
        to: number;
    };
    stats?: Array<Record<string, number>>;
};

export type ApiCampaign = {
    status: 'ok';
    results: Campaign;
};

export type ApiCampaignList = {
    status: 'ok';
    results: CampaignList;
};

export type ApiCampaignRuns = {
    status: 'ok';
    results: Pick<CampaignList, 'data' | 'paginate'>;
};

export type CampaignCreateRequest = Pick<Campaign, 'name' | 'channel'> &
    Partial<Pick<Campaign, 'description' | 'tags'>>;

export type EstimateAudienceCountPayload = {
    channel: CommunicationChannel;
    campaignAudienceConfig: CampaignAudienceConfig;
};

export type CampaignUpdateRequest = Partial<Campaign>;

export type PublishCampaignRequest = {
    campaignId: string;
};

export type UnpublishCampaignRequest = PublishCampaignRequest;

export type TriggerCampaignRequest = {
    scheduleAt: number;
};

export type CampaignTagsUpdateRequest = {
    tags: string[];
};

export type CampaignStatusResponse = {
    id: string | undefined;
    status: CampaignStatus | undefined;
};

export type CampaignWithAudienceDetails = Campaign &
    Partial<{
        audienceName: string;
        audienceCount: number;
        failureReason: string;
    }>;

export type ExternalCampaignTriggerRequest = Partial<
    Pick<
        Campaign,
        | 'organizationId'
        | 'projectId'
        | 'channel'
        | 'communicationDetails'
        | 'templateDetails'
        | 'sendTo'
        | 'subscriptionGroupId'
        | 'contentDetails'
        | 'contentMappings'
        | 'schedule'
        | 'campaignTrackingParameters'
    >
>;

export type UserDetails = {
    userId: string;
    email?: string;
    phone?: string;
};

export enum CampaignAnalyticsOverviewStatuses {
    PROCESSED = 'Processed',
    OPEN = 'Open',
    DELIVERED = 'Delivered',
    REJECTED = 'Rejected',
    TOTAL_REQUESTS = 'TotalRequests',
    CONVERTED = 'CampaignConverted',
}

export type CampaignAnalyticsOverviewResponse = Partial<
    Record<CampaignAnalyticsOverviewStatuses, number>
>;

export const unsubEndpoint = '/unsubscribe';
export const unsubPreferencesEndpoint = '/subscription-preferences/channel';

export const unsubUrl = `{{site_url}}${unsubEndpoint}?campaign={{campaign_id}}&channel={{campaign_channel}}&projectId={{project_id}}&subGroupId={{subscription_group_id}}&userId={{distinct_id}}`;
export const unsubPreferencesUrl = `{{site_url}}${unsubPreferencesEndpoint}?campaign={{campaign_id}}&channel={{campaign_channel}}&projectId={{project_id}}&subGroupId={{subscription_group_id}}&userId={{distinct_id}}`;

export const BASE_FUNNEL_CHART_TITLE = '1. Base Funnel';

export const BASE_FUNNEL_CHART_ITEMS = [
    DELIVERED_EVENT_STATUS,
    OPENED_EVENT_STATUS,
    CLICKED_EVENT_STATUS,
    CONVERSION_EVENT_STATUS,
];

export const BASE_FUNNEL_CHART_VALUES_MAP = {
    [DELIVERED_EVENT_STATUS]: [
        EventStatus.DELIVERED,
        EventStatus.UNDELIVERED,
        EventStatus.BOUNCE,
    ],
    [OPENED_EVENT_STATUS]: [EventStatus.OPEN, EventStatus.READ],
    [CLICKED_EVENT_STATUS]: [EventStatus.CLICKED],
    [CONVERSION_EVENT_STATUS]: [EventStatus.CONVERTED],
};
export const previewTextVariable = '{{previewText}}';
