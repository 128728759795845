import { sortmentApi } from '@api/index';
import {
    type AIMessageContext,
    type ApiError,
    type SuggestedPrompt,
    type UserMessageFeedback,
} from '@lightdash/common';
import { type UserThreadMapping } from '@lightdash/common/src';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';

const getAiAnalystPrompts = async (
    projectId: string,
    context: AIMessageContext,
) =>
    sortmentApi<SuggestedPrompt[]>({
        url: `/projects/${projectId}/ai/suggested-prompts?context=${context}`,
        method: 'GET',
        body: undefined,
    });

interface UseAiAnalystPromptsProps {
    context: AIMessageContext;
}

export const useAiAnalystPrompts = ({ context }: UseAiAnalystPromptsProps) => {
    const params = useParams<{ projectUuid: string }>();
    const prompts = useQuery<SuggestedPrompt[], ApiError>({
        queryKey: [QueryKeys.GET_AI_ANALYST_PROMPTS, context],
        queryFn: () => getAiAnalystPrompts(params.projectUuid ?? '', context),
        enabled: !!params.projectUuid,
    });
    return prompts;
};

const createAiAnalystThread = async (projectUuid: string) =>
    sortmentApi<UserThreadMapping>({
        url: `/projects/${projectUuid}/ai/user-thread`,
        method: 'POST',
        body: undefined,
    });

export const useAiAnalystThread = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const response = useMutation<UserThreadMapping, ApiError>(
        () => createAiAnalystThread(projectUuid ?? ''),
        {
            mutationKey: [QueryKeys.CREATE_AI_ANALYST_THREAD, projectUuid],
        },
    );

    return response;
};

const updateAiAnalystFeedback = async (
    projectUuid: string,
    payload: Pick<
        UserMessageFeedback,
        'threadId' | 'messageId' | 'sentiment' | 'metadata' | 'traceId'
    >,
) =>
    sortmentApi<UserMessageFeedback>({
        url: `/projects/${projectUuid}/ai/user-message-feedback`,
        method: 'PUT',
        body: JSON.stringify(payload),
    });

export const useAiAnalystFeedback = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const response = useMutation<
        UserMessageFeedback,
        ApiError,
        Pick<
            UserMessageFeedback,
            'threadId' | 'messageId' | 'sentiment' | 'metadata' | 'traceId'
        >
    >(
        (
            payload: Pick<
                UserMessageFeedback,
                'threadId' | 'messageId' | 'sentiment' | 'metadata' | 'traceId'
            >,
        ) => updateAiAnalystFeedback(projectUuid ?? '', payload),
        {
            mutationKey: [QueryKeys.CREATE_AI_ANALYST_FEEDBACK],
        },
    );
    return response;
};
