import {
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from '@components/Audience/Filters/FieldListItem/utils';
import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import {
    type Campaign,
    type ContentMappings,
    type ExternalTemplate,
    type TemplateContentDetails,
} from '@lightdash/common';

export enum CampaignBuilderStep {
    AUDIENCE = 'audience',
    CHANNEL = 'channel',
    CONTENT = 'content',
    CONVERSION_AND_THROTTLING = 'conversion-and-throttling',
    SCHEDULE = 'schedule',
}

export interface CampaignBuilderStepMap {
    key: CampaignBuilderStep;
    label: string;
    component: React.ComponentType;
    pageHeader: string;
}

export enum CampaignContentStep {
    SETUP = 'setup',
    PERSONALISE = 'personalise',
    TRACK = 'track',
    UTM_PARAMETERS = 'utmParameters',
    TEST = 'test',
}

export interface ContentStepComponentProps {
    setActiveContentStep: (
        step: CampaignContentStep,
        currentCampaignPayload: Partial<Campaign> | undefined,
    ) => void;
    activeContentStep: CampaignContentStep;
    templateMetadata: TemplateContentDetails | ExternalTemplate | undefined;
    fields: FieldsWithSuggestions | undefined;
    additionalPropertySelectList:
        | PropertySelectListType<
              FieldWithSelectStatus,
              PropertySelectGroupType
          >[]
        | undefined;
    source: SetupContentSource;
    variablesMappedWithJourneyParams?: ContentMappings;
}

export interface CampaignContentStepMap {
    key: CampaignContentStep;
    label: string;
    component: React.ComponentType<ContentStepComponentProps>;
}

export interface CampaignContentDetails {
    sendAsName: string;
    sendAsEmail: string;
    replyToSameAsSender: boolean;
    emailSubject: string;
    previewText?: string;
}

export type TemplateSetupCompleteArgs = Partial<Campaign>;

export enum CampaignBuilderQueryParams {
    CHANNEL = 'channel',
    TEMPLATE_ID = 'templateId',
}

export enum SetupContentSource {
    CAMPAIGN = 'CAMPAIGN',
    JOURNEY = 'JOURNEY',
    TEMPLATE_DETAILS = 'TEMPLATE_DETAILS',
}
