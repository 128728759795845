import FilterGroupForm from '@components/Audience/Filters/FilterGroupForm';
import {
    categorizeDimensionsAndEvents,
    getUnsavedChartVersion,
} from '@components/Audience/utils';
import { useFilterFields } from '@hooks/useFilterFields';
import {
    type Filters,
    type JourneyFiltersConfig,
    type NestedMetricQueryGroup,
} from '@lightdash/common';
import { Stack } from '@mantine/core';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { sortRelationFields } from '@utils/relation';
import React, { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useJourneyFiltersContext from './JourneyFiltersProvider/useJourneyFiltersContext';

interface WarehouseFiltersGroupProps {
    warehouseFilters: JourneyFiltersConfig['audienceFilters'];
    setFilters: (filters: JourneyFiltersConfig['audienceFilters']) => void;
}

//Info: To render warehouse filters aka audience filters
const WarehouseFiltersGroup: React.FC<WarehouseFiltersGroupProps> = ({
    warehouseFilters,
    setFilters,
}) => {
    const {
        warehouseFieldsMap,
        eventsData,
        audienceFields,
        warehouseRelation,
        journeyEventFields,
        journeyPrimaryTableFields,
    } = useJourneyFiltersContext();
    const { dimensions, metrics } = useFilterFields();

    const { isEditable } = useJourneyBuilderContext((context) => context.state);

    const filters: Filters | undefined = useMemo(() => {
        if (!warehouseFilters) return;

        const unsavedChartVersion = getUnsavedChartVersion(
            warehouseFilters.filterConfig,
        );

        if (!unsavedChartVersion || !unsavedChartVersion.length) return;

        return unsavedChartVersion[0].metricQuery.filters;
    }, [warehouseFilters]);

    const groupFilter = useMemo(
        () =>
            categorizeDimensionsAndEvents(
                filters?.dimensions,
                warehouseFieldsMap,
                eventsData,
            ),
        [filters?.dimensions, warehouseFieldsMap, eventsData],
    );

    const handleOnChange = useCallback(
        (value: Filters) => {
            if (!warehouseFilters) return;
            const unsavedChartVersion = getUnsavedChartVersion(
                warehouseFilters.filterConfig,
            );

            if (!unsavedChartVersion || !unsavedChartVersion.length) return;

            const modifiedUnsavedChartVersion = unsavedChartVersion.map(
                (chartVersion, index) => {
                    if (index === 0) {
                        return {
                            ...chartVersion,
                            metricQuery: {
                                ...chartVersion.metricQuery,
                                filters: value,
                            },
                        };
                    }
                    return chartVersion;
                },
            );

            const metricQueries = modifiedUnsavedChartVersion.map(
                (metric) => metric.metricQuery,
            );

            let modifiedNestedMetricQuery: NestedMetricQueryGroup = {
                id: uuidv4(),
                and: metricQueries,
            };

            setFilters({
                ...warehouseFilters,
                filterConfig: modifiedNestedMetricQuery,
            });
        },
        [warehouseFilters, setFilters],
    );

    if (!warehouseFilters || !filters) return null;

    return (
        <Stack className="gap-0">
            {/* 
                Info: FilterGroupForm to render the dimensions filter rules
            */}
            {filters.dimensions && groupFilter && (
                <FilterGroupForm
                    allowConvertToGroup
                    hideLine
                    hideButtons
                    conditionLabel="dimension"
                    filterGroup={groupFilter}
                    fields={[
                        ...sortRelationFields([
                            ...dimensions,
                            ...metrics,
                            ...eventsData,
                            ...audienceFields,
                        ]),
                        ...journeyEventFields,
                        ...journeyPrimaryTableFields,
                    ]}
                    isEditMode={isEditable}
                    onChange={(value) =>
                        handleOnChange({
                            ...filters,
                            dimensions: value,
                        })
                    }
                    onDelete={() => {
                        setFilters(undefined);
                    }}
                    filters={filters}
                    setFilters={(value) => {
                        handleOnChange(value);
                    }}
                    groupIndex={0}
                    relation={warehouseRelation}
                    customCss="!w-12"
                    additionalMetrics={undefined}
                    setAdditionalMetrics={undefined}
                    dynamicFieldValues={[]}
                />
            )}

            {/* 
                Info: FilterGroupForm to render the metrics filter rules
            */}
            {filters.metrics && (
                <FilterGroupForm
                    allowConvertToGroup
                    hideLine
                    hideButtons
                    conditionLabel="metric"
                    filterGroup={filters.metrics}
                    fields={metrics}
                    isEditMode={isEditable}
                    onChange={(value) =>
                        handleOnChange({
                            ...filters,
                            metrics: value,
                        })
                    }
                    onDelete={() => {
                        setFilters(undefined);
                    }}
                    filters={filters}
                    setFilters={(value) => {
                        handleOnChange(value);
                    }}
                    groupIndex={0}
                    relation={warehouseRelation}
                    customCss="!w-12"
                    additionalMetrics={undefined}
                    setAdditionalMetrics={undefined}
                    dynamicFieldValues={[]}
                />
            )}
        </Stack>
    );
};

export default WarehouseFiltersGroup;
