import { useLocale } from '@hooks/useLocale';
import { Box, Flex, Loader, Text } from '@mantine/core';
import React from 'react';

const TableLoader = () => {
    const { t } = useLocale();
    return (
        <Box className="flex items-center justify-center my-2">
            <Flex className="p-3.5" align="center">
                <Loader size={11} />
                <Text className="text-sm font-normal text-blu-800 px-1.5">
                    {t('loader.loading')}
                </Text>
            </Flex>
        </Box>
    );
};

export default TableLoader;
