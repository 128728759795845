import FiltersForm from '@components/Audience/Filters';
import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider/FiltersProvider';
import { type FieldValueProperty } from '@components/Audience/Filters/FiltersProvider/types';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { useGetCustomMetrics } from '@hooks/useCustomMetric';
import { useAudiences } from '@hooks/useGetAudience';
import { useGetRelatedOneToManyTables } from '@hooks/useGetRelatedTables';
import {
    AudienceInternalTags,
    AudienceRunStatus,
    AudienceStatus,
    CommonReservedTags,
    FieldType,
    type CustomSqlDimension,
} from '@lightdash/common';
import useAudienceContext from '@providers/Audience/useAudienceContext';
import useProjectContext from '@providers/Project/useProjectContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { memo, useMemo, type FC } from 'react';
import { useParams } from 'react-router';
import { useFieldsWithCampaignEvents } from './useFieldsWithCampaignEvents';
import { useFieldsWithEvents } from './useFieldsWithEvents';
import { useFieldsWithJourneyEvents } from './useFieldsWithJourneyEvents';
import { useFieldsWithSuggestions } from './useFieldsWithSuggestions';

interface FiltersCardProps {
    index: number;
    dynamicFieldValues:
        | PropertySelectListType<FieldValueProperty>[]
        | undefined;
}

const FiltersCard: FC<FiltersCardProps> = memo(
    ({ index, dynamicFieldValues }) => {
        const { projectUuid } = useParams<{ projectUuid: string }>();
        const { activeRelation, activeRelationUuid } = useRelationContext();
        const { projectData } = useProjectContext();

        const { data: fetchedAudience, isLoading: isAudienceLoading } =
            useAudiences({
                query: `status=${AudienceStatus.ACTIVE}&lastRunStatus=${AudienceRunStatus.SUCCESS}&excludesTags=${AudienceInternalTags.INTERNAL},${CommonReservedTags.HIDDEN}`,
                perPage: 9999,
                currentPage: 1,
                polling: false,
            });
        const { data: customMetrics, isLoading: isCustomMetricsLoading } =
            useGetCustomMetrics({
                query: '',
            });

        const isEditMode = useAudienceContext(
            (context) => context.state.isEditMode,
        );

        const filters = useAudienceContext(
            (context) =>
                context.state.unsavedChartVersion[index]?.metricQuery.filters,
        );
        const additionalMetrics = useAudienceContext(
            (context) =>
                context.state.unsavedChartVersion[index].metricQuery
                    .additionalMetrics,
        );

        const customDimensions = useAudienceContext(
            (context) =>
                context.state.unsavedChartVersion[index].metricQuery
                    .customDimensions,
        );
        const customDimensionsFromMetrics = useMemo(() => {
            if (!customMetrics) return undefined;
            return customMetrics
                ?.filter((metric) => metric.type === FieldType.DIMENSION)
                .map((metric) => ({
                    ...metric.definition,
                    tags: metric.tags,
                }));
        }, [customMetrics]);
        const tableCalculations = useAudienceContext(
            (context) =>
                context.state.unsavedChartVersion[index].metricQuery
                    .tableCalculations,
        );
        const queryResults = useAudienceContext(
            (context) => context.queryResults.data,
        );
        const setFilters = useAudienceContext(
            (context) => context.actions.setFilters,
        );
        const setAdditionalMetrics = useAudienceContext(
            (context) => context.actions.setAdditionalMetrics,
        );
        const setCustomDimensions = useAudienceContext(
            (context) => context.actions.setCustomDimensions,
        );
        const { data: eventsData, eventsTableNames } = useFieldsWithEvents({
            relationData: activeRelation,
            activeRelationUuid,
            projectUuid,
        });
        const { internalFynoEvents, isLoading: isCampaignEventsLoading } =
            useFieldsWithCampaignEvents();
        const journeyEvents = useFieldsWithJourneyEvents();
        const fieldsWithSuggestions = useFieldsWithSuggestions({
            relationData: activeRelation,
            queryResults,
            additionalMetrics,
            tableCalculations: tableCalculations,
            customDimensions: customDimensions,
            hideOrphanedTables: true,
        });

        const relatedOneToManyTables = useGetRelatedOneToManyTables(
            activeRelation?.baseTable ?? '',
            activeRelation,
            fieldsWithSuggestions,
        );

        return (
            <FiltersProvider
                projectUuid={projectUuid}
                fieldsMap={fieldsWithSuggestions}
                startOfWeek={
                    projectData?.warehouseConnection?.startOfWeek ?? undefined
                }
                eventsMap={[
                    ...eventsData,
                    ...internalFynoEvents,
                    ...journeyEvents,
                ]}
                eventTables={eventsTableNames}
                audienceData={fetchedAudience?.data ?? []}
                customDimensions={
                    customDimensionsFromMetrics as CustomSqlDimension[]
                }
                isLoading={
                    isAudienceLoading ||
                    isCampaignEventsLoading ||
                    isCustomMetricsLoading
                }
                relatedOneToManyTables={relatedOneToManyTables?.map(
                    (table) => table.name,
                )}
            >
                <FiltersForm
                    isEditMode={isEditMode}
                    filters={filters}
                    setFilters={setFilters}
                    index={index}
                    additionalMetrics={additionalMetrics}
                    setAdditionalMetrics={setAdditionalMetrics}
                    dynamicFieldValues={dynamicFieldValues}
                    customDimensions={
                        customDimensions as CustomSqlDimension[] | undefined
                    }
                    setCustomDimensions={setCustomDimensions}
                />
            </FiltersProvider>
        );
    },
);

export default FiltersCard;
