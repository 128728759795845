import { t as translate } from 'i18next';
import PreviewUser from './PreviewUser';
import VariableDefaults from './VariableDefaults';

export enum TestCommunicationSectionKeys {
    PREVIEW = 'preview',
    DEFAULTS = 'defaults',
    RECIPIENTS = 'recipients',
    CHANNEL_INTEGRATION = 'channel_integration',
}

export const JourneysTestCommunicationSections = [
    {
        label: translate('test_communication.preview_user.title'),
        component: PreviewUser,
        key: TestCommunicationSectionKeys.PREVIEW,
    },
    {
        label: translate('test_communication.variable_defaults.title'),
        component: VariableDefaults,
        key: TestCommunicationSectionKeys.DEFAULTS,
    },
    {
        label: translate('test_communication.recipients.title'),
        key: TestCommunicationSectionKeys.RECIPIENTS,
    },
];

export const CampaignTestCommunicationSections = [
    {
        label: translate('test_communication.preview_user.title'),
        component: PreviewUser,
        key: TestCommunicationSectionKeys.PREVIEW,
    },
    {
        label: translate('test_communication.recipients.title'),
        key: TestCommunicationSectionKeys.RECIPIENTS,
    },
];

export const TemplateTestCommunicationSections = [
    {
        label: translate('test_communication.preview_user.title'),
        component: PreviewUser,
        key: TestCommunicationSectionKeys.PREVIEW,
    },
    {
        label: 'Sending information',
        component: PreviewUser,
        key: TestCommunicationSectionKeys.CHANNEL_INTEGRATION,
    },
    {
        label: translate('test_communication.recipients.title'),
        key: TestCommunicationSectionKeys.RECIPIENTS,
    },
];

export enum TestCommunicationSource {
    CAMPAIGNS = 'CAMPAIGNS',
    JOURNEYS = 'JOURNEYS',
    TEMPLATES = 'TEMPLATES',
}
