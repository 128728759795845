import Select from '@components/common/Select';
// import { useLocale } from '@hooks/useLocale';
import { useAudiences } from '@hooks/useGetAudience';
import {
    AudienceInternalTags,
    AudienceRunStatus,
    AudienceStatus,
    CommonReservedTags,
} from '@lightdash/common';
import { Box, Group, Text } from '@mantine/core';
import { UsersThree, X as DeleteIcon } from '@phosphor-icons/react';
import React from 'react';
import { MESSAGE_TYPE_OPTIONS } from '../types';

type SuppressionRuleProps = {
    index: number;
    onChange: (value: string | null, type: string, index: number) => void;
    value: any;
    onDelete: (index: number) => void;
    readOnly: boolean;
    inputMode: boolean;
    isLastItem: boolean;
};

const SuppressionRule = ({
    index,
    onChange,
    value,
    onDelete,
    readOnly,
    inputMode,
    isLastItem,
}: SuppressionRuleProps) => {
    // const { t } = useLocale();
    const { data } = useAudiences({
        query: `status=${AudienceStatus.ACTIVE}&lastRunStatus=${AudienceRunStatus.SUCCESS}&excludesTags=${CommonReservedTags.HIDDEN},${AudienceInternalTags.INTERNAL}&totalCountGte=1`,
        perPage: 9999,
        currentPage: 1,
        polling: false,
    });

    const handleChange = (val: string | null, key: string, idx: number) => {
        onChange(val, key, idx);
    };
    return (
        <Group
            className={`mt-3 ${
                (!isLastItem || inputMode) && 'border-b pb-3'
            } w-full`}
            position="apart"
            align="center"
        >
            <Group>
                {index !== 0 && (
                    <Box className="p-1 text-xs text-white bg-gray-500 rounded-md">
                        AND
                    </Box>
                )}
                <Text className="text-gray-600">
                    {index === 0 && 'Send'} no
                </Text>
                <Select
                    data={MESSAGE_TYPE_OPTIONS}
                    withinPortal
                    dropdownPosition="top"
                    value={value.channel}
                    onChange={(val) => handleChange(val, 'channel', index)}
                    readOnly={readOnly}
                />
                <Text className="text-gray-600">to users in</Text>
                <Select
                    data={
                        data?.data?.map((item) => ({
                            label: item.name,
                            value: item.id,
                        })) ?? []
                    }
                    withinPortal
                    dropdownPosition="top"
                    value={value.audienceId}
                    onChange={(val) => handleChange(val, 'audienceId', index)}
                    readOnly={readOnly}
                    searchable
                    icon={<UsersThree color="rgb(var(--color-pink-800))" />}
                    maxDropdownHeight={180}
                />
            </Group>
            {inputMode && (
                <Box className="mr-2">
                    <DeleteIcon
                        className="cursor-pointer"
                        onClick={() => onDelete(index)}
                        weight="regular"
                    />
                </Box>
            )}
        </Group>
    );
};

export default SuppressionRule;
