import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import type { FieldValueProperty } from '@components/Audience/Filters/FiltersProvider/types';
import {
    createFilterRuleForCampaignEvent,
    isTimeWindowFilterInMetricFilter,
} from '@components/Audience/Filters/utils';
import {
    campaignEventFieldId,
    campaignEventFieldRef,
} from '@components/Audience/utils';
import type { PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { addFieldIdToMetricFilterRule } from '@components/Explorer/CustomMetricModal/utils';
import {
    CommonEventColumns,
    FilterOperator,
    getFilterRuleWithDefaultValue,
    WarehouseTypes,
    type AdditionalMetric,
    type FilterableDimension,
    type FilterableField,
    type FilterRule,
    type MetricFilterRule,
} from '@lightdash/common';
import { Flex, Stack } from '@mantine/core';
import useProjectContext from '@providers/Project/useProjectContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { SetTimeWindowButton } from '../SetTimeWIndowButton';
import { TimeWindowFilterRule } from '../TimeWindowFilterRule';
import CampaignFilterRuleForm from './CampaignFilterRuleForm';

interface CampaignEventFilterGroupProps {
    activeField: FilterableField | undefined;
    isEditMode: boolean;
    additionalMetrics: AdditionalMetric[];
    setAdditionalMetrics: (
        metrics: AdditionalMetric[],
        isNested: boolean,
        groupIndex: number,
    ) => void;
    tableFields: FilterableDimension[];
    groupIndex: number;
    dynamicFieldValues:
        | PropertySelectListType<FieldValueProperty>[]
        | undefined;
}
const CampaignEventFilterGroup: React.FC<CampaignEventFilterGroupProps> = ({
    activeField,
    isEditMode,
    additionalMetrics,
    setAdditionalMetrics,
    groupIndex,
    tableFields,
    dynamicFieldValues,
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData } = useProjectContext();
    const { activeRelation } = useRelationContext();
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });
    const timeWindowFieldRef = useMemo(
        () =>
            projectUuid &&
            projectData &&
            projectData.warehouseConnection &&
            campaignEventFieldRef(
                projectUuid,
                CommonEventColumns.CLIENT_TIMESTAMP,
                projectData.warehouseConnection.type,
            ),
        [projectUuid, projectData],
    );

    const isMatchingMetric = useCallback(
        (metric: AdditionalMetric): boolean => {
            return (
                metric.name === activeField?.name &&
                metric.table === activeField?.table
            );
        },
        [activeField],
    );
    const matchedAdditionalMetric = additionalMetrics.find((metric) =>
        isMatchingMetric(metric),
    );
    const timeStampField = useMemo(() => {
        const timeStampId = campaignEventFieldId(
            projectUuid ?? '',
            CommonEventColumns.CLIENT_TIMESTAMP,
            projectData?.warehouseConnection?.type ?? WarehouseTypes.SNOWFLAKE,
        );
        return fieldsWithSuggestions[timeStampId];
    }, [projectUuid, projectData, fieldsWithSuggestions]);
    // Helper function to check if a filter is a time window filter

    const campaignFilters = useMemo(
        () =>
            matchedAdditionalMetric?.filters
                ?.slice(2)
                .filter(
                    (filter) =>
                        timeWindowFieldRef &&
                        !isTimeWindowFilterInMetricFilter(
                            filter,
                            timeWindowFieldRef,
                        ),
                )
                .map(addFieldIdToMetricFilterRule),
        [matchedAdditionalMetric, timeWindowFieldRef],
    );

    const timeWindowFilter = useMemo(
        () =>
            matchedAdditionalMetric?.filters
                ?.slice(2)
                .find(
                    (filter) =>
                        timeWindowFieldRef &&
                        isTimeWindowFilterInMetricFilter(
                            filter,
                            timeWindowFieldRef,
                        ),
                ),
        [matchedAdditionalMetric, timeWindowFieldRef],
    );

    // Handlers
    const changedAdditionalMetrics = useCallback(
        (filters: MetricFilterRule[]) => {
            const newAdditionalMetrics = additionalMetrics.map((metric) =>
                isMatchingMetric(metric) ? { ...metric, filters } : metric,
            );
            setAdditionalMetrics(newAdditionalMetrics, false, groupIndex);
        },
        [additionalMetrics, groupIndex, setAdditionalMetrics, isMatchingMetric],
    );

    const handleSetTimeWindow = useCallback(() => {
        if (
            !projectUuid ||
            !projectData ||
            !projectData.warehouseConnection ||
            !timeStampField
        )
            return;
        const newTimeWindowFilter = getFilterRuleWithDefaultValue(
            timeStampField,
            createFilterRuleForCampaignEvent({
                dimension: CommonEventColumns.CLIENT_TIMESTAMP,
                projectUuid: projectUuid ?? '',
                operator: FilterOperator.EQUALS,
                value: '',
                warehouseType: projectData.warehouseConnection.type,
            }),
            undefined,
        );

        const newFilters = [
            ...(matchedAdditionalMetric?.filters ?? []),
            {
                ...newTimeWindowFilter,
                target: {
                    fieldRef: campaignEventFieldRef(
                        projectUuid ?? '',
                        CommonEventColumns.CLIENT_TIMESTAMP,
                        projectData.warehouseConnection.type,
                    ),
                },
            },
        ];
        changedAdditionalMetrics(newFilters);
    }, [
        projectUuid,
        changedAdditionalMetrics,
        matchedAdditionalMetric?.filters,
        projectData,
        timeStampField,
    ]);

    const handleTimeWindowFilterDelete = useCallback(() => {
        const filteredMetrics = (matchedAdditionalMetric?.filters ?? []).filter(
            (filter) =>
                timeWindowFieldRef &&
                !isTimeWindowFilterInMetricFilter(filter, timeWindowFieldRef),
        );
        changedAdditionalMetrics(filteredMetrics);
    }, [
        matchedAdditionalMetric?.filters,
        changedAdditionalMetrics,
        timeWindowFieldRef,
    ]);

    const handleTimeWindowFilterChange = useCallback(
        (value: FilterRule) => {
            if (
                !projectUuid ||
                !projectData ||
                !projectData.warehouseConnection
            )
                return;
            const updatedFilters = (matchedAdditionalMetric?.filters ?? []).map(
                (filter) =>
                    timeWindowFieldRef &&
                    isTimeWindowFilterInMetricFilter(
                        filter,
                        timeWindowFieldRef,
                    ) &&
                    projectData &&
                    projectData.warehouseConnection
                        ? {
                              ...value,
                              target: {
                                  ...value.target,
                                  fieldRef: campaignEventFieldRef(
                                      projectUuid ?? '',
                                      CommonEventColumns.CLIENT_TIMESTAMP,
                                      projectData.warehouseConnection.type,
                                  ),
                              },
                          }
                        : filter,
            );
            changedAdditionalMetrics(updatedFilters);
        },
        [
            projectUuid,
            matchedAdditionalMetric?.filters,
            changedAdditionalMetrics,
            timeWindowFieldRef,
            projectData,
        ],
    );

    // Render helper components
    const renderCampaignFilterForm = useMemo(() => {
        if (!activeField) return null;
        return (
            <CampaignFilterRuleForm
                campaignFilters={campaignFilters ?? []}
                activeField={activeField}
                isEditMode={isEditMode}
                additionalMetrics={additionalMetrics}
                setAdditionalMetrics={setAdditionalMetrics}
                groupIndex={groupIndex}
            />
        );
    }, [
        campaignFilters,
        activeField,
        isEditMode,
        additionalMetrics,
        setAdditionalMetrics,
        groupIndex,
    ]);

    const renderTimeWindowButton = useMemo(
        () => <SetTimeWindowButton onClick={handleSetTimeWindow} />,
        [handleSetTimeWindow],
    );
    const renderTimeWindowFilter = useMemo(() => {
        return (
            <TimeWindowFilterRule
                showWherePrefix={!campaignFilters?.length}
                timeWindowFilter={addFieldIdToMetricFilterRule(
                    timeWindowFilter!,
                )}
                fields={tableFields}
                handleTimeWindowFilterChange={handleTimeWindowFilterChange}
                handleTimeWindowFilterDelete={handleTimeWindowFilterDelete}
                isEditMode={isEditMode}
                groupIndex={groupIndex}
                additionalMetrics={additionalMetrics}
                dynamicFieldValues={dynamicFieldValues}
            />
        );
    }, [
        timeWindowFilter,
        tableFields,
        isEditMode,
        handleTimeWindowFilterChange,
        handleTimeWindowFilterDelete,
        groupIndex,
        additionalMetrics,
        campaignFilters,
        dynamicFieldValues,
    ]);
    if (!activeField) return null;

    return (
        <Stack className="gap-2 pl-4 mb-4 ml-4 border-l-4 border-shade-4">
            {(campaignFilters?.length ?? 0) > 0 && (
                <>
                    <Flex align="center" gap={8}>
                        {renderCampaignFilterForm}
                    </Flex>
                    {!timeWindowFilter && renderTimeWindowButton}
                </>
            )}

            {timeWindowFilter && renderTimeWindowFilter}

            {(campaignFilters?.length ?? 0) === 0 && (
                <Flex align="center" gap={8}>
                    {renderCampaignFilterForm}
                    {!timeWindowFilter && renderTimeWindowButton}
                </Flex>
            )}
        </Stack>
    );
};

export default CampaignEventFilterGroup;
