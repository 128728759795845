import { useLocale } from '@hooks/useLocale';
import { AudienceType } from '@lightdash/common';
import { Flex, Text } from '@mantine/core';
import { X } from '@phosphor-icons/react';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import React from 'react';

const UploadAudienceDetails = () => {
    const { t } = useLocale();

    const { state, actions } = useCampaignContext((context) => context);

    const { campaignPayload } = state;
    const { setAudienceCsvUploadData, setCampaignType } = actions;
    return (
        <Flex justify={'space-between'} className="border-base p-3 m-9">
            <Text>
                <span className="text-gray-600">
                    {t('campaign_builder.audience.uploaded_static_audience')}:
                </span>{' '}
                {campaignPayload.csvUploadDetails?.fileName}
            </Text>
            <X
                weight="regular"
                color="rgb(var(--color-gray-700))"
                className="cursor-pointer"
                onClick={() => {
                    setAudienceCsvUploadData(undefined);
                    setCampaignType(AudienceType.WAREHOUSE);
                }}
            />
        </Flex>
    );
};

export default UploadAudienceDetails;
