import { useLocale } from '@hooks/useLocale';
import useUser from '@hooks/user/useUser';
import useSorte from '@hooks/useSorte';
import { Box, Text } from '@mantine/core';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';
import ChatHeader from './ChatHeader';
import { Messages } from './Messages';
import { MultimodalInput } from './MultimodalInput';
import { SuggestedActions } from './SuggestedActions';

export function Chat() {
    const { aiChatMessages } = useAiAnalystContext((context) => context.state);
    const { threadId, showAiAnalyst } = useAiAnalystContext(
        (context) => context.state,
    );

    const { handleSendMessage, isLoading, chatInput, setChatInput, stop } =
        useSorte();

    const { data: user } = useUser(true);

    const { t } = useLocale();

    return (
        <>
            <Box
                className={`flex flex-col h-full ${
                    aiChatMessages.length === 0 ? 'justify-center' : ' '
                }`}
            >
                <ChatHeader setChatInput={setChatInput} stop={stop} />

                {aiChatMessages.length > 0 && (
                    <Messages chatId={threadId} isLoading={isLoading} />
                )}
                <Box
                    className={`flex flex-col  ${
                        aiChatMessages.length === 0 && showAiAnalyst
                            ? 'justify-between h-full'
                            : ''
                    }`}
                >
                    <Box className="flex flex-col w-full gap-2 px-4 pb-4 mx-auto mb-4 md:pb-6 md:max-w-3xl">
                        {aiChatMessages.length === 0 && (
                            <>
                                <Box
                                    className={`flex flex-col ${
                                        showAiAnalyst
                                            ? ''
                                            : 'items-center justify-center'
                                    } h-full mb-6`}
                                >
                                    <Text
                                        className={`${
                                            showAiAnalyst
                                                ? 'text-2xl'
                                                : 'text-5xl'
                                        } text-white font-semibold`}
                                    >
                                        {t('chat.greeting', {
                                            name: user?.firstName,
                                        })}
                                    </Text>
                                    <Text
                                        className={`${
                                            showAiAnalyst
                                                ? 'text-sm'
                                                : 'text-2xl'
                                        } text-white/60`}
                                    >
                                        {t('chat.what_would_you_like_to_do')}
                                    </Text>
                                </Box>
                                <SuggestedActions
                                    handleSendMessage={handleSendMessage}
                                    setChatInput={setChatInput}
                                    threadId={threadId}
                                />
                            </>
                        )}
                    </Box>
                    <form className="flex flex-col w-full gap-2 px-2 pb-4 mx-auto md:pb-2 md:max-w-3xl">
                        <MultimodalInput
                            chatId={threadId}
                            isLoading={isLoading}
                            chatInput={chatInput}
                            setChatInput={setChatInput}
                            handleSendMessage={handleSendMessage}
                            stop={stop}
                        />
                    </form>
                </Box>
            </Box>
        </>
    );
}
