import { type FieldValueProperty } from '@components/Audience/Filters/FiltersProvider/types';
import { getUnsavedChartVersion } from '@components/Audience/utils';
import VisualBuilder from '@components/Audience/VisualBuilder';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { useQueryResults } from '@hooks/useAudienceQueryResults';
import {
    FilterGroupOperator,
    QueryGenerationStrategy,
    type FilterGroupOperatorType,
    type NestedMetricQueryGroup,
} from '@lightdash/common';
import AudienceProvider from '@providers/Audience/AudienceProvider';
import { metricQueryDefaultState } from '@providers/Audience/utils';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React, { useMemo } from 'react';

interface JourneyAudienceFilterProps {
    onUpdate: (metricQuery: NestedMetricQueryGroup) => void;
    metricQuery: NestedMetricQueryGroup | undefined;
    dynamicFieldValues:
        | PropertySelectListType<FieldValueProperty>[]
        | undefined;
}

const JourneyAudienceFilter: React.FC<JourneyAudienceFilterProps> = ({
    onUpdate,
    metricQuery,
    dynamicFieldValues,
}) => {
    const queryResults = useQueryResults();
    const { isEditable } = useJourneyBuilderContext((context) => context.state);

    const groupOperator = useMemo(() => {
        if (metricQuery) {
            const nestedKeys = Object.keys(
                metricQuery as NestedMetricQueryGroup,
            );
            if (nestedKeys.length <= 1) return FilterGroupOperator.and;
            if (nestedKeys[1] !== 'id') return nestedKeys[1];
            return nestedKeys[0];
        }
        return FilterGroupOperator.and;
    }, [metricQuery]);

    return (
        <AudienceProvider
            isEditMode={isEditable}
            queryResults={queryResults}
            initialState={{
                shouldFetchResults: false,
                unsavedChartVersion:
                    getUnsavedChartVersion(metricQuery).length > 0
                        ? getUnsavedChartVersion(metricQuery)
                        : [metricQueryDefaultState],
                modals: {
                    additionalMetric: {
                        isOpen: false,
                    },
                    customDimension: {
                        isOpen: false,
                    },
                },
                globalFilterCondition: groupOperator as FilterGroupOperatorType,

                audiencePayload: {
                    name: '',
                    description: '',
                    generationStrategy:
                        QueryGenerationStrategy.AUDIENCE_BUILDER,
                    sqlQuery: '',
                    userAlias: '',
                    previewConfig: undefined,
                    insights: undefined,
                    ...(metricQuery !== undefined
                        ? {
                              nestedMetricQuery: metricQuery,
                          }
                        : {}),
                    metadata: undefined,
                },
                initialAudiencePayload: {
                    name: '',
                    description: '',
                    generationStrategy:
                        QueryGenerationStrategy.AUDIENCE_BUILDER,
                    sqlQuery: '',
                    userAlias: '',
                    previewConfig: undefined,
                    insights: undefined,
                    ...(metricQuery !== undefined
                        ? {
                              nestedMetricQuery: metricQuery,
                          }
                        : {}),
                    metadata: undefined,
                },
                audienceCount: null,
                initialAudienceCount: 0,
            }}
        >
            <VisualBuilder
                onUpdateMetricQuery={(metricQueryData) => {
                    onUpdate(metricQueryData);
                }}
                isEditMode={isEditable}
                dynamicFieldValues={dynamicFieldValues}
            />
        </AudienceProvider>
    );
};

export default JourneyAudienceFilter;
