import { journeyEventFieldRef } from '@components/Audience/utils';
import {
    FilterOperator,
    JourneyEventNames,
    JourneyStatus,
    ReservedEventColumns,
    type WarehouseTypes,
} from '@lightdash/common';
import {
    JOURNEY_EVENT_JOURNEY_CONVERTED,
    JOURNEY_EVENT_JOURNEY_ENTERED,
    JOURNEY_EVENT_JOURNEY_EXITED,
} from '@utils/constants';
import { t as translate } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

export const timeWindowFieldRefInJourneyEvents = (
    projectUuid: string,
    warehouseType: WarehouseTypes,
) =>
    journeyEventFieldRef(
        projectUuid,
        ReservedEventColumns.SERVER_TIMESTAMP,
        warehouseType,
    );
interface CreateFilterRuleForJourneyEventProps {
    dimension: string;
    value: string;
    projectUuid: string;
    operator: FilterOperator;
    warehouseType: WarehouseTypes;
}

export const createFilterRuleForJourneyEvent = ({
    dimension,
    value,
    projectUuid,
    operator,
    warehouseType,
}: CreateFilterRuleForJourneyEventProps) => {
    return {
        id: uuidv4(),
        target: {
            fieldId: journeyEventFieldRef(
                projectUuid,
                dimension,
                warehouseType,
            ),
        },
        operator,
        values: [value],
    };
};

export const getJourneyEventLabel = (eventName: JourneyEventNames) => {
    switch (eventName) {
        case JourneyEventNames.JOURNEY_ENTERED:
            return JOURNEY_EVENT_JOURNEY_ENTERED;
        case JourneyEventNames.JOURNEY_EXITED:
            return JOURNEY_EVENT_JOURNEY_EXITED;
        case JourneyEventNames.JOURNEY_CONVERTED:
            return JOURNEY_EVENT_JOURNEY_CONVERTED;
        default:
            return eventName;
    }
};
export const journeyEventFilterOperatorOptions = () => {
    return [
        { label: translate('common.is'), value: FilterOperator.EQUALS },
        { label: translate('common.is_not'), value: FilterOperator.NOT_EQUALS },
    ];
};

export const journeyStatusForJourneyEventFilter = [
    JourneyStatus.ACTIVE,
    JourneyStatus.CANCELED,
];
