import SearchInput from '@components/SearchInput';
import { ShowDataType } from '@lightdash/common';
import { Box, Flex, Group } from '@mantine/core';
import { useCallback } from 'react';
import { Grid, List } from 'react-feather';
import { type ManagerProps } from '../type';

type TopBannerProps<TData, T> = Pick<
    ManagerProps<TData, T>,
    | 'showSearch'
    | 'searchPlaceholder'
    | 'searchValue'
    | 'onSearchChange'
    | 'leftSection'
    | 'rightSection'
    | 'options'
> & {
    onSearchChange: (value: string) => void;
    viewType: ShowDataType;
    setViewType: (viewType: ShowDataType) => void;
};

const TopBanner = <TData, T>({
    showSearch,
    searchPlaceholder,
    searchValue,
    onSearchChange,
    leftSection,
    rightSection,
    options,
    viewType,
    setViewType,
}: TopBannerProps<TData, T>) => {
    const hasListOption = useCallback(
        (type: ShowDataType) => {
            return (
                options.length > 1 &&
                options.some((option) => option.format === type)
            );
        },
        [options],
    );

    return (
        <Box className="flex justify-between w-full pb-2 h-[2.75rem]">
            <Flex align="center" gap={8}>
                {showSearch && (
                    <Box className="w-[15rem]">
                        <SearchInput
                            placeholder={searchPlaceholder}
                            defaultValue={searchValue}
                            onChange={(e) => onSearchChange(e.target.value)}
                            size="sm"
                        />
                    </Box>
                )}
                <Group>{leftSection}</Group>
            </Flex>
            <Flex align="center" gap={4}>
                <Group>{rightSection}</Group>
                <Box className="flex items-center justify-center">
                    {hasListOption(ShowDataType.GRID) && (
                        <Box
                            onClick={() => setViewType(ShowDataType.GRID)}
                            className={`cursor-pointer px-3.5 py-2.5 border border-shade-6 ${
                                viewType === ShowDataType.GRID
                                    ? 'bg-shade-6'
                                    : ''
                            }`}
                        >
                            <Grid
                                className={` h-3.5 w-3.5 ${
                                    viewType === ShowDataType.GRID
                                        ? 'text-gray-800'
                                        : 'text-gray-600'
                                } `}
                            />
                        </Box>
                    )}
                    {hasListOption(ShowDataType.LIST) && (
                        <Box
                            onClick={() => setViewType(ShowDataType.LIST)}
                            className={`cursor-pointer px-3.5 py-2.5 border border-shade-6 ${
                                viewType === ShowDataType.LIST
                                    ? 'bg-shade-6'
                                    : ''
                            } `}
                        >
                            <List
                                className={`  h-3.5 w-3.5 ${
                                    viewType === ShowDataType.LIST
                                        ? 'text-gray-800 '
                                        : 'text-gray-600'
                                } `}
                            />
                        </Box>
                    )}
                </Box>
            </Flex>
        </Box>
    );
};

export default TopBanner;
