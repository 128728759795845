import UserSearch from '@components/UserSearch';
import useContactFieldOptions from '@components/UserSearch/useContactFieldOptions';
import { useAudiencePreviewByPayload } from '@hooks/useAudiencePreview';
import { useLocale } from '@hooks/useLocale';
import { DimensionType, FilterOperator } from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

const PreviewUser = ({
    previewDimensions,
    previewUserCallback,
}: {
    disabled?: boolean;
    previewDimensions: string[];
    previewUserCallback: any;
}) => {
    const { t } = useLocale();
    const { contactFields, activeRelationUuid } = useContactFieldOptions();
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [menuOpened, setMenuOpened] = useState(false);

    const { mutateAsync: mutateAsyncPreview } = useAudiencePreviewByPayload();

    useEffect(() => {
        if (searchValue.length > 0) {
            setMenuOpened(true);
        }
    }, [searchValue]);

    const handleReset = () => {
        setSelectedIndex(-1);
        setMenuOpened(false);
        setSearchValue('');
    };

    const getAudiencePreviewData = useCallback(
        async (index?: number) => {
            const contactFilters =
                searchValue.length > 0 && (index ?? selectedIndex) > -1
                    ? {
                          contactFieldId:
                              contactFields[index ?? selectedIndex].fieldId!,
                          contactValue: searchValue,
                          type: contactFields[index ?? selectedIndex].type,
                      }
                    : undefined;
            // check if search value exists at all
            if (contactFilters) {
                await mutateAsyncPreview(
                    {
                        relationUuid: activeRelationUuid,
                        dimensions: previewDimensions,
                        metrics: [],
                        data: {
                            metricQuery: {
                                id: uuid4(),
                                and: [
                                    {
                                        filters: {
                                            dimensions: {
                                                id: uuid4(),
                                                and: [
                                                    {
                                                        id: uuid4(),
                                                        target: {
                                                            fieldId:
                                                                contactFilters.contactFieldId,
                                                        },
                                                        operator:
                                                            contactFilters?.type ===
                                                            DimensionType.NUMBER
                                                                ? FilterOperator.EQUALS
                                                                : FilterOperator.INCLUDE,
                                                        values: [
                                                            contactFilters.contactValue,
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                        additionalMetrics: [],
                                        dimensions: [],
                                        exploreName: '',
                                        limit: 1,
                                        metrics: [],
                                        sorts: [],
                                        tableCalculations: [],
                                    },
                                ],
                            },
                        },
                        limit: 1,
                    },
                    {
                        onSuccess: (response) => {
                            if (previewUserCallback) {
                                previewUserCallback(response);
                            }
                        },
                    },
                );
            }
        },
        [
            searchValue,
            selectedIndex,
            contactFields,
            mutateAsyncPreview,
            activeRelationUuid,
            previewDimensions,
            previewUserCallback,
        ],
    );

    const handleListItemClick = (index: number) => {
        setSelectedIndex(index);
        setMenuOpened(false);
        void getAudiencePreviewData(index);
    };

    return (
        <Box>
            <Text className="font-semibold text-gray-800 mb-[2px]">
                {t('campaigns_builder.test.test_user_title')}
            </Text>
            <Text className="font-medium text-gray-600 mb-2">
                {t('campaigns_builder.test.test_user_subtitle')}
            </Text>
            <UserSearch
                fields={contactFields}
                searchValue={searchValue}
                setSearchValue={(e) => {
                    setSearchValue(e.target.value);
                }}
                selectedIndex={selectedIndex}
                open={menuOpened}
                handleListItemClick={(index) => handleListItemClick(index)}
                handleReset={handleReset}
            />
        </Box>
    );
};

export default PreviewUser;
