import { Box, Flex, Stack, Switch, Text } from '@mantine/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Container from '../Container';

import { useGetUtmKeys } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { useUpdateTemplateContentDetails } from '@hooks/useTemplate';
import {
    ContentMappingSection,
    type AnyType,
    type ContentMappings,
    type ParsedVariable,
    type TrackingParameters,
} from '@lightdash/common';
import UtmTracking, {
    type UtmTableData,
} from '../../../Campaigns/Builder/Steps/CampaignBuilderContent/UtmTracking/UtmTracking';

const resolveVariable = (variable: string, values: any) => {
    switch (variable) {
        case '{{channel_name}}':
            return values.channel;
        case '{{campaign_name}}':
            return variable;
        default:
            return values.name;
    }
};

type LinkTrackingProps = {
    links: ParsedVariable[] | null | undefined;
    contentMappings: ContentMappings | undefined;
    trackingParameters: TrackingParameters | undefined;
};

const LinkTracking = ({
    links,
    contentMappings,
    trackingParameters,
}: LinkTrackingProps) => {
    const { t } = useLocale();
    const initialRender = useRef<AnyType>(null);
    const { templateId } = useParams<{ templateId: string }>();
    const [isEditing, setIsEditing] = useState(false);
    const [utmTrackingState, setUtmTrackingState] = useState<UtmTableData[]>(
        [],
    );
    const [clickTracking, setClickTracking] = useState<boolean>(false);

    const { data: utmKeys } = useGetUtmKeys();

    const { mutateAsync: updateTemplateContentDetails, isLoading } =
        useUpdateTemplateContentDetails();

    const internalKeys = useMemo(() => {
        if (utmKeys) {
            return utmKeys
                ?.filter((utmKey) => utmKey.type === 'internal')
                .map((utmKey) => utmKey.key);
        }
        return [];
    }, [utmKeys]);

    useEffect(() => {
        if (
            contentMappings &&
            contentMappings[ContentMappingSection.LINK_TRACKING]
        ) {
            if (
                Object.values(
                    contentMappings[ContentMappingSection.LINK_TRACKING],
                ).some((item) => item.enableTracking)
            ) {
                setClickTracking(true);
            }
        }
    }, [contentMappings]);

    useEffect(() => {
        if (!initialRender.current) {
            const data: UtmTableData[] = [];
            if (
                trackingParameters &&
                Object.keys(trackingParameters).length > 0
            ) {
                Object.entries(trackingParameters).forEach(([key, value]) => {
                    const isInternalKey = internalKeys.includes(key);
                    data.push({
                        key: key,
                        value: value,
                        type: isInternalKey ? 'internal' : 'external',
                        required: isInternalKey,
                        error: '',
                    });
                });
                data.sort((a) => (a.type === 'external' ? 1 : -1));
                setUtmTrackingState([...data]);
                initialRender.current = true;
                return;
            }
            if (utmKeys) {
                utmKeys.forEach((utmKey) => {
                    if (utmKey.type === 'internal') {
                        data.push({
                            key: utmKey.key,
                            value: (utmKey.value.type === 'static'
                                ? utmKey.value.value
                                : resolveVariable(
                                      utmKey.value.value,
                                      {},
                                  )) as string,
                            type: utmKey.type,
                            required: utmKey.required,
                            error: '',
                        });
                    }
                });
                setUtmTrackingState([...data]);
                initialRender.current = true;
            }
        }
    }, [internalKeys, trackingParameters, utmKeys]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            if (templateId) {
                await updateTemplateContentDetails({
                    templateId,
                    version: 1,
                    body: {
                        contentMappings: {
                            ...contentMappings,
                            [ContentMappingSection.LINK_TRACKING]:
                                links?.reduce(
                                    (accum, curr) => ({
                                        ...accum,
                                        [curr.key]: {
                                            value: curr.value,
                                            defaultValue: '',
                                            enableTracking: clickTracking,
                                            type: 'static',
                                        },
                                    }),
                                    {},
                                ),
                        },
                        trackingParameters: utmTrackingState.reduce(
                            (accum, curr) => {
                                if (accum) {
                                    return { ...accum, [curr.key]: curr.value };
                                }
                                return { [curr.key]: curr.value };
                            },
                            {},
                        ),
                    },
                });
                setIsEditing(false);
            }
        } catch (error) {}
    };

    const handleCancel = () => {
        setIsEditing(false);
        const data: UtmTableData[] = [];
        if (trackingParameters && Object.keys(trackingParameters).length > 0) {
            Object.entries(trackingParameters).forEach(([key, value]) => {
                const isInternalKey = internalKeys.includes(key);
                data.push({
                    key: key,
                    value: value,
                    type: isInternalKey ? 'internal' : 'external',
                    required: isInternalKey,
                    error: '',
                });
            });
            data.sort((a) => (a.type === 'external' ? 1 : -1));
            setUtmTrackingState([...data]);
        }
        if (
            contentMappings &&
            contentMappings[ContentMappingSection.LINK_TRACKING]
        ) {
            if (
                Object.values(
                    contentMappings[ContentMappingSection.LINK_TRACKING],
                ).some((item) => item.enableTracking)
            ) {
                setClickTracking(true);
            } else {
                setClickTracking(false);
            }
        } else {
            setClickTracking(false);
        }
    };

    const renderContent = useMemo(() => {
        if (isEditing) {
            return (
                <Box>
                    <Text className="text-gray-500 font-medium mb-6">
                        {t('template_details.click_tracking.title')}
                    </Text>
                    <Switch
                        label={t(
                            'template_details.click_tracking.switch_label',
                        )}
                        checked={clickTracking}
                        onChange={(e) => setClickTracking(e.target.checked)}
                    />
                    {clickTracking && (
                        <>
                            <Text className="text-gray-500 font-medium my-6">
                                {t('template_details.utm_parameters.title')}
                            </Text>
                            <UtmTracking
                                data={utmTrackingState}
                                setData={setUtmTrackingState}
                            />
                        </>
                    )}
                </Box>
            );
        }
        return (
            <Stack>
                <Flex align={'center'}>
                    <Text className="text-gray-600 font-semibold uppercase w-[30%]">
                        {t('template_details.click_tracking.title')}
                    </Text>
                    <Text className="text-gray-500">
                        {clickTracking ? 'On' : 'Off'}
                    </Text>
                </Flex>
                <Flex align={'center'}>
                    <Text className="text-gray-600 font-semibold uppercase w-[30%]">
                        {t('template_details.utm_parameters.title')}
                    </Text>
                    {(!trackingParameters || !clickTracking) && (
                        <Text className="text-gray-500">
                            {t('common.empty_state.not_set')}
                        </Text>
                    )}
                </Flex>
                <Stack>
                    {trackingParameters &&
                        clickTracking &&
                        Object.entries(trackingParameters).map(
                            ([key, value]) => (
                                <Flex key={key} align={'center'}>
                                    <Text className="text-gray-800 font-semibold w-[30%]">
                                        {key}
                                    </Text>
                                    <Text className="text-gray-800">
                                        {value}
                                    </Text>
                                </Flex>
                            ),
                        )}
                </Stack>
            </Stack>
        );
    }, [isEditing, clickTracking, trackingParameters, t, utmTrackingState]);

    return (
        <Container
            isEditing={isEditing}
            handleCancel={handleCancel}
            handleEdit={handleEdit}
            handleSave={handleSave}
            label={'Links'}
            isSaving={isLoading}
        >
            {renderContent}
        </Container>
    );
};

export default LinkTracking;
