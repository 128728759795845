import {
    JourneyNodeEnum,
    TriggerType,
} from '@components/Journeys/Builder/types';
import { isExperimentNode } from '@components/Journeys/providerUtils';
import { useIsTruncated } from '@hooks/useIsTruncated';
import {
    ActionType,
    isAndNestedMetricQuery,
    JourneyGroup,
    type EntryTrigger,
    type JourneyNode,
    type SendMessageActionConfig,
    type WaitAction,
    type WaitActionConfig,
    type WaitUntilAction,
} from '@lightdash/common';
import { Group, Text, Tooltip } from '@mantine/core';
import { type JourneyAnalytics } from '@pages/JourneyBuilder';
import { CursorClick, Timer } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React, { useMemo } from 'react';
import {
    checkTriggerType,
    extractNodeValue,
    extractWaitNodeValue,
    getBlockIcon,
    getNodeAnalytics,
    hasAction,
    hasEntryLogicError,
    hasNodeError,
    hasTriggerNodeError,
} from '../../utils';

interface NodeData {
    journeyNodeData: JourneyNode | null;
    blockGroup: JourneyGroup | null;
    isLeafNode: boolean;
    blockType: JourneyNodeEnum | null;
    icon: React.ReactNode;
    nodeValue: React.ReactNode | null;
    hasError: boolean;
    nodeBanner: React.ReactNode | null;
    subTitle: string | null;
    nodeAnalytics: JourneyAnalytics | undefined;
    isExperiment: boolean | undefined;
}

const getNodeBanner = (journeyNodeData: JourneyNode) => {
    const nodeActions = journeyNodeData.actions;
    if (!nodeActions || nodeActions.length === 0) return null;

    if (
        hasAction(nodeActions, ActionType.WAIT_UNTIL) ||
        hasAction(nodeActions, ActionType.WAIT)
    ) {
        const waitUntilAction = nodeActions.find(
            (action) => action.type === ActionType.WAIT_UNTIL,
        );
        const waitAction = nodeActions.find(
            (action) => action.type === ActionType.WAIT,
        );

        const timeWait = waitAction
            ? extractWaitNodeValue(waitAction as WaitAction) //FIXME: This is a temporary fix to ensure the type is correct
            : null;
        const timeWaitUntil = waitUntilAction
            ? extractWaitNodeValue(waitUntilAction as WaitUntilAction) //FIXME: This is a temporary fix to ensure the type is correct
            : null;

        if (!timeWait && !timeWaitUntil) return null;
        return (
            <Group className="gap-1 px-2 py-1 bg-white rounded-xl">
                <Timer size={14} color="rgb(var(--color-mustard-800))" />
                <Text className="text-xs font-medium text-mustard-800">
                    {timeWait || timeWaitUntil}
                </Text>
            </Group>
        );
    }
    return null;
};

/**
 * Custom hook to get node data for a given nodeId.
 *
 * @param {string} nodeId - The ID of the node to retrieve data for.
 * @returns {NodeData} - The data associated with the node.
 */
export const useNodeData = (nodeId: string): NodeData => {
    const { ref: truncatedRef, isTruncated } = useIsTruncated<HTMLDivElement>();

    const {
        edges,
        journeyPayload,
        blocksList,
        nodes,
        journeyEvents,
        journeyAnalytics,
    } = useJourneyBuilderContext((context) => context.state);
    const { getTriggerType } = useJourneyBuilderContext(
        (context) => context.actions,
    );
    const node = nodes.find((n) => n.id === nodeId);
    const blockType = node?.data.type as JourneyNodeEnum | null;

    let journeyNodeData: JourneyNode | null = null;
    let blockGroup: JourneyGroup | null = null;
    let nodeValue: React.ReactNode | null = null;
    let hasError: boolean = false;
    let nodeBanner: React.ReactNode | null = null;
    let subTitle: string | null = null;

    const isValidQuery = useMemo(() => {
        const triggerType =
            journeyPayload.triggers?.entry[0] &&
            checkTriggerType(journeyPayload.triggers?.entry[0]);
        const nestedMetricQuery =
            (triggerType === TriggerType.BUSINESS_EVENT &&
                (journeyPayload.triggers?.entry[0] as EntryTrigger)
                    ?.associatedAudienceFilterConfig) ||
            (triggerType === TriggerType.SCHEDULED &&
                (journeyPayload.triggers?.entry[0] as EntryTrigger)
                    ?.associatedAudienceFilterConfig);

        if (!nestedMetricQuery) return false;
        const queries = isAndNestedMetricQuery(nestedMetricQuery)
            ? nestedMetricQuery.and
            : nestedMetricQuery.or;
        return queries.length > 0;
    }, [journeyPayload.triggers?.entry]);

    if (blockType === JourneyNodeEnum.TRIGGER) {
        const triggerBlock = journeyPayload.triggers?.entry[0];
        const event = journeyEvents?.find(
            (e) => e.eventName === triggerBlock?.eventName,
        );
        if (triggerBlock) {
            journeyNodeData = {
                id: triggerBlock.metadata?.id ?? '',
                title: triggerBlock.metadata?.title ?? 'Trigger',
                description: triggerBlock.metadata?.description ?? '',
                metadata: {
                    blockId: triggerBlock.metadata?.metadata?.blockId ?? '',
                },
                actions: [],
            };
            blockGroup = JourneyGroup.TRIGGER;
            hasError =
                (getTriggerType() !== TriggerType.SCHEDULED &&
                    hasTriggerNodeError(triggerBlock)) ||
                hasEntryLogicError(journeyPayload.entryLogic!) ||
                // FIXME: This is a temporary fix to ensure the trigger node is valid Divyansh need to relook into it
                // getTriggerType() === TriggerType.BUSINESS_EVENT ||
                (getTriggerType() === TriggerType.SCHEDULED && !isValidQuery);
            const nodeValueStr = event?.label ?? triggerBlock.eventName;
            nodeValue = (
                <Group className="gap-1">
                    <CursorClick size={14} />
                    <Tooltip
                        withinPortal
                        variant="xs"
                        label={nodeValueStr}
                        disabled={!isTruncated}
                        styles={{
                            tooltip: {
                                maxWidth: '80rem',
                                wordBreak: 'break-word',
                                whiteSpace: 'normal',
                                overflowWrap: 'break-word',
                                backgroundColor: 'rgb(var(--color-gray-800))',
                                color: 'white',
                            },
                        }}
                    >
                        <Text className="truncate max-w-28" ref={truncatedRef}>
                            {nodeValueStr}
                        </Text>
                    </Tooltip>
                </Group>
            );
        }
    } else if (journeyPayload.config) {
        const { nodes: journeyNodes } = journeyPayload.config;
        journeyNodeData = journeyNodes.find((n) => n.id === nodeId) ?? null;
        if (journeyNodeData) {
            const block = blocksList.find(
                (b) => b.id === journeyNodeData?.metadata?.blockId,
            );
            blockGroup = block?.group ?? null;
            nodeValue = extractNodeValue(journeyNodeData.actions);
            nodeBanner = getNodeBanner(journeyNodeData);
        }
        hasError =
            (journeyNodeData &&
                hasNodeError(journeyNodeData.actions, journeyNodes, nodeId)) ??
            false;
    }

    const isLeafNode = !edges.some((edge) => edge.source === nodeId);
    const nodeAnalytics =
        journeyAnalytics && getNodeAnalytics(journeyAnalytics, nodeId);

    const icon = getBlockIcon({
        type: blockType ?? JourneyNodeEnum.BLOCK,
        actions:
            journeyNodeData?.actions.map((action) => ({
                actionType: action.type,
                config:
                    'actionConfig' in action
                        ? action.actionConfig
                        : (action.config as unknown as
                              | WaitActionConfig
                              | SendMessageActionConfig),
                payload: action,
            })) ?? [],
        group: blockGroup ?? JourneyGroup.DELAY,
    });
    if (journeyNodeData?.actions[0]?.type === ActionType.API) {
        subTitle =
            journeyNodeData?.actions[0].config.apiConfig.httpConfig.method;
    }
    return {
        journeyNodeData,
        blockGroup,
        isLeafNode,
        blockType,
        icon,
        nodeValue,
        hasError,
        nodeBanner,
        subTitle,
        nodeAnalytics,
        isExperiment: isExperimentNode(nodeId, nodes),
    };
};
