import React, { memo } from 'react';
import ReactMarkdown, { type Components } from 'react-markdown';
import { Link } from 'react-router';

const components: Partial<Components> = {
    pre: ({ children }) => <>{children}</>,
    ol: ({ node, children, ...props }) => {
        return (
            <ol className="list-decimal list-outside ml-4" {...props}>
                {children}
            </ol>
        );
    },
    li: ({ node, children, ...props }) => {
        return (
            <li className="py-1" {...props}>
                {children}
            </li>
        );
    },
    ul: ({ node, children, ...props }) => {
        return (
            <ul className="list-decimal list-outside ml-4" {...props}>
                {children}
            </ul>
        );
    },
    strong: ({ node, children, ...props }) => {
        return (
            <span className="font-semibold" {...props}>
                {children}
            </span>
        );
    },
    a: ({ node, children, ...props }) => {
        return (
            // @ts-expect-error
            <Link
                className="text-blue-500 hover:underline"
                target="_blank"
                rel="noreferrer"
                {...props}
            >
                {children}
            </Link>
        );
    },
    h1: ({ node, children, ...props }) => {
        return (
            <h1 className="text-3xl font-semibold mt-6 mb-2" {...props}>
                {children}
            </h1>
        );
    },
    h2: ({ node, children, ...props }) => {
        return (
            <h2 className="text-2xl font-semibold mt-6 mb-2" {...props}>
                {children}
            </h2>
        );
    },
    h3: ({ node, children, ...props }) => {
        return (
            <h3
                className="text-sm font-semibold mt-6 mb-4 text-gray-500 uppercase"
                {...props}
            >
                {children}
            </h3>
        );
    },
    h4: ({ node, children, ...props }) => {
        return (
            <h4 className="text-lg font-semibold mt-6 mb-2" {...props}>
                {children}
            </h4>
        );
    },
    h5: ({ node, children, ...props }) => {
        return (
            <h5 className="text-base font-semibold mt-6 mb-2" {...props}>
                {children}
            </h5>
        );
    },
    h6: ({ node, children, ...props }) => {
        return (
            <h6 className="text-sm font-semibold mt-6 mb-2" {...props}>
                {children}
            </h6>
        );
    },
    blockquote: ({ node, children, ...props }) => {
        return (
            <blockquote
                className="text-gray-500 my-5  border-l border-gray-250 pl-4 ml-1.5"
                {...props}
            >
                {children}
            </blockquote>
        );
    },
    code: ({ node, children, ...props }) => {
        return (
            <code
                className="text-gray-600 font-semibold px-1 bg-gray-100 rounded-sm mt-6 mb-2"
                {...props}
            >
                {children}
            </code>
        );
    },
    p: ({ node, children, ...props }) => {
        return (
            <p className="text-base font-medium text-gray-800 mb-2" {...props}>
                {children}
            </p>
        );
    },
    hr: ({ node, children, ...props }) => {
        return (
            <hr
                className="border-dashed border-gray-250 my-6"
                style={{
                    border: 'none',
                    borderTop: '1px dashed transparent',
                    backgroundImage:
                        'linear-gradient(to right, #E5E5E5 66%, rgba(255, 255, 255, 0) 0%)',
                    backgroundSize: '10px 1px', // Adjust the first value to change the dash spacing
                }}
                {...props}
            />
        );
    },
};

const NonMemoizedMarkdown = ({ children }: { children: string }) => {
    return <ReactMarkdown components={components}>{children}</ReactMarkdown>;
};

export const AudienceDescriptionMarkdown = memo(
    NonMemoizedMarkdown,
    (prevProps, nextProps) => prevProps.children === nextProps.children,
);
