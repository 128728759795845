import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import {
    ContentMappingSection,
    JoinType,
    type TemplateVariableDetails,
} from '@lightdash/common';
import useRelationContext from '@providers/Relation/useRelationContext';
import { filterTablesFromRelation } from '@utils/relation';
import { useMemo } from 'react';

const usePersonalization = ({
    variables,
}: {
    variables: TemplateVariableDetails | undefined;
}) => {
    const { getTableRelation, activeRelation } = useRelationContext();

    const relationData = useMemo(() => {
        if (activeRelation) {
            const allowedRelationTables = getTableRelation([
                JoinType.one_one,
                JoinType.many_one,
            ]);
            if (!allowedRelationTables) return;
            const tableIds = allowedRelationTables.map((table) => table.name);
            const filteredRelation = filterTablesFromRelation(
                activeRelation,
                tableIds,
            );
            if (!filteredRelation) return;
            return filteredRelation;
        }
    }, [activeRelation, getTableRelation]);

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: relationData,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });

    const varibleFields = fieldsWithSuggestions;

    const fieldWithSuggestionInArray = useMemo(() => {
        return Object.keys(varibleFields)?.map((fieldKey: string) => {
            return {
                ...varibleFields?.[fieldKey],
                uniqueIdentifier:
                    varibleFields?.[fieldKey].uniqueIdentifier ?? fieldKey,
            };
        });
    }, [varibleFields]);

    const variablesContent = useMemo((): TemplateVariableDetails => {
        const variableContent: TemplateVariableDetails = {
            [ContentMappingSection.HEADER]: undefined,
            [ContentMappingSection.BODY]: undefined,
            [ContentMappingSection.BUTTONS]: undefined,
            [ContentMappingSection.SUBJECT]: undefined,
        };
        // if (content && 'subject' in content) {
        //     variableContent[ContentMappingSection.SUBJECT] =
        //         extractSubjectVariables(content.subject);
        // }
        if (variableContent) {
            variableContent[ContentMappingSection.HEADER] =
                // channel === CommunicationChannel.WHATSAPP
                //     ? (templateMetadata as any)?.variables?.[
                //           ContentMappingSection.HEADER
                //       ]
                variables?.[ContentMappingSection.HEADER];
            variableContent[ContentMappingSection.BODY] =
                // campaignPayload.channel === CommunicationChannel.WHATSAPP
                //     ? (templateMetadata as any)?.variables?.[
                //           ContentMappingSection.BODY
                //       ]
                variables?.[ContentMappingSection.BODY];
        }
        // if (campaignPayload.channel === CommunicationChannel.WHATSAPP) {
        //     variableContent[ContentMappingSection.BUTTONS] = (
        //         templateMetadata as any
        //     )?.variables?.[ContentMappingSection.BUTTONS];
        // }

        // if (
        //     campaignPayload?.channel === CommunicationChannel.WHATSAPP &&
        //     (templateMetadata as any)?.contents.header?.type &&
        //     (templateMetadata as any)?.contents.header?.type !== 'text'
        // ) {
        //     if (variableContent?.[ContentMappingSection.HEADER]) {
        //         variableContent[ContentMappingSection.HEADER] = [
        //             ...variableContent[ContentMappingSection.HEADER],
        //             WhatsappHeaderVariable,
        //         ];
        //     } else {
        //         variableContent[ContentMappingSection.HEADER] = [
        //             WhatsappHeaderVariable,
        //         ];
        //     }
        // }

        return variableContent;
    }, [variables]);

    return {
        fieldWithSuggestionInArray,
        variablesContent,
    };
};

export default usePersonalization;
