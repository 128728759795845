import { Box, Button, Flex, Grid, Stack, Text } from '@mantine/core';
import React, { useCallback, useEffect, useMemo } from 'react';

import TextArea from '@components/common/Inputs/TextArea';
import TextInput from '@components/common/Inputs/TextInput';
import MultiSelect, {
    CreationLabel,
} from '@components/common/Select/MultiSelect';
import { useGenerateCampaignNameDescription } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { useCreateTag, useTags } from '@hooks/useTags';
import { useGetSyncedTemplateByTemplateName } from '@hooks/useTemplate';
import { AudienceType, CommunicationChannel } from '@lightdash/common';
import { CheckCircle, Pencil } from '@phosphor-icons/react';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { ButtonVariant } from '../../../../../mantineTheme';
import { CampaignBuilderStep, SetupContentSource } from '../../types';
import TemplateContentPreview from '../CampaignBuilderContent/SetupContent/TemplateContentPreview';
import StepTitle from '../StepTitle';
import Audience from './Audience';
import Conversion from './Conversion';
import Integration from './Integration';

const ReviewCampaign = () => {
    const { t } = useLocale();
    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload, isEditMode } = state;
    const {
        setShowFooterButtons,
        setCampaignTags,
        setCampaignDescription,
        setCampaignName,
        setCurrentStep,
    } = actions;
    const { templateDetails, communicationDetails } = campaignPayload;
    const { data: tags, isLoading: isLoadingTags } = useTags();
    const { mutate: createTag, isLoading: isCreatingTag } = useCreateTag();
    const {
        data: nameDescription,
        mutateAsync: mutateAsyncNameDescription,
        isLoading: isLoadingNameDescription,
    } = useGenerateCampaignNameDescription();

    const {
        data: syncedTemplateMetadata,
        // isInitialLoading: isSyncedTemplateMetadataLoading,
    } = useGetSyncedTemplateByTemplateName(
        (templateDetails?.integrationId || communicationDetails?.id) ?? '',
        templateDetails?.id ?? '',
        campaignPayload?.channel === CommunicationChannel.WHATSAPP,
    );

    useEffect(() => {
        setShowFooterButtons({
            next: true,
            back: true,
        });
    }, [setShowFooterButtons]);

    const getTemplateMetadata = useMemo(() => {
        if (campaignPayload?.channel === CommunicationChannel.WHATSAPP) {
            return {
                ...syncedTemplateMetadata,
                contents: syncedTemplateMetadata?.languages[0]?.content ?? [],
                channel: CommunicationChannel.WHATSAPP,
            };
        }
        return campaignPayload.contentDetails;
    }, [
        campaignPayload?.channel,
        campaignPayload.contentDetails,
        syncedTemplateMetadata,
    ]);

    useEffect(() => {
        if (!campaignPayload.description) {
            mutateAsyncNameDescription({
                audienceIdArray:
                    campaignPayload?.audienceConfig?.config.in || [],
                templateId: campaignPayload?.templateDetails?.id || '',
                channel: campaignPayload.channel ?? CommunicationChannel.EMAIL,
                schedule: campaignPayload.schedule ?? {},
            })
                .then((data) => {
                    if (data.description) {
                        setCampaignDescription(data.description);
                    }
                })
                .catch(() => {});
        }
    }, [campaignPayload, mutateAsyncNameDescription, setCampaignDescription]);

    const handleCreateTag = useCallback(
        (query: string) => {
            createTag(
                { name: query },
                {
                    onSuccess: () => {
                        setCampaignTags([
                            ...(campaignPayload?.tags ?? []),
                            query,
                        ]);
                    },
                },
            );
            return query;
        },
        [createTag, campaignPayload?.tags, setCampaignTags],
    );

    const handleNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCampaignName(e.target.value);
    };

    const handleDescriptionInput = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setCampaignDescription(e.target.value);
    };

    const handleIntegrationEdit = () => {
        setCurrentStep(CampaignBuilderStep.CHANNEL);
    };

    const handleAudienceEdit = () => {
        setCurrentStep(CampaignBuilderStep.AUDIENCE);
    };

    const handleConversionEdit = () => {
        setCurrentStep(CampaignBuilderStep.CONVERSION_AND_THROTTLING);
    };

    const handleContentEdit = () => {
        setCurrentStep(CampaignBuilderStep.CONTENT);
    };

    return (
        <Box className="pt-6 h-full">
            <StepTitle
                title={t('campaign_builder.review_campaign.title')}
                customClassName="pl-7 mb-6"
            />
            <Grid className="h-full">
                <Grid.Col span={6} className="pl-9 h-[85%] overflow-y-scroll">
                    <Stack spacing={'xl'} className="overflow-y-scroll">
                        <TextInput
                            label={t('campaign_builder.campaign_name.label')}
                            value={campaignPayload.name}
                            onChange={handleNameInput}
                        />
                        <TextArea
                            label={
                                <Text>
                                    {t(
                                        'campaigns_builder_details.campaign_description_label',
                                    )}{' '}
                                    <span className="text-gray-600">
                                        {t('common.optional')}
                                    </span>
                                </Text>
                            }
                            value={campaignPayload.description}
                            isAiGeneratingData={isLoadingNameDescription}
                            aiGeneratedData={nameDescription?.description}
                            onChange={handleDescriptionInput}
                        />
                        <MultiSelect
                            placeholder={t(
                                'audience_create.modal_tags_placeholder',
                            )}
                            value={campaignPayload.tags}
                            label={
                                <Flex gap={1}>
                                    <Text className="text-sm font-medium text-gray-800">
                                        {t('common.tags')}
                                    </Text>
                                    <Text className="text-sm font-medium text-gray-600">
                                        {t(
                                            'custom_metric.dimension_modal_description_label_optional',
                                        )}
                                    </Text>
                                </Flex>
                            }
                            // {...register('tags')}
                            onChange={(value) => {
                                setCampaignTags(value);
                            }}
                            data={
                                tags?.map((tag) => ({
                                    value: tag.name,
                                    label: tag.name,
                                })) || []
                            }
                            searchable
                            creatable
                            getCreateLabel={(query) => (
                                <CreationLabel
                                    query={query}
                                    isCreating={isCreatingTag}
                                />
                            )}
                            onCreate={handleCreateTag}
                            disabled={
                                isLoadingTags || isCreatingTag || !isEditMode
                            }
                            disableSelectedItemFiltering
                        />
                        <Integration
                            providerId={communicationDetails?.providerId}
                            integrationId={communicationDetails?.id}
                            handleEdit={handleIntegrationEdit}
                        />
                        <Audience
                            handleEdit={handleAudienceEdit}
                            sendList={campaignPayload.audienceConfig?.config.in}
                            suppressList={
                                campaignPayload.audienceConfig?.config.notIn
                            }
                            channel={
                                campaignPayload.channel ??
                                CommunicationChannel.EMAIL
                            }
                            count={
                                campaignPayload.audienceType ===
                                AudienceType.WAREHOUSE
                                    ? campaignPayload.audienceConfig?.totalCount
                                    : campaignPayload.csvUploadDetails
                                          ?.audienceCount
                            }
                            filename={
                                campaignPayload.csvUploadDetails?.fileName
                            }
                            showPreview={
                                campaignPayload.audienceType ===
                                AudienceType.WAREHOUSE
                            }
                        />
                        <Conversion
                            handleEdit={handleConversionEdit}
                            conversionConfig={campaignPayload.conversionConfig}
                            throttleConfig={campaignPayload.throttleConfig}
                        />
                    </Stack>
                </Grid.Col>
                <Grid.Col span={6} className="h-[85%] pr-9">
                    {getTemplateMetadata && (
                        <Box
                            className={`mr-9 mb-6 py-3 bg-gray-50 border-base ${
                                campaignPayload.channel ===
                                CommunicationChannel.EMAIL
                                    ? 'w-full'
                                    : 'w-fit'
                            } h-fit max-h-full max-w-full overflow-hidden`}
                        >
                            <Flex justify={'space-between'} className="px-3">
                                <Flex gap={6} align={'center'}>
                                    <CheckCircle color="rgb(var(--color-blu-800))" />
                                    <Text className="text-base text-gray-700 font-semibold">
                                        {t('common.content')}
                                    </Text>
                                </Flex>
                                <Button
                                    leftIcon={
                                        <Pencil color="rgb(var(--color-gray-600))" />
                                    }
                                    variant={ButtonVariant.OUTLINED}
                                    onClick={handleContentEdit}
                                >
                                    {t('common.edit')}
                                </Button>
                            </Flex>
                            <Box className="h-[90%] overflow-scroll px-3">
                                <TemplateContentPreview
                                    templateMetadata={
                                        getTemplateMetadata as any
                                    }
                                    grapesContainerId="html-preview-campaigns"
                                    showDeviceAndThemeSwitcher={false}
                                    onTemplateSelect={undefined}
                                    isSetupComplete={undefined}
                                    openContentSetupManager={() => {}}
                                    source={SetupContentSource.CAMPAIGN}
                                    showHeader={false}
                                    handleUpdateTemplateInCampaignContext={
                                        undefined
                                    }
                                />
                            </Box>
                        </Box>
                    )}
                </Grid.Col>
            </Grid>
        </Box>
    );
};

export default ReviewCampaign;
