import BuilderStepTitle from '@components/common/BuilderContainer/BuilderStepTitle';
import InputErrorText from '@components/common/InputErrorText';
import LoadingState from '@components/common/LoadingState';
import EventsModal from '@components/EventsManager/EventsModal';
import {
    EventBuilderStep,
    type PropertiesType,
} from '@components/EventsManager/types';
import {
    concatPropertiesInEvents,
    convertKeysToSelectData,
    EventBuilderSteps,
    getNestedValue,
} from '@components/EventsManager/utils';
import AddSourceModal from '@components/EventSources/EventModals/AddSourceModal';
import SourceDataModal from '@components/EventSources/EventModals/SourceDataModal';
import WebhookModal from '@components/EventSources/EventModals/WebHookModal';
import { Source } from '@components/ProjectSettings/EventSourcePanel/types';
import useNotify from '@hooks/toaster/useNotify';
import { useGetAllEventSources, useGetEventLogsStats } from '@hooks/useEvents';
import { useLocale } from '@hooks/useLocale';
import {
    getErrorMessage,
    Sources,
    type SourceEventKeyMapping,
} from '@lightdash/common';
import { Box, Button, Flex, Menu, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    CaretDown,
    Check,
    CopySimple,
    PaintBrush,
    PlusCircle,
} from '@phosphor-icons/react';
import useEventContext from '@providers/Events/useEventContext';
import 'ace-builds/src-noconflict/ext-beautify';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-textmate';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AceEditor from 'react-ace';
import { ButtonVariant } from '../../../../mantineTheme';

const EventBuilderPayload: React.FC = () => {
    const { t } = useLocale();
    const { data } = useGetAllEventSources();
    const { state, actions } = useEventContext((context) => context);
    const {
        eventPayload,
        isViewMode,
        isEditMode,
        jsonPayloadKeys,
        jsonString,
        isDuplicateMode,
        isNewMode,
    } = state;

    const editorRef = useRef<InstanceType<typeof AceEditor> | null>(null);
    const {
        setJsonPayload,
        setJsonPayloadKeys,
        setShowFooterButtons,
        setSource,
        setCurrentStepCallback,
        setEventName,
        setJsonString,
    } = actions;
    const { showToastError } = useNotify();
    const [opened, { open, close }] = useDisclosure();
    const [sourceOpened, { open: sourceOpen, close: sourceClose }] =
        useDisclosure();
    const [showEventModal, { open: eventModalOpen, close: eventModalClose }] =
        useDisclosure(false);
    const [showSourceData, { open: sourceDataOpen, close: sourceDataClose }] =
        useDisclosure(false);
    const [
        showWebhookModal,
        { open: webhookModalOpen, close: webhookModalClose },
    ] = useDisclosure(false);

    const [sourceData, setSourceData] = useState<SourceEventKeyMapping>();
    const [eventSource, setEventSource] = useState<Source>(Source.WEBHOOK);

    const [borderColor, setBorderColor] = useState<string>('border-shade-4');
    const [showEventKeyNamesError, setShowEventKeyNamesError] =
        useState<boolean>(false);
    const title = EventBuilderSteps.find(
        (step) => step.key === EventBuilderStep.PAYLOAD,
    )?.pageHeader;
    const { data: events, isInitialLoading } = useGetEventLogsStats();
    const filteredEvents = useMemo(() => {
        return events?.filter(
            (event) => event?.source?.toLowerCase() !== Sources.FYNO,
        );
    }, [events]);

    const eventKeyNames = useMemo(() => {
        if (!eventPayload.source) return undefined;

        const sourceObj = data?.find(
            (sourceObject) => sourceObject.source === eventPayload.source,
        );

        return sourceObj?.eventNameKeys.map((source) => source.slice(2));
    }, [eventPayload.source, data]);

    const isValidJson = useMemo(() => {
        try {
            const refinedJson = JSON.stringify(JSON.parse(jsonString ?? ''));
            const isEmptyJson =
                JSON.stringify(eventPayload.sampleEventPayload) === '{}';
            if (eventPayload.source.length > 0 && !isEmptyJson)
                return Boolean(
                    jsonString &&
                        jsonString.length > 0 &&
                        refinedJson ===
                            JSON.stringify(eventPayload.sampleEventPayload),
                );
        } catch (error) {}
    }, [jsonString, eventPayload.sampleEventPayload, eventPayload.source]);

    useEffect(() => {
        if (jsonString !== undefined)
            setShowFooterButtons({
                next: true,
                disableNext: false,
                back: false,
            });
        else {
            setShowFooterButtons({
                next: true,
                disableNext: true,
                back: false,
            });
        }
    }, [setShowFooterButtons, jsonString]);

    const handleJsonChange = useCallback(() => {
        try {
            const cleanedJsonString = jsonString?.replace(/,\s*$/, '');
            const parsedJson = JSON.parse(cleanedJsonString ?? '');
            setJsonPayload(parsedJson);
            setBorderColor('border-blu-800');
            editorRef.current?.editor.getSession().setAnnotations([]);
        } catch (error) {
            setBorderColor('border-halt-800');
            const errorMessage = getErrorMessage(error);
            const lineMatch = errorMessage.match(/at position (\d+)/);
            if (lineMatch && jsonString) {
                const position = parseInt(lineMatch[1], 10);
                const lines = jsonString.substring(0, position).split('\n');
                const row = lines.length - 1;
                const column =
                    position - jsonString.lastIndexOf('\n', position) - 1;

                const adjustedColumn =
                    column > 0 ? column : lines[row].length - 1;

                editorRef.current?.editor.getSession().setAnnotations([
                    {
                        row,
                        column: adjustedColumn,
                        text: errorMessage,
                        type: 'error',
                    },
                ]);
            }
        }
    }, [jsonString, setJsonPayload]);

    const checkEventKeyNamesInPayload = useCallback(() => {
        try {
            const parsedJson = JSON.parse(jsonString ?? '');
            const keys = convertKeysToSelectData(parsedJson).map(
                (keyObj) => keyObj.key,
            );
            return eventKeyNames?.every((key) => keys.includes(key));
        } catch (error) {
            return false;
        }
    }, [eventKeyNames, jsonString]);

    useEffect(() => {
        setCurrentStepCallback({
            callback: () => {
                if (jsonString?.length === 0) {
                    setBorderColor('border-halt-800');
                    showToastError({
                        title: t('event_manager.empty_payload_error'),
                        autoClose: 2000,
                    });
                }
                if (eventPayload.source === '') {
                    showToastError({
                        title: t('event_manager.empty_source_error'),
                        autoClose: 2000,
                    });
                }

                const parsedJson = JSON.parse(jsonString ?? '');

                if (jsonString?.length && jsonString?.length > 0)
                    handleJsonChange();

                if (!isViewMode) {
                    const properties: PropertiesType[] =
                        convertKeysToSelectData(parsedJson);
                    if (!isEditMode && !isDuplicateMode) {
                        setJsonPayloadKeys(properties);
                    } else {
                        const concatedProperties: PropertiesType[] =
                            concatPropertiesInEvents(
                                jsonPayloadKeys,
                                properties,
                            );
                        setJsonPayloadKeys(concatedProperties);
                    }
                }
                if (!checkEventKeyNamesInPayload()) {
                    setShowEventKeyNamesError(true);
                    if (eventKeyNames?.length && eventKeyNames?.length > 0) {
                        setBorderColor('border-halt-800');
                    } else setBorderColor('border-blu-800');
                } else {
                    if (eventKeyNames?.[0]) {
                        const eventName = getNestedValue(
                            parsedJson,
                            eventKeyNames?.[0],
                        );
                        if (isNewMode) {
                            setEventName(eventName);
                        }
                    }
                    setBorderColor('border-blu-800');
                    setShowEventKeyNamesError(false);
                }
            },
            skipExecutionAfterCallback:
                !checkEventKeyNamesInPayload() || !isValidJson,
        });
    }, [
        setJsonPayloadKeys,
        jsonString,
        setCurrentStepCallback,
        isEditMode,
        isViewMode,
        isValidJson,
        handleJsonChange,
        checkEventKeyNamesInPayload,
        eventKeyNames,
        setEventName,
        jsonPayloadKeys,
        showToastError,
        t,
        eventPayload.source,
        isDuplicateMode,
        isNewMode,
    ]);

    const handleBeautify = () => {
        try {
            const formattedJson = JSON.stringify(
                JSON.parse(jsonString ?? ''),
                null,
                2,
            );
            setJsonString(formattedJson);
            setJsonPayload(JSON.parse(formattedJson));
        } catch (error) {
            showToastError({
                title: t('event_create.json_parse_error_title'),
            });
        }
    };

    const onSourceSelect = useCallback(
        (item: SourceEventKeyMapping) => {
            setSourceData(item);
            sourceDataOpen();
            setSource(item.source);
        },
        [setSource, sourceDataOpen],
    );
    if (isInitialLoading) {
        return <LoadingState title={''} />;
    }

    return (
        <>
            <Flex direction="column" gap={16}>
                <Flex gap={10}>
                    <BuilderStepTitle title={title || ''} />

                    {!(isEditMode || isViewMode) && (
                        <Button
                            variant={ButtonVariant.SUBTLE_ACCENTED}
                            onClick={open}
                            leftIcon={
                                <CopySimple
                                    weight="duotone"
                                    color={'rgb(var(--color-blu-800))'}
                                />
                            }
                        >
                            {t('events_manager.clone_from_existing_event')}
                        </Button>
                    )}
                </Flex>
                <Box>
                    <Flex gap={4} direction="column">
                        <Text className="text-gray-800">
                            {t('event_create.keys_sources_title')}
                        </Text>
                        <Menu
                            opened={sourceOpened}
                            onOpen={sourceOpen}
                            onClose={sourceClose}
                        >
                            <Menu.Target>
                                <Button
                                    variant={ButtonVariant.OUTLINED}
                                    className="min-w-[12rem] w-fit  p-2"
                                    rightIcon={
                                        <CaretDown
                                            weight="bold"
                                            className={`text-gray-500 text-sm mx-1 ${
                                                sourceOpened
                                                    ? ' rotate-180'
                                                    : ''
                                            }`}
                                        />
                                    }
                                    styles={{
                                        label: {
                                            justifyContent: 'flex-start',
                                            paddingLeft: '0.25rem',
                                        },
                                    }}
                                >
                                    {eventPayload.source ? (
                                        eventPayload.source
                                    ) : (
                                        <Text className="text-gray-500">
                                            {t(
                                                'event_create.keys_sources_placeholder',
                                            )}
                                        </Text>
                                    )}
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Box className="max-h-[15rem]  overflow-auto">
                                    {data
                                        ?.filter((source) => !source.isInternal)
                                        .map(
                                            (sourcePayload) =>
                                                sourcePayload.source && (
                                                    <Menu.Item
                                                        key={
                                                            sourcePayload.source
                                                        }
                                                        onClick={() =>
                                                            setSource(
                                                                sourcePayload.source,
                                                            )
                                                        }
                                                    >
                                                        <Flex
                                                            className="w-full text-gray-800"
                                                            justify="space-between"
                                                        >
                                                            {
                                                                sourcePayload.source
                                                            }
                                                            {eventPayload.source ===
                                                                sourcePayload.source && (
                                                                <Check weight="bold" />
                                                            )}
                                                        </Flex>
                                                    </Menu.Item>
                                                ),
                                        )}
                                </Box>
                                <Menu.Item
                                    icon={<PlusCircle weight="duotone" />}
                                    className="text-gray-800"
                                    onClick={() => {
                                        eventModalOpen();
                                    }}
                                >
                                    {t(
                                        'event_create.event_builder_step_payload_source_create',
                                    )}
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Flex>
                </Box>
                <Box>
                    <Flex justify="space-between" align="center">
                        <Text className="font-medium text-gray-800">
                            {t('event_create.json_input_label')}
                        </Text>
                        <Button
                            onClick={handleBeautify}
                            variant={ButtonVariant.SUBTLE_ACCENTED}
                            disabled={!jsonString}
                            leftIcon={
                                <PaintBrush
                                    weight="duotone"
                                    color={
                                        !jsonString
                                            ? 'rgb(var(--color-gray-500))'
                                            : 'rgb(var(--color-blu-800))'
                                    }
                                />
                            }
                        >
                            {t('events_create.payload_beautify_json')}
                        </Button>
                    </Flex>
                    <Box className="mb-20">
                        <Box
                            className={`w-full h-[31rem] border pt-1 ${
                                jsonString !== undefined
                                    ? borderColor
                                    : 'border-blu-800 shadow shadow-blu-800/20'
                            } rounded-xl overflow-hidden`}
                        >
                            {jsonString !== undefined ? (
                                <AceEditor
                                    ref={editorRef}
                                    width="100%"
                                    height="100%"
                                    mode="json"
                                    theme="textmate"
                                    name="json-editor"
                                    readOnly={isViewMode}
                                    onBlur={() => {
                                        if (jsonString.length > 0)
                                            handleJsonChange();
                                    }}
                                    onChange={(newJson: string) =>
                                        setJsonString(newJson)
                                    }
                                    onFocus={() => {
                                        setShowEventKeyNamesError(false);
                                        setBorderColor('border-shade-4');
                                    }}
                                    showPrintMargin={false}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    fontSize={14}
                                    value={jsonString}
                                    wrapEnabled={true}
                                    setOptions={{
                                        tabSize: 2,
                                        lineHeight: 19,
                                    }}
                                />
                            ) : (
                                <Flex
                                    align={'center'}
                                    justify={'center'}
                                    className="h-full cursor-pointer"
                                    onClick={() => {
                                        //waiting for 20ms so that aceeditor component will mount and focus can work.
                                        setTimeout(() => {
                                            editorRef.current?.editor.focus();
                                        }, 200);
                                        setJsonString('');
                                    }}
                                >
                                    <Text className="text-gray-500">
                                        {t(
                                            'event_create.json_editor_initial_load_text',
                                        )}
                                    </Text>
                                </Flex>
                            )}
                        </Box>
                        {showEventKeyNamesError &&
                            eventKeyNames?.length &&
                            eventKeyNames?.length > 0 && (
                                <InputErrorText
                                    value={t(
                                        'event_create.event_key_names_error_description',
                                        {
                                            event_key_names: eventKeyNames
                                                ?.map((key) => `${key}`)
                                                .join(', '),
                                        },
                                    )}
                                />
                            )}
                    </Box>
                </Box>
            </Flex>

            <AddSourceModal
                setEventSource={setEventSource}
                showEventModal={showEventModal}
                webhookModalOpen={webhookModalOpen}
                eventModalClose={eventModalClose}
            />
            {showWebhookModal && (
                <WebhookModal
                    onSourceAdded={(item: SourceEventKeyMapping) => {
                        onSourceSelect(item);
                    }}
                    eventSource={eventSource}
                    showWebhookModal={showWebhookModal}
                    webhookModalClose={webhookModalClose}
                />
            )}
            {showSourceData && sourceData && !showWebhookModal && (
                <SourceDataModal
                    sourceData={sourceData}
                    showSourceData={showSourceData}
                    sourceDataClose={sourceDataClose}
                />
            )}
            <EventsModal
                opened={opened}
                onClose={close}
                events={filteredEvents ?? []}
                inProvider
                setJsonString={setJsonString}
                setJsonPayload={setJsonPayload}
                setSource={setSource}
            />
        </>
    );
};

export default EventBuilderPayload;
