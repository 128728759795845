import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import LeftLabelInput from '@components/common/Inputs/LeftLabelInput';
import FieldSelect from '@components/common/Select/FieldSelect';
import { useLocale } from '@hooks/useLocale';
import { useUpdateTemplateContentDetails } from '@hooks/useTemplate';
import {
    CampaignFieldType,
    FieldType,
    WhatsappHeaderVariable,
    type CommunicationChannel,
    type ContentMappings,
    type ContentMappingSection,
    type TemplateVariableDetails,
    type Variable,
} from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { capitalize } from 'lodash';
import { useParams } from 'react-router';
import Container from '../Container';
import PersonalizationFilled from './PersonalizationFilled';
import usePersonalization from './usePersonalization';

type PersonalizationProps = {
    variables: TemplateVariableDetails | undefined;
    contentMappings: ContentMappings | undefined;
    channel: CommunicationChannel | undefined;
};
const Personalization = ({
    variables,
    contentMappings,
}: // channel
PersonalizationProps) => {
    const { t } = useLocale();
    const { templateId } = useParams<{ templateId: string }>();
    const [isEditing, setIsEditing] = useState(false);
    const [mappings, setMappings] = useState<ContentMappings | undefined>(
        contentMappings,
    );

    const { fieldWithSuggestionInArray, variablesContent } = usePersonalization(
        {
            variables,
        },
    );

    const { mutateAsync: updateTemplateContentDetails, isLoading } =
        useUpdateTemplateContentDetails();

    useEffect(() => {
        if (contentMappings) {
            setMappings(contentMappings);
        }
    }, [contentMappings]);

    const handleEdit = () => setIsEditing(true);

    const handleSave = async () => {
        try {
            if (templateId) {
                await updateTemplateContentDetails({
                    templateId,
                    version: 1,
                    body: {
                        contentMappings: mappings,
                    },
                });
                setIsEditing(false);
            }
        } catch (error) {}
    };

    const handleCancel = () => {
        setIsEditing(false);
        setMappings(contentMappings);
    };

    const handleVariableChange = useCallback(
        (
            value: FieldWithSuggestions | undefined,
            key: string,
            groupName: ContentMappingSection,
            defaultValue?: string,
        ) => {
            if (!value || !value?.uniqueIdentifier) return;

            if (mappings && mappings[groupName]) {
                const oldValue = mappings[groupName];
                const selectedVariable = {
                    value: value?.uniqueIdentifier,
                    defaultValue:
                        defaultValue || oldValue?.[key]?.defaultValue || '',
                    type: value?.fieldType || FieldType.DIMENSION,
                };
                const newValue = {
                    ...(oldValue?.[key] ?? {}),
                    ...selectedVariable,
                };
                setMappings({
                    ...mappings,
                    [groupName]: {
                        ...oldValue,
                        [key]: {
                            ...(oldValue?.[key] ?? {}),
                            ...newValue,
                        },
                    },
                });
            } else {
                const selectedVariable = {
                    value: value?.uniqueIdentifier,
                    defaultValue: defaultValue || '',
                    type: value?.fieldType || FieldType.DIMENSION,
                };
                setMappings({
                    ...mappings,
                    [groupName]: {
                        [key]: {
                            ...selectedVariable,
                        },
                    },
                });
            }
        },
        [mappings],
    );

    const handleVariableDefaultValue = useCallback(
        (value: string, key: string, groupName: ContentMappingSection) => {
            const selectedVariable: Variable = {
                value: '',
                defaultValue: value,
                type: CampaignFieldType.STATIC,
            };
            if (mappings && mappings[groupName]) {
                const oldValue = mappings[groupName];
                if (oldValue?.[key]?.value) {
                    selectedVariable.value = oldValue[key].value;
                }
                if (oldValue?.[key]?.type) {
                    selectedVariable.type = oldValue[key].type;
                }
                const newValue = {
                    ...oldValue,
                    [key]: {
                        ...(oldValue?.[key] ?? {}),
                        ...selectedVariable,
                    },
                };
                setMappings({
                    ...mappings,
                    [groupName]: {
                        ...oldValue,
                        ...newValue,
                    },
                });
            } else {
                setMappings({
                    ...mappings,
                    [groupName]: {
                        [key]: {
                            ...selectedVariable,
                        },
                    },
                });
            }
        },
        [mappings],
    );

    const renderContent = useMemo(() => {
        if (isEditing) {
            return Object.entries(variablesContent).map(([section, vars]) => (
                <Box key={section}>
                    {vars?.length ? (
                        <Text className="text-gray-600 font-medium mb-6">
                            {capitalize(section)} variables
                        </Text>
                    ) : null}
                    {vars?.map((item) => (
                        <Box key={`${section}_${item}`} className="mb-6">
                            <Text className="text-gray-800 font-semibold mb-2">
                                <span className="text-gray-600">{`{{ `}</span>
                                {item}
                                <span className="text-gray-600">{` }}`}</span>
                            </Text>
                            <Flex align={'baseline'} gap={8}>
                                <FieldSelect
                                    size="sm"
                                    item={fieldWithSuggestionInArray.find(
                                        (field) =>
                                            field.uniqueIdentifier ===
                                            mappings?.[
                                                section as ContentMappingSection
                                            ]?.[item]?.value,
                                    )}
                                    items={fieldWithSuggestionInArray}
                                    onChange={(value: FieldWithSuggestions) =>
                                        handleVariableChange(
                                            value,
                                            item,
                                            section as ContentMappingSection,
                                        )
                                    }
                                    placeholder={t(
                                        'campaign.create.template_variable.placeholder',
                                        {
                                            variable:
                                                item === WhatsappHeaderVariable
                                                    ? `_url`
                                                    : item,
                                        },
                                    )}
                                    isDisabled={false}
                                    additionalPropertySelectList={undefined}
                                />
                                <LeftLabelInput
                                    value={
                                        mappings?.[
                                            section as ContentMappingSection
                                        ]?.[item]?.defaultValue
                                    }
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        handleVariableDefaultValue(
                                            event.target.value,
                                            item,
                                            section as ContentMappingSection,
                                        );
                                    }}
                                />
                            </Flex>
                        </Box>
                    ))}
                </Box>
            ));
        }
        return (
            <PersonalizationFilled variables={variables} mappings={mappings} />
        );
    }, [
        fieldWithSuggestionInArray,
        handleVariableChange,
        handleVariableDefaultValue,
        isEditing,
        mappings,
        t,
        variables,
        variablesContent,
    ]);

    return (
        <Container
            isEditing={isEditing}
            handleCancel={handleCancel}
            handleEdit={handleEdit}
            handleSave={handleSave}
            label={'Personalization'}
            isSaving={isLoading}
        >
            <Box>{renderContent}</Box>
        </Container>
    );
};

export default Personalization;
