import { sortmentApi } from '@api/index';
import { SortmentLogo } from '@assets/icons/Core';
import { ThirdPartySignInButton } from '@components/common/ThirdPartySignInButton';
import Page from '@components/Page/Page';
import PageSpinner from '@components/PageSpinner';
import CreateUserForm from '@components/RegisterForms/CreateUserForm';
import useNotify from '@hooks/toaster/useNotify';
import { useFlashMessages } from '@hooks/useFlashMessages';
import { useInviteLink } from '@hooks/useInviteLink';
import { useLocale } from '@hooks/useLocale';
import {
    OpenIdIdentityIssuerType,
    type ActivateUserWithInviteCode,
    type ApiError,
    type CreateUserArgs,
    type LightdashUser,
} from '@lightdash/common';
import {
    Anchor,
    Box,
    Button,
    Card,
    Divider,
    Stack,
    Text,
    Title,
} from '@mantine/core';
import useApp from '@providers/App/useApp';
import useTracking from '@providers/Tracking/useTracking';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState, type FC } from 'react';
import { Navigate, useLocation, useParams } from 'react-router';

interface WelcomeCardProps {
    email: string | undefined;
    setReadyToJoin: (isReady: boolean) => void;
}

const WelcomeCard: FC<WelcomeCardProps> = ({ email, setReadyToJoin }) => {
    // const { data: org } = useOrganization();

    return (
        <>
            <div>
                <Stack spacing="md" align="center">
                    <Title className="text-base font-medium text-gray-600">
                        You’ve been invited!
                    </Title>
                    {email && (
                        <Text fw="600" size="md">
                            {email}
                        </Text>
                    )}
                    {/* <Text color="gray.6" ta="center">
                        {`Your teammates ${
                            org?.name ? `at ${org.name}` : ''
                        } are using Sortment to discover
                    and share data insights. Click on the link below within the
                    next 72 hours to join your team and start exploring your
                    data!`}
                    </Text> */}
                    <Button
                        onClick={() => setReadyToJoin(true)}
                        className="w-full py-3 rounded-lg"
                    >
                        Join your team
                    </Button>
                </Stack>
            </div>
            <Text color="gray.6" ta="center">
                {`Not ${email ? email : 'for you'}?`}
                <br />
                Ignore this invite link and contact your workspace admin.
            </Text>
        </>
    );
};

const ErrorCard: FC<{ title: string }> = ({ title }) => {
    return (
        <Card data-cy="welcome-user">
            <Stack spacing="md" align="center">
                <Title order={3}>{title}</Title>
                <Text color="gray.7" ta="center">
                    Please check with the person who shared it with you to see
                    if there’s a new link available.
                </Text>
            </Stack>
        </Card>
    );
};

const createUserQuery = async (data: ActivateUserWithInviteCode) =>
    sortmentApi<LightdashUser>({
        url: `/user`,
        method: 'POST',
        body: JSON.stringify(data),
    });

const Invite: FC = () => {
    const { inviteCode } = useParams<{ inviteCode: string }>();
    const { health } = useApp();
    const { t } = useLocale();
    const { showToastError, showToastApiError } = useNotify();
    const flashMessages = useFlashMessages();

    useEffect(() => {
        if (flashMessages.data?.error) {
            showToastError({
                title: 'Failed to authenticate',
                subtitle: flashMessages.data.error.join('\n'),
            });
        }
    }, [flashMessages.data, showToastError]);
    const { search } = useLocation();
    const { identify } = useTracking();
    const redirectUrl = '/';
    const [isLinkFromEmail, setIsLinkFromEmail] = useState<boolean>(false);
    const { isLoading, mutate, isSuccess } = useMutation<
        LightdashUser,
        ApiError,
        ActivateUserWithInviteCode
    >(createUserQuery, {
        mutationKey: ['create_user'],
        onSuccess: (data) => {
            identify({ id: data.userUuid });
            window.location.href = redirectUrl;
        },
        onError: ({ error }) => {
            showToastApiError({
                title: `Failed to create user`,
                apiError: error,
            });
        },
    });
    const inviteLinkQuery = useInviteLink(inviteCode);

    const allowPasswordAuthentication =
        !health.data?.auth.disablePasswordAuthentication;

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const fromParam = searchParams.get('from');
        if (fromParam === 'email') {
            setIsLinkFromEmail(true);
        }
    }, [search]);

    if (health.isInitialLoading || inviteLinkQuery.isInitialLoading) {
        return <PageSpinner />;
    }

    if (health.status === 'success' && health.data?.isAuthenticated) {
        return <Navigate to={{ pathname: redirectUrl }} />;
    }

    const ssoAvailable =
        health.data?.auth.google.enabled ||
        health.data?.auth.okta.enabled ||
        health.data?.auth.oneLogin.enabled ||
        health.data?.auth.azuread.enabled ||
        health.data?.auth.oidc.enabled;
    const ssoLogins = ssoAvailable && (
        <Stack>
            {Object.values(OpenIdIdentityIssuerType).map((providerName) => (
                <ThirdPartySignInButton
                    key={providerName}
                    providerName={providerName}
                    inviteCode={inviteCode}
                    intent="signup"
                    redirect={redirectUrl}
                />
            ))}
        </Stack>
    );
    const passwordLogin = allowPasswordAuthentication && inviteCode && (
        <CreateUserForm
            isLoading={isLoading || isSuccess}
            readOnlyEmail={inviteLinkQuery.data?.email}
            onSubmit={({ firstName, lastName, password }: CreateUserArgs) => {
                mutate({
                    inviteCode,
                    firstName,
                    lastName,
                    password,
                });
            }}
        />
    );
    const logins = (
        <>
            {passwordLogin}
            {ssoLogins && passwordLogin && (
                <Divider
                    label={
                        <>
                            <Text className="text-sm font-semibold text-gray-500 uppercase">
                                {t('common.or')}
                            </Text>
                        </>
                    }
                    labelPosition="center"
                    size="xs"
                    className="w-full"
                    styles={() => ({
                        label: {
                            '&::before': {
                                background:
                                    'linear-gradient(to right, transparent, rgb(var(--color-gray-200)))',
                                border: 'none',
                                height: '1px',
                            },
                            '&::after': {
                                background:
                                    'linear-gradient(to left, transparent, rgb(var(--color-gray-200)))',
                                border: 'none',
                                height: '1px',
                            },
                            marginHorizontal: '8px',
                        },
                    })}
                />
            )}
            <Box className="w-full">{ssoLogins}</Box>
        </>
    );

    return (
        <Page
            title="Register"
            withCenteredContent
            withFullHeight
            backgroundColor="gray"
        >
            <section className="w-[300px] px-3 pt-6 pb-3 bg-white flex flex-col items-center border-base gap-3">
                <div className="flex flex-col items-center w-full gap-2">
                    <SortmentLogo />
                </div>

                <Stack>
                    {inviteLinkQuery.error ? (
                        <ErrorCard
                            title={
                                inviteLinkQuery.error.error.name ===
                                'ExpiredError'
                                    ? 'This invite link has expired 🙈'
                                    : inviteLinkQuery.error.error.message
                            }
                        />
                    ) : isLinkFromEmail ? (
                        <>
                            <div>{logins}</div>
                            <Text color="gray.6" ta="center">
                                By creating an account, you agree to
                                <br />
                                our{' '}
                                {/* TODO: tyagi is yet to give me the link */}
                                <Anchor
                                    href="https://sortment.gitbook.io/sortment-docs/privacy-policy"
                                    target="_blank"
                                >
                                    Privacy Policy.
                                </Anchor>{' '}
                                {/* TODO: tyagi is yet to give me the link for terms of service */}
                                {/* and our{' '}
                                <Anchor
                                    href="#"
                                    target="_blank"
                                >
                                    Terms of Service.
                                </Anchor> */}
                            </Text>
                        </>
                    ) : (
                        <WelcomeCard
                            email={inviteLinkQuery.data?.email}
                            setReadyToJoin={setIsLinkFromEmail}
                        />
                    )}
                </Stack>
            </section>
        </Page>
    );
};

export default Invite;
