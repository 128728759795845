import { Box } from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';

import { useUpdateCampaign } from '@hooks/useCampaigns';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { useParams } from 'react-router';
import ConversionEvent from './ConversionEvent';
import ThrottleConfig from './ThrottleConfig';

const ConversionAndThrottling = () => {
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const { actions, state } = useCampaignContext((context) => context);
    const { campaignPayload } = state;
    const { setShowFooterButtons, setCurrentStepCallback } = actions;
    const [enableConversionTracking, setEnableConversionTracking] =
        useState<boolean>(false);

    const { mutate: updateCampaign } = useUpdateCampaign(
        campaignUuid ?? '',
        false,
    );

    const handleNextStep = useCallback(() => {
        updateCampaign({
            throttleConfig: campaignPayload.throttleConfig,
            conversionConfig: campaignPayload.conversionConfig,
        });
    }, [campaignPayload, updateCampaign]);

    useEffect(() => {
        setCurrentStepCallback({
            callback: handleNextStep,
            skipExecutionAfterCallback: false,
        });
    }, [handleNextStep, setCurrentStepCallback]);

    useEffect(() => {
        let showNextButton = true;
        if (enableConversionTracking) {
            if (
                !campaignPayload.conversionConfig ||
                (campaignPayload.conversionConfig?.events[0].eventName === '' &&
                    campaignPayload.conversionConfig?.events[0].eventSource ===
                        '') ||
                !campaignPayload.conversionConfig?.events[0]?.deadline
            ) {
                showNextButton = false;
            }
        }
        setShowFooterButtons({
            next: showNextButton,
            back: true,
        });
    }, [
        campaignPayload.conversionConfig,
        enableConversionTracking,
        setShowFooterButtons,
    ]);

    return (
        <Box className="pt-6 pl-9">
            <ConversionEvent
                enableConversionTracking={enableConversionTracking}
                setEnableConversionTracking={setEnableConversionTracking}
            />
            <ThrottleConfig />
        </Box>
    );
};

export default ConversionAndThrottling;
