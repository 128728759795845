import { type DimensionType } from '../field';
import { type WarehouseTableColumn } from '../warehouse';
import { type JoinType, type RelationTableType } from './enums';
import {
    type CachedColumnValues,
    type CompiledRelation,
    type JoinTableData,
    type SummaryRelation,
} from './relations';

type CreateRelationTableRequestBase = {
    name: string;
    description?: string; // Optional description of table
    label?: string;
    database: string;
    schema: string;
    primaryKey?: string;
    isReserved?: boolean;
    dimensions: Array<WarehouseTableColumn>; // Field names must be unique across dims and metrics.
    userUploadedTable?: boolean;
};
type CreateRelationTablePrimaryRequest = CreateRelationTableRequestBase & {
    type: RelationTableType.PRIMARY;
    userId: string;
};
type CreateRelationTableRelatedRequest = CreateRelationTableRequestBase & {
    type: RelationTableType.RELATED;
};

type CreateRelationTableMaterializedRequest = CreateRelationTableRequestBase & {
    type: RelationTableType.AUDIENCE;
};

type CreateRelationTableEventRequest = CreateRelationTableRequestBase & {
    type: RelationTableType.EVENT;
    eventNameColumn: string;
    eventTimestampColumn: string;
};

type CreateRelationTableMiscellaneousRequest =
    CreateRelationTableRequestBase & {
        type: RelationTableType.MISCELLANEOUS;
    };

type CreateRelationTableCatalogRequest = CreateRelationTableRequestBase & {
    type: RelationTableType.CATALOG;
};

export type CreateRelationTableRequest =
    | CreateRelationTablePrimaryRequest
    | CreateRelationTableRelatedRequest
    | CreateRelationTableMaterializedRequest
    | CreateRelationTableEventRequest
    | CreateRelationTableMiscellaneousRequest
    | CreateRelationTableCatalogRequest;

export type CreateRelationRequest = {
    name: string;
    label: string;
};
export type GetRelationsResponse = {
    results: SummaryRelation[];
};
export type GetRelationResponse = {
    status: string;
    results: CompiledRelation;
};
export type CompileQueryResponse = {
    status: string;
    results: string;
};
export type DownloadCsvResponse = {
    status: string;
    results: {
        jobId: string;
    };
};
export type CreateRelationResponse = {
    status: string;
    uuid: string;
};
export type CreateRelationTableResponse = {
    status: string;
};

export type ApiUpdateRelationTable = {
    tableUuid: string;
    jobUuids: string[];
};

export type UpdateRelationTableRequestBase = {
    description?: string; // Optional description of table
    label?: string;
    // sqlTable: string; // To be generated
    dimensions?: { [fieldName: string]: UpdateDbtModelColumn }; // Field names must be unique across dims and metrics
    primaryKey?: string;
    sqlWhere?: string;
    relationships?: Array<CreateJoinRequest>;
};
export type UpdateRelationTablePrimaryRequest =
    UpdateRelationTableRequestBase & {
        type: RelationTableType.PRIMARY;
        userId?: string;
    };
export type UpdateRelationTableRelatedRequest =
    UpdateRelationTableRequestBase & { type: RelationTableType.RELATED };

export type UpdateRelationTableCatalogRequest =
    UpdateRelationTableRequestBase & { type: RelationTableType.CATALOG };

export type UpdateRelationTableEventRequest = UpdateRelationTableRequestBase & {
    type: RelationTableType.EVENT;
    eventNameColumn?: string;
    eventTimestampColumn?: string;
};
export type UpdateRelationTableRequest =
    | UpdateRelationTablePrimaryRequest
    | UpdateRelationTableRelatedRequest
    | UpdateRelationTableEventRequest
    | UpdateRelationTableCatalogRequest;
export type UpdateDbtModelColumn = {
    description?: string;
    hidden?: boolean;
    masked?: boolean;
    label?: string;
    // Flag to know whether to cache the table's dimension value or not
    cached?: boolean;
};

export type FieldVal = Record<string, boolean | string>;

export type DbCreateDimValConfig = {
    project_uuid: string;
    relation_id: string;
    relation_table_name: string;
    dimension_name: string;
};

export type SourceTableJoinRequest = {
    field: string;
    alias?: string;
    type?: string;
};

export type CreateJoinRequest = {
    source: SourceTableJoinRequest;
    target: TargetTableJoinRequest;
    joinType: JoinType;
};

export type JoinResponse = CreateJoinRequest;

export type CreateJoinResponse = {
    status: string;
};

export type TargetTableJoinRequest = JoinTableData;

export type RelationSchemaColumn = {
    name: string;
    label: string;
    description: string | undefined;
    hidden: boolean;
    masked: boolean;
    cached: boolean;
    cachedColumnValues: CachedColumnValues[];
    lastSyncedAt: string | null;
};

export type RelationSchemaTableResponse = {
    name: string;
    label: string;
    description: string | undefined;
    type: RelationTableType;
    primaryKey: string | undefined;
    columns: RelationSchemaColumn[];
    relationships: JoinResponse[];
};

export type UpdateCachedColumnSyncPayload = {
    cron: string;
};

export type TableFileUploadPayload = {
    blobFilePath: string;
    tableName: string;
    columns: FileTableColumn[];
    fileFormat: FileUploadFormat;
    tableType: RelationTableType;
    description?: string;
    label?: string;
    primaryKey?: string;
    userUploadedTable?: boolean;
};

export enum FileUploadFormat {
    CSV = 'CSV',
}

export interface FileTableColumn extends RelationSchemaColumn {
    nullable: boolean;
    dataType: DimensionType;
}

export type TablePreviewPayload = {
    dimensions: string[];
    limit?: number;
    offset?: number;
};
