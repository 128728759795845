import { useLocale } from '@hooks/useLocale';
import { Box, Text } from '@mantine/core';

const NoDataFound: React.FC<{ name?: string }> = ({ name }) => {
    const { t } = useLocale();
    return (
        <Box className="flex items-center justify-center w-full gap-2 px-3 py-2 ">
            <Text className="text-sm text-gray-600">
                {t('table.search_no_data_found', {
                    name: name || 'Data',
                })}
            </Text>
        </Box>
    );
};

export default NoDataFound;
