import { sortmentApi } from '@api/index';
import {
    type ApiAudienceExclusionCountQueryDto,
    type ApiCampaignClickedCountQueryDto,
    type ApiCampaignUnsubscribedCountQueryDto,
    type ApiError,
    type AudienceExclusionCountQueryRequest,
    type GenericAnalyticsCountRequest,
} from '@lightdash/common';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';

const getUnsubscribeCount = ({
    projectUuid,
    relationId,
    body,
}: {
    projectUuid: string;
    relationId: string;
    body: GenericAnalyticsCountRequest;
}) =>
    sortmentApi<ApiCampaignUnsubscribedCountQueryDto['results']>({
        method: 'POST',
        url: `/projects/${projectUuid}/relations/${relationId}/analytics/campaign-unsubscribe-count`,
        body: JSON.stringify(body),
    });

const getSpamReportsCount = ({
    projectUuid,
    relationId,
    body,
}: {
    projectUuid: string;
    relationId: string;
    body: GenericAnalyticsCountRequest;
}) =>
    sortmentApi<ApiCampaignUnsubscribedCountQueryDto['results']>({
        method: 'POST',
        url: `/projects/${projectUuid}/relations/${relationId}/analytics/campaign-spam-reports-count`,
        body: JSON.stringify(body),
    });

export const useUnsubscribeCount = ({ relationId }: { relationId: string }) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useMutation<
        ApiCampaignUnsubscribedCountQueryDto['results'],
        ApiError,
        GenericAnalyticsCountRequest
    >({
        mutationFn: (body: GenericAnalyticsCountRequest) =>
            getUnsubscribeCount({ projectUuid, relationId, body }),
        mutationKey: [QueryKeys.GET_UNSUBSCRIBE_COUNT],
        retry: false,
    });
};

export const useSpamReportsCount = ({ relationId }: { relationId: string }) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useMutation<
        ApiCampaignUnsubscribedCountQueryDto['results'],
        ApiError,
        GenericAnalyticsCountRequest
    >({
        mutationFn: (body: GenericAnalyticsCountRequest) =>
            getSpamReportsCount({ projectUuid, relationId, body }),
        mutationKey: [QueryKeys.GET_SPAM_REPORTS_COUNT],
        retry: false,
    });
};

const getClickedCount = ({
    projectUuid,
    relationId,
    body,
}: {
    projectUuid: string;
    relationId: string;
    body: GenericAnalyticsCountRequest;
}) =>
    sortmentApi<ApiCampaignClickedCountQueryDto>({
        method: 'POST',
        url: `/projects/${projectUuid}/relations/${relationId}/analytics/campaign-clicked-count`,
        body: JSON.stringify(body),
    });

export const useClickedCount = ({ relationId }: { relationId: string }) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useMutation<
        ApiCampaignClickedCountQueryDto,
        ApiError,
        GenericAnalyticsCountRequest
    >({
        mutationFn: (body: GenericAnalyticsCountRequest) =>
            getClickedCount({ projectUuid, relationId, body }),
        mutationKey: [QueryKeys.GET_CLICKED_COUNT],
        retry: false,
    });
};

const getAudienceExclusions = ({
    projectUuid,
    relationId,
    body,
}: {
    projectUuid: string;
    relationId: string;
    body: AudienceExclusionCountQueryRequest;
}) =>
    sortmentApi<ApiAudienceExclusionCountQueryDto>({
        method: 'POST',
        url: `/projects/${projectUuid}/relations/${relationId}/analytics/audience-exclusion-count`,
        body: JSON.stringify(body),
    });

export const useGetAudienceExclusions = ({
    relationId,
}: {
    relationId: string;
}) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useMutation<
        ApiAudienceExclusionCountQueryDto,
        ApiError,
        AudienceExclusionCountQueryRequest
    >({
        mutationFn: (body: AudienceExclusionCountQueryRequest) =>
            getAudienceExclusions({ projectUuid, relationId, body }),
        mutationKey: [QueryKeys.GET_AUDIENCE_EXCLUSIONS],
        retry: false,
    });
};
