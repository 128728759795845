import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import {
    getFilterRulesFromGroup,
    type FilterRule,
    type Filters,
    type JourneyDataSchema,
    type JourneyEventMapperSchema,
} from '@lightdash/common';
import { Stack } from '@mantine/core';
import { CursorClick } from '@phosphor-icons/react';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { type JourneyEventFilterConfig } from '../../types';
import JourneyActiveEventField from '../JourneyActiveEventField';
import { JourneyEventType, type ActionEventField } from '../types';
import { getEventType } from '../utils';
import EventFilterRuleForm from './EventFilterRuleForm';
import EventSelector from './EventSelector';

interface JourneyEventFiltersProps {
    /** The node parameters schema of the selected node in the journey. */
    journeyDataSchema: JourneyDataSchema;

    /** The list of events that are available for the selected node in the journey. */
    journeyEvents: JourneyEventMapperSchema[];

    /** The list of filter rules that are currently applied to the selected node in the journey. */
    filterRules: FilterRule[];

    /** Indicates if the component is in edit mode. */
    isEditMode: boolean;

    /** Function called when the filter rules are changed. */
    onChange: (value: FilterRule[]) => void;

    /** The list of filters that are currently applied to the selected node in the journey. */
    filters: Filters;

    /** Function called when the filters are changed. */
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;

    allFields: FieldWithSuggestions[];

    eventConfig: Omit<JourneyEventFilterConfig, 'eventFilters'>;

    setEventConfig: (value: JourneyEventFilterConfig) => void;

    nodeID: string;
}

/**** INFO *****
    1. To render Internal Event Filter: https://www.figma.com/design/Y89jAFoieVCVviLXdVhyji/%F0%9F%8F%9D%EF%B8%8F-Journeys-0?node-id=1461-180337&m=dev
        - Internal Events like Fyno events have a different UI. We only have Event Field, with property to specify the campaign id.
    
    2. To render Whitelisted Event Filter: https://www.figma.com/design/Y89jAFoieVCVviLXdVhyji/%F0%9F%8F%9D%EF%B8%8F-Journeys-0?node-id=1269-81602&m=dev
        - Whitelisted Events are rendered using JourneyEventPropertySelector component where the properties are based on the selected event profiles coming from JourneyParams API.
****/
const JourneyEventFilters: React.FC<JourneyEventFiltersProps> = ({
    journeyDataSchema,
    journeyEvents,
    filterRules,
    isEditMode,
    filters,
    setFilters,
    eventConfig,
    allFields,
    setEventConfig,
    nodeID,
}: JourneyEventFiltersProps) => {
    const activeEventField: ActionEventField | undefined = useMemo(() => {
        const eventType = getEventType({
            eventConfig: eventConfig,
            journeyDataSchema,
            journeyEvents,
        });

        if (eventType === JourneyEventType.INTERNAL_EVENT) {
            const internalEvent = journeyEvents.find(
                (event) =>
                    event.eventName === eventConfig.eventName &&
                    event.sourceLabel === eventConfig.eventChannel,
            );
            if (internalEvent) {
                return {
                    eventName: internalEvent.eventName,
                    eventLabel: internalEvent.label ?? internalEvent.eventName,
                    eventSourceIcon: (
                        <ChannelIcon
                            channel={internalEvent.sourceLabel}
                            showChannelName={false}
                        />
                    ),
                    eventSourceLabel: internalEvent.sourceLabel ?? '',
                    eventSource: internalEvent.source,
                    eventType: JourneyEventType.INTERNAL_EVENT,
                };
            }
        }

        if (eventType === JourneyEventType.WHITELISTED_EVENT) {
            const whitelistedEvent = journeyEvents.find(
                (event) => event.eventName === eventConfig.eventName,
            );
            if (whitelistedEvent) {
                return {
                    eventName: whitelistedEvent.eventName,
                    eventLabel:
                        whitelistedEvent.label ?? whitelistedEvent.eventName,
                    eventSourceIcon: (
                        <CursorClick color={'rgb(var(--color-mustard-800))'} />
                    ),
                    eventSourceLabel:
                        whitelistedEvent.sourceLabel ?? whitelistedEvent.source,
                    eventSource: whitelistedEvent.source,
                    eventType: JourneyEventType.WHITELISTED_EVENT,
                };
            }
        }
    }, [eventConfig, journeyDataSchema, journeyEvents]);

    const isDimensionsEmpty =
        isEmpty(filters.dimensions) ||
        getFilterRulesFromGroup(filters.dimensions).length === 0;

    return (
        <Stack className="gap-2">
            {/* Event field that is selected in the journey */}
            <EventSelector
                targetElement={
                    <JourneyActiveEventField
                        activeEventField={activeEventField}
                        isEditMode={isEditMode}
                    />
                }
                setEvent={(event) => {
                    setEventConfig({
                        eventName: event.eventName,
                        eventSource: event.source,
                        eventChannel: event.sourceLabel,
                    });
                }}
                journeyEvents={journeyEvents}
                journeyDataSchema={journeyDataSchema}
                nodeID={nodeID}
                setFilters={setFilters}
                filters={filters}
            />

            {/* Filter group that is applied to the event field */}
            <Stack
                className={`border-l-4 border-gray-100 relative ${
                    isDimensionsEmpty ? 'pl-8' : 'pl-12'
                }`}
            >
                {/* Filter rules that are pre-applied to the event field */}
                <EventFilterRuleForm
                    activeEventField={activeEventField}
                    filterRules={filterRules}
                    isDimensionsEmpty={isDimensionsEmpty}
                    journeyDataSchema={journeyDataSchema}
                    allFields={allFields}
                    isEditMode={isEditMode}
                    filters={filters}
                    setFilters={setFilters}
                />
            </Stack>
        </Stack>
    );
};

export default JourneyEventFilters;
