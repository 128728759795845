import Timestamp from '@components/common/Timestamp';
import { Button, Group, Text, Tooltip } from '@mantine/core';
// import { useInterval } from '@mantine/hooks';
import { useCallback } from 'react';
import { RefreshCw } from 'react-feather';
import {
    useDashboardRefresh,
    useGetLastRunStatus,
    useRefreshCampaignAnalytics,
} from '../../../hooks/dashboard/useDashboardRefresh';
// import useNotify from '../../../hooks/toaster/useNotify';

import { getDedupedEventTableName, WarehouseTypes } from '@lightdash/common';
import useDashboardContext from '@providers/Dashboard/useDashboardContext';
import useProjectContext from '@providers/Project/useProjectContext';
import { useParams } from 'react-router';
import { ButtonVariant } from '../../../mantineTheme';

// const REFRESH_INTERVAL_OPTIONS = [
//     {
//         value: '0.5',
//         label: '30s',
//     },
//     {
//         value: '1',
//         label: '1m',
//     },
//     {
//         value: '5',
//         label: '5m',
//     },
//     {
//         value: '15',
//         label: '15m',
//     },
//     {
//         value: '30',
//         label: '30m',
//     },
//     {
//         value: '60',
//         label: '1h',
//     },
//     {
//         value: '120',
//         label: '2h',
//     },
// ];

export const CampaignAnalyticsDashboardRefreshButton = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData } = useProjectContext();
    // const { showToastSuccess } = useNotify();
    const { data: materialisationData, refetch } = useGetLastRunStatus(
        `?name=${getDedupedEventTableName(
            projectUuid!,
            projectData?.warehouseConnection?.type ?? WarehouseTypes.SNOWFLAKE,
        )}`,
    );
    const { mutate: refreshCampaignAnalytics } = useRefreshCampaignAnalytics();
    // const [isOpen, setIsOpen] = useState(false);
    // const [refreshInterval, setRefreshInterval] = useState<
    //     undefined | number
    // >();

    const { isFetching } = useDashboardRefresh();
    const clearCacheAndFetch = useDashboardContext((c) => c.clearCacheAndFetch);

    const isOneAtLeastFetching = isFetching > 0;

    const invalidateAndSetRefreshTime = useCallback(() => {
        refreshCampaignAnalytics();
        void refetch();
        clearCacheAndFetch();
        // void invalidateDashboardRelatedQueries();
    }, [clearCacheAndFetch, refetch, refreshCampaignAnalytics]);

    // const interval = useInterval(
    //     () => invalidateAndSetRefreshTime(),
    //     refreshInterval ? refreshInterval * 1000 * 60 : 0,
    // );

    // useEffect(() => {
    //     if (refreshInterval !== undefined) {
    //         interval.start();
    //     }
    //     return interval.stop;
    // }, [interval, refreshInterval, showToastSuccess]);

    return (
        <Group>
            <Text className="flex gap-1 text-sm text-gray-600">
                Last refreshed:{' '}
                {materialisationData && materialisationData.length > 0 ? (
                    <>
                        <Timestamp
                            timestamp={
                                materialisationData[0].lastRunAt
                                    ? new Date(
                                          materialisationData[0].lastRunAt,
                                      ).toISOString()
                                    : new Date().toISOString()
                            }
                        />
                    </>
                ) : (
                    'Never'
                )}
            </Text>
            <Button.Group>
                <Tooltip
                    withinPortal
                    position="bottom"
                    label={
                        <Text>
                            Last refreshed at:{' '}
                            {materialisationData &&
                            materialisationData.length > 0 &&
                            materialisationData[0].lastRunAt
                                ? new Date(
                                      materialisationData[0].lastRunAt,
                                  ).toLocaleString()
                                : 'Never'}
                        </Text>
                    }
                >
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        loading={isOneAtLeastFetching}
                        loaderPosition="center"
                        onClick={() => {
                            invalidateAndSetRefreshTime();
                        }}
                        leftIcon={
                            <RefreshCw
                                size={13}
                                strokeWidth={2.5}
                                color={'rgb(var(--color-gray-600))'}
                                className="mr-1"
                            />
                        }
                    >
                        Refresh
                    </Button>
                </Tooltip>
                {/* <Menu
                    withinPortal
                    withArrow
                    closeOnItemClick
                    closeOnClickOutside
                    opened={isOpen}
                    onClose={() => setIsOpen((prev) => !prev)}
                >
                    <Menu.Target>
                        <Button
                            h={35}
                            variant={ButtonVariant.OUTLINED}
                            disabled={isOneAtLeastFetching}
                            p={4}
                            onClick={() => setIsOpen((prev) => !prev)}
                            className="px-3 rounded-lg shadow-card"
                        >
                            <ChevronDown
                                size={13}
                                strokeWidth={2.5}
                                color={'rgb(var(--color-gray-600))'}
                            />
                        </Button>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Label>Auto-refresh</Menu.Label>
                        <Menu.Item
                            fz="xs"
                            onClick={() => {
                                setRefreshInterval(undefined);
                            }}
                            disabled={refreshInterval === undefined}
                            sx={{
                                '&[disabled]': {
                                    color:
                                        refreshInterval === undefined
                                            ? 'text-gray-800'
                                            : 'text-gray-800',
                                },
                            }}
                            className={`text-sm font-medium text-gray-800 hover:bg-shade-4`}
                            rightSection={
                                refreshInterval === undefined ? (
                                    <Check
                                        size={13}
                                        strokeWidth={2.5}
                                        color={'rgb(var(--color-gray-600))'}
                                    />
                                ) : null
                            }
                        >
                            Off
                        </Menu.Item>
                        {REFRESH_INTERVAL_OPTIONS.map(({ value, label }) => (
                            <Menu.Item
                                fz="xs"
                                key={value}
                                onClick={() => {
                                    setRefreshInterval(+value);
                                    showToastSuccess({
                                        title: `Your dashboard will refresh every ${
                                            REFRESH_INTERVAL_OPTIONS.find(
                                                (option) =>
                                                    value === option.value,
                                            )?.label
                                        }`,
                                    });
                                }}
                                disabled={refreshInterval === +value}
                                sx={{
                                    '&[disabled]': {
                                        color:
                                            refreshInterval === +value
                                                ? 'text-gray-800'
                                                : 'text-gray-800',
                                    },
                                }}
                                className="text-sm font-medium text-gray-800 hover:bg-shade-4"
                                rightSection={
                                    refreshInterval === +value ? (
                                        <Check
                                            size={13}
                                            strokeWidth={2.5}
                                            color={'rgb(var(--color-gray-600))'}
                                        />
                                    ) : null
                                }
                            >
                                {label}
                            </Menu.Item>
                        ))}
                    </Menu.Dropdown>
                </Menu> */}
            </Button.Group>
        </Group>
    );
};
