import FilterSelectWrapper from '@components/common/Filters/FilterInputs/FilterSelectWrapper';
import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import List from '@components/common/List';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { useCampaigns } from '@hooks/useCampaigns';
import { useIsTruncated } from '@hooks/useIsTruncated';
import { useLocale } from '@hooks/useLocale';
import {
    CampaignStatus,
    CommonReservedTags,
    type CommunicationChannel,
    type FilterRule,
    type ReducedCampaign,
} from '@lightdash/common';
import { Box, Button, Group, Menu, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretRight, PaperPlaneTilt } from '@phosphor-icons/react';
import React, { useMemo } from 'react';
import { ButtonVariant } from '../../../../../mantineTheme';
import { type ActionEventField } from '../types';

interface JourneyInternalEventFilterRuleFormProps {
    filterRule: FilterRule;
    onChange: (value: string) => void;
    activeEventField: ActionEventField;
}

export const CampaignListItemTemplate: React.FC<{
    campaginName: string;
    className?: string;
}> = ({ campaginName, className }) => {
    const { t } = useLocale();
    const { ref: truncatedRef, isTruncated } = useIsTruncated<HTMLDivElement>();
    return (
        <Group className="items-center max-w-full gap-1 overflow-hidden flex-nowrap  ">
            <PaperPlaneTilt color={'rgb(var(--color-lime))'} />
            <Text className="text-sm font-medium text-gray-500  max-w-[7.5rem] ">
                {t('campaign.title')}
            </Text>
            <CaretRight />

            <Tooltip
                withinPortal
                variant="xs"
                label={campaginName}
                disabled={!isTruncated}
            >
                <Text
                    ref={truncatedRef}
                    className={`!m-0 rounded text-sm text-gray-800 font-medium max-w-[7.5rem] truncate ${className}`}
                >
                    {campaginName}
                </Text>
            </Tooltip>
        </Group>
    );
};

const JourneyInternalEventFilterRuleForm: React.FC<
    JourneyInternalEventFilterRuleFormProps
> = ({ filterRule, onChange, activeEventField }) => {
    const { t } = useLocale();
    const [opened, { open, close }] = useDisclosure();

    const campaignId = filterRule?.values?.[0] ?? '';

    //FIXME: Use infinite scroll with pagination here
    const { data: campaigns, isInitialLoading } = useCampaigns({
        perPage: 1000,
        currentPage: 1,
        query: ``,
        polling: false,
    });

    const selectedCampaign = useMemo(
        () =>
            campaigns?.data?.find((campaign) => campaign.id === campaignId) ??
            null,
        [campaigns, campaignId],
    );
    const filteredCampaignData = useMemo(() => {
        const campaignsData = campaigns?.data ?? [];
        return campaignsData.filter((campaign) => {
            if (campaign.tags?.includes(CommonReservedTags.HIDDEN)) {
                return false;
            }
            if (campaign.status === CampaignStatus.COMPLETED) {
                return true;
            }
            if (campaign.status === CampaignStatus.SCHEDULED) {
                //return campaign if campaign sent atleast once for that checking the schedule.children grater than 1;
                return (
                    campaign.schedule?.children &&
                    Number(campaign.schedule.children) > 1
                );
            }
            return false;
        });
    }, [campaigns]);

    const renderSelectedCampaign = useMemo(() => {
        if (!campaigns?.data) return null;

        return (
            <Menu
                opened={opened}
                onOpen={open}
                onClose={close}
                position="bottom-start"
                width={450}
                withinPortal={true}
            >
                <Menu.Target>
                    <Button variant={ButtonVariant.UNSTYLED} className="w-fit">
                        <FilterSelectWrapper isEditMode={true}>
                            <Box className="w-full">
                                {selectedCampaign ? (
                                    <CampaignListItemTemplate
                                        campaginName={
                                            selectedCampaign.name ?? ''
                                        }
                                    />
                                ) : (
                                    <Text>
                                        {t(
                                            'filter_rule_form.empty_property_select_label',
                                        )}
                                    </Text>
                                )}
                            </Box>
                        </FilterSelectWrapper>
                    </Button>
                </Menu.Target>

                <Menu.Dropdown>
                    <Box className="max-h-[400px] overflow-y-scroll">
                        <List<ReducedCampaign>
                            items={filteredCampaignData ?? []}
                            itemTemplate={(campaign) => (
                                <CampaignListItemTemplate
                                    campaginName={campaign.name ?? ''}
                                />
                            )}
                            onItemClick={(campaign) => {
                                onChange(campaign.id ?? '');
                                close();
                            }}
                        />
                    </Box>
                </Menu.Dropdown>
            </Menu>
        );
    }, [
        campaigns,
        filteredCampaignData,
        opened,
        open,
        close,
        selectedCampaign,
        t,
        onChange,
    ]);

    if (isInitialLoading) {
        return <SkeletonLoader height={20} />;
    }

    if (!campaigns || !filterRule) {
        return null;
    }

    return (
        <Group className="flex flex-row items-center gap-1.5 w-full  text-gray-800 font-medium whitespace-nowrap">
            <Text className="text-sm text-gray-600">
                {t('filter_group_form.group_prefix_where')}
            </Text>

            <ChannelIcon
                channel={
                    activeEventField.eventSourceLabel as CommunicationChannel
                }
                showChannelName={true}
                color="rgb(var(--color-gray-800))"
            />

            <Text className="text-sm text-gray-600">is</Text>
            {renderSelectedCampaign}
        </Group>
    );
};

export default JourneyInternalEventFilterRuleForm;
