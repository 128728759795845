import FilterOperatorSelect from '@components/common/Select/FilterOperatorSelect';
import {
    addFilterRule,
    createFilterRuleFromField,
    createFilterRuleFromFieldForCustomMetric,
    DimensionType,
    FilterOperator,
    FilterType,
    getFieldRef,
    getFilterRuleWithDefaultValue,
    getFilterTypeFromItem,
    getItemId as getFieldId,
    isCustomSqlDimension,
    isDimension,
    isMetric,
    RelationTableType,
    ReservedAudienceKeys,
    type AdditionalMetric,
    type Field,
    type FilterableField,
    type FilterRule,
    type Filters,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import useRelationContext from '@providers/Relation/useRelationContext';
import React, { useCallback, useMemo, type FC } from 'react';
import { isDerivedAdditionalMetric } from '../../utils';
import { FilterInputComponent } from '../FilterInputs';
import {
    getCaseChangeOperator,
    getFilterOperatorOptions,
} from '../FilterInputs/utils';
import FilterRuleFormPropertySelect from '../FilterRuleFormPropertySelect';
import { MenuForDimensionFilterRule } from '../FilterRuleMenuItem';
import useFiltersContext from '../FiltersProvider/useFiltersContext';
import { getBaseTableUserFieldId, isAudienceFilterRule } from '../utils';
// import { getTimeFrameFieldFromTable } from '../utils/getTimeframeFieldFromTable';
import { type FieldValueProperty } from '@components/Audience/Filters/FiltersProvider/types';
import Checkbox from '@components/common/Checkbox';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { useLocale } from '@hooks/useLocale';
import AudienceFilterRuleForm from './AudienceFilterRuleForm';
import DerivedMetricFilterRuleForm from './DerivedMetric/DerivedMetricFilterRuleForm';
import FilterRuleFormWrapper from './FilterRuleFormWrapper';

type Props = {
    fields: FilterableField[];
    filterRule: FilterRule;
    isEditMode: boolean;
    onChange: (value: FilterRule) => void;
    onDelete: () => void;
    onConvertToGroup?: () => void;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    groupIndex: number;
    customMetricFilter?: boolean;
    withinPortal?: boolean;
    showFieldSource?: boolean;
    additionalMetrics: AdditionalMetric[] | undefined;
    dynamicFieldValues:
        | PropertySelectListType<FieldValueProperty>[]
        | undefined;
    disableFieldSelector?: boolean;
};

const FilterRuleForm: FC<Props> = ({
    fields,
    filterRule,
    isEditMode,
    onChange,
    onDelete,
    // onConvertToGroup,
    filters,
    setFilters,
    groupIndex,
    customMetricFilter = false,
    withinPortal = false,
    showFieldSource = true,
    additionalMetrics,
    dynamicFieldValues,
    disableFieldSelector = false,
}) => {
    const { popoverProps, fieldsMap } = useFiltersContext();

    const { activeRelation } = useRelationContext();

    const { t } = useLocale();

    const activeField = useMemo(() => {
        const fieldId = filterRule.target.fieldId;
        if (isMetric(fieldsMap[fieldId])) {
            return fieldsMap[fieldId];
        }
        if (isCustomSqlDimension(fieldsMap[fieldId])) {
            return fieldsMap[fieldId];
        }

        return fields.find((field) => {
            return getFieldId(field) === filterRule.target.fieldId;
        });
    }, [filterRule, fields, fieldsMap]);

    //Info: Check if the filter rule is derived from additional metric, for events and related records
    const isActiveFieldDerivedAdditionalMetric = useMemo(() => {
        if (!activeField || !additionalMetrics) return false;
        return isDerivedAdditionalMetric(activeField, additionalMetrics);
    }, [activeField, additionalMetrics]);

    const filterType = useMemo(() => {
        return activeField
            ? getFilterTypeFromItem(activeField)
            : FilterType.STRING;
    }, [activeField]);

    const filterOperatorOptions = useMemo(() => {
        return getFilterOperatorOptions(filterType);
    }, [filterType]);

    const onCaseSensitiveChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({
                ...filterRule,
                operator: getCaseChangeOperator(
                    filterRule.operator,
                    event.currentTarget.checked,
                ),
            });
        },
        [filterRule, onChange],
    );

    const onFieldChange = useCallback(
        (field: FilterableField) => {
            if (isDimension(field) && field?.type === DimensionType.EVENT) {
                onChange(
                    createFilterRuleFromField(field, field.fieldReference),
                );
                return;
            }

            const fieldId: string = getFieldId(field);
            const fieldRef: string = getFieldRef(field as Field);
            const selectedField: FilterableField | undefined = fields.find(
                (fieldValue) => getFieldId(fieldValue) === fieldId,
            );

            // If no selected field or active field, exit early
            if (!selectedField || !activeField) {
                return;
            }

            // Check if the selected field matches the active field type and is not an audience table type
            if (
                selectedField.type === activeField.type &&
                'tableType' in selectedField &&
                selectedField.tableType !== RelationTableType.AUDIENCE
            ) {
                if (customMetricFilter) {
                    onChange({
                        ...filterRule,
                        target: {
                            fieldId,
                            fieldRef,
                        },
                    });
                    return;
                }
                onChange({
                    ...filterRule,
                    target: {
                        fieldId,
                    },
                });
                return;
            }

            // Handle custom metric filter case
            if (customMetricFilter) {
                onChange(
                    createFilterRuleFromFieldForCustomMetric(selectedField),
                );
                return;
            }

            // Create a new filter rule from the selected field
            const newFilterRule: FilterRule =
                createFilterRuleFromField(selectedField);

            // If the selected field is an audience table type, update the filter rule accordingly
            if (
                'tableType' in selectedField &&
                selectedField.tableType === RelationTableType.AUDIENCE
            ) {
                newFilterRule.target.fieldId =
                    (activeRelation &&
                        getBaseTableUserFieldId(activeRelation)) ??
                    newFilterRule.target.fieldId;
                newFilterRule.values = [
                    `${ReservedAudienceKeys.SRT_AUDIENCE}${selectedField.table}`,
                ];
            }

            // Update the filter rule
            onChange(newFilterRule);
        },
        [
            activeField,
            activeRelation,
            fields,
            filterRule,
            onChange,
            customMetricFilter,
        ],
    );

    // const addTimeWindow = useCallback(() => {
    //     const field = getTimeFrameFieldFromTable({
    //         fields,
    //         tableId:
    //             activeField && isTableCalculation(activeField)
    //                 ? ''
    //                 : activeField?.table,
    //         activeRelation,
    //     });
    //     if (!field) return;

    //     if (isField(field) && isFilterableField(field)) {
    //         setFilters(
    //             addFilterRule({
    //                 filters,
    //                 field,
    //                 ...(Boolean(field.fieldReference) && {
    //                     value: field.fieldReference,
    //                 }),
    //             }),
    //             false,
    //             groupIndex,
    //         );
    //     }
    // }, [activeField, activeRelation, fields, filters, groupIndex, setFilters]);

    const duplicateFilter = useCallback(() => {
        if (activeField) {
            setFilters(
                addFilterRule({
                    filters,
                    field: activeField,
                    ...(filterRule.values?.length && {
                        value: filterRule.values,
                    }),
                }),
                false,
                groupIndex,
            );
        }
    }, [activeField, filterRule.values, filters, groupIndex, setFilters]);

    const renderSelectComponent = useMemo(() => {
        if (!activeField) return null;
        return (
            <FilterOperatorSelect
                isDisabled={!isEditMode}
                filterOperatorOptions={filterOperatorOptions}
                selectedOperator={filterRule.operator}
                onChange={(value) => {
                    if (!value) return;
                    onChange(
                        getFilterRuleWithDefaultValue(
                            activeField,
                            {
                                ...filterRule,
                                operator: value as FilterRule['operator'],
                            },
                            (filterRule.values?.length || 0) > 0
                                ? filterRule.values
                                : [1],
                        ),
                    );
                }}
            />
        );
    }, [activeField, filterOperatorOptions, filterRule, isEditMode, onChange]);

    const renderFieldSelect = useMemo(() => {
        if (!activeField) return null;
        return (
            <FilterRuleFormPropertySelect
                fields={fields}
                onSubmit={(items) => {
                    onFieldChange(items[0]);
                }}
                isEditMode={isEditMode && !disableFieldSelector}
                activeField={activeField}
                withinPortal={withinPortal}
                showFieldSource={showFieldSource}
            />
        );
    }, [
        activeField,
        fields,
        isEditMode,
        onFieldChange,
        showFieldSource,
        withinPortal,
        disableFieldSelector,
    ]);

    const renderFilterRuleOptions = useMemo(() => {
        if (!isEditMode || !activeField) return null;

        return (
            <MenuForDimensionFilterRule
                onDelete={onDelete}
                duplicateFilter={duplicateFilter}
            />
        );
    }, [isEditMode, activeField, onDelete, duplicateFilter]);

    const renderCaseSensitiveCheckbox = useMemo(() => {
        if (
            filterType === FilterType.STRING &&
            (filterRule.operator === FilterOperator.EQUALS ||
                filterRule.operator === FilterOperator.NOT_EQUALS ||
                filterRule.operator ===
                    FilterOperator.EQUALS_CASE_INSENSITIVE ||
                filterRule.operator ===
                    FilterOperator.NOT_EQUALS_CASE_INSENSITIVE)
        ) {
            return (
                <Checkbox
                    label={t('audience_filter.case_sensitive')}
                    onChange={onCaseSensitiveChange}
                    checked={
                        filterRule.operator === FilterOperator.EQUALS ||
                        filterRule.operator === FilterOperator.NOT_EQUALS
                    }
                />
            );
        }
        return null;
    }, [filterRule, filterType, onCaseSensitiveChange, t]);

    if (
        activeField &&
        isActiveFieldDerivedAdditionalMetric &&
        additionalMetrics
    ) {
        return (
            <DerivedMetricFilterRuleForm
                filterRule={filterRule}
                onChange={onChange}
                isEditMode={isEditMode}
                fields={fields}
                onFieldChange={onFieldChange}
                additionalMetrics={additionalMetrics}
                groupIndex={groupIndex}
                filters={filters}
                setFilters={setFilters}
                onDeleteItem={onDelete}
                dynamicFieldValues={dynamicFieldValues}
            />
        );
    }
    if (isAudienceFilterRule(filterRule, fields)) {
        return (
            <AudienceFilterRuleForm
                filterRule={filterRule}
                onChange={onChange}
                isEditMode={isEditMode}
                fields={fields}
                onDelete={onDelete}
                setFilters={setFilters}
                groupIndex={groupIndex}
                filters={filters}
            />
        );
    }

    return (
        <FilterRuleFormWrapper>
            {activeField ? (
                <>
                    <Box>{renderFieldSelect}</Box>
                    <Box>{renderSelectComponent}</Box>
                    <Box>
                        <FilterInputComponent
                            filterType={filterType}
                            field={activeField}
                            rule={filterRule}
                            onChange={onChange}
                            disabled={!isEditMode}
                            popoverProps={popoverProps}
                            dynamicFieldValues={dynamicFieldValues}
                        />
                    </Box>
                    <Box>{renderCaseSensitiveCheckbox}</Box>
                </>
            ) : null}

            {renderFilterRuleOptions}
        </FilterRuleFormWrapper>
    );
};

export default React.memo(FilterRuleForm);
