import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';

import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import { isEventHidden } from '@components/EventsManager/utils';
import Page from '@components/Page/Page';
import { useGetEventLogsStats } from '@hooks/useEvents';
import { useLocale } from '@hooks/useLocale';
import { CommonReservedTags, Sources } from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import useApp from '@providers/App/useApp';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import EventManagerRightSection from '../components/EventsManager/EventManagerRightSection';
import EventsModal from '../components/EventsManager/EventsModal';
import EventsTable from '../components/EventsManager/Table/EventsTable';
import { EventManagerType } from '../components/EventsManager/types';

const EventsBuilderContainer: React.FC<{}> = () => {
    const { t } = useLocale();

    const [opened, { open, close }] = useDisclosure();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { user } = useApp();
    const ability = useAbilityContext();
    const [isHiddenActive, setIsHiddenActive] = useState<boolean>(false);
    const canEditEventsMapper = ability.can(
        'update',
        subject('EventsMapper', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const { data: events, isInitialLoading } = useGetEventLogsStats();
    const navigate = useNavigate();
    // console.log(eventLogsStats, isEventLogsStatsLoading);
    //TODO: below code is commented is to support the backend pagination
    // const [searchString, setSearchString] = useState('');
    // const [currentPage, setCurrentPage] = useState(1);
    // perPage: 10,
    // currentPage,
    // query: `name=${searchString}`,
    const filteredEvents = useMemo(() => {
        let processedEvents = events?.filter(
            (event) => event?.source?.toLowerCase() !== Sources.FYNO,
        );
        if (isHiddenActive) {
            processedEvents = processedEvents?.filter((event) =>
                isEventHidden(event),
            );
        } else {
            processedEvents = processedEvents?.filter(
                (event) => !isEventHidden(event),
            );
        }
        return processedEvents;
    }, [events, isHiddenActive]);
    const hiddenEvents = useMemo(() => {
        return events?.filter((event) =>
            event?.tags?.includes(CommonReservedTags.HIDDEN),
        );
    }, [events]);
    const hideHiddenButton = useMemo(() => {
        return hiddenEvents?.length === 0;
    }, [hiddenEvents]);

    useEffect(() => {
        if ((hiddenEvents?.length ?? 0) === 0) {
            setIsHiddenActive(false);
        }
    }, [hiddenEvents]);

    if (isInitialLoading) {
        return <SuboptimalState loading />;
    }
    return (
        <ManagerBuilderContainer
            withContentPadding={false}
            title={t('events.title')}
            rightSection={
                canEditEventsMapper && (
                    <EventManagerRightSection onCloneClick={open} />
                )
            }
        >
            <EventsTable
                events={filteredEvents ?? []}
                managerType={EventManagerType.VIEW}
                handleRowClick={(row) => {
                    if (canEditEventsMapper) {
                        void navigate(
                            `/projects/${projectUuid}/events/${row.original.uuid}/${row.original.source}/edit`,
                        );
                    } else {
                        void navigate(
                            `/projects/${projectUuid}/events/${row.original.uuid}/${row.original.source}/view`,
                        );
                    }
                }}
                isHiddenActive={isHiddenActive}
                onHiddenChange={(isHiddenFlag: boolean) => {
                    setIsHiddenActive(isHiddenFlag);
                }}
                hideHiddenButton={hideHiddenButton}
                // onPageChange={async (newPage) => {
                //     setCurrentPage(newPage);
                // }}
                // onSearchChange={(newSearchString) => {
                //     setSearchString(newSearchString);
                // }}
            />
            <EventsModal
                opened={opened}
                onClose={close}
                events={filteredEvents ?? []}
                inProvider={false}
            />
        </ManagerBuilderContainer>
    );
};

const EventsManager = () => {
    const { t } = useLocale();
    return (
        <>
            <Page
                title={t('events')}
                withFullHeight
                withPaddedContent
                withNavbar
                withFixedContent
            >
                <EventsBuilderContainer />
            </Page>
        </>
    );
};
export default EventsManager;
