import HiddenButton, { type HideProps } from '@components/Hidden/HiddenButton';
import Manager from '@components/Manager';
import { useLocale } from '@hooks/useLocale';
import {
    ShowDataType,
    type Pagination,
    type ReducedTemplate,
} from '@lightdash/common';
import { type SortingState } from '@tanstack/react-table';
import React, { useCallback, type Dispatch, type SetStateAction } from 'react';
import { useTemplateColumns, useTemplateGrid } from './useTemplateTableUtils';

type BaseTemplateTableProps = {
    templates: ReducedTemplate[];
    pagination: Pagination;
    onPageChange: (page: number) => void;
    isDataFetching: boolean;
    handleRowPress: (data: ReducedTemplate) => void;
    withBorder?: boolean;
    tableBackgroundColor?: string;
    handleSearchInput: (value: string) => void;
    searchValue: string;
    activeTab: string;
    sorting: SortingState;
    setSorting: Dispatch<SetStateAction<SortingState>>;
    isNeedToRefresh: boolean;
    setIsNeedToRefresh: Dispatch<SetStateAction<boolean>>;
};
type ManagerTemplateTableProps = BaseTemplateTableProps &
    HideProps & {
        isManager: true;
        hideHiddenButton: boolean;
    };
type NonManagerTemplateTableProps = BaseTemplateTableProps & {
    isManager: false;
};

type TemplateTableProps =
    | ManagerTemplateTableProps
    | NonManagerTemplateTableProps;
const TemplateTable: React.FC<TemplateTableProps> = ({
    templates,
    pagination,
    onPageChange,
    isDataFetching,
    handleRowPress,
    withBorder,
    tableBackgroundColor,
    handleSearchInput,
    searchValue,
    activeTab,
    isManager,
    sorting,
    setSorting,
    isNeedToRefresh,
    setIsNeedToRefresh,
    ...hideProps
}) => {
    const { hideHiddenButton, isHiddenActive, onHiddenChange } =
        hideProps as ManagerTemplateTableProps;

    const gridColumns = useTemplateGrid({
        handleClick: handleRowPress,
    });
    const { t } = useLocale();
    const handeMenuClick = useCallback(() => {
        setIsNeedToRefresh(true);
        onPageChange(1);
    }, [onPageChange, setIsNeedToRefresh]);
    const templateColumns = useTemplateColumns(handeMenuClick);

    return (
        <div>
            {templates && gridColumns && (
                <Manager<ReducedTemplate>
                    name={t('templates')}
                    tableData={templates}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: templateColumns,
                        },
                        {
                            format: ShowDataType.GRID,
                            formatData: gridColumns,
                        },
                    ]}
                    searchPlaceholder={t(
                        'template_manager.table_search_placeholder',
                        { channel: activeTab },
                    )}
                    paginationText={t('template_manager.pagination_text')}
                    isManualPagination={true}
                    onPageChange={onPageChange}
                    pagination={pagination}
                    isDataFetching={isDataFetching}
                    gridItemBorder={false}
                    withBorder={withBorder}
                    pageSize={8}
                    tableBackgroundColor={tableBackgroundColor}
                    isEditable={false}
                    onSearchChange={(value) => {
                        handleSearchInput(value);
                        setIsNeedToRefresh(true);
                    }}
                    searchValue={searchValue}
                    leftSection={
                        isManager &&
                        !hideHiddenButton && (
                            <HiddenButton
                                isHiddenActive={isHiddenActive}
                                onHiddenChange={(isHiddenFlag: boolean) => {
                                    onHiddenChange(isHiddenFlag);
                                    setIsNeedToRefresh(true);
                                }}
                                activeHideText={t(
                                    'template_manager.active_hide_button_text',
                                )}
                            />
                        )
                    }
                    handleRowClick={(row) => {
                        handleRowPress(row.original);
                    }}
                    sorting={sorting}
                    setSorting={(updater) => {
                        setSorting(updater);
                        onPageChange(1);
                        setIsNeedToRefresh(true);
                    }}
                    isNeedToRefresh={isNeedToRefresh}
                    onNeedToRefresh={setIsNeedToRefresh}
                />
            )}
        </div>
    );
};

export default React.memo(TemplateTable);
