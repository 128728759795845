import {
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from '@components/Audience/Filters/FieldListItem/utils';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import SubscriptionGroupFilter, {
    type Option,
} from '@components/Campaigns/Builder/Steps/CampaignBuilderContent/SetupContent/SubscriptionGroupFilter';
import LeftLabelInput from '@components/common/Inputs/LeftLabelInput';
import TextInput from '@components/common/Inputs/TextInput';
import FieldSelect from '@components/common/Select/FieldSelect';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import useFileUpload from '@hooks/useFileUpload';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceType,
    CommunicationChannel,
    ContentMappingSection,
    WAFilesizeLimits,
    WAHeaderType,
    WhatsappHeaderVariable,
    type ApiResponse,
    type ContentMapping,
    type ExternalTemplate,
    type TemplateContentDetails,
    type TemplateVariableDetails,
} from '@lightdash/common';
import {
    Box,
    Button,
    FileButton,
    Group,
    Loader,
    Stack,
    Text,
} from '@mantine/core';
import { MIME_TYPES } from '@mantine/dropzone';
import { UploadSimple } from '@phosphor-icons/react';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import React, { useCallback, useMemo, useRef } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import { getFileSizeErrorMsg } from '../utils';

const TemplateVariableGroups = {
    [ContentMappingSection.SUBJECT]: 'Subject variables',
    [ContentMappingSection.BODY]: 'Content variables',
    [ContentMappingSection.BUTTONS]: 'Button variables',
    [ContentMappingSection.HEADER]: 'Header variables',
};

interface VariablesProps {
    dimensions: FieldWithSuggestions[];
    variablesContent: TemplateVariableDetails;
    isDisabled?: boolean;
    errors: {
        [key: string]: string;
    };
    onVariableChange: (
        value: FieldWithSuggestions | undefined,
        key: string,
        groupName: ContentMappingSection,
        defaultValue?: string,
    ) => void;
    onDefaultValueChange: (
        value: string,
        key: string,
        groupName: ContentMappingSection,
    ) => void;
    options: Option[] | undefined;
    templateMetadata: TemplateContentDetails | ExternalTemplate | undefined;
    additionalPropertySelectList?: PropertySelectListType<
        FieldWithSelectStatus,
        PropertySelectGroupType
    >[];
    setErrors: React.Dispatch<any>;
}

const Variables: React.FC<VariablesProps> = ({
    dimensions,
    variablesContent,
    isDisabled,
    errors,
    onVariableChange,
    onDefaultValueChange,
    options,
    templateMetadata,
    additionalPropertySelectList,
    setErrors,
}) => {
    const { t } = useLocale();
    const fileButtonRef = useRef<HTMLButtonElement | null>(null);
    const { campaignPayload } = useCampaignContext((context) => context.state);
    const { mutateAsync: uploadImageAsync, isLoading } = useFileUpload<
        { url: string } & ApiResponse
    >();

    const { contentMappings } = campaignPayload;

    const dataVariables = useMemo(() => {
        const variables = variablesContent;
        if (!variables || !Object.keys(variables)?.length) return;

        let variablesObject: {
            [key: string]: {
                value: string;
                defaultValue: string;
            };
        } = {};
        Object.keys(variables)?.forEach((groupName) => {
            (variables as any)?.[groupName as ContentMappingSection]?.forEach(
                (key: string) => {
                    variablesObject = {
                        ...variablesObject,
                        [groupName]: {
                            ...variablesObject[groupName],
                            [key]: {
                                value:
                                    contentMappings?.[
                                        groupName as ContentMappingSection
                                    ]?.[key]?.value || '',
                                defaultValue:
                                    contentMappings?.[
                                        groupName as ContentMappingSection
                                    ]?.[key]?.defaultValue || '',
                            },
                        },
                    };
                },
            );
        });

        return variablesObject;
    }, [variablesContent, contentMappings]);

    const keyValueLabel = useMemo(() => {
        if (campaignPayload.channel === CommunicationChannel.WHATSAPP) {
            return (templateMetadata as any)?.contents.header?.type;
        }
        return '';
    }, [campaignPayload.channel, templateMetadata]);

    const acceptFileTypes = useMemo(() => {
        switch (keyValueLabel) {
            case WAHeaderType.VIDEO:
                return MIME_TYPES.mp4;
            case WAHeaderType.FILE:
                return `${MIME_TYPES.doc},${MIME_TYPES.docx},${MIME_TYPES.xls},${MIME_TYPES.xlsx},${MIME_TYPES.ppt},${MIME_TYPES.pptx},${MIME_TYPES.csv}`;
            case WAHeaderType.IMAGE:
                return `${MIME_TYPES.png},${MIME_TYPES.jpeg}`;
            default:
                return '';
        }
    }, [keyValueLabel]);

    const handleFileChange = useCallback(
        (file: File | null) => {
            if (file) {
                const fileList = [file];
                const headerType = (templateMetadata as any)?.contents.header
                    ?.type;
                if (file.size > WAFilesizeLimits[headerType as WAHeaderType]) {
                    setErrors({
                        ...errors,
                        [ContentMappingSection.HEADER]: {
                            url: getFileSizeErrorMsg(headerType),
                        },
                    });
                    return;
                } else {
                    setErrors({
                        ...errors,
                        [ContentMappingSection.HEADER]: {
                            url: '',
                        },
                    });
                }
                uploadImageAsync(fileList as unknown as FileList)
                    .then((response) => {
                        onDefaultValueChange(
                            response.url ?? '',
                            WhatsappHeaderVariable,
                            ContentMappingSection.HEADER,
                        );
                    })
                    .catch(() => {});
            }
        },
        [
            errors,
            onDefaultValueChange,
            setErrors,
            templateMetadata,
            uploadImageAsync,
        ],
    );

    const renderHeaderUrlInput = useCallback(
        (variableDefaultValue: string) => {
            return (
                <Box className="flex items-center">
                    <FileButton
                        onChange={(file) => handleFileChange(file)}
                        accept={acceptFileTypes}
                    >
                        {(props) => (
                            <Button
                                {...props}
                                ref={fileButtonRef}
                                variant={ButtonVariant.OUTLINED}
                                disabled={isDisabled}
                                className="text-sm font-medium !bg-shade-2 hover:!bg-shade-4 border border-shade-4 rounded-lg rounded-r-none py-2.5"
                            >
                                {isLoading ? (
                                    <Loader size={14} />
                                ) : (
                                    <UploadSimple size={14} weight="regular" />
                                )}
                            </Button>
                        )}
                    </FileButton>
                    <TextInput
                        value={variableDefaultValue}
                        placeholder={t('variables.select_file')}
                        className="!rounded-l-none w-[13rem] !cursor-pointer"
                        styles={() => ({
                            input: {
                                borderTopLeftRadius: '0px !important',
                                borderBottomLeftRadius: '0px !important',
                                cursor: 'pointer',
                                caretColor: 'transparent',
                            },
                        })}
                        onClick={() => fileButtonRef.current?.click()}
                        disabled={isDisabled}
                    />
                </Box>
            );
        },
        [acceptFileTypes, handleFileChange, isDisabled, isLoading, t],
    );
    return (
        <Stack>
            {Object.keys(variablesContent).map((groupName: string) => {
                const variableObject =
                    variablesContent?.[
                        groupName as keyof TemplateVariableDetails
                    ];

                return variableObject && variableObject?.length > 0 ? (
                    <Stack key={groupName} className="">
                        <Text className="text-sm text-gray-500">
                            {groupName === ContentMappingSection.HEADER &&
                            campaignPayload.channel ===
                                CommunicationChannel.WHATSAPP
                                ? 'Header variables'
                                : (TemplateVariableGroups as any)?.[
                                      groupName as keyof TemplateVariableDetails
                                  ]}
                        </Text>
                        {variableObject?.map((key: string) => {
                            const variableGroup = dataVariables?.[
                                groupName as ContentMappingSection
                            ] as unknown as ContentMapping;
                            const dataVariableValue =
                                variableGroup?.[key]?.value;
                            let variableValue =
                                (dataVariableValue &&
                                    dimensions?.find(
                                        (dimension) =>
                                            dimension?.uniqueIdentifier ===
                                            dataVariableValue,
                                    )) ||
                                undefined;

                            if (
                                !variableValue &&
                                additionalPropertySelectList
                            ) {
                                variableValue = additionalPropertySelectList
                                    .flatMap((item) => item.items)
                                    .find(
                                        (subItem) =>
                                            subItem.uniqueIdentifier ===
                                            dataVariableValue,
                                    );
                            }
                            const variableDefaultValue =
                                variableGroup?.[key]?.defaultValue;

                            return (
                                <Stack className="gap-1.5" key={key}>
                                    <Text className="text-sm text-gray-600">
                                        {'{{ '}
                                        <span className="font-medium text-gray-800">
                                            {key === WhatsappHeaderVariable
                                                ? `${keyValueLabel}_url`
                                                : key}
                                        </span>
                                        {' }}'}
                                    </Text>

                                    <Group
                                        className="gap-2.5 flex-nowrap"
                                        align="flex-start"
                                    >
                                        {options ? (
                                            <SubscriptionGroupFilter
                                                label={undefined}
                                                error={
                                                    (
                                                        errors?.[
                                                            groupName
                                                        ] as unknown as {
                                                            [
                                                                key: string
                                                            ]: string;
                                                        }
                                                    )?.[key]
                                                }
                                                value={
                                                    variableValue?.uniqueIdentifier
                                                }
                                                onChange={(value) =>
                                                    onVariableChange(
                                                        {
                                                            uniqueIdentifier:
                                                                value,
                                                        } as FieldWithSuggestions,
                                                        key,
                                                        groupName as ContentMappingSection,
                                                    )
                                                }
                                                width="25rem"
                                                anchorClass="!w-[18rem]"
                                                options={options}
                                            />
                                        ) : (
                                            <FieldSelect
                                                size="sm"
                                                item={variableValue}
                                                items={dimensions}
                                                onChange={(
                                                    value: FieldWithSuggestions,
                                                ) =>
                                                    onVariableChange(
                                                        value,
                                                        key,
                                                        groupName as ContentMappingSection,
                                                    )
                                                }
                                                placeholder={t(
                                                    'campaign.create.template_variable.placeholder',
                                                    {
                                                        variable:
                                                            key ===
                                                            WhatsappHeaderVariable
                                                                ? `${keyValueLabel}_url`
                                                                : key,
                                                    },
                                                )}
                                                isDisabled={isDisabled}
                                                error={
                                                    (
                                                        errors?.[
                                                            groupName
                                                        ] as unknown as {
                                                            [
                                                                key: string
                                                            ]: string;
                                                        }
                                                    )?.[key]
                                                }
                                                additionalPropertySelectList={
                                                    additionalPropertySelectList
                                                }
                                            />
                                        )}
                                        {campaignPayload.audienceType ===
                                            AudienceType.WAREHOUSE && (
                                            <Box className="flex items-center gap-2">
                                                {key !==
                                                    WhatsappHeaderVariable && (
                                                    <LeftLabelInput
                                                        className="!min-w-[16rem]"
                                                        value={
                                                            variableDefaultValue
                                                        }
                                                        onChange={(
                                                            event: React.ChangeEvent<HTMLInputElement>,
                                                        ) => {
                                                            onDefaultValueChange(
                                                                event.target
                                                                    .value,
                                                                key,
                                                                groupName as ContentMappingSection,
                                                            );
                                                        }}
                                                        disabled={isDisabled}
                                                    />
                                                )}
                                                {key ===
                                                    WhatsappHeaderVariable &&
                                                    renderHeaderUrlInput(
                                                        variableDefaultValue ??
                                                            '',
                                                    )}
                                            </Box>
                                        )}
                                    </Group>

                                    <br />
                                </Stack>
                            );
                        })}
                    </Stack>
                ) : null;
            })}
        </Stack>
    );
};

export default Variables;
