import { type CommunicationChannel } from './communications';
import { type SubscriptionCategory } from './subscriptionCategory';
import { type LightdashUser } from './user';

export type SubscriptionGroup = {
    id: string;
    projectId: string;
    name: string;
    description: string;
    channel: CommunicationChannel;
    type: SubscriptionGroupType;
    isGlobal: boolean;
    weight: number;
    createdAt: Date;
    categoryId: string | null; // null, for global groups
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
};

export enum SubscriptionGroupType {
    REQUIRED = 'REQUIRED',
    OPT_IN = 'OPT_IN',
    OPT_OUT = 'OPT_OUT',
}

export type SubscriptionGroupWithName = SubscriptionGroup & {
    createdByName: Pick<LightdashUser, 'firstName' | 'lastName'>;
    updatedByName: Pick<LightdashUser, 'firstName' | 'lastName'>;
};

export type CategoriesWithGroups = {
    category: SubscriptionCategory;
    groups: SubscriptionGroupWithName[];
}[];

export type PartialCategoriesWithGroups = {
    data: {
        category: Partial<SubscriptionCategory>;
        groups: Partial<SubscriptionGroupWithName>[];
    }[];
};

export type SubscriptionGroupCreateRequest = {
    name: string;
    description: string;
    channel: CommunicationChannel;
    categoryId: string | null;
    type: SubscriptionGroupType;
};

export type SubscriptionGroupUpdateRequest = {
    name?: string;
    description?: string;
    categoryId?: string | null;
    type?: SubscriptionGroupType;
    weight?: number;
};

export type CsvHeaders = {
    user_id: string;
    subscription_group_id: string;
    channel: CommunicationChannel;
    action_taken: boolean;
};

export type UploadUserSubscriptionDetailsResponse = {
    validCount: number;
    invalidCount: number;
    successCount: number;
    failureCount: number;
};
