import ChannelTabs from '@components/ChannelTabs';
import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';
import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import Modal from '@components/common/modal/Modal';
import Page from '@components/Page/Page';
import SyncedWhatsappTemplates from '@components/TemplateManager/Table/SyncedWhatsappTemplates';
import TemplateTable from '@components/TemplateManager/Table/TemplateTable';
import TemplateBuilderManager from '@components/Templates/Builder/TemplateBuilderManager';
import TemplateOptionsModal from '@components/Templates/TemplateOptionsModal';
import WhatsappPreview from '@components/Templates/WhatsappManager/Preview';
import { useGetChannelIntegrations } from '@hooks/useChannels';
import { useTemplates } from '@hooks/useGetTemplate';
import { useLocale } from '@hooks/useLocale';
import {
    CommonReservedTags,
    CommunicationChannel,
    type AnyType,
    type ReducedTemplate,
    type SyncedTemplateBasicDetails,
} from '@lightdash/common';
import { Box, Button, Menu, Tabs, Text } from '@mantine/core';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { CaretDown, PlusCircle } from '@phosphor-icons/react';
import { type SortingState } from '@tanstack/react-table';
import { ROWS_PER_PAGE, SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ButtonVariant } from '../mantineTheme';

const DEFAULT_PROVIDER = CommunicationChannel.EMAIL;

const TemplateBuilderContainer: React.FC<{}> = ({}) => {
    // TODO - LOOK FOR AN ALTERNATE SOLUTION
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [editMode, setEditMode] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchValue, setSearchValue] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const [activePill, setActivePill] = useState<
        CommunicationChannel | undefined
    >(undefined);
    const [selectedChannel, setSelectedChannel] =
        useState<CommunicationChannel>(DEFAULT_PROVIDER);

    useEffect(() => {
        if (location.hash) {
            setActivePill(location.hash.substring(1) as CommunicationChannel);
        } else {
            setActivePill(CommunicationChannel.EMAIL);
            void navigate(
                `/projects/${projectUuid}/templates#${CommunicationChannel.EMAIL.toLowerCase()}`,
            );
        }
    }, [location.hash, navigate, projectUuid]);

    const handlePillChange = (pill: CommunicationChannel) => {
        setActivePill(pill);
        void navigate(`/projects/${projectUuid}/templates#${pill}`);
    };

    const { t } = useLocale();
    const [isHiddenActive, setIsHiddenActive] = useState<boolean>(false);
    const [isNeedToRefresh, setIsNeedToRefresh] = useState<boolean>(true);
    let { data: channelIntegration } = useGetChannelIntegrations(
        activePill ?? CommunicationChannel.EMAIL,
    );
    const [sorting, setSorting] = useState<SortingState>([
        {
            id: 'updatedAt',
            desc: true,
        },
    ]);
    const [currentPage, setCurrentPage] = useState(1);

    const {
        data: templates,
        isFetching,
        isLoading,
    } = useTemplates(
        activePill ?? CommunicationChannel.EMAIL,
        ROWS_PER_PAGE,
        currentPage,
        searchValue,
        activePill !== CommunicationChannel.WHATSAPP,
        (isHiddenActive
            ? `includesTags=${CommonReservedTags.HIDDEN}`
            : `excludesTags=${CommonReservedTags.HIDDEN}`) +
            (sorting[0]?.id
                ? `&sort=${sorting[0]?.id}&order=${
                      sorting[0]?.desc ? 'desc' : 'asc'
                  }`
                : ''),
    );
    const { data: hiddenTemplates } = useTemplates(
        activePill ?? CommunicationChannel.EMAIL,
        ROWS_PER_PAGE,
        1,
        searchValue,
        activePill !== CommunicationChannel.WHATSAPP,
        `includesTags=${CommonReservedTags.HIDDEN}`,
    );
    useEffect(() => {
        if ((hiddenTemplates?.data?.length ?? 0) === 0) {
            setIsHiddenActive(false);
        }
    }, [hiddenTemplates]);
    const [opened, { toggle, close }] = useDisclosure(false);
    const [whatsappPreview, setWhatsappPreview] = useState<boolean>(false);
    // TODO - Fix this type with Naimish BE is sending the response in a different type from what we have in controller
    const [selectedWhatsappTemplate, setSelectedWhatsappTemplate] =
        useState<AnyType>(undefined);

    const [selectedTemplate, setSelectedTemplate] = useState<
        string | undefined
    >(undefined);
    const previousActivePill = useRef(activePill);
    const fetchData = useCallback(async () => {
        if (activePill !== previousActivePill.current) {
            previousActivePill.current = activePill;
            setCurrentPage(1);
        }
    }, [activePill]);
    useEffect(() => {
        void fetchData();
    }, [fetchData, currentPage, activePill]);

    const handleTemplateSelect = (data: ReducedTemplate) => {
        if (projectUuid) {
            void navigate(
                `/projects/${projectUuid}/templates/${activePill}/${data.id}`,
            );
        }
    };

    const handleWhatsappTemplateSelect = (
        payload: SyncedTemplateBasicDetails,
    ) => {
        setSelectedTemplate(payload.name);
        setEditMode(true);
        setSelectedWhatsappTemplate(payload);
        setWhatsappPreview(true);
    };

    const handleSearchInput = (value: string) => {
        setSearchValue(value);
        setCurrentPage(1);
    };

    const getContent = () => {
        if (activePill === CommunicationChannel.WHATSAPP) {
            return (
                <SyncedWhatsappTemplates
                    integrationId=""
                    handleRowClick={handleWhatsappTemplateSelect}
                />
            );
        }
        if (templates?.data) {
            return (
                <TemplateTable
                    onPageChange={async (newPage) => {
                        setCurrentPage(newPage);
                    }}
                    pagination={templates.pagination}
                    templates={templates?.data}
                    isDataFetching={isFetching || isLoading}
                    handleRowPress={handleTemplateSelect}
                    handleSearchInput={handleSearchInput}
                    searchValue={searchValue}
                    activeTab={activePill ?? CommunicationChannel.EMAIL}
                    isManager={true}
                    isHiddenActive={isHiddenActive}
                    onHiddenChange={(isHiddenFlag: boolean) => {
                        setIsHiddenActive(isHiddenFlag);
                        setCurrentPage(1);
                    }}
                    hideHiddenButton={
                        (hiddenTemplates?.data?.length ?? 0) === 0
                    }
                    sorting={sorting ?? []}
                    setSorting={(updater) => {
                        setSorting(updater);
                        setCurrentPage(1);
                    }}
                    isNeedToRefresh={isNeedToRefresh}
                    setIsNeedToRefresh={setIsNeedToRefresh}
                />
            );
        }
        if (channelIntegration?.length) {
            <TemplateBuilderManager
                integration={channelIntegration[0]}
                isSingleSelect={true}
                handleTemplateSelect={() => {}}
                integrationId={''}
                channel={activePill ?? CommunicationChannel.EMAIL}
                onClose={() => {
                    close();
                    setEditMode(false);
                }}
                editMode={editMode}
                handleUpdateTemplateInCampaignContext={undefined}
                contentDetails={undefined}
            />;
        }
        return null;
    };

    return (
        <>
            <ManagerBuilderContainer
                withContentPadding={false}
                title={t('templates')}
                rightSection={
                    activePill !== CommunicationChannel.WHATSAPP && (
                        <Menu>
                            <Menu.Target>
                                <Button
                                    variant={ButtonVariant.PRIMARY}
                                    leftIcon={<PlusCircle color="white" />}
                                    rightIcon={<CaretDown color="white" />}
                                >
                                    <Text className="text-sm font-semibold">
                                        {t('template.create.title')}
                                    </Text>
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Box className="w-[160px]">
                                    <Menu.Item
                                        onClick={() => {
                                            setSelectedChannel(
                                                CommunicationChannel.EMAIL,
                                            );
                                            toggle();
                                        }}
                                    >
                                        <ChannelIcon
                                            channel={CommunicationChannel.EMAIL}
                                            color="rgb(var(--color-gray-800))"
                                        />
                                    </Menu.Item>
                                    <Menu.Item
                                        onClick={() => {
                                            setSelectedChannel(
                                                CommunicationChannel.SMS,
                                            );
                                            toggle();
                                        }}
                                    >
                                        <ChannelIcon
                                            channel={CommunicationChannel.SMS}
                                            color="rgb(var(--color-gray-800))"
                                        />
                                    </Menu.Item>
                                </Box>
                            </Menu.Dropdown>
                        </Menu>
                    )
                }
            >
                <Tabs
                    keepMounted={false}
                    variant="pills"
                    radius="lg"
                    defaultValue={activePill}
                    onTabChange={(pill: CommunicationChannel) => {
                        handlePillChange(pill);
                        setIsNeedToRefresh(true);
                    }}
                    className="pl-2 bg-gray-100"
                >
                    <ChannelTabs
                        activeTab={activePill ?? CommunicationChannel.EMAIL}
                    />
                </Tabs>

                {getContent()}

                <TemplateOptionsModal
                    opened={opened}
                    onClose={() => {
                        close();
                        setEditMode(false);
                    }}
                    providerMetadata={{} as any}
                    integrationId={''}
                    handleTemplateSelect={() => {}}
                    channel={selectedChannel}
                    showCreateEditTemplate={true}
                    templateId={selectedTemplate}
                    isTemplateLibrary={true}
                    editMode={editMode}
                    handleUpdateTemplateInCampaignContext={undefined}
                    contentDetails={undefined}
                />
            </ManagerBuilderContainer>
            <Modal
                styles={() => ({
                    content: {
                        height: 'fit-content !important',
                        maxHeight: '100vh !important',
                    },
                })}
                title={selectedWhatsappTemplate?.name}
                size={'xl'}
                opened={whatsappPreview}
                onClose={() => {
                    setWhatsappPreview(false);
                    setSelectedWhatsappTemplate(undefined);
                    setEditMode(false);
                    setSelectedTemplate(undefined);
                }}
            >
                <Box>
                    {selectedWhatsappTemplate?.content && (
                        <WhatsappPreview
                            content={selectedWhatsappTemplate?.content}
                            name={selectedWhatsappTemplate?.name}
                            onModify={() => {}}
                            showModifyButton={false}
                            isSetupComplete={false}
                            openContentSetupManager={() => {}}
                            showSetupStrip={false}
                            showHeader={true}
                        />
                    )}
                </Box>
            </Modal>
        </>
    );
};

const Templates = () => {
    const { t } = useLocale();

    return (
        <Page
            title={t('templates')}
            withFullHeight
            withNavbar
            withFixedContent
            withPaddedContent
        >
            <TemplateBuilderContainer />
        </Page>
    );
};

export default Templates;
