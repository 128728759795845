import FilterGroupForm from '@components/Audience/Filters/FilterGroupForm';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import { useLocale } from '@hooks/useLocale';
import {
    addFilterRule,
    FilterOperator,
    generateShortUUID,
    getSortmentWarehouseName,
    ReservedCampaignEventColumns,
    type FilterRule,
    type Filters,
    type JourneyDataSchema,
    type JourneyDimension,
} from '@lightdash/common';
import { Button, Text } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import useProjectContext from '@providers/Project/useProjectContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import React, { useCallback } from 'react';
import { ButtonVariant } from '../../../../../mantineTheme';
import JourneyEventPropertySelector from '../JourneyEventPropertySelector';
import {
    JourneyEventType,
    type ActionEventField,
    type JourneyProperty,
} from '../types';
import { convertToFilterableField } from '../utils';
import JourneyInternalEventFilterRuleForm from './JourneyInternalEventFilterRuleForm';

interface EventFilterRuleFormProps {
    activeEventField: ActionEventField | undefined;
    filterRules: FilterRule[];
    isDimensionsEmpty: boolean;
    journeyDataSchema: JourneyDataSchema;
    allFields: FieldWithSuggestions[];
    isEditMode: boolean;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
}

const EventFilterRuleForm: React.FC<EventFilterRuleFormProps> = ({
    activeEventField,
    filterRules,
    isDimensionsEmpty,
    journeyDataSchema,
    allFields,
    isEditMode,
    filters,
    setFilters,
}) => {
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();
    const { projectData } = useProjectContext();

    const addEventProperty = useCallback(
        (item: JourneyProperty) => {
            if (!activeEventField) return;

            const tableName =
                Object.entries(journeyDataSchema.tables).find(
                    ([_key, table]) => table.name === item.table,
                )?.[0] ?? '';

            const filterableField = convertToFilterableField({
                ...item,
                table: tableName ?? item.table,
            } as JourneyDimension);
            const newFilterRule = addFilterRule({
                filters,
                field: filterableField,
            });

            setFilters(newFilterRule, false, 0);
        },
        [activeEventField, filters, journeyDataSchema.tables, setFilters],
    );

    const handleAddChannelProperty = useCallback(
        (value: string) => {
            if (
                !activeEventField ||
                !projectData ||
                !projectData.warehouseConnection
            )
                return;

            const tableName = Object.entries(journeyDataSchema.tables).find(
                ([_key, table]) =>
                    table.name === activeEventField.eventName &&
                    table.eventSource === activeEventField.eventSource,
            )?.[0];

            const newFilterRule: FilterRule = {
                id: generateShortUUID(),
                operator: FilterOperator.EQUALS,
                target: {
                    fieldId: `${
                        tableName ?? activeEventField.eventSource
                    }_${getSortmentWarehouseName(
                        ReservedCampaignEventColumns.CUSTOM_ID,
                        projectData.warehouseConnection.type,
                    )}`,
                },
                values: [value],
            };

            setFilters(
                {
                    dimensions: {
                        and: [newFilterRule],
                        id: generateShortUUID(),
                    },
                },
                false,
                0,
            );
        },
        [activeEventField, journeyDataSchema.tables, setFilters, projectData],
    );

    if (!activeEventField) return null;

    {
        /* If the event is internal event, then we need to add filter rules to select the campaign related metadata */
    }
    if (activeEventField.eventType === JourneyEventType.INTERNAL_EVENT) {
        return (
            <>
                <JourneyInternalEventFilterRuleForm
                    filterRule={filterRules[0]}
                    onChange={(value) => handleAddChannelProperty(value)}
                    activeEventField={activeEventField}
                />
                {/* If the dimensions are empty and the event is internal event, then we need to add a filter rule to select the campaign related metadata */}
                {isDimensionsEmpty && (
                    <Button
                        variant={ButtonVariant.UNSTYLED}
                        className="flex"
                        onClick={() => handleAddChannelProperty('')}
                        disabled={!isEditMode}
                    >
                        <PlusCircle color={'rgb(var(--color-gray-700))'} />
                        <Text className="ml-1 text-sm font-semibold text-gray-700">
                            {t(
                                'journey_builder.trigger_config_specify_channel_id',
                                {
                                    channelName: 'email',
                                },
                            )}
                        </Text>
                    </Button>
                )}
            </>
        );
    }

    if (activeEventField.eventType === JourneyEventType.WHITELISTED_EVENT) {
        const actionEventFieldTableName = Object.entries(
            journeyDataSchema.tables,
        ).find(
            ([_, table]) => table.eventSource === activeEventField.eventSource,
        )?.[0];
        const allowedFields = allFields.filter(
            (field) => field.table === actionEventFieldTableName,
        );
        return (
            <>
                {!isDimensionsEmpty && filters.dimensions && (
                    <FilterGroupForm
                        allowConvertToGroup={false}
                        hideLine
                        hideButtons
                        conditionLabel="dimension"
                        filterGroup={filters.dimensions}
                        fields={allowedFields}
                        isEditMode={isEditMode}
                        onChange={(value) =>
                            setFilters(
                                {
                                    ...filters,
                                    dimensions: value,
                                },
                                false,
                                0,
                            )
                        }
                        onDelete={() => {
                            setFilters(
                                {
                                    ...filters,
                                    dimensions: undefined,
                                },
                                false,
                                0,
                            );
                        }}
                        filters={filters}
                        setFilters={setFilters}
                        groupIndex={0}
                        showFieldSource={false}
                        relation={activeRelation}
                        nestedLevel={0}
                        customCss="!w-12"
                        additionalMetrics={undefined}
                        setAdditionalMetrics={undefined}
                        dynamicFieldValues={[]}
                    />
                )}
                {isEditMode && (
                    <JourneyEventPropertySelector
                        targetElement={
                            <Button variant={ButtonVariant.UNSTYLED}>
                                <PlusCircle
                                    color={'rgb(var(--color-gray-700))'}
                                />
                                <Text className="ml-1 text-sm font-semibold text-gray-700">
                                    {t('common.property')}
                                </Text>
                            </Button>
                        }
                        eventField={activeEventField}
                        journeyDataSchema={journeyDataSchema}
                        onSubmit={addEventProperty}
                    />
                )}
            </>
        );
    }

    return null;
};

export default EventFilterRuleForm;
