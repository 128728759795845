import TextInput from '@components/common/Inputs/TextInput';
import { useLocale } from '@hooks/useLocale';
import { useUpdateTemplateContentDetails } from '@hooks/useTemplate';
import { type TemplateContentDetailsUpdateRequest } from '@lightdash/common';
import { Flex, Stack, Text } from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import Container from '../Container';

type DetailsProps = {
    subject: string | undefined;
    preheader: string | undefined;
};
const Details = ({ subject, preheader }: DetailsProps) => {
    const { t } = useLocale();
    const { templateId } = useParams<{ templateId: string }>();

    const [isEditing, setIsEditing] = useState(false);
    const [subjectInput, setSubjectInput] = useState(subject);
    const [preheaderInput, setPreheaderInput] = useState(preheader);

    const { mutateAsync: updateTemplateContentDetails, isLoading } =
        useUpdateTemplateContentDetails();

    useEffect(() => {
        setSubjectInput(subject);
    }, [subject]);

    useEffect(() => {
        setPreheaderInput(preheader);
    }, [preheader]);

    const handleEdit = () => setIsEditing(true);

    const handleSave = async () => {
        try {
            if (templateId) {
                const body: TemplateContentDetailsUpdateRequest = {
                    subject: undefined,
                    preheader: undefined,
                };
                if (subjectInput !== null) {
                    body.subject = subjectInput;
                }
                if (preheaderInput !== null) {
                    body.preheader = preheaderInput;
                }
                await updateTemplateContentDetails({
                    templateId,
                    version: 1,
                    body,
                });
                setIsEditing(false);
            }
        } catch (error) {}
    };

    const handleCancel = () => {
        setIsEditing(false);
        setSubjectInput(subject);
        setPreheaderInput(preheader);
    };

    const renderContent = useMemo(() => {
        if (isEditing) {
            return (
                <Stack>
                    <TextInput
                        autoFocus
                        label={t('common.subject')}
                        placeholder={t('common.placeholder.type_here')}
                        value={subjectInput}
                        onChange={(e) => setSubjectInput(e.target.value)}
                    />
                    <TextInput
                        label={t('template_details.email.preheader')}
                        isOptional
                        description={t(
                            'template_details.email.preheader.input_description',
                        )}
                        placeholder={t('common.placeholder.type_here')}
                        onChange={(e) => setPreheaderInput(e.target.value)}
                        value={preheaderInput}
                    />
                </Stack>
            );
        }
        return (
            <Stack spacing={24} className="w-full">
                <Flex align={'center'}>
                    <Text className="text-gray-600 font-semibold uppercase w-[30%]">
                        {t('common.subject')}
                    </Text>
                    <Text
                        className={
                            subjectInput
                                ? 'text-gray-800 font-medium'
                                : 'text-gray-500'
                        }
                    >
                        {subjectInput ?? t('common.empty_state.not_set')}
                    </Text>
                </Flex>
                <Flex align={'center'}>
                    <Text className="text-gray-600 font-semibold uppercase w-[30%]">
                        {t('template_details.email.preheader')}{' '}
                        {t('common.optional')}
                    </Text>
                    <Text
                        className={
                            preheaderInput
                                ? 'text-gray-800 font-medium'
                                : 'text-gray-500'
                        }
                    >
                        {preheaderInput ?? t('common.empty_state.not_set')}
                    </Text>
                </Flex>
            </Stack>
        );
    }, [isEditing, preheaderInput, subjectInput, t]);

    return (
        <Container
            isEditing={isEditing}
            handleCancel={handleCancel}
            handleEdit={handleEdit}
            handleSave={handleSave}
            label={'Details'}
            isSaving={isLoading}
            isComplete={!!subject}
        >
            {renderContent}
        </Container>
    );
};

export default Details;
