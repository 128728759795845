import { subject } from '@casl/ability';
import {
    getButtonHighlightClassName,
    modifyFieldsWithSelectionStatus,
} from '@components/Audience/Filters/FieldListItem/utils';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import BuilderContainer from '@components/common/BuilderContainer';
import LoadingState from '@components/common/LoadingState';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import {
    useGetProfileConfig,
    useGetProfiles,
    useUpdateProfile,
} from '@hooks/useProfile';
import {
    ConditionalOperator,
    FieldType,
    JoinType,
    type CompiledRelationTablePrimary,
} from '@lightdash/common';
import { Box, Button, Flex, Input, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretLeft, MagnifyingGlass, PlusCircle } from '@phosphor-icons/react';
import useApp from '@providers/App/useApp';
import Fuse from 'fuse.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDebounce } from 'react-use';
import { ButtonVariant } from '../../../mantineTheme';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import ProfileCard from '../ProfileCard';
import ProfileManagerPropertySelect from '../ProfileManager/ProfileManagerPropertySelect';
import {
    getFilterForProfileSearch,
    getMetricsAndCustomDimensions,
    toFieldsWithSuggestions,
    updateProfilePayload,
} from '../utils';

const ProfileAttributes: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { getTableRelation, activeRelation, customDimensionsFields } =
        useRelationContext();
    const [opened, { open, close }] = useDisclosure(false);
    const {
        mutate: updateProfile,
        isLoading,
        isSuccess,
    } = useUpdateProfile(projectUuid);
    const { data: config, isFetching } = useGetProfileConfig(projectUuid);
    const { showToastError } = useNotify();
    const [search, setSearch] = useState<string>('');
    const [searchDebounce, setSearchDebounce] = useState<string>('');
    const { profileId } = useParams<{ profileId: string }>();
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });
    const userIdColumn = useMemo(() => {
        const baseTableID = activeRelation?.baseTable;
        if (!baseTableID) return '';
        const baseTable = activeRelation?.tables[baseTableID];
        return `${baseTableID}_${
            (baseTable as CompiledRelationTablePrimary)?.userId
        }`;
    }, [activeRelation]);
    const filterForProfileSearch = useMemo(() => {
        if (!config || !profileId) return null;
        return getFilterForProfileSearch({
            primaryKey: userIdColumn,
            profileId,
            operator: ConditionalOperator.EQUALS,
            fieldType: fieldsWithSuggestions[userIdColumn]?.type,
        });
    }, [config, profileId, fieldsWithSuggestions, userIdColumn]);
    const { data: profile, isLoading: profilesLoading } = useGetProfiles(
        config && filterForProfileSearch && userIdColumn
            ? {
                  projectUuid,
                  data: {
                      offset: 0,
                      limit: 10,
                      filters: filterForProfileSearch,
                  },
              }
            : { projectUuid, data: { offset: 0, limit: 10 } },
    );

    const ability = useAbilityContext();
    const { user } = useApp();
    const canManageProfiles = ability.can(
        'manage',
        subject('Profile', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const filteredRelation = getTableRelation([
        JoinType.one_one,
        JoinType.many_one,
    ]);

    const fields = toFieldsWithSuggestions(
        filteredRelation,
        fieldsWithSuggestions,
        customDimensionsFields,
    );
    const filterFields = useMemo(() => {
        const keysOfFields = Object.keys(fields);
        const filteredFields = [
            ...(config?.metricQuery?.dimensions ?? []),
            ...(config?.metricQuery?.metrics ?? []),
        ]
            .filter((field: string) =>
                keysOfFields.some((present_field) => present_field === field),
            )
            .filter((fieldId) => fieldId);
        return filteredFields;
    }, [fields, config]);

    useDebounce(
        () => {
            setSearchDebounce(search);
        },
        300,
        [search],
    );

    const filterItems = useCallback(
        (data: Record<string, unknown>) => {
            let fieldsData = data ? Object.entries(data) : [];

            if (searchDebounce !== '') {
                const flatData = data
                    ? Object.entries(data).map(([key, value]) => ({
                          key,
                          id: fieldsWithSuggestions[key]?.label,
                          value: value?.toString(),
                      }))
                    : [];

                const fuse = new Fuse(flatData, {
                    keys: ['id', 'value'],
                    threshold: 0.3,
                });

                fieldsData = fuse
                    .search(searchDebounce)
                    .map((res) => [res.item.key, res.item.value]);
            }
            if (config)
                return fieldsData.filter(
                    (res) =>
                        config.metricQuery?.dimensions.includes(res[0]) ||
                        config.metricQuery?.metrics.includes(res[0]),
                );
            return fieldsData;
        },
        [searchDebounce, fieldsWithSuggestions, config],
    );

    const navigate = useNavigate();
    const handleBack = useCallback(() => {
        void navigate(-1);
    }, [navigate]);
    const handleDelete = useCallback(
        (fieldToBeDelete: string) => {
            if (profile?.rows[0]) {
                const fieldItem = fieldsWithSuggestions[fieldToBeDelete];
                let newDimensionColumns = config?.metricQuery?.dimensions;
                let newMetrics = config?.metricQuery?.metrics;
                if (fieldItem.fieldType === FieldType.DIMENSION) {
                    newDimensionColumns = newDimensionColumns?.filter(
                        (field: string) => field !== fieldToBeDelete,
                    );
                } else {
                    newMetrics = newMetrics?.filter(
                        (field: string) => field !== fieldToBeDelete,
                    );
                }

                if (
                    newDimensionColumns?.length &&
                    newDimensionColumns.length > 1 &&
                    config
                ) {
                    updateProfile(
                        updateProfilePayload({
                            payload: config,
                            dimensions: newDimensionColumns,
                            metrics: newMetrics,
                        }),
                    );
                } else {
                    showToastError({
                        title: t('profiles_view.delete_toast_error'),
                    });
                }
            }
        },
        [
            profile,
            showToastError,
            t,
            config,
            updateProfile,
            fieldsWithSuggestions,
        ],
    );
    const profileCards = useMemo(() => {
        if (!profile || !profile.rows || profile.rows.length === 0) {
            return (
                <Box className="flex items-center justify-center h-full text-sm text-gray-600">
                    {t('profiles_view.no_columns_available')}
                </Box>
            );
        }

        return (
            profile.rows[0] &&
            filterItems(profile.rows[0]).map((property) => {
                return (
                    <ProfileCard
                        property={property}
                        key={property[0]}
                        handleDelete={handleDelete}
                        primaryProperty={userIdColumn}
                        isLoading={isLoading}
                        onSuccess={isSuccess}
                    />
                );
            })
        );
    }, [
        profile,
        filterItems,
        handleDelete,
        isLoading,
        isSuccess,
        t,
        userIdColumn,
    ]);
    const isPrimaryKeySelected = useMemo(() => {
        return filterFields?.includes(userIdColumn);
    }, [filterFields, userIdColumn]);
    const modifiedFields = useMemo(() => {
        return modifyFieldsWithSelectionStatus({
            fields: Object.values(fields),
            selectedFieldIds: filterFields ?? [],
            shouldDisableChecked: false,
            disabledFields: isPrimaryKeySelected ? [userIdColumn] : [],
        });
    }, [fields, filterFields, isPrimaryKeySelected, userIdColumn]);
    useEffect(() => {
        if (isSuccess) {
            close();
        }
    }, [isSuccess, close]);

    const handleProfileUpdate = useCallback(
        (items: FieldWithSuggestions[]) => {
            if (!config) return;
            const { dimensions, metrics, customDimensionsSelected } =
                getMetricsAndCustomDimensions(items);
            const newColumns =
                userIdColumn && !dimensions.includes(userIdColumn)
                    ? [userIdColumn, ...dimensions, ...customDimensionsSelected]
                    : [...dimensions, ...customDimensionsSelected];

            updateProfile(
                updateProfilePayload({
                    payload: config,
                    dimensions: newColumns,
                    metrics: metrics,
                }),
            );
        },
        [config, updateProfile, userIdColumn],
    );

    return (
        <>
            <Box component="div" className="mb-3.5 bg-white rounded-xl p-1">
                <Flex gap={9} align="center">
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        className="p-1"
                        onClick={handleBack}
                    >
                        <CaretLeft />
                    </Button>
                    <Text className="font-semibold text-gray-600">
                        {profileId}
                    </Text>
                </Flex>
            </Box>
            <BuilderContainer
                isEditable={false}
                title={t('user_profile_page.subtitle')}
                withContentPadding={false}
            >
                <Flex className="p-3.5 border-y border-shade-6 rounded-none">
                    <Input
                        icon={<MagnifyingGlass />}
                        placeholder={t('profiles_view.search_placeholder')}
                        width="100%"
                        size="xs"
                        radius="md"
                        styles={{
                            wrapper: {
                                width: '100%',
                            },
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setSearch(e.target.value);
                        }}
                    />
                </Flex>
                {isFetching || profilesLoading || !config ? (
                    <Box className="flex items-center justify-center h-full">
                        <LoadingState title={''} />
                    </Box>
                ) : (
                    <>
                        <Box className="rounded-lg !h-[30vh] overflow-auto">
                            {profileCards}
                        </Box>
                        <Box className="flex justify-center p-3 border-t border-shade-6">
                            {Object.values(fields).length > 0 &&
                                config &&
                                canManageProfiles && (
                                    <ProfileManagerPropertySelect
                                        fields={modifiedFields}
                                        onSubmit={handleProfileUpdate}
                                        isLoading={isLoading}
                                        isSuccess={isSuccess}
                                        targetButton={
                                            <Button
                                                variant={ButtonVariant.OUTLINED}
                                                className={`${getButtonHighlightClassName(
                                                    opened,
                                                )} w-[19.5rem]`}
                                            >
                                                <Flex gap={4} align="center">
                                                    <PlusCircle
                                                        weight={'duotone'}
                                                        color={
                                                            opened
                                                                ? 'rgb(var(--color-blu-800))'
                                                                : 'rgb(var(--color-gray-800))'
                                                        }
                                                    />
                                                    {t('common.add_properties')}
                                                </Flex>
                                            </Button>
                                        }
                                        opened={opened}
                                        close={close}
                                        open={open}
                                    />
                                )}
                        </Box>
                    </>
                )}
            </BuilderContainer>
        </>
    );
};
export default ProfileAttributes;
