import { useLocale } from '@hooks/useLocale';
import { type UserAlertPreferenceResponse } from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import { type ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import AlertMenuItem from './AlertMenuItem';

export function useAlertColumns() {
    const [columns, setColumns] =
        useState<ColumnDef<UserAlertPreferenceResponse | null>[]>();
    const { t } = useLocale();

    useEffect(() => {
        setColumns([
            {
                accessorKey: 'type',
                header: t('alert_settings.th_alert_type'),
                cell: ({ row }) => (
                    <Box className="text-sm text-gray-800">
                        <Text>{row.original?.entity}</Text>
                    </Box>
                ),
            },
            {
                accessorKey: 'user',
                header: t('alert_settings.th_user'),
                cell: ({ row }) => (
                    <Box className="text-sm text-gray-800">
                        <Text>{`${row.original?.firstName} ${row.original?.lastName}`}</Text>
                    </Box>
                ),
            },
            {
                accessorKey: 'channel',
                header: t('alert_settings.th_channel'),
                cell: ({ getValue }) => (
                    <Box className="text-sm text-gray-800">
                        <Text>{getValue<string>()}</Text>
                    </Box>
                ),
            },
            {
                accessorKey: 'actions',
                header: '',
                enableSorting: false,
                cell: ({ row }) => <AlertMenuItem rowData={row} />,
            },
        ]);
    }, [t]);

    return columns;
}
