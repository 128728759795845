import SettingsTitle from '@components/common/Settings/SettingsTitle';
import { useLocale } from '@hooks/useLocale';
import {
    useCreateTestProfiles,
    useGetTestProfiles,
} from '@hooks/useTestProfiles';
import { type TestProfileRequest } from '@lightdash/common';
import { Box, Button, FileButton, Group, Text } from '@mantine/core';
import { DownloadSimple, FileArrowUp } from '@phosphor-icons/react';
import Papa from 'papaparse';
import React from 'react';
import { ButtonVariant } from '../../../mantineTheme';

const TestProfiles = () => {
    const { t } = useLocale();

    const { mutate } = useCreateTestProfiles();
    const { data } = useGetTestProfiles({ successCallback: () => {} });

    const handleFileInput = (file: File | null) => {
        if (file) {
            Papa.parse(file as any, {
                header: true,
                complete(results) {
                    if (results.data) {
                        const payload = (
                            results.data as TestProfileRequest
                        ).map((item) => {
                            const {
                                email = '',
                                sms = '',
                                whatsapp = '',
                                name = '',
                            } = item;
                            return {
                                email: email,
                                sms: sms,
                                whatsapp: whatsapp,
                                name: name,
                            };
                        });
                        mutate({
                            body: payload,
                        });
                    }
                },
            });
        }
    };

    const handleDownload = () => {
        if (data && data.length) {
            const csv = Papa.unparse(data);
            const blob = new Blob([csv], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = 'test-profiles.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }
    };

    return (
        <Box>
            <SettingsTitle title={'Test profiles'} />
            <Text className="font-medium text-gray-700 mt-6">
                {t('test_profiles.title')}
            </Text>
            <Group className="mt-6" spacing={12} align="center">
                {data && data.length ? (
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        leftIcon={
                            <DownloadSimple
                                color="rgb(var(--color-gray-700))"
                                weight="regular"
                            />
                        }
                        onClick={handleDownload}
                    >
                        {t('test_profiles.download_current_list')}
                    </Button>
                ) : (
                    <Button
                        leftIcon={
                            <DownloadSimple
                                color="rgb(var(--color-gray-700))"
                                weight="regular"
                            />
                        }
                        variant={ButtonVariant.OUTLINED}
                        component="a"
                        download
                        href="/test_profiles_sample_file.csv"
                    >
                        {t('test_profiles.download_sample_csv')}
                    </Button>
                )}
                <FileButton onChange={handleFileInput} accept={'text/csv'}>
                    {(props) => (
                        <Button
                            {...props}
                            leftIcon={<FileArrowUp color="white" />}
                        >
                            {data && data.length
                                ? t('test_profiles.update_list')
                                : t('test_profiles.upload_test_profiles')}
                        </Button>
                    )}
                </FileButton>
            </Group>
        </Box>
    );
};

export default TestProfiles;
