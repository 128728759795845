import useNotify from '@hooks/toaster/useNotify';
import {
    useAudienceScheduleMutation,
    useScheduleDeleteMutation,
    useScheduleUpdateMutation,
} from '@hooks/useAudience';
import { useLocale } from '@hooks/useLocale';
import useTimestamp from '@hooks/useTimestamp';
import { AudienceRunTypes, type Audience } from '@lightdash/common';
import { Box, Group, Radio, Stack, Text } from '@mantine/core';
import { DateInput, TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { Calendar, Clock } from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { QueryKeys } from 'types/UseQuery';
import { CronInternalInputs } from './ReactHookForm/CronInput';
import SchedulerModalFooter from './SchedulerModalFooter';

interface SchedulerModalContentProps {
    audienceData?: Audience;
    onClose: () => void;
    handleMenuClick: () => void;
}

const SchedulerModalContent: React.FC<SchedulerModalContentProps> = ({
    audienceData,
    onClose,
    handleMenuClick,
}) => {
    const { showToastError } = useNotify();
    const form = useForm({
        initialValues: {
            runType:
                audienceData && audienceData.runType === AudienceRunTypes.MANUAL
                    ? AudienceRunTypes.SCHEDULED
                    : audienceData && audienceData.runType,
            cron:
                audienceData && audienceData.cron
                    ? audienceData.cron
                    : '0 0 * * *',
            runAt:
                audienceData && audienceData.runAt
                    ? new Date(audienceData.runAt)
                    : new Date(),
            cronStartAt:
                audienceData && audienceData.cronStartAt
                    ? new Date(audienceData.cronStartAt)
                    : new Date(),
            cronEndAt:
                audienceData && audienceData.cronEndAt
                    ? new Date(audienceData.cronEndAt)
                    : new Date(),
        },
    });
    const queryClient = useQueryClient();
    const { getTimestamp } = useTimestamp();
    const { t } = useLocale();

    const { mutateAsync: mutateAudienceSchedule, isLoading: isScheduling } =
        useAudienceScheduleMutation();

    const { mutateAsync: mutateUpdateSchedule, isLoading: isUpdatingSchedule } =
        useScheduleUpdateMutation();
    const { mutateAsync: mutateDeleteSchedule } = useScheduleDeleteMutation();

    const [schedulerType, setSchedulerType] = useState<AudienceRunTypes>(
        form.values.runType || AudienceRunTypes.SCHEDULED,
    );

    const [scheduleStartDateTime, setScheduleStartDateTime] = useState<Date>(
        form.values.cronStartAt,
    );
    const [scheduleEndDateTime, setScheduleEndDateTime] = useState<Date>(
        form.values.cronEndAt,
    );
    const [scheduleDateTime, setScheduleDateTime] = useState<Date>(
        form.values.runAt,
    );

    const refStart = useRef<HTMLInputElement>(null);
    const refEnd = useRef<HTMLInputElement>(null);

    const minimumDateTime = useMemo(() => {
        const date = new Date();
        date.setMinutes(date.getMinutes() + 15);
        return new Date(getTimestamp(date.getTime()));
    }, [getTimestamp]);

    const cronStartDateChange = useCallback(
        (value: Date) => {
            setScheduleStartDateTime(value);
            form.setValues({ cronStartAt: value });
        },
        [setScheduleStartDateTime, form],
    );

    const cronStartTimeChange = useCallback(
        (value: string) => {
            const [hours, minutes] = value.split(':');
            const date = new Date(scheduleStartDateTime);
            date.setHours(parseInt(hours, 10));
            date.setMinutes(parseInt(minutes, 10));
            setScheduleStartDateTime(date);
            form.setValues({ cronStartAt: date });
        },
        [scheduleStartDateTime, form],
    );
    const cronEndDateChange = useCallback(
        (value: Date) => {
            setScheduleEndDateTime(value);
            form.setValues({ cronEndAt: value });
        },
        [setScheduleEndDateTime, form],
    );

    const cronEndTimeChange = useCallback(
        (value: string) => {
            const [hours, minutes] = value.split(':');
            const date = new Date(scheduleEndDateTime);
            date.setHours(parseInt(hours, 10));
            date.setMinutes(parseInt(minutes, 10));
            setScheduleEndDateTime(date);
            form.setValues({ cronEndAt: date });
        },
        [scheduleEndDateTime, form],
    );

    const scheduleDateChange = useCallback(
        (value: Date) => {
            setScheduleDateTime(value);
            form.setValues({ runAt: value });
        },
        [setScheduleDateTime, form],
    );

    const scheduleTimeChange = useCallback(
        (value: string) => {
            const [hours, minutes] = value.split(':');
            const date = new Date(scheduleDateTime);
            date.setHours(parseInt(hours, 10));
            date.setMinutes(parseInt(minutes, 10));
            setScheduleDateTime(date);
            form.setValues({ runAt: date });
        },
        [setScheduleDateTime, scheduleDateTime, form],
    );

    const handleSchedulerTypeChange = (value: AudienceRunTypes) => {
        setSchedulerType(value);
        form.setValues({ runType: value });
    };

    const handleSchedule = async () => {
        if (
            audienceData &&
            form.values.runType === AudienceRunTypes.SCHEDULED
        ) {
            await mutateAudienceSchedule({
                audienceId: audienceData.id,
                data: {
                    runType: form.values.runType,
                    runAt: form.values.runAt,
                },
            });
        }

        if (audienceData && form.values.runType === AudienceRunTypes.CRON) {
            await mutateAudienceSchedule({
                audienceId: audienceData.id,
                data: {
                    runType: form.values.runType,
                    cron: form.values.cron,
                    cronStartAt: form.values.cronStartAt,
                    cronEndAt: form.values.cronEndAt,
                },
            });
        }
        handleMenuClick();

        await queryClient.invalidateQueries([QueryKeys.GET_AUDIENCE]);
        await queryClient.invalidateQueries([QueryKeys.SAVED_AUDIENCE]);
    };

    const showErrorAndReturn = useCallback(
        (errorKey: string) => {
            showToastError({ title: t(errorKey) });
            return;
        },
        [t, showToastError],
    );

    const updateSchedule = useCallback(
        async (data: {
            runType: AudienceRunTypes;
            runAt?: Date;
            cron?: string;
            cronStartAt?: Date;
            cronEndAt?: Date;
        }) => {
            if (!audienceData) return;
            onClose();
            await mutateUpdateSchedule({
                audienceId: audienceData.id,
                data,
            });
            await queryClient.invalidateQueries([QueryKeys.GET_AUDIENCE]);
            await queryClient.invalidateQueries([QueryKeys.SAVED_AUDIENCE]);
        },
        [mutateUpdateSchedule, queryClient, audienceData, onClose],
    );

    const handleUpdateSchedule = async () => {
        if (!audienceData) return;

        const fifteenMinutesFromNow = new Date(
            new Date().getTime() + 15 * 60 * 1000,
        );

        if (form.values.runType === AudienceRunTypes.SCHEDULED) {
            if (scheduleDateTime < fifteenMinutesFromNow) {
                return showErrorAndReturn('journey_scheduler.start_time_error');
            }
            await updateSchedule({
                runType: form.values.runType,
                runAt: form.values.runAt,
            });
            handleMenuClick();
            return;
        }
        if (form.values.runType === AudienceRunTypes.CRON) {
            if (form.values.cronStartAt < fifteenMinutesFromNow) {
                return showErrorAndReturn('journey_scheduler.start_time_error');
            }
            if (
                form.values.cronEndAt <
                new Date(form.values.cronStartAt.getTime() + 15 * 60 * 1000)
            ) {
                return showErrorAndReturn('journey_scheduler.end_time_error');
            }
            await updateSchedule({
                runType: form.values.runType,
                cron: form.values.cron,
                cronStartAt: form.values.cronStartAt,
                cronEndAt: form.values.cronEndAt,
            });
            handleMenuClick();
        }
    };

    const onSubmit = async () => {
        if (audienceData?.runType === AudienceRunTypes.MANUAL)
            await handleSchedule();
        if (
            audienceData?.runType === AudienceRunTypes.CRON ||
            audienceData?.runType === AudienceRunTypes.SCHEDULED
        ) {
            await handleUpdateSchedule();
        }
        onClose();
    };

    const handleDeleteSchedule = useCallback(async () => {
        onClose();
        if (audienceData)
            await mutateDeleteSchedule({
                audienceId: audienceData?.id,
            });
        handleMenuClick();
        await queryClient.invalidateQueries([QueryKeys.GET_AUDIENCE]);
        await queryClient.invalidateQueries([QueryKeys.SAVED_AUDIENCE]);
    }, [
        mutateDeleteSchedule,
        queryClient,
        audienceData,
        onClose,
        handleMenuClick,
    ]);

    const isSaveButtonDisabled = useMemo(() => {
        if (schedulerType === AudienceRunTypes.CRON) {
            return scheduleStartDateTime > scheduleEndDateTime;
        }
        return false;
    }, [schedulerType, scheduleStartDateTime, scheduleEndDateTime]);

    const untilComponent = useMemo(() => {
        return (
            <Box>
                <Text className="text-sm font-medium text-gray-800 mb-1">
                    Until
                </Text>
                <Group>
                    <DateInput
                        valueFormat="DD MMM YYYY"
                        placeholder="DD/MM/YYYY"
                        icon={<Calendar />}
                        minDate={scheduleStartDateTime}
                        onChange={cronEndDateChange}
                        value={scheduleEndDateTime}
                        popoverProps={{
                            withinPortal: true,
                        }}
                    />
                    <TimeInput
                        ref={refEnd}
                        icon={<Clock />}
                        onClick={() => refEnd?.current?.showPicker()}
                        onChange={(event) =>
                            cronEndTimeChange(event.target.value)
                        }
                        value={`${scheduleEndDateTime
                            .getHours()
                            .toString()
                            .padStart(2, '0')}:${scheduleEndDateTime
                            .getMinutes()
                            .toString()
                            .padStart(2, '0')}`}
                    />
                </Group>
            </Box>
        );
    }, [
        scheduleStartDateTime,
        cronEndDateChange,
        cronEndTimeChange,
        scheduleEndDateTime,
    ]);

    const formData = useMemo(() => {
        switch (schedulerType) {
            case AudienceRunTypes.CRON:
                return (
                    <Box className="py-3.5">
                        <Text className="mb-1 text-sm font-medium text-gray-800">
                            {t('audience_scheduler.cron_label')}
                        </Text>

                        <CronInternalInputs
                            disabled={false}
                            {...form.getInputProps('cron')}
                            value={form.values.cron}
                            name="cron"
                            disableHourly={false}
                        />

                        <Group className="flex flex-col items-start gap-1 pt-3.5">
                            <Text className="text-sm font-medium text-gray-800">
                                {t('audience_scheduler.cron_start_date')}
                            </Text>
                            <Group>
                                <DateInput
                                    valueFormat="DD MMM YYYY"
                                    placeholder="DD/MM/YYYY"
                                    icon={<Calendar />}
                                    minDate={minimumDateTime}
                                    onChange={cronStartDateChange}
                                    value={scheduleStartDateTime}
                                    popoverProps={{
                                        withinPortal: true,
                                    }}
                                />
                                <TimeInput
                                    ref={refStart}
                                    icon={<Clock />}
                                    onClick={() =>
                                        refStart?.current?.showPicker()
                                    }
                                    onChange={(event) =>
                                        cronStartTimeChange(event.target.value)
                                    }
                                    value={`${scheduleStartDateTime
                                        .getHours()
                                        .toString()
                                        .padStart(
                                            2,
                                            '0',
                                        )}:${scheduleStartDateTime
                                        .getMinutes()
                                        .toString()
                                        .padStart(2, '0')}`}
                                />
                            </Group>
                        </Group>
                        <Group className="flex flex-col items-start gap-1 pt-3.5">
                            {untilComponent}
                        </Group>
                    </Box>
                );
            case AudienceRunTypes.SCHEDULED:
                return (
                    <Group className="py-3.5 gap-3">
                        <DateInput
                            valueFormat="DD MMM YYYY"
                            placeholder="DD/MM/YYYY"
                            icon={<Calendar />}
                            minDate={minimumDateTime}
                            onChange={scheduleDateChange}
                            value={scheduleDateTime}
                            popoverProps={{
                                withinPortal: true,
                            }}
                        />
                        <Text className="text-sm font-normal text-gray-600">
                            {t('audience_scheduler.at')}
                        </Text>
                        <TimeInput
                            ref={refEnd}
                            icon={<Clock />}
                            onClick={() => refEnd?.current?.showPicker()}
                            onChange={(event) =>
                                scheduleTimeChange(event.target.value)
                            }
                            value={`${scheduleDateTime
                                .getHours()
                                .toString()
                                .padStart(2, '0')}:${scheduleDateTime
                                .getMinutes()
                                .toString()
                                .padStart(2, '0')}`}
                        />
                    </Group>
                );
        }
    }, [
        t,
        schedulerType,
        minimumDateTime,
        scheduleStartDateTime,
        scheduleDateTime,
        scheduleDateChange,
        scheduleTimeChange,
        form,
        cronStartDateChange,
        cronStartTimeChange,
        untilComponent,
    ]);

    return (
        <Box>
            <Box>
                <Text className="pt-3.5 text-gray-800 text-base font-medium">
                    {t('audience_scheduler.generation_type_title')}
                </Text>
                <Radio.Group
                    className="pt-3.5"
                    value={schedulerType}
                    onChange={handleSchedulerTypeChange}
                    withAsterisk
                >
                    <Radio
                        className="pb-3.5 text-sm text-gray-800 font-normal"
                        value={AudienceRunTypes.SCHEDULED}
                        label="At a specific date and time"
                    />
                    <Radio
                        className="text-sm font-normal text-gray-800"
                        value={AudienceRunTypes.CRON}
                        label="Set up recurring schedule"
                    />
                </Radio.Group>
            </Box>
            <form
                className="flex flex-col justify-between"
                onSubmit={form.onSubmit(() => onSubmit())}
            >
                <Stack
                    sx={(theme) => ({
                        backgroundColor: theme.white,
                        paddingRight: theme.spacing.xl,
                    })}
                >
                    {formData}
                </Stack>
                {audienceData && (
                    <SchedulerModalFooter
                        audienceData={audienceData}
                        handleDeleteSchedule={handleDeleteSchedule}
                        onClose={onClose}
                        isPrimaryButtonDisabled={isSaveButtonDisabled}
                        isScheduling={isScheduling || isUpdatingSchedule}
                    />
                )}
            </form>
        </Box>
    );
};

export default SchedulerModalContent;
