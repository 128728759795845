import { subject } from '@casl/ability';
import AudiencePreviewModal from '@components/Audience/AudiencePreview/AudiencePreviewData';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import MantineIcon from '@components/common/MantineIcon';
import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Modal from '@components/common/modal/Modal';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import useNotify from '@hooks/toaster/useNotify';
import {
    useAudienceExportMutation,
    useAudienceUpdation,
    useGetIterableLists,
    useIterableExportMutation,
} from '@hooks/useAudience';
import { useAudiencePreviewById } from '@hooks/useAudiencePreview';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceBuilderHashParams,
    AudienceRunTypes,
    AudienceStatus,
    CommonReservedTags,
    getErrorMessage,
    QueryGenerationStrategy,
    type Audience,
    type CustomSqlDimension,
    type IterableListInfo,
} from '@lightdash/common';
import { Box, Button, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    Archive,
    ArrowCounterClockwise,
    ArrowElbowDownLeft,
    CaretRight,
    Clock,
    CopySimple,
    DotsThree,
    Export,
    Share,
} from '@phosphor-icons/react';
import useApp from '@providers/App/useApp';
import useProjectContext from '@providers/Project/useProjectContext';
import { type Row } from '@tanstack/react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ButtonVariant } from '../../../mantineTheme';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import { type FieldsWithSuggestions } from '../Filters/FiltersProvider/types';
import { getDimensionsAndMetrics } from '../utils';
import { IterableExportSection } from './AudienceIterableExportSection';
interface AudienceMenuItemProps {
    rowData: Row<Audience>;
    setModalOpen: () => void;
    setAudienceData: (data: Audience) => void;
    onRowChange: () => void;
    fieldsWithSuggestions: FieldsWithSuggestions;
    customDimensions: CustomSqlDimension[];
}

export const AudienceMenuItem: React.FC<AudienceMenuItemProps> = ({
    rowData,
    setModalOpen,
    setAudienceData,
    onRowChange,
    fieldsWithSuggestions,
    customDimensions,
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData: project } = useProjectContext();
    const { mutateAsync: mutateAudienceExport } = useAudienceExportMutation();
    const {
        mutateAsync: mutateIterableExport,
        isLoading: isIterableExportLoading,
    } = useIterableExportMutation();
    const { user } = useApp();
    const { t } = useLocale();
    const ability = useAbilityContext();
    const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } =
        useAudienceUpdation(true); // INFO: hide toast for now as we want to customise mesage here.
    const { activeRelationUuid } = useRelationContext();
    const navigate = useNavigate();
    const { showToastSuccess, showToastError } = useNotify();
    const [
        openedHideConfirmModal,
        { open: openHideConfirmModal, close: closeHideConfirmModal },
    ] = useDisclosure();
    const [previewOpened, { open: openPreview, close: closePreview }] =
        useDisclosure(false);
    const [
        iterablePreviewOpened,
        { open: openIterablePreview, close: closeIterablePreview },
    ] = useDisclosure(false);
    const [emailField, setEmailField] = useState<string | undefined>(undefined);
    const [listId, setListId] = useState<number | undefined>(undefined);
    const [name, setName] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(
        undefined,
    );
    const [updateExistingUsersOnly, setUpdateExistingUsersOnly] =
        useState<boolean>(false);
    const [previewFields, setPreviewFields] = useState<string[]>(
        rowData.original.userAlias ? [rowData.original.userAlias] : [],
    );
    const [dimensionFields, setDimensionFields] = useState<string[]>([]);
    const [metricFields, setMetricFields] = useState<string[]>([]);
    const [useExistingList, setUseExistingList] = useState(true);
    const [iterableLists, setIterableLists] = useState<IterableListInfo[]>([]);

    const iterableExportEnabled = useMemo(
        () => project?.attributes?.exportToIterableAbility === true,
        [project?.attributes?.exportToIterableAbility],
    );

    const canEditAudience = ability.can(
        'manage',
        subject('VisualAudience', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const {
        mutateAsync: mutateAsyncView,
        isLoading: isFetchingPreview,
        data: previewAudienceData,
    } = useAudiencePreviewById();

    const { data: listData, isLoading: isFetchingLists } = useGetIterableLists(
        iterableExportEnabled && iterablePreviewOpened
            ? rowData.original.id
            : undefined,
    );

    useEffect(() => {
        if (iterablePreviewOpened && listData) {
            setIterableLists(listData);
        }
    }, [iterablePreviewOpened, isFetchingLists, listData]);

    const handleViewEdit = useCallback(
        (id: string, type: string) => {
            void navigate(`/projects/${projectUuid}/audiences/${id}/${type}`);
        },
        [projectUuid, navigate],
    );

    const handleDuplicate = useCallback(
        (value: Audience) => {
            void navigate(
                `/projects/${projectUuid}/audiences/create?templateId=${
                    value.id
                }#${
                    value.generationStrategy ===
                    QueryGenerationStrategy.AUDIENCE_BUILDER
                        ? AudienceBuilderHashParams.VISUAL
                        : AudienceBuilderHashParams.SQL
                }`,
            );
        },
        [projectUuid, navigate],
    );

    const handleExport = useCallback(async () => {
        try {
            await mutateAsyncView({
                dimensions: [],
                metrics: [],
                audienceId: rowData.original.id,
            });
            openPreview();
        } catch (error) {
            showToastError({
                title:
                    getErrorMessage(error) ??
                    t('audience_export.error_message'),
            });
        }
    }, [rowData.original.id, mutateAsyncView, openPreview, showToastError, t]);

    const handleExportToIterable = useCallback(async () => {
        try {
            await mutateAsyncView({
                dimensions: [],
                metrics: [],
                audienceId: rowData.original.id,
            });
            setName(rowData.original.name ?? undefined);
            setDescription(rowData.original.description ?? undefined);

            openIterablePreview();
        } catch (error) {
            showToastError({
                title:
                    getErrorMessage(error) ??
                    t('audience_export_iterable.toast_error_message'),
            });
        }
    }, [
        rowData.original.id,
        rowData.original.name,
        rowData.original.description,
        mutateAsyncView,
        openIterablePreview,
        showToastError,
        t,
    ]);

    const getPreviewInitialColumns = useCallback(() => {
        if (!previewAudienceData) return [];
        return Object.keys(previewAudienceData.fields);
    }, [previewAudienceData]);

    const handlePropertySelect = useCallback(
        (previewFieldIds: string[]) => {
            const { dimensions: dimensionsFields, metrics: metricsFields } =
                getDimensionsAndMetrics(
                    previewFieldIds,
                    fieldsWithSuggestions,
                    customDimensions,
                );

            setDimensionFields(dimensionsFields);
            setMetricFields(metricsFields);
        },
        [fieldsWithSuggestions, customDimensions],
    );

    const triggerExport = useCallback(async () => {
        if (!user.data) return;
        await mutateAudienceExport({
            audienceId: rowData.original.id,
            userUuid: user.data.userUuid,
            dimensionFields,
            metricFields,
        });
    }, [
        mutateAudienceExport,
        rowData.original.id,
        user.data,
        dimensionFields,
        metricFields,
    ]);

    const triggerIterableExport = useCallback(async () => {
        if (!user.data) return;
        try {
            await mutateIterableExport({
                audienceId: rowData.original.id,
                userUuid: user.data.userUuid,
                dimensionFields,
                metricFields,
                userIdField: rowData.original.userAlias!,
                emailField,
                listId,
                name: name && name.trim().length > 0 ? name.trim() : 'untitled',
                description,
                updateExistingUsersOnly,
            });
            closeIterablePreview();
        } catch (error) {
            showToastError({
                title:
                    getErrorMessage(error) ??
                    t('audience_export_iterable.toast_error_message'),
            });
        }
    }, [
        user.data,
        mutateIterableExport,
        rowData.original.id,
        dimensionFields,
        metricFields,
        rowData.original.userAlias,
        emailField,
        listId,
        name,
        description,
        updateExistingUsersOnly,
        showToastError,
        closeIterablePreview,
        t,
    ]);
    const viewAudienceItem = useCallback(
        () => ({
            leftSection: (
                <ArrowElbowDownLeft
                    size={14}
                    weight="duotone"
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('audience_manager.menu_item_view'),
            onClick: (values: Audience) => handleViewEdit(values.id, 'view'),
        }),
        [handleViewEdit, t],
    );
    const duplicateAudienceItem = useCallback(
        (isDividerRequired: boolean) => ({
            leftSection: (
                <CopySimple
                    size={14}
                    weight="duotone"
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('audience_manager.menu_item_duplicate'),
            onClick: (values: Audience) => handleDuplicate(values),
            isDivider: isDividerRequired,
        }),
        [handleDuplicate, t],
    );

    const exportAudienceItem = useCallback(
        (isDividerRequired: boolean) => ({
            leftSection: (
                <Export
                    size={14}
                    weight="duotone"
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('audience_manager.menu_item_export'),
            onClick: async () => {
                await handleExport();
            },
            isDivider: isDividerRequired,
        }),
        [t, handleExport],
    );

    const exportToIterableItem = useCallback(
        (isDividerRequired: boolean) => ({
            leftSection: (
                <Share
                    size={14}
                    weight="duotone"
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('audience_manager.menu_item_export_iterable'),
            onClick: async () => {
                await handleExportToIterable();
            },
            isDivider: isDividerRequired,
        }),
        [t, handleExportToIterable],
    );
    const changeRunScheduleItem = useCallback(
        (isDividerRequired: boolean) => {
            const menuItem = {
                leftSection: (
                    <Clock
                        size={14}
                        color={'rgb(var(--color-gray-600))'}
                        weight="duotone"
                        strokeWidth={2.5}
                    />
                ),
                children:
                    rowData.original.runType === AudienceRunTypes.SCHEDULED ||
                    rowData.original.runType === AudienceRunTypes.CRON
                        ? t('audience_manager.menu_item_change_schedule')
                        : t('audience_manager.menu_item_add_schedule'),
                onClick: (values: Audience) => {
                    setAudienceData(values);
                    setModalOpen();
                },
                isDivider: isDividerRequired,
            };

            return menuItem;
        },
        [setAudienceData, setModalOpen, t, rowData.original.runType],
    );

    const editItems = useMemo(
        () => ({
            leftSection: (
                <ArrowElbowDownLeft
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('audience_manager.menu_item_edit'),
            onClick: (audience: Audience) =>
                handleViewEdit(audience.id, 'edit'),
        }),
        [t, handleViewEdit],
    );
    const hideItems = useMemo(
        () => ({
            leftSection: (
                <Archive
                    weight="duotone"
                    color={'rgb(var(--color-halt-800))'}
                />
            ),
            children: <Text className="text-halt-800">{t('common.hide')}</Text>,
            customClass: 'hover:bg-halt-800/6',
            onClick: () => openHideConfirmModal(),
        }),
        [t, openHideConfirmModal],
    );
    const restoreItems = useMemo(
        () => ({
            leftSection: <ArrowCounterClockwise weight="duotone" />,
            children: t('common.restore'),
            onClick: async (audience: Audience) => {
                onRowChange();
                await mutateAsyncUpdate(
                    {
                        audienceId: audience.id,
                        relationUuid: activeRelationUuid,
                        payload: {
                            tags: [
                                ...(audience.tags?.filter(
                                    (tag) => tag !== CommonReservedTags.HIDDEN,
                                ) || []),
                            ],
                        },
                    },
                    {
                        onSuccess: () => {
                            showToastSuccess({
                                title: t('common.hidden_restore_success', {
                                    entityName: t('common.audience'),
                                }),
                            });
                        },
                        onError: (error) => {
                            showToastError({
                                title: t('common.hidden_restore_error', {
                                    entityName: t('common.audience'),
                                }),
                                subtitle: error.error.message,
                            });
                        },
                    },
                );
            },
        }),
        [
            t,
            mutateAsyncUpdate,
            activeRelationUuid,
            showToastError,
            showToastSuccess,
            onRowChange,
        ],
    );

    const menuItems = useMemo(() => {
        let items: CustomMenuItemProps<Audience>[] = [];
        if (rowData.original.status === AudienceStatus.DRAFT) {
            items = [editItems, duplicateAudienceItem(true)];
        } else {
            items = [
                viewAudienceItem(),
                duplicateAudienceItem(
                    !rowData.original.audienceLastMaterializationName ||
                        !project?.attributes?.exportAbility,
                ),
                ...(rowData.original.audienceLastMaterializationName &&
                project?.attributes?.exportAbility
                    ? [exportAudienceItem(false)]
                    : []),
                ...(iterableExportEnabled ? [exportToIterableItem(true)] : []),
                changeRunScheduleItem(true),
            ];
        }
        if (rowData.original.tags?.includes(CommonReservedTags.HIDDEN)) {
            if (!isUpdating) {
                items.push(restoreItems);
            }
        } else {
            items.push(hideItems);
        }
        return items;
    }, [
        changeRunScheduleItem,
        duplicateAudienceItem,
        exportAudienceItem,
        editItems,
        rowData.original.status,
        viewAudienceItem,
        restoreItems,
        hideItems,
        rowData.original.tags,
        rowData.original.audienceLastMaterializationName,
        project?.attributes?.exportAbility,
        iterableExportEnabled,
        isUpdating,
        exportToIterableItem,
    ]);
    return (
        <>
            {canEditAudience && (
                <>
                    <CustomMenu<Audience>
                        menuItems={menuItems}
                        data={rowData.original}
                        icon={
                            <MantineIcon
                                icon={() => (
                                    <DotsThree
                                        size={14}
                                        weight="bold"
                                        color="rgb(var(--color-gray-600))"
                                    />
                                )}
                            />
                        }
                    />
                    {previewOpened && !isFetchingPreview && (
                        <Box
                            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                                e.stopPropagation();
                            }}
                        >
                            <AudiencePreviewModal
                                opened={previewOpened}
                                close={closePreview}
                                data={previewAudienceData?.rows || []}
                                fields={previewAudienceData?.fields || {}}
                                generationStrategy={
                                    rowData.original.generationStrategy
                                }
                                isEditMode={true}
                                handlePreview={undefined}
                                isValidQuery={undefined}
                                showPropertySelect={true}
                                footerRightSection={
                                    previewAudienceData &&
                                    previewAudienceData.rows.length > 0 && (
                                        <Button
                                            variant={ButtonVariant.PRIMARY}
                                            onClick={async (
                                                e: React.MouseEvent,
                                            ) => {
                                                e.stopPropagation();
                                                void triggerExport();
                                                closePreview();
                                                showToastSuccess({
                                                    title: t(
                                                        'audience_export.toast_success_message',
                                                    ),
                                                });
                                            }}
                                        >
                                            {t('audience_export.modal_button')}
                                        </Button>
                                    )
                                }
                                initialColumns={{
                                    previewFields: getPreviewInitialColumns(),
                                }}
                                bottomSection={null}
                                isApiCallTimeout={undefined}
                                audienceId={rowData.original.id}
                                onPropertySelect={(fields) => {
                                    handlePropertySelect(fields);
                                }}
                                allowPropertySelect={true}
                            />
                        </Box>
                    )}
                    {iterablePreviewOpened && !isFetchingPreview && (
                        <Box
                            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                                e.stopPropagation();
                            }}
                        >
                            <AudiencePreviewModal
                                opened={iterablePreviewOpened}
                                close={closeIterablePreview}
                                data={previewAudienceData?.rows || []}
                                fields={previewAudienceData?.fields || {}}
                                generationStrategy={
                                    rowData.original.generationStrategy
                                }
                                isEditMode={true}
                                handlePreview={undefined}
                                isValidQuery={undefined}
                                showPropertySelect={true}
                                footerRightSection={
                                    previewAudienceData &&
                                    previewAudienceData.rows.length > 0 && (
                                        <Button
                                            variant={ButtonVariant.PRIMARY}
                                            loading={isIterableExportLoading}
                                            loaderProps={{
                                                size: 'xs',
                                            }}
                                            onClick={triggerIterableExport}
                                            disabled={
                                                (useExistingList && !listId) ||
                                                (!useExistingList && !name)
                                            }
                                            rightIcon={
                                                <Share
                                                    size={14}
                                                    color="white"
                                                    strokeWidth={2.5}
                                                />
                                            }
                                        >
                                            {t(
                                                'audience_export_iterable.modal_button',
                                            )}
                                        </Button>
                                    )
                                }
                                initialColumns={{
                                    previewFields: getPreviewInitialColumns(),
                                }}
                                bottomSection={null}
                                isApiCallTimeout={undefined}
                                audienceId={rowData.original.id}
                                onPropertySelect={(fields) => {
                                    handlePropertySelect(fields);
                                    setPreviewFields(fields);
                                }}
                                allowPropertySelect={true}
                                rightSection={
                                    <IterableExportSection
                                        previewFields={previewFields.map(
                                            (field) => ({
                                                fieldWithSuggestion:
                                                    fieldsWithSuggestions[
                                                        field
                                                    ],
                                                fieldId: field,
                                            }),
                                        )}
                                        emailField={emailField}
                                        setEmailField={setEmailField}
                                        listId={listId}
                                        setListId={setListId}
                                        name={name}
                                        setName={setName}
                                        description={description}
                                        setDescription={setDescription}
                                        updateExistingUsersOnly={
                                            updateExistingUsersOnly
                                        }
                                        setUpdateExistingUsersOnly={
                                            setUpdateExistingUsersOnly
                                        }
                                        iterableLists={iterableLists}
                                        isLoading={isFetchingLists}
                                        useExistingList={useExistingList}
                                        setExistingList={setUseExistingList}
                                    />
                                }
                                customTitle={`Send ${rowData.original.name} to Iterable`}
                            />
                        </Box>
                    )}
                    <Modal
                        opened={openedHideConfirmModal}
                        onClose={closeHideConfirmModal}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        title={
                            <Box>
                                <Text>
                                    {t(
                                        'audinece_manager.confirm_hide_modal_title',
                                    )}
                                </Text>
                                <Text className="text-sm font-normal text-gray-600">
                                    {rowData.original.name}
                                </Text>
                            </Box>
                        }
                        footerRightSection={
                            <ButtonGroup
                                primaryButtonLabel={t(
                                    'audience_manager.confirm_hide_prim_button',
                                )}
                                secondaryButtonLabel={t('common.no')}
                                isUpdating={isUpdating}
                                handlePrimaryButtonClick={async () => {
                                    await mutateAsyncUpdate(
                                        {
                                            audienceId: rowData.original.id,
                                            relationUuid: activeRelationUuid,
                                            payload: {
                                                tags: [
                                                    ...(rowData.original.tags ||
                                                        []),
                                                    CommonReservedTags.HIDDEN,
                                                ],
                                            },
                                        },
                                        {
                                            onSuccess: () => {
                                                showToastSuccess({
                                                    title: t(
                                                        'common.hidden_success',
                                                        {
                                                            entityName:
                                                                t(
                                                                    'common.audience',
                                                                ),
                                                        },
                                                    ),
                                                });
                                            },
                                            onError: (error) => {
                                                showToastError({
                                                    title: t(
                                                        'common.hidden_error',
                                                        {
                                                            entityName:
                                                                t(
                                                                    'common.audience',
                                                                ),
                                                        },
                                                    ),
                                                    subtitle:
                                                        error.error.message,
                                                });
                                            },
                                        },
                                    );
                                    onRowChange();
                                    closeHideConfirmModal();
                                }}
                                handleSecondaryButtonClick={
                                    closeHideConfirmModal
                                }
                                primaryButtonVariant={
                                    ButtonVariant.FILLED_DESTRUCTIVE
                                }
                                primaryButtonRightIcon={
                                    <CaretRight color="white" />
                                }
                            />
                        }
                        closeButtonProps={{
                            className: 'absolute top-3 right-3',
                        }}
                    >
                        <Flex direction={'column'} gap={12}>
                            <Text className="text-sm font-semibold text-gray-800">
                                {t(
                                    'audinece_manager.confirm_hide_modal_header',
                                )}
                            </Text>
                            <Text className="text-sm text-gray-800">
                                {t(
                                    'audinece_manager.confirm_hide_modal_description',
                                )}
                            </Text>
                        </Flex>
                    </Modal>
                </>
            )}
        </>
    );
};
