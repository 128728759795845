import Modal from '@components/common/modal/Modal';
import TemplateBuilderManager from '@components/Templates/Builder/TemplateBuilderManager';
import { useLocale } from '@hooks/useLocale';
import {
    type CommunicationChannel,
    type Integration,
    type ProviderTemplateDetails,
    type TemplateContentDetails,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import React from 'react';

interface TemplateOptionsModalProps {
    opened: boolean;
    onClose: () => void;
    providerMetadata: Integration;
    integrationId: string;
    handleTemplateSelect: (obj: ProviderTemplateDetails) => void;
    channel: CommunicationChannel;
    showCreateEditTemplate: boolean;
    templateId: string | undefined;
    isTemplateLibrary: boolean;
    editMode: boolean;
    contentDetails: TemplateContentDetails | undefined;
    handleUpdateTemplateInCampaignContext:
        | ((content: TemplateContentDetails) => void)
        | undefined;
}

const TemplateOptionsModal: React.FC<TemplateOptionsModalProps> = ({
    opened,
    onClose,
    providerMetadata,
    integrationId,
    handleTemplateSelect,
    channel,
    showCreateEditTemplate,
    templateId,
    isTemplateLibrary,
    editMode,
    contentDetails,
    handleUpdateTemplateInCampaignContext,
}) => {
    const { t } = useLocale();

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            keepMounted={false}
            title={t('template_manager.change_template', { channel })}
            size="lg"
            closeOnEscape={false}
        >
            <Box className="py-3">
                <TemplateBuilderManager
                    integration={providerMetadata}
                    isSingleSelect={true}
                    handleTemplateSelect={(obj) => {
                        onClose();
                        handleTemplateSelect(obj);
                    }}
                    integrationId={integrationId}
                    channel={channel}
                    showCreateEditTemplate={showCreateEditTemplate}
                    templateId={templateId}
                    isTemplateLibrary={isTemplateLibrary}
                    onClose={onClose}
                    editMode={editMode}
                    contentDetails={contentDetails}
                    handleUpdateTemplateInCampaignContext={
                        handleUpdateTemplateInCampaignContext
                    }
                />
            </Box>
        </Modal>
    );
};

export default React.memo(TemplateOptionsModal);
