import { ListSelectItem } from '@components/Audience/Filters/FilterSelect';
import { useLocale } from '@hooks/useLocale';
import {
    type ContentMappings,
    type ContentMappingSection,
    type TemplateVariableDetails,
} from '@lightdash/common';
import { Box, Flex, Text, Tooltip } from '@mantine/core';
import { capitalize } from 'lodash';
import React from 'react';
import usePersonalization from './usePersonalization';

const PersonalizationFilled = ({
    variables,
    mappings,
}: {
    variables: TemplateVariableDetails | undefined;
    mappings: ContentMappings | undefined;
}) => {
    const { t } = useLocale();

    const { fieldWithSuggestionInArray, variablesContent } = usePersonalization(
        {
            variables,
        },
    );
    return (
        <Box>
            {Object.entries(variablesContent).map(([section, vars]) => (
                <Box key={section}>
                    {vars?.length ? (
                        <Text className="text-gray-600 font-medium mb-6">
                            {capitalize(section)} variables
                        </Text>
                    ) : null}
                    {vars?.map((item) => {
                        const value =
                            mappings?.[section as ContentMappingSection]?.[item]
                                ?.value;
                        const defaultValue =
                            mappings?.[section as ContentMappingSection]?.[item]
                                ?.defaultValue;
                        const isSet = Boolean(value) || Boolean(defaultValue);
                        return (
                            <Box key={`${section}_${item}`} className="mb-6">
                                <Flex align={'center'} gap={8}>
                                    <Text className="text-gray-800 font-semibold mb-2 w-[30%]">
                                        <span className="text-gray-600">{`{{ `}</span>
                                        {item}
                                        <span className="text-gray-600">{` }}`}</span>
                                    </Text>
                                    {isSet ? (
                                        <Flex align={'center'}>
                                            {value && (
                                                <ListSelectItem
                                                    item={fieldWithSuggestionInArray.find(
                                                        (field) =>
                                                            field.uniqueIdentifier ===
                                                            value,
                                                    )}
                                                    divider={
                                                        <span className="text-sm text-gray-600">
                                                            in
                                                        </span>
                                                    }
                                                    placeholder={''}
                                                    hideLabel={false}
                                                    hideTableLabel={false}
                                                    labelCustomClasses={''}
                                                    tableLabelCustomClasses={''}
                                                />
                                            )}
                                            {defaultValue && (
                                                <Tooltip label={defaultValue}>
                                                    <Text
                                                        className={
                                                            value &&
                                                            'overflow-hidden max-w-[100px] truncate'
                                                        }
                                                    >
                                                        {value ? '/ ' : ''}
                                                        {defaultValue}
                                                    </Text>
                                                </Tooltip>
                                            )}
                                        </Flex>
                                    ) : (
                                        <Text className={'text-gray-500'}>
                                            {t('common.empty_state.not_set')}
                                        </Text>
                                    )}
                                </Flex>
                            </Box>
                        );
                    })}
                </Box>
            ))}
        </Box>
    );
};

export default PersonalizationFilled;
