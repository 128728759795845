import {
    ChartType,
    type CreateSavedChartVersion,
    type FilterGroupOperatorType,
    type MetricQuery,
    type NestedMetricQueryGroup,
} from '@lightdash/common';

import { t as translate } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

export const metricQueryDefaultState = {
    tableName: '',
    metricQuery: {
        exploreName: '',
        dimensions: [],
        metrics: [],
        filters: {},
        sorts: [],
        limit: 500,
        tableCalculations: [],
        additionalMetrics: [],
    },
    pivotConfig: undefined,
    tableConfig: {
        columnOrder: [],
    },
    chartConfig: {
        type: ChartType.CARTESIAN,
        config: undefined,
    },
};

export const getModifiedNestedMetricQuery = ({
    index,
    unsavedChartVersion,
    nestedMetricQuery,
    globalFilterCondition,
    filters,
}: {
    index: number;
    unsavedChartVersion: CreateSavedChartVersion[];
    nestedMetricQuery: NestedMetricQueryGroup | undefined;
    globalFilterCondition: FilterGroupOperatorType;
    filters: MetricQuery['filters'];
}) => {
    const indexToModify = index ?? 0;
    const modifiedUnsavedChartVersion = unsavedChartVersion.map(
        (chartVersion, idx) => {
            if (idx === indexToModify) {
                return {
                    ...chartVersion,
                    metricQuery: {
                        ...chartVersion.metricQuery,
                        filters,
                    },
                };
            }
            return chartVersion;
        },
    );

    const metricQueries = modifiedUnsavedChartVersion.map(
        (metric) => metric.metricQuery,
    );

    let modifiedNestedMetricQuery: NestedMetricQueryGroup = {
        id: nestedMetricQuery?.id ?? uuidv4(),
        and: metricQueries,
    };

    if (globalFilterCondition === 'or') {
        modifiedNestedMetricQuery = {
            id: nestedMetricQuery?.id ?? uuidv4(),
            or: metricQueries,
        };
    }

    return {
        modifiedNestedMetricQuery,
        modifiedUnsavedChartVersion,
    };
};

export const DefaultAudienceName = translate('audience.untitled');
export const DefaultDuplicateAudienceName = translate('common.copy_of');
