import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';
import { type SegmentedTimeFilterOptions } from '@components/common/SegmentedTimeFilter/types';
import { isProjectSetUpCompleted } from '@components/ProjectConnection/utils';
import SetupPage from '@components/SetupPage';
import { useAuditLogs } from '@hooks/useAuditLog';
import { useLocale } from '@hooks/useLocale';
import { type EntityType } from '@lightdash/common';
import { useDebouncedState } from '@mantine/hooks';
import useProjectContext from '@providers/Project/useProjectContext';
import { type SortingState } from '@tanstack/react-table';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import React, { useState } from 'react';
import AuditLogsTable from './Table/AuditLogsTable';

const AuditLogsContainer: React.FC<{}> = () => {
    const { t } = useLocale();
    const { projectData: activeProject } = useProjectContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchString, setSearchString] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );

    const [selectedUser, setSelectedUser] = useState('');
    const [resourceType, setResourceType] = useState<EntityType | null>(null);
    const [timeFilter, setTimeFilter] = useState<{
        startTimestamp: string;
        endTimestamp: string;
    }>({
        startTimestamp: '',
        endTimestamp: '',
    });
    const [sorting, setSorting] = useState<SortingState>([
        {
            id: 'timestamp',
            desc: true,
        },
    ]);
    const [selectedFilter, setSelectedFilter] = useState<
        SegmentedTimeFilterOptions | undefined
    >(undefined);

    const { data: auditLogs, isFetching: isAuditLogsFetching } = useAuditLogs({
        perPage: 10,
        currentPage,
        query: [
            searchString && `entityName=${searchString}`,
            selectedUser && `userId=${selectedUser}`,
            resourceType && `entityType=${resourceType}`,
            timeFilter.startTimestamp &&
                `startTimestamp=${timeFilter.startTimestamp}`,
            timeFilter.endTimestamp &&
                `endTimestamp=${timeFilter.endTimestamp}`,
            sorting[0]?.id
                ? `&sort=${sorting[0]?.id}&order=${
                      sorting[0]?.desc ? 'desc' : 'asc'
                  }`
                : '',
        ]
            .filter(Boolean)
            .join('&'),
    });

    const handleSearchInput = (value: string) => {
        setSearchString(value);
        setCurrentPage(1);
    };

    if (activeProject && !isProjectSetUpCompleted(activeProject)) {
        return (
            <SetupPage
                description={t('set_up_page.description', {
                    type_description: t(
                        'set_up_page.type_description.journeys',
                    ),
                })}
            />
        );
    }

    return (
        <ManagerBuilderContainer
            withContentPadding={false}
            title={t('audit_logs.audit_logs')}
        >
            <AuditLogsTable
                isDataFetching={isAuditLogsFetching}
                auditLogs={auditLogs?.data ?? []}
                onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                }}
                onSearchChange={handleSearchInput}
                searchValue={searchString}
                onUserChange={(newUser: string) => {
                    setSelectedUser(newUser);
                    setCurrentPage(1);
                }}
                selectedUser={selectedUser}
                onResourceTypeChange={(newResourceType: EntityType | null) => {
                    setResourceType(newResourceType);
                    setCurrentPage(1);
                }}
                resourceType={resourceType}
                selectedFilter={selectedFilter}
                onFilterChange={(newFilter) => {
                    setSelectedFilter(newFilter);
                    setCurrentPage(1);
                }}
                onTimeFilterChange={(newTimeFilter) =>
                    setTimeFilter({
                        startTimestamp: newTimeFilter.startTimestamp
                            ? new Date(
                                  newTimeFilter.startTimestamp,
                              ).toISOString()
                            : '',
                        endTimestamp: newTimeFilter.endTimestamp
                            ? new Date(newTimeFilter.endTimestamp).toISOString()
                            : '',
                    })
                }
                pagination={
                    auditLogs?.paginate ?? {
                        total: 0,
                        lastPage: 0,
                        currentPage: 0,
                        perPage: 0,
                        from: 0,
                        to: 0,
                    }
                }
                sorting={sorting}
                setSorting={setSorting}
            />
        </ManagerBuilderContainer>
    );
};

const AuditLogsManager: React.FC<{}> = () => {
    return <AuditLogsContainer />;
};

export default AuditLogsManager;
