import { SetupContentSource } from '@components/Campaigns/Builder/types';
import {
    CommunicationChannel,
    type EmailTemplateContentDetails,
    type SmsTemplateContentDetails,
    type Template,
} from '@lightdash/common';
import React from 'react';

import SMSPreview from '@components/Templates/SMSManager/SMSPreview';
import { Box } from '@mantine/core';
import HTMLPreview from '../../HTMLManager/HTMLPreview';

type PreviewProps = {
    templateMetadata: Template | undefined;
    handleModifyTemplate: () => void;
    grapesId: string;
    previewUserData: Record<string, unknown> | undefined;
    showModifyButton?: boolean;
};
const Preview = ({
    templateMetadata,
    handleModifyTemplate,
    grapesId,
    previewUserData,
    showModifyButton = true,
}: PreviewProps) => {
    if (
        templateMetadata &&
        templateMetadata.channel === CommunicationChannel.EMAIL
    ) {
        const emailContent = templateMetadata?.contents[0]
            ?.content as EmailTemplateContentDetails;
        const templateDetail = {
            id: templateMetadata.id,
            name: templateMetadata?.name ?? '',
            html: emailContent?.html,
            unsubscribeBlock: true,
        };
        return (
            <HTMLPreview
                templateDetail={templateDetail}
                subject={templateMetadata?.contents[0]?.subject ?? ''}
                senderName={''}
                senderMail={''}
                handleModifyTemplate={handleModifyTemplate}
                setGrapesPreviewInstance={undefined}
                grapesContainerId={grapesId}
                showDeviceAndThemeSwitcher={true}
                showModifyButton={showModifyButton}
                isSetupComplete={true}
                openContentSetupManager={undefined}
                showSetupStrip={false}
                showHeader
                previewUserData={previewUserData}
                source={SetupContentSource.TEMPLATE_DETAILS}
                showPreviewUserSelect={false}
                disablePreviewUserSelect={false}
                showSenderDetails={false}
                showSubject={false}
            />
        );
    }
    if (
        templateMetadata &&
        templateMetadata.channel === CommunicationChannel.SMS
    ) {
        const smsAppContent = templateMetadata.contents[0]
            .content as SmsTemplateContentDetails;
        return (
            <Box className="mt-3 ml-3">
                <SMSPreview
                    content={smsAppContent.body}
                    isFlash={smsAppContent.flash || false}
                    allowModify={showModifyButton}
                    onModify={handleModifyTemplate}
                    templateName={templateMetadata.name}
                    isSetupComplete={true}
                    openContentSetupManager={() => {}}
                    showSetupStrip={false}
                    showHeader
                />
            </Box>
        );
    }
    return <div></div>;
};

export default Preview;
