import { SetupContentSource } from '@components/Campaigns/Builder/types';
import DeviceSwitcher from '@components/Templates/HTMLManager/HTMLPreview/DeviceSwitcher';
import ThemeSwitcher from '@components/Templates/HTMLManager/HTMLPreview/ThemeSwitcher';
import { parseHtmlVariables } from '@components/Templates/utils';
import { useIsTruncated } from '@hooks/useIsTruncated';
import { useLocale } from '@hooks/useLocale';
import {
    Avatar,
    Box,
    Button,
    Flex,
    Group,
    Stack,
    Text,
    Tooltip,
} from '@mantine/core';
import { CaretDown, CheckCircle } from '@phosphor-icons/react';
import grapesjs, { type Editor } from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ButtonVariant } from '../../../../mantineTheme';
import { type HtmlTemplateDetails } from './../types';
import {
    getPreviewConfig,
    nonEditableComponentConfig,
    previewConfig,
    themeCSS,
} from './config';
import { Devices, Theme } from './types';

interface IHTMLPreviewProps {
    templateDetail: HtmlTemplateDetails;
    subject: string;
    senderName: string;
    senderMail: string;
    handleModifyTemplate: () => void;
    setGrapesPreviewInstance: ((editor: Editor) => void) | undefined;
    grapesContainerId: string;
    showDeviceAndThemeSwitcher: boolean;
    showModifyButton: boolean;
    isSetupComplete: boolean | undefined;
    openContentSetupManager: (() => void) | undefined;
    showSetupStrip: boolean;
    showHeader?: boolean;
    previewUserData?: Record<string, unknown>;
    source: SetupContentSource;
    showPreviewUserSelect: boolean | undefined;
    disablePreviewUserSelect?: boolean;
    showSubject?: boolean;
    showSenderDetails?: boolean;
}

const HTMLPreview = ({
    templateDetail,
    subject,
    senderName,
    senderMail,
    handleModifyTemplate,
    setGrapesPreviewInstance,
    grapesContainerId,
    previewUserData,
    showModifyButton,
    showDeviceAndThemeSwitcher,
    showHeader = true,
    source,
    showSubject = true,
    showSenderDetails = true,
}: IHTMLPreviewProps) => {
    const { t } = useLocale();
    const [editor, setEditor] = useState<Editor | null>(null);
    const [theme, setTheme] = useState<Theme>(Theme.LIGHT);
    const [device, setDevice] = useState<Devices>(Devices.DESKTOP);
    const [deviceWidth, setDeviceWidth] = useState<string>('');
    const [themeInitialized, setThemeInitialized] = useState<boolean>(false);
    const previewContainer = useRef<HTMLDivElement>(null);
    const { ref: truncatedRef, isTruncated } = useIsTruncated<HTMLDivElement>();

    useEffect(() => {
        const editorJs = grapesjs.init(getPreviewConfig(grapesContainerId));
        const parsedHtml = parseHtmlVariables({
            html: templateDetail?.html,
            variables: previewUserData,
        });
        editorJs.setComponents(parsedHtml);

        // Disable all interactions
        editorJs.DomComponents.getWrapper()?.onAll((comp) => {
            comp.set(nonEditableComponentConfig);
        });
        if (setGrapesPreviewInstance) {
            setGrapesPreviewInstance(editorJs);
        }
        setEditor(editorJs);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateDetail?.html, previewUserData]);

    const injectThemeStyles = useCallback((iframeDoc: Document | null) => {
        if (!iframeDoc) return;

        const linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'stylesheet');
        linkElement.setAttribute('type', 'text/css');
        linkElement.setAttribute(
            'href',
            'data:text/css;charset=UTF-8,' + encodeURIComponent(themeCSS),
        );

        const head = iframeDoc.querySelector('head');
        if (!head) return;

        head.appendChild(linkElement);
    }, []);

    const injectDarkModeLayer = useCallback((iframeDoc: Document | null) => {
        if (!iframeDoc) return;

        const layer = document.createElement('div');
        layer.id = 'layer';
        layer.classList.add('darkmode-layer');

        const body = iframeDoc.querySelector('body');
        if (!body) return;

        body.insertBefore(layer, body.firstChild);
    }, []);

    const initTheme = useCallback(
        (iframeDoc: Document | null) => {
            if (themeInitialized || !iframeDoc) return;

            injectThemeStyles(iframeDoc);
            injectDarkModeLayer(iframeDoc);
            setThemeInitialized(true);
        },
        [injectDarkModeLayer, injectThemeStyles, themeInitialized],
    );

    const injectDarkModeStyling = useCallback(
        (iframeDoc: Document | null) => {
            if (!iframeDoc) return;
            initTheme(iframeDoc);

            const body = iframeDoc.querySelector('body');
            if (!body) return;

            body.querySelector('#layer')?.classList.add(
                'darkmode-layer--simple',
            );
        },
        [initTheme],
    );

    const revertDarkModeStyling = useCallback((iframeDoc: Document | null) => {
        if (!iframeDoc) return;

        const body = iframeDoc.querySelector('body');
        if (!body) return;

        body.querySelector('#layer')?.classList.remove(
            'darkmode-layer--simple',
        );
    }, []);

    const changeMode = useCallback(
        (mode: Theme) => {
            setTheme(mode);
            previewContainer.current?.classList.toggle(Theme.DARK);
            const iframe: HTMLIFrameElement | null | undefined =
                previewContainer.current?.querySelector('iframe');
            if (!iframe) return;

            const iframeDoc: Document | null = iframe.contentDocument;
            if (!iframeDoc) return;

            if (mode === Theme.DARK) injectDarkModeStyling(iframeDoc);
            else revertDarkModeStyling(iframeDoc);
        },
        [injectDarkModeStyling, revertDarkModeStyling],
    );

    const changeDevice = useCallback(
        (mode: Devices) => {
            if (!editor) return;

            editor.setDevice(mode);
            setDevice(mode);
        },
        [editor],
    );

    useEffect(() => {
        const activeDevice = previewConfig.deviceManager.devices.find(
            (d) => d.id === device,
        );
        let width = '';
        if (activeDevice && activeDevice.width) {
            width = `calc(${activeDevice.width} + 60px)`;
        } else {
            width = 'calc(100% + 1px)';
        }

        setDeviceWidth(width);
    }, [device]);

    const heightClass = useMemo(() => {
        if (source === SetupContentSource.CAMPAIGN) {
            if (templateDetail?.unsubscribeBlock) {
                return '!h-[calc(100vh-263px)]';
            }
            return '!h-[calc(100vh-303px)]';
        }
        if (source === SetupContentSource.JOURNEY) {
            return '!h-[calc(100vh-403px)]';
        }
        if (source === SetupContentSource.TEMPLATE_DETAILS) {
            return '!h-[calc(100vh-283px)]';
        }
    }, [source, templateDetail?.unsubscribeBlock]);

    return (
        <Box className="h-full w-full">
            {showHeader && (
                <Box className="p-3 border-b-[1px] bg-white">
                    <Flex justify={'space-between'}>
                        <Flex align={'center'} gap={8}>
                            <CheckCircle color="rgb(var(--color-blu-800))" />
                            <Text className="text-lg text-gray-800 font-medium">
                                {t(
                                    'campaigns_builder_details.campaign_preview_title',
                                )}
                            </Text>
                        </Flex>
                        <Flex gap={8}>
                            {showDeviceAndThemeSwitcher && (
                                <>
                                    <DeviceSwitcher
                                        theme={theme}
                                        onSwitch={changeDevice}
                                        showLabel={false}
                                    />
                                    <ThemeSwitcher onSwitch={changeMode} />
                                </>
                            )}
                            {showModifyButton && (
                                <Button
                                    variant={ButtonVariant.OUTLINED}
                                    onClick={handleModifyTemplate}
                                >
                                    {t('template_create.edit_design')}
                                </Button>
                            )}
                        </Flex>
                    </Flex>
                </Box>
            )}
            <Box className="relative">
                {!templateDetail?.unsubscribeBlock && (
                    <Box className="px-[13px] py-[9px] bg-halt-600/25">
                        <Text className="text-center text-halt-800">
                            {t('campaign.content.unsubscribe_variable_missing')}
                        </Text>
                    </Box>
                )}
                <Stack align="center" spacing={0} className="p-3 bg-gray-50">
                    <Box
                        className={`${heightClass} rounded-[4px] overflow-hidden`}
                        style={{
                            boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.13)',
                            width: deviceWidth,
                            transition: 'width 0.5s ease',
                        }}
                    >
                        {showSubject && (
                            <Box className="w-full bg-white">
                                <Tooltip
                                    label="Subject"
                                    disabled={!isTruncated}
                                >
                                    <span>
                                        <Text
                                            className="bg-white text-base p-3 truncate max-w-[600px]"
                                            ref={truncatedRef}
                                        >
                                            Re: {subject}
                                        </Text>
                                    </span>
                                </Tooltip>
                            </Box>
                        )}
                        {showSenderDetails && (
                            <Group className="gap-3 bg-white w-full px-3">
                                <Avatar
                                    data-testid="user-avatar"
                                    variant="filled"
                                    size={40}
                                    color="rgb(var(--color-blu-800))"
                                    radius={100}
                                    styles={{
                                        placeholder: {
                                            fontSize: '1.5rem',
                                            fontWeight: 'normal',
                                        },
                                    }}
                                >
                                    {senderName?.[0]}
                                </Avatar>

                                <Stack className="gap-0">
                                    <Text className="text-gray-850 dark:text-white">
                                        {senderName}{' '}
                                        <span className="text-xs text-gray-600">
                                            {'<'}
                                            {senderMail}
                                            {'>'}
                                        </span>
                                    </Text>
                                    <Group className="gap-1">
                                        <Text className="text-xs text-gray-600">
                                            to me
                                        </Text>
                                        <CaretDown weight="regular" size={12} />
                                    </Group>
                                </Stack>
                            </Group>
                        )}
                        <Box
                            id={grapesContainerId}
                            ref={previewContainer}
                            className={`html-preview ${heightClass}`}
                        />
                    </Box>
                </Stack>
                <Box className="absolute z-10 w-full border-t-[1px] backdrop-blur-sm bg-white-30 bottom-0 text-center py-2">
                    <Text className="text-xs text-gray-500">
                        {t('template_preview.disclaimer')}
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};

export default HTMLPreview;
