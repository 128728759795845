import { getEntityURL } from '@components/AuditLogs/utils';
import { type SegmentedTimeFilterOptions } from '@components/common/SegmentedTimeFilter/types';
import useNotify from '@hooks/toaster/useNotify';
import { useCampaigns } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { useGetUserHistory } from '@hooks/useProfile';
import {
    EntityType,
    getEventTableName,
    getSortmentWarehouseName,
    ReservedCampaignEventColumns,
    ReservedEventColumns,
    WarehouseTypes,
    type ApiSqlQueryResults,
    type ReducedCampaign,
} from '@lightdash/common';
import { Box, Button, Flex, Stack, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { ArrowSquareOut, Copy, PaperPlaneTilt } from '@phosphor-icons/react';
import useProjectContext from '@providers/Project/useProjectContext';
import {
    PROFILE_ACTIVITY_PAGE_SIZE,
    SEARCH_INPUT_DEBOUNCE_TIME,
} from '@utils/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AceEditor from 'react-ace';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { ButtonVariant } from '../../../../../mantineTheme';
import { getUserHistoryFilter } from '../../utils';
import EventsHistoryAccordion from '../EventsHistoryAccordion';

interface InternalEventHistoryProps {
    searchString: string;
    currentSelectedTimeFilter: SegmentedTimeFilterOptions | undefined;
    timeFilter: {
        startTimestamp?: string;
        endTimestamp?: string;
    };
}

const InternalEventHistory: React.FC<InternalEventHistoryProps> = ({
    searchString,
    currentSelectedTimeFilter,
    timeFilter,
}) => {
    const { showToastSuccess } = useNotify();
    const { t } = useLocale();

    const [offset, setOffset] = useState<number>(0);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
    const [debouncedSearchString] = useDebouncedValue(
        searchString,
        SEARCH_INPUT_DEBOUNCE_TIME,
    );

    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { profileId } = useParams<{ profileId: string }>();
    const [accumulatedUserHistory, setAccumulatedUserHistory] = useState<
        ApiSqlQueryResults | undefined
    >(undefined);
    const { data: campaigns, isLoading: isCampaignsLoading } = useCampaigns({
        perPage: 9999,
        currentPage: 1,
        polling: false,
    });
    const { projectData } = useProjectContext();

    const filterForProfileSearch = useMemo(() => {
        if (!projectUuid || !projectData || !projectData.warehouseConnection)
            return null;
        return getUserHistoryFilter(
            projectUuid,
            profileId,
            projectData.warehouseConnection.type,
            debouncedSearchString,
            timeFilter.startTimestamp,
            timeFilter.endTimestamp,
        );
    }, [
        projectUuid,
        profileId,
        debouncedSearchString,
        timeFilter,
        projectData,
    ]);

    const {
        data: userHistory,
        isLoading,
        isFetching,
    } = useGetUserHistory(projectUuid, {
        limit: PROFILE_ACTIVITY_PAGE_SIZE,
        offset,
        filters: filterForProfileSearch ?? undefined,
        sorts: [
            {
                fieldId: `${getEventTableName(
                    projectUuid ?? '',
                    projectData?.warehouseConnection?.type ??
                        WarehouseTypes.SNOWFLAKE,
                )}_${getSortmentWarehouseName(
                    ReservedEventColumns.SERVER_TIMESTAMP,
                    projectData?.warehouseConnection?.type ??
                        WarehouseTypes.SNOWFLAKE,
                )}`,
                descending: true,
            },
        ],
    });

    useEffect(() => {
        if (userHistory) {
            setAccumulatedUserHistory((prev) => ({
                ...prev,
                rows: [...(prev?.rows ?? []), ...userHistory.rows],
                fields: prev?.fields ?? userHistory.fields,
            }));
        }
    }, [userHistory]);

    const prepareVisibleActivities = useCallback(() => {
        if (!projectUuid) return [];
        return accumulatedUserHistory?.rows.map((item, index, array) => {
            const currentDate = new Date(
                item[
                    `${getEventTableName(
                        projectUuid,
                        projectData?.warehouseConnection?.type ??
                            WarehouseTypes.SNOWFLAKE,
                    )}_${getSortmentWarehouseName(
                        ReservedEventColumns.SERVER_TIMESTAMP,
                        projectData?.warehouseConnection?.type ??
                            WarehouseTypes.SNOWFLAKE,
                    )}` as string
                ] as string,
            );
            const previousDate =
                index > 0
                    ? new Date(
                          array[index - 1][
                              `${getEventTableName(
                                  projectUuid,
                                  projectData?.warehouseConnection?.type ??
                                      WarehouseTypes.SNOWFLAKE,
                              )}_${getSortmentWarehouseName(
                                  ReservedEventColumns.SERVER_TIMESTAMP,
                                  projectData?.warehouseConnection?.type ??
                                      WarehouseTypes.SNOWFLAKE,
                              )}` as string
                          ] as string,
                      )
                    : null;
            const showDateHeader =
                index === 0 ||
                currentDate.toLocaleDateString() !==
                    previousDate?.toLocaleDateString();
            const campainId = item[
                `${getEventTableName(
                    projectUuid,
                    projectData?.warehouseConnection?.type ??
                        WarehouseTypes.SNOWFLAKE,
                )}_${getSortmentWarehouseName(
                    ReservedCampaignEventColumns.CUSTOM_ID,
                    projectData?.warehouseConnection?.type ??
                        WarehouseTypes.SNOWFLAKE,
                )}` as string
            ] as string;

            const campaign = campaigns?.data?.find(
                (eachCampaign) => eachCampaign.id === campainId,
            );
            const rawPayload = item[
                `${getEventTableName(
                    projectUuid,
                    projectData?.warehouseConnection?.type ??
                        WarehouseTypes.SNOWFLAKE,
                )}_${getSortmentWarehouseName(
                    ReservedEventColumns.RAW_PAYLOAD,
                    projectData?.warehouseConnection?.type ??
                        WarehouseTypes.SNOWFLAKE,
                )}` as string
            ] as string;
            const payload = rawPayload ? JSON.parse(rawPayload) : null;

            return {
                id: uuidv4(),
                timestamp: item[
                    `${getEventTableName(
                        projectUuid,
                        projectData?.warehouseConnection?.type ??
                            WarehouseTypes.SNOWFLAKE,
                    )}_${getSortmentWarehouseName(
                        ReservedEventColumns.SERVER_TIMESTAMP,
                        projectData?.warehouseConnection?.type ??
                            WarehouseTypes.SNOWFLAKE,
                    )}` as string
                ] as string,
                name: item[
                    `${getEventTableName(
                        projectUuid,
                        projectData?.warehouseConnection?.type ??
                            WarehouseTypes.SNOWFLAKE,
                    )}_${getSortmentWarehouseName(
                        ReservedEventColumns.EVENT_NAME,
                        projectData?.warehouseConnection?.type ??
                            WarehouseTypes.SNOWFLAKE,
                    )}` as string
                ] as string,
                payload,
                showDateHeader,
                currentDate,
                data: campaign,
                isReadonly: !Boolean(payload),
            };
        });
    }, [projectUuid, accumulatedUserHistory, campaigns?.data, projectData]);

    const visibleActivities = useMemo(
        () => prepareVisibleActivities(),
        [prepareVisibleActivities],
    );

    const handleTimeFilterChangeOrSearchChange = useCallback(() => {
        setOffset(0);
        setAccumulatedUserHistory(undefined);
        setIsLoadingMore(false);
    }, []);
    useEffect(() => {
        handleTimeFilterChangeOrSearchChange();
    }, [
        currentSelectedTimeFilter,
        handleTimeFilterChangeOrSearchChange,
        searchString,
    ]);
    const handleClickCampaign = useCallback(
        (
            e: React.MouseEvent<HTMLDivElement>,
            item: {
                id: string;
                timestamp: string;
                name: string;
                payload: any;
                showDateHeader: boolean;
                currentDate: Date;
                data: ReducedCampaign | undefined;
            },
        ) => {
            e.stopPropagation();
            window.open(
                getEntityURL({
                    entityType: EntityType.Campaign,
                    entityId: item.data?.id ?? '',
                    data: item.data,
                    projectUuid: projectUuid ?? '',
                }),
                '_blank',
            );
        },
        [projectUuid],
    );
    const loadMoreActivities = () => {
        if (
            userHistory &&
            userHistory.rows.length === PROFILE_ACTIVITY_PAGE_SIZE
        ) {
            setOffset((prevOffset) => prevOffset + PROFILE_ACTIVITY_PAGE_SIZE);
            setIsLoadingMore(true);
        }
    };

    return (
        <Box>
            <Stack>
                <Box className="p-3 !h-[65vh] overflow-auto">
                    <EventsHistoryAccordion<ReducedCampaign | undefined>
                        events={visibleActivities ?? []}
                        onLoadMore={loadMoreActivities}
                        eventName={(item) => {
                            if (!item.data) {
                                return;
                            }
                            return (
                                <Flex
                                    gap={4}
                                    align="center"
                                    className="text-sm font-semibold truncate cursor-pointer text-blu-800 max-w-44"
                                    onClick={(e) =>
                                        handleClickCampaign(e, item)
                                    }
                                >
                                    <PaperPlaneTilt
                                        weight="duotone"
                                        color="rgb(var(--color-blu-800))"
                                    />
                                    <Text>{item.data?.name}</Text>
                                    <ArrowSquareOut
                                        weight="duotone"
                                        size={14}
                                        color="rgb(var(--color-blu-800))"
                                    />
                                </Flex>
                            );
                        }}
                        eventPanel={(item) => (
                            <Box className="w-full border rounded border-shade-6 ">
                                <Box className="flex justify-between px-3.5 border-b border-shade-6 items-center h-[2.8rem]">
                                    <Text className="text-sm font-medium">
                                        {t('common.payload')}
                                    </Text>
                                    <Button
                                        leftIcon={
                                            <Copy
                                                weight="duotone"
                                                color="rgb(var(--color-blu-800))"
                                            />
                                        }
                                        variant={ButtonVariant.SUBTLE_ACCENTED}
                                        onClick={() => {
                                            void navigator.clipboard.writeText(
                                                JSON.stringify(
                                                    item.payload,
                                                    null,
                                                    2,
                                                ),
                                            );
                                            showToastSuccess({
                                                title: t('common.copied'),
                                                autoClose: 1000,
                                            });
                                        }}
                                    >
                                        {t('common.copy_json')}
                                    </Button>
                                </Box>
                                <Box className="px-2 py-2">
                                    <AceEditor
                                        value={JSON.stringify(
                                            item.payload,
                                            null,
                                            2,
                                        )}
                                        readOnly
                                        width="100%"
                                        minLines={1}
                                        maxLines={100}
                                        setOptions={{
                                            tabSize: 2,
                                            lineHeight: 19,
                                        }}
                                        mode="json"
                                        theme="textmate"
                                        name="json-editor"
                                        showGutter={false}
                                        highlightActiveLine={false}
                                    />
                                </Box>
                            </Box>
                        )}
                        canLoadMore={Boolean(
                            userHistory &&
                                userHistory.rows.length ===
                                    PROFILE_ACTIVITY_PAGE_SIZE,
                        )}
                        isInitialLoading={
                            (isLoading || isFetching || isCampaignsLoading) &&
                            !isLoadingMore
                        }
                        isLoadingMore={
                            isLoadingMore && (isLoading || isFetching)
                        }
                    />
                </Box>
            </Stack>
        </Box>
    );
};

export default InternalEventHistory;
