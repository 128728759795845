import { sortmentApi } from '@api/index';
import {
    type AgentMessage,
    type ApiError,
    type ApiInsightsCountQueryDto,
    type ApiResponse,
    type ApiSqlQueryCountResults,
    type ApiSqlQueryResults,
    type ApiSuccessEmpty,
    type APIUpdateAudience,
    type Audience,
    type AudienceCountPayload,
    type AudienceDescriptionRequest,
    type AudienceExportUserPayload,
    type AudienceInsightsBreakdownPayload,
    type AudienceOverlapPayload,
    type AudienceReachabilityPayload,
    type AudienceToIterablePayload,
    type EntityNameDescription,
    type InsertAudience,
    type IterableListInfo,
    type ScheduleOpts,
} from '@lightdash/common';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

const postAudience = async (
    data: InsertAudience,
    projectId: string,
    relationId: string,
) =>
    sortmentApi<Audience>({
        url: `/projects/${projectId}/relations/${relationId}/audiences`,
        method: 'POST',
        body: JSON.stringify(data),
    });
const updateAudience = async (
    data: APIUpdateAudience,
    projectId: string,
    relationId: string,
    audienceId: string,
) =>
    sortmentApi<Audience>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}`,
        method: 'PATCH',
        body: JSON.stringify(data),
    });

export const useAudienceCreateMutation = () => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation<
        Audience,
        ApiError,
        { payload: APIUpdateAudience; relationUuid: string }
    >(
        ({ payload, relationUuid }) =>
            postAudience(payload as InsertAudience, projectUuid, relationUuid),
        {
            mutationKey: [QueryKeys.CREATE_AUDIENCE],
            onSuccess: async (data) => {
                await queryClient.invalidateQueries([QueryKeys.GET_AUDIENCE]);
                await queryClient.invalidateQueries([QueryKeys.SAVED_AUDIENCE]);
                showToastSuccess({
                    title: t('use_audience.success_audience_created'),
                    subtitle: data.description,
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('use_audience.error_audience_created'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};
export const useAudienceUpdation = (hideToast?: boolean) => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation<
        Audience,
        ApiError,
        { payload: APIUpdateAudience; relationUuid: string; audienceId: string }
    >(
        ({ payload, relationUuid, audienceId }) =>
            updateAudience(payload, projectUuid, relationUuid, audienceId),
        {
            mutationKey: [QueryKeys.UPDATE_AUDIENCE],
            onSuccess: async (data) => {
                await queryClient.invalidateQueries([QueryKeys.GET_AUDIENCE]);
                await queryClient.invalidateQueries([QueryKeys.SAVED_AUDIENCE]);
                if (!hideToast) {
                    showToastSuccess({
                        title: t('use_audience.success_audience_updated'),
                        subtitle: data.description,
                    });
                }
            },
            onError: (error) => {
                if (!hideToast) {
                    showToastError({
                        title: t('use_audience.error_audience_updated'),
                        subtitle: error.error.message,
                    });
                }
            },
        },
    );
};
const activateAudience = async (
    projectId: string,
    relationId: string,
    audienceId: string,
) =>
    sortmentApi<Audience>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}/activate`,
        method: 'POST',
        body: undefined,
    });

export const useAudienceActivateMutation = () => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation<
        Audience,
        ApiError,
        { audienceUuid: string; relationUuid: string }
    >(
        ({ audienceUuid, relationUuid }) =>
            activateAudience(projectUuid, relationUuid, audienceUuid),
        {
            mutationKey: [QueryKeys.ACTIVATE_AUDIENCE],
            onSuccess: async (data) => {
                await queryClient.invalidateQueries([QueryKeys.GET_AUDIENCE]);
                await queryClient.invalidateQueries([QueryKeys.SAVED_AUDIENCE]);
                showToastSuccess({
                    title: t('use_audience.success_audience_activated'),
                    subtitle: data.description,
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('use_audience.error_audience_activated'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const scheduleAudience = async (
    projectId: string,
    relationId: string,
    audienceId: string,
    data: ScheduleOpts,
) =>
    sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}/schedule`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useAudienceScheduleMutation = () => {
    const { t } = useLocale();
    const { projectUuid: projectId = '' } = useParams<{
        projectUuid: string;
    }>();
    const { activeRelationUuid: relationId } = useRelationContext();
    const { showToastSuccess, showToastError } = useNotify();
    return useMutation<
        ApiSqlQueryResults,
        ApiError,
        { audienceId: string; data: ScheduleOpts }
    >(
        ({ audienceId, data }) =>
            scheduleAudience(projectId, relationId, audienceId, data),
        {
            mutationKey: ['audience_scheduler'],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('audience_scheduler.successful'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('audience_scheduler.failed'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const scheduleUpdateAudience = async (
    projectId: string,
    relationId: string,
    audienceId: string,
    data: ScheduleOpts,
) =>
    sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}/schedule`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useScheduleUpdateMutation = () => {
    const { t } = useLocale();
    const { projectUuid: projectId = '' } = useParams<{
        projectUuid: string;
    }>();
    const { activeRelationUuid: relationId } = useRelationContext();
    const { showToastSuccess, showToastError } = useNotify();
    return useMutation<
        ApiSqlQueryResults,
        ApiError,
        { audienceId: string; data: ScheduleOpts }
    >(
        ({ audienceId, data }) =>
            scheduleUpdateAudience(projectId, relationId, audienceId, data),
        {
            mutationKey: ['audience_scheduler'],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('audience_scheduler.success'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('audience_scheduler.failed'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const deleteSchedule = async (
    projectId: string,
    relationId: string,
    audienceId: string,
) =>
    sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}/schedule`,
        method: 'DELETE',
        body: undefined,
    });

export const useScheduleDeleteMutation = () => {
    const { t } = useLocale();
    const { projectUuid: projectId = '' } = useParams<{
        projectUuid: string;
    }>();
    const { activeRelationUuid: relationId } = useRelationContext();
    const { showToastSuccess, showToastError } = useNotify();
    return useMutation<ApiSqlQueryResults, ApiError, { audienceId: string }>(
        ({ audienceId }) => deleteSchedule(projectId, relationId, audienceId),
        {
            mutationKey: ['audience_scheduler'],
            onSuccess: async () => {
                showToastSuccess({
                    title: t(
                        'audience_scheduler.schedule_turned_off_successfully',
                    ),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('audience_scheduler.failed_to_turn_off_schedule'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const getAudienceCountByPayload = async (
    projectId: string,
    relationId: string,
    data: AudienceCountPayload,
) =>
    sortmentApi<ApiSqlQueryCountResults>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/count`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useAudienceCountByPayload = ({
    disableToasts = false,
}: { disableToasts?: boolean } = {}) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<
        ApiSqlQueryCountResults,
        ApiError,
        { payload: AudienceCountPayload; relationUuid: string }
    >(
        ({ payload, relationUuid }) =>
            getAudienceCountByPayload(projectUuid, relationUuid, payload),
        {
            mutationKey: [QueryKeys.AUDIENCE_COUNT_PAYLOAD],
            onError: (error) => {
                if (!disableToasts) {
                    showToastError({
                        title: t('use_audience.error_get_audience_count'),
                        subtitle: error.error.message,
                    });
                }
            },
        },
    );
};

const getAiGeneratedAudience = async (projectId: string, data: AgentMessage) =>
    sortmentApi<AgentMessage>({
        url: `/projects/${projectId}/ai/agents`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useAiGeneratedAudience = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useMutation<
        AgentMessage,
        ApiError,
        { payload: AgentMessage; relationUuid: string }
    >(({ payload }) => getAiGeneratedAudience(projectUuid, payload), {
        mutationKey: [QueryKeys.GENERATE_VISUAL_AI_AUDIENCE],
    });
};

const generateAudienceDescriptionWithAi = async (
    projectId: string,
    data: AudienceDescriptionRequest,
) =>
    sortmentApi<EntityNameDescription>({
        url: `/projects/${projectId}/ai/audiences/description`,
        method: 'POST',
        body: JSON.stringify(data),
    });

const getReachability = async (
    projectId: string,
    relationId: string,
    data: AudienceReachabilityPayload,
) =>
    sortmentApi<ApiInsightsCountQueryDto>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/insights/reachability/runQuery`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useGenerateAudienceDescriptionWithAi = ({
    relationUuid,
}: {
    relationUuid: string;
}) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { mutate: updateAudienceDescription } = useAudienceUpdation(true);
    return useMutation<
        EntityNameDescription,
        ApiError,
        { payload: AudienceDescriptionRequest }
    >(
        ({ payload }) =>
            generateAudienceDescriptionWithAi(projectUuid, payload),
        {
            mutationKey: [QueryKeys.GENERATE_AI_AUDIENCE_DESCRIPTION],
            onSuccess: (response) => {
                updateAudienceDescription({
                    payload: {
                        description: response.description,
                        name: response.name,
                    },
                    relationUuid,
                    audienceId:
                        'TODO FIX this, audience will be created after name and description are generated',
                });
            },
        },
    );
};

const generateAudienceNameDescription = async (
    projectId: string,
    data: AudienceDescriptionRequest,
) =>
    sortmentApi<EntityNameDescription>({
        url: `/projects/${projectId}/ai/audiences/name-description`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useGenerateAudienceNameDescription = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useMutation<
        EntityNameDescription,
        ApiError,
        AudienceDescriptionRequest
    >({
        mutationKey: [
            QueryKeys.GENERATE_AUDIENCE_NAME_DESCRIPTION,
            projectUuid,
        ],
        mutationFn: (data) =>
            generateAudienceNameDescription(projectUuid, data),
    });
};

export const useGetReachability = ({ relationId }: { relationId: string }) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();

    return useMutation<
        ApiInsightsCountQueryDto,
        ApiError,
        AudienceReachabilityPayload
    >({
        mutationFn: (payload: AudienceReachabilityPayload) =>
            getReachability(projectUuid, relationId, payload),
        mutationKey: [QueryKeys.AUDIENCE_REACHABILITY],
    });
};

const getAudienceOverlap = async (
    projectId: string,
    relationId: string,
    data: AudienceOverlapPayload,
) =>
    sortmentApi<ApiInsightsCountQueryDto['results']>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/insights/overlap/runQuery`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useGetAudienceOverlap = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { activeRelationUuid: relationId } = useRelationContext();

    return useMutation<
        ApiInsightsCountQueryDto['results'],
        ApiError,
        AudienceOverlapPayload
    >({
        mutationFn: (payload: AudienceOverlapPayload) =>
            getAudienceOverlap(projectUuid, relationId, payload),
        mutationKey: [QueryKeys.AUDIENCE_OVERLAP],
    });
};

const getAudienceBreakdown = async (
    projectId: string,
    relationId: string,
    data: AudienceInsightsBreakdownPayload,
) =>
    sortmentApi<ApiInsightsCountQueryDto['results']>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/insights/breakdown/runQuery`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useGetAudienceBreakdown = () => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const { activeRelationUuid: relationId } = useRelationContext();

    return useMutation<
        ApiInsightsCountQueryDto['results'],
        ApiError,
        AudienceInsightsBreakdownPayload
    >({
        mutationFn: (payload: AudienceInsightsBreakdownPayload) =>
            getAudienceBreakdown(projectUuid, relationId, payload),
        mutationKey: [QueryKeys.AUDIENCE_BREAKDOWN],
    });
};

const exportAudience = async (
    projectId: string,
    relationId: string,
    audienceId: string,
    userUuid: string,
    dimensionFields: string[],
    metricFields: string[],
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}/export`,
        method: 'POST',
        body: JSON.stringify({
            audienceId,
            userUuid,
            dimensionFields,
            metricFields,
        }),
    });

export const useAudienceExportMutation = () => {
    const { t } = useLocale();
    const { projectUuid: projectId } = useParams<{
        projectUuid: string;
    }>();
    if (!projectId) {
        throw new Error(t('common.project_id_required'));
    }
    const { activeRelationUuid: relationId } = useRelationContext();
    const { showToastSuccess, showToastError } = useNotify();
    return useMutation<ApiSuccessEmpty, ApiError, AudienceExportUserPayload>(
        ({ audienceId, userUuid, dimensionFields, metricFields }) =>
            exportAudience(
                projectId,
                relationId,
                audienceId,
                userUuid,
                dimensionFields,
                metricFields,
            ),
        {
            mutationKey: ['audience_export'],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('audience_export.toast_email_success_message'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('audience_export.toast_email_error_message'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const exportIterableAudience = async ({
    projectId,
    userUuid,
    audienceId,
    relationId,
    dimensionFields,
    metricFields,
    userIdField,
    emailField,
    listId,
    name,
    description,
    updateExistingUsersOnly,
}: {
    projectId: string;
    userUuid: string;
    audienceId: string;
    relationId: string;
    dimensionFields: string[];
    metricFields: string[];
    userIdField: string;
    emailField?: string;
    listId?: number;
    name?: string;
    description?: string;
    updateExistingUsersOnly?: boolean;
}) => {
    const payload: AudienceToIterablePayload = {
        userUuid,
        audienceId,
        dimensionFields,
        metricFields,
        userIdField,
        emailField,
        listId,
        name,
        description,
        updateExistingUsersOnly,
    };
    return sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}/iterable/subscribe`,
        method: 'POST',
        body: JSON.stringify(payload),
    });
};

export const useIterableExportMutation = () => {
    const { t } = useLocale();
    const { projectUuid: projectId } = useParams<{
        projectUuid: string;
    }>();
    if (!projectId) {
        throw new Error(t('common.project_id_required'));
    }
    const { activeRelationUuid: relationId } = useRelationContext();
    const { showToastSuccess, showToastError } = useNotify();
    return useMutation<ApiSuccessEmpty, ApiError, AudienceToIterablePayload>(
        ({
            userUuid,
            audienceId,
            dimensionFields,
            metricFields,
            userIdField,
            emailField,
            listId,
            name,
            description,
            updateExistingUsersOnly,
        }) =>
            exportIterableAudience({
                projectId,
                userUuid,
                audienceId,
                relationId,
                dimensionFields,
                metricFields,
                userIdField,
                emailField,
                listId,
                name,
                description,
                updateExistingUsersOnly,
            }),
        {
            mutationKey: [QueryKeys.AUDIENCE_EXPORT_ITERABLE],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('audience_export_iterable.toast_success_message'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('audience_export_iterable.toast_error_message'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

export const getIterableLists = async ({
    projectId,
    relationId,
    audienceId,
}: {
    projectId: string;
    relationId: string;
    audienceId: string;
}) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}/iterable/lists`,
        method: 'GET',
        body: null,
    });

export const useGetIterableLists = (audienceId?: string) => {
    const { t } = useLocale();
    const { projectUuid: projectId } = useParams<{
        projectUuid: string;
    }>();
    if (!projectId) {
        throw new Error(t('common.project_id_required'));
    }
    const { activeRelationUuid: relationId } = useRelationContext();

    return useQuery<ApiResponse, ApiError, IterableListInfo[]>({
        queryKey: [
            QueryKeys.GET_ITERABLE_LISTS,
            projectId,
            relationId,
            audienceId,
        ],
        queryFn: () =>
            getIterableLists({
                projectId,
                relationId,
                audienceId: audienceId || '',
            }),
        enabled: !!projectId && !!relationId && !!audienceId,
        retry: true,
        keepPreviousData: true,
    });
};
