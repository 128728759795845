import PreviewUser from '@components/TestCommunication/PreviewUser';
import { useLocale } from '@hooks/useLocale';
import {
    ContentMappingSection,
    type ApiSqlQueryResults,
    type Template,
} from '@lightdash/common';
import { Box, Drawer, Flex, Grid, Text } from '@mantine/core';
import { Info, X } from '@phosphor-icons/react';
import React, { useMemo, useState } from 'react';
import usePreviewDimensions from '../../../TestCommunication/usePreviewDimensions';
import PersonalizationFilled from '../Personalization/PersonalizationFilled';
import Preview from '../Preview';
import SendTest from '../SendTest';

type ViewAsUserProps = {
    open: boolean;
    onClose: () => void;
    templateMetadata: Template | undefined;
    isSendTestDisabled: boolean;
};
const ViewAsUser = ({
    open,
    onClose,
    templateMetadata,
    isSendTestDisabled,
}: ViewAsUserProps) => {
    const { t } = useLocale();
    const [previewUserData, setPreviewUserData] = useState<
        Record<string, unknown>
    >({});
    const { previewDimensions, getPreviewUserFormattedData } =
        usePreviewDimensions({
            contentMappings: templateMetadata?.contents[0].contentMappings,
        });
    const isPersonalizationComplete = useMemo(() => {
        if (
            templateMetadata?.contents[0].contentMappings?.[
                ContentMappingSection.BODY
            ]
        ) {
            return Object.values(
                templateMetadata?.contents[0].contentMappings?.[
                    ContentMappingSection.BODY
                ],
            ).every((item) => item.defaultValue);
        }
    }, [templateMetadata?.contents]);

    const previewUserCallback = (payload: ApiSqlQueryResults) => {
        const res = getPreviewUserFormattedData(payload);
        setPreviewUserData(res);
    };

    return (
        <Drawer
            opened={open}
            onClose={onClose}
            position="bottom"
            size={'95%'}
            withCloseButton={false}
            padding={0}
            sx={() => ({
                '& .mantine-Drawer-body': {
                    height: '100%',
                },
            })}
        >
            <Box className="h-full">
                <Flex
                    className="border-b-[1px] p-3"
                    justify={'space-between'}
                    align={'center'}
                >
                    <Text className="text-lg text-gray-800 font-medium">
                        {t('template_preview.preview_user_modal.title')}
                    </Text>
                    <Flex align={'center'} gap={24}>
                        {templateMetadata && (
                            <SendTest
                                templateId={templateMetadata.id}
                                channel={templateMetadata.channel}
                                mappings={
                                    templateMetadata.contents[0]
                                        ?.contentMappings
                                }
                                contentDetails={
                                    templateMetadata.contents[0]?.content
                                }
                                disabled={isSendTestDisabled}
                                subject={templateMetadata.contents[0]?.subject}
                            />
                        )}
                        <X weight="regular" onClick={onClose} />
                    </Flex>
                </Flex>
                <Grid className="h-full">
                    <Grid.Col span={6} className="p-6">
                        <Box className="border-b-[1px] border-dashed pb-6">
                            <PreviewUser
                                previewDimensions={previewDimensions}
                                previewUserCallback={previewUserCallback}
                            />
                        </Box>
                        <Box className="mt-6">
                            <Text className="text-gray-700 text-base font-semibold">
                                {t(
                                    'template_preview.personalization_variables',
                                )}
                            </Text>
                            {!isPersonalizationComplete && (
                                <Flex align={'center'} gap={4} className="mt-1">
                                    <Info color="rgb(var(--color-mustard-800))" />
                                    <Text className="text-mustard-900">
                                        {t(
                                            'template_preview.personalization_variables_incomplete',
                                        )}
                                    </Text>
                                </Flex>
                            )}
                            <PersonalizationFilled
                                variables={
                                    templateMetadata?.contents[0]?.variables
                                }
                                mappings={
                                    templateMetadata?.contents[0]
                                        ?.contentMappings
                                }
                            />
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Preview
                            templateMetadata={templateMetadata}
                            handleModifyTemplate={() => {}}
                            grapesId="viewAsUser"
                            previewUserData={previewUserData}
                            showModifyButton={false}
                        />
                    </Grid.Col>
                </Grid>
            </Box>
        </Drawer>
    );
};

export default ViewAsUser;
