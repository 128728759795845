import { useAiAnalystThread } from '@hooks/useAiAnalyst';
import {
    AIMessageContext,
    type AgentJsonContent,
    type AgentMessage,
} from '@lightdash/common';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from 'react';
import { useParams } from 'react-router';
import AiAnalystProviderContext from './context';
import {
    ActionType,
    type AiAnalystContext,
    type AiAnalystReducerState,
} from './types';

// Define the actions for AiAnalyst
type Action =
    | { type: ActionType.SET_SHOW_AI_ANALYST; payload: boolean }
    | { type: ActionType.SET_AI_CHAT_MESSAGES; payload: Array<AgentMessage> }
    | { type: ActionType.SET_THREAD_ID; payload: string }
    | { type: ActionType.SET_AI_CONTEXT; payload: AIMessageContext }
    | { type: ActionType.SET_AI_CHAT_MESSAGES; payload: Array<AgentMessage> }
    | { type: ActionType.ADD_STREAMED_MESSAGE_ID; payload: string }
    | { type: ActionType.SET_USER_FEEDBACK; payload: Record<string, boolean> };

// Define the reducer function
function reducer(
    state: AiAnalystReducerState,
    action: Action,
): AiAnalystReducerState {
    switch (action.type) {
        case ActionType.SET_SHOW_AI_ANALYST: {
            return {
                ...state,
                showAiAnalyst: action.payload,
            };
        }

        case ActionType.SET_USER_FEEDBACK: {
            return {
                ...state,
                userFeedback: {
                    ...state.userFeedback,
                    ...action.payload,
                },
            };
        }

        case ActionType.SET_AI_CHAT_MESSAGES: {
            return {
                ...state,
                aiChatMessages: [...state.aiChatMessages, ...action.payload],
            };
        }

        case ActionType.SET_THREAD_ID: {
            return {
                ...state,
                threadId: action.payload,
                aiChatMessages: [],
                streamedMessageIds: [],
                userFeedback: {},
            };
        }

        case ActionType.SET_AI_CONTEXT: {
            return {
                ...state,
                aiContext: action.payload,
            };
        }

        case ActionType.ADD_STREAMED_MESSAGE_ID: {
            return {
                ...state,
                streamedMessageIds: [
                    ...state.streamedMessageIds,
                    action.payload,
                ],
            };
        }

        default: {
            throw new Error('Unexpected action');
        }
    }
}

// Define the AiAnalystProvider component
const AiAnalystProvider: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { mutateAsync: mutateAsyncCreateAiAnalystThread } =
        useAiAnalystThread();

    const [reducerState, dispatch] = useReducer(reducer, {
        showAiAnalyst: JSON.parse(
            localStorage.getItem('showAiAnalyst') ?? 'false',
        ),
        threadId: '',
        aiChatMessages: [],
        aiContext: AIMessageContext.SCHEMA_SEARCH,
        streamedMessageIds: [],
        userFeedback: {},
    });
    const currentProjectUuid = useRef<string>(null);
    const responseCallback = useRef<((data: AgentJsonContent) => void) | null>(
        null,
    );

    const registerCallback = useCallback(
        (callback: (data: AgentJsonContent) => void) => {
            responseCallback.current = callback;
        },
        [],
    );

    const addStreamedMessageId = useCallback((messageId: string) => {
        dispatch({
            type: ActionType.ADD_STREAMED_MESSAGE_ID,
            payload: messageId,
        });
    }, []);

    const unregisterCallback = useCallback(() => {
        responseCallback.current = null;
    }, []);

    const setAiContext = useCallback((context: AIMessageContext) => {
        dispatch({
            type: ActionType.SET_AI_CONTEXT,
            payload: context,
        });
    }, []);

    const setUserFeedback = useCallback(
        (userFeedback: Record<string, boolean>) => {
            dispatch({
                type: ActionType.SET_USER_FEEDBACK,
                payload: userFeedback,
            });
        },
        [],
    );

    const setShowAiAnalyst = useCallback((showAiAnalyst: boolean) => {
        localStorage.setItem('showAiAnalyst', JSON.stringify(showAiAnalyst));
        dispatch({
            type: ActionType.SET_SHOW_AI_ANALYST,
            payload: showAiAnalyst,
        });
    }, []);

    const setAIChatMessages = useCallback((messages: Array<AgentMessage>) => {
        dispatch({
            type: ActionType.SET_AI_CHAT_MESSAGES,
            payload: messages,
        });
    }, []);

    const setThreadId = useCallback((id: string) => {
        dispatch({
            type: ActionType.SET_THREAD_ID,
            payload: id,
        });
    }, []);

    const onSuccess = useCallback((json: AgentJsonContent) => {
        if (responseCallback.current) {
            responseCallback.current(json);
        }
    }, []);

    const handleCreateThread = useCallback(async () => {
        await mutateAsyncCreateAiAnalystThread().then((res) => {
            setThreadId(res.threadId);
        });
    }, [mutateAsyncCreateAiAnalystThread, setThreadId]);

    useEffect(() => {
        if (
            currentProjectUuid.current !==
                localStorage.getItem('lastProject') &&
            projectUuid
        ) {
            currentProjectUuid.current = localStorage.getItem('lastProject');
            void handleCreateThread();
        }
    }, [handleCreateThread, reducerState.threadId, projectUuid]);

    const state = useMemo(
        () => ({
            ...reducerState,
        }),
        [reducerState],
    );

    const actions = useMemo(
        () => ({
            setShowAiAnalyst,
            registerCallback,
            unregisterCallback,
            onSuccess,
            setAiContext,
            setAIChatMessages,
            handleCreateThread,
            addStreamedMessageId,
            setUserFeedback,
        }),
        [
            setShowAiAnalyst,
            registerCallback,
            unregisterCallback,
            onSuccess,
            setAiContext,
            setAIChatMessages,
            handleCreateThread,
            addStreamedMessageId,
            setUserFeedback,
        ],
    );

    const value: AiAnalystContext = useMemo(
        () => ({
            state,
            actions,
        }),
        [state, actions],
    );

    return (
        <AiAnalystProviderContext.Provider value={value}>
            {children}
        </AiAnalystProviderContext.Provider>
    );
};

export default AiAnalystProvider;
