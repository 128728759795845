import { subject } from '@casl/ability';
import { useFieldsWithEvents } from '@components/Audience/Filters/FiltersCard/useFieldsWithEvents';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider/FiltersProvider';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import CalculatedTraitBaseModal from '@components/CustomAttribute/CalculatedTraitBaseModal';
import CustomMetricManagerContent from '@components/CustomAttribute/CustomMetricManagerContent';
import Page from '@components/Page/Page';
import { isProjectSetUpCompleted } from '@components/ProjectConnection/utils';
import SetupPage from '@components/SetupPage';
import UpdateTraitsModal from '@components/UpdateTraits/UpdateTraitsModal';
import { useGetCustomMetrics } from '@hooks/useCustomMetric';
import { useLocale } from '@hooks/useLocale';
import { useUpdateTraits } from '@hooks/useUpdateTrait';
import { CommonReservedTags } from '@lightdash/common';
import { Button, Flex, Group, Menu, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    AsteriskSimple,
    Function,
    PlusCircle,
    Textbox,
} from '@phosphor-icons/react';
import useApp from '@providers/App/useApp';
import useProjectContext from '@providers/Project/useProjectContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import React, { useEffect, useMemo, useState } from 'react';
import { AlertCircle } from 'react-feather';
import { useParams } from 'react-router';
import { ButtonVariant } from '../mantineTheme';

interface CreateCustomMetricMenuProps {
    setOpenBaseTable: (value: boolean) => void;
    openUpdateTraitsModal: () => void;
}

export const CreateCustomMetricMenu: React.FC<CreateCustomMetricMenuProps> = ({
    setOpenBaseTable,
    openUpdateTraitsModal,
}) => {
    const { t } = useLocale();
    const [opened, setOpened] = useState(false);

    return (
        <Menu opened={opened} onChange={setOpened}>
            <Menu.Target>
                <Button
                    className="m-3.5"
                    variant={ButtonVariant.PRIMARY}
                    leftIcon={<PlusCircle color="white" />}
                    size="md"
                >
                    <Text className="inline text-sm font-semibold">
                        {t('custom_metric.create_custom_metric')}
                    </Text>
                </Button>
            </Menu.Target>
            <Menu.Dropdown>
                <Group className="flex flex-col">
                    <Button
                        variant={ButtonVariant.DEFAULT}
                        className="min-w-full border-none"
                        leftIcon={<Function size={13} />}
                        styles={{
                            label: {
                                justifyContent: 'left',
                            },
                        }}
                        onClick={() => {
                            setOpenBaseTable(true);
                            setOpened(false);
                        }}
                    >
                        {t('custom_metric.create_new_trait')}
                    </Button>
                    <Button
                        variant={ButtonVariant.DEFAULT}
                        leftIcon={<Textbox weight="duotone" />}
                        className="border-none"
                        onClick={() => {
                            openUpdateTraitsModal();
                            setOpened(false);
                        }}
                    >
                        {t('update_traits.create_trait')}
                    </Button>
                </Group>
            </Menu.Dropdown>
        </Menu>
    );
};

const CustomMetricBuilderContainer: React.FC<{}> = () => {
    const { t } = useLocale();
    const { data: allCustomMetrics, isLoading: isCustomMetricsLoading } =
        useGetCustomMetrics({
            query: '',
        });
    const [isHiddenActive, setIsHiddenActive] = useState<boolean>(false);
    const [openBaseTable, setOpenBaseTable] = useState<boolean>(false);
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const ability = useAbilityContext();
    const { projectData: activeProject } = useProjectContext();
    const { user } = useApp();
    const canCreateCustomMetricAbility = ability.can(
        'create',
        subject('CustomMetric', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const [
        openedUpdateTraitsModal,
        { open: openUpdateTraitsModal, close: closeUpdateTraitsModal },
    ] = useDisclosure(false);

    const { data: updateTraits, isLoading: isUpdateTraitsLoading } =
        useUpdateTraits(projectUuid ?? '');
    const hiddenCustomMetrics = useMemo(() => {
        return allCustomMetrics?.filter((metric) =>
            metric.tags?.includes(CommonReservedTags.HIDDEN),
        );
    }, [allCustomMetrics]);
    const customMetrics = useMemo(() => {
        return allCustomMetrics?.filter(
            (metric) => !metric.tags?.includes(CommonReservedTags.HIDDEN),
        );
    }, [allCustomMetrics]);
    useEffect(() => {
        if ((hiddenCustomMetrics?.length ?? 0) === 0) {
            setIsHiddenActive(false);
        }
    }, [hiddenCustomMetrics]);
    const hasMetrics = useMemo(
        () =>
            (customMetrics?.length ?? 0) > 0 ||
            (hiddenCustomMetrics?.length ?? 0) > 0 ||
            (Object.keys(updateTraits?.config.fieldConfig ?? {}).length ?? 0) >
                0,
        [customMetrics, hiddenCustomMetrics, updateTraits],
    );
    if (activeProject && !isProjectSetUpCompleted(activeProject)) {
        return (
            <SetupPage
                description={t('set_up_page.description', {
                    type_description: t(
                        'set_up_page.type_description.custom_properties',
                    ),
                })}
            />
        );
    }

    if (isCustomMetricsLoading) {
        if (!canCreateCustomMetricAbility)
            return (
                <Flex
                    justify="start"
                    gap="xs"
                    className="p-2 m-2 rounded-lg border border-shade-6 mx-3"
                >
                    <Flex justify="center" align="center">
                        <AlertCircle
                            size={13}
                            strokeWidth={2.5}
                            color={'rgb(var(--color-gray-600))'}
                        />
                    </Flex>
                    <Flex direction="column">
                        <Text className="block text-gray-800">
                            {t('custom_metric.cannot_create_message')}
                        </Text>
                    </Flex>
                </Flex>
            );

        return <SuboptimalState loading />;
    }
    if (isUpdateTraitsLoading) {
        return <SuboptimalState loading />;
    }
    return (
        <ManagerBuilderContainer
            withContentPadding={false}
            title={
                <Flex justify="start" className="gap-1.5">
                    <Flex align="center">
                        <AsteriskSimple
                            color={'rgb(var(--color-purple))'}
                            size={20}
                        />
                    </Flex>
                    <Text>{t('custom_metric')}</Text>
                </Flex>
            }
            rightSection={
                canCreateCustomMetricAbility && hasMetrics ? (
                    <CreateCustomMetricMenu
                        setOpenBaseTable={setOpenBaseTable}
                        openUpdateTraitsModal={openUpdateTraitsModal}
                    />
                ) : null
            }
        >
            {hasMetrics ? (
                <CustomMetricManagerContent
                    openBaseTable={openBaseTable}
                    setOpenBaseTable={setOpenBaseTable}
                    updateTraits={updateTraits}
                    openUpdateTraitsModal={openUpdateTraitsModal}
                    customMetrics={customMetrics}
                    isHiddenActive={isHiddenActive}
                    onHiddenChange={setIsHiddenActive}
                    hiddenCustomMetrics={hiddenCustomMetrics}
                />
            ) : canCreateCustomMetricAbility ? (
                <CreateCustomMetricMenu
                    setOpenBaseTable={setOpenBaseTable}
                    openUpdateTraitsModal={openUpdateTraitsModal}
                />
            ) : null}
            <UpdateTraitsModal
                opened={openedUpdateTraitsModal}
                onClose={closeUpdateTraitsModal}
                items={undefined}
                updateTrait={updateTraits}
                isEditMode={true}
                updateTraitFieldConfig={undefined}
            />
            <CalculatedTraitBaseModal
                onClose={() => {
                    setOpenBaseTable(false);
                }}
                opened={openBaseTable}
            />
        </ManagerBuilderContainer>
    );
};
const CustomMetricManager: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const { projectData } = useProjectContext();

    const { data: eventsData, eventsTableNames } = useFieldsWithEvents({
        relationData: activeRelation,
        activeRelationUuid,
        projectUuid: projectUuid ?? '',
    });
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });

    return (
        <FiltersProvider
            projectUuid={projectUuid}
            fieldsMap={fieldsWithSuggestions}
            startOfWeek={
                projectData?.warehouseConnection?.startOfWeek ?? undefined
            }
            eventsMap={eventsData}
            eventTables={eventsTableNames}
        >
            <Page
                title={t('custom_metric')}
                withFullHeight
                withNavbar
                withFixedContent
                withPaddedContent
            >
                <CustomMetricBuilderContainer />
            </Page>
        </FiltersProvider>
    );
};
export default React.memo(CustomMetricManager);
