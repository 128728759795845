import { PropertySelectGroupEnum } from '@components/Audience/Filters/FieldListItem/utils';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import { type ContentStepComponentProps } from '@components/Campaigns/Builder/types';
import {
    getNextContentStep,
    getPreviousContentStep,
} from '@components/Campaigns/Builder/utils';
import { extractSubjectVariables } from '@components/Campaigns/utils';
import FieldSelect from '@components/common/Select/FieldSelect';
import Variables from '@components/Templates/Variables';
import { useUpdateCampaign } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceType,
    CampaignFieldType,
    CommunicationChannel,
    ContentMappingSection,
    FieldType,
    getItemId,
    isCustomDimension,
    isTableCalculation,
    JoinType,
    WhatsappHeaderVariable,
    type AnyType,
    type CampaignUpdateRequest,
    type ContentMapping,
    type EmailTemplateContentDetails,
    type ExternalTemplate,
    type TemplateVariableDetails,
    type Variable,
} from '@lightdash/common';
import { Box, Stack, Text } from '@mantine/core';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import useProjectContext from '@providers/Project/useProjectContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { filterTablesFromRelation } from '@utils/relation';
import { isEmpty, isEqual } from 'lodash';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useParams } from 'react-router';
import { getFieldIdFromReachability } from '../../../utils';
import SubscriptionGroupFilter, {
    type Option,
} from './SubscriptionGroupFilter';
import { LabelIconType } from './SubscriptionGroupFilter/types';

const SendToFieldType: Record<CommunicationChannel, string> = {
    [CommunicationChannel.EMAIL]: 'an email',
    [CommunicationChannel.SMS]: 'a phone number',
    [CommunicationChannel.WHATSAPP]: 'a phone number',
    [CommunicationChannel.SLACK]: 'a slack account',
    [CommunicationChannel.UNKNOWN]: '',
    [CommunicationChannel.ANY]: '',
};

const PersonaliseContent: React.FC<ContentStepComponentProps> = ({
    setActiveContentStep,
    activeContentStep,
    templateMetadata,
    fields,
    additionalPropertySelectList,
}) => {
    const initialData = useRef<AnyType>(null);
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const { t } = useLocale();
    const { getTableRelation, activeRelation, customDimensionsFields } =
        useRelationContext();
    const {
        setCurrentStepCallback,
        setPreviousStepCallback,
        setSendToVariableMapping,
        setContentMappings,
        setContentDetails,
    } = useCampaignContext((context) => context.actions);
    const [errors, setErrors] = useState<AnyType>({
        sendTo: '',
    });

    const { campaignPayload, isEditMode } = useCampaignContext(
        (context) => context.state,
    );
    const { communicationDetails, templateDetails } = campaignPayload;
    const { mutate: updateCampaign } = useUpdateCampaign(campaignUuid);

    useEffect(() => {
        if (initialData.current === null) {
            initialData.current = campaignPayload;
        }
    }, [campaignPayload]);
    const { projectData } = useProjectContext();

    const isValidSendToVariable = useMemo(() => {
        if (!isEmpty(campaignPayload.sendTo)) {
            return true;
        }
        return false;
    }, [campaignPayload.sendTo]);

    const variablesContent = useMemo((): TemplateVariableDetails => {
        const content = campaignPayload.contentDetails;
        const variableContent: TemplateVariableDetails = {
            [ContentMappingSection.HEADER]: undefined,
            [ContentMappingSection.BODY]: undefined,
            [ContentMappingSection.BUTTONS]: undefined,
            [ContentMappingSection.SUBJECT]: undefined,
        };
        if (content && 'subject' in content) {
            variableContent[ContentMappingSection.SUBJECT] =
                extractSubjectVariables(content.subject ?? '');
        }
        if (templateMetadata) {
            variableContent[ContentMappingSection.HEADER] =
                campaignPayload.channel === CommunicationChannel.WHATSAPP
                    ? (templateMetadata as any)?.variables?.[
                          ContentMappingSection.HEADER
                      ]
                    : (templateMetadata as EmailTemplateContentDetails)
                          ?.variables?.[ContentMappingSection.HEADER];
            variableContent[ContentMappingSection.BODY] =
                campaignPayload.channel === CommunicationChannel.WHATSAPP
                    ? (templateMetadata as any)?.variables?.[
                          ContentMappingSection.BODY
                      ]
                    : (templateMetadata as EmailTemplateContentDetails)
                          ?.variables?.[ContentMappingSection.BODY];
        }
        if (campaignPayload.channel === CommunicationChannel.WHATSAPP) {
            variableContent[ContentMappingSection.BUTTONS] = (
                templateMetadata as any
            )?.variables?.[ContentMappingSection.BUTTONS];
        }

        if (
            campaignPayload?.channel === CommunicationChannel.WHATSAPP &&
            (templateMetadata as any)?.contents.header?.type &&
            (templateMetadata as any)?.contents.header?.type !== 'text'
        ) {
            if (variableContent?.[ContentMappingSection.HEADER]) {
                variableContent[ContentMappingSection.HEADER] = [
                    ...variableContent[ContentMappingSection.HEADER],
                    WhatsappHeaderVariable,
                ];
            } else {
                variableContent[ContentMappingSection.HEADER] = [
                    WhatsappHeaderVariable,
                ];
            }
        }

        return variableContent;
    }, [
        campaignPayload.channel,
        campaignPayload.contentDetails,
        templateMetadata,
    ]);

    useEffect(() => {
        if (campaignPayload.contentDetails) {
            return;
        }
        if (
            templateMetadata &&
            campaignPayload.channel === CommunicationChannel.WHATSAPP
        ) {
            const whatsappTemplateContent = (
                templateMetadata as ExternalTemplate
            )?.languages?.[0]?.content;
            if (whatsappTemplateContent) {
                setContentDetails(whatsappTemplateContent);
            }
        }
    }, [
        campaignPayload.channel,
        campaignPayload.contentDetails,
        campaignUuid,
        setContentDetails,
        templateMetadata,
        updateCampaign,
    ]);

    useEffect(() => {
        const nextStep = getNextContentStep(
            activeContentStep,
            campaignPayload?.channel,
            templateMetadata,
        );
        const handleNextStep = () => {
            if (!isValidSendToVariable) {
                setErrors((oldValues: any) => ({
                    ...oldValues,
                    sendTo: 'Please select a field',
                }));
                return;
            }

            if (campaignPayload.contentMappings) {
                let isError = false;
                Object.entries(variablesContent).forEach(
                    ([groupName, variables]) => {
                        variables?.forEach((variable) => {
                            const variablesGroup =
                                campaignPayload.contentMappings?.[
                                    groupName as ContentMappingSection
                                ];
                            if (
                                (!variablesGroup?.[variable]?.value &&
                                    campaignPayload.audienceType ===
                                        AudienceType.CSV) ||
                                (!variablesGroup?.[variable]?.defaultValue &&
                                    campaignPayload.audienceType ===
                                        AudienceType.WAREHOUSE)
                            ) {
                                isError = true;
                                setErrors((oldValues: any) => ({
                                    ...oldValues,
                                    [groupName]: {
                                        ...(typeof oldValues[groupName] ===
                                        'object'
                                            ? oldValues[groupName]
                                            : {}),
                                        [variable]:
                                            campaignPayload.audienceType ===
                                            AudienceType.CSV
                                                ? 'Please select a value'
                                                : 'Please enter a default value',
                                    },
                                }));
                            }
                        });
                    },
                );
                if (isError) {
                    return;
                }
            }
            const payload: CampaignUpdateRequest = {
                sendTo: campaignPayload.sendTo,
                contentMappings: campaignPayload.contentMappings,
            };
            if (
                !isEqual(
                    {
                        sendTo: initialData.current.sendTo,
                        contentMappings: initialData.current.contentMappings,
                    },
                    payload,
                ) &&
                campaignUuid
            ) {
                updateCampaign(payload);
            }

            if (nextStep) {
                setActiveContentStep(nextStep, undefined);
            }
        };

        setCurrentStepCallback({
            callback: handleNextStep,
            skipExecutionAfterCallback: Boolean(nextStep),
        });
    }, [
        activeContentStep,
        isValidSendToVariable,
        setActiveContentStep,
        setCurrentStepCallback,
        communicationDetails,
        templateDetails,
        templateMetadata,
        variablesContent,
        campaignPayload?.channel,
        campaignPayload.contentMappings,
        campaignPayload.sendTo,
        updateCampaign,
        campaignUuid,
        setContentDetails,
        campaignPayload.audienceType,
    ]);

    useEffect(() => {
        const prevStep = getPreviousContentStep(
            activeContentStep,
            campaignPayload?.channel,
            templateMetadata,
        );

        const handlePrevStep = () => {
            if (prevStep) {
                setActiveContentStep(prevStep, undefined);
            }
            return;
        };

        setPreviousStepCallback({
            callback: handlePrevStep,
            skipExecutionAfterCallback: Boolean(prevStep),
        });
    }, [
        activeContentStep,
        campaignPayload?.channel,
        setActiveContentStep,
        setPreviousStepCallback,
        templateMetadata,
    ]);

    const campaignRelationData = useMemo(() => {
        if (activeRelation) {
            const allowedRelationTables = getTableRelation([
                JoinType.one_one,
                JoinType.many_one,
            ]);
            if (!allowedRelationTables) return;
            const tableIds = allowedRelationTables.map((table) => table.name);
            const filteredRelation = filterTablesFromRelation(
                activeRelation,
                tableIds,
            );
            if (!filteredRelation) return;
            return filteredRelation;
        }
    }, [activeRelation, getTableRelation]);

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: campaignRelationData,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });

    const varibleFields = useMemo(() => {
        if (fields) return fields;

        // Convert customDimensionsFields to have the same shape as FieldWithSuggestions
        const convertedCustomDimensions = Object.entries(
            customDimensionsFields,
        ).reduce(
            (acc, [key, field]) => ({
                ...acc,
                [key]: {
                    ...field,
                    uniqueIdentifier: key,
                },
            }),
            {},
        );

        return {
            ...fieldsWithSuggestions,
            ...convertedCustomDimensions,
        };
    }, [fields, fieldsWithSuggestions, customDimensionsFields]);

    const suggestedFields = useMemo(() => {
        if (!campaignPayload.channel) return [];

        const reachability =
            projectData?.attributes?.reachability?.[campaignPayload.channel];
        if (!reachability) return [];

        // Extract primary and secondary field IDs
        const primaryFieldId = getFieldIdFromReachability(reachability.primary);

        const secondaryFieldIds =
            reachability.secondary?.map(getFieldIdFromReachability) || [];

        // Combine and map to suggested fields
        return [primaryFieldId, ...secondaryFieldIds].map((fieldId) => ({
            ...varibleFields?.[fieldId],
            uniqueIdentifier: fieldId,
            subGroupKey: PropertySelectGroupEnum.SUGGESTIONS,
        }));
    }, [
        varibleFields,
        campaignPayload.channel,
        projectData?.attributes?.reachability,
    ]);

    const fieldWithSuggestionInArray = useMemo(() => {
        if (
            campaignPayload.audienceType === AudienceType.CSV &&
            campaignPayload.csvUploadDetails?.columns?.length
        ) {
            return campaignPayload.csvUploadDetails?.columns.map((item) => ({
                label: item,
                value: item,
                table: campaignPayload.csvUploadDetails?.fileName,
                tableLabel: campaignPayload.csvUploadDetails?.fileName,
                uniqueIdentifier: item,
            }));
        }
        return Object.keys(varibleFields)?.map((fieldKey: string) => {
            return {
                ...varibleFields?.[fieldKey],
                uniqueIdentifier:
                    varibleFields?.[fieldKey].uniqueIdentifier ?? fieldKey,
            };
        });
    }, [campaignPayload, varibleFields]);

    const groupSelectMenuItems: Option[] = useMemo(() => {
        if (
            campaignPayload.audienceType === AudienceType.CSV &&
            campaignPayload.csvUploadDetails?.columns?.length
        ) {
            return campaignPayload.csvUploadDetails?.columns.map((item) => ({
                leftLabel: campaignPayload.csvUploadDetails?.fileName,
                rightLabel: item,
                leftLabelIcon: LabelIconType.CSV,
                rightLabelIcon: undefined,
                value: item,
                divider: false,
            }));
        }
        return Object.keys(fieldsWithSuggestions)?.map((fieldKey: string) => {
            return {
                leftLabel: fieldsWithSuggestions?.[fieldKey].label,
                rightLabel: fieldsWithSuggestions?.[fieldKey].name,
                leftLabelIcon: LabelIconType.CSV,
                rightLabelIcon: undefined,
                value: fieldKey,
                divider: false,
            };
        });
        // return []
    }, [campaignPayload, fieldsWithSuggestions]);

    const handleSendToChange = (item: FieldWithSuggestions) => {
        if (!item || !item.uniqueIdentifier) return;
        setSendToVariableMapping({
            fieldKey: item.uniqueIdentifier,
            fieldType:
                isCustomDimension(item) || isTableCalculation(item)
                    ? FieldType.DIMENSION
                    : item.fieldType,
        });
    };

    const sendToVariableField: FieldWithSuggestions | undefined =
        useMemo(() => {
            const channel = campaignPayload.channel;
            if (!channel) return;
            const sendToValues = campaignPayload.sendTo?.[channel];
            if (
                campaignPayload.audienceType !== AudienceType.CSV &&
                (!campaignPayload.sendTo || !sendToValues)
            ) {
                const primaryFieldObject =
                    projectData?.attributes?.reachability?.[channel]?.primary;
                if (!primaryFieldObject) {
                    return;
                }
                const primaryFieldId =
                    primaryFieldObject.tableName +
                    '_' +
                    primaryFieldObject.dimensionName;
                const primaryField = fieldsWithSuggestions[primaryFieldId];
                if (!primaryField) {
                    return;
                }
                setSendToVariableMapping({
                    fieldKey: getItemId(primaryField),
                    fieldType:
                        isCustomDimension(primaryField) ||
                        isTableCalculation(primaryField)
                            ? FieldType.DIMENSION
                            : primaryField.fieldType,
                });
                return primaryField;
            }
            if (!sendToValues) {
                return;
            }

            setErrors((oldValues: any) => ({
                ...oldValues,
                sendTo: '',
            }));

            let sendToField = varibleFields[sendToValues];
            if (!sendToField && additionalPropertySelectList) {
                const journeyField = additionalPropertySelectList
                    .flatMap((item) => item.items)
                    .find(
                        (subItem) => subItem.uniqueIdentifier === sendToValues,
                    );
                return journeyField;
            }

            return sendToField;
        }, [
            additionalPropertySelectList,
            campaignPayload,
            varibleFields,
            projectData?.attributes?.reachability,
            setSendToVariableMapping,
            fieldsWithSuggestions,
        ]);

    const handleVariableChange = useCallback(
        (
            value: FieldWithSuggestions | undefined,
            key: string,
            groupName: ContentMappingSection,
            defaultValue?: string,
        ) => {
            if (!value || !value?.uniqueIdentifier) return;
            if (errors?.[groupName]?.[key]) {
                setErrors({
                    ...errors,
                    [groupName]: {
                        ...errors[groupName],
                        [key]: '',
                    },
                });
            }

            if (
                campaignPayload.contentMappings &&
                campaignPayload.contentMappings[groupName]
            ) {
                const oldValue = campaignPayload.contentMappings[
                    groupName
                ] as ContentMapping;
                const selectedVariable: Variable = {
                    value: value?.uniqueIdentifier,
                    defaultValue:
                        defaultValue || oldValue?.[key]?.defaultValue || '',
                    type: value?.fieldType || FieldType.DIMENSION,
                };
                const newValue = {
                    ...oldValue[key],
                    ...selectedVariable,
                };
                setContentMappings({
                    ...campaignPayload.contentMappings,
                    [groupName]: {
                        ...oldValue,
                        [key]: {
                            ...oldValue[key],
                            ...newValue,
                        },
                    },
                });
            } else {
                const selectedVariable: Variable = {
                    value: value?.uniqueIdentifier,
                    defaultValue: defaultValue || '',
                    type: value?.fieldType || FieldType.DIMENSION,
                };
                setContentMappings({
                    ...campaignPayload.contentMappings,
                    [groupName]: {
                        [key]: {
                            ...selectedVariable,
                        },
                    },
                });
            }
        },
        [setContentMappings, campaignPayload.contentMappings, errors],
    );

    const handleVariableDefaultValue = useCallback(
        (value: string, key: string, groupName: ContentMappingSection) => {
            const selectedVariable: Variable = {
                value: '',
                defaultValue: value,
                type: CampaignFieldType.STATIC,
            };
            if (
                campaignPayload.contentMappings &&
                campaignPayload.contentMappings[groupName]
            ) {
                const oldValue = campaignPayload.contentMappings[
                    groupName
                ] as ContentMapping;
                if (oldValue?.[key]?.value) {
                    selectedVariable.value = oldValue[key].value;
                }
                if (oldValue?.[key]?.type) {
                    selectedVariable.type = oldValue[key].type;
                }
                const newValue = {
                    ...oldValue,
                    [key]: {
                        ...oldValue[key],
                        ...selectedVariable,
                    },
                };
                setContentMappings({
                    ...campaignPayload.contentMappings,
                    [groupName]: {
                        ...oldValue,
                        ...newValue,
                    },
                });
            } else {
                setContentMappings({
                    ...campaignPayload.contentMappings,
                    [groupName]: {
                        [key]: {
                            ...selectedVariable,
                        },
                    },
                });
            }
            if (errors?.[groupName]?.[key]) {
                setErrors({
                    ...errors,
                    [groupName]: {
                        ...errors[groupName],
                        [key]: '',
                    },
                });
            }
        },
        [campaignPayload.contentMappings, errors, setContentMappings],
    );
    const additionalPropertySelectListItems = useMemo(() => {
        if (suggestedFields.length <= 0) {
            return additionalPropertySelectList;
        }
        return [
            ...(additionalPropertySelectList ?? []),
            {
                items: suggestedFields,
                groupKey: PropertySelectGroupEnum.SUGGESTIONS,
                groupLabel: t('campaign.create.step2.suggestions'),
                groupIcon: null,
            },
        ];
    }, [suggestedFields, additionalPropertySelectList, t]);

    return (
        <Box>
            <Stack className="gap-6 pb-12">
                {/* Send To */}
                {templateMetadata && (
                    <Stack className="gap-1.5">
                        {campaignPayload.audienceType ===
                            AudienceType.WAREHOUSE &&
                            fieldWithSuggestionInArray?.length > 0 && (
                                <>
                                    <Text className="text-sm font-medium text-gray-800">
                                        {t('campaign.create.step2_send_to')}
                                    </Text>
                                    <FieldSelect
                                        size="xs"
                                        item={sendToVariableField}
                                        items={
                                            fieldWithSuggestionInArray as FieldWithSuggestions[]
                                        }
                                        onChange={handleSendToChange}
                                        placeholder={t(
                                            'campaign.create.step2.send_to_placeholder',
                                            {
                                                fieldName:
                                                    SendToFieldType[
                                                        campaignPayload?.channel ??
                                                            CommunicationChannel.EMAIL
                                                    ],
                                            },
                                        )}
                                        isDisabled={!isEditMode}
                                        error={errors.sendTo}
                                        additionalPropertySelectList={
                                            additionalPropertySelectListItems
                                        }
                                    />
                                </>
                            )}
                        {campaignPayload.audienceType === AudienceType.CSV &&
                            campaignPayload.csvUploadDetails?.columns
                                ?.length && (
                                <SubscriptionGroupFilter
                                    value={
                                        campaignPayload.channel &&
                                        campaignPayload.sendTo?.[
                                            campaignPayload.channel
                                        ]
                                    }
                                    onChange={(value) => {
                                        setSendToVariableMapping({
                                            fieldKey: value,
                                            fieldType: FieldType.DIMENSION,
                                        });
                                    }}
                                    error={errors.sendTo}
                                    anchorClass="!w-[20rem]"
                                    width="25rem"
                                    label={t('campaign.create.step2_send_to')}
                                    options={groupSelectMenuItems}
                                />
                            )}
                    </Stack>
                )}
                <Box>
                    <Variables
                        dimensions={
                            fieldWithSuggestionInArray as FieldWithSuggestions[]
                        }
                        variablesContent={variablesContent}
                        isDisabled={!isEditMode}
                        errors={errors}
                        onVariableChange={handleVariableChange}
                        onDefaultValueChange={handleVariableDefaultValue}
                        options={
                            campaignPayload.audienceType === AudienceType.CSV
                                ? groupSelectMenuItems
                                : undefined
                        }
                        templateMetadata={templateMetadata}
                        additionalPropertySelectList={
                            additionalPropertySelectList
                        }
                        setErrors={setErrors}
                    />
                </Box>
            </Stack>
        </Box>
    );
};

export default PersonaliseContent;
