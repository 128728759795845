import TextArea from '@components/common/Inputs/TextArea';
import { useLocale } from '@hooks/useLocale';
import { Stack } from '@mantine/core';
interface AIModeInTraitModalProps {
    aiThinking: boolean;
    setInstructions: (value: string) => void;
    instructions: string;
    exampleInstructions: string;
    setExampleInstructions: (value: string) => void;
}

const AIModeInTraitModal: React.FC<AIModeInTraitModalProps> = ({
    aiThinking,
    setInstructions,
    instructions,
    exampleInstructions,
    setExampleInstructions,
}) => {
    const { t } = useLocale();
    return (
        <Stack spacing={12}>
            <TextArea
                placeholder={t('common.type_here')}
                label={t('custom_metric.create_new_trait_modal.use_case_desc')}
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
                readOnly={aiThinking}
                autoFocus={true}
            />
            <TextArea
                placeholder={t('common.type_here')}
                label={t(
                    'custom_metric.create_new_trait_modal.outline_instructions',
                )}
                value={exampleInstructions}
                onChange={(e) => setExampleInstructions(e.target.value)}
                readOnly={aiThinking}
                isOptional={true}
            />
        </Stack>
    );
};

export default AIModeInTraitModal;
