import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { Box, Text } from '@mantine/core';
import { type ColumnDef, type Getter } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useRelationContext from '../../../providers/Relation/useRelationContext';

const AUDIENCE_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 min-w-fit !w-52`;
const AUDIENCE_TABLE_CELL_OBJECT = `text-sm font-medium text-gray-800 truncate !w-52`;

export function useProfileColumns(fields: string[]) {
    const [columns, setColumns] = useState<
        ColumnDef<Record<string, unknown>>[]
    >([]);
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { activeRelation, customDimensionsFields } = useRelationContext();

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });

    useEffect(() => {
        if (projectUuid && fields) {
            if (!fieldsWithSuggestions || isEmpty(fieldsWithSuggestions))
                return;
            const newColumns = fields.map((item) => {
                const field =
                    fieldsWithSuggestions[item] || customDimensionsFields[item];
                return {
                    accessorKey: item,
                    header: field?.label ?? field?.name ?? item,
                    enableSorting: false,
                    cell: ({ getValue }: { getValue: Getter<unknown> }) => {
                        const value = getValue();
                        if (typeof value === 'object') {
                            return (
                                <Box>
                                    <Text
                                        className={AUDIENCE_TABLE_CELL_OBJECT}
                                    >
                                        {JSON.stringify(value)}
                                    </Text>
                                </Box>
                            );
                        } else if (typeof value === 'string') {
                            return (
                                <Box>
                                    <Text className={AUDIENCE_TABLE_CELL_NAME}>
                                        {value}
                                    </Text>
                                </Box>
                            );
                        } else {
                            return (
                                <Box>
                                    <Text className={AUDIENCE_TABLE_CELL_NAME}>
                                        {JSON.stringify(value)}
                                    </Text>
                                </Box>
                            );
                        }
                    },
                    size: 300,
                };
            });
            setColumns(newColumns);
        }
    }, [projectUuid, fields, fieldsWithSuggestions, customDimensionsFields]);

    return { columns, setColumns };
}
