import StepTitle from '@components/Campaigns/Builder/Steps/StepTitle';
import { CampaignBuilderStep } from '@components/Campaigns/Builder/types';
import { CampaignBuilderSteps } from '@components/Campaigns/Builder/utils';
import {
    useGetAudienceEstimatedCount,
    useUpdateCampaign,
} from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceType,
    CommunicationChannel,
    type AnyType,
    type CampaignAudienceConfig,
} from '@lightdash/common';
import { Box, Flex, Grid, Stack, Text } from '@mantine/core';
import { PaperPlaneTilt, ProhibitInset } from '@phosphor-icons/react';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import AudienceTable from './AudienceTable';
import CountSection from './CountSection';
import SelectedAudienceList from './SelectedAudienceList';
import UploadAudienceDetails from './UploadAudienceDetails';

const CampaignBuilderAudience: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const gridContainerRef = useRef<HTMLDivElement>(null);
    const initialData = useRef<AnyType>(null);
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const { state } = useCampaignContext((context) => context);
    const { campaignPayload } = state;
    const { setCurrentStepCallback, setShowFooterButtons, setAudienceCount } =
        useCampaignContext((context) => context.actions);
    const { mutate: updateCampaign } = useUpdateCampaign(
        campaignUuid ?? '',
        false,
    );

    const { mutateAsync: estimateCount } = useGetAudienceEstimatedCount();

    useEffect(() => {
        if (initialData.current === null) {
            initialData.current = campaignPayload;
        }
    }, [campaignPayload]);

    const handleNextStep = useCallback(() => {
        if (campaignPayload.audienceType === AudienceType.WAREHOUSE) {
            updateCampaign({
                audienceType: campaignPayload.audienceType,
                audienceConfig: campaignPayload.audienceConfig,
            });
        } else if (campaignPayload.audienceType === AudienceType.CSV) {
            updateCampaign({
                audienceType: campaignPayload.audienceType,
                csvUploadDetails: campaignPayload.csvUploadDetails,
            });
        }
        // INFO - If the user has not explicitly clicked on estimate count we are fetching it in background on next click and
        // saving it for showing it later on
        if (
            campaignPayload.audienceConfig &&
            campaignPayload.channel &&
            campaignPayload.audienceType === AudienceType.WAREHOUSE
        ) {
            if (!campaignPayload.audienceConfig.totalCount) {
                estimateCount({
                    channel: campaignPayload.channel,
                    campaignAudienceConfig: campaignPayload.audienceConfig,
                })
                    .then((response) => {
                        setAudienceCount(response as unknown as number);
                        if (campaignPayload.audienceConfig?.config) {
                            const payload: CampaignAudienceConfig = {
                                totalCount: response as unknown as number,
                                config: campaignPayload.audienceConfig.config,
                            };
                            updateCampaign({
                                audienceConfig: payload,
                            });
                        }
                    })
                    .catch(() => {});
            }
        }
    }, [
        campaignPayload.audienceConfig,
        campaignPayload.audienceType,
        campaignPayload.channel,
        campaignPayload.csvUploadDetails,
        estimateCount,
        setAudienceCount,
        updateCampaign,
    ]);

    useEffect(() => {
        setCurrentStepCallback({
            callback: handleNextStep,
            skipExecutionAfterCallback: false,
        });
    }, [handleNextStep, setCurrentStepCallback]);

    useEffect(() => {
        setShowFooterButtons({
            next:
                campaignPayload.audienceType === AudienceType.WAREHOUSE
                    ? !!campaignPayload.audienceConfig?.config?.in?.length
                    : !!campaignPayload.csvUploadDetails?.blobPath,
            back: true,
        });
    }, [
        campaignPayload.audienceConfig?.config?.in?.length,
        campaignPayload.csvUploadDetails,
        campaignPayload.audienceType,
        setShowFooterButtons,
    ]);

    const title = CampaignBuilderSteps.find(
        (step) => step.key === CampaignBuilderStep.AUDIENCE,
    )?.pageHeader;

    const listHeight = gridContainerRef?.current?.offsetHeight
        ? `${(gridContainerRef?.current?.offsetHeight - 150 - 35) / 2}px`
        : '100%';
    const listClass = `max-h-[${listHeight}]`;

    return (
        <Grid className="h-full" ref={gridContainerRef}>
            <Grid.Col span={9} className="border-r-[1px] h-full !p-0">
                <StepTitle customClassName="ml-9 mt-6" title={title || ''} />
                {campaignPayload.audienceType === AudienceType.WAREHOUSE ? (
                    <AudienceTable />
                ) : (
                    <UploadAudienceDetails />
                )}
            </Grid.Col>
            <Grid.Col span={3} className="p-0 pt-2 h-full">
                <Box className="ml-1 border-l-[1px] h-full">
                    <Box className="border-b-[1px] py-[9px] px-3">
                        <Text className="text-xs text-gray-600">
                            {t('campaign_builder.audience.selected_audiences')}
                        </Text>
                    </Box>
                    <Flex
                        className="h-[150px] border-b-[1px]"
                        justify={'center'}
                        align={'center'}
                    >
                        <CountSection />
                    </Flex>
                    <Box className="p-3">
                        <Stack
                            className={`${listClass} mt-3 overflow-y-auto`}
                            spacing={0}
                            style={{ maxHeight: listHeight }}
                        >
                            <SelectedAudienceList
                                audienceIds={
                                    campaignPayload?.audienceConfig?.config?.in
                                }
                                filename={
                                    campaignPayload.csvUploadDetails?.fileName
                                }
                                label={t('campaign_builder.audience.send_list')}
                                labelIcon={
                                    <PaperPlaneTilt color="rgb(var(--color-gray-700))" />
                                }
                                channel={
                                    campaignPayload.channel ??
                                    CommunicationChannel.EMAIL
                                }
                            />
                        </Stack>
                        <Stack
                            className={`${listClass} mt-3 overflow-y-auto`}
                            spacing={0}
                            style={{ maxHeight: listHeight }}
                        >
                            <SelectedAudienceList
                                audienceIds={
                                    campaignPayload?.audienceConfig?.config
                                        .notIn
                                }
                                filename={undefined}
                                label={t(
                                    'campaign_builder.audience.suppress_list',
                                )}
                                labelIcon={
                                    <ProhibitInset color="rgb(var(--color-gray-700))" />
                                }
                                channel={
                                    campaignPayload.channel ??
                                    CommunicationChannel.EMAIL
                                }
                            />
                        </Stack>
                    </Box>
                </Box>
            </Grid.Col>
        </Grid>
    );
};

export default CampaignBuilderAudience;
