import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import useFiltersContext from '@components/Audience/Filters/FiltersProvider/useFiltersContext';
import Select from '@components/common/Select';
import { useGetRelatedTables } from '@hooks/useGetRelatedTables';
import { useLocale } from '@hooks/useLocale';
import {
    friendlyName,
    getCustomMetricType,
    type AdditionalMetric,
    type CompiledDimension,
    type MetricType,
} from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import useCustomAttributeContext from '@providers/CustomAttribute/useCustomAttributeContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useMemo } from 'react';
import { type UseFormReturn } from 'react-hook-form';
import CustomMetricFilter from '../CustomMetricFilter';
import CustomMetricPropertySelect from '../CustomMetricPropertySelect';
import { type CustomAttributeForm } from '../types';
import { getInitialCustomAttributeDefinition } from '../utils';

interface CustomMetricLogicProps {
    isLoading: boolean;
    form: UseFormReturn<CustomAttributeForm, any, undefined>;
}

const CustomMetricLogic: React.FC<CustomMetricLogicProps> = ({
    isLoading,
    form,
}) => {
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();
    const {
        selectedTable,
        selectedDimension,
        isEditMode,
        customAttributePayload,
    } = useCustomAttributeContext((context) => context.reducerState);
    const {
        selectDimension,
        addSelectedDimensionDetails,
        addMetricTypeDetailsToPayload,
        setFilters,
        addFilttersToCustomMetric,
        setDefinition,
    } = useCustomAttributeContext((context) => context.actions);
    const { fieldsMap } = useFiltersContext();
    const relatedTables = useGetRelatedTables(
        selectedTable?.name ?? '',
        activeRelation,
        fieldsMap,
    );
    const didSelectedDimension = useMemo(
        () => (selectedDimension ? true : false),
        [selectedDimension],
    );
    const handleSelectDimension = (field: FieldWithSuggestions[]) => {
        // If the selected dimension is from a different table, reset the filters
        if (selectedDimension && selectedDimension.table !== field[0].table) {
            setFilters(undefined);
            addFilttersToCustomMetric([]);
            setDefinition({
                ...getInitialCustomAttributeDefinition({
                    fieldType: customAttributePayload.type,
                    tableName: field[0].table,
                    name: customAttributePayload.name,
                    label: (
                        customAttributePayload.definition as AdditionalMetric
                    )?.label,
                }),
                //this type casting required as i want user to select the type the type and without selecting this user cant create metric
                type: '' as MetricType,
            } as AdditionalMetric);
        }
        selectDimension(field[0]);
        addSelectedDimensionDetails(field[0]);
    };
    return (
        <>
            <Box className="!min-w-[265px]">
                <Text className="mb-1">
                    {t('custom_metric.dimension_modal_property_label')}
                </Text>
                <CustomMetricPropertySelect
                    fields={
                        relatedTables
                            ? (Object.values(
                                  relatedTables,
                              ) as FieldWithSuggestions[])
                            : []
                    }
                    onSubmit={handleSelectDimension}
                    isLoading={isLoading}
                    activeField={selectedDimension}
                />
            </Box>
            <Select
                withinPortal={true}
                label={
                    <Text
                        className={!didSelectedDimension ? 'text-gray-400' : ''}
                    >
                        {t('custom_metric.dimension_modal_function_label')}
                    </Text>
                }
                placeholder={t(
                    'custom_metric.dimension_modal_function_description',
                )}
                data={
                    selectedDimension
                        ? getCustomMetricType(
                              (selectedDimension as CompiledDimension).type,
                          ).map((name) => ({
                              label: friendlyName(name),
                              value: name,
                          }))
                        : ['']
                }
                className="!w-[272px]"
                disabled={!didSelectedDimension || isLoading}
                readOnly={!isEditMode}
                required
                withAsterisk={false}
                value={
                    (customAttributePayload.definition as AdditionalMetric)
                        ?.type
                }
                onChange={(value) => {
                    form.setValue('metric', value as MetricType);
                    addMetricTypeDetailsToPayload(value as MetricType);
                }}
                readOnlyClasses="!w-[272px]"
            />

            <Text className={!didSelectedDimension ? 'text-gray-400' : ''}>
                {t('custom_metric.dimension_modal.filter_label')}
            </Text>
            <Box className={!didSelectedDimension ? 'opacity-70' : ''}>
                <CustomMetricFilter isLoading={isLoading} />
            </Box>
        </>
    );
};

export default CustomMetricLogic;
