import FieldListItem from '@components/Audience/Filters/FieldListItem';
import {
    convertFieldsIntoPropertySelectListType,
    type FieldWithSelectStatus,
} from '@components/Audience/Filters/FieldListItem/utils';
import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import { useLocale } from '@hooks/useLocale';
import { useEffect } from 'react';
import { type FieldWithSuggestions } from '../../Audience/Filters/FiltersProvider/types';

interface ProfileManagerPropertySelectProps {
    fields: FieldWithSelectStatus[];
    onSubmit: (items: FieldWithSuggestions[]) => void;
    isLoading: boolean;
    isSuccess: boolean;
    targetButton: React.ReactNode;
    opened: boolean;
    open: () => void;
    close: () => void;
}

const ProfileManagerPropertySelect: React.FC<
    ProfileManagerPropertySelectProps
> = ({
    fields,
    onSubmit,
    isLoading,
    isSuccess,
    targetButton,
    opened,
    close,
    open,
}) => {
    const { t } = useLocale();

    useEffect(() => {
        if (isSuccess) close();
    }, [isSuccess, close]);

    return (
        <>
            <PropertySelect<
                FieldWithSuggestions & AddditionalPropertySelectListProps
            >
                items={convertFieldsIntoPropertySelectListType(fields, true)}
                showGroup={true}
                headerRightSection={undefined}
                onSubmit={(items: FieldWithSuggestions[]) => {
                    onSubmit(items);
                }}
                itemTemplate={({ item }) => {
                    return (
                        <FieldListItem
                            item={item}
                            checked={item.isChecked ?? false}
                            showCheckbox={true}
                            disabled={item.isDisabled ?? false}
                        />
                    );
                }}
                opened={opened}
                close={close}
                open={open}
                targetButton={targetButton}
                showSearch={true}
                searchKeys={['label', 'tableLabel']}
                searchPlaceholder={t('audience_filters.search_filter_label')}
                allowMultipleSelection={true}
                isLoading={isLoading}
            />
        </>
    );
};

export default ProfileManagerPropertySelect;
