import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import TemplateBuilderManager from '@components/Templates/Builder/TemplateBuilderManager';
import { appendPreheader } from '@components/Templates/utils';
import { useUpdateCampaign } from '@hooks/useCampaigns';
import { useGetProviderMetada } from '@hooks/useChannels';
import {
    useGetSyncedTemplateByTemplateName,
    useGetTemplateById,
} from '@hooks/useTemplate';
import {
    AudienceType,
    CommunicationChannel,
    previewTextVariable,
    TemplateType,
    type CampaignUpdateRequest,
    type EmailTemplateContentDetails,
    type ProviderTemplateDetails,
    type SmsTemplateContentDetails,
    type TemplateContentDetails,
} from '@lightdash/common';
import { Box, Group, Stack } from '@mantine/core';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { SetupContentSource } from '../../types';
import SetupContent from './SetupContent';
import TemplateContentPreview from './SetupContent/TemplateContentPreview';

const CampaignBuilderContent: React.FC<{}> = () => {
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const { setShowFooterButtons, setTemplateDetails, populateContentDetails } =
        useCampaignContext((context) => context.actions);

    const { campaignPayload } = useCampaignContext((context) => context.state);
    const { templateDetails, communicationDetails, contentDetails } =
        campaignPayload;
    const [selectedTemplate, setSelectedTemplate] = useState<string>('');

    // INFO - Will need this for template de link from campaigns
    const isTemplateSelected = useMemo(() => {
        if (campaignPayload.channel === CommunicationChannel.WHATSAPP) {
            return !!templateDetails?.id;
        }
        if (campaignPayload.channel === CommunicationChannel.EMAIL) {
            return !!(contentDetails as EmailTemplateContentDetails)?.html;
        }
        if (campaignPayload.channel === CommunicationChannel.SMS) {
            return !!(contentDetails as SmsTemplateContentDetails)?.body;
        }
        return false;
    }, [campaignPayload.channel, contentDetails, templateDetails?.id]);

    const { data: providerMetadata, isInitialLoading } = useGetProviderMetada(
        communicationDetails?.providerId ?? '',
        campaignPayload?.channel ?? '',
    );

    const {
        data: templateMetadata,
        isInitialLoading: isTemplateMetadataLoading,
    } = useGetTemplateById(
        selectedTemplate,
        campaignPayload?.channel !== CommunicationChannel.WHATSAPP,
    );

    const { mutateAsync: updateCampaign } = useUpdateCampaign(campaignUuid);

    useEffect(() => {
        if (templateMetadata) {
            if (campaignPayload.channel === CommunicationChannel.EMAIL) {
                let payload: CampaignUpdateRequest = {
                    campaignTrackingParameters:
                        templateMetadata.contents[0]?.trackingParameters !==
                        null
                            ? templateMetadata.contents[0]?.trackingParameters
                            : undefined,
                    contentDetails: {
                        subject: templateMetadata.contents[0]?.subject ?? '',
                        html: (
                            templateMetadata.contents[0]
                                ?.content as EmailTemplateContentDetails
                        ).html,
                        json: (
                            templateMetadata.contents[0]
                                .content as EmailTemplateContentDetails
                        ).json,
                        variables: templateMetadata.contents[0].variables,
                        parsedVariables:
                            templateMetadata.contents[0].parsedVariables ?? [],
                    },
                    previewText:
                        templateMetadata.contents[0]?.preheader !== null
                            ? templateMetadata.contents[0]?.preheader
                            : undefined,
                    templateDetails: {
                        id: '',
                        type: TemplateType.INTERNAL,
                    },
                };
                if (
                    campaignPayload.audienceType === AudienceType.WAREHOUSE &&
                    templateMetadata.contents[0].contentMappings
                ) {
                    payload = {
                        ...payload,
                        contentMappings:
                            templateMetadata.contents[0]?.contentMappings,
                    };
                }
                populateContentDetails(payload);
                void updateCampaign(payload);
            }
            if (campaignPayload.channel === CommunicationChannel.SMS) {
                let payload: CampaignUpdateRequest = {
                    campaignTrackingParameters:
                        templateMetadata.contents[0]?.trackingParameters !==
                        null
                            ? templateMetadata.contents[0]?.trackingParameters
                            : undefined,
                    contentDetails: {
                        ...(templateMetadata.contents[0]
                            ?.content as SmsTemplateContentDetails),
                        variables: templateMetadata.contents[0]?.variables,
                    },
                    templateDetails: {
                        id: '',
                        type: TemplateType.INTERNAL,
                    },
                };
                if (
                    campaignPayload.audienceType === AudienceType.WAREHOUSE &&
                    templateMetadata.contents[0].contentMappings
                ) {
                    payload = {
                        ...payload,
                        contentMappings:
                            templateMetadata.contents[0]?.contentMappings,
                    };
                }
                populateContentDetails(payload);
                void updateCampaign(payload);
            }
        }
    }, [
        campaignPayload.audienceType,
        campaignPayload.channel,
        populateContentDetails,
        templateMetadata,
        updateCampaign,
    ]);

    const {
        data: syncedTemplateMetadata,
        isInitialLoading: isSyncedTemplateMetadataLoading,
    } = useGetSyncedTemplateByTemplateName(
        (templateDetails?.integrationId || communicationDetails?.id) ?? '',
        templateDetails?.id ?? '',
        campaignPayload?.channel === CommunicationChannel.WHATSAPP,
    );

    const handleTemplateSelect = useCallback(
        (value: ProviderTemplateDetails) => {
            if (!value) return;

            if (campaignPayload.channel === CommunicationChannel.WHATSAPP) {
                setTemplateDetails({
                    id: value?.name,
                    type: TemplateType.EXTERNAL,
                    integrationId: value?.integrationId,
                });
                void updateCampaign({
                    templateDetails: {
                        id: value?.name,
                        type: TemplateType.EXTERNAL,
                        integrationId: value?.integrationId,
                    },
                });
            } else {
                setSelectedTemplate(value.id);
            }
        },
        [campaignPayload.channel, setTemplateDetails, updateCampaign],
    );

    useEffect(() => {
        if (templateDetails?.id || contentDetails) {
            setShowFooterButtons({
                next: true,
                back: true,
            });
            return;
        }
        setShowFooterButtons({
            next: false,
            back: true,
        });
    }, [templateDetails, setShowFooterButtons, contentDetails]);

    const getTemplateMetadata = useMemo(() => {
        if (campaignPayload?.channel === CommunicationChannel.WHATSAPP) {
            return {
                ...syncedTemplateMetadata,
                contents: syncedTemplateMetadata?.languages[0]?.content ?? [],
                channel: CommunicationChannel.WHATSAPP,
            };
        }
        if (campaignPayload.contentDetails) {
            return campaignPayload.contentDetails;
        }
        return templateMetadata;
    }, [
        campaignPayload?.channel,
        campaignPayload.contentDetails,
        templateMetadata,
        syncedTemplateMetadata,
    ]);

    const handleUpdateTemplateInCampaignContext = async (
        content: TemplateContentDetails,
    ) => {
        try {
            let updatedContent = {
                ...content,
            };
            if ((updatedContent as EmailTemplateContentDetails)?.html) {
                updatedContent = {
                    ...updatedContent,
                    html: appendPreheader(
                        (updatedContent as EmailTemplateContentDetails)?.html,
                        previewTextVariable,
                    ),
                };
            }
            const response = await updateCampaign({
                contentDetails: updatedContent,
            });
            populateContentDetails({
                contentDetails: {
                    ...campaignPayload.contentDetails,
                    ...updatedContent,
                    ...response.contentDetails,
                },
            });
        } catch (error) {}
    };

    if (
        isInitialLoading ||
        isTemplateMetadataLoading ||
        isSyncedTemplateMetadataLoading
    )
        return <SuboptimalState loading />;

    return (
        <Group spacing={4} className="items-start h-full" noWrap>
            <Stack className="w-full grow border-r-[1px] h-full">
                <Box className="">
                    {!isTemplateSelected &&
                    providerMetadata &&
                    campaignPayload?.channel ? (
                        <Box className="w-[32rem] mt-6 ml-[6.5rem]">
                            <TemplateBuilderManager
                                integration={providerMetadata}
                                isSingleSelect={true}
                                handleTemplateSelect={handleTemplateSelect}
                                integrationId={communicationDetails?.id ?? ''}
                                channel={campaignPayload?.channel ?? ''}
                                onClose={undefined}
                                editMode={false}
                                contentDetails={campaignPayload.contentDetails}
                                handleUpdateTemplateInCampaignContext={
                                    undefined
                                }
                            />
                        </Box>
                    ) : (
                        <>
                            <SetupContent
                                templateMetadata={getTemplateMetadata as any}
                                onTemplateSetupComplete={undefined}
                                fields={undefined}
                                additionalPropertySelectList={undefined}
                                source={SetupContentSource.CAMPAIGN}
                            />
                        </>
                    )}
                </Box>
            </Stack>
            {isTemplateSelected && getTemplateMetadata && (
                <Box className="w-full h-full">
                    <TemplateContentPreview
                        templateMetadata={getTemplateMetadata as any}
                        grapesContainerId="html-preview-campaigns"
                        showDeviceAndThemeSwitcher={true}
                        onTemplateSelect={handleTemplateSelect}
                        isSetupComplete={undefined}
                        openContentSetupManager={() => {}}
                        source={SetupContentSource.CAMPAIGN}
                        handleUpdateTemplateInCampaignContext={
                            handleUpdateTemplateInCampaignContext
                        }
                    />
                </Box>
            )}
        </Group>
    );
};

export default CampaignBuilderContent;
