import {
    Box,
    Button,
    Divider,
    Flex,
    Popover,
    Stack,
    Text,
} from '@mantine/core';

import EventHistoryModal from '@components/EventsManager/Table/MenuItems/EventHistoryModal';
import { useLocale } from '@hooks/useLocale';
import { useDisclosure } from '@mantine/hooks';
import { Clock, CursorClick, Notebook } from '@phosphor-icons/react';
import { ButtonVariant } from '../../../../../mantineTheme';

interface EventInfoPopoverProps {
    target: React.ReactNode;
    eventSource: string;
    eventName: string;
    eventLabel: string;
}
const EventInfoPopover: React.FC<EventInfoPopoverProps> = ({
    target,
    eventLabel,
    eventSource,
    eventName,
}) => {
    const [opened, { close, open }] = useDisclosure(false);
    const [
        isEventHistoryModalOpen,
        { open: openEventHistoryModal, close: closeEventHistoryModal },
    ] = useDisclosure(false);
    const { t } = useLocale();
    return (
        <>
            <Popover
                width={300}
                position="left"
                shadow="md"
                withinPortal
                opened={opened}
                styles={{
                    dropdown: {
                        padding: 0,
                    },
                }}
                //Fix Me: for now we are not using this popover as we are not able to fetch the event logs in events api call in journeys
                disabled={true}
            >
                <Popover.Target>
                    <Box onMouseEnter={open} onMouseLeave={close}>
                        {target}
                    </Box>
                </Popover.Target>
                <Popover.Dropdown
                    onMouseEnter={open}
                    onMouseLeave={close}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                        e.stopPropagation();
                    }}
                >
                    <Stack spacing="xs">
                        <Flex
                            align="center"
                            gap={4}
                            justify="space-between"
                            className="p-3"
                        >
                            <Box>
                                <Flex align="center" gap={4}>
                                    <CursorClick
                                        color={'rgb(var(--color-mustard-800))'}
                                    />
                                    <Text size="sm" weight={500}>
                                        {eventLabel}
                                    </Text>
                                </Flex>
                                <Flex align="center" gap={4}>
                                    <Clock
                                        color={'rgb(var(--color-gray-500))'}
                                    />
                                    <Text
                                        size="xs"
                                        weight={500}
                                        className="text-gray-500"
                                    >
                                        {eventSource}
                                    </Text>
                                </Flex>
                            </Box>
                            <Button
                                variant={ButtonVariant.OUTLINED}
                                className="p-1 rounded-sm"
                                onClick={() => {
                                    close();
                                    openEventHistoryModal();
                                }}
                            >
                                <Notebook weight="duotone" />
                            </Button>
                        </Flex>
                        <Divider color="rgb(var(--color-gray-250))" />
                        <Stack className="p-3" spacing={8}>
                            <Flex
                                align="center"
                                gap={4}
                                justify="space-between"
                            >
                                <Text
                                    size="xs"
                                    weight={500}
                                    className="text-gray-600"
                                >
                                    {t(
                                        'journey_builder.trigger_block_events_list_last_7_days',
                                    )}
                                </Text>
                                <Text
                                    size="xs"
                                    weight={500}
                                    className="text-gray-700"
                                >
                                    1000
                                </Text>
                            </Flex>
                            <Flex
                                align="center"
                                gap={4}
                                justify="space-between"
                            >
                                <Text
                                    size="xs"
                                    weight={500}
                                    className="text-gray-600"
                                >
                                    {t(
                                        'journey_builder.trigger_block_events_list_last_30_days',
                                    )}
                                </Text>
                                <Text
                                    size="xs"
                                    weight={500}
                                    className="text-gray-700"
                                >
                                    1000
                                </Text>
                            </Flex>
                        </Stack>
                    </Stack>
                </Popover.Dropdown>
            </Popover>
            <EventHistoryModal
                isEventHistoryModalOpen={isEventHistoryModalOpen}
                closeEventHistoryModal={closeEventHistoryModal}
                eventName={eventName}
            />
        </>
    );
};

export default EventInfoPopover;
