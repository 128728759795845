import { Chat } from '@components/AnalystChat/Chat';
import { Box } from '@mantine/core';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';
import { useEffect } from 'react';

const Home = () => {
    const { showAiAnalyst } = useAiAnalystContext((context) => context.state);
    const { setShowAiAnalyst } = useAiAnalystContext(
        (context) => context.actions,
    );

    useEffect(() => {
        if (showAiAnalyst) {
            setShowAiAnalyst(false);
        }
    }, [setShowAiAnalyst, showAiAnalyst]);

    return (
        <Box
            className="h-full"
            style={{
                background:
                    'radial-gradient(100% 100% at 50% 0%, rgba(16, 16, 16, 0.00) 50.99%, #101010 100%), #2F2F2F',
            }}
        >
            <Chat />
        </Box>
    );
};

export default Home;
