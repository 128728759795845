import SkeletonLoader from '@components/common/SkeletonLoader';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import { Button, Flex } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { FileArrowUp } from '@phosphor-icons/react';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import React, { useEffect, useState } from 'react';
import { ButtonVariant } from '../../../../../../mantineTheme';
import AudienceUploadModal from '../AudienceUploadModal';
import InfiniteScrollTable from './InfiniteScrollTable';
import useAudienceColumns from './useAudienceColumns';
import useAudienceData from './useAudienceData';

const AudienceTable = () => {
    const { t } = useLocale();
    const [uploadCsvModal, setUploadCsvModal] = useState(false);
    const [searchValue, setSearchValue] = useDebouncedState(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const [currentPage, setCurrentPage] = useState(1);
    const audienceColumns = useAudienceColumns();
    const {
        audienceData = [],
        pagination,
        isFetching,
        fetchNextPage,
    } = useAudienceData({
        currentPage,
        searchValue,
    });

    useEffect(() => {
        if (searchValue) {
            setCurrentPage(1);
        }
    }, [searchValue]);

    const onSearchChange = (value: string) => {
        setSearchValue(value);
        setCurrentPage(1);
    };

    const onPageChange = (newPage: number) => {
        setCurrentPage(newPage);
        if (currentPage !== newPage) {
            void fetchNextPage();
        }
    };

    if (!audienceData || !pagination) {
        return <SkeletonLoader width={250} height={100} />;
    }

    return (
        <div>
            <Flex className="w-full mt-3 mb-6 ml-9" gap={8}>
                <SearchInput
                    className="w-[600px]"
                    placeholder={t(
                        'campaigns_builder_audience.search_placeholder',
                    )}
                    onChange={(e) => onSearchChange(e.target.value)}
                />
                <Button
                    variant={ButtonVariant.OUTLINED}
                    leftIcon={
                        <FileArrowUp color="rgb(var(--color-gray-700))" />
                    }
                    onClick={() => setUploadCsvModal(true)}
                >
                    {t('campaign_builder.upload_csv_button')}
                </Button>
            </Flex>
            <InfiniteScrollTable
                pagination={pagination}
                data={audienceData}
                columns={audienceColumns}
                isFetching={isFetching}
                onPageChange={onPageChange}
                height={'70vh'}
            />
            {uploadCsvModal && (
                <AudienceUploadModal
                    open={uploadCsvModal}
                    onClose={() => setUploadCsvModal(false)}
                />
            )}
        </div>
    );
};

export default AudienceTable;
