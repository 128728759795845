import PrimaryTableConfiguration from './Steps/PrimaryTableConfiguration';
import PrimaryTableSetup from './Steps/PrimaryTableSetup';
import SchemaTableConfiguration from './Steps/SchemaTableConfiguration';
import SchemaTableRelationships from './Steps/SchemaTableRelationships';
import TableEvents from './Steps/TableEvents';
import TableProperties from './Steps/TableProperties';
import {
    SchemaBuilderStep,
    SchemaConfigStep,
    SchemaFileType,
    type SchemaStepMap,
    type StepMap,
} from './types';

export const stepperSteps: StepMap[] = [
    {
        key: SchemaBuilderStep.SETUP,
        label: 'Setup',
        isVisited: false,
    },
    {
        key: SchemaBuilderStep.PERSONALISE,
        label: 'Properties',
        isVisited: false,
    },
];

export const SchemaBuilderSteps: SchemaStepMap[] = [
    {
        key: SchemaBuilderStep.SETUP,
        label: 'Setup',
        component: PrimaryTableSetup,
    },
    {
        key: SchemaBuilderStep.PERSONALISE,
        label: 'Personalise',
        component: PrimaryTableConfiguration,
    },
    {
        key: SchemaBuilderStep.PROPERTIES,
        label: 'Configure',
        component: TableProperties,
    },
];

export const SchemaConfigSteps: SchemaStepMap[] = [
    {
        key: SchemaConfigStep.CONFIGURE,
        label: 'Configure',
        component: SchemaTableConfiguration,
    },
    {
        key: SchemaConfigStep.RELATIONSHIPS,
        label: 'Relationships',
        component: SchemaTableRelationships,
    },
    {
        key: SchemaConfigStep.EVENTS,
        label: 'Events',
        component: TableEvents,
    },
    {
        key: SchemaConfigStep.PROPERTIES,
        label: 'Configure',
        component: TableProperties,
    },
];

export const fileTypeData = [
    {
        value: SchemaFileType.WAREHOUSE,
        label: SchemaFileType.WAREHOUSE,
    },
    {
        value: SchemaFileType.CSV,
        label: SchemaFileType.CSV,
    },
];
