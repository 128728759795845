import TestCommunication from '@components/TestCommunication';
import { TestCommunicationSource } from '@components/TestCommunication/types';
import useTestCommunication from '@components/TestCommunication/useTestCommunication';
import { useGetTestProfiles } from '@hooks/useTestProfiles';
import {
    CommunicationChannel,
    TemplateType,
    type AnyType,
    type Campaign,
    type ContentMappings,
    type EmailTemplateContentDetails,
    type TemplateContentDetails,
} from '@lightdash/common';
import { Box, Button, Flex, Menu, Text } from '@mantine/core';
import { CaretDown, CaretRight } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import { ButtonVariant } from '../../../../mantineTheme';

type SendTestProps = {
    channel: CommunicationChannel | undefined;
    templateId: string;
    mappings: ContentMappings | undefined;
    contentDetails: Campaign['contentDetails'];
    disabled: boolean;
    subject: string | undefined;
    previewText?: string | undefined;
};
const SendTest = ({
    channel,
    templateId,
    mappings,
    contentDetails,
    disabled,
    subject,
    previewText,
}: SendTestProps) => {
    const [testProfilesOptions, setTestProfilesOptions] = useState<
        { label: string; value: string }[]
    >([]);
    const [selectedTestProfiles, setSelectedTestProfiles] = useState<string[]>(
        [],
    );
    const [communicationDetails, setCommunicationDetails] =
        useState<Campaign['communicationDetails']>(undefined);

    const { sendTestOldFlow } = useTestCommunication({
        communicationDetails: {
            id: communicationDetails?.id ?? '',
            providerId: communicationDetails?.providerId ?? '',
            eventId: '',
        },
        templateDetails: {
            id: templateId,
            type: TemplateType.INTERNAL,
        },
        channel: channel ?? CommunicationChannel.EMAIL,
        previewText,
    });

    const { data } = useGetTestProfiles({
        successCallback: (payload) => {
            if (payload && payload.length) {
                setSelectedTestProfiles([
                    ...payload
                        .map(
                            (item: any) =>
                                item[channel ?? CommunicationChannel.EMAIL],
                        )
                        .filter((item: any) => item),
                ]);
                setTestProfilesOptions([
                    ...payload.map(
                        (profile: { name: string; [key: string]: string }) => ({
                            label: profile.name,
                            value: profile?.[
                                channel ?? CommunicationChannel.EMAIL
                            ],
                        }),
                    ),
                ]);
            }
        },
    });

    useEffect(() => {
        if (data && data.length && channel) {
            setSelectedTestProfiles([
                ...data
                    .map(
                        (item: any) =>
                            item[channel ?? CommunicationChannel.EMAIL],
                    )
                    .filter((item: any) => item),
            ]);
            setTestProfilesOptions([
                ...data
                    .filter(
                        (profile: AnyType) =>
                            profile?.[channel ?? CommunicationChannel.EMAIL],
                    )
                    .map((profile: any) => ({
                        label: profile.name,
                        value: profile?.[channel ?? CommunicationChannel.EMAIL],
                    })),
            ]);
        }
    }, [channel, data]);

    const handleSendTest = async () => {
        try {
            if (!contentDetails) return;

            let contentPayload: TemplateContentDetails = { ...contentDetails };
            if (channel === CommunicationChannel.EMAIL && subject) {
                contentPayload = {
                    ...contentPayload,
                    subject,
                } as EmailTemplateContentDetails;
            }
            await sendTestOldFlow({
                mappings: mappings ?? {},
                contentDetails: contentPayload,
                recipients: selectedTestProfiles,
            });
        } catch (error) {}
    };
    return (
        <Menu>
            <Menu.Target>
                <Button
                    variant={ButtonVariant.OUTLINED}
                    rightIcon={<CaretDown color="rgb(var(--color-gray-600))" />}
                    disabled={disabled}
                >
                    Send test
                </Button>
            </Menu.Target>
            <Menu.Dropdown className="p-0">
                <Box className="w-[600px]">
                    <Box>
                        <Text className="text-base text-gray-800 font-medium p-3 border-b-[1px]">
                            Send test {channel}
                        </Text>
                    </Box>
                    <Box className="p-3">
                        <TestCommunication
                            source={TestCommunicationSource.TEMPLATES}
                            journeyVariables={{}}
                            mappings={{}}
                            testProfilesOptions={testProfilesOptions}
                            setSelectedTestProfiles={setSelectedTestProfiles}
                            setTestProfilesOptions={setTestProfilesOptions}
                            selectedTestProfiles={selectedTestProfiles}
                            handleChange={() => {}}
                            channel={channel ?? CommunicationChannel.EMAIL}
                            communicationDetails={communicationDetails}
                            setCommunicationDetails={setCommunicationDetails}
                        />
                    </Box>
                    <Flex className="border-t-[1px] p-3" justify={'flex-end'}>
                        <Button
                            onClick={handleSendTest}
                            variant={ButtonVariant.OUTLINED_ACCENTED}
                            rightIcon={
                                <CaretRight color="rgb(var(--color-blu-800))" />
                            }
                        >
                            Send test {channel}
                        </Button>
                    </Flex>
                </Box>
            </Menu.Dropdown>
        </Menu>
    );
};

export default SendTest;
