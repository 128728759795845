import { useEffect, useState } from 'react';

// Function to randomly decide how many words to reveal at a time
const getRandomWordChunkSize = () => {
    const random = Math.random();
    if (random < 0.3) return 1; // 30% chance: 1 word
    if (random < 0.7) return 2; // 40% chance: 2 words
    return 3; // 30% chance: 3 words
};

export const useStreamingText = (text: string, speed: number = 80) => {
    const [streamedText, setStreamedText] = useState('');
    const [index, setIndex] = useState(0);

    // Split text into words, preserving spaces
    const words = text.split(/(\s+)/); // Splits words while keeping spaces intact

    useEffect(() => {
        if (index >= words.length) return;
        const chunkSize = getRandomWordChunkSize();
        const nextChunk = words.slice(index, index + chunkSize).join('');

        const timeoutId = setTimeout(() => {
            setStreamedText((prev) => prev + nextChunk);
            setIndex(index + chunkSize);
        }, speed);

        return () => clearTimeout(timeoutId);
    }, [index, text, speed, words]);

    return streamedText;
};
