import { isTimeWindowFilterInMetricFilter } from '@components/Audience/Filters/utils';
import { journeyEventFieldRef } from '@components/Audience/utils';
import FilterOperatorSelect from '@components/common/Select/FilterOperatorSelect';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { TextWithTooltip } from '@components/common/TextWithTooltip';
import { useJourneys } from '@hooks/useJourney';
import { useLocale } from '@hooks/useLocale';
import {
    FilterOperator,
    getFilterRuleWithDefaultValue,
    JourneyEventColumns,
    WarehouseTypes,
    type AdditionalMetric,
    type FilterableField,
    type FilterRule,
} from '@lightdash/common';
import { ActionIcon, Button, Flex, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FlowArrow, PlusCircle, X } from '@phosphor-icons/react';
import useProjectContext from '@providers/Project/useProjectContext';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ButtonVariant } from '../../../../../../mantineTheme';
import JourneyMenu from './JourneyMenu';
import {
    createFilterRuleForJourneyEvent,
    journeyEventFilterOperatorOptions,
    journeyStatusForJourneyEventFilter,
    timeWindowFieldRefInJourneyEvents,
} from './utils';

interface JourneyFilterRuleFormInAudienceProps {
    journeyFilters: FilterRule[];
    activeField: FilterableField;
    isEditMode: boolean;
    additionalMetrics: AdditionalMetric[];
    setAdditionalMetrics: (
        metrics: AdditionalMetric[],
        isDerivedMetric: boolean,
        groupIndex: number,
    ) => void;
    groupIndex: number;
}
const JourneyFilterRuleFormInAudience = ({
    journeyFilters,
    activeField,
    isEditMode,
    additionalMetrics,
    setAdditionalMetrics,
    groupIndex,
}: JourneyFilterRuleFormInAudienceProps) => {
    const [opened, { open, close }] = useDisclosure();
    const { data: journeyData, isLoading: isJourneyLoading } = useJourneys({
        perPage: 1000,
        currentPage: 1,
        query: ``,
    });
    const { t } = useLocale();
    const filterRule = journeyFilters[0];
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [activeJourneyId, setActiveJourneyId] = useState<string | null>(
        filterRule ? (filterRule?.values?.[0] as string) : null,
    );

    const { projectData } = useProjectContext();

    const timeWindowFieldRef = useMemo(
        () =>
            projectUuid &&
            projectData?.warehouseConnection &&
            timeWindowFieldRefInJourneyEvents(
                projectUuid,
                projectData.warehouseConnection.type,
            ),
        [projectUuid, projectData],
    );
    const handleJourneyFilterChange = useCallback(
        (newFilterRule: FilterRule[]) => {
            if (!projectUuid) return additionalMetrics;
            const newAdditionalMetrics = additionalMetrics.map((metric) => {
                if (
                    metric.name === activeField?.name &&
                    metric.table === activeField?.table
                ) {
                    let baseFilterRules = metric.filters?.slice(0, 1);
                    const timeWindowFilter = metric.filters?.find(
                        (eachFilter) =>
                            timeWindowFieldRef &&
                            isTimeWindowFilterInMetricFilter(
                                eachFilter,
                                timeWindowFieldRef,
                            ),
                    );
                    if (timeWindowFilter) {
                        baseFilterRules = [
                            ...(baseFilterRules ?? []),
                            timeWindowFilter,
                        ];
                    }
                    if (!baseFilterRules || baseFilterRules.length === 0)
                        return metric;
                    if (!projectData || !projectData.warehouseConnection)
                        return metric;
                    const addingFieldRef = newFilterRule.map((rule) => {
                        return {
                            ...rule,
                            target: {
                                ...rule.target,
                                fieldRef: journeyEventFieldRef(
                                    projectUuid,
                                    JourneyEventColumns.JOURNEY_ID,
                                    projectData?.warehouseConnection?.type ??
                                        WarehouseTypes.SNOWFLAKE,
                                ),
                            },
                        };
                    });
                    return {
                        ...metric,
                        filters: [...baseFilterRules, ...addingFieldRef],
                    };
                }
                return metric;
            });
            setAdditionalMetrics(newAdditionalMetrics, false, groupIndex);
        },
        [
            projectUuid,
            additionalMetrics,
            setAdditionalMetrics,
            groupIndex,
            activeField?.name,
            activeField?.table,
            projectData,
            timeWindowFieldRef,
        ],
    );
    const handleJourneyFilterDelete = useCallback(() => {
        if (!projectUuid) return;
        const newAdditionalMetrics = [...additionalMetrics];
        const alteredAdditionalMetrics = newAdditionalMetrics.map((metric) => {
            if (
                metric.name === activeField?.name &&
                metric.table === activeField?.table &&
                projectData?.warehouseConnection
            ) {
                return {
                    ...metric,
                    filters: metric.filters?.filter(
                        (filter) =>
                            filter.target.fieldRef !==
                            journeyEventFieldRef(
                                projectUuid,
                                JourneyEventColumns.JOURNEY_ID,
                                projectData?.warehouseConnection?.type ??
                                    WarehouseTypes.SNOWFLAKE,
                            ),
                    ),
                };
            }
            return metric;
        });
        setAdditionalMetrics(alteredAdditionalMetrics, false, groupIndex);
    }, [
        projectUuid,
        additionalMetrics,
        setAdditionalMetrics,
        groupIndex,
        activeField?.name,
        activeField?.table,
        projectData?.warehouseConnection,
    ]);
    const filteredJourneyData = useMemo(() => {
        const journeys = journeyData?.data ?? [];
        return journeys.filter((journey) => {
            return (
                journey.id !== activeJourneyId &&
                journeyStatusForJourneyEventFilter.includes(journey.status)
            );
        });
    }, [journeyData, activeJourneyId]);
    const renderSelectComponent = useMemo(() => {
        if (!activeField) return null;
        if (journeyFilters.length === 0) return null;
        return (
            <FilterOperatorSelect
                isDisabled={!isEditMode}
                filterOperatorOptions={journeyEventFilterOperatorOptions()}
                selectedOperator={filterRule.operator}
                onChange={(value) => {
                    if (!value) return;
                    handleJourneyFilterChange([
                        getFilterRuleWithDefaultValue(
                            activeField,
                            {
                                ...filterRule,
                                operator: value as FilterRule['operator'],
                            },
                            (filterRule.values?.length || 0) > 0
                                ? filterRule.values
                                : [1],
                        ),
                    ]);
                }}
            />
        );
    }, [
        activeField,
        filterRule,
        isEditMode,
        handleJourneyFilterChange,
        journeyFilters.length,
    ]);
    const journeyName = useMemo(() => {
        if (journeyFilters.length === 0) return null;
        if (!filterRule.values?.[0]) return null;
        const journey = journeyData?.data?.find(
            (eachJourney) => eachJourney.id === filterRule.values?.[0],
        );
        if (!journey) return null;
        return journey.name;
    }, [journeyData, filterRule, journeyFilters.length]);
    const targetButton = () => {
        return (
            <Button
                variant={ButtonVariant.SUBTLE}
                className="text-gray-800 w-fit"
                size="md"
            >
                <PlusCircle
                    color="rgb(var(--color-gray-800))"
                    className="mr-1"
                />
                {t('audience_filters.specify_journey')}
            </Button>
        );
    };
    const filledTargetButton = useMemo(() => {
        return (
            <Button
                variant={ButtonVariant.UNSTYLED}
                className={`p-2 min-h-[2.3rem] rounded-lg bg-white border border-shade-6 shadow-card  text-gray-600 text-sm cursor-${
                    isEditMode ? 'auto' : 'pointer hover:bg-gray-50'
                }`}
                form="journey_filter"
            >
                <Flex gap={4} align="center">
                    <FlowArrow color={'rgb(var(--color-orange-800))'} />
                    <Text className="flex-grow text-sm font-medium text-gray-800  max-w-[10rem] ">
                        <TextWithTooltip
                            text={journeyName ?? ''}
                            className="!m-0 rounded text-sm text-gray-800 font-medium"
                        />
                    </Text>
                </Flex>
            </Button>
        );
    }, [journeyName, isEditMode]);
    const handleJourneyMenuChange = useCallback(
        (id: string) => {
            if (!id || !projectUuid || !projectData?.warehouseConnection)
                return;
            setActiveJourneyId(id);
            handleJourneyFilterChange([
                createFilterRuleForJourneyEvent({
                    dimension: JourneyEventColumns.JOURNEY_ID,
                    value: id,
                    projectUuid,
                    operator: FilterOperator.EQUALS,
                    warehouseType: projectData.warehouseConnection.type,
                }),
            ]);
        },
        [handleJourneyFilterChange, projectData, projectUuid],
    );
    if (journeyFilters.length === 0) {
        if (!isEditMode) {
            return null;
        }
        return (
            <JourneyMenu
                journeyData={filteredJourneyData ?? []}
                targetButton={
                    isJourneyLoading ? (
                        <SkeletonLoader height={20} width={130} />
                    ) : (
                        targetButton()
                    )
                }
                opened={opened}
                onOpen={open}
                onClose={close}
                isDisabled={!isEditMode}
                onChange={handleJourneyMenuChange}
            />
        );
    }

    return (
        <Stack className="gap-2">
            <Flex gap={6} align="center">
                <Text className="text-sm text-gray-600">
                    {t('common.where')}
                </Text>
                <Flex gap={4} align="center">
                    <FlowArrow color={'rgb(var(--color-orange-800))'} />
                    <Text className="text-sm font-medium text-gray-800">
                        {t('common.journey')}
                    </Text>
                </Flex>
                {renderSelectComponent}
                <JourneyMenu
                    journeyData={filteredJourneyData ?? []}
                    targetButton={
                        isJourneyLoading ? (
                            <SkeletonLoader height={20} width={130} />
                        ) : (
                            filledTargetButton
                        )
                    }
                    opened={opened}
                    onOpen={open}
                    onClose={close}
                    isDisabled={!isEditMode}
                    onChange={(id) => {
                        setActiveJourneyId(id);
                        handleJourneyFilterChange([
                            {
                                ...filterRule,
                                values: [id],
                            },
                        ]);
                    }}
                />
                <ActionIcon
                    onClick={handleJourneyFilterDelete}
                    variant={ButtonVariant.UNSTYLED}
                    className="rounded-lg hover:bg-shade-4"
                >
                    <X
                        size={14}
                        color={'rgb(var(--color-gray-700))'}
                        weight="bold"
                    />
                </ActionIcon>
            </Flex>
        </Stack>
    );
};

export default JourneyFilterRuleFormInAudience;
