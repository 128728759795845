import {
    CampaignBuilderStep as CampaignBuilderStepEnum,
    CampaignContentStep as CampaignContentStepEnum,
    type CampaignBuilderStep,
    type CampaignContentStep,
} from '@components/Campaigns/Builder/types';
import {
    assertUnreachable,
    AudienceType,
    CampaignScheduleType,
    CampaignType,
    ContentMappingSection,
    type Audience,
    type CampaignConversionConfig,
    type CampaignThrottleConfig,
    type CampaignUpdateRequest,
    type CommunicationChannel,
    type ContentMappings,
    type CSVUploadDetailsType,
    type FieldType,
    type Integration,
    type RecurringDetails,
    type SenderDetails,
    type TemplateContentDetails,
    type TemplateDetails,
} from '@lightdash/common';
import { type Editor } from 'grapesjs';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import CampaignProviderContext from './context';
import {
    ActionType,
    type CampaignContext,
    type CampaignReducerState,
    type CampaignState,
    type CurrentStepCallbackProps,
    type TrackedLinks,
} from './types';

type Action =
    | {
          type: ActionType.SET_AUDIENCE_PAYLOAD;
          payload: Audience | null | undefined;
      }
    | { type: ActionType.SET_CHANNEL_PAYLOAD; payload: Integration | null }
    | {
          type: ActionType.SET_CONTENT_PAYLOAD;
          payload: TemplateContentDetails | null;
      }
    | { type: ActionType.SET_SCHEDULER_EXECUTION_TIME; payload: Date }
    | { type: ActionType.SET_SCHEDULER_END_TIME; payload: Date | null }
    | { type: ActionType.SET_CRON; payload: string }
    | {
          type: ActionType.SET_RECURRING_DETAILS;
          payload: RecurringDetails | undefined;
      }
    | { type: ActionType.SET_CAMPAIGN_NAME; payload: string }
    | { type: ActionType.SET_CAMPAIGN_DESCRIPTION; payload: string }
    | { type: ActionType.SET_CURRENT_STEP; payload: CampaignBuilderStep }
    | { type: ActionType.SET_SCHEDULE_TYPE; payload: CampaignScheduleType }
    | {
          type: ActionType.SET_CURRENT_STEP_CALLBACK;
          payload: CurrentStepCallbackProps;
      }
    | {
          type: ActionType.SET_PREVIOUS_STEP_CALLBACK;
          payload: CurrentStepCallbackProps;
      }
    | {
          type: ActionType.SET_SHOW_FOOTER_BUTTONS;
          payload: { next: boolean; back: boolean };
      }
    | {
          type: ActionType.SET_SEND_TO_VARIABLE_MAPPING;
          payload: {
              fieldKey: string;
              fieldType: FieldType;
          };
      }
    | { type: ActionType.SET_SUBSCRIPTION_GROUP; payload: string }
    | { type: ActionType.SET_AUDIENCE_UPLOAD_LINK; payload: string }
    | { type: ActionType.SET_AUDIENCE_CSV_DATA; payload: unknown[] }
    | { type: ActionType.SET_GRAPES_PREVIEW_INSTANCE; payload: Editor }
    | { type: ActionType.SET_TRACKED_LINKS; payload: TrackedLinks }
    | { type: ActionType.SET_UTM_TRACKING; payload: Record<string, string> }
    | {
          type: ActionType.SET_AUDIENCE_CSV_UPLOAD_DATA;
          payload: CSVUploadDetailsType | undefined;
      }
    | { type: ActionType.SET_CAMPAIGN_TYPE; payload: AudienceType }
    | { type: ActionType.SET_CAMPAIGN_CHANNEL; payload: CommunicationChannel }
    | { type: ActionType.SET_CAMPAIGN_RUN_TYPE; payload: CampaignType }
    | { type: ActionType.SET_TEMPLATE_DETAILS; payload: TemplateDetails }
    | { type: ActionType.SET_CONTENT_DETAILS; payload: TemplateContentDetails }
    | { type: ActionType.SET_CONTENT_MAPPINGS; payload: ContentMappings }
    | { type: ActionType.RESET_CONTENT_MAPPINGS }
    | { type: ActionType.RESET_STATE; payload: CampaignReducerState }
    | { type: ActionType.SET_SENDER_DETAILS; payload: SenderDetails }
    | {
          type: ActionType.SET_THROTTLE_CONFIG;
          payload: CampaignThrottleConfig | undefined;
      }
    | { type: ActionType.SET_CAMPAIGN_TAGS; payload: string[] }
    | {
          type: ActionType.UPDATE_CONVERSION_CONFIG;
          payload: CampaignConversionConfig | undefined;
      }
    | {
          type: ActionType.SET_PREVIEW_TEXT;
          payload: string;
      }
    | {
          type: ActionType.SET_PREVIEW_USER;
          payload: string | undefined;
      }
    | {
          type: ActionType.SET_PREVIEW_USER_DATA;
          payload: Record<string, unknown>;
      }
    | {
          type: ActionType.POPULATE_CONTENT_DETAILS;
          payload: CampaignUpdateRequest;
      }
    | {
          type: ActionType.SET_SEND_AUDIENCES;
          payload: string[];
      }
    | {
          type: ActionType.SET_SUPPRESSED_AUDIENCES;
          payload: string[];
      }
    | {
          type: ActionType.SET_AUDIENCE_COUNT;
          payload: number;
      };

function reducer(
    state: CampaignReducerState,
    action: Action,
): CampaignReducerState {
    switch (action.type) {
        case ActionType.SET_AUDIENCE_PAYLOAD: {
            if (state.campaignPayload.audienceType === AudienceType.WAREHOUSE) {
                return {
                    ...state,
                    campaignPayload: {
                        ...state.campaignPayload,
                        audienceId: action.payload?.id as string,
                        audienceConfig: undefined,
                    },
                };
            }
            return state;
        }
        case ActionType.SET_CHANNEL_PAYLOAD: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    channel: action.payload
                        ?.channelName as CommunicationChannel,
                    communicationDetails: action.payload
                        ? {
                              ...state.campaignPayload.communicationDetails,
                              eventId: '',
                              id: action.payload?.integrationId || '',
                              providerId: action.payload?.providerId || '',
                          }
                        : {
                              id: '',
                              providerId: '',
                              eventId: '',
                          },
                },
            };
        }
        case ActionType.SET_CONTENT_PAYLOAD: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    contentDetails: action.payload ? action.payload : undefined,
                },
            };
        }
        case ActionType.SET_SCHEDULER_EXECUTION_TIME: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    schedule: {
                        ...state.campaignPayload.schedule,
                        executionTime: action.payload,
                        type: CampaignScheduleType.DELAYED,
                    },
                },
            };
        }

        case ActionType.SET_SCHEDULER_END_TIME: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    schedule: {
                        ...state.campaignPayload.schedule,
                        recurringDetails: {
                            cron:
                                state.campaignPayload.schedule?.recurringDetails
                                    ?.cron ?? '0 0 1 * *',
                            endTime: action.payload?.toISOString() ?? null,
                        },
                        type: CampaignScheduleType.DELAYED,
                    },
                },
            };
        }

        case ActionType.SET_CAMPAIGN_RUN_TYPE: {
            if (action.payload === CampaignType.RECURRING) {
                return {
                    ...state,
                    campaignPayload: {
                        ...state.campaignPayload,
                        schedule: {
                            ...state.campaignPayload.schedule,
                            type: CampaignScheduleType.DELAYED,
                        },
                        type: action.payload,
                    },
                };
            }
            if (state.campaignPayload.schedule) {
                const { recurringDetails, ...restSchedule } =
                    state.campaignPayload.schedule;
                return {
                    ...state,
                    campaignPayload: {
                        ...state.campaignPayload,
                        schedule: {
                            ...restSchedule,
                        },
                        type: action.payload,
                    },
                };
            }
            return state;
        }

        case ActionType.SET_CRON: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    schedule: {
                        ...state.campaignPayload.schedule,
                        recurringDetails: {
                            endTime:
                                state.campaignPayload.schedule?.recurringDetails
                                    ?.endTime ?? null,
                            cron: action.payload,
                        },
                        type: CampaignScheduleType.DELAYED,
                    },
                },
            };
        }

        case ActionType.SET_RECURRING_DETAILS: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    schedule: {
                        ...state.campaignPayload.schedule,
                        type:
                            state.campaignPayload.schedule?.type ??
                            CampaignScheduleType.DELAYED,
                        recurringDetails: action.payload,
                    },
                },
            };
        }

        case ActionType.SET_CAMPAIGN_NAME: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    name: action.payload,
                },
            };
        }
        case ActionType.SET_CAMPAIGN_DESCRIPTION: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    description: action.payload,
                },
            };
        }

        case ActionType.SET_CURRENT_STEP: {
            return {
                ...state,
                currentStep: action.payload,
            };
        }

        case ActionType.SET_SCHEDULE_TYPE: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    schedule: {
                        ...state.campaignPayload.schedule,
                        type: action.payload,
                        executionTime: null,
                    },
                },
            };
        }

        case ActionType.SET_CURRENT_STEP_CALLBACK: {
            const callbackFn = () => {
                if (action.payload.callback) {
                    action.payload.callback();
                }
                return action.payload.skipExecutionAfterCallback || false;
            };
            return {
                ...state,
                currentStepCallback: callbackFn,
            };
        }

        case ActionType.SET_PREVIOUS_STEP_CALLBACK: {
            const callbackFn = () => {
                if (action.payload.callback) {
                    action.payload.callback();
                }
                return action.payload.skipExecutionAfterCallback || false;
            };
            return {
                ...state,
                previousStepCallback: callbackFn,
            };
        }

        case ActionType.SET_SHOW_FOOTER_BUTTONS: {
            return {
                ...state,
                showFooterButtons: action.payload,
            };
        }

        case ActionType.SET_SEND_TO_VARIABLE_MAPPING: {
            const channel = state.campaignPayload.channel;
            if (!channel) return state;
            const sendToMapping = {
                [channel]: action.payload.fieldKey,
                type: action.payload.fieldType,
            };
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    sendTo: sendToMapping,
                },
            };
        }

        case ActionType.SET_SUBSCRIPTION_GROUP: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    subscriptionGroupId: action.payload,
                },
            };
        }

        case ActionType.SET_GRAPES_PREVIEW_INSTANCE: {
            return {
                ...state,
                editor: action.payload,
            };
        }

        case ActionType.SET_TRACKED_LINKS: {
            if (state.campaignPayload.templateDetails) {
                return {
                    ...state,
                    campaignPayload: {
                        ...state.campaignPayload,
                        contentMappings: {
                            ...state.campaignPayload.contentMappings,
                            [ContentMappingSection.LINK_TRACKING]: {
                                ...state.campaignPayload.contentMappings?.[
                                    ContentMappingSection.LINK_TRACKING
                                ],
                                ...action.payload,
                            },
                        },
                    },
                };
            }
            return { ...state };
        }

        case ActionType.SET_UTM_TRACKING: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    campaignTrackingParameters: action.payload,
                },
            };
        }

        case ActionType.SET_AUDIENCE_CSV_DATA: {
            return {
                ...state,
                audienceCsvData: action.payload,
            };
        }

        case ActionType.SET_AUDIENCE_CSV_UPLOAD_DATA: {
            if (state.campaignPayload.audienceType === AudienceType.CSV) {
                return {
                    ...state,
                    campaignPayload: {
                        ...state.campaignPayload,
                        csvUploadDetails: action.payload,
                    },
                };
            }
            return state;
        }

        case ActionType.SET_CAMPAIGN_TYPE: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    // INFO - Typecasting this as any because if we change the types here it affects the types at BE
                    // and we can't modify the types on BE due to some tsoa errors
                    audienceType: action.payload as any, // FIX THIS TypeError
                },
            };
        }

        case ActionType.SET_CAMPAIGN_CHANNEL: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    channel: action.payload,
                },
            };
        }

        case ActionType.SET_TEMPLATE_DETAILS: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    templateDetails: action.payload,
                },
            };
        }

        case ActionType.SET_CONTENT_DETAILS: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    contentDetails: {
                        ...state.campaignPayload.contentDetails,
                        ...action.payload,
                    },
                },
            };
        }

        case ActionType.SET_CONTENT_MAPPINGS: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    contentMappings: {
                        ...state.campaignPayload.contentMappings,
                        ...action.payload,
                    },
                },
            };
        }

        case ActionType.RESET_CONTENT_MAPPINGS: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    contentMappings: {},
                },
            };
        }

        case ActionType.RESET_STATE: {
            return {
                ...state,
                ...action.payload,
                showFooterButtons: state.showFooterButtons,
            };
        }
        case ActionType.SET_SENDER_DETAILS: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    senderDetails: {
                        ...state.campaignPayload.senderDetails,
                        ...action.payload,
                    },
                },
            };
        }

        case ActionType.SET_THROTTLE_CONFIG: {
            if (action.payload) {
                return {
                    ...state,
                    campaignPayload: {
                        ...state.campaignPayload,
                        throttleConfig: {
                            ...state.campaignPayload.throttleConfig,
                            ...action.payload,
                        },
                    },
                };
            }
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    throttleConfig: undefined,
                },
            };
        }

        case ActionType.SET_CAMPAIGN_TAGS: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    tags: action.payload,
                },
            };
        }

        case ActionType.UPDATE_CONVERSION_CONFIG: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    conversionConfig: action.payload,
                },
            };
        }

        case ActionType.SET_PREVIEW_TEXT: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    previewText: action.payload,
                },
            };
        }

        case ActionType.SET_PREVIEW_USER: {
            return {
                ...state,
                previewUser: action.payload,
            };
        }

        case ActionType.SET_PREVIEW_USER_DATA: {
            return {
                ...state,
                previewUserData: action.payload,
            };
        }

        case ActionType.POPULATE_CONTENT_DETAILS: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    ...action.payload,
                },
            };
        }

        case ActionType.SET_SEND_AUDIENCES: {
            if (state.campaignPayload.audienceConfig) {
                return {
                    ...state,
                    campaignPayload: {
                        ...state.campaignPayload,
                        audienceConfig: {
                            ...state.campaignPayload.audienceConfig,
                            config: {
                                ...state.campaignPayload.audienceConfig.config,
                                in: action.payload,
                            },
                        },
                    },
                };
            }
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    audienceConfig: {
                        totalCount: undefined,
                        config: {
                            in: action.payload,
                            notIn: [],
                        },
                    },
                },
            };
        }

        case ActionType.SET_SUPPRESSED_AUDIENCES: {
            if (state.campaignPayload.audienceConfig) {
                return {
                    ...state,
                    campaignPayload: {
                        ...state.campaignPayload,
                        audienceConfig: {
                            ...state.campaignPayload.audienceConfig,
                            config: {
                                ...state.campaignPayload.audienceConfig.config,
                                notIn: action.payload,
                            },
                        },
                    },
                };
            }
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    audienceConfig: {
                        totalCount: undefined,
                        config: {
                            notIn: action.payload,
                            in: [],
                        },
                    },
                },
            };
        }

        case ActionType.SET_AUDIENCE_COUNT: {
            if (state.campaignPayload.audienceConfig) {
                return {
                    ...state,
                    campaignPayload: {
                        ...state.campaignPayload,
                        audienceConfig: {
                            ...state.campaignPayload.audienceConfig,
                            totalCount: action.payload,
                        },
                    },
                };
            }
            return {
                ...state,
            };
        }

        default: {
            return assertUnreachable(
                action,
                'Unexpected action in explore reducer',
            );
        }
    }
}

const CampaignProvider: React.FC<
    React.PropsWithChildren<
        Pick<CampaignState, 'isEditMode' | 'uuid' | 'isNewMode'> & {
            initialState: CampaignReducerState;
        }
    >
> = ({ isEditMode, children, initialState, uuid, isNewMode }) => {
    const [reducerState, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        dispatch({ type: ActionType.RESET_STATE, payload: initialState });
    }, [initialState]);

    const setCampaignAudience = useCallback(
        (audience: Audience | null | undefined) => {
            dispatch({
                type: ActionType.SET_AUDIENCE_PAYLOAD,
                payload: audience,
            });
        },
        [],
    );

    const setCampaignName = useCallback((value: string) => {
        dispatch({
            type: ActionType.SET_CAMPAIGN_NAME,
            payload: value,
        });
    }, []);

    const setCampaignDescription = useCallback((value: string) => {
        dispatch({
            type: ActionType.SET_CAMPAIGN_DESCRIPTION,
            payload: value,
        });
    }, []);

    const setCampaignScheduleType = useCallback(
        (value: CampaignScheduleType) => {
            dispatch({
                type: ActionType.SET_SCHEDULE_TYPE,
                payload: value,
            });
        },
        [],
    );

    const setSchedulerEndTime = useCallback((value: Date | null) => {
        dispatch({
            type: ActionType.SET_SCHEDULER_END_TIME,
            payload: value,
        });
    }, []);

    const setCron = useCallback((value: string) => {
        dispatch({
            type: ActionType.SET_CRON,
            payload: value,
        });
    }, []);

    const setRecurringDetails = useCallback(
        (value: RecurringDetails | undefined) => {
            dispatch({
                type: ActionType.SET_RECURRING_DETAILS,
                payload: value,
            });
        },
        [],
    );

    const setPreviousStepCallback = useCallback(
        ({
            callback,
            skipExecutionAfterCallback,
        }: CurrentStepCallbackProps) => {
            dispatch({
                type: ActionType.SET_PREVIOUS_STEP_CALLBACK,
                payload: {
                    callback,
                    skipExecutionAfterCallback,
                },
            });
        },
        [],
    );

    const setCurrentStepCallback = useCallback(
        ({
            callback,
            skipExecutionAfterCallback,
        }: CurrentStepCallbackProps) => {
            dispatch({
                type: ActionType.SET_CURRENT_STEP_CALLBACK,
                payload: {
                    callback,
                    skipExecutionAfterCallback,
                },
            });
        },
        [],
    );

    const setCurrentStep = useCallback(
        (value: CampaignBuilderStep) => {
            setCurrentStepCallback({
                callback: null,
                skipExecutionAfterCallback: false,
            });
            setPreviousStepCallback({
                callback: null,
                skipExecutionAfterCallback: false,
            });
            dispatch({
                type: ActionType.SET_CURRENT_STEP,
                payload: value,
            });
        },
        [setCurrentStepCallback, setPreviousStepCallback],
    );

    const setSchedulerExecutionTime = useCallback((value: Date) => {
        dispatch({
            type: ActionType.SET_SCHEDULER_EXECUTION_TIME,
            payload: value, //TODO: Check the timestamp here
        });
    }, []);

    const setCampaignChannelPayload = useCallback(
        (channel: Integration | null) => {
            dispatch({
                type: ActionType.SET_CHANNEL_PAYLOAD,
                payload: channel,
            });
        },
        [],
    );

    const setCampaignContentPayload = useCallback(
        (content: TemplateContentDetails | null) => {
            dispatch({
                type: ActionType.SET_CONTENT_PAYLOAD,
                payload: content,
            });
        },
        [],
    );

    const setShowFooterButtons = useCallback(
        (value: { next: boolean; back: boolean }) => {
            dispatch({
                type: ActionType.SET_SHOW_FOOTER_BUTTONS,
                payload: value,
            });
        },
        [],
    );

    const setSendToVariableMapping = useCallback(
        (value: { fieldKey: string; fieldType: FieldType }) => {
            dispatch({
                type: ActionType.SET_SEND_TO_VARIABLE_MAPPING,
                payload: value,
            });
        },
        [],
    );

    const setSubscriptionGroup = useCallback((payload: string) => {
        dispatch({
            type: ActionType.SET_SUBSCRIPTION_GROUP,
            payload,
        });
    }, []);

    const setAudienceCsvUploadData = useCallback(
        (payload: CSVUploadDetailsType | undefined) => {
            dispatch({
                type: ActionType.SET_AUDIENCE_CSV_UPLOAD_DATA,
                payload,
            });
        },
        [],
    );

    const setGrapesPreviewInstance = useCallback((payload: Editor) => {
        dispatch({
            type: ActionType.SET_GRAPES_PREVIEW_INSTANCE,
            payload,
        });
    }, []);

    const setAudienceCsvData = useCallback((payload: unknown[]) => {
        dispatch({
            type: ActionType.SET_AUDIENCE_CSV_DATA,
            payload,
        });
    }, []);

    const setTrackedLinks = useCallback((payload: any) => {
        dispatch({
            type: ActionType.SET_TRACKED_LINKS,
            payload,
        });
    }, []);

    const setUtmTracking = useCallback((payload: Record<string, string>) => {
        dispatch({
            type: ActionType.SET_UTM_TRACKING,
            payload,
        });
    }, []);

    const setCampaignType = useCallback((payload: AudienceType) => {
        dispatch({
            type: ActionType.SET_CAMPAIGN_TYPE,
            payload,
        });
    }, []);

    const setCampaignChannel = useCallback((payload: CommunicationChannel) => {
        dispatch({
            type: ActionType.SET_CAMPAIGN_CHANNEL,
            payload,
        });
    }, []);

    const setCampaignRunType = useCallback((payload: CampaignType) => {
        dispatch({
            type: ActionType.SET_CAMPAIGN_RUN_TYPE,
            payload,
        });
    }, []);

    const setTemplateDetails = useCallback((payload: TemplateDetails) => {
        dispatch({
            type: ActionType.SET_TEMPLATE_DETAILS,
            payload,
        });
    }, []);

    const setContentDetails = useCallback((payload: TemplateContentDetails) => {
        dispatch({
            type: ActionType.SET_CONTENT_DETAILS,
            payload,
        });
    }, []);

    const setContentMappings = useCallback((payload: ContentMappings) => {
        dispatch({
            type: ActionType.SET_CONTENT_MAPPINGS,
            payload,
        });
    }, []);

    const resetContentMappings = useCallback(() => {
        dispatch({
            type: ActionType.RESET_CONTENT_MAPPINGS,
        });
    }, []);

    const setSenderDetails = useCallback((payload: SenderDetails) => {
        dispatch({
            type: ActionType.SET_SENDER_DETAILS,
            payload,
        });
    }, []);

    const setThrottleConfig = useCallback(
        (payload: CampaignThrottleConfig | undefined) => {
            dispatch({
                type: ActionType.SET_THROTTLE_CONFIG,
                payload,
            });
        },
        [],
    );
    const setCampaignTags = useCallback((payload: string[]) => {
        dispatch({
            type: ActionType.SET_CAMPAIGN_TAGS,
            payload,
        });
    }, []);

    const updateConversionConfig = useCallback(
        (payload: CampaignConversionConfig | undefined) => {
            dispatch({
                type: ActionType.UPDATE_CONVERSION_CONFIG,
                payload,
            });
        },
        [],
    );

    const setPreviewText = useCallback((payload: string) => {
        dispatch({
            type: ActionType.SET_PREVIEW_TEXT,
            payload,
        });
    }, []);

    const setPreviewUser = useCallback((payload: string | undefined) => {
        dispatch({
            type: ActionType.SET_PREVIEW_USER,
            payload,
        });
    }, []);

    const setPreviewUserData = useCallback(
        (payload: Record<string, unknown>) => {
            dispatch({
                type: ActionType.SET_PREVIEW_USER_DATA,
                payload,
            });
        },
        [],
    );

    const populateContentDetails = useCallback(
        (payload: CampaignUpdateRequest) => {
            dispatch({
                type: ActionType.POPULATE_CONTENT_DETAILS,
                payload,
            });
        },
        [],
    );
    const setSendAudiences = useCallback((payload: string[]) => {
        dispatch({
            type: ActionType.SET_SEND_AUDIENCES,
            payload,
        });
    }, []);

    const setSuppressedAudiences = useCallback((payload: string[]) => {
        dispatch({
            type: ActionType.SET_SUPPRESSED_AUDIENCES,
            payload,
        });
    }, []);

    const setAudienceCount = useCallback((payload: number) => {
        dispatch({
            type: ActionType.SET_AUDIENCE_COUNT,
            payload,
        });
    }, []);

    const isValidStep = useCallback(
        (step: CampaignBuilderStep | CampaignContentStep) => {
            switch (step) {
                case CampaignBuilderStepEnum.AUDIENCE:
                    return reducerState.campaignPayload.audienceType ===
                        AudienceType.WAREHOUSE
                        ? Boolean(reducerState.campaignPayload.audienceId)
                        : Boolean(
                              reducerState.campaignPayload.csvUploadDetails
                                  ?.uploadId,
                          );
                case CampaignBuilderStepEnum.CHANNEL:
                    return !!reducerState.campaignPayload.communicationDetails
                        ?.providerId;
                case CampaignBuilderStepEnum.CONTENT:
                    return !!reducerState.campaignPayload.templateDetails?.id;
                case CampaignBuilderStepEnum.SCHEDULE:
                    return !!reducerState.campaignPayload.schedule
                        ?.executionTime;
                case CampaignContentStepEnum.SETUP:
                    return true;
                case CampaignContentStepEnum.UTM_PARAMETERS:
                case CampaignContentStepEnum.TRACK:
                    return reducerState.campaignPayload
                        .campaignTrackingParameters &&
                        Object.keys(
                            reducerState.campaignPayload
                                .campaignTrackingParameters,
                        ).length
                        ? Object.values(
                              reducerState.campaignPayload
                                  .campaignTrackingParameters,
                          ).every((value) => value)
                        : false;
                case CampaignContentStepEnum.PERSONALISE:
                    return Boolean(
                        reducerState.campaignPayload.channel &&
                            reducerState.campaignPayload.sendTo?.[
                                reducerState.campaignPayload.channel
                            ],
                    );
                case CampaignContentStepEnum.TEST:
                    return Boolean(
                        reducerState.campaignPayload.channel &&
                            reducerState.campaignPayload.sendTo?.[
                                reducerState.campaignPayload.channel
                            ],
                    );
                default:
                    return true;
            }
            return true;
        },
        [reducerState],
    );

    const state = useMemo(
        () => ({
            ...reducerState,
            isEditMode,
            uuid,
            // status: null,
            isNewMode,
            isValidStep,
        }),
        [isEditMode, reducerState, uuid, isNewMode, isValidStep],
    );

    const actions = useMemo(
        () => ({
            setCampaignAudience,
            setCampaignName,
            setCampaignDescription,
            setCampaignScheduleType,
            setCurrentStep,
            setSchedulerExecutionTime,
            setCurrentStepCallback,
            setPreviousStepCallback,
            setCampaignChannelPayload,
            setCampaignContentPayload,
            setShowFooterButtons,
            setSendToVariableMapping,
            setSubscriptionGroup,
            setAudienceCsvUploadData,
            setAudienceCsvData,
            setGrapesPreviewInstance,
            setTrackedLinks,
            setUtmTracking,
            setCampaignType,
            setCampaignChannel,
            setSchedulerEndTime,
            setCron,
            setRecurringDetails,
            setCampaignRunType,
            setTemplateDetails,
            setContentDetails,
            setContentMappings,
            resetContentMappings,
            setSenderDetails,
            setThrottleConfig,
            setCampaignTags,
            updateConversionConfig,
            setPreviewText,
            setPreviewUser,
            setPreviewUserData,
            populateContentDetails,
            setSendAudiences,
            setSuppressedAudiences,
            setAudienceCount,
        }),
        [
            setCampaignAudience,
            setCampaignName,
            setCampaignDescription,
            setCampaignScheduleType,
            setCurrentStep,
            setSchedulerExecutionTime,
            setCurrentStepCallback,
            setPreviousStepCallback,
            setCampaignChannelPayload,
            setCampaignContentPayload,
            setShowFooterButtons,
            setSendToVariableMapping,
            setSubscriptionGroup,
            setAudienceCsvUploadData,
            setAudienceCsvData,
            setGrapesPreviewInstance,
            setTrackedLinks,
            setUtmTracking,
            setCampaignType,
            setCampaignChannel,
            setSchedulerEndTime,
            setCron,
            setRecurringDetails,
            setCampaignRunType,
            setTemplateDetails,
            setContentDetails,
            setContentMappings,
            resetContentMappings,
            setSenderDetails,
            setThrottleConfig,
            setCampaignTags,
            updateConversionConfig,
            setPreviewText,
            setPreviewUser,
            setPreviewUserData,
            populateContentDetails,
            setSendAudiences,
            setSuppressedAudiences,
            setAudienceCount,
        ],
    );

    const value: CampaignContext = useMemo(
        () => ({
            state,
            actions,
        }),
        [state, actions],
    );
    return (
        <CampaignProviderContext.Provider value={value}>
            {children}
        </CampaignProviderContext.Provider>
    );
};

export default CampaignProvider;
