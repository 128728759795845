import { useLocale } from '@hooks/useLocale';
import {
    AIMessageContext,
    AiMessageTypes,
    Author,
    type AgentMessage,
} from '@lightdash/common';
import { Box, Textarea } from '@mantine/core';
import { ArrowBendDownLeft, Stop } from '@phosphor-icons/react';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';
import { memo, useCallback, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

function PureMultimodalInput({
    chatId,
    chatInput,
    setChatInput,
    isLoading,
    handleSendMessage,
    stop,
}: {
    chatId: string;
    chatInput: string;
    setChatInput: (value: string) => void;
    isLoading: boolean;
    handleSendMessage: (message?: string) => Promise<void>;
    stop: () => void;
}) {
    const { t } = useLocale();

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const { setAIChatMessages } = useAiAnalystContext(
        (context) => context.actions,
    );

    const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setChatInput(event.target.value);
    };

    const submitForm = useCallback(async () => {
        const message: AgentMessage = {
            threadId: chatId,
            author: Author.USER,
            messageId: uuidv4(),
            context: AIMessageContext.VISUAL_AUDIENCE,
            content: {
                type: AiMessageTypes.TEXT,
                value: chatInput,
            },
            traceId: '',
        };
        setAIChatMessages([message]);
        await handleSendMessage();
    }, [chatId, chatInput, setAIChatMessages, handleSendMessage]);

    useEffect(() => {
        if (chatInput && textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [chatInput, setChatInput]);

    return (
        <div className="relative flex flex-col w-full gap-4">
            <Textarea
                ref={textareaRef}
                styles={(_params) => ({
                    input: {
                        border: '1px solid rgb(var(--color-white)/0.10)',
                        '&:hover': {
                            border: '1px solid rgb(var(--color-blu-800))',
                        },
                        backgroundColor:
                            'rgb(var(--color-white)/0.06) !important',
                        color: 'white !important',
                        '&:focus': {
                            backgroundColor: 'black !important',
                            color: 'white !important',
                        },
                        height: '76px !important',
                    },
                })}
                placeholder={t('chat.send_a_message')}
                value={chatInput}
                onChange={handleInput}
                rightSection={
                    <Box
                        className={`absolute bottom-4 right-4 cursor-pointer ${
                            isLoading ? 'p-1 bg-white/20 rounded-full' : ''
                        } `}
                        onClick={async () => {
                            if (isLoading) {
                                await stop();
                            } else {
                                await submitForm();
                            }
                        }}
                    >
                        {isLoading ? (
                            <Stop size={14} weight="duotone" />
                        ) : (
                            <ArrowBendDownLeft size={14} />
                        )}
                    </Box>
                }
                autoFocus
                onKeyDown={async (event) => {
                    if (event.key === 'Enter' && !event.shiftKey) {
                        event.preventDefault();
                        await submitForm();
                    }
                }}
                disabled={isLoading}
            />
        </div>
    );
}

export const MultimodalInput = memo(
    PureMultimodalInput,
    (prevProps, nextProps) => {
        if (prevProps.chatInput !== nextProps.chatInput) return false;
        if (prevProps.isLoading !== nextProps.isLoading) return false;
        return true;
    },
);
