import {
    AIMessageContext,
    AudienceBuilderHashParams,
    FieldType,
    type AgentJsonContent,
} from '@lightdash/common';
import { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';

const useAIMessageContext = () => {
    const location = useLocation();
    const { projectUuid } = useParams<{ projectUuid: string }>();

    const getCurrentContext = useMemo(() => {
        const hashContextMap = {
            '#visual': AIMessageContext.VISUAL_AUDIENCE,
            '#sql': AIMessageContext.SQL_AUDIENCE,
        };

        const eventContextMap = {
            '/events': AIMessageContext.EVENT_SETUP,
        };
        const traitContextMap = {
            '/traits/create': AIMessageContext.CUSTOM_ATTRIBUTE_CREATION,
            '/traits': AIMessageContext.CUSTOM_ATTRIBUTE,
        };
        const analyticsContextMap = {
            '/spaces': AIMessageContext.CHART,
        };
        const relationContextMap = {
            '/relations': AIMessageContext.RELATIONS,
        };

        const pathContextMap = {
            ...eventContextMap,
            ...traitContextMap,
            ...analyticsContextMap,
            ...relationContextMap,
        };

        for (const [hash, context] of Object.entries(hashContextMap)) {
            if (location.hash.includes(hash)) {
                return context;
            }
        }

        for (const [path, context] of Object.entries(pathContextMap)) {
            if (location.pathname.includes(path)) {
                return context;
            }
        }

        return AIMessageContext.OTHERS;
    }, [location.hash, location.pathname]);

    const getContextLocation = useCallback(
        (jsonContent: AgentJsonContent) => {
            const context = jsonContent.value.context;
            switch (context) {
                case AIMessageContext.VISUAL_AUDIENCE:
                    return `/projects/${projectUuid}/audiences/create#${AudienceBuilderHashParams.VISUAL}`;
                case AIMessageContext.SQL_AUDIENCE:
                    return `/projects/${projectUuid}/audiences/create#${AudienceBuilderHashParams.SQL}`;
                case AIMessageContext.CUSTOM_ATTRIBUTE:
                    return `/projects/${projectUuid}/traits/create#${
                        jsonContent.value.customAttribute.type ??
                        FieldType.DIMENSION
                    }`;
                case AIMessageContext.CUSTOM_ATTRIBUTE_CREATION:
                    return `/projects/${projectUuid}/traits/create#${
                        jsonContent.value.customAttribute.type ??
                        FieldType.DIMENSION
                    }`;
                default:
                    return AIMessageContext.OTHERS;
            }
        },
        [projectUuid],
    );

    const getStateForNavigation = (jsonContent: AgentJsonContent) => {
        const context = jsonContent.value.context;
        switch (context) {
            case AIMessageContext.CUSTOM_ATTRIBUTE:
            case AIMessageContext.CUSTOM_ATTRIBUTE_CREATION:
                return {
                    customAttribute:
                        jsonContent.value.customAttribute
                            .insertCustomAttributeData,
                };
            default:
                return { aiJsonData: jsonContent };
        }
    };

    return {
        getCurrentContext,
        getContextLocation,
        getStateForNavigation,
    };
};

export default useAIMessageContext;
