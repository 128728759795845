import { useLocale } from '@hooks/useLocale';
import {
    WAButtonType,
    WAHeaderType,
    type WAButton,
    type WhatsAppTemplateContentDetails,
} from '@lightdash/common';
import { Box, Flex, Group, Stack, Text } from '@mantine/core';
import {
    ArrowLeft,
    ArrowSquareOut,
    Camera,
    Copy,
    DotsThreeVertical,
    FileText,
    Microphone,
    Phone,
    Plus,
    ShareFat,
    Sticker,
    VideoCamera,
} from '@phosphor-icons/react';
import React from 'react';

const chatBgColor = '#1E1E1E';
const toolbarColor = '#2F3131';

const replaceTildaWithStrikethrough = (text: string) => {
    return text.replace(/\~(.*?)\~/g, '<del>$1</del>');
};

const replaceUnderscoreWithItalic = (text: string) => {
    return text.replace(/\_(.*?)\_/g, '<i>$1</i>');
};

const replaceAsterisksWithBold = (text: string) => {
    return text.replace(/\*(.*?)\*/g, '<b>$1</b>');
};

const createWhatsappBodyMarkup = (text: string) => {
    let formattedText = replaceUnderscoreWithItalic(text);
    formattedText = replaceAsterisksWithBold(formattedText);
    formattedText = replaceTildaWithStrikethrough(formattedText);
    formattedText = formattedText.replaceAll('\\n', '<br />');
    return { __html: formattedText };
};

const WhatsappTemplateHeader = ({
    text,
    type,
    mediaDetails,
}: {
    text: string;
    type?: string;
    mediaDetails?: {
        url: string;
    };
}) => {
    if (type === WAHeaderType.FILE) {
        return (
            <Group
                spacing={4}
                className={`m-1 mt-0 h-[40px] bg-gray-100 rounded-md py-1 px-2`}
            >
                <FileText
                    weight="duotone"
                    color="rgb(var(--color-gray-800))"
                    size={20}
                />
                <Text className="text-sm font-normal text-gray-800">
                    File name
                </Text>
            </Group>
        );
    }
    if (type === WAHeaderType.IMAGE) {
        return (
            <Box className="w-[220px] bg-none rounded-md py-1 px-2">
                <img
                    src="/image_placeholder.jpg"
                    alt="template"
                    className="object-contain w-full h-full"
                />
            </Box>
        );
    }
    if (type === WAHeaderType.VIDEO) {
        return (
            <Box className="w-[220px] bg-none rounded-md py-1 px-2">
                <img
                    src={mediaDetails && mediaDetails.url}
                    alt="template"
                    className="object-contain w-full h-full"
                />
            </Box>
        );
    }
    if (!text) {
        return null;
    }
    return (
        <Text className="px-2 pt-2 text-sm font-semibold text-gray-800">
            {text}
        </Text>
    );
};

const WhatsappTemplateBody = ({ text }: { text: string }) => {
    if (!text) {
        return null;
    }
    return (
        <Text
            className="px-2 text-sm font-normal text-gray-800 whitespace-pre-line"
            dangerouslySetInnerHTML={createWhatsappBodyMarkup(text)}
        />
    );
};

const WhatsappTemplateFooter = ({ text }: { text: string }) => {
    if (!text) {
        return null;
    }
    return (
        <Text
            className="text-xs font-normal text-[#848294] px-2 pb-2"
            dangerouslySetInnerHTML={{ __html: text }}
        />
    );
};

const WhatsappButtonIcons = ({ type }: { type: string }) => {
    const iconColor = 'rgb(var(--color-blu-800))';
    switch (type) {
        case WAButtonType.URL:
            return <ArrowSquareOut weight="regular" color={iconColor} />;
        case WAButtonType.QUICK_REPLY:
            return (
                <ShareFat
                    weight="regular"
                    color={iconColor}
                    style={{ transform: 'rotateY(180deg)' }}
                />
            );
        case WAButtonType.COPY:
            return <Copy weight="regular" color={iconColor} />;
        case WAButtonType.PHONE:
        default:
            return <Phone weight="regular" color={iconColor} />;
    }
};

const WhatsappButtons = ({ buttons }: { buttons: WAButton[] }) => {
    return (
        <Stack spacing={0}>
            {buttons?.map((button: any) => {
                return (
                    <Group
                        key={button.id}
                        spacing={'xs'}
                        align="center"
                        position="center"
                        className="h-[33px] text-gray-800 text-xs"
                        style={{ borderTop: '1px solid #E0E0E0' }}
                    >
                        <WhatsappButtonIcons type={button.type as string} />
                        {button.title}
                    </Group>
                );
            })}
        </Stack>
    );
};

const WhatsappPreview = ({
    content,
    name,
    onModify,
    showModifyButton,
    showHeader = true,
}: {
    content: WhatsAppTemplateContentDetails;
    name: string;
    onModify: () => void;
    showModifyButton: boolean;
    isSetupComplete: boolean;
    openContentSetupManager: () => void;
    showSetupStrip: boolean;
    showHeader: boolean;
}) => {
    const { t } = useLocale();
    return (
        <Box className={`pb-3 mt-6 rounded-lg ${showHeader && 'border-base'}`}>
            {showHeader && (
                <>
                    {showModifyButton && (
                        <Flex
                            justify="space-between"
                            align="center"
                            className="pr-4"
                        >
                            <Text className="px-[13px] py-[9px]">
                                {t('common.preview')}
                            </Text>
                            {showModifyButton && (
                                <Text
                                    className="font-medium cursor-pointer text-blu-800"
                                    onClick={onModify}
                                >
                                    {t('preview_subtitle.modify')}
                                </Text>
                            )}
                        </Flex>
                    )}
                    <Text className="px-[13px] py-[9px] bg-gray-50 border border-r-0 border-l-0 text-xs text-gray-500">
                        This preview might not be representative of all whatsapp
                        clients.
                    </Text>
                    <Box className="border-b p-[13px] w-full">
                        <Text className="text-gray-500 text-ellipsis overflow-hidden whitespace-nowrap w-full max-w-[300px] truncate">
                            {name}
                        </Text>
                    </Box>
                </>
            )}
            <Box
                className={`mt-2 mx-auto w-[300px] h-[540px] bg-[${chatBgColor}] rounded-xl whatsapp-preview border-base shadow-[0_1px_4px_0px_rgba(0,0,0,0.15)]`}
            >
                <Group
                    spacing={'xs'}
                    className={`bg-[${toolbarColor}] sticky top-0 p-4`}
                    position="apart"
                >
                    <Flex align="center" gap={'xs'}>
                        <ArrowLeft
                            weight="bold"
                            size={13}
                            className="border-base rounded-lg h-[24px] w-[24px] p-1"
                        />
                        <Text className="text-sm font-semibold text-gray-800">
                            {t('sms_template_preview.normal_sms_sender')}
                        </Text>
                    </Flex>
                    <Flex gap={'sm'}>
                        <VideoCamera
                            weight="regular"
                            color="rgb(var(--color-gray-600))"
                        />
                        <Phone weight="regular" />
                        <DotsThreeVertical weight="bold" />
                    </Flex>
                </Group>
                <Box className="h-[445px] bg-[#F5F5F5] pt-4 overflow-auto">
                    <Box className="w-[220px] ml-2 mt-1 mb-2 bg-white rounded-xl rounded-bl-none pt-1">
                        <WhatsappTemplateHeader
                            type={content.header?.type ?? 'text'}
                            mediaDetails={{
                                url: 'https://placehold.co/600x400',
                            }}
                            text={(content.header as any)?.text}
                        />
                        <WhatsappTemplateBody
                            text={(content.body as any)?.text}
                        />
                        <WhatsappTemplateFooter
                            text={(content.footer as any)?.text}
                        />
                        {content.buttons &&
                            content.buttons.length > 0 &&
                            Array.isArray(content.buttons) && (
                                <WhatsappButtons buttons={content.buttons} />
                            )}
                    </Box>
                </Box>
                <Group className="sticky bottom-0 p-2 bg-white border-none">
                    <Plus
                        weight="regular"
                        color="rgb(var(--color-blu-800))"
                        size={20}
                    />
                    <Flex
                        justify="flex-end"
                        align="center"
                        className={`w-[172px] h-[20px] bg-[#F5F5F5] rounded-full pr-1`}
                    >
                        <Sticker
                            weight="regular"
                            color="rgb(var(--color-blu-800))"
                            size={18}
                        />
                    </Flex>
                    <Camera
                        weight="regular"
                        color="rgb(var(--color-blu-800))"
                        size={20}
                    />
                    <Microphone
                        weight="regular"
                        color="rgb(var(--color-blu-800))"
                        size={20}
                    />
                </Group>
            </Box>
        </Box>
    );
};

export default WhatsappPreview;
