import { useSpamReportsCount, useUnsubscribeCount } from '@hooks/useAnalytics';
import {
    CommunicationChannel,
    type ApiQueryResults,
    type GenericAnalyticsCountRequest,
} from '@lightdash/common';
import { Box, Skeleton, Table, Text } from '@mantine/core';
import useRelationContext from '@providers/Relation/useRelationContext';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import useBaseFunnelData from './useBaseFunnelData';

const CampaignSummaryTable = ({
    data,
    channel,
}: {
    data: ApiQueryResults | undefined;
    channel: CommunicationChannel;
}) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const campaignId = queryParams.get('campaignUuid') ?? '';
    const result = useBaseFunnelData({ data });
    const { activeRelationUuid } = useRelationContext();

    const {
        mutate: getUnsubscribeCount,
        data: unsubscribeCount,
        isLoading: isLoadingUnsubscribeCount,
    } = useUnsubscribeCount({
        relationId: activeRelationUuid,
    });

    useEffect(() => {
        const payload: GenericAnalyticsCountRequest = {
            campaignIds: [campaignId],
        };
        getUnsubscribeCount(payload);
    }, [campaignId, getUnsubscribeCount]);

    const {
        mutate: getSpamReportsCount,
        data: spamReportsCount,
        isLoading: isLoadingSpamReportsCount,
    } = useSpamReportsCount({
        relationId: activeRelationUuid,
    });

    useEffect(() => {
        const payload: GenericAnalyticsCountRequest = {
            campaignIds: [campaignId],
        };
        getSpamReportsCount(payload);
    }, [campaignId, getSpamReportsCount]);

    if (!result) return null;

    const elements = [
        {
            campaign: result.campaignName,
            audience: result.audienceCount ?? 0,
            sent: result.sentCount,
            delivered: result.deliveredCount,
            opened: result.openEventsCount,
            unqiueClicks: result.clickedEventsCount,
            converted: result.convertedEventsCount,
            bounced: result.bouncedCount,
            unsubsribed: unsubscribeCount?.[campaignId] ?? 0,
            spamReports: spamReportsCount?.[campaignId] ?? 0,
            read: result.readEventsCount,
            // spam: 200,
        },
    ];
    const rows = elements.map((element) => (
        <tr key={element.campaign}>
            <td>{element.audience}</td>
            <td>
                {element.sent}
                {` (${((element.sent / element.audience) * 100).toFixed(2)}%)`}
            </td>
            <td>
                {element.delivered}
                {` (${((element.delivered / element.audience) * 100).toFixed(
                    2,
                )}%)`}
            </td>
            <td>
                {channel === CommunicationChannel.WHATSAPP
                    ? element.read
                    : element.opened}
                {` (${(
                    ((channel === CommunicationChannel.WHATSAPP
                        ? element.read
                        : element.opened) /
                        element.audience) *
                    100
                ).toFixed(2)}%)`}
            </td>
            <td>
                {element.unqiueClicks}
                {` (${((element.unqiueClicks / element.audience) * 100).toFixed(
                    2,
                )}%)`}
            </td>
            <td>
                {element.converted}
                {` (${((element.converted / element.audience) * 100).toFixed(
                    2,
                )}%)`}
            </td>
            <td>
                {element.bounced}
                {` (${((element.bounced / element.audience) * 100).toFixed(
                    2,
                )}%)`}
            </td>
            <td>
                {isLoadingUnsubscribeCount ? (
                    <Skeleton height={10} width={100} />
                ) : (
                    `${element.unsubsribed} (${(
                        (element.unsubsribed / element.audience) *
                        100
                    ).toFixed(2)}%)`
                )}
            </td>
            <td>
                {isLoadingSpamReportsCount ? (
                    <Skeleton height={10} width={100} />
                ) : (
                    `${element.spamReports} (${(
                        (element.spamReports / element.audience) *
                        100
                    ).toFixed(2)}%)`
                )}
            </td>
        </tr>
    ));

    return (
        <Box className="border-base mb-3 p-4 mx-3">
            <Text className="text-base font-semibold text-gray-800">
                Campaign Summary
            </Text>
            <Table withBorder withColumnBorders className="mt-4">
                <thead>
                    <tr>
                        <th>Audience</th>
                        <th>Sent</th>
                        <th>Delivered</th>
                        <th>
                            {channel === CommunicationChannel.WHATSAPP
                                ? 'Read'
                                : 'Opened'}
                        </th>
                        <th>Clicked</th>
                        <th>Converted</th>
                        <th>Bounced</th>
                        <th>Unsubscribed</th>
                        <th>Spam Reports</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        </Box>
    );
};

export default CampaignSummaryTable;
